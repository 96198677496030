import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerSearchDialogComponent } from '../customer-search-dialog/customer-search-dialog.component';
import {CustomerBarcodeSearchDialogComponent} from "../customer-barcode-search-dialog/customer-barcode-search-dialog.component";
import {DialogHelper, DialogWidth} from "../../../../../../../../dist/tymes4-shared";
import { TranslateService } from '@ngx-translate/core';
import { CustomerService, CustomerView } from '../../../api';

@Component({
  selector: 't4-customer-selector',
  templateUrl: 'customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})

export class CustomerSelectorComponent implements OnInit {

  @Output() onCustomerChanged = new EventEmitter<object>();

  public selectedCustomer = null;
  public selectedCustomerName = null;
  public isClearable: boolean = false;

  constructor(private customerService: CustomerService, private dialog: MatDialog, private translate: TranslateService) {
  }

  @Input()
  set clearable(c: boolean) {
    this.isClearable = c;
  }

  ngOnInit() {
  }

  setCustomer(customerId) {

    if (customerId == null) {
      this.setCustomerInternal(null);
    }
    else {
      this.customerService.getCustomer(customerId).subscribe((customer: CustomerView) => {
        this.setCustomerInternal(customer);
      });
    }

  }
  customerPickup() {

    let title = this.translate.instant('COMPONENTS.CUSTOMER.SELECTOR.TITLE.SELECT-RELATION');
    let dialogRef: MatDialogRef<any> = this.dialog.open(CustomerSearchDialogComponent, {
      height: '95%',
      width: '98vw',
      maxWidth: '98vw',
      disableClose: true,
      data: { }
    });
    dialogRef.afterClosed()
      .subscribe(customer => {

        if (customer) {
          this.onCustomerChanged.emit(customer);
          this.setCustomerInternal(customer);
        }

      }
    );

  }
  customerPickupBarcode() {

    let title = this.translate.instant('COMPONENTS.CUSTOMER.SELECTOR.TITLE.SELECT-RELATION');
    let dialogRef: MatDialogRef<any> = this.dialog.open(CustomerBarcodeSearchDialogComponent, {
      width: '500px',
      maxWidth: '98vw',
      disableClose: false,
      data: { }
    });
    dialogRef.afterClosed()
      .subscribe(customer => {

          if (customer) {
            this.onCustomerChanged.emit(customer);
            this.setCustomerInternal(customer);
          }

        }
      );

  }

  setCustomerInternal(customer: CustomerView) {
    this.selectedCustomer = customer;

    if (this.selectedCustomer === null) this.selectedCustomerName = null;
    else this.selectedCustomerName = (customer.Name) + ' (' + customer.CustomerNumber + ')';
  }

  resetCustomerInternal(){
    this.onCustomerChanged.emit();
    this.selectedCustomer = null;
    this.selectedCustomerName = null;

  }

}
