import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import { GenericErrorHandler } from 'app/shared/helpers/generic-error-handler';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import {DiscountService, FileImportResult} from "../../api";

@Component({
  selector: 'app-upload-discountcodes-importfile',
  templateUrl: './upload-discountcodes-importfile.component.html'
})
export class UploadDiscountCodesImportfileComponent implements OnInit {

  public discountId: number;

  @ViewChild('file') fileInput;

  validationMessages = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<UploadDiscountCodesImportfileComponent>,
    private discountService: DiscountService,
    private loader: HttpLoaderService,
    private confirmService: ConfirmService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.discountId = this.passedData.discountId;
  }

  uploadDiscountCodesFile(files) {
    if (files === null || files === 'undefined' || !(files.length > 0)) {
      return;
    }

    const file = files[0];
    var blobArray : Array<Blob> = new Array<Blob>();
    blobArray.push(file);

    this.loader.open();
    this.discountService.uploadDiscountCodesFile(this.discountId, blobArray).subscribe((result: FileImportResult) => {
      if(result.Errors && result.Errors.length > 0) {
        const messageContent = this.buildUploadErrorText(result.Errors);
        this.confirmService.confirm({ title: this.translate.instant('DIALOGS.UPLOAD-DISCOUNTCODES-IMPORTFILE.WARNING.TITLE.IMPORT-DISCOUNTCODES'), message: messageContent, okonly: true }).subscribe(confirmed => {
          this.loader.close();
          this.dialogRef.close(null)
        });
      }

      if(result.Warnings && result.Warnings.length > 0) {
        const messageContent = this.buildUploadWarningText(result.Warnings);
        this.confirmService.confirm({ title: this.translate.instant('DIALOGS.UPLOAD-DISCOUNTCODES-IMPORTFILE.WARNING.TITLE.IMPORT-DISCOUNTCODES'), message: messageContent, okonly: true }).subscribe(confirmed => {
          this.loader.close();
          this.dialogRef.close(result.LongRunningOperation);
        });
      };

      this.loader.close();
      this.dialogRef.close(result.LongRunningOperation);
    });
  }

  downloadTemplate() {

    this.loader.open();

    this.discountService.downloadImportTemplate().subscribe((data: Blob) =>{
      this.loader.close();
      FileSaver.saveAs(data, "tymes4-examples-coupon-import" + ".xlsx");
    });

  }
  buildUploadErrorText(errors) {
    let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.FAILED') + '</b></div><br />';
    let errorCounter = 0;
    for (const e of errors) {
      if (errorCounter >= 5) {
        result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.ERRORS.COUNT', {errors : (errors.length - errorCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;${e}</span></div><br />`;
      errorCounter++;
    }
    return result;
  }

  buildUploadWarningText(warnings) {
    if (warnings.length === 1) {
      const resultok = '<div><b>' + warnings[0] + '</b></div>';
      return resultok;
    } else {
      let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.WARNINGS') + '</b></div><br />';
      let warningCounter = 0;
      for (const e of warnings) {

        if (warningCounter >= 5) {
          result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.WARNING.COUNT', {count: (warnings.length - warningCounter)}) + `</span></div><br />`;
          break;
        }

        result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;${this.translate.instant(e)}</span></div><br />`;
        warningCounter++;

      }
      return result;
    }
  }
}
