<div class="vertical-tabs edit-content" >
  <div class="nav flex-column nav-pills nav-pills-custom">
    <a [class]="'nav-link p-3 ' + (tab.customCss && tab.customCss !== null ? tab.customCss : '')" [class.active]="tab.selected" *ngFor="let tab of tabs" (click)="selectTab(tab)">
      <i *ngIf="tab.faIcon && tab.faIcon !== null" [class]="tab.faIcon + ' mr-2'"></i>
      <span class="font-weight-bold small text-uppercase">{{tab.tabTitle | translate}}</span>
    </a>
  </div>
</div>

<ng-content></ng-content>
