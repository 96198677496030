import { HttpLoaderService } from '@tymes4-shared';
import { KeyValuePair } from './../../models/key-value-pair';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SalesGroupValidator } from '../../validators/sales-group-validator';
import { SalesGroupService } from '../../api';

@Component({
    selector: 'app-sales-group-form',
    templateUrl: './edit-sales-group-form.component.html'
})
export class EditSalesGroupComponent implements OnInit {
    public form: FormGroup;

    public saving = false;

    private isNew: boolean = true;
    private editObject: any = null;

    validationMessages = [];

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {

        if (this.requiredFields === null) {
          this.requiredFields = {};
        }

        if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {

          const form = targetForm ? targetForm : this.form;
          const formField = form.get(field);

          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
      }

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef:  MatDialogRef<EditSalesGroupComponent>,
    private salesGroupValidator: SalesGroupValidator,
    private loader:HttpLoaderService,
    private salesGroupService: SalesGroupService
    ) { }

    ngOnInit() {
      this.editObject = this.passedData.payload;
      this.salesGroupValidator.editingObject = this.editObject;

      if (this.editObject == null) {
        this.isNew = true;
        this.editObject = {};

      }
      else
      {
        this.isNew = false;
      }

      this.form = new FormGroup({
        Id: new FormControl(0),
        Name: new FormControl('', [Validators.required]),
        Code: new FormControl('', {
          validators: Validators.required,
          asyncValidators: [
            this.salesGroupValidator.codeTaken.bind(this)
        ]})
      });

      this.form.patchValue(this.editObject);
    }

    submit() {
      let formData = this.form.value;

      this.saving = true;
      this.loader.open();

      if (this.isNew)
      {
        this.salesGroupService.createSalesGroup(formData).subscribe((id:number) => {
          this.dialogRef.close(id);
          this.loader.close();
        })
      }
      else {
        this.salesGroupService.updateSalesGroup(formData).subscribe(() => {
          this.dialogRef.close(this.editObject.Id);
          this.saving = false;
          this.loader.close();
        })
      }

    }

}
