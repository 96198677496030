import {Component, Inject, OnInit} from '@angular/core';
import {FileInputResult} from '../../components/file-input/file-input.component';
import {HttpResponseBase} from '@angular/common/http';
import {HttpLoaderService} from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import {ConfirmService} from '../../../../../../../dist/tymes4-shared';
import {TranslateService} from '@ngx-translate/core'
import { ImageService } from '../../api';
@Component({
  selector: 'app-file-input-dialog',
  templateUrl: './file-input-dialog.component.html',
  styleUrls: ['./file-input-dialog.component.scss']
})
export class FileInputDialogComponent implements OnInit {

  public fileInputResult: FileInputResult = null;
  public fileInputDescription: string;
  public isUploading: boolean;
  public title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private loader: HttpLoaderService,
              private imageService: ImageService,
              private confirmService: ConfirmService,
              private translate: TranslateService,
  public dialogRef: MatDialogRef<FileInputDialogComponent>) { }


  ngOnInit() {
    this.title = this.passedData.title;
  }

  onFileSelect(fileInputResult: FileInputResult) {
    this.fileInputResult = fileInputResult;
  }

  onDescriptionChanged(e) {
    this.fileInputDescription = e.target.value;
  }

  uploadFile() {
    if (this.fileInputResult != null) {
      this.loader.open();
      this.isUploading = true;

      const file = this.fileInputResult.file;
      if (file.type.toLocaleLowerCase() === 'image/jpg' || file.type.toLocaleLowerCase() === 'image/jpeg' ||
        file.type.toLocaleLowerCase() === 'image/png' || file.type.toLocaleLowerCase() === 'image/bmp' || file.type.toLocaleLowerCase() === 'image/gif') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const mimeType = (reader.result as string).split(',')[0];
          const base64string = (reader.result as string).split(',')[1];

          this.imageService.uploadImage(null,  { 
             Description: this.fileInputDescription, 
             FileData: base64string, 
             FileName: file.name, 
             FileTypeName: file.type, 
             MimeType: mimeType
          })
            .subscribe(
              (data: any) => {

                if (data instanceof HttpResponseBase) {
                  this.isUploading = false;
                }
                this.dialogRef.close(data);
                this.loader.close();
              },
              error => {
                console.log(error);
                this.loader.close();
              }
            );
        };
      }
      else {
        this.isUploading = false;
        this.loader.close();
        this.confirmService.confirm({
          title: this.translate.instant('IMAGE.FILE.UPLOAD.WRONG.FILE.WARNING.TITLE'),
          message: this.translate.instant(`IMAGE.FILE.UPLOAD.WRONG.FILE.WARNING.MESSAGE`)
        });
      }
    }
  }
}
