import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {DataService, EnvironmentService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class PromotionService extends DataService {
  constructor(private httpClient: HttpClient, logging: LoggingService, environmentService: EnvironmentService) {
    super(httpClient, environmentService);
    this.entityName = 'promotion';
  }

  // TODO obsolete? 
  // TODO needs create, update, delete, search
  listSelectable(): any {
    const url = this.getUrl() + `/list-selectable`;
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }
}
