

<div class="row modal-bar">
    <div class="col-sm-9">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-3">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(null);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">

      <div class="form-layout">

        <t4-page-info info='SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.INFO.MSG'></t4-page-info>


        <t4-list-view #listVw [options]="listOptions" (onDataRequest)="onDataRequest($event)">


            <ng-container *t4ListItem="let item">
              <td><input [disabled]="item.AllowPrint === false"  type="checkbox"  [checked]="item.Selected" (change)="update(item, $event)" /></td>
              <td>{{item.CustomerPassePartoutId ? item.PassePartoutName : item.MembershipName}}</td>
              <td *ngIf="item.CustomerPassePartoutId">{{item.SectionShortName}} / {{item.RowNumber | replaceOnEmpty}} / {{item.SeatNumber | replaceOnEmpty}}</td>
              <td *ngIf="!item.CustomerPassePartoutId">-</td>
              <td>{{item.TicketCustomerName}}</td>
              <td>{{item.Barcode}}</td>
              <td [class.text-danger]="item.AllowPrint === false">{{item.StatusName}}</td>
            </ng-container>

          </t4-list-view>



        <div class="form-layout-footer row">
            <div class="col-6">
              <button (click)="submit()" [disabled]="selectedStatusses.length == 0 || form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
                class="fas fa-print"></i><span>{{'GENERIC.BTN.PRINT' | translate}}</span></button>
            </div>
            <div class="col-6">
              <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
                class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
            </div>
          </div><!-- form-layout-footer -->


      </div><!-- form-layout -->
    </div>
  </form>


