<form [formGroup]="form">

    

        <div class="row">
            <div class="col-lg-6">
                <div class="card order-height h-100" style="min-height: 450px;">
                    <div class="card-header">
                        <h6>{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.HEADER.SELECT-A-PRODUCT' | translate}}</h6>
                    </div>
                    <div class="row">

                        <div class="col-lg-6">
                            <div class="mt-2 ml-2">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                    <span (click)="updateFilter($event)" class="input-group-text bd-0"><i class="fas fa-search"></i></span>
                                    </div>
                                    <input (keydown.enter)="updateFilter($event)" formControlName="SearchText" class="form-control bd-0"  placeholder="{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.SEARCH-BAR-TEXT.PLACEHOLDER' | translate}}" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="mt-2 mr-2">
                                <ng-select [items]="crossSellProductCategories" bindLabel="Name" bindValue="Id" formControlName="CategoryId" placeholder="{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CATEGORY.PLACEHOLDER' | translate}}"
                                clearable="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="updateFilter($event)"></ng-select>
                            </div>
                        </div>
                      
                    </div>
                    <div class="mx-2">
                        <table class="table table-striped">
                            <ng-container *ngIf="listProducts.length > 0; else noresultproduct">
                                <tbody>
                                    <tr *ngFor="let e of listProducts" class="clickable list-item" (click)="toggleProductSelection(e)">
                                        <td style="vertical-align: middle; width: 40px;"><input type="checkbox" [checked]="isProductSelected(e)" /> </td>
                                        <td>
                                          <a class="text-dark">{{e.Name}}</a>
                                          <span class=" d-block" style="max-width:160px;">{{ 'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.LISTVIEW.PRODUCT.VARIANT-COUNT' | translate : {count: e.NoOfVariants} }}</span>
                                        </td>
                                        <td style='vertical-align: middle;' class="text-right">
                                          <img *ngIf="e.MainImageUrl !== null" style="width: 32px;" [src]="fileHelper.getResizedImageUrl(e.MainImageUrl, 32, 32)">  
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-container>
    
                            <ng-template #noresultproduct>
                                <tbody>
                                    <tr><td>{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.LISTVIEW.PRODUCT.NO-RESULT' | translate}}</td></tr>
                                </tbody>
                            </ng-template>
                        </table>
                    </div>
    
                    <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doProductPage($event)" #productPagerComponent></t4-pager>
                </div>
                
            </div>

            <div class="col-lg-6">
                <div class="card order-height h-100" style="min-height: 450px;">
                    <div class="card-header">
                        <h6>{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.HEADER.SELECT-A-VARIANT' | translate}}</h6>
                    </div>
                    <div class="mx-2 mt-2" *ngIf="listVariants.length > 0; else noresultvariant">
                        <table class="table table-striped">
                            <ng-container >
                                <tbody>
                                    <tr *ngFor="let e of listVariants" class="list-item" [class.clickable]="e.AvailableCount !== 0"  (click)="toggleVariantSelection(e)">
                                        <td style="vertical-align: middle; width: 40px;"><input type="checkbox" [checked]="isVariantSelected(e)" [disabled]="e.AvailableCount === 0" /> </td>
                                        <td>
                                          <a class="text-dark">{{e.Name | translate}}</a>
                                          <span class=" d-block" style="max-width:160px;">{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.LISTVIEW.VARIANT.STOCK' | translate : {stockCount: e.AvailableCount} }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-container>
    
                            
                        </table>
                    </div>

                    <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doVariantPage($event)" #variantPagerComponent></t4-pager>

                    <ng-template #noresultvariant>
                        <div class="p-5">{{'SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.MSG.SELECT-PRODUCT-TO-SHOW-VARIANTS' | translate}}</div>
                    </ng-template>

                    <div class="row">
                        <div class="col-lg-6"></div>
                        <div class="col-lg-6">
                            <div class="m-2">
                                <button class="px-0 btn btn-primary btn-block btn-small" (click)="doAction()" [disabled]="!canSubmit()">
                                    <i class="fas fa-cart-plus"></i>
                                    {{'GENERIC.BUTTON.ADD-TO-ORDER' | translate}}
                                  </button>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    

</form>