import { Component, OnInit } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../shared/api';

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit {

  orderId: number = -1;
  order: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apploader: HttpLoaderService,
    private orderService: OrderService
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: any) => {

      if (params.id) {

        this.orderId = +params.id;
        this.loadOrder(this.orderId);
      }
    });
  }

  loadOrder(orderId: number) {

    //first fetch all details
    this.apploader.open();

    this.orderService.getOrderDetails(orderId).subscribe((details:any) => {
      //received the order
      this.order = details;
      this.apploader.close();
    });
  }

}
