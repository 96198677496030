import { Deserializable } from  '../models/deserializable'
import { ApplicationModule } from './app-module';

export class AppConfig implements Deserializable <AppConfig> {
    
    ActiveModules: Array<ApplicationModule>;
    
    deserialize(input: any): AppConfig {
      Object.assign(this, input);
      return this;
    }
  }