import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Tab } from '@tymes4-shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-venue-renderer',
  templateUrl: './venue-renderer.component.html',
  styleUrls: ['./venue-renderer.component.scss']
})
export class VenueRendererComponent {

  @ViewChild('svgContainer') svgContainer: ElementRef;

  @Output() onSectionClicked: EventEmitter<any> = new EventEmitter();

  sections = [];
  hasMatches = false;

  constructor(private router: Router) {

  }

  addInternalIds(sections) {

    let i = 1;
    this.sections = sections;


    for (const s of sections) {
      s.InternalId = i;
      i++;
    }
  }

  handleClick(e, changed) {

    if (changed) {

    } else {
      const g = $(e.target).closest('g[data-id]');

      if (g) {

        const internalId = +g.data('id');

        const filteredSections = this.sections.filter(function (s) {
          if (s.InternalId === internalId)
            return s;
        });

        if (filteredSections.length > 0) {
          const foundSection = filteredSections[0];
          this.onSectionClicked.emit(foundSection);
        }
      }
    }
  }

  getMergedSVG(basePlan, sectionAvailability) {

    //assume no matches
    this.hasMatches = false;
    let addedVbbIds: number[] = [];
    let sectionsSvg = '';

    for (const section of sectionAvailability) {

      const svg = section.SectionSVG;

      if (svg) {

        var alreadyAdded = addedVbbIds.filter(function (id) { if (id == section.VenueBuildingBlockId) return id; });

        if (alreadyAdded.length == 0) {
          addedVbbIds.push(section.VenueBuildingBlockId);
          this.hasMatches = true;
          const additionalClasses = (!section.HasTicketsAvailable ? 'unavailable' : '');
          sectionsSvg += `<g class='section ${additionalClasses}' data-id='${section.InternalId}'>${svg}</g>`;
        }

      }
    }

    return basePlan.replace('<!-- PLACEHOLDER_FOR_SECTIONS -->', sectionsSvg);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeMap();
  }

  public resizeMap() {
    $('.baseStadion').width($('.map-holder').width() - 40);
    $('.baseStadion').height('');
    $('.baseStadion').css('max-height', $('.map-holder').height() - 40);




  }

  public setVenuePlan(basePlan, availability) : boolean {

    this.addInternalIds(availability);

    this.svgContainer.nativeElement.innerHTML = this.getMergedSVG(basePlan, availability);

    // Safari does not know relative urls in SVGs, we need to manually update the stylerule to be absolute.
    const referencesToFix = ['cls-3', 'cls-41'];
    for (const ref of referencesToFix) {
      const elements = this.svgContainer.nativeElement.getElementsByClassName(ref);
      for (const el of elements) {
        let fill = getComputedStyle(el).fill;
        if (fill.startsWith(`url("#`)) {
          fill = `url('${this.router.url}${fill.substring(5, fill.length - 2)}')`;
          el.style.fill = fill;
        }
      }
    }

    return this.hasMatches;
  }

}
