import { IdentificationStateEnum } from "../api";

export class IdentificationStatus {
  public CustomerId: number;
  public UserId: number;
  public Status: EIdentificationStates;
  public CustomerIdentificationType: EIdentificationTypes;
  public Reason: string;
}

export enum EIdentificationStates {
  NotStarted = 0,
  PendingCheck = 1,
  CheckStarted = 2,
  Verified = 3,
  Rejected = 4
}
export enum IdentificationTypeEnum {
  InternalBO = 0,
  Internal = 1,
  Idin = 2,
  FastId = 3
}

export enum EIdentificationTypes {
  Internal = "Internal",
  Idin = "Idin",
  FastId = "FastId"
}

export const IdentificationStatesTextMap = {
  [IdentificationStateEnum.NotStarted]: 'RELATIONS.GENERIC.IDENTIFICATION-STATUS.REQUIRED',
  [IdentificationStateEnum.PendingCheck]: 'RELATIONS.GENERIC.IDENTIFICATION-STATUS.WAITING-FOR-VERIFICATION',
  [IdentificationStateEnum.BeingChecked]: 'RELATIONS.GENERIC.IDENTIFICATION-STATUS.BEING-VERIFIED',
  [IdentificationStateEnum.Verified]: 'RELATIONS.GENERIC.IDENTIFICATION-STATUS.VERIFIED',
  [IdentificationStateEnum.Rejected]: 'RELATIONS.GENERIC.IDENTIFICATION-STATUS.DENIED'
};

export const IdentificationTypesTextMap = {
  [IdentificationTypeEnum.InternalBO]: 'RELATIONS.GENERIC.IDENTIFICATION-TYPE.INTERNAL',
  [IdentificationTypeEnum.Internal]: 'RELATIONS.GENERIC.IDENTIFICATION-TYPE.INTERNAL',
  [IdentificationTypeEnum.Idin]: 'RELATIONS.GENERIC.IDENTIFICATION-TYPE.IDIN',
  [IdentificationTypeEnum.FastId]: 'RELATIONS.GENERIC.IDENTIFICATION-TYPE.FASTID'
};
