<!-- MODAL HEADER-->
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>

  

  <form class="form-content">
    
        <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, false, null, null, true)"></div> </div>
            <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.FREE-POSITION' | translate}}</p></div>
        </div>
        <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, true, null, null, true)"></div> </div>
            <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.SOLD' | translate}}</p></div>
        </div>
        <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, false, null, '#8a0481', true)"></div> </div>
            <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.RESERVED' | translate}}</p></div>
        </div>
        <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, false, 1, null, false)"></div> </div>
            <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.NOT-AVAILABLE' | translate}}</p></div>
        </div>

        <ng-container>
            <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
                <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, false, 1, null, true)"></div> </div>
                <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.CONTINGENT-AVAILABLE' | translate}}</p></div>
            </div>
            <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
                <div fxFlex="40px" class="spr"><div class="sp-h" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, true, 1, null, true)"></div> </div>
                <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.CONTINGENT-SOLD' | translate}}</p></div>
            </div>
        
        </ng-container>
        <div class='legend-row' fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex="40px" class="spr"><div class="sp-h s-rv" [style]="seatPlanHelper.getArtifactStyle(contingents, 1, false, null, null, true)"></div> </div>
            <div fxFlex><p>{{'SALES.ORDERS.NEW.SEATPLAN.LEGEND.RESTRICTION' | translate}}</p></div>
        </div>

  </form>