<div *ngIf="operation !== null" [class]="determineCssClass()">
  <div class="row row-sm">
    <div class="col-7 mt-1">{{operation.Message}}</div>

    <div class="col-5 mg-b-10">
      <button class="btn btn-block btn-secondary btn-small" *ngIf="operation.IsCancelable === true" (click)="cancel()">
        <i class="fas fa-ban"></i>
        <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
      </button>
    </div>

  </div>

  <ng-container *ngIf="operation.OperationStatusId <= 3 && operation.ProgressPercentage !== null">
    <div class="mt-3">
      <app-progress-bar [progress]="operation.ProgressPercentage"></app-progress-bar>
    </div>
  </ng-container>
</div>

