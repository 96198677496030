import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ConstService } from 'app/shared/services/const.service';
import { LinkPricelistComponent } from 'app/shared/components/link-pricelist/link-pricelist.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-edit-linked-pricelists',
  templateUrl: './edit-linked-pricelists.component.html'
})
export class EditLinkedPriceListsComponent implements OnInit {

  public form: FormGroup;

  eventDetails = null;
  eventId = null;

  private isNew = false;
  public saving = false;
  public isInArchiveMode = this.authService.isInArchiveMode();

  @ViewChild('pricelistComponent') public pricelistComponent: LinkPricelistComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef:  MatDialogRef<EditLinkedPriceListsComponent>,
              public constants: ConstService,
              private loader: HttpLoaderService,
              private authService: AuthService) { }



  ngOnInit() {
    this.eventId = this.passedData.payload.Id;
    this.eventDetails = this.passedData.payload;

  }

  submit() {
    const linkedLists = this.pricelistComponent.linkedLists;
    this.dialogRef.close(linkedLists)
  }
}
