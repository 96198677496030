<!-- MODAL HEADER-->
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
  
  
  
  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">
  
          <div class="col-lg-12">
  
            <div class="form-group">
              <label class="form-control-label">{{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.LABEL.TAG' | translate}}</label>
              <ng-select [items]="selectableTags" bindLabel="Name" bindValue="Id" placeholder="{{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.PLACEHOLDER.TAG' | translate}}" formControlName="Tag"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="Tag" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
  
          <div class="col-lg-12">
  
            <div class="form-group">
              <label class="form-control-label d-block">{{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.LABEL.TYPE' | translate}}<span class="tx-danger">*</span></label>
  
              <div class="form-check mr-3 mb-3 d-inline-block">
                <input class="form-check-input" type="radio" id="IsReplaced" formControlName="IsReplaced" value="0" checked="true">
                <label class="form-check-label">
                  {{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.LABEL.TYPE.ADD' | translate}}
                </label>
              </div>
  
              <div class="form-check mr-3 mb-3 d-inline-block">
                <input class="form-check-input" type="radio" id="IsReplaced" formControlName="IsReplaced" value="1" checked="true">
                <label class="form-check-label">
                  {{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.LABEL.TYPE.REPLACE' | translate}}
                </label>
              </div>
  
            </div>
  
  
          </div>
  
  
  
          <div class="col-lg-12">
            <div class="form-group">
                <label class="form-control-label">{{'FANS.TAGS.UPLOAD-CUSTOMERLOYALTYTAG-IMPORTFILE.LABEL.FILE' | translate}}<span class="tx-danger">*</span></label>
                <input #file type="file" class="btn-small btn-default"/>
            </div>
          </div>
  
  
        </div><!-- row -->
  
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="uploadTagFile(file.files);" [disabled]="!form.valid"  class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
  </form>