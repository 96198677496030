<app-page-title title="Configuratie instellingen"></app-page-title>

<t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

  <ng-container *t4ListActions>
    <button (click)="actionClick('add')" class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
  </ng-container>

  <ng-container *t4ListItem="let item">
    <th scope="row">{{item.Key}}</th>
    <td style="overflow:hidden;" data-toggle="tooltip" data-placement="bottom" title="{{item.Value}}" *ngIf="!item.Sensitive">{{item.Value}}</td>
    <td style="overflow:hidden;" data-toggle="tooltip" data-placement="bottom" title="{{'SYSTEMSETTINGS.SENSITIVE-INFORMATION' | translate}}" *ngIf="item.Sensitive">{{ 'SYSTEMSETTINGS.SENSITIVE-INFORMATION' | translate }}</td>
    <td class="grid-action" >
      <button (click)="actionClick('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
      <button (click)="actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
    </td>
  </ng-container>

</t4-list-view>