import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { SystemEventHelper } from '../../helpers/system-event.helper';
import * as _moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
const moment = _moment;



@Component({
  selector: 'app-event-placement-details',
  templateUrl: './event-placement-details.component.html',
  styleUrls: ['./event-placement-details.component.scss']
})
export class EventPlacementDetailsComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EventPlacementDetailsComponent>,
    private loader: HttpLoaderService,
    public systemEventHelper: SystemEventHelper,
    public translate: TranslateService
  ) { }

  @ViewChild('pagerComponent') pagerComponent;

  public loading:boolean = false;
  public eventPlacementDetails;
  public pagedTrailItems = [];
  private lastUsedPage = null;

  ngOnInit() {
    this.eventPlacementDetails = this.passedData.payload;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setSearchAndPage(1);

    });
  }

  doPage(pageArgs) {
    this.setSearchAndPage(pageArgs.pageNr);
  }

  setSearchAndPage(pageNr: number) {
    this.lastUsedPage = pageNr;
      let length = this.eventPlacementDetails.TrailItems.length; //we need a copy
      this.pagerComponent.setObjectCount(length);
      this.pagedTrailItems = this.eventPlacementDetails.TrailItems.slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize);
      this.pagerComponent.setPage (pageNr);

  }

  getDetails(ti) {



    let details =[];

    if (ti.ReasonCode !== null) details.push(this.translate.instant('DIALOGS.EVENT-PLACEMENT-DETAILS.GET-DETAILS.REASON') + this.systemEventHelper.systemEventReasonToText(ti.ReasonCode));
    if (ti.OrderId !== null) details.push(this.translate.instant('DIALOGS.EVENT-PLACEMENT-DETAILS.GET-DETAILS.ORDER-NUMBER') + ti.OrderId);
    if (ti.PassePartoutName !== null) details.push(this.translate.instant('DIALOGS.EVENT-PLACEMENT-DETAILS.GET-DETAILS.PASSE-PARTOUT') + ti.PassePartoutName);
    if (ti.ReservationId) details.push(this.translate.instant('DIALOGS.EVENT-PLACEMENT-DETAILS.GET-DETAILS.RESERVATION-NUMBER') + ti.ReservationId);

    if (ti.Expires)  {
      var dte = moment(ti.Expires);
      details.push(this.translate.instant('DIALOGS.EVENT-PLACEMENT-DETAILS.GET-DETAILS.EXPIRES') + dte.format("DD-MM-YYYY, hh:mm:ss"));
    }


    return details;
  }

}
