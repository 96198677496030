import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  pageTitle = '';

  @Input()
  set title(t: string) {
    this.pageTitle = t;
  }

  constructor() { }

  ngOnInit() {
  }

}
