<!-- MODAL HEADER-->
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  
  
  
  <div class="section-wrapper">
  
    <div class="form-layout">
  
      <div class="row mt-5">
        <div class="col-lg-12">
  
          <div class="custom-file">
            <input #file type="file" class="custom-file-input" id="upload-translation"
                   (change)="uploadRepurchaseFile(file.files)" multiple>
            <label class="custom-file-label custom-file-label-primary" for="upload-translation">
             {{'GENERIC.BUTTON.SELECT.FILE' | translate}}
            </label>
          </div>
  
          <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
            {{progress}}%
          </span>
  
        </div>
      </div>
  
    </div>
  </div>
  