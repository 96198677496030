import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpLoaderService, NotificationCommunicationService} from '@tymes4-shared';
import { isObject } from 'ngx-bootstrap/chronos/utils/type-checks';
import { interval, of, Subscription } from 'rxjs';
import { switchMap, catchError, first } from 'rxjs/operators';
import {LongRunningOperationModel, LongRunningOperationService, LongRunningOperationTypeEnum} from '../../api';

@Component({
  selector: 't4-lro-button',
  templateUrl: './lro-button.component.html',
  styleUrls: ['./lro-button.component.scss']
})
export class LroButton implements OnInit {

  pollingObservable = null;
  busyPolling = false;
  pollInterval = 2500;

  constructor(
    private lroService: LongRunningOperationService,
    private notificationCommunicationService: NotificationCommunicationService) { }

  public cssClasses: string = 'btn btn-sm btn-block btn-primary mt-1'; //can be overridden via the cssClasses property
  public code: LongRunningOperationTypeEnum;
  public buttonText: string = '';
  public iconClasses: string = '';
  public isDisabled: boolean = false;

  public lro: LongRunningOperationModel = null;
  private pollingStarted = false;

  private notificationSubscription: Subscription;

  @Input('icon')
  set icon(i) {
   this.iconClasses = i;
  }

  @Input('text')
  set text(txt) {
   this.buttonText = txt;
  }

  @Input('lroTypeCode')
  set lroTypeCode(code) {
    this.code = code;
    this.refreshLroStatus();
  }

  @Input('cssClasses')
  set setCssClasses(classes) {
    this.cssClasses = classes;
  }

  @Input('isDisabled')
  set setDisabled(disabled) {
    this.isDisabled = disabled;
  }

  @Output('onLROClick')
  public onLROClick : EventEmitter<null> = new EventEmitter();

  @Output('onLROComplete')
  public onLROComplete : EventEmitter<null> = new EventEmitter();

  refreshLroStatus() {
    if (this.code === undefined) {
      return;
    }

    this.lroService.getLatestLroByTypeCodeEnum(this.code)
    .pipe(first())
    .subscribe(lro => {

      this.lro = lro;

      if (lro !== null && lro.IsPending === true) {
        this.initializePolling();
      }
      else {
        this.cancelPolling();
      }
    });
  }

  emitClick(e) {
    this.onLROClick.emit();
    //FDP: this method is just here to prevent confusion.
    //the click is not manually emitted, but handled by angular itself
    //every component has a click event by default.
  }

  public isPending() : boolean  {
    if (this.lro == null) return false;
    return this.lro.IsPending;
  }


  ngOnInit() {
    this.notificationSubscription = this.notificationCommunicationService.$notificationUpdateRequired.subscribe(n => { this.refreshLroStatus() })
  }

  ngOnDestroy(): void {
    if (this.pollingObservable !== null) {
      this.pollingObservable.unsubscribe();
    }

    this.notificationSubscription.unsubscribe();
  }

  cancel() {
    this.lroService.cancelLongRunningOperation(this.lro.Id).subscribe(data => {

    });
  }

  cancelPolling(): any {
    
  }

  initializePolling(): any {
    this.pollingStarted = true;

    this.pollingObservable = interval(this.pollInterval).pipe(switchMap((ev) => this.lroService.getLongRunningOperation(this.lro.Id).pipe(
      catchError(error => {
        //An error in polling shouldn't stop the polling
        return of(null);
      })
    )))
        .subscribe(lro => {
          if (lro != null)
          {
            this.lro = lro;
            if (this.lro.IsPending === false) {
              this.pollingObservable.unsubscribe();
              this.onLROComplete.emit();
              this.cancelPolling();
            } 
          }
        });
  }


}
