import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import {ConfirmService} from '@tymes4-shared';
import * as moment from 'moment';
import { CreateInvoiceBatchArgs, EventService, InvoiceBatchService, MembershipService, PassePartoutService } from '../../api';

@Component({
  selector: 'app-create-invoice-batch',
  templateUrl: './create-invoice-batch.component.html' 
})
export class CreateInvoiceBatchComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CreateInvoiceBatchComponent>,
    private confirmService: ConfirmService,
    private eventService: EventService,
    private passePartoutService: PassePartoutService,
    private membershipService: MembershipService,
    private invoiceBatchService: InvoiceBatchService,
    private loader: HttpLoaderService,
    private translate: TranslateService
  ) { }

  public form: FormGroup;
  public saving = false;

  public selectableEvents: Array<any> = [];
  public selectablePassePartout: Array<any> = [];
  public selectableMembership: Array<any> = [];

  ngOnInit() {
    this.loader.open();

    this.form = new FormGroup({
      eventIds: new FormControl(null),
      passePartoutIds: new FormControl(null),
      membershipIds: new FormControl(null)
    });

    let call0 = this.eventService.eventSearch('', 1, 9999999, 'StartDateTime', true);;
    let call1 = this.passePartoutService.getCurrentFuturePassePartouts();
    let call2 = this.membershipService.listAllMemberships();

    forkJoin([call0, call1, call2]).subscribe(data => {
      let events = (data[0] as any).Records as Array<any>;
      events.unshift({ Id: -1, Name: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.DROPDOWN.EVENTS.ALL') });
      this.selectableEvents = events;

      let passepartouts = data[1] as Array<any>;
      passepartouts.unshift({ Id: -1, Code: '', Name: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.DROPDOWN.PASSE-PARTOUTS.ALL') });
      this.selectablePassePartout = passepartouts;

      let memberships = data[2] as Array<any>;
      memberships.unshift({ Id: -1, Name: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.DROPDOWN.MEMBERSHIPS.ALL') });
      this.selectableMembership = memberships;

      this.loader.close();
    });
  }

  formInvalid() {
    const eventIdsFilled = (this.form.value.eventIds != null && this.form.value.eventIds.length > 0) ? 1 : 0;
    const passePartoutIdsFilled = (this.form.value.passePartoutIds != null && this.form.value.passePartoutIds.length > 0) ? 1 : 0;
    const membershipIdsFilled = (this.form.value.membershipIds != null && this.form.value.membershipIds.length > 0) ? 1 : 0;

    return (eventIdsFilled + passePartoutIdsFilled + membershipIdsFilled) != 1;
  }

  doCreate() {
    let o = this.form.value;

    if(!o.eventIds || o.eventIds.length === 0) o.eventIds = null;
    if(!o.passePartoutIds || o.passePartoutIds.length === 0) o.passePartoutIds = null;
    if(!o.membershipIds || o.membershipIds.length === 0) o.membershipIds = null;

    var args : CreateInvoiceBatchArgs = {
      EventIds: o.eventIds,
      PassePartoutIds: o.passePartoutIds,
      MembershipIds: o.membershipIds
    };

    this.invoiceBatchService.existingOpenInvoiceBatch(args).subscribe((exists:any) => {
      if (exists === true) {
        let title = this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.CONFIRM.HAS-EXISTING.TITLE');
        if (o.eventIds) {
          this.confirmService.confirm({ title: title, message: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.CONFIRM.HAS-EXISTING.EVENT.MSG'), okonly: true }).subscribe(confirmed => {});
        }

        if (o.passePartoutIds) {
          this.confirmService.confirm({ title: title, message: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.CONFIRM.HAS-EXISTING.PASSE-PARTOUT.MSG'), okonly: true }).subscribe(confirmed => {});
        }

        if (o.membershipIds) {
          this.confirmService.confirm({ title: title, message: this.translate.instant('SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.CONFIRM.HAS-EXISTING.MEMBERSHIP.MSG'), okonly: true }).subscribe(confirmed => {});
        }
      }
      else {
        this.saving = true;
        this.dialogRef.close(o);
      }
    });
  }
}
