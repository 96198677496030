import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import PerfectScrollbar from 'perfect-scrollbar';
import { ApplicationModuleHelperService, ApplicationModules } from '../../services/application-module.service';

@Component({
  selector: 'app-add-positions',
  templateUrl: './add-positions.component.html',
  styleUrls: ['./add-positions.component.scss']
})
export class AddPositionsComponent implements OnInit, AfterViewInit {

  public saving = false;
  private sidebarPS: PerfectScrollbar;
  private selectedPositions = null;

  public selectedTab: string = 'E';
  public showMerchandiseTab: boolean = false;

  @ViewChild('scrollArea') scrollArea;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<AddPositionsComponent>,
    private loader:HttpLoaderService,
    private applicationModuleHelperService: ApplicationModuleHelperService
  ) { }




  ngOnInit() {


    //the customer and pendingorder will need to be passed

    this.selectedPositions = this.passedData.selectedPositions;

    this.applicationModuleHelperService.getActiveModules().subscribe((module) => {
      this.showMerchandiseTab = (module && module.includes(ApplicationModules.Merchandise));
    })
  }

  onTabChange(e) {
    this.selectedTab = e.selectedTab.id;
  }

  ngAfterViewInit() {


    setTimeout(() => {

      var availableHeight = +window.innerHeight - 150;
      this.scrollArea.nativeElement.style.maxHeight  = availableHeight + 'px';

      this.sidebarPS = new PerfectScrollbar('#scroll-area', {
        suppressScrollX: true
      });
    })
  }
  ngOnDestroy() {
    if(this.sidebarPS) {
      this.sidebarPS.destroy();
    }
  }

  submit() {
    this.dialogRef.close(true);
  }

  getOrderLineSelection() {


    return this.selectedPositions;
    //if (this.sale .state == null || this.state.order == null) return null;
    //return this.state.order.OrderLines;
  }

  sectionOrderLinesChanges(e)
  {

    if (e === false) {
      
    }
    else {
      
      this.dialogRef.close(e);
    }
  }


}
