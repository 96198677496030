import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityTypeEnum } from 'app/shared/enums/activity-type.enum';
import { KeyValuePair } from 'app/shared/models/key-value-pair';
import { HttpLoaderService } from '@tymes4-shared';
import {TranslateService} from '@ngx-translate/core'
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { CustomerSelectorComponent } from '../../components/customer/customer-selector/customer-selector.component';
import {DiscountService} from "../../api";

@Component({
    selector: 'app-discount-code-form',
    templateUrl: './edit-discount-code-form.component.html'
  })
  export class EditDiscountCodeFormComponent implements OnInit {
    @ViewChild('activityLog') public activityLog;
    public activityLogType = ActivityTypeEnum.DiscountCode;

    public form: FormGroup;

    customersLoading = false;
    customers: Observable<unknown>;
    customersInput$ = new Subject<string>();

    public saving = false;

    private editObject: any;

    validationMessages = {
    };

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditDiscountCodeFormComponent>,
    private translate: TranslateService,
    private discountService: DiscountService,
    private loader: HttpLoaderService
    ) { }

    @ViewChild('customerSelector') customerSelectorComponent: CustomerSelectorComponent;

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {
      if (this.requiredFields === null) {
        this.requiredFields = {};
      }

      if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
        const form = targetForm ? targetForm : this.form;
        const formField = form.get(field);
        if (!formField.validator) {
          return false;
        }

        const validator = formField.validator({} as AbstractControl);
        this.requiredFields[field] = validator && validator.required;

        formField.validator(formField);
      }
      return this.requiredFields[field];
    }

    ngOnInit() {
        this.editObject = this.passedData.payload;

        const customerCtrl = new FormControl('');

        this.form = new FormGroup({
          OriginalAmount: new FormControl({value: ''}),
          CustomerId: customerCtrl
        })

        if (this.editObject != null && this.editObject.CustomerId) {
          this.loader.open();

          this.customerSelectorComponent.setCustomer(this.editObject.CustomerId);

          this.loader.close();
         }

        this.form.patchValue(this.editObject);
    }

    customerSelected() {
        this.form.controls['CustomerId'].setValue(0);
    }

    submit() {
      this.loader.open();

      if(this.customerSelectorComponent.selectedCustomer)
        this.form.controls['CustomerId'].setValue(this.customerSelectorComponent.selectedCustomer.Id);

      this.discountService.updateDiscountDiscountCode(this.editObject.Id, this.form.value).subscribe((resp) => {
        this.dialogRef.close(true);
        this.loader.close();
      })
    }

    showActivityHistory() {
      this.activityLog.showHistory();
    }
  }
