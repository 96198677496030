import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core'
import { GenerateDiscountCodesRequestModel } from '../../api';

@Component({
    selector: 'app-generate-discount-codes',
    templateUrl: './generate-discount-codes.component.html'
  })
  export class GenerateDiscountCodesComponent implements OnInit {

    validationMessages = [];

      public form: FormGroup;

      public genericCodeTypes: any = [];

      constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef: MatDialogRef<GenerateDiscountCodesComponent>,
      private translate: TranslateService) { }

      ngOnInit() {
        this.form = new FormGroup({
          Count: new FormControl('', [Validators.required, Validators.min(0)]),
          CodeAmountPerc: new FormControl(null),
        });
     }

      submit() {
        let result : GenerateDiscountCodesRequestModel = {
            Count: this.form.controls.Count.value,
            CodeAmountPerc: this.form.controls.CodeAmountPerc.value
        }

        this.dialogRef.close(result);
      }
}
