import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { PromotionService } from 'app/shared/services/http/promotion.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promotion-form',
  templateUrl: './edit-promotion-form.component.html'
})
export class EditPromotionFormComponent implements OnInit {
  public form: FormGroup;

  public saving = false;

  private isNew: boolean;
  private editObject: any;

  public promotionTypes: Array<any> = [
    {Name: this.translate.instant('DIALOGS.EDIT-PROMOTION.FORM.PROMOTION-TYPE.FREE-TICKETS'), Id: 1},
    {Name: this.translate.instant('DIALOGS.EDIT-PROMOTION.FORM.PROMOTION-TYPE.DISCOUNT-AMOUNT'), Id: 2},
    {Name: this.translate.instant('DIALOGS.EDIT-PROMOTION.FORM.PROMOTION-TYPE.DISCOUNT-PERCENTAGE'), Id: 3},
  ];

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public dialogRef:  MatDialogRef<EditPromotionFormComponent>,
  private promotionService: PromotionService,
  private loader: HttpLoaderService,
  private translate: TranslateService) { }

  ngOnInit() {
    this.isNew = this.passedData.isNew;
    this.editObject = this.passedData.payload;

    this.form = new FormGroup({
      Id: new FormControl(''),
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Description: new FormControl(''),
      PromotionType: new FormControl('', [Validators.required]),
      PromotionAmount: new FormControl('', [Validators.required]),
      Active: new FormControl(''),
    });

    if (!this.isNew) {
      setTimeout(() => {
        this.form.patchValue(this.editObject);
      }, 0);
    }
  }

  submit() {
    const propertyObject = this.form.value;
    this.saving = true;
    this.loader.open();

    // store the object and return the id
    if (this.isNew) {
      this.promotionService.create(propertyObject).subscribe((id: number) => {
        // close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    } else {
      this.promotionService.update(propertyObject).subscribe((id: any) => {
        this.dialogRef.close(true);
        this.loader.close();
      });
    }
  }
}
