<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>
 <form [formGroup]="form">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{ 'DIALOGS.MEMBERSHIP.RENEWAL.EXPIRATION-DATE' | translate }} <span class="tx-danger">*</span></label>
            <ng-select [items]="this.constTimeInterval" bindLabel="Name" bindValue="Id" formControlName="TimeInterval" placeholder="{{ 'DIALOGS.MEMBERSHIP.RENEWAL.EXPIRATION-DATE' | translate }}"
                        [clearable]="false" notFoundText="Geen items gevonden" appendTo="body"></ng-select>
            <t4-display-validation controlName="TimeInterval" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
  
        <div class="col-lg-6" *ngIf="isTimeIntervalCustom()">
          <div class="form-group">
            <label class="form-control-label">{{ 'DIALOGS.MEMBERSHIP.RENEWAL.START-DATE' | translate }} <span class="tx-danger">*</span></label>
            <input class="form-control" datepicker formControlName="TimeIntervalStart">
            <t4-display-validation controlName="TimeIntervalStart" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
  
        <div class="col-lg-6" *ngIf="isTimeIntervalCustom()">
          <div class="form-group">
            <label class="form-control-label">{{ 'DIALOGS.MEMBERSHIP.RENEWAL.END-DATE' | translate }} <span class="tx-danger">*</span></label>
            <input class="form-control" datepicker formControlName="TimeIntervalEnd">
            <t4-display-validation controlName="TimeIntervalEnd" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
    </div>
  </div>
  </div>
</form>
<div class="form-layout-footer row">
  <div class="col-6">
    <button (click)="submit()" [disabled]="!form.valid" class="btn btn-block btn-primary bd-0"><i
      class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close()" class="btn btn-block btn-secondary bd-0"><i
      class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
  </div>

</div>

