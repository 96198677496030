import { HostListener, Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({ selector: '[fill-height]' })

export class FillHeightDirective implements AfterViewInit {

	@Input() footerElement = null;
	constructor(private el: ElementRef) {
	}

	ngAfterViewInit(): void {
		this.calculateAndSetElementHeight();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.el.nativeElement.style.height = '1px';
		this.calculateAndSetElementHeight();
	}

	private calculateAndSetElementHeight() {

		
		//const elementMarginBottom = this.el.nativeElement.style.marginBottom;
		//const footerElementMargin = this.getfooterElementMargin();
		setTimeout(() => {

			this.el.nativeElement.style.overflow = 'auto';
		
			const totalAvailableHeight = this.getFooterTop(); // document.documentElement.scrollHeight;
			const elementOffsetTop = this.getElementOffsetTop();
			const marginToFooter = 10;
			const totalElementHeight = totalAvailableHeight - elementOffsetTop - marginToFooter;
			this.el.nativeElement.style.height = totalElementHeight + 'px' //windowHeight - footerElementMargin - elementOffsetTop + 'px';	
			
		}, 1);
		
		//console.log([windowHeight, elementOffsetTop, elementMarginBottom, footerElementMargin, this.el.nativeElement.style.height]);
	}

	private getElementOffsetTop() {
		return this.el.nativeElement.getBoundingClientRect().top;
	}

	private getFooterTop() {

		let footerElement = document.getElementById('page-footer');
		if (!footerElement || footerElement === null) return;
	 
		var footerTop = footerElement.getBoundingClientRect().top;
		
		return footerTop;
//		if (!this.footerElement) { return 0; }
		//const footerStyle = window.getComputedStyle(this.footerElement);
		//return parseInt(footerStyle.height, 10);
	}
} 