import {Component, Input, OnInit} from '@angular/core';
import {ActivityTypeDictionary, ActivityTypeEnum} from '../../enums/activity-type.enum';
import {ActivityLog} from '../../models/activity-log';
import {ActivityActionTypeDictionary, ActivityActionTypeEnum} from '../../enums/activity-action-type.enum';
import { MatDialog } from '@angular/material/dialog';
import {ActivityLogDialogComponent} from './dialog-activity-log/activity-log-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {ActivityLogService, ActivityLogView} from "../../api";


@Component({
  selector: 'app-activity-log',
  templateUrl: 'activity-log.component.html'
})

export class ActivityLogComponent implements OnInit {
  @Input()
  set resourceId(id: number) {
    this._resourceId = id;
   // this.refresh();
  }
  @Input()
  set activityType(type: ActivityTypeEnum) {
    this._activityType = type;
    //this.refresh();
  }
  @Input() limit = 1;
  @Input() inDialog = false;

  public _resourceId: number;
  public _activityType: ActivityTypeEnum;
  public isLoading = true;

  public activities: Array<ActivityLogView> = [];

  constructor(private activityLogService: ActivityLogService,
               private dialog: MatDialog,
               private translate: TranslateService ) {
  }

  ngOnInit() {

    this.refresh();
  }

  public refresh() {
    this.isLoading = true;

    if (this._activityType && this._resourceId) {
      this.activityLogService.getUserActivityLogs(this._activityType, this._resourceId, this.limit, 0).subscribe((resp) => {
        this.activities = resp;
        this.isLoading = false;
      });
    }
  }

  loadMore() {
    this.isLoading = true;
    if (this._activityType && this._resourceId) {
      this.activityLogService.getUserActivityLogs(this._activityType, this._resourceId, this.limit, this.activities.length).subscribe((resp) => {
        this.activities.push(...resp);
        this.isLoading = false;
      });
    }
  }

  userName(a: ActivityLog): string {
    return `${a.Firstname} ${a.Middlename} ${a.Lastname}`.replace('  ', ' ');
  }

  getActionIcon(a: ActivityLog): string {
    if (a.ActionType === ActivityActionTypeEnum.Create) {
      return 'far fa-plus-square';
    } else if (a.ActionType === ActivityActionTypeEnum.Delete) {
      return 'far fa-trash-alt';
    }
    return 'far fa-edit';
  }

  getActionDescription(a: ActivityLog): string {
    const activityTypeDict = ActivityTypeDictionary[a.ActivityType];

    const activityArticle = this.translate.instant(activityTypeDict.article);
    const activityName = this.translate.instant(activityTypeDict.name);

    if (ActivityActionTypeDictionary[a.ActionType]) {
      return this.translate.instant(ActivityActionTypeDictionary[a.ActionType], {resource : (activityArticle + ' ' + activityName)});
    }
    return `${activityArticle} ${activityName} ` + this.translate.instant('ACTIVITY.LOG.CHANGED');
  }

  public showHistory() {
    this.dialog.open(ActivityLogDialogComponent, {
      width: '720px',
      disableClose: true,
      data: {title: this.translate.instant('ACTIVITY.LOG.DIALOG.TITLE'), resourceId: this._resourceId, activityType: this._activityType}
    });
  }
}
