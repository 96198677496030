<form [formGroup]="form">

  <div class="card card-table p-2 order-height">


    <div class="row">
      <div class="col-xl-8 col-lg-12">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span (click)="updateFilter($event)" class="input-group-text bd-0"><i class="fas fa-search"></i></span>
          </div>
          <input (keydown.enter)="updateFilter($event)" formControlName="SearchText" class="form-control bd-0"  placeholder="{{'SALES.ORDERS.NEW.SALES-PASSEPARTOUT-SELECTION.LISTVIEW.SEARCH-BAR-TEXT.PLACEHOLDER' | translate}}" type="text">
        </div>
      </div>
      <div class="col-xl-4 col-lg-12  pt-1">
        <button [disabled]="selectedObject === null" (click)="clearSelection()" class="btn btn-small btn-block btn-primary border" type="button"><i class="fas fa-sync-alt"></i> {{'GENERIC.BUTTON.RESET' | translate}}</button>
      </div>
    </div>




    <div>

      <table class="table table-striped tx-13">

        <ng-container *ngIf="listObjects.length > 0; else noresult">

          <tbody>
            <tr *ngFor="let e of listObjects" class="clickable list-item" (click)="toggleSelection(e)">

              <td style="vertical-align: middle;"><input type="checkbox" [checked]="isItemSelected(e)" /> </td>

              <td>
                <a class="text-dark">{{e.Name}}</a>
                <span class=" d-block" style="max-width:160px;">{{'SALES.ORDERS.NEW.SALES-PASSEPARTOUT-SELECTION.LISTVIEW.EVENTCOUNT' | translate : {count: e.NrOfEvents} }}</span>
              </td>

            </tr>
          </tbody>

        </ng-container>

        <ng-template #noresult>
          <tbody>
            <tr><td>{{'SALES.ORDERS.NEW.SALES-PASSEPARTOUT-SELECTION.LISTVIEW.NORESULT' | translate}}</td></tr>
          </tbody>
        </ng-template>

      </table>

    </div><!-- table-responsive -->

    <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doPage($event)" #pagerComponent></t4-pager>

  </div>

</form>
