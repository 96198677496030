import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {LoggingService} from '@tymes4-shared';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EditUnplacedTicketsComponent } from '../../../dialogs/edit-unplaced-tickets/edit-unplaced-tickets.component';
import {ConfirmService} from '@tymes4-shared';
import { ChangeUnplacedContingentsComponent } from 'app/shared/dialogs/change-unplaced-contingents/change-unplaced-contingents.component';
import { TranslateService } from '@ngx-translate/core';
import { SystemConfigurationSettingsService, VenueBuildingBlockService } from '../../../api';

@Component({
  selector: 'app-unplaced-ticket-list',
  templateUrl: './unplaced-ticket-list.component.html',
  styleUrls: ['./unplaced-ticket-list.component.scss']
})
export class UnplacedTicketListComponent implements OnInit {

  constructor(private logging: LoggingService,
              private dialog: MatDialog,
              private confirmService: ConfirmService,
              private venue : VenueBuildingBlockService,
              private systemConfigurationSettingsService: SystemConfigurationSettingsService,
              private translate: TranslateService) { }

  @ViewChild('pagerComponent') pagerComponent;
  @ViewChild('searchComponent') searchComponent;

  selectedEndpoints = [];
  parentVBB;
  selectedTicketType;
  existingEndpoints = [];
  filteredEndpoints = [];
  priceCategories = [];
  searchText = "";
  eventPlacementEventId = null;
  showContingentEditButton = false;
  allowContingentEdit = false;

  @Output() onRefreshRequired = new EventEmitter();

  @Input()
  set eventId(eId: number) {
    this.eventPlacementEventId = eId;
  }

  ngOnInit() {
    this.venue.allowContingentsForUnplacedSections().subscribe(setting => {
      this.showContingentEditButton = setting;
    });
  }

  @Input()
  set parent(vbb) {
    this.parentVBB = vbb;

    if (this.eventPlacementEventId == null) {
      //vbb, if events are linked it is not possible to change contingents
      this.venue.getLinkedEvents(this.parentVBB.Id).subscribe((events: any) => {
        this.allowContingentEdit = (events == null || events.length == 0);
      });
    }
    else {
      this.allowContingentEdit = true;
    }

  }

  @Input()
  set endpoints(eps) {
    this.existingEndpoints = eps;
    this.filteredEndpoints = this.existingEndpoints;
  }

  @Input()
  set ticketType(tt) {
    this.selectedTicketType = tt;
  }

  delete(id){
    this.confirmService.confirm({title: this.translate.instant('GENERIC.CONFIRM.CONFIRM'),
      message: this.translate.instant('COMPONENTS.VENUE-DEFENITION.UNPLACED-TICKET-LIST.CONFIRM.WARNING.MESSAGE.DELETE-TICKET')})
    .subscribe((result) => {
      if (result) {
        this.venue.deleteVenueBuildingBlock(id).subscribe((data : any) => {
          //t/his.notifyRefreshRequired();
        });
      }
    });
  }

  manageContingents() {
    const title: string = this.translate.instant('COMPONENTS.VENUE-DEFENITION.UNPLACED-TICKET-LIST.DIALOG.TITLE.EDIT-QUOTAS', {parentVBBName: this.parentVBB.Name})
    const dialogRef: MatDialogRef<any> = this.dialog.open(ChangeUnplacedContingentsComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, totalPlaces: this.existingEndpoints.length, sectionId: this.parentVBB.Id, eventPlacementEventId: this.eventPlacementEventId, parentVBBHasPlacements: this.parentVBB.HasPlacements }
    })
    dialogRef.afterClosed()
      .subscribe(data => {
      }
    );
  }

  addOrUpdateTickets(idx, isNew, ep) {
    let data = null;

    //set the required data
    data = {};
    data.SelectedIdx = idx;
    data.ParentId = this.parentVBB.Id;
    data.TicketTypeId = this.selectedTicketType.Id;
    data.currentTicketAmount = this.existingEndpoints.length;

    let title:string = this.translate.instant('COMPONENTS.VENUE-DEFENITION.UNPLACED-TICKET-LIST.FORM.TITLE.TICKETS-FORM-TYPE', {selectedTicketTypeName: this.selectedTicketType.Name})

    if (isNew) title += this.translate.instant('COMPONENTS.VENUE-DEFENITION.UNPLACED-TICKET-LIST.FORM.TITLE.ADD')
    else title = this.translate.instant('COMPONENTS.VENUE-DEFENITION.UNPLACED-TICKET-LIST.FORM.TITLE.EDIT');


    let dialogRef: MatDialogRef<any> = this.dialog.open(EditUnplacedTicketsComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data, isNew: isNew }
    })
    dialogRef.afterClosed()
      .subscribe(data => {
        //Done, refresh the data.
        this.onRefreshRequired.emit();
      }
    )
  }
}
