import { TranslateService } from '@ngx-translate/core';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ListViewSelectionMode, PagedResult} from '@tymes4-shared';
import {HttpLoaderService} from '@tymes4-shared';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import { OrderService } from '../../api';

@Component({
  selector: 'app-print-order-hardcards',
  templateUrl: './print-order-hardcards.component.html'
})
export class PrintOrderHardcardsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<PrintOrderHardcardsComponent>,
    private orderService: OrderService,
    private confirmService: ConfirmService,
    private loader: HttpLoaderService,
    private snackBar: SnackbarService,
    private translate: TranslateService
  ) {
  }

  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 8,
    disableSort: true,
    renderInCard: false,
    selectionMode: ListViewSelectionMode.None,
    headers: [
      {sortField: null, label: '', width: 50, hideOn: null},
      {sortField: null, label: 'SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.HEADER.PRODUCT', width: 150, hideOn: null},
      {sortField: null, label: 'SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.HEADER.POSITION', width: 100, hideOn: null},
      {sortField: null, label: 'SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.HEADER.CUSTOMER', width: null, hideOn: null},
      {sortField: null, label: 'SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.HEADER.BARCODE', width: null, hideOn: null},
      {sortField: null, label: 'SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.HEADER.STATUS', width: null, hideOn: null},
    ]
  };


  public selectedStatusses = [];
  private allStatusses = null;
  public form: FormGroup;
  public saving = false;
  public orderId: number = null;

  ngOnInit() {

    this.orderId = this.passedData.payload.Id;

    this.loader.open();

    this.orderService.getHardcardStatus(this.orderId).subscribe((statusses: any) => {
      this.allStatusses = this.setCalculatedData(statusses);

      this.listVw.refreshData();
      this.loader.close();
    });

    //  let packingSlip = (this.dialogMode==='print');

    this.form = new FormGroup({
      eventId: new FormControl(null)
    });
  }

  setCalculatedData(statusses: any): any {

    this.selectedStatusses = [];

    for (let s of statusses) {
      if (s.Barcode === null || s.Barcode === '') s.Barcode = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.LISTVIEW.BARCODE-NEW');

      s.WarnForIdentification = false;

      switch (s.CreationStatus) {
        case 1:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.ACTIVE');
          s.AllowPrint = true;
          break;

        case 2:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.READYFORCREATION');
          s.AllowPrint = true;
          break;

        case 3:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.BLOCKED');
          s.AllowPrint = false;
          break;

        case 4:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.WAITINGFORPHOTO');
          s.AllowPrint = false;
          break;

        case 5:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.WAITINGFORIDENTIFICATION');
          s.AllowPrint = true;
          s.WarnForIdentification = true;
          break;

        case 6:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.NOTEMPLATELINKED');
          s.AllowPrint = false;
          break;

        case 7:
          s.StatusName = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-ORDER-HARDCARDS.STATUS.BLACKLISTED');
          s.AllowPrint = false;
          break;
      }

      if (s.AllowPrint === true) {
        s.Selected = true;
        this.selectedStatusses.push(s);
      }
    }

    return statusses;
  }


  update(hc, e) {

    const isChecked = e.target.checked;
    const idx = this.selectedStatusses.indexOf(hc);
    
    hc.Selected = e.target.checked;

    if (idx >= 0) {
      this.selectedStatusses.splice(idx, 1);
    }

    if (isChecked) {
      this.selectedStatusses.push(hc);
    }

  }

  onDataRequest(e) {

    if (this.allStatusses === null) return;

    var length = this.allStatusses.length;
    var paged = this.allStatusses.slice((e.pageNr - 1) * e.pageSize, (e.pageNr) * e.pageSize);

    const d : PagedResult =
    {
      TotalRecords: length,
      Records: paged,
      ResultCountCapped: false

    }

    this.listVw.Data = d;
  }


  submit() {
    var warnings = this.selectedStatusses.filter(function (hc) {
      if (hc.WarnForIdentification === true)
        return hc;
    });

    if (warnings.length > 0) {

      let msg = this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PRINT-ORDER-HARDCARDS.WARNING', {count: warnings.length});

      this.confirmService.confirm({title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PRINT-ORDER-HARDCARDS.WARNING.TITLE'), message: msg, okonly: false}).subscribe(confirmed => {
        if (confirmed) {
          this.print();
        }
      });

    } else {
      this.print();
    }
  }

  print() {
    const payload = {
      CustomerPassePartoutIds: this.selectedStatusses.map(hc => hc.CustomerPassePartoutId).filter(id => id !== null),
      CustomerMemberShipIds: this.selectedStatusses.map(hc => hc.CustomerMembershipId).filter(id => id !== null),
    };

    this.orderService.printHardcards(this.orderId, payload).subscribe(data => {
      this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PRINTJOB-CREATED'), 'GENERIC.SNACKBAR.CLOSE');
      this.dialogRef.close(false);
    });

  }

}
