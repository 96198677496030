import { TranslateService } from '@ngx-translate/core';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ListViewSelectionMode} from '@tymes4-shared';
import {CashRegisterAction} from 'app/shared/models/cache-register-state';
import {SnackbarService} from '@tymes4-shared';
import {PaymentComponent} from '../../dialogs/cash-register/payment/payment.component';
import {OrderDetailsComponent} from "../../dialogs/order-details/order-details.component";
import {SelectPendingorderDebtorComponent} from '../../dialogs/select-pendingorder-debtor/select-pendingorder-debtor.component';
import {HttpLoaderService} from '@tymes4-shared';
import { ApplicationModuleService, OrderService } from '../../api';
import { ApplicationModuleHelperService } from '../../services/application-module.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-order-overview-with-balance',
  templateUrl: './order-overview-with-balance.component.html',
  styleUrls: ['./order-overview-with-balance.component.scss']
})
export class OrderOverviewWithBalanceComponent implements OnInit {

  @Output() onUpdated = new EventEmitter<object>();
  @ViewChild('listVw') listVw;

  public readonly Actions = {
    NotSet: 0,
    OpenOrder: 1,
    FinishOrder: 2,
  };

  public selectedCustomers: any[] = null;
  public selectedOrders: any[] = [];
  public lastDataRequestEvent = null;
  public action: CashRegisterAction = CashRegisterAction.NotSet;
  public isIbaActive: boolean = false;
  public newOrderManagement: boolean = false;

  public listOptions = {
    search: true,
    showBarcodeSearch: false,
    pageSize: 12,
    selectionMode: ListViewSelectionMode.MultiSelect,
    multiSelectMode: true,
    headers: [
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.ORDER-NR', width: 70, hideOn: null},
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.DATE', width: 70, hideOn: null},
      {sortField: 'OrderDate', label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.TIME', width: 55, hideOn: null, isDefaultSort: 'desc'},

      {sortField: 'CustomerName', label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.CUSTOMER', width: 180, hideOn: null},
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.TOTAL', width: 100, hideOn: null, class: 'text-right'},
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.PAYED', width: 100, hideOn: null, class: 'text-right'},
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.IN-PROGRESS', width: 100, hideOn: null, class: 'text-right'},
      {sortField: null, label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.REMAINING', width: 110, hideOn: null, class: 'text-right'},

      {sortField: 'Reference', label: 'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.HEADER.REFERENCE', width: null, hideOn: null},
      {sortField: null, label: '', width: 146, hideOn: null},
      {sortField: null, label: '', width: 50}
    ]
  };

  constructor(private orderService: OrderService,
              private dialog: MatDialog,
              private snackBar: SnackbarService,
              private loader: HttpLoaderService,
              private translate: TranslateService,
              private applicationModuleService: ApplicationModuleService,
              private router: Router,
              private applicationModuleHelperService: ApplicationModuleHelperService) {
  }

  @Input()
  set SelectedCustomers(cs: any[]) {
    this.selectedCustomers = cs;
  }

  @Input()
  set Action(a: CashRegisterAction) {
    this.action = a;
  }

  ngOnInit() {
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA');
        this.newOrderManagement = activeModules.some((module) => module.toUpperCase() == 'NEWORDERMANAGEMENT');
      }
    })
  }

  openAllowed() {
    if (this.selectedOrders == null)
      return false;
    else if (this.selectedOrders.length !== 1)
      return false;

    return true;
  }

  // orderAllowed() {

  //   if (this.selectedOrders == null || this.selectedOrders.length === 0)
  //     return false;

  //   return true;

  // }

  actionClick(action) {
    if (action == this.Actions.FinishOrder) {
      this.showPaymentDialog();
    }
  }

  openDialogFromLine(orderId) {
    if (this.newOrderManagement) {
      this.router.navigate(['/sales/orders/details', orderId]);
    }
    else {
      this.loader.open();
  
      this.orderService.getOrderDetails(orderId).subscribe(data => {
        this.loader.close();
        this.openOrderDialog(data);
      });
    }
  }

  showPaymentDialog() {

    const uniqueCustomerIds = Array.from(new Set(this.selectedOrders.map(o => o.CustomerId)));
    const customersInSelection = [];
    for (const id of uniqueCustomerIds) {
      customersInSelection.push(this.selectedCustomers.find(c => c.Id === id));
    }
    const orderIds = this.selectedOrders.map(a => a.Id);

    if (uniqueCustomerIds.length > 1) {
      const selectDebtorDialogRef: MatDialogRef<any> = this.dialog.open(SelectPendingorderDebtorComponent, {
        width: '720px',
        disableClose: true,
        data: {title: this.translate.instant('SALES.CASH-REGISTER.ORDER-OVERVIEW.DIALOG.SELECT-DEBTOR.TITLE'), payload: customersInSelection}
      });
      selectDebtorDialogRef.afterClosed()
        .subscribe(selectedDebtorId => {
            if (selectedDebtorId) {
              // this.initiateOrder(selectedDebtorId, true);
              this.doShowPaymentDialog(selectedDebtorId, orderIds);
            }
          }
        );
    } else {
      this.doShowPaymentDialog(uniqueCustomerIds[0], orderIds);
    }

  }

  doShowPaymentDialog(bookingCustomerId, orderIds) {
    const title = this.translate.instant('SALES.CASH-REGISTER.ORDER-OVERVIEW.DIALOG.PAY-ORDERS.TITLE');
    const dialogRef: MatDialogRef<any> = this.dialog.open(PaymentComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: title, payload: orderIds, bookingCustomerId: bookingCustomerId}
    });

    dialogRef.afterClosed().subscribe(submitForm => {
      if (submitForm) {
        this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PAYMENT-HANDLED'), 'GENERIC.SNACKBAR.CLOSE');
      }
      this.OnDataRequest(this.lastDataRequestEvent);
      this.onUpdated.emit(submitForm);
    });
  }

  OnDataRequest($event) {
    this.lastDataRequestEvent = $event;

    this.orderService.searchForPayments(this.selectedCustomers.map(c => c.Id), $event.searchText, $event.pageNr, $event.pageSize, $event.sortField, $event.sortAsc, null, null).subscribe((resp: any) => {
      this.listVw.Data = resp;
    });
  }

  OnItemSelectionChange($event) {
    this.selectedOrders = $event;
  }

  openOrderDialog(order) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(OrderDetailsComponent, {
      width: '95%',
      maxWidth: '95%',
      disableClose: true,
      data: {
        title: this.translate.instant('SALES.CASH-REGISTER.ORDER-OVERVIEW.DIALOG.ORDER-DETAILS.TITLE'),
        isReadOnly: true,
        order: order,
        buttons: {
          [OrderDetailsComponent.buttons.Close]: true,
          [OrderDetailsComponent.buttons.DownloadETickets]: false,
          [OrderDetailsComponent.buttons.Edit]: false,
          [OrderDetailsComponent.buttons.PrintOrderConfirmation]: false,
          [OrderDetailsComponent.buttons.PrintTickets]: false,
          [OrderDetailsComponent.buttons.SendPaymentRequest]: false,
        }
      }
    })
    dialogRef.afterClosed()
      .subscribe(updatedOrder => {
          //nothing to do, dialog is read only
        }
      );
  }

  getActionTitle() {
    let titleString: string = '';

    switch (this.action) {
      case CashRegisterAction.SelectAnonymousUser:
        titleString = '';
        break;
      case CashRegisterAction.ExistingReservation:
        titleString = this.translate.instant('SALES.CASH-REGISTER.ACTION.PICKUP-RESERVATION');
        break;
      case CashRegisterAction.ExistingOrder:
        titleString = this.translate.instant('SALES.CASH-REGISTER.ACTION.PAY-ORDER');
        break;
      case CashRegisterAction.NewOrder:
        titleString = this.translate.instant('SALES.CASH-REGISTER.ACTION.NEW-ORDER');
        break;
    }

    titleString = titleString.toUpperCase();

    for (const customer of this.selectedCustomers) {
      if (customer.ContactFullName != undefined) {
        titleString += customer.ContactFullName + ", ";
      } else {
        titleString += "-, ";
      }
    }
    if (titleString.endsWith(', ')) {
      titleString = titleString.slice(0, -2);
    }

    return titleString;
  }

  constructCustomerIdentificationModel(order){
    return {
      CalculatedName : order.CalculatedName,
      IsIdentified: order.IsIdentified,
      CustomerNumber: order.CustomerNumber,
    }
  }
}
