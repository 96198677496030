import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {LoggingService} from '@tymes4-shared';
import { forkJoin } from 'rxjs';
import {ConfirmService} from '@tymes4-shared';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core'
import { DeliveryMethodService, EventService, EventTypeService, PassePartoutService, Event, DeliveryMethod, EventTypeViewPagedResult, PassePartout, EventTypeView } from '../../api';

@Component({
  selector: 'create-print-batch',
  templateUrl: './create-print-batch.component.html'
})
export class CreatePrintBatchComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CreatePrintBatchComponent>,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private passePartoutService: PassePartoutService,
    private loader: HttpLoaderService,
    private deliveryMethodService: DeliveryMethodService,
    private confirmService: ConfirmService,
    private logging : LoggingService,
    private translate: TranslateService
  ) { }

  public form: FormGroup;
  public saving = false;

  public deliveryMethods: Array<DeliveryMethod> = null;
  private defaultDeliveryMethodId = -1;

  selectableEvents: Array<Event> = [];
  eventTypes: Array<EventTypeView> = [];
  selectablePassePartout: Array<PassePartout> = [];

  ngOnInit() {

    this.loader.open();

    var call0 = this.eventService.getUpcomingEvents(); // (this.orderDetails.Id).subscribe((data:any) => {
    var call1 = this.deliveryMethodService.listAllDeliveryMethods();
    var call2 = this.eventTypeService.searchEventTypes('', 1, 9999999, 'DisplayName', true);
    var call3 = this.passePartoutService.getCurrentFuturePassePartouts();

    forkJoin([call0, call1, call2, call3]).subscribe(([selectableEvents, deliveryMethods, eventTypes, selectablePassePartout]: [Array<Event>, Array<DeliveryMethod>, EventTypeViewPagedResult, Array<PassePartout>]) => {
      this.selectableEvents = selectableEvents.filter(e => e.Id > 0);
      this.deliveryMethods = deliveryMethods.map(e => ({Name: this.translate.instant(e.TranslationCode), ...e}));
      this.eventTypes = eventTypes.Records;
      this.selectablePassePartout = selectablePassePartout.filter(e => e.Id > 0).map(e => ({text: `${e.Code} - ${e.Name}`, ...e}));

      this.selectableEvents.splice(0, 0, { Name: this.translate.instant('DIALOGS.CREATE-PRINT-BATCH.DROPDOWN.EVENT-TO-PRINT.ALL'), Id: -1});
      this.eventTypes.splice(0, 0, { DisplayName: this.translate.instant('DIALOGS.CREATE-PRINT-BATCH.DROPDOWN.EVENT-TYPE.ALL'), Id: -1});

      this.initForm();
      this.loader.close();
    });

  }

  initForm(): any {

     let methods = this.deliveryMethods.filter(function (d)   {
      if (d.IsPrintDefault === true) return d;
    });

    if (methods.length >= 1) this.defaultDeliveryMethodId = methods[0].Id;
    else this.defaultDeliveryMethodId = this.deliveryMethods[0].Id;

    this.form = new FormGroup({
      deliveryMethodId: new FormControl(this.defaultDeliveryMethodId),
      printMethodId: new FormControl({ value: 1, disabled: true }, [Validators.required]),
      packagingSlip: new FormControl(null, [Validators.required]),
      maxNrOfTickets: new FormControl(this.translate.instant('GENERIC.VALUE.100')),
      cutMode: new FormControl(null, [Validators.required]),
      eventId: new FormControl(-1),
      eventTypeId: new FormControl(-1),
      printFor: new FormControl('E'),
      passePartoutId: new FormControl(null)
    });

  }

  doDownload() {

    var o = this.form.value;

    if(o.eventId == -1) o.eventId = null;
    if(o.eventTypeId == -1) o.eventTypeId = null;
    if(!o.printMethodId) o.printMethodId = 1; // because it is disabled

    this.saving = true;
    this.dialogRef.close(o);
  }

}
