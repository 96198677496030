import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { Injectable } from "@angular/core";
import { AssignmentPolicyService } from '../../api';

@Injectable()
export class AssignmentPolicyValidator {
  constructor(private assignmentPolicyService: AssignmentPolicyService) { }

  public editingObject: any;

  assignmentPolicyCodeTaken = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve, reject) => {
      var v = control.value;
      setTimeout(() => {
        if (v === '') {
          resolve(null);
        }

        this.assignmentPolicyService.getAssignmentPolicyByCode(v).subscribe(data => {
          if (data !== null && data !== void 0) {
            if (data.Id != this.editingObject.Id) {
              resolve({ "codetaken": data });
            }
          }
          resolve(null);
        });
      }, 200);
    });
  }

  static ValidateDates = (group: FormGroup) => {

    if (group.controls.RevokeStartDate.dirty === true || group.controls.RevokeEndDate.dirty === true) {
      let startCheckDate = group.controls.RevokeStartDate.value;
      let endCheckDate = group.controls.RevokeEndDate.value;

      if (startCheckDate > endCheckDate) {
        group.controls.RevokeStartDate.setErrors({ startDateAfterEndDate: true }, { emitEvent: true });
        group.controls.RevokeEndDate.setErrors({ endDateBeforeStartDate: true }, { emitEvent: true });

        return ({ startDateAfterEndDate: true, endDateBeforeStartDate: true });
      }
      else {
        if (group.controls.RevokeStartDate.hasError('startDateAfterEndDate')) {
          group.controls.RevokeStartDate.setErrors(null, { emitEvent: true });
        }
        if (group.controls.RevokeEndDate.hasError('endDateBeforeStartDate')) {
          group.controls.RevokeEndDate.setErrors(null, { emitEvent: true });
        }
        return null;
      }
    }
  }

}