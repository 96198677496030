<div *ngIf="!this.isSalesPeriod && !this.isEvent && !this.isInitiative; else show">
  {{'EVENT.DETAILS.PURCHASE-RIGHT.IMPOSSIBLE.TITLE' | translate}}
</div>

<ng-template #show>

  <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListActions>

      <div class="object-actions bg-white text-right">
        <button (click)="actionClick('check')" class="btn btn-primary border btn-small" type="button"><i class="fa fa-address-card"></i>{{'GENERIC.BUTTON.CHECK' | translate}}</button>
        <button *ngIf="!isInArchiveMode" (click)="actionClick('add')" class="btn btn-primary border btn-small" type="button"><i class="far fa-edit"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
      </div>

    </ng-container>

    <ng-container *t4ListItem="let item">
      <th scope="row">{{item.Id}}</th>

      <td>{{ item.AvailableFrom | localDateTime }}</td>
      <td>{{ item.AvailableTill | localDateTime }}</td>
      <td *ngIf="isEvent">{{ item.SaleCategoryName }}</td>
      <td *ngIf="isSalesPeriod">{{ item.TargetPassePartoutName }}</td>
      <td *ngIf="isInitiative">{{ item.TargetEventName ? item.TargetEventName + ' (' + item.SaleCategoryName + ')' : item.TargetPassePartoutName }}</td>
      <td>{{ this.showRightsFor(item.TagName, item.OriginPassePartoutName) }}</td>
      <td>{{ item.SalesChannelName }}</td>
      <td>{{ this.showAmount(item.UnlimitedAmount, item.AmountPerTicket, item.AmountAbsolute) }}</td>
      <td><t4-list-value-boolean [value]="item.Transferable"> </t4-list-value-boolean></td>
      <td *ngIf="isSalesPeriod"><t4-list-value-boolean [value]="item.IsPrimary"> </t4-list-value-boolean></td>
      <td *ngIf="isSalesPeriod"><t4-list-value-boolean [value]="item.IsCrossSell"> </t4-list-value-boolean></td>

      <td class="grid-action" >
        <button (click)="this.actionClick('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
        <button *ngIf="!isInArchiveMode" (click)="this.actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
      </td>


    </ng-container>

  </t4-list-view>

</ng-template>
