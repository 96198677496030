import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { TagService } from "../api";
import { VerifyTagDetailArgs } from "../api/model/verifyTagDetailArgs";

@Injectable()
export class TagValidator {

    constructor(private tagService: TagService) {}

    private debouncer: any;
    public currentTag: any;

    verifyTagDetails = (propertyName: string, control: FormControl) => {

        //do not hammer the service
       // clearTimeout(this.debouncer);

        return new Promise(resolve => {

            this.debouncer = setTimeout(() => {

                if ((!control.dirty && control.value !== '') || this.currentTag === null ){
                   control.setErrors(null);
                   resolve(null);
                }
                else{
                    if (propertyName === 'Name' && (control.value === this.currentTag.Name || control.value === '')){
                        control.setErrors(null);
                        resolve(null);
                    }
                    else if (propertyName === 'Code' && (control.value === this.currentTag.Code || control.value === '')){
                        control.setErrors(null);
                        resolve(null);
                    }
                    else {
                        const args : VerifyTagDetailArgs = {
                            PropertyName: propertyName,
                            PropertyValue: control.value.trim()
                        }

                        this.tagService.verifyTagDetails(args).subscribe(exists=> {

                            if (propertyName === 'Name' && exists === true) {
                              control.setErrors({"nametaken": true});
                              resolve({"nametaken": exists});
                            }
                            else if (propertyName === 'Code' && exists === true) {
                                control.setErrors({"codetaken": true});
                                resolve({"codetaken": exists});
                            }
                            else {
                              control.setErrors(null);
                              resolve(null);
                            }
                        });
                    }
                }
            }, 1000);
        });
    }
}
