<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>



<!-- MODAL BODY -->

<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'LOCATION.VENUE-DEFINITION.EDIT-TICKETS-DIALOG.FORM.LABEL.ROWS' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="NrOfRows" formControlName="NrOfRows" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-TICKETS-DIALOG.FORM.PLACEHOLDER.ROWS' | translate}}" maxlength="64">
              <t4-display-validation controlName="NrOfRows" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.VENUE-DEFINITION.EDIT-TICKETS-DIALOG.FORM.LABEL.POSITIONS' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control" name="NrOfColumns" formControlName="NrOfColumns" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-TICKETS-DIALOG.FORM.PLACEHOLDER.POSITIONS' | translate}}" maxlength="64">
                    <t4-display-validation controlName="NrOfColumns" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
        </div>

        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>

