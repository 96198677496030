import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import { GenericErrorHandler } from 'app/shared/helpers/generic-error-handler';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TagService } from '../../api';


@Component({
  selector: 'app-upload-customertag-importfile',
  templateUrl: './upload-customertag-importfile.component.html'
})
export class UploadCustomerTagImportfileComponent implements OnInit {

  public selectableTags = null;
  public tags = [];
  public form: FormGroup;

  @ViewChild('file') fileInput;

  validationMessages = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<UploadCustomerTagImportfileComponent>,
    private tagService: TagService,
    private loader: HttpLoaderService,
    private confirmService: ConfirmService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      Tag: new FormControl(null, Validators.required),
      IsReplaced: new FormControl('0', Validators.required),
    });

    this.tagService.listAllTags().subscribe(resp => {
      this.loader.close();
      this.tags = resp;
      this.selectableTags = this.tags;
    });
  }

  uploadTagFile(files) {
    const formData = this.form.value;

    if (files === null || files === 'undefined' || !(files.length > 0)) {
      return;
    }

    const file = files[0];
    var blobArray : Array<Blob> = new Array<Blob>();
    blobArray.push(file);

    this.tagService.uploadTagFile(formData.Tag, formData.IsReplaced === "1", blobArray).subscribe(event => {

    this.loader.close();

    this.dialogRef.close(true);
    });
  }

  buildUploadErrorText(errors) {
    let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.FAILED') + '</b></div><br />';
    let errorCounter = 0;
    for (const e of errors) {
      if (errorCounter >= 5) {
        result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.ERRORS.COUNT', {errors : (errors.length - errorCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;${e}</span></div><br />`;
      errorCounter++;
    }
    return result;
  }

  buildUploadWarningText(warnings) {
    if (warnings.length === 1) {
      const resultok = '<div><b>' + warnings[0] + '</b></div>';
      return resultok;
    } else {
      let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.WARNINGS') + '</b></div><br />';
      let warningCounter = 0;
      for (const e of warnings) {

        if (warningCounter >= 5) {
          result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.WARNING.COUNT', {count: (warnings.length - warningCounter)}) + `</span></div><br />`;
          break;
        }

        result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;${e}</span></div><br />`;
        warningCounter++;

      }
      return result;
    }
  }
}
