<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.NAME' | translate}}<span class="tx-danger">*</span></label>
              <input
                class="form-control"
                name="Name"
                autocomplete="off"
                formControlName="Name"
                placeholder="{{'DIALOGS.EDIT-PROMOTION.FORM.PLACEHOLDER.NAME' | translate}}">
              <t4-display-validation controlName="Name" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.CODE' | translate}}<span class="tx-danger">*</span></label>
              <input
                class="form-control"
                trim
                name="Code"
                autocomplete="off"
                formControlName="Code"
                placeholder="{{'DIALOGS.EDIT-PROMOTION.FORM.PLACEHOLDER.CODE' | translate}}">
              <t4-display-validation controlName="Code" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.DESCRIPTION' | translate}}</label>
              <input
                class="form-control"
                name="Description"
                formControlName="Description"
                placeholder="{{'DIALOGS.EDIT-PROMOTION.FORM.PLACEHOLDER.DESCRIPTION' | translate}}">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-control-label">
                {{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.TYPE' | translate}}<span class="tx-danger">*</span>
              </label>
              <ng-select [items]="promotionTypes" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.EDIT-PROMOTION.FORM.PLACEHOLDER.TYPE' | translate}}" formControlName="PromotionType"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="PromotionType" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.AMOUNT' | translate}}<span class="tx-danger">*</span></label>
              <input
                class="form-control"
                name="PromotionAmount"
                formControlName="PromotionAmount">
              <t4-display-validation controlName="PromotionAmount" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="form-group col-4">
            <label class="ckbox ckbox-input">
              <input
                type="checkbox"
                name="Active"
                formControlName="Active">
              <span>{{'DIALOGS.EDIT-PROMOTION.FORM.LABEL.ACTIVE' | translate}}</span>
            </label>
          </div>
        </div><!-- row -->

        <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>
