import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EventPlacementModifiedStateView, EventPlacementService } from '../../api';

interface PassedData {
  title: string;
  vbbId: number;
  type: 'details';
}

@Component({
  selector: 'app-vbb-update-ep-dialog',
  templateUrl: 'vbb-update-ep-dialog.component.html'
})

export class VbbUpdateEpDialogComponent implements OnInit {

  public title = this.translate.instant('DIALOGS.VBB-UPDATE-EP-DIALOG.TITLE.TRANSMIT-CHANGES');
  public epEventStates: Array<EventPlacementModifiedStateView> = [];
  public showPassedEvents: boolean;
  public epsToUpdate: {[key: number]: boolean} = {};

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: PassedData,
              public dialogRef:  MatDialogRef<VbbUpdateEpDialogComponent>,
              private eventPlacementService: EventPlacementService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.passedData.title)
      this.title = this.passedData.title;

    let observer: Observable<Array<EventPlacementModifiedStateView>>;
    switch (this.passedData.type) {
      case 'details': {
          observer = this.eventPlacementService.getEventPlacementModifiedStateByVBBId(this.passedData.vbbId);
        break;
      }
      default: {
        throw new Error(`Passed invalid type ${this.passedData.type}`);
      }
    }

    observer.subscribe((data) =>{
      this.epEventStates = data;
      for (const state of this.epEventStates){
        this.epsToUpdate[state.EventId] = !state.DiffersFromVBB && !state.PassedEvent;
      }
    });
  }


  submit() {
    const eventIdsToUpdate: Array<number> = [];
    for (const epId in this.epsToUpdate) {
      if (this.epsToUpdate[epId]) {
        eventIdsToUpdate.push(Number(epId));
      }
    }
    this.dialogRef.close(eventIdsToUpdate);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
