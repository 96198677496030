<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form [formGroup]="form" class="form-content">

  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">

        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-USER.FORM.LABEL.FIRST-NAME' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control"  name="firstname" formControlName="Firstname" placeholder="{{'DIALOGS.EDIT-USER.FORM.PLACEHOLDER.FIRST-NAME' | translate}}">
            <t4-display-validation controlName="Firstname" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-USER.FORM.LABEL.MIDDLE-NAME' | translate}}</label>
            <input class="form-control" name="middlename" formControlName="Middlename" placeholder="{{'DIALOGS.EDIT-USER.FORM.PLACEHOLDER.MIDDLE-NAME' | translate}}">
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-USER.FORM.LABEL.LAST-NAME' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" name="lastname" formControlName="Lastname" placeholder="{{'DIALOGS.EDIT-USER.FORM.PLACEHOLDER.LAST-NAME' | translate}}">
            <t4-display-validation controlName="Lastname" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-USER.FORM.LABEL.EMAIL' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" name="email" formControlName="Email" placeholder="{{'DIALOGS.EDIT-USER.FORM.PLACEHOLDER.EMAIL' | translate}}">
            <t4-display-validation controlName="Email" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-USER.FORM.LABEL.DESCRIPTION' | translate}}</label>
            <input class="form-control" name="Description" formControlName="Description" placeholder="{{'DIALOGS.EDIT-USER.FORM.PLACEHOLDER.DESCRIPTION' | translate}}">
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
            <label class="ckbox">
              <input type="checkbox"  name="Active" formControlName="Active"><span>  {{'DIALOGS.EDIT-USER.FORM.LABEL.ACTIVE' | translate}}</span>
            </label>
          </div>
        </div>

      </div><!-- row -->

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="form.invalid || saving"  class="btn btn-block btn-primary bd-0"> <i class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->

    </div><!-- form-layout -->

  </div>
</form>
