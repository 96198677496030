/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type HardcardStatusEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

export const HardcardStatusEnum = {
    NUMBER_1: 1 as HardcardStatusEnum,
    NUMBER_2: 2 as HardcardStatusEnum,
    NUMBER_3: 3 as HardcardStatusEnum,
    NUMBER_4: 4 as HardcardStatusEnum,
    NUMBER_5: 5 as HardcardStatusEnum,
    NUMBER_6: 6 as HardcardStatusEnum,
    NUMBER_7: 7 as HardcardStatusEnum,
    NUMBER_8: 8 as HardcardStatusEnum,
    NUMBER_9: 9 as HardcardStatusEnum,
    NUMBER_10: 10 as HardcardStatusEnum,
    NUMBER_11: 11 as HardcardStatusEnum,
    NUMBER_12: 12 as HardcardStatusEnum,
    NUMBER_13: 13 as HardcardStatusEnum,
    NUMBER_14: 14 as HardcardStatusEnum
};

