import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ValidationHelper } from 'app/shared/helpers/validation.helper';
import { UserValidator } from 'app/shared/helpers/user-validator';
//FDP: Tymes4V2 migration: ngx-custom-validators is incompatible with ng13, this is a fork
//import {CustomValidators} from 'ngx-custom-validators';
import { CustomValidators } from '@narik/custom-validators';
import { UserService } from '../../api';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html'
})
export class EditUserFormComponent implements OnInit {

  public form: FormGroup;

  private editingObject = null;
  public saving = false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditUserFormComponent>,
              private loader: HttpLoaderService,
              private userService: UserService,
              private validationHelper: ValidationHelper,
              private userValidator: UserValidator) {}

  validationMessages = [];

  ngOnInit() {
    this.editingObject = this.passedData.payload;

    if (!this.editingObject) {
      this.editingObject = { Active: true };
    }

    this.form = new FormGroup({
      Id: new FormControl(''), //to preserve value
      Active: new FormControl(''),
      Firstname: new FormControl('', [Validators.required]),
      Middlename: new FormControl(''),
      Lastname: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, CustomValidators.email, this.userValidator.emailTaken.bind(this)]),
      Description: new FormControl('')
    });

    this.form.patchValue(this.editingObject);
  }

  submit() {
    var formData = this.form.value;

    //Ready to save
    this.loader.open();

    //store the object and return the id
    if (!formData.Id) {
      formData.Id = 0;
      this.userService.createUser(formData).subscribe((id:number) => {
        //close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    }
    else {
      this.userService.updateUser(formData).subscribe(() => {
        //close this dialog and pass the newly added id to the called
        this.dialogRef.close(this.editingObject.Id);
        this.saving = false;
        this.loader.close();
      });
    }
  }
}
