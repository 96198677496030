import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpLoaderService, NotificationCommunicationService, SnackbarService} from '@tymes4-shared';
import {GenericErrorHandler} from 'app/shared/helpers/generic-error-handler';
import * as FileSaver from 'file-saver';
import {CreateRepurchaseExportArgs, OrderImportService, PassePartoutService} from "../../api";

@Component({
  selector: 'app-create-order-import-file',
  templateUrl: './create-order-import-file.component.html',
  styleUrls: ['./create-order-import-file.component.scss']
})
export class CreateOrderImportFileComponent implements OnInit {

  public saving = false;
  public passePartoutSelections = [];
  public allPassePartouts = [];
  public salesPeriodeId: number;
  private eventId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CreateOrderImportFileComponent>,
    private passePartoutService: PassePartoutService,
    public orderImportService: OrderImportService,
    private loader: HttpLoaderService,
    private errorHandler: GenericErrorHandler,
    private notificationCommunicationService : NotificationCommunicationService,
    private snackBar: SnackbarService
  ) {
  }


  ngOnInit() {
    this.salesPeriodeId = this.passedData.salesPeriodId;
    this.eventId = this.passedData.eventId;
    this.initForm();
  }

  initForm(): any {

    this.passePartoutSelections = [];

    this.loader.open();

    this.passePartoutService.pptSearch('', 1, 99999, 'Name').subscribe(resp => {

        //activate the first row
        this.pushNewRow();

        this.loader.close();
        this.allPassePartouts = resp.Records;

      },
      error => {
        this.errorHandler.handle(error);
      }
    );
  }

  pushNewRow(): any {
    const lastRow = this.passePartoutSelections.length > 0 ? this.passePartoutSelections[this.passePartoutSelections.length - 1] : null;

    this.passePartoutSelections.push({
      FromPassePartoutId: null,
      ToPassePartoutId: null
    });
  }

  removeLastRow() {
    if (this.passePartoutSelections.length === 0) return;
    this.passePartoutSelections.pop();
  }

  onFromPassePartoutChanged(event, ps) {
    ps.FromPassePartoutId = event.Id;
  }

  onToPassePartoutChanged(event, ps) {
    ps.ToPassePartoutId = event.Id;
  }

  cannotDownload() {
    let allowed = true;

    for (let pps of this.passePartoutSelections) {
      if (this.salesPeriodeId) {
        allowed = allowed && (pps.FromPassePartoutId !== null && pps.ToPassePartoutId !== null);
      } else {
        allowed = allowed && pps.FromPassePartoutId !== null;
      }
    }

    return !allowed;
  }

  doDownload() {

    let args: CreateRepurchaseExportArgs = {};
    args.EventId = this.eventId;
    args.SalesPeriodId = this.salesPeriodeId;
    args.PassePartoutSelection = this.passePartoutSelections;

    this.orderImportService.orderImportCreateExportForSalesPeriod(args).subscribe(data => {
      this.notificationCommunicationService.updateNotificationsAndLROS();
      this.dialogRef.close(true);
    });
  }
}
