import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-long-running-operation-status',
  templateUrl: './app-long-running-operation-status.html',
  styleUrls: ['./app-long-running-operation-status.css']
})
export class LongRunningOperationComponent implements OnInit {
  
  title;
  message;
  progress = 0;
  progressMode = 'query';
  status = 1;
  

  constructor(public dialogRef: MatDialogRef<LongRunningOperationComponent>) {}

  ngOnInit() {
  }

}
