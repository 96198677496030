<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(null);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form [formGroup]="form" class="form-content">

  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'CUSTOM.BUTTON.STATE.FORM.TRANSLATION-CODE' | translate}} <span class="tx-danger" *ngIf="isRequiredField('translationCode')">*</span></label>
            <input class="form-control" name="translationCode" formControlName="translationCode" placeholder="{{'CUSTOM.BUTTON.STATE.FORM.TRANSLATION-CODE' | translate}}">
            <t4-display-validation controlName="translationCode" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE' | translate}} <span class="tx-danger" *ngIf="isRequiredField('actionType')">*</span></label>

            <ng-select [items]="actionTypeValues" bindLabel="viewValue" bindValue="value" formControlName="actionType" placeholder="{{'CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="handleActionChanges($event)"></ng-select>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'CUSTOM.BUTTON.STATE.FORM.BUTTON-STYLE' | translate}} <span class="tx-danger" *ngIf="isRequiredField('buttonStyle')">*</span></label>

            <ng-select [items]="buttonStyleValues" bindLabel="viewValue" bindValue="value" formControlName="buttonStyle" placeholder="{{'CUSTOM.BUTTON.STATE.FORM.BUTTON-STYLE' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="form-group">
            <label class="form-control-label">{{'CUSTOM.BUTTON.STATE.FORM.ACTION' | translate}} <span class="tx-danger" *ngIf="isRequiredField('action')">*</span></label>
            <input class="form-control" name="action" formControlName="action" placeholder="{{'CUSTOM.BUTTON.STATE.FORM.ACTION' | translate}}">
            <t4-display-validation controlName="action" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group mt-6" *ngIf="showOpenInNewTab()">
            <label class="ckbox">
              <input type="checkbox" name="openInNewTab" formControlName="openInNewTab"><span>  {{'CUSTOM.BUTTON.STATE.FORM.OPEN-IN-TAB' | translate}}</span>
            </label>

          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <t4-date-time-control formControlName="ActiveFrom" [beforeToday]="false" [labelDate]="'CUSTOM.BUTTON.STATE.FORM.FROM-DATE'" [labelTime]="'CUSTOM.BUTTON.STATE.FORM.FROM-TIME'"></t4-date-time-control>
            <t4-display-validation controlName="ActiveFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <t4-date-time-control formControlName="ActiveTill" [beforeToday]="false" [labelDate]="'CUSTOM.BUTTON.STATE.FORM.TILL-DATE'" [labelTime]="'CUSTOM.BUTTON.STATE.FORM.TILL-TIME'"></t4-date-time-control>
            <t4-display-validation controlName="ActiveTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        
      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button *ngIf="!isInArchiveMode" (click)="save()" [disabled]="!form.valid" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(null);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
