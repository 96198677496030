<button [class]="cssClasses" [disabled]="isDisabled || isPending()" (click)="emitClick($event)">
  
   
  <div *ngIf="isPending()"  class="d-flex align-items-center justify-content-center">
    <div class="sk-circle sk-circle-small">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
    <div style="padding-left: 10px;">
      {{ buttonText | translate }}
    </div>

  </div>

  <ng-container *ngIf="!isPending()" >
    <i [class]="iconClasses"></i>
    <span *ngIf="buttonText != null && buttonText != ''">{{ buttonText | translate}}</span>
  </ng-container>
  
</button>