<div class="row modal-bar">
  <div class="col-sm-9">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-3">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


  <t4-list-view #passepartoutEventQueueListVw [options]="pptEventQueueListOptions" (onDataRequest)="onDataRequest($event)">

    <ng-container *t4ListItem="let item">

      <td class="text-center">
        <i *ngIf="item.AmountErrors == 0" class="pl-1 far fa-check-circle text-success icon-24"></i>
        <i *ngIf="item.AmountErrors > 0" class="pl-1 fas fa-exclamation-circle text-danger icon-24"></i>
      </td>

      <td *ngIf="item.AmountErrors > 0">{{'LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.CONTENT.NOT-IMPLEMENTED' | translate}}</td>
      <td *ngIf="item.AmountErrors == 0">{{'LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.CONTENT.IMPLEMENTED' | translate}}</td>

      <td>{{item.Created | localDateTime }}</td>
      <td>{{item.CreatedBy}}</td>
      <td>{{item.AmountChanges | number}}</td>
      <td>{{item.AmountWarnings | number}}</td>
      <td>{{item.AmountErrors | number}}</td>

      <td class="grid-action" >
        <button (click)="downloadReport(item.Id)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-file-excel"></button>
      </td>




    </ng-container>

  </t4-list-view>

