import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import { PassePartoutService } from "../../api";

@Injectable()
export class PassePartoutValidator{
    constructor(private passePartoutService: PassePartoutService) {}

    public editingObject : any;

    passePartoutCodeTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        var v = control.value;
        setTimeout(() => {
          this.passePartoutService.getPPTByCode(v).subscribe(data => {
            if (data !== null && data !== void 0) {
              if (data.Id != this.editingObject.Id) {
                resolve({"codetaken": data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
  }
}
