<div class="card card-default">
    <form [formGroup]="form" class="form-content">
  
      <div class="card-header">
          <h2>{{'ACCOUNTING-DIMENSION.FORM.CARD-HEADER' | translate}}</h2>
      </div>
  
      <div class="card-body">
          <div class="row p-2" *ngIf="showDim1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE1' | translate}}</label>
                  <input class="form-control" name="Code1" formControlName="Code1"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE1' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim2">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE2' | translate}}</label>
                  <input class="form-control" name="Code2" formControlName="Code2"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE2' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim3">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE3' | translate}}</label>
                  <input class="form-control" name="Code3" formControlName="Code3"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE3' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim4">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE4' | translate}}</label>
                  <input class="form-control" name="Code4" formControlName="Code4"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE4' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim5">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE5' | translate}}</label>
                  <input class="form-control" name="Code5" formControlName="Code5"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE5' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
  
          <div class="col-12">
          <button *ngIf="!isInArchiveMode" [disabled]="!form.dirty|| saving" (click)="submit()" class="btn btn-primary border btn-block" type="button">
              <i class="far fa-save"></i>{{'GENERIC.BUTTON.SAVE' | translate}}
          </button>
          </div>
  
      </div>
    </form>
  </div>