import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { UserService } from "../api";

@Injectable()
export class UserValidator {

    constructor(private userService: UserService) {}

    private debouncer: any;
    public currentEmail : string;

    emailTaken = (control: FormControl) => {

        //do not hammer the service
        clearTimeout(this.debouncer);

        return new Promise(resolve => {

            this.debouncer = setTimeout(() => {

                if (!control.dirty && control.value !== ''){
                    control.setErrors(null);
                    resolve(null);
                }
                else{
                    if (control.value === this.currentEmail || control.value === ''){
                        resolve(null);
                    }
                    else {

                        this.userService.isUserEmailInUse(control.value.trim()).subscribe(data=> {

                            if (data === true) {
                              control.setErrors({"emailTaken": true});
                              resolve({"emailTaken": data});
                            }
                            else {
                             control.setErrors(null);
                              resolve(null);
                            }
                        });
                    }
                }

            }, 1000);
        });
    }
}
