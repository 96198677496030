

import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";
import {CrossSellProductCategoryService} from "../../api";


@Injectable()
export class CrossSellCategoryValidator {
    constructor( private crossSellProductCategoryService: CrossSellProductCategoryService) {}

    public currentName : string;

    CategoryNameTaken = (argArray, control: AbstractControl) => {

      return new Promise<ValidationErrors | null>((resolve, reject) => {

        var v = control.value;

        this.currentName = argArray.currentName;

        if (this.currentName != null && control.value === this.currentName ) {
          resolve(null); //same name as before, this is ok
        }
        else {
          setTimeout(() => {
            this.crossSellProductCategoryService.crossSellProductCategoryGetByName(v).subscribe(data => {
              if (data !== null && Object.keys(data).length > 0 &&  data !== void 0) {
                resolve({"nametaken": data});
              }
              resolve(null);
            });
          }, 200);
        }
      });
    }
}
