import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import {LoggingService} from '@tymes4-shared';
import { ConfigurationSettingsValidator } from './configuration-settings.validator';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { SystemConfigurationSettingsService } from '../../api';

@Component({
  selector: 'app-edit-configuration-form',
  templateUrl: './edit-configuration-form.component.html'
})
export class EditConfigurationFormComponent implements OnInit {
  public form: FormGroup;
  private editingObject = null;
  private isNew = false;
  public saving = false;

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
                                        public dialogRef:  MatDialogRef<EditConfigurationFormComponent>,
                                        private settingsService: SystemConfigurationSettingsService,
                                        private settingsValidator: ConfigurationSettingsValidator,
                                        private logging: LoggingService,
                                        private loader: HttpLoaderService,
                                        private translate: TranslateService) {
                                        }

  ngOnInit() {
    this.editingObject = this.passedData.payload;

    if (this.editingObject == null) {
      this.isNew = true;
      this.editingObject = {};
    } else {
      this.isNew = false;
    }

    this.settingsValidator.editingObject = this.editingObject;

    this.form = new FormGroup({
      Key: new FormControl('', [Validators.required], [this.settingsValidator.settingsKeyTaken.bind(this)]),
      Value: new FormControl(''),
      Sensitive: new FormControl(false),
    });

    this.form.patchValue(this.editingObject);
  }

  submit() {
    const formValues = this.form.value;
    this.saving = true;
    this.loader.open();

    if (this.isNew) {
        this.settingsService.getCurrentConfigId().subscribe(configid => {
          const test2 = {
            Id: 0,
            Created: Date.now(),
            Modified: null,
            Deleted: null,
            ConfigurationId: configid,
            Key: formValues.Key,
            Value: formValues.Value,
            Comments: '',
            ApplicationModuleId: null,
            Sensitive: formValues.Sensitive,
          };
          this.settingsService.createConfigurationSetting(test2).subscribe((key: number) => {
            this.dialogRef.close(key);
            this.saving = false;
            this.loader.close();
          });
        });
    } else {
      this.editingObject.Key = formValues.Key;
      this.editingObject.Value = formValues.Value;
      this.editingObject.Sensitive = formValues.Sensitive;

      this.settingsService.updateConfigurationSetting(this.editingObject).subscribe(() => {
        this.dialogRef.close(this.editingObject.Key);
        this.saving = false;
        this.loader.close();
      });
    }
  }
}
