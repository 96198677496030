


<div class="row modal-bar">
    <div class="col-sm-9">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-3">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>



<!-- MODAL BODY -->

<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">

        <div class="row">
         
            <div class="col-lg-12">

                <div class="form-group">
                    <label class="form-control-label">{{'DIALOGS.AUTO-NUMBER.FORM.LABEL.DIRECTION-OF-NUMBERING' | translate}}<span class="tx-danger">*</span></label>
                    <ng-select [items]="directions" 
                               bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.AUTO-NUMBER.FORM.PLACEHOLDER.DIRECTION-OF-NUMBERING' | translate}}" 
                               formControlName="Direction"
                               [clearable]="false" 
                               notFoundText="{{'GENERIC.NOT-FOUND-TEXT.NO-OPTIONS-FOUND' | translate}}" 
                               appendTo="body">
                    </ng-select>
                </div>
            </div>
        </div>

       

        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{'GENERIC.BUTTON.OK' | translate}}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>

