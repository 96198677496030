<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<div class="section-wrapper p-3">

  <div *ngFor="let progressInfo of progressInfos" class="mb-2">
    <span>{{ progressInfo.fileName }}</span>
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ progressInfo.percentage }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progressInfo.percentage + '%' }"
      >
        {{ progressInfo.percentage }}%
      </div>
    </div>
  </div>

  <ul class="pt-2 parsley-errors-list filled" *ngIf="message != ''">
    <li>{{ message}}</li>
  </ul>

</div>

<label class="btn btn-default"><input type="file" accept="application/pdf" multiple (change)="selectFiles($event)" /></label>



<div class="form-layout-footer row">
  <div class="col-6">
    <button [disabled]="allowUpload == false" (click)="uploadFiles()" class="btn btn-block btn-primary bd-0"><i
      class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
      class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
  </div>
</div>





