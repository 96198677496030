
<t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">

  <ng-container *ngIf="readonlyMode === false">
    <ng-container *t4ListActions >
      <button (click)="onActionSelected('add')"  class="btn btn-primary border" type="button" style="margin-top: 1.5625rem;"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
    </ng-container>
  </ng-container>


  <ng-container *t4ListItem="let item">
    <td><b>{{ item.SalesChannelName }}</b></td>
    <td>{{ item.PriceListName }}</td>
    <td>{{ item.ActiveTill | localDateTime | replaceOnEmpty }}</td>
<!--    <td>{{ item.ActiveTill }}</td>-->

    <td class="grid-action" >
      <button *ngIf="readonlyMode === false" (click)="onActionSelected('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
    </td>
  </ng-container>
</t4-list-view>
