import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-select-event',
  templateUrl: './select-event.component.html',
  styleUrls: ['./select-event.component.scss']
})
export class SelectEventComponent implements OnInit {

  public selectableEvents: any;
  public form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<SelectEventComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      EventId: new FormControl('')
    });

    this.selectableEvents = this.passedData.payload.map(e => ({text: this.formatEventName(e), ...e}));

    if (this.passedData.eventId !== null) {
      var o = {
        EventId: this.passedData.eventId
      };

      this.form.patchValue(o);
    }
  }

  formatEventName(e) {
    return moment(e.StartDateTime).format('DD-MM-YYYY HH:mm') + ' - ' + e.Name;
  }

  submit() {
    const o = this.form.value;

    this.dialogRef.close(o.EventId);
  }
}
