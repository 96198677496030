<div class="row modal-bar">
  <div class="col-sm-6">
    <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(null);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">
        <div class="col-lg-12">
          <!-- Events -->
          <div class="form-group">
            <label class="form-control-label">{{'SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.LABEL' | translate}}</label>

            <ng-select [items]="selectableEvents" bindLabel="Name" bindValue="Id" placeholder="{{'SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.EVENT.PLACEHOLDER' | translate}}" formControlName="eventIds"
                       [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [multiple]="true">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item && item.Name ? item.Name + (item.StartDateTime ? ' - ' : '') : '' }}{{ item.StartDateTime | localDateTime | slice:0:10 }}
              </ng-template>
            </ng-select>
          </div>

          <!-- Passepartout -->
          <div class="form-group">
            <ng-select [items]="selectablePassePartout" bindLabel="Name" bindValue="Id" placeholder="{{'SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.PASSE-PARTOUT.PLACEHOLDER' | translate}}" formControlName="passePartoutIds"
                       [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [multiple]="true">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.Code + ' - ' + item.Name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- Membership -->
          <div class="form-group">
            <ng-select [items]="selectableMembership" bindLabel="Name" bindValue="Id" placeholder="{{'SALES.ORDERS.INVOICING.DIALOG.CREATE-BATCH.FORM.MEMBERSHIP.PLACEHOLDER' | translate}}" formControlName="membershipIds"
                       [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [multiple]="true">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.Name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="doCreate()" [disabled]="formInvalid()" class="btn btn-block btn-primary bd-0"><i
               class="far fa-plus-square"></i><span>{{'GENERIC.BTN.CREATE' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(null);" class="btn btn-block btn-secondary bd-0"><i
               class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
