<div class="card card-default card-turnover-per">
  <div class="card-header">
    <h2>{{ Name }}</h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-4">
        <h1 class="amountCardFormat">{{ TurnOverWeek | currency:'EUR':'symbol':'1.0-0' }}</h1>
        <p class="amountTitleCardFormat">{{'COMPONENTS.CARD-TURNOVER-WEEK' | translate}}</p>
      </div>
      <div class="col-4">
        <h1 class="amountCardFormat">{{ TurnOverMonth | currency:'EUR':'symbol':'1.0-0' }}</h1>
        <p class="amountTitleCardFormat">{{'COMPONENTS.CARD-TURNOVER-MONTH' | translate}}</p>
      </div>
      <div class="col-4">
        <h1 class="amountCardFormat">{{ TurnOverTotal | currency:'EUR':'symbol':'1.0-0' }}</h1>
        <p class="amountTitleCardFormat">{{'COMPONENTS.CARD-TURNOVER-TOTAL' | translate}}</p>
      </div>
    </div>
    <div>
      <apx-chart  [chart]="progressPerc.chart" *ngIf="progressPerc !== null"
                  [xaxis]="progressPerc.xaxis"
                  [colors]="progressPerc.colors"
                  [labels]="progressPerc.labels"
                  [dataLabels]="progressPerc.dataLabels"
                  [plotOptions]="progressPerc.plotOptions"
                  [series]="progressPerc.series">
      </apx-chart>
    </div>
  </div>
</div>
