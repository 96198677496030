import { Pipe, PipeTransform } from "@angular/core";
import { LocalizationService } from "../services/localization.service";

@Pipe({ name: "localizedCurrency" })
export class LocalizedCurrencyPipe implements PipeTransform {

  constructor(private localizationService: LocalizationService) {
  }

  transform(value: number): string {
     
    return this.localizationService.numberToCurrencyString(value);

  }

  parse(value: string): string {
    return this.localizationService.currencyStringToNumber(value);
  }

}