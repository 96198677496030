/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResellerDetailsView { 
    SalesChannelId?: number;
    SalesChannelCode?: string | null;
    SalesChannelName?: string | null;
    SystemManaged?: boolean;
    ResellerId?: number;
    ResellerWebsite?: string | null;
    ResellerEmail?: string | null;
    ResellerIBAN?: string | null;
    AllowNewMembers?: boolean | null;
    PrimaryContactId?: number | null;
    PrimaryContactFirstname?: string | null;
    PrimaryContactMiddlename?: string | null;
    PrimaryContactLastname?: string | null;
    PrimaryContactEmail?: string | null;
    PrimaryContactTelephoneNr1?: string | null;
    PrimaryContactTelephoneNr2?: string | null;
    LocationAddressId?: number | null;
    LocationName?: string | null;
    LocationStreet?: string | null;
    LocationStreetNr?: number | null;
    LocationStreetNrAddition?: string | null;
    LocationPostalCode?: string | null;
    LocationCity?: string | null;
    LocationCountryId?: number;
    LocationCountry?: string | null;
    SecondaryLocationAddressId?: number | null;
    SecondaryLocationName?: string | null;
    SecondaryLocationStreet?: string | null;
    SecondaryLocationStreetNr?: number | null;
    SecondaryLocationStreetNrAddition?: string | null;
    SecondaryLocationPostalCode?: string | null;
    SecondaryLocationCity?: string | null;
    SecondaryLocationCountryId?: number | null;
    SecondaryLocationCountry?: string | null;
    ParentId?: number | null;
    ParentCode?: string | null;
    ParentName?: string | null;
    SalesGroupId?: number | null;
    SalesGroupCode?: string | null;
    SalesGroupName?: string | null;
}

