import { Routes } from '@angular/router';
import { AdminLayoutComponent } from '@tymes4-shared';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { ArchiveGuard } from './shared/services/auth/archive.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'location',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    data: {
      breadcrumb: 'GENERIC.BREADCRUMB.LOCATION', noNavigate: true
    },
    loadChildren: () => import('./views/locations/locations.module').then(m => m.LocationsModule)
  },

  {
    path: 'sales',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'reporting',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule)
  },


  {
    path: 'administration',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    data: {
      breadcrumb: 'GENERIC.BREADCRUMB.ADMINISTRATION', noNavigate: true
    },
    loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule)
  }, {
    path: 'relations',
    data: {
      breadcrumb: 'GENERIC.BREADCRUMB.RELATIONS', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/relations/relations.module').then(m => m.RelationsModule)
  }, {
    path: 'service',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    data: {
      breadcrumb: 'GENERIC.BREADCRUMB.SERVICE', noNavigate: true
    },
    loadChildren: () => import('./views/service/service.module').then(m => m.ServiceModule)
  }, {
    path: 'finance',
    data: {
      breadcrumb: 'GENERIC.BREADCRUMB.FINANCE', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/finance/finance.module').then(m => m.FinanceModule)
  }, {
    path: 'distributix',
    data: {
      Breadcrumb: 'GENERIC.BREADCRUMB.DISTRIBUTIX', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/distributix/distributix.module').then(m => m.DistributixModule)
  }, {
    path: 'fantastix',
    data: {
      Breadcrumb: 'GENERIC.BREADCRUMB.FANTASTIX', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/fantastix/fantastix.module').then(m => m.FantastixModule)
  }, {
    path: 'crosssell',
    data: {
      Breadcrumb: 'GENERIC.BREADCRUMB.MERCHANDISE', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/crosssell/crosssell.module').then(m => m.CrossSellModule)
  }, {
    path: 'policies',
    data: {
      Breadcrumb: 'GENERIC.BREADCRUMB.MARKETPLACE', noNavigate: true
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ArchiveGuard],
    loadChildren: () => import('./views/policies/policies.module').then(m => m.PoliciesModule)
  },
  {
    path: 'flexplan',
    data: {
        noNavigate: false,
        breadcrumb: 'GENERIC.BREADCRUMB.MATCHDAYPASS.OVERVIEW', 
        title: 'GENERIC.PAGETITLE.MATCHDAYPASS.OVERVIEW'
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/matchdaypass/matchdaypass.module').then(m => m.MatchdaypassModule)
},
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];
