
<t4-card class="card-profile card-activity" [showFooter]="inDialog === false" cardTitle="{{'ACTIVITY.LOG.CARD.TITLE' | translate}}">
  
  <ng-container data-body>
    
    <div *ngIf="activities.length === 0">{{'ACTIVITY.LOG.NO.HISTORY' | translate}}</div>

    <div class="media-list pd-t-10">
      <div class="media" *ngFor="let activity of activities">
        <div class="activity-icon bg-primary">
          <i [class]="getActionIcon(activity)"></i>
        </div><!-- activity-icon -->
        <div class="media-body pt-0">
          <b>{{getActionDescription(activity)}}</b><br />
          <p>{{'ACTIVITY.LOG.ACTION.BY' | translate}} {{userName(activity)}}</p>
          <span>{{activity.Created | localDateTime | replaceOnEmpty }}</span>
        </div><!-- media-body -->
      </div><!-- media -->
  
    </div><!-- media-list -->

  </ng-container>
  <ng-container data-footer-right *ngIf="inDialog === false">
    
    <div style="min-height: 24px;">
      <span (click)="showHistory()" class="action-text clickable" >{{'ACTIVITY.LOG.SHOW.COMPLETE.HISTORY' | translate}}</span>
    </div>

  </ng-container>

</t4-card>

<!-- 
<div class="card card-activities">
  
  <div class="card-header"><h2>Activiteit</h2></div>

  <<h6 class="slim-card-title"></h6> 
  


</div>

-->

