import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EditPricelistLinkComponent } from '../../../shared/dialogs/edit-pricelist-link/edit-pricelist-link.component';
import * as moment from 'moment';
import {ConfirmService} from '@tymes4-shared';
import {PricelistLink} from '../../models/pricelist-link';
import {ListViewSelectionMode} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import {EventPriceListView, EventService, MembershipPriceListView, MembershipService, PassePartoutPriceListView, PassePartoutService} from '../../api';
import {PagedResult} from "../../../../../../../dist/tymes4-shared";
import { idLocale } from 'ngx-bootstrap/chronos';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-link-pricelist',
  templateUrl: './link-pricelist.component.html',
  styleUrls: ['./link-pricelist.component.scss']
})
export class LinkPricelistComponent implements OnInit {

  private selectedPriceList = null;
  public linkedLists = [];
  private currentEventId: number = null;
  private currentPassePartoutId: number = null;
  private currentMembershipId: number = null;
  private hasListWithoutExpiry = false;
  private lastChannelName = '';
  private saleChannelsWithoutExpiryDate: Array<number>;
  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(
    private eventService: EventService,
    private passePartoutService: PassePartoutService,
    private membershipService: MembershipService,
    private dialog: MatDialog,
    private confirmService: ConfirmService,
    private translate: TranslateService,
    private authService: AuthService
    ) { }

  @Input()
  set eventId(id: number) {
    this.currentEventId = id;
  }

  @Input() readonlyMode = false;

  @Input()
  set passePartoutId(id: number) {
    this.currentPassePartoutId = id;
  }

  @Input()
  set membershipId(id: number) {
    this.currentMembershipId = id;
  }

  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12,
    selectionMode: ListViewSelectionMode.None,
    disableSort: true,
    renderInCard: false,
    noSpacing: true,
    headers: [
      { sortField: null, label: 'EVENT.DETAILS.PRICELISTS.LISTVIEW.HEADER.SALESCHANNEL', width: 140, hideOn: null },
      { sortField: null, label: 'EVENT.DETAILS.PRICELISTS.LISTVIEW.HEADER.PRICELIST', hideOn: null },
      { sortField: null, label: 'EVENT.DETAILS.PRICELISTS.LISTVIEW.HEADER.ACTIVE.TILL', width: 100, hideOn: null },
      { label: '' },
    ]
  };

  // Gave "value has changed" issues
  getChannelName(item) {

    if (item.SalesChannelName != this.lastChannelName) {
      this.lastChannelName = item.SalesChannelName;
      return item.SalesChannelName;
    }

    return '';
  }

  OnDataRequest($event) {
    if (this.currentEventId != null) {
      this.eventService.getEventPriceList(this.currentEventId).subscribe((lists: Array<EventPriceListView>) => {
        this.setListViewData(lists);
      });
    } else if (this.currentPassePartoutId != null) {
      this.passePartoutService.getPassePartoutPriceList(this.currentPassePartoutId).subscribe((lists: Array<PassePartoutPriceListView>) => {
        this.setListViewData(lists);
      });
    } else if (this.currentMembershipId != null) {
      this.membershipService.getMembershipPriceList(this.currentMembershipId).subscribe((lists: Array<MembershipPriceListView>) => {
        this.setListViewData(lists);
      });
    } else {
      this.setListViewData([]);
    }
  }

  setListViewData(lists: Array<any>): void {
    // for (const l of lists) {
    //   if (l.ActiveTill != null) {
    //    l.ActiveTill = moment.utc(l.ActiveTill).toDate();
    //   }
    // }

    this.linkedLists = lists;
    this.preparePriceListData();
    const data : PagedResult = {
      TotalRecords: lists.length,
      Records: this.linkedLists
    };
    this.listVw.Data = data;
  }

  preparePriceListData(): any {
    this.saleChannelsWithoutExpiryDate = this.linkedLists.filter( (l) => !l.ActiveTill).map( (l) => l.SalesChannelId);
    //Sort the list
    this.linkedLists = this.linkedLists.sort(function(a,b){
      var dateA = a.ActiveTill;
      var dateB = b.ActiveTill;

      //first sort on saleschannel
      if (a.SalesChannelName > b.SalesChannelName) return 1;
      else if (a.SalesChannelName < b.SalesChannelName) return -1;
      else {
        //saleschannels are the same, sort on date.
        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return 1;
        } else if (dateB === null) {
          return -1;
        } 

        //return dateA.isBefore(dateB) ? -1 : 1;
        return dateA <= dateB ? -1 : 1;
      }

    });

    this.hasListWithoutExpiry = false;

    for (let l of this.linkedLists) {
      if (!l.ActiveTill){
        this.hasListWithoutExpiry = true;
      }
    }
  }

  OnItemSelectionChange(items){
    if (items.length > 0) this.selectedPriceList = items[0];
    else this.selectedPriceList = null;
  }

  ngOnInit() {
  }

  ensureInitialized(): any {
    if (this.linkedLists === null) {
      this.listVw.refreshData();
    }
  }

  deleteAllowed() {
    return (this.selectedPriceList !== null);
  }

  refreshList() {
    this.listVw.refreshData();
  }

  onActionSelected(action, item = null) {
    if (action === 'add') {
      this.openAddDialog();
    }
    else if (action === 'delete') {
      this.deleteItem(item);
    }
  }

  deleteItem(item) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE'),
      okonly: false
    }).subscribe((confirmed:boolean) => {
      if(confirmed) {
        this.linkedLists.splice(this.linkedLists.indexOf(item), 1);
        this.selectedPriceList = null;
        this.preparePriceListData();
      }
    });
  }

  openAddDialog() {
    let title =  this.translate.instant('EVENT.DETAILS.PRICELISTS.ADD');
    let dialogRef: MatDialogRef<any> = this.dialog.open(EditPricelistLinkComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, requireDateForChannels: this.saleChannelsWithoutExpiryDate }
    })
    dialogRef.afterClosed()
      .subscribe(addedObject => {
        if(addedObject) {

          //we need to add the pricelist for every selected saleschannel

          for (let sc of addedObject.SalesChannels) {

              //the user commit the pricelist
              // fake id to make line unique
              let fId = -1 * this.linkedLists.length - 1;
              while (this.linkedLists.find(l => l.Id == fId)) fId--;

              let o: any = {
                Id: fId,
                EventId: this.currentEventId,
                PassePartoutId: this.currentPassePartoutId,
                PriceListId: addedObject.PriceList.Id,
                PriceListName: addedObject.PriceList.Name,
                SalesChannelId: sc.Id,
                SalesChannelName: sc.Name
              };

              if (addedObject.HasTillDate) {
                o.ActiveTill= addedObject.ActiveTill;
              }
              else {
                o.ActiveTill = null;
              }

              this.linkedLists.push(o);

          }

          this.selectedPriceList = null;
          this.setListViewData(this.linkedLists)
        }
        else {
          //nothing to do
        }
      }
    )
  }
}
