/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardcardBatchLineView { 
    Id?: number;
    HardcardId?: number;
    Sort?: number;
    CustomerPassePartoutId?: number;
    CustomerMembershipId?: number;
    Gender?: string | null;
    CustomerId?: number;
    CustomerNumber?: string | null;
    CustomerName?: string | null;
    CustomerType?: number;
    ExternalId?: string | null;
    Initials?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    Address?: string | null;
    ZipCode?: string | null;
    City?: string | null;
    Country?: string | null;
    Price?: number;
    Row?: string | null;
    Seat?: string | null;
    DateOfBirth?: Date | null;
    PhotoNumber?: string | null;
    PriceType?: string | null;
    DeliveryMethod?: string | null;
    DeliveryMethodCode?: string | null;
    SequenceNumber?: string | null;
    ParentVenueBuildingId?: number | null;
    SelectedSingleEntranceId?: number;
    PassePartout?: string | null;
    Block?: string | null;
    TicketType?: string | null;
    Membership?: string | null;
    MembershipValidTillDate?: string | null;
    OrderId?: number;
    readonly FullName?: string | null;
    readonly Salutation?: string | null;
}

