/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationView { 
    SalesGroupId?: number;
    Id?: number;
    CanceledByCustomerId?: number | null;
    ReservationSummary?: string | null;
    ReservationDate?: Date;
    Description?: string | null;
    UserId?: number | null;
    CustomerId?: number | null;
    UserFullName?: string | null;
    Expires?: Date | null;
    IsExpired?: boolean;
    ConvertedToOrderId?: number | null;
    ReservationTypeId?: number | null;
    ReservationTypeName?: string | null;
    CustomerName?: string | null;
    CustomerFirstName?: string | null;
    CustomerMiddleName?: string | null;
    CustomerLastName?: string | null;
    CustomerInitials?: string | null;
    readonly CustomerCalculatedName?: string | null;
    CustomerIsIdentified?: boolean;
    CustomerNumber?: string | null;
    HexColor?: string | null;
}

