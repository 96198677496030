import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ResellerDetailsView, ResellerService } from '../api';

@Injectable()
export class ResellerValidator {
    constructor(private resellerService: ResellerService) {}

    public editingObject: any;

    salesChannelCodeTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        const v = control.value;

        setTimeout(() => {
          this.resellerService.getResellerByCode(v).subscribe((data: ResellerDetailsView) => {
            if (data !== null && data !== void 0) {
              if (!this.editingObject || data.SalesChannelCode !== this.editingObject.SalesChannelCode) {
                resolve({'codetaken': data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
    }
}