import {Component, OnInit, Inject, ViewChild, AfterViewInit} from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-failure-details',
  templateUrl: './order-failure-details.component.html',
  styleUrls: ['./order-failure-details.component.scss']
})
export class OrderFailureDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('paymentOverview') paymentOverview;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<OrderFailureDetailsComponent>,
              private dialog: MatDialog) { }

  orderId: number;

  ngOnInit() {

    this.orderId = this.passedData.Id;
    //this.billingCustomer = this.passedData.customer;

  }
  ngAfterViewInit() {
      this.paymentOverview.loadPaymentHistoryForOrderFailure(this.orderId);
  }

}
