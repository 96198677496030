import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogHelper, DialogWidth, HttpLoaderService, SearchDataRequest } from '@tymes4-shared';
import { ConfirmService } from '@tymes4-shared';
import { IdentificationStatesTextMap } from "../../models/identification-status";
import { TranslateService } from '@ngx-translate/core';
import { IdentificationStateEnum, IdVerificationModel, IdVerificationModelPagedResult, IdVerificationService, IdVerificationView, UpdateIdArgs } from "../../../shared/api";
import { ManualIdentificationReasonComponent } from "../manual-identification-reason/manual-identification-reason.component";
import { ApplicationModuleHelperService } from '../../services/application-module.service';


@Component({
  selector: 'app-manual-identification',
  templateUrl: './manual-identification.component.html',
  styleUrls: ['./manual-identification.component.scss']
})
export class ManualIdentificationComponent implements OnInit {

  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 10,
    disableSort: true,
    headers: [
      { sortField: 'Created', label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.DATA', isDefaultSort: 'desc', width: 80 },
      { label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.TIME', width: 80 },
      { sortField: 'Status', label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.STATUS', width: 80 },
      { sortField: 'UserFirstName', label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.BY', width: 80 },
      { sortField: 'CustomerIdentificationType', label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.METHOD', width: 80 },
      { sortField: 'Reason', label: 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.LISTVIEW.REASON' }
    ]
  };

  identifications: IdVerificationModel = {};
  lastIdentification: any;

  public customerId: number;
  public customerName: string;
  public identificationStatus: any;
  public statusChangeReason: string;
  public startedIdentification: boolean;
  public isVerified: boolean;
  public pendingActionOfEmployee: boolean;
  public isIbaActive: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: { customerId: number, customerName: string, identificationStatus: IdVerificationView },
    public dialogRef: MatDialogRef<ManualIdentificationComponent>,
    private idVerificationService: IdVerificationService,
    private dialog: MatDialog,
    private applicationModuleHelperService: ApplicationModuleHelperService
  ) {
    this.customerId = passedData.customerId;
    this.customerName = passedData.customerName;
    this.identificationStatus = passedData.identificationStatus ? passedData.identificationStatus : {
      Reason: null,
      Status: IdentificationStateEnum.NotStarted
    };
  }

  ngOnInit(): void {
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  getIdentificationIconClass(): string {

    if (!this.identificationStatus) {
      return '';
    }

    switch (this.identificationStatus.Status) {
      case IdentificationStateEnum.NotStarted:
        return 'fas fa-file-upload text-warning pr-2';
      case IdentificationStateEnum.PendingCheck:
        return 'fas fa-clock text-warning pr-2';
      case IdentificationStateEnum.BeingChecked:
        return 'fas fa-clock text-warning pr-2';
      case IdentificationStateEnum.Verified:
        return 'fas fa-check-circle text-success pr-2';
      case IdentificationStateEnum.Rejected:
        return 'fas fa-exclamation-circle text-danger pr-2';
    }
  }

  isRejected() {
    return this.identificationStatus.Status == IdentificationStateEnum.Rejected;
  }

  OnDataRequest(e: SearchDataRequest) {
    this.idVerificationService.searchHistory(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, null, null, this.customerId).subscribe((resp: IdVerificationModelPagedResult) => {
      this.listVw.Data = resp;

      this.startedIdentification = resp.TotalRecords == 0 ? false : true;
      this.isVerified = this.identificationStatus.Status === IdentificationStateEnum.Verified;
      this.pendingActionOfEmployee = this.identificationStatus.Status === IdentificationStateEnum.PendingCheck || this.identificationStatus.Status === IdentificationStateEnum.BeingChecked;
    });
  }

  verifyCustomer() {
    this.openReasonPopUp(IdentificationStateEnum.Verified);
  }

  rejectCustomer() {
    this.openReasonPopUp(IdentificationStateEnum.NotStarted);
  }

  getStatusText(status: IdentificationStateEnum): string {
    return IdentificationStatesTextMap[status];
  }

  constructCustomerIdentificationModel() {
    return {
      CalculatedName: this.identificationStatus.CustomerCalculatedName,
      IsIdentified: this.identificationStatus.CustomerIsIdentified,
      CustomerNumber: this.identificationStatus.CustomerNumber,
      CustomerId: this.identificationStatus.Status,
    }
  }

  openReasonPopUp(identification: IdentificationStateEnum) {

    var myTitle: string;
    switch (identification) {
      case IdentificationStateEnum.Verified:
        myTitle = 'GENERIC.BUTTON.IDENTIFICATION.IDENTIFY';
        break;
      case IdentificationStateEnum.NotStarted:
        myTitle = 'GENERIC.BUTTON.IDENTIFICATION.RESTART';
        break;
    }

    const options = DialogHelper.GetDialogOptions(DialogWidth.md, {
      status: identification,
      customerId: this.customerId,
      title: myTitle
    });

    const dialogRef: MatDialogRef<any> = this.dialog.open(ManualIdentificationReasonComponent, options);

    dialogRef.afterClosed().subscribe((reload: any) => {
      if (reload) {
        this.dialogRef.close();
      }
    });

  }
}
