import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { TemplateValidator } from './template.validator';
import { TranslateService } from '@ngx-translate/core';
import { HardcardTemplate, HardcardTemplateService, SoftcardTemplate, SoftcardTemplateService } from '../../../../../shared/api';



@Component({
  selector: 't4-edit-template-form',
  templateUrl: './edit-template-form.component.html'
})
export class EditTemplateFormComponent implements OnInit {
  public form: FormGroup;

  public inSoftcardMode = false;
  public templates = [];
 
  private formObject = null;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public hardcardTemplateService: HardcardTemplateService,
  public softcardTemplateService: SoftcardTemplateService,
  public dialogRef:  MatDialogRef<EditTemplateFormComponent>,
  private templateValidator: TemplateValidator,
  private loader:HttpLoaderService,
  private translate: TranslateService) { }

  validationMessages = [];

  ngOnInit() {

    this.inSoftcardMode = this.passedData.inSoftcardMode;
    this.retrieveTemplates();

    //Create a new object to bound to the form 
    this.formObject = {};

    this.form = new FormGroup({
      Name: new FormControl('', { validators: Validators.required, asyncValidators: [this.templateValidator.TemplateNameTaken.bind(this)], updateOn: "blur" }),
      CurrentTemplateId: new FormControl(-1)
    });

    this.form.patchValue(this.formObject);
  }

  retrieveTemplates() {
    this.loader.open();
    var method = this.inSoftcardMode ? this.softcardTemplateService.listAllSoftcardTemplates() : this.hardcardTemplateService.listAllHardcardTemplates();
    method.subscribe((templates: Array<SoftcardTemplate|HardcardTemplate>) => {
      this.templates = templates;

      //add the default
      var defaultText =  { 
        Id: -1,
        Name: this.translate.instant('GENERIC.DROPDOWN.NONE')
      };

      this.templates.splice(0, 0, defaultText);
      this.loader.close();

    });
  }
 
  submit() {
    var propertyObject = this.form.value;

    if (propertyObject.CurrentTemplateId === -1) {
      propertyObject.CurrentTemplateId = null;
    }

    this.dialogRef.close(propertyObject);
  }
}
