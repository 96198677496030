import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ConfirmService, PagedResult, SnackbarService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { HardcardModel, HardcardService, HardcardStatusModel, HardcardStatusService, PassePartoutService } from '../../api';

@Component({
  selector: 'app-customer-passepartout-hardcards',
  templateUrl: './customer-passepartout-hardcards.html',
  styleUrls: ['./customer-passepartout-hardcards.scss']
})
export class CustomerPassePartoutHardcardsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<CustomerPassePartoutHardcardsComponent>,
              private confirmService: ConfirmService,
              private hardcardStatusService: HardcardStatusService,
              private hardcardService: HardcardService,
              private passePartoutService: PassePartoutService,
              private loader: HttpLoaderService,
              private snackBar: SnackbarService,
              private translate: TranslateService) {
  }

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12 ,
    noItemsText: this.translate.instant('DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.NO-ITEMS-TEXT'),
    multiSelectMode: false,
    disableSort: true,
    disableSelect: true,
    headers: [
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.VERSION', width: 80, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.BARCODE', width: 220, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.CREATED', width: 140, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.LAST.MODIFICATION', width: 175, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.BY', width: 150, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.STATUS', width: 250, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.TYPE', width: null, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.CREATE', width: 120, hideOn: null },
      { sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.LISTVIEW.HEADER.ACCESS', width: 110, hideOn: null },
    ]
  };

  hcOptions = [];

  @ViewChild('listVw') listVw;

  private hardCardId: number = null;
  private updatedStatusId: number = null;
  public statusDirty = false;
  public createDirty = false;
  public saving = false;

  public createHardCardValue = null;
  public customerPassePartout: any = null;
  public selectableStatusses: any = null;
  public hardCardStatusses: Array<HardcardStatusModel> = null;


  ngOnInit() {

    this.customerPassePartout = this.passedData.payload;

    this.hardcardStatusService.getHCStatusses().subscribe((statusses: Array<HardcardStatusModel>) => {
      this.hardCardStatusses = statusses;
      this.selectableStatusses = statusses.map(et => ({text: et.Name, ...et}));
    });
  }

  OnDataRequest(e){

    this.loader.open();

    this.passePartoutService.getPPTHardcards(this.customerPassePartout.Id).subscribe((data: Array<HardcardModel>) => {

      this.loader.close();

      let paged = data.slice((e.pageNr - 1) * e.pageSize, (e.pageNr) * e.pageSize);
      let d : PagedResult = {
        Records: paged,
        TotalRecords: data.length
      }

      this.listVw.Data = d;

    });

  }


  onStatusChange(e, hardCardId) {
    this.statusDirty = true;
    this.hardCardId = hardCardId;
    this.updatedStatusId = e.Id;
  }

  submit() {

      let statusId = this.updatedStatusId;

      //update the status
      var selectedStatus = this.hardCardStatusses.filter(function (hcs)   {
        if (hcs.Id === statusId) return hcs;
      });

      var status = selectedStatus[0];

      if (status.ExportToACL === false) {

        this.loader.open();

          this.hardcardService.getPrecedingHardcard(this.hardCardId).subscribe((hasPrecedingHardcard: boolean) => {
            this.hardcardService.getSubsequentHardcards(this.hardCardId).subscribe((hasSubsequentHardcard: boolean) => {

              this.loader.close();
              
              let msg;

              if (hasPrecedingHardcard)
              {
                msg = this.translate.instant('HARDCARD.STATUS-CHANGE.HAS-PRECEDING-HARDCARD', {passePartoutName: this.customerPassePartout.PassePartoutName});
              }
              else if (hasSubsequentHardcard)
              {
                msg = this.translate.instant('HARDCARD.STATUS-CHANGE.HAS-SUBSEQUENT-HARDCARD', {passePartoutName: this.customerPassePartout.PassePartoutName});
              }
              else
              {
                msg = this.translate.instant(status.CreateHardCard ? 'HARDCARD.STATUS-CHANGE.NO-ACCESS.NEW-HARDCARD' : 'HARDCARD.STATUS-CHANGE.NO-ACCESS');
              }

              this.confirmService.confirm({ title: 'GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE',
              message: msg,
              okonly: false}).subscribe((confirmed:boolean) => {
                if(confirmed === true) {
                  //update and close
                  this.handleStatusUpdate(status);
                }
              });

            });
          });

      }
      else {
        this.handleStatusUpdate(status);
      }
  }

  handleStatusUpdate(status) {

    this.loader.open();
    this.saving = true;

    this.hardcardService.setHardcardStatus(this.hardCardId, status.Id).subscribe(() => {
      this.loader.close();
      this.saving = false;
      this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.STATUS-UPDATED'), 'GENERIC.SNACKBAR.CLOSE');
      this.dialogRef.close(true);
    });
  }
}
