<ng-template #NewEventMessage>{{'COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.NEW-EVENT-MESSAGE' | translate}}</ng-template>

<t4-tabs (selected)="onTabChange($event)">
  <t4-tab *ngFor="let saleCategory of eventSaleCategories" [tabTitle]="saleCategory.Name" [id]="saleCategory.Id"></t4-tab>
</t4-tabs>
  
<div>
  <app-custom-button-state-list  #customButtonStateListComponent></app-custom-button-state-list>
</div>




 