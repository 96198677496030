import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConversionHelper {

    constructor(private translate: TranslateService) { 
    }

    // static decimalToString(decimal, stripThousandSeparator) {

    //     let result = decimal.toLocaleString();

    //     if (stripThousandSeparator) {
    //         var dl = this.getDecimalLocale();
    //         result = result.replace (dl.thousandSeparator, '');
    //     }

    //     return result;
    // }

    // static getDecimalLocale() {
    //     var result =  {
    //         fractionSeparator: '.',
    //         thousandSeparator: ','
    //     }

    //     if ((1.1).toLocaleString().indexOf(',') !== -1) {
    //         result.fractionSeparator = ',';
    //         result.thousandSeparator = '.';
    //     }

    //     return result;
    // }


    static fixDateTimezone(d: Date): Date {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    }

    calculateDisplayTime(expiration, unit) {

        if (!expiration || expiration === null) return null;

        if (unit == 'M') return Math.floor(expiration / 60);
        else if (unit == 'H') return Math.floor(expiration / (60 * 60));
        else if (unit == 'D') return Math.floor(expiration / (60 * 60 * 24));
        return '-';

    }

    calculateStoreExpiration(expiration, unit) {

        //console.log('in');
        //console.log(expiration);
        if (unit == 'M') return Math.floor(expiration * 60);
        else if (unit == 'H') return Math.floor(expiration * 60 * 60);
        else if (unit == 'D') return Math.floor(expiration *  60 * 60 * 24);
        return '-';

    }

    expirationText(dataEntryUnit) {
        if (dataEntryUnit == 'M') return this.translate.instant('GENERIC.MINUTES');
        else if (dataEntryUnit == 'H') return this.translate.instant('GENERIC.HOURS');
        else if (dataEntryUnit == 'D') return this.translate.instant('GENERIC.DAYS');
        return '-';
    }

    padLeft(text:string, padChar:string, size:number): string {
        return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
    }
        
    namingForSeat(ep, colIdx, rowIdx) {
        
        if(ep == null || ep.PlacementTypeId != 1) return '';
        
        let offset = 1;//(rowIdx % 2 == 0) ? 1 : 0;

        //console.log(offset);

        if (true) {//(colIdx % 2 == offset) {

            //strip leading zero's 
            let seatNr = ep.SeatNumber

            if (seatNr === null) return '';

            //no longer stripping zero's
            var unwantedCharacter = "0";
            while( seatNr.charAt(0) == unwantedCharacter ) seatNr = seatNr.substr(1);

            return seatNr;
        } 
        //else return '';
    }
}
