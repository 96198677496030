<div class="row modal-bar">

  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>

</div>
<div class="form-content">

  <div class="section-wrapper mg-t-20">
    
    <table class="table table-striped table-ticketselect">
      <tbody>
          <tr *ngFor="let pc of variants">

              <td style="font-weight: bold; text-align: right; vertical-align: middle;">
                  <ng-container *ngIf="pc.PriceVariant != null; else noprice">{{pc.PriceVariant}}</ng-container>
                  <ng-template #noprice>{{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.NO-PRICE' | translate}}</ng-template><ng-container *ngIf="pc.TotalPrice !== null">- {{pc.TotalPrice | currency:'EUR'}}</ng-container>
              </td>

              <td style="text-align: center;">
                  <div class="form-group m-0">
                      <input
                          class="form-control border text-right"
                          matInput
                          name="version"
                          type="number"
                          min="0"
                          (change)="onOrderAmountChange($event, pc.PriceVariationId)"
                          placeholder="Aantal">
                  </div>

              </td>

          </tr>
      </tbody>
    </table>
    <div class="row mt-3">
      <div class="col-lg-6"></div>
      <div class="col-lg-3"> <button  [disabled]="selectionIsEmpty" (click)="submit()" class="btn btn-block btn-primary bd-0"><i class="far fa-save"></i> {{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button></div>
      <div class="col-lg-3"><button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i> {{ 'GENERIC.BUTTON.CANCEL' | translate }}</button></div>
    </div>
  </div>
</div>