import { Deserializable } from "./deserializable";
import { Order } from "./order";

export class AppSalesState implements Deserializable <AppSalesState> {

  deserialize(input: any) : AppSalesState {
    Object.assign(this, input);
    return this;
  }

  selectedCustomer;
  order : Order;

}

