<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">

    <div class="form-layout">

      <div class="row" *ngIf="isInitiative">

        <div class="col-lg-12" >

          <div class="form-group m-0">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.BASED.ON' | translate}}<span class="tx-danger">*</span></label>
            <div class="row">
              <div class="col-4">
                <div class="mb-3 d-inline-block">
                  <label class="form-check-label">
                    <input type="radio" formControlName="RightsType" value="E" (change)="rightsTypeChanged($event)">{{'EDIT.PURCHASE.RIGHT.FORM.EVENT' | translate}}</label>
                </div>

              </div>
              <div class="col-4">
                <div class="mb-3 d-inline-block">
                  <label class="form-check-label">
                    <input type="radio" formControlName="RightsType" value="S" (change)="rightsTypeChanged($event)">{{'EDIT.PURCHASE.RIGHT.FORM.SALES-PERIOD' | translate}}</label>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row" *ngIf="isInitiative && isEvent">
        <div class="col-lg-12" >
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.FOR.EVENT' | translate}}<span class="tx-danger">*</span></label>

            <ng-select [items]="events" bindLabel="Name" bindValue="Id" formControlName="TargetEventId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.FOR.EVENT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="targetEventIdChanged($event)">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item && item.Name ? item.Name + ' - ' : '' }}{{ item.StartDateTime | localDateTime | slice:0:10 }}
              </ng-template>
            </ng-select>

            <t4-display-validation controlName="TargetEventId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isInitiative && isSalesPeriod">

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.FOR.SALES-PERIOD' | translate}}<span class="tx-danger">*</span></label>

            <ng-select [items]="salesperiods" bindLabel="Name" bindValue="Id" formControlName="SalesPeriodId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.FOR.SALES-PERIOD' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="salesPeriodIdChanged($event)"></ng-select>

            <t4-display-validation controlName="SalesPeriodId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

      </div>

      <div class="row" *ngIf="isSalesPeriod">
        <div class="col-lg-12" >
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.PASSE-PARTOUT' | translate}}<span class="tx-danger" *ngIf="isRequiredField('TargetPassePartoutId')">*</span></label>

            <ng-select [items]="targetPassePartouts" bindLabel="Name" bindValue="Id" formControlName="TargetPassePartoutId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.PASSE-PARTOUT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="targetPassePartoutChanged($event)"></ng-select>

            <t4-display-validation controlName="TargetPassePartoutId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

      </div>

       <div class="row" *ngIf="isEvent">

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.SALES.TYPE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SaleCategoryId')">*</span></label>

            <ng-select [items]="salesCategories" bindLabel="Name" bindValue="Id" formControlName="SaleCategoryId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.SALES.TYPE' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

            <t4-display-validation controlName="SaleCategoryId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

       </div>

       <div class="row">

        <div class="col-lg-12">
          <t4-date-time-control formControlName="AvailableFrom" (change)="DatesChanged($event)"  [labelDate]="'EDIT.PURCHASE.RIGHT.FORM.START-DATE.PERIOD'" [labelTime]="'EDIT.PURCHASE.RIGHT.FORM.START-TIME.PERIOD'"></t4-date-time-control>
          <t4-display-validation controlName="AvailableFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
        
       </div>

       <div class="row">

        <div class="col-lg-12">
          <t4-date-time-control formControlName="AvailableTill" (change)="DatesChanged($event)" [labelDate]="'EDIT.PURCHASE.RIGHT.FORM.END-DATE.PERIOD'" [labelTime]="'EDIT.PURCHASE.RIGHT.FORM.END-TIME.PERIOD'"></t4-date-time-control>
          <t4-display-validation controlName="AvailableTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
        
       </div>

       <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.FOR' | translate}} <span class="tx-danger" *ngIf="isRequiredField('RightsFor')">*</span></label>

            <ng-select [items]="groupSelectorTypes" bindLabel="Name" bindValue="Id" formControlName="RightsFor" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.SELECT.GROUP.PLACEHOLDER' | translate}}"
                        (change)="rightsForChanged($event)" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

                        <t4-display-validation controlName="RightsFor" [form]="form"
                        [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="form.controls['RightsFor'].value === 'H'">
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.CARD.HOLDER' | translate}} <span class="tx-danger" *ngIf="isRequiredField('OriginPassePartoutId')">*</span></label>

            <ng-select [items]="originPassePartouts" bindLabel="Name" bindValue="Id" formControlName="OriginPassePartoutId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.CARD.HOLDER' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

            <t4-display-validation controlName="OriginPassePartoutId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6"  *ngIf="form.controls['RightsFor'].value === 'G'">
          <div class="form-group">
            <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.CUSTOMER.TAG' | translate}}<span class="tx-danger" *ngIf="isRequiredField('TagId')">*</span></label>

            <ng-select [items]="tags" bindLabel="Name" bindValue="Id" formControlName="TagId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.GROUP.PLACEHOLDER' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

            <t4-display-validation controlName="TagId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

       </div>


        <div class="row">

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.VALID.IN.SALESCHANNEL' | translate}} <span class="tx-danger" *ngIf="isRequiredField('SalesChannelId')">*</span></label>

              <ng-select [items]="salesChannels" bindLabel="Name" bindValue="Id" formControlName="SalesChannelId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.SALESCHANNEL' | translate}}"
                         [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="SalesChannelIdChanged($event)"></ng-select>

              <t4-display-validation controlName="SalesChannelId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="ckbox">
                <input type="checkbox" name="RestrictToChannel" formControlName="RestrictToChannel" ><span>  {{'EDIT.PURCHASE.RIGHT.FORM.RESTRICT.TO.CHANNEL' | translate}}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-4">
            <div class="form-group mt-3">
              <label class="ckbox mt-5">
                <input type="checkbox" name="UnlimitedAmount" formControlName="UnlimitedAmount" (change)="unlimitedAmountChanged($event)"><span>  {{'EDIT.PURCHASE.RIGHT.FORM.UNLIMITED.AMOUNT' | translate}}</span>
              </label>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.AMOUNT.ABSOLUTE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('AmountAbsolute')">*</span></label>
              <input class="form-control" type="number" name="AmountAbsolute" formControlName="AmountAbsolute" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.AMOUNT.ABSOLUTE' | translate}}">
              <t4-display-validation controlName="AmountAbsolute" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group" *ngIf="form.controls['RightsFor'].value === 'H'">
              <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.AMOUNT.PER.TICKET' | translate}}<span class="tx-danger" *ngIf="isRequiredField('AmountPerTicket')">*</span></label>
              <input class="form-control" type="number" name="AmountPerTicket" formControlName="AmountPerTicket" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.AMOUNT.PER.TICKET' | translate}}">
              <t4-display-validation controlName="AmountPerTicket" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mg-t-40">
              <label class="ckbox">
                <input type="checkbox" name="Transferable" formControlName="Transferable" (change)="transferableChanged($event)"><span>  {{'EDIT.PURCHASE.RIGHT.FORM.TRANSFERABLE' | translate}}</span>
                <t4-display-validation controlName="Transferable" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </label>
            </div>
          </div>
        </div>

        <div class="row"  *ngIf="isSalesPeriod">
          <div class="col-lg-12">
            <div class="form-group mg-t-40">
              <label class="ckbox">
                <input type="checkbox" name="IsPrimary" formControlName="IsPrimary"><span>  {{'EDIT.PURCHASE.RIGHT.FORM.IS.PRIMARY' | translate}}</span>
                <t4-display-validation controlName="IsPrimary" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </label>
            </div>
          </div>
        </div>


        <div class="row">

          <div class="col-lg-4" *ngIf="isSalesPeriod">
            <div class="form-group mg-t-40">
              <label class="ckbox">
                <input type="checkbox" name="IsCrossSell" formControlName="IsCrossSell" (change)="crossSellChanged($event)"><span>  {{'EDIT.PURCHASE.RIGHT.FORM.IS.CROSSSELL' | translate}}</span>
                <t4-display-validation controlName="IsCrossSell" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </label>
            </div>
          </div>

          <div class="col-lg-4" *ngIf="form.controls['IsCrossSell'].value">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.PURCHASE.RIGHT.FORM.SECTION' | translate}} <span class="tx-danger" *ngIf="isRequiredField('VenueBuildingBlockId')">*</span></label>

              <ng-select [items]="venueBuildingBlocks | async" bindLabel="Name" bindValue="Id" formControlName="VenueBuildingBlockId" placeholder="{{'EDIT.PURCHASE.RIGHT.FORM.SECTION' | translate}}" [typeahead]="vbbInput$" loadingText="{{'EDIT.PURCHASE.RIGHT.FORM.LOADING' | translate}}"
                          [clearable]="false" notFoundText="{{'EDIT.PURCHASE.RIGHT.FORM.NO.SECTION.FOUND' | translate}}" appendTo="body" [loading]="vbbLoading" [virtualScroll]="true" typeToSearchText="{{'EDIT.PURCHASE.RIGHT.FORM.TYPE.TO.SEARCH' | translate}}"
                          (change)="VenueBuildingBlockChange($event)" [searchable]="checkSearchableCrossSell()">
              </ng-select>
              <t4-display-validation controlName="VenueBuildingBlockId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-4" *ngIf="form.controls['IsCrossSell'].value">
            <div class="form-group mt-5">
              {{ ticketTypeName }}
            </div>
          </div>

        </div>

        <div class="form-layout-footer row">
          <div class="col-6">
            <button *ngIf="!isInArchiveMode" (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>






      <!-- end new -->
<!--
        <div class="col-lg-12">

          <div class="form-group">
            <label class="form-control-label">Aankooprecht voor: <span class="tx-danger" *ngIf="isRequiredField('RightsFor')">*</span></label>
            <div class="row">
              <div class="col-4">
                <div class="mb-3 d-inline-block">
                  <label class="form-check-label">
                    <input type="radio" formControlName="RightsFor" value="G" (change)="rightsForChanged($event)">
                    Groep</label>
                </div>

              </div>
              <div class="col-4">
                <div class="mb-3 d-inline-block">
                  <label class="form-check-label">
                    <input type="radio" formControlName="RightsFor" value="H" (change)="rightsForChanged($event)">
                    Kaarthouder</label>
                </div>

              </div>
              <div class="col-4">
                <div class="mb-3 d-inline-block">
                  <label class="form-check-label">
                    <input type="radio" formControlName="RightsFor" value="P" (change)="rightsForChanged($event)">
                    Publiek</label>
                </div>

              </div>
            </div>

          </div>
        </div> -->
