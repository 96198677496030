

<t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">

  <ng-container *t4ListActions>
    <div *ngIf="!isInArchiveMode" class="object-actions bg-white text-right">
      <button (click)="startOrderProcess()" [disabled]="!orderAllowed" class="btn btn-primary border btn-small" type="button"><i class="fas fa-cart-plus"></i>{{ 'CUSTOMER.RESERVATIONS.LISTVIEW.BUTTON.CREATE-ORDER' | translate}}</button>
    </div>
  </ng-container>

  <ng-container *t4ListItem="let item">
    <td>{{ item.ReservationDate | localDateTime | slice:0:10 }}</td>
    <td>{{ item.ReservationDate | localTime }}</td>
    <td> <div class="color-picker-display" [style.background-color]="item.HexColor"></div> </td>
    <td>{{ item.ReservationTypeName }}</td>
    <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
    <td>
      <div style="overflow: hidden;text-overflow: ellipsis;">
        {{ item.ReservationSummary }}
      </div>
    </td>
    <td>{{ item.Expires | localDateTime | replaceOnEmpty:'-' }}</td>

    <td class="text-right">
      <span *ngIf="item.ConvertedToOrderId !== null" class="chipbox chipbox-closed">{{ 'CUSTOMER.RESERVATIONS.LISTVIEW.CHIPBOX.ORDER' | translate}}</span>
      <span *ngIf="item.ConvertedToOrderId === null && !item.IsExpired" class="chipbox chipbox-active">{{ 'CUSTOMER.RESERVATIONS.LISTVIEW.CHIPBOX.ACTIVE' | translate}}</span>
      <span *ngIf="item.ConvertedToOrderId === null && item.IsExpired && item.CanceledByCustomerId !== null" class="chipbox chipbox-open">{{ 'CUSTOMER.RESERVATIONS.LISTVIEW.CHIPBOX.CANCEL-BY-CUSTOMER' | translate}}</span>
      <span *ngIf="item.ConvertedToOrderId === null && item.IsExpired && item.CanceledByCustomerId === null" class="chipbox chipbox-open">{{ 'CUSTOMER.RESERVATIONS.LISTVIEW.CHIPBOX.EXPIRED' | translate}}</span>
    </td>
 
    <td class="grid-action">
      <button (click)="actionClick('open-reservation', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-format-align-justify"></button>
    </td>

  </ng-container>


</t4-list-view>
