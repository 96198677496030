import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService, EnvironmentService, LoggingService, ApplicationModules } from '@tymes4-shared';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subscriber } from 'rxjs';
import { AppConfig } from '../../models/app-config';

@Injectable()
export class ConfigServiceOld extends DataService {
  private readonly InvoiceBatchDefaultCacheKey = 'invoiceBatchDefault';
  private readonly shopBaseUriCacheKey = 'shopBaseUri';
  private readonly configCacheKey = 'appConfiguration';
  private readonly sportsAllianceOpenUserBaseUrlKey = '';

  constructor(private httpClient: HttpClient, environmentService: EnvironmentService) {
    super(httpClient, environmentService);
    this.entityName = 'config';
  }

  getBaseUri(): Observable<string> {
    const baseUri = sessionStorage.getItem(this.shopBaseUriCacheKey);
    if (baseUri === null) {
      let observer: Subscriber<string>;
      const observable = new Observable<string>((o) => {
        observer = o;
      });
      this.httpClient.get(this.getUrl() + '/base-url').pipe(catchError(this.handleError)).subscribe((uri: string) => {
        sessionStorage.setItem(this.shopBaseUriCacheKey, uri);
        observer.next(uri);
        observer.complete();
      });
      return observable;
    } else {
      return of(baseUri);
    }
  }

  getConfig(): Observable<AppConfig> {

    //migrated to V2
    const config = sessionStorage.getItem(this.configCacheKey);

    if (config === null) {
      
      let observer: Subscriber<AppConfig>;
      
      const observable = new Observable<AppConfig>((o) => {
        observer = o;
      });

      this.httpClient.get(this.getUrl()).pipe(catchError(this.handleError)).subscribe((config: AppConfig) => {
        sessionStorage.setItem(this.configCacheKey, JSON.stringify(config));
        observer.next(config);
        observer.complete();
      });
      return observable;

    } else {
      const u: AppConfig = new AppConfig();
      const c = JSON.parse(config);
      return of(u.deserialize(c));
    }
  }

  clearConfigCache() {
    sessionStorage.removeItem(this.configCacheKey);
  }
}
