import {Component, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import { EventPlacementDeliveryMethodView } from '../../api';
import { AuthService } from '../../services/auth.service';

interface ILabel {
  id: number;
  name: string;
  parentId: number;
  position: number;
}

@Component({
  selector: 'app-delivery-method-grid',
  templateUrl: './delivery-method-grid.component.html',
  styleUrls: ['./delivery-method-grid.component.scss']
})
export class DeliveryMethodGridComponent {
  public pageData = [];
  public pageRowLabels = [];

  constructor(
    private authService: AuthService
  ) {}

  @ViewChild('pagerComponent') pagerComponent;

  @Output() onChange = new EventEmitter<object>();
  @Input() parentRowIdField: string;
  @Input() rowIdField: string;
  @Input() columnIdField: string;
  @Input() rowNameField: string;
  @Input() columnNameField: string;
  @Input() data: Array<EventPlacementDeliveryMethodView> = null;

  public rowLabels: ILabel[] = [];
  public columnLabels: ILabel[] = [];
  public numberOfColumns = 0;
  public numberOfRows = 0;
  public isInitialized = false;

  public useCustomStyledCheckboxes = true;
  public isInArchiveMode = this.authService.isInArchiveMode();

  public initialize(): void {
    if (this.data === null || this.data === undefined) {
      return;
    }

    if (this.data.length === 0) {
      this.isInitialized = true;
      return;
    }
    this.rowLabels = [];
    this.columnLabels = [];
    this.numberOfColumns = 0;
    this.numberOfRows = 0;

    let rowNumber = 2;
    let columnNumber = 2;
    // Find label values
    for (const d of this.data) {
      // check if row key already exists and add it otherwise
      if (this.rowLabels.filter(rl => rl.id === d[this.rowIdField]).length === 0) {
        this.rowLabels.push({
          id: d[this.rowIdField],
          name: d[this.rowNameField],
          parentId: d[this.parentRowIdField],
          position: null
        });
      }
      // check if column key already exists and add it otherwise
      if (this.columnLabels.filter(rl => rl.id === d[this.columnIdField]).length === 0) {
        this.columnLabels.push({
          id: d[this.columnIdField],
          name: d[this.columnNameField],
          parentId: null,
          position: columnNumber++
        });
      }
    }
    // Order rows
    const baseNode = this.rowLabels.find(b => b.parentId === null || b.parentId === 0);
    this.rowLabels = this.sortAsHierarchy(this.rowLabels, baseNode);
    this.rowLabels = [baseNode, ...this.rowLabels];

    // only when placement is available
    if (this.rowLabels.length > 0 && this.rowLabels[0] !== undefined) {
      for (const r of this.rowLabels) {
        r.position = rowNumber++;
      }

      this.numberOfColumns = columnNumber;
      this.numberOfRows = rowNumber;

      const pageBaseNodes = this.rowLabels.filter(l => l.parentId === baseNode.id);
      this.pagerComponent.setPageCount(pageBaseNodes.length);
    }

    this.isInitialized = true;
  }

  private getPageData(pageNr: number) {
    const baseNode = this.data.filter(b => b[this.parentRowIdField] === null || b[this.parentRowIdField] === 0);
    const pageBaseRow = this.rowLabels.filter(l => l.parentId === baseNode[0][this.rowIdField])[pageNr - 1];
    const pageBaseNodes = this.data.filter(l => l[this.rowIdField] === pageBaseRow.id || l[this.parentRowIdField] === pageBaseRow.id);
    const pageData = [];
    if (pageNr === 1) {
      pageData.push(...baseNode);
    }

    pageData.push(...pageBaseNodes);

    return pageData;
  }

  private getRowLabels(pageNr: number) {
    const baseRow = this.rowLabels.find(b => b.parentId === null || b.parentId === 0);
    const pageBaseRow = this.rowLabels.filter(l => l.parentId === baseRow.id)[pageNr - 1];

    const pageData = [];
    if (pageNr === 1) {
      pageData.push(baseRow);
    }

    pageData.push(pageBaseRow);
    pageData.push(...this.rowLabels.filter(l => l.parentId === pageBaseRow.id));
    return pageData;
  }

  public updatePage(pageNr: number) {
    this.pageData = this.getPageData(pageNr);
    this.pageRowLabels = this.getRowLabels(pageNr);
  }

  private sortAsHierarchy(list: ILabel[], parent: ILabel): ILabel[] {
    const children: Array<ILabel> = list.filter(i => i.parentId === parent.id).sort((_a, _b) => {
      const a = _a.name;
      const b = _b.name;

      const ax = [], bx = [];

      a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || '']);
        return '';
      });
      b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || '']);
        return '';
      });

      while (ax.length && bx.length) {
        const an = ax.shift();
        const bn = bx.shift();
        const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }

      return ax.length - bx.length;
    });

    const sortedChildren = [];
    for (const c of children) {
      c.name = `${parent.name} - ${c.name}`;
      sortedChildren.push(c);
      sortedChildren.push(...this.sortAsHierarchy(list, c));
    }

    return sortedChildren;
  }

  getColumn(d) {
    const l = this.columnLabels.find(rl => rl.id === d[this.columnIdField]);
    if (l === undefined)
      console.log(d);
    return l.position;
  }

  getRow(d) {
    const l = this.rowLabels.find(rl => rl.id === d[this.rowIdField]);
    if (l === undefined)
      console.log(d);
    return l.position;
  }

  getValue(d) {
    if (d.Enabled !== null)
      return d.Enabled;

    if (d[this.parentRowIdField] !== null) {
      const parent = this.data.find(p => {
        return p[this.rowIdField] === d[this.parentRowIdField] && p[this.columnIdField] === d[this.columnIdField];
      });
      return this.getValue(parent);
    }

    return false;
  }

  updateValue(d) {
    if(this.isInArchiveMode){
      return;
    }
    switch (d.Enabled) {
      case true:
        d.Enabled = false;
        break;
      case false:
        if (d[this.parentRowIdField])
          d.Enabled = null;
        else
          d.Enabled = true;
        break;
      case null:
        d.Enabled = true;
        break;
    }
    this.onChange.emit();
  }

  isIndeterminate(d) {
    return this.getShownValue(d) === null;
  }

  getShownValue(d) {
    return d.Enabled;
  }

  getState(d) {

    return {
      'tri-state-inheritance--disabled': this.isInArchiveMode === true,
      'tri-state-inheritance--checked': !this.isIndeterminate(d) && this.getShownValue(d) === true,
      'tri-state-inheritance--unchecked': !this.isIndeterminate(d) && this.getShownValue(d) === false,
      'tri-state-inheritance--parent-checked': this.isIndeterminate(d) && this.getValue(d) === true,
      'tri-state-inheritance--parent-unchecked': this.isIndeterminate(d) && this.getValue(d) === false
    };
  }
}
