/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IdentificationStateEnum = 'NotStarted' | 'PendingCheck' | 'BeingChecked' | 'Verified' | 'Rejected';

export const IdentificationStateEnum = {
    NotStarted: 'NotStarted' as IdentificationStateEnum,
    PendingCheck: 'PendingCheck' as IdentificationStateEnum,
    BeingChecked: 'BeingChecked' as IdentificationStateEnum,
    Verified: 'Verified' as IdentificationStateEnum,
    Rejected: 'Rejected' as IdentificationStateEnum
};

