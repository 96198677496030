import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import * as moment from 'moment';

@Injectable()
export class EventValidator{
    constructor() {}

    static ValidateDates  = (group: FormGroup) => {

        if (group.controls.StartDateTime.dirty === true || group.controls.EndDateTime.dirty === true )
        {
            let startCheckDate = group.controls.StartDateTime.value;
            let endCheckDate = group.controls.EndDateTime.value;

            if (startCheckDate > endCheckDate){
                group.controls.StartDateTime.setErrors({startDateAfterEndDate: true}, {emitEvent: true});
                group.controls.EndDateTime.setErrors({endDateBeforeStartDate: true}, {emitEvent: true});

                return ({startDateAfterEndDate: true, endDateBeforeStartDate: true});
            }
            else {
                if (group.controls.StartDateTime.hasError('startDateAfterEndDate')){
                    group.controls.StartDateTime.setErrors(null, {emitEvent: true});
                }
                if (group.controls.EndDateTime.hasError('endDateBeforeStartDate')){
                    group.controls.EndDateTime.setErrors(null, {emitEvent: true});
                }
                return null;
            }
        }
    }
}
