import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class SeatPlanHelper {

    constructor(private sanitizer: DomSanitizer) { }

    getArtifactStyle(contingents, placementTypeId, sold, contingentId, reservationHexColor, accessToContingent) {

        let style = ''; 
       
        if (placementTypeId == 1 ) {
    
          let inReservation: boolean = (reservationHexColor && reservationHexColor !== null);
          let inContingent: boolean = false;
    
          let contingentColor = null;
          let statusColor = ''; //green by default
    
          if (contingentId && contingentId !== null) {
           
            //set the topleft color to contingent
            var contingents = contingents.filter(function (c)   {
              if (c.Id === contingentId) return c;
            });
    
            if (contingents.length > 0) {
              contingentColor = contingents[0].HexColor;
              inContingent = true;
            }
          }
    
          if (inReservation) statusColor = reservationHexColor;
          else if (sold) statusColor = 'rgba(183,38,55,1)'; //red
          else 
          {
            if (inContingent === true && accessToContingent === false) statusColor = 'rgba(140,140,140,1)' //Gray, the user cannot select this contingent
            else {
                statusColor = 'rgba(126, 181,78)'; //green / available
            }
          }

          //now set the bottomright color to the status
          if (inContingent && (accessToContingent === true || inReservation)) style = `background: linear-gradient(138deg, ${contingentColor} 0%, ${contingentColor} 34%,  ${statusColor} 35%, ${statusColor}  100%);`;  //style = `background: linear-gradient(315deg, ${statusColor} 0%, ${statusColor} 29%, rgba(75,75,75,1) 30%, ${contingentColor} 71%, ${contingentColor} 100%);` ;
          else style = 'background: ' + statusColor + ';'; //not in an contingent or reservation, use one color
        }
    
        //return style;
        return this.sanitizer.bypassSecurityTrustStyle(style);
    
      }

    
}
