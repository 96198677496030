/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SeatPlanPlacementColumn { 
    Id?: number;
    Column?: number;
    Row?: number;
    Name?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    IsUnavailable?: boolean;
    IsReserved?: boolean | null;
    IsSold?: boolean | null;
    IsAvailableForSale?: boolean;
    ReservationHexColor?: string | null;
    SeatRestrictionId?: number | null;
    PlacementTypeId?: number | null;
    PriceCategoryIds?: Array<number> | null;
    HasPrice?: boolean;
    ReservationId?: number | null;
    ContingentId?: number | null;
    AccessToContingent?: boolean | null;
    AvailableForSecondary?: boolean;
    MarketplacePrice?: number | null;
    MarketplaceBundled?: boolean;
    MarketplaceGrad?: number | null;
    OrderCustomerId?: number | null;
}

