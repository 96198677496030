import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpLoaderService } from '@tymes4-shared';
import { ValidationHelper } from '../../helpers/validation.helper';
import { ConstService } from '../../services/const.service';
import { MembershipImportService } from '../../api';

@Component({
  selector: 'app-select-membership-renewal-date',
  templateUrl: './select-membership-renewal-date.component.html',
})
export class SelectMembershipRenewalDateComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<SelectMembershipRenewalDateComponent>,
    private validation: ValidationHelper,
    public constants: ConstService,
    public translate: TranslateService,
    private loader: HttpLoaderService,
    private membershipImportService: MembershipImportService
  ) { }

  form: FormGroup
  public constTimeInterval;
  saving = false
  membershipId = null

  validationMessages = {
    'TimeIntervalStart': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ],
    'TimeIntervalEnd': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ]
  };

  ngOnInit(): void {
    this.membershipId = this.passedData.membershipId;

    this.constTimeInterval = this.constants.reportIntervals.map(c => ({ Id: c.value, Name: this.translate.instant(c.code) }));

    const ctrlTimeInterval = new FormControl(null, [Validators.required]);
    this.form = new FormGroup({
      TimeInterval: ctrlTimeInterval,
      TimeIntervalStart: new FormControl('', this.validation.requiredOnValueCompare(ctrlTimeInterval, 999)),
      TimeIntervalEnd:  new FormControl('', this.validation.requiredOnValueCompare(ctrlTimeInterval, 999)),
    });
    this.form.get('TimeInterval').valueChanges.subscribe((data: any) => {
      this.form.get('TimeIntervalStart').updateValueAndValidity();
      this.form.get('TimeIntervalEnd').updateValueAndValidity();
    });
  }

  isTimeIntervalCustom() {
    return this.form.controls['TimeInterval'].value == 999;
  }

  isFormInvalid() {
    return !this.form.valid || this.saving
  }

  submit() {
    this.saving = true;
    this.loader.open();
    this.dialogRef.close(this.form.value);
    this.saving = false;
    this.loader.close();
  }
}
