

<t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">

  <!-- <ng-container *t4ListActions>
    <button  class="btn btn-primary border" type="button"><i class="fa fa-cart-plus"></i>Betalen</button>
  </ng-container> -->
  <ng-container *t4ListActions>
    <div class="object-actions bg-white text-right">
      <button (click)="actionClick(Actions.FinishOrder)" [disabled]="this.selectedOrders == null || selectedOrders.length === 0 " (click)="actionClick('add')" class="btn btn-primary border btn-small" type="button"><i class="far fa-credit-card"></i>{{'GENERIC.BUTTON.PAY' | translate}}</button>
    </div>
  </ng-container>

  <ng-container *t4ListItem="let item">
    <th style="width: 70px">#{{ item.Id }}</th>

    <td style="width: 70px">{{ item.OrderDate | localDateTime | slice:0:10 }}</td>
    <td style="width: 50px">{{ item.OrderDate | localTime }}</td>

    <td style="width: 180px"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>

    <td style="width: 100px" class="text-right">{{ item.TotalInclVat | currency:'EUR':'symbol' }}</td>
    <td style="width: 100px" class="text-right">{{ (item.TotalInclVat - item.OrderBalance) | currency:'EUR':'symbol' }}</td>
    <td style="width: 100px" class="text-right">{{ item.OrderPendingPaymentBalance | currency:'EUR':'symbol' }}</td>

    <td style="width: 100px" class="text-right">{{ (item.OrderBalance - item.OrderPendingPaymentBalance) | currency:'EUR':'symbol' }}</td>

    <td>{{ item.Reference }}</td>
    <td style="width: 146px" class="text-right">
      <span *ngIf="(item.OrderBalance - item.OrderPendingPaymentBalance) < 0.01" class="chipbox chipbox-closed">{{'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.BALANCE-CLOSED' | translate}}</span>
      <span *ngIf="(item.OrderBalance - item.OrderPendingPaymentBalance) >= 0.01" class="chipbox chipbox-open">{{'SALES.CASH-REGISTER.ORDER-OVERVIEW.LISTVIEW.BALANCE-OPEN' | translate}}</span>
    </td>


    <td class="grid-action" >
      <button  (click)="openDialogFromLine(item.Id)"  type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-eye"></button>
    </td>

  </ng-container>
</t4-list-view>
