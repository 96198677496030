/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CrossSystemCheckErrorsRowView { 
    EventPlacementId?: number;
    SectionName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    Message?: string | null;
    Error?: string | null;
}

