import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ConfirmService, ListViewSelectionMode, PagedResult} from '@tymes4-shared';
import {EditResellerContactComponent} from '../../dialogs/edit-reseller-contact/edit-reseller-contact.component';
import { TranslateService } from '@ngx-translate/core';
import { ResellerService, SalesChannelContactView } from '../../api';

@Component({
    selector: 'app-reseller-contacts',
    templateUrl: './reseller-contacts.component.html',
  })
  export class ResellerContactsComponent implements OnInit {

    private currentResellerId: number = null;

    constructor(private resellerService: ResellerService,
            private dialog: MatDialog,
            private confirmService: ConfirmService,
            private translate: TranslateService) {}

    @Input()
    set resellerId(id: number) {
        this.currentResellerId = id
    }

    @Input() readonlyMode = false;

    @ViewChild('listVw') listVw;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 12,
        noItemsText: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.NO-ITEMS-TEXT'),
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        headers: [
           { sortField: null, label: 'COMPONENTS.RESELLER-CONTACTS.LISTVIEW.HEADER.ACCOUNT', hideOn: null },
           { sortField: null, label: 'COMPONENTS.RESELLER-CONTACTS.LISTVIEW.HEADER.E-MAIL', width: 180, hideOn: null },
           { sortField: null, label: 'COMPONENTS.RESELLER-CONTACTS.LISTVIEW.HEADER.ROLES', width: 180, hideOn: null },
           { sortField: null, label: 'COMPONENTS.RESELLER-CONTACTS.LISTVIEW.HEADER.PRIMARY-CONTACT', width: 60, hideOn: null },
           { label: '' },
        ]
      };

    ngOnInit() {

    }

    OnDataRequest($event) {
        this.resellerService.getResellerContacts(this.currentResellerId).subscribe((contacts: Array<SalesChannelContactView>) => {
            let d : PagedResult = {
              TotalRecords: contacts.length,
              Records: contacts
            };

            this.listVw.Data = d;
          });
    }

    onActionSelected(action, item = null) {
        if (action === 'add') {
          this.openDialog(item);
        } else if (action === 'edit') {
            this.openDialog(item);
        } else if (action === 'delete') {
          this.deleteItem(item);
        }
    }

    openDialog(item) {
        let isNew = item == null;
        let resellerContact = item;

        let dialogRef: MatDialogRef<any> = this.dialog.open(EditResellerContactComponent, {
          width: '720px',
          disableClose: true,
          data: { title: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.OPEN-DIALOG.TITLE.LINK-USER'), resellerId: this.currentResellerId, isNew: isNew, resellerContact: resellerContact}
        })
        dialogRef.afterClosed()
          .subscribe(resp => {
            this.listVw.refreshData();
          }
        )
      }


  deleteItem(item) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.CONFIRM.WARNING.MESSAGE.DELETE-USER'),
      okonly: false
    }).subscribe((confirmed:boolean) => {
      if(confirmed) {
        this.resellerService.removeResellerContact(this.currentResellerId, item.ContactId).subscribe((data : string) => {
          if (data != null && data.length > 0)
          {
            this.confirmService.confirm({
              title: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.CONFIRM.TITLE.CONTACT-PERSON-NOT-DELETED'),
              message: data,
              okonly: true
            }).subscribe((confirmed:boolean) => {
            });
          }
          else
          {
            this.listVw.refreshData();
          }
        })
      }
    });
  }

  TogglePrimaryContact(contactId: number, checked: boolean)  {
    
    if (checked)
    {
      this.confirmService.confirm({
        title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
        message: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.CONFIRM.WARNING.MESSAGE.MAKE-USER-PRIMARY-CONTACT-PERSON'),
        okonly: false
      }).subscribe((confirmed:boolean) => {
        if(confirmed) {
          this.resellerService.setResellerPrimaryContact(this.currentResellerId, contactId).subscribe(() => {
            this.listVw.refreshData();
          })
        }
        else {
          this.listVw.refreshData();
        }
      });
    }
    else {
      this.confirmService.confirm({
        title: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.CONFIRM.ERRORS.TITLE.PRIMARY-CONTACT-PERSON-REQUIRED'),
        message: this.translate.instant('COMPONENTS.RESELLER-CONTACTS.CONFIRM.ERRORS.MESSAGE.PRIMARY-CONTACT-PERSON-REQUIRED'),
        okonly: true
      }).subscribe((confirmed:boolean) => {
        if(confirmed) {
          this.listVw.refreshData();
        }
      });
    }
  }


}
