import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {CreateRebuyExportComponent} from 'app/shared/dialogs/create-rebuy-export/create-rebuy-export.component';
import {UploadRepurchaseImportfileComponent} from 'app/shared/dialogs/upload-repurchase-importfile/upload-repurchase-importfile.component';
import {ListViewSelectionMode, NotificationCommunicationService} from '@tymes4-shared';
import {GenericErrorHandler} from 'app/shared/helpers/generic-error-handler';
import {HttpLoaderService} from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import {ConfirmReservationImportComponent} from '../../dialogs/confirm-reservation-import/confirm-reservation-import.component';
import {DialogHelper, DialogWidth} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { ActiveReservationLineView, ActiveReservationLineViewPagedResult, CreateRepurchaseExportArgs, EventRepurchaseLineService, EventRepurchaseLineViewPagedResult, EventService, LongRunningOperationModel, LongRunningOperationTypeEnum, RepurchaseService, ReservationService, ReservationViewPagedResult, SalesPeriodRepurchaseLineService, SalesPeriodRepurchaseLineViewPagedResult, SalesPeriodService } from '../../api';
import {LongRunningOperationService, OrderImportProcessArgs, OrderImportService} from '../../api';
import { UploadOpenReservationsImportfileComponent } from '../../dialogs/upload-open-reservations-importfile/upload-open-reservations-importfile.component';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-open-reservations',
  templateUrl: './open-reservations.component.html',
  styleUrls: ['./open-reservations.component.scss']
})
export class OpenReservationsComponent implements OnInit {

  @ViewChild('listVw') listVw;
  @ViewChild('progressBar') progressBar;

  public isSalesPeriod: boolean = false;
  public isEvent: boolean = false;

  public listOptions = {};

  public deletionAllowed: boolean = false;
  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(private dialog: MatDialog, private loader: HttpLoaderService,
              private errorHandler: GenericErrorHandler,
              private confirmService: ConfirmService,
              private reservationService: ReservationService,
              private translate: TranslateService,
              private notificationCommunicationService : NotificationCommunicationService,
              private authService: AuthService) {
  }


  private spId: number;
  public evId: number;
  private selectedItems = [];
  public processableCount = 0;
  public latestLongRunningOperation = null;
  public longRunningOperationTypeEnum = LongRunningOperationTypeEnum;


  @Input()
  set salesPeriodId(id: number) {
    this.spId = id;
    this.isSalesPeriod = true;
    this.loadListOptions();
  }

  @Input()
  set eventId(id: number) {
    this.evId = id;
    this.isEvent = true;
    this.loadListOptions();
  }

  ngOnInit() {
    this.updateStatus();
  }

  loadListOptions() {
    this.listOptions = {
      search: true,
      showBarcodeSearch: false,
      pageSize: 12,
      noItemsText: this.translate.instant('COMPONENTS.REPURCHASE-RESERVATIONS.NO-ITEMS-TEXT'),
      selectionMode: ListViewSelectionMode.None,
      disableSort: true,
      actionDropdownWidth: '300px',
      headers: [
        {sortField: 'Id', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ID', width: 50, hideOn: null},
        {sortField: 'SectionName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.SECTION', width: 70, hideOn: null, isDefaultSort: 'asc'},
        {sortField: 'RowNumber', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ROWNUMBER', width: 30, hideOn: null},
        {sortField: 'SeatNumber', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.SEATNUMBER', width: 30, hideOn: null},
        {sortField: 'AllowSeatSelection', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ALLOW.SEAT-SELECTION', width: 70, hideOn: null},
        {sortField: 'ToPassePartoutName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.PASSE-PARTOUT', width: 300, hideOn: null, visible: this.isSalesPeriod },
        {sortField: 'CustomerName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.CUSTOMER', hideOn: null},
        {sortField: 'ExpiryDate', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.EXPIRY-DATE', width: 120, hideOn: null},
        {sortField: 'Reason', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.REASON', width: 300, hideOn: null},
        {sortField: 'Status', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.STATUS', width: 300, hideOn: null},
      ]
    };
  }




  OnDataRequest(e) {
    this.loader.open();
    if (this.isSalesPeriod) {
      let additionalFilters : { [key: string]: string; } = {};
      if (this.spId != null)
      {
          additionalFilters['salesPeriodId'] = this.spId.toString();
      }

      this.reservationService.searchReservationsForSalesPeriod(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, additionalFilters).subscribe((resp: ActiveReservationLineViewPagedResult) => {
        this.loader.close();
        this.listVw.Data = resp;

      });
    } else if (this.evId) {
      this.reservationService.searchReservationsForEvent(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { ['eventId']: `${this.evId}` }).subscribe((resp: ActiveReservationLineViewPagedResult) => {
        this.loader.close();
        this.listVw.Data = resp;
      });
    } else {
      this.loader.close();
    }
  }

  updateStatus(): any {
    let spOrEventId = this.spId ? this.spId : this.evId;
    let type = this.spId ? 1 : 2;
    this.reservationService.getChangedEventReservationLineCount(type, spOrEventId).subscribe((count: number) => {
      this.processableCount = count;
        },
        error => {
          this.errorHandler.handle(error);
      });
  }

  progressFinished($event) {
    //refresh data and status
    this.listVw.refreshData();
  }

  getProcessable() {
    return this.translate.instant('RESERVATIONS.BUTTON.EDIT.RESERVATIONS', {count: this.processableCount})

  }
  
  exportList() {
    this.loader.open();

    const spOrEventId = this.isEvent ? this.evId : this.spId;
    this.reservationService.openReservationsCreateExportList(spOrEventId, this.isEvent).subscribe(data => {
        this.notificationCommunicationService.updateNotificationsAndLROS();
        this.loader.close();
      },
      error => {
        this.errorHandler.handle(error);
      }
    );
  }

  importOpenReservations() {
    let title = this.translate.instant('OPEN-RESERVATIONS.RESERVATIONS.IMPORT.OPEN-RESERVATIONS.FILE');
    let dialogRef: MatDialogRef<any> = this.dialog.open(UploadOpenReservationsImportfileComponent, {
      width: '720px',
      disableClose: true,
      data: {title: title, type: this.spId ? 1 : 2}
    })
    dialogRef.afterClosed()
      .subscribe(lro => {

          if(lro!=null){
            this.notificationCommunicationService.updateNotificationsAndLROS();
          }
        }
      )
  }

  deleteUnprocessed(){

    let msg = this.translate.instant('REPURCHASE.RESERVATIONS.DELETE.UNPROCESSED.MESSAGE');

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: msg,
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (confirmed === true) {

        this.loader.open();

        let spOrEventId = this.spId ? this.spId : this.evId;
        let type = this.spId ? 1 : 2;
        this.reservationService.deleteUnprocessedReservations(type, spOrEventId).subscribe(resp => {
          this.loader.close();
          this.refreshListAndButtons();
          this.selectedItems = null;
          }, error => {
            this.errorHandler.handle(error);
        });
      }
    });
    ;
    
  }

  processReservations() {
    let spOrEventId = this.spId ? this.spId : this.evId;
    let type = this.spId ? 1 : 2;

    if (this.processableCount === 0) {
      return;
    }
    const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.md, {
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      payload: {
        processableCount: this.processableCount,
        showGroupBy: false,
      }
    });
    this.dialog.open(ConfirmReservationImportComponent, dialogOptions)
      .afterClosed().subscribe((result: boolean | { groupByCustomer: boolean }) => {
      if (typeof result === "boolean") {
        return;
      }

      this.loader.open();
      this.reservationService.processOpenReservations(type, spOrEventId).subscribe(data => {
        this.loader.close();
        this.notificationCommunicationService.updateNotificationsAndLROS();
      },
      error => {
        this.errorHandler.handle(error);
      }
    );
    });
  }
  refreshListAndButtons(){
    this.updateStatus();
    this.listVw.refreshData();
  }
}
