import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FileService, HttpLoaderService, PagedResult } from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ListViewSelectionMode} from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import {TranslateService} from "@ngx-translate/core";
import { VenueBuildingBlockService } from '../../api';


@Component({
  selector: 'app-venue-edit-change-log',
  templateUrl: './venue-edit-change-log.component.html',
  styleUrls: ['./venue-edit-change-log.component.scss']
})
export class VenueEditChangeLogComponent implements OnInit {

  // for listview
  @ViewChild('passepartoutEventQueueListVw') listVw;

  public pptEventQueueListOptions = {
    search: false,
    pageSize: 12,
    disableSort: true,
    renderInCard: false,
    noItemsText: this.translate.instant('DIALOGS.VENUE-EDIT-CHANGE-LOG.NO-ITEMS-TEXT'),
    selectionMode: ListViewSelectionMode.None,
    headers: [
      {sortField: null, label: '', width: 60, hideOn: null},
      {sortField: null, label: '', width: 120, hideOn: null},
      {sortField: null, label: this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.HEADER.DATE'), width: 120, hideOn: null},
      {sortField: null, label: this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.HEADER.PERSON'), width: null, hideOn: null},
      {sortField: null, label: this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.HEADER.CHANGES'), width: 150, hideOn: null},
      {sortField: null, label: this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.HEADER.WARNING'), width: 150, hideOn: null},
      {sortField: null, label: this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.LISTVIEW.HEADER.MISTAKES'), width: 150, hideOn: null},
      {sortField: null, label: '', width: 90, hideOn: null},
    ]
  };
  // end for listview

  public logRecords = [];
  public vbbId: number = 0;
  private pageNr = 0;
  private  pageSize = 0

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<VenueEditChangeLogComponent>,
              private loader: HttpLoaderService,
              private vbbService: VenueBuildingBlockService,
              private fileHelper: FileService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.logRecords = this.passedData.logRecords;
    this.vbbId = this.passedData.vbbId;
  }

  onDataRequest(e) {

    this.pageNr = e.pageNr;
    this.pageSize = e.pageSize;

    let paged = this.logRecords.slice((this.pageNr - 1) * this.pageSize, (this.pageNr) * this.pageSize);

    const data : PagedResult = {
      TotalRecords: this.logRecords.length,
      Records: paged
    };
  
    this.listVw.Data = data;
  }



  downloadReport(logId) {

   this.loader.open();

    this.vbbService.getChangeReport(this.vbbId, logId).subscribe((data) => {
      this.loader.close();
      FileSaver.saveAs(data, this.fileHelper.makeFileNameSafe(`Change-report-${this.vbbId}`) + this.fileHelper.getFileExtension('excel'));
    });
  }

}
