import {Directive, ElementRef, OnInit, Input, ChangeDetectorRef, forwardRef, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// Documentation: https://github.com/simontabor/jquery-toggles/

@Directive({selector: 'input[type=checkbox][appToggle]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleDirective),
      multi: true
    }
  ]})
export class ToggleDirective implements OnInit, ControlValueAccessor {

  @Input() onText: string = this.translate.instant('DIRECTIVES.TOGGLE-DIRECTIVE.ONTEXT');
  @Input() offText: string = this.translate.instant('DIRECTIVES.TOGGLE-DIRECTIVE.OFFTEXT');
  @Input() disabled: boolean = false;


  private toggleEl: any = null;
  private toggle: any = null;

  onChange = (val: number) => {};
  onTouched = () => {};

  constructor(private el: ElementRef, private translate : TranslateService) {}

  ngOnInit() {
    const container = $('<div class="toggle-wrapper">');
    $(this.el.nativeElement).after(container);
    this.toggleEl = $('<div class="toggle toggle-modern orange">').appendTo(container);
    $(this.el.nativeElement).hide();
    this.toggleEl.toggles({
      drag: false,
      checkbox: $(this.el.nativeElement),
      text: {
        on: this.onText,
        off: this.offText
      },
      on: this.el.nativeElement.value,
    });
    this.toggleEl.toggleClass('disabled', this.disabled)
    this.toggle = this.toggleEl.data('toggles');
    this.toggleEl.on('toggle', (e, active) => this.onChange(active));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.toggle.toggle(obj);
  }
}
