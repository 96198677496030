import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-pendingorder-debtor',
  templateUrl: './select-pendingorder-debtor.component.html',
  styleUrls: ['./select-pendingorder-debtor.component.scss']
})
export class SelectPendingorderDebtorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<SelectPendingorderDebtorComponent>,
    private loader: HttpLoaderService
  ) { }

  public form: FormGroup;
  public availableDebtors;
  public saving = false;

  ngOnInit() {
    this.availableDebtors = this.passedData.payload;



    this.form = new FormGroup({
      debtorId: new FormControl(this.availableDebtors[0].Id)
    });

  }

  submit() {
    var o = this.form.value;
    this.saving = true;
    this.dialogRef.close(o.debtorId);
  }

}
