<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.REPORT-CATEGORY' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="availableReportCategories" bindLabel="Name" bindValue="Id" formControlName="ReportCategory" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.REPORT-CATEGORY' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="onChangeReportCategories($event)"></ng-select>
            <t4-display-validation controlName="ReportCategory" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.REPORT' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="availableReports" bindLabel="Name" bindValue="Id" formControlName="Report" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.REPORT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="onChangeReports($event)"></ng-select>
            <t4-display-validation controlName="Report" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.REPORT-NAME' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" name="name" formControlName="Name" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.REPORT-NAME' | translate}}" maxlength="200">
            <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('TIME_INTERVAL')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.TIME-INTERVAL' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="this.constTimeInterval" bindLabel="Name" bindValue="Id" formControlName="TimeInterval" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.TIME-INTERVAL' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            <t4-display-validation controlName="TimeInterval" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="isTimeIntervalCustom()">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.STARTING-DATE' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" datepicker formControlName="TimeIntervalStart">
            <t4-display-validation controlName="TimeIntervalStart" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="isTimeIntervalCustom()">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.END-DATE' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" datepicker formControlName="TimeIntervalEnd">
            <t4-display-validation controlName="TimeIntervalEnd" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('PRICE')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.SELLING-PRICE' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="this.constSellPriceType" bindLabel="Name" bindValue="Id" formControlName="SellPriceType" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.SELLING-PRICE' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            <t4-display-validation controlName="SellPriceType" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="isSellPriceCustom()">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.FROM-PRICE' | translate}}<span class="tx-danger">*</span></label>
            <input currencyformat class="form-control" name="PriceFrom" formControlName="PriceFrom" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.FROM-PRICE' | translate}}" maxlength="15">
            <t4-display-validation controlName="PriceFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="isSellPriceCustom()">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.TO-PRICE' | translate}}<span class="tx-danger">*</span></label>
            <input currencyformat class="form-control" name="PriceTill" formControlName="PriceTill" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.TO-PRICE' | translate}}" maxlength="15">
            <t4-display-validation controlName="PriceTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('INITIATIVE')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.INITIATIVE' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectableInitiatives" bindLabel="Name" bindValue="Id" formControlName="InitiativeId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.INITIATIVE' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            <t4-display-validation controlName="InitiativeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('PRODUCT')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.PRODUCT' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="productTypeList" bindLabel="Name" bindValue="Id" formControlName="Product" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.PRODUCT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            <t4-display-validation controlName="Product" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('EVENT_SINGLE') || form.value.Product === 1">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.EVENT' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectableEvents" bindLabel="Name" bindValue="Id" formControlName="EventId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.EVENT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item && item.Id > 0 ? item.Id + ' - ' : '' }}{{ item && item.Name ? item.Name + (item.StartDateTime ? ' - ' : '') : '' }}{{ item.StartDateTime | localDate }}
              </ng-template>
            </ng-select>
            <t4-display-validation controlName="EventId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('PASSEPARTOUT_SINGLE')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.PASSE-PARTOUT' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectablePassePartouts" bindLabel="Name" bindValue="Id" formControlName="PassePartoutId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.PASSE-PARTOUT' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.Name }}
              </ng-template>
            </ng-select>
            <t4-display-validation controlName="PassePartoutId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('SEASON')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.SEASON' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectableSeasons" bindLabel="Name" bindValue="Id" formControlName="SeasonId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.SEASON' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            <t4-display-validation controlName="SeasonId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="allowedParams.includes('FANCLUB')">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.FANCLUB' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectableFanSalesChannel" bindLabel="Name" bindValue="Id" formControlName="SalesChannelId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.FANCLUB' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.Name }}
              </ng-template>
            </ng-select>
            <t4-display-validation controlName="SalesChannelId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
      </div>

      <div class="col-lg-14" *ngIf="allowedParams.includes('TICKET_TYPE')">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.TICKET-TYPES' | translate}}</label>
          <ng-select  [items]="selectableTicketTypes" bindLabel="Name" bindValue="Id" formControlName="TicketTypes" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.TICKET-TYPES' | translate}}"
          [clearable]="true" [multiple]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
        </div>
      </div>

      <div class="col-lg-14" *ngIf="allowedParams.includes('LINKED_MEMBERSHIP')">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.LINKED-MEMBERSHIP-TYPES' | translate}}<span class="tx-danger">*</span></label>
          <ng-select  [items]="selectableMembrsipTypes" bindLabel="Name" bindValue="Id" formControlName="RecurringMembershipTypeId" (change)="onChangeSpecificMembership($event)" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.LINKED-MEMBERSHIP-TYPES' | translate}}"
          [clearable]="false" [multiple]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="RecurringMembershipTypeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>

      <div class="col-lg-14" *ngIf="allowedParams.includes('RECURRING_MEMBERSHIP_TYPE')">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.RECURRING-MEMBERSHIP-TYPES' | translate}}<span class="tx-danger">*</span></label>
          <ng-select  [items]="selectableMembrsipTypes" bindLabel="Name" bindValue="Id" formControlName="RecurringMembershipTypeId" (change)="onChangeSpecificMembership($event)" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.MEMBERSHIP-TYPES' | translate}}"
          [clearable]="false" [multiple]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="RecurringMembershipTypeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>
      <div class="col-lg-14" *ngIf="allowedParams.includes('MATCHDAYPASS_TYPE')">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.MATCHDAYPASS_TYPE' | translate}}<span class="tx-danger">*</span></label>
          <ng-select  [items]="selectableMatchDayPassTypes" bindLabel="Name" bindValue="Id" formControlName="MatchDaypassTypeId" (change)="onChangeSpecificMatchDayPass($event)" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.MATCHDAYPASS_TYPE' | translate}}"
          [clearable]="false" [multiple]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="MatchDaypassTypeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>
      <div class="col-lg-14" *ngIf="isSelectSpecificRecurringMembership">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.RECURRING-MEMBERSHIPS' | translate}}<span class="tx-danger">*</span></label>
          <ng-select  [items]="selectableRecurringMemberships" bindLabel="Name" bindValue="Id" formControlName="MembershipId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.RECURRING-MEMBERSHIP-TYPES' | translate}}"
          [clearable]="false" [multiple]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="MembershipId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>
      <div class="col-lg-14" *ngIf="allowedParams.includes('MEMBERSHIP') || isSpecificMembership">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.MEMBERSHIP' | translate}}<span class="tx-danger">*</span></label>
          <ng-select [items]="selectableMemberships" bindLabel="Name" bindValue="Id" formControlName="MembershipId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.MEMBERSHIP' | translate}}"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="MembershipId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>
      <div class="col-lg-14" *ngIf="allowedParams.includes('MATCHDAYPASS') && isSpecificMatchDayPass">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.LABEL.MATCHDAYPASS' | translate}}<span class="tx-danger">*</span></label>
          <ng-select [items]="selectableMatchDayPasses" bindLabel="Name" bindValue="Id" formControlName="MatchDayPassId" placeholder="{{'DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PLACEHOLDER.MATCHDAYPASS' | translate}}"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          <t4-display-validation controlName="MatchDayPassId" [form]="form" [messages]="validationMessages"></t4-display-validation>
        </div>
      </div>


      <div class="form-layout-footer row">
        <div class="col-4">
          <button (click)="download('excel')" [disabled]="formIsInvalid()" class="btn btn-block btn-primary bd-0"><i
            class="far fa-file-excel"></i><span>{{'GENERIC.BUTTON.DOWNLOAD-AS-EXCEL' | translate}}</span></button>
        </div>
        <div class="col-4">
          <button (click)="submit()" [disabled]="formIsInvalid() || isInArchiveMode" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-4">
          <button (click)="dialogRef.close(null);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
