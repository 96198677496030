

  <!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<!-- MODAL BODY -->

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">
        <div class="col-lg-12">
          <div class="form-group">
              <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.ORDER-REFFERENCE' | translate}}<span class="tx-danger">*</span></label>
              <input class="form-control" name="Reference" formControlName="Reference" placeholder="{{'SALES-ENTITY-MODIFICATION.DIALOG.PLACEHOLDER.ORDER-REFFERENCE' | translate}}" maxlength="128">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.DELIVERY-METHOD' | translate}}<span class="tx-danger"></span></label>
            <ng-select [appendTo]="'body'" [items]="deliveryMethods" bindLabel="Name" bindValue="Id" formControlName="DeliveryMethodId" placeholder="{{'DIALOGS.CONFIRM-ORDER-MODIFICATION.FORM.PLACEHOLDER.DELIVERY-METHOD' | translate}}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.SALES-CHANNELS' | translate}}<span class="tx-danger"></span></label>
            <ng-select [appendTo]="'body'" [items]="salesChannels" bindLabel="Name" bindValue="Id" formControlName="SalesChannelId" placeholder="{{'DIALOGS.CONFIRM-ORDER-MODIFICATION.FORM.PLACEHOLDER.SALES-CHANNEL' | translate}}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="showCreditQuestion">
          <div class="form-group">
            <div class="form-group">
              <label class="form-control-label d-block">{{'DIALOGS.CONFIRM-ORDER-MODIFICATION.FORM.LABEL.CREDIT-EXISTING-INVOICE' | translate}}<span class="tx-danger">*</span></label>

              <div class="form-check mr-3 mb-3 d-inline-block">
                <input class="form-check-input" type="radio" id="CreateCreditInvoiceY" formControlName="CreateCreditInvoice" value="1">
                <label class="form-check-label">
                  {{'GENERIC.BOOLEANTYPE.YES' | translate}}
                </label>
              </div>

              <div class="form-check mr-3 mb-3 d-inline-block">
                <input class="form-check-input" type="radio" id="CreateCreditInvoiceN" formControlName="CreateCreditInvoice" value="0">
                <label class="form-check-label">
                  {{'GENERIC.BOOLEANTYPE.NO' | translate}}
                </label>
              </div>

            </div>
          </div>
        </div>


      </div><!-- row -->

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>






