import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

@Injectable()
export class CrossSellProductEventValidator {
  constructor() {}

  static ValidateFormat  = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve, reject) => {
      const isValid = control.value === '' || moment(control.value, 'HH:mm', true).isValid();

      if (isValid === true) {
          return resolve(null);
      } else {
          return resolve({invalidFormat: true});
      }
    });
  }

  static ValidateDates  = (group: FormGroup) => {
    if
     (group.controls.OnSaleFrom.dirty === true || group.controls.OnSaleTill.dirty === true) {

      const startDate = group.controls.OnSaleFrom.value;
      const endDate = group.controls.OnSaleTill.value

      if (startDate > endDate) {

          group.controls.OnSaleFrom.setErrors({startDateAfterEndDate: true}, {emitEvent: true});
          group.controls.OnSaleTill.setErrors({endDateBeforeStartDate: true}, {emitEvent: true});

          return ({startDateAfterEndDate: true, endDateBeforeStartDate: true});
      } else {
          if (group.controls.OnSaleFrom.hasError('startDateAfterEndDate')) {
              group.controls.OnSaleFrom.setErrors(null, {emitEvent: true});
          }
          if (group.controls.OnSaleTill.hasError('endDateBeforeStartDate')) {
              group.controls.OnSaleTill.setErrors(null, {emitEvent: true});
          }
          return null;
      }
    }
  }
}
