/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PricingMethodEnum = 1 | 2 | 3;

export const PricingMethodEnum = {
    NUMBER_1: 1 as PricingMethodEnum,
    NUMBER_2: 2 as PricingMethodEnum,
    NUMBER_3: 3 as PricingMethodEnum
};

