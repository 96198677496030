/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LoyaltyTagView { 
    Id?: number;
    Code?: string | null;
    Name?: string | null;
    Active?: boolean;
    ActiveUntil?: Date | null;
    Score?: number;
    HasTag?: boolean | null;
    ShowInTicketshop?: boolean;
    TicketshopName?: string | null;
    ShowFrom?: Date | null;
    ShowTill?: Date | null;
    ShowAwardDate?: boolean;
    AwardDate?: Date;
    SubDescription?: string | null;
}

