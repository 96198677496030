import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { HelperService } from '../../components/common/helper-service';
import { VenueBuildingBlockService } from '../../api';

@Component({
  selector: 'app-edit-unplaced-tickets',
  templateUrl: './edit-unplaced-tickets.component.html',
  styleUrls: ['./edit-unplaced-tickets.component.scss']
})

export class EditUnplacedTicketsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditUnplacedTicketsComponent>,
              private logging : LoggingService,
              private venue: VenueBuildingBlockService,
              private loader:HttpLoaderService,
              private helperService : HelperService) { }

  private editingObject = null;
  public isNew = false;
  public saving = false;
  public form: FormGroup;
  public priceCategories =  null;
  selectedEndpoints = [];
  private parentId = 0;

  validationMessages = {
    'NrOfTickets  ': [
      { type: 'pattern', message: 'FORM.VALIDATION.NUMERIC' },
      { type: 'max', message: 'FORM.VALIDATION.MAX30000' },
    ]
  }

  ngOnInit() {

    this.editingObject = this.passedData.payload;
    this.isNew = this.passedData.isNew;
    this.parentId = this.editingObject.ParentId;

    if (!this.isNew) {

      this.selectedEndpoints = this.editingObject.endpoints;

      //when no pricecategory is set we need to select the inherited
      if (this.editingObject.PriceCategoryId == null) this.editingObject.PriceCategoryId = -1;

    }
    else {
      this.selectedEndpoints = [];
    }



     //retrieve the selectable priceCategories
     this.updateSelectablePriceCategories();

     //set the defaults
      this.editingObject.ImageId = null;
      this.editingObject.SeatRestrictionId = null;

      if (this.passedData.payload.currentTicketAmount && this.passedData.payload.currentTicketAmount !== null) {
        this.editingObject.NrOfTickets = this.passedData.payload.currentTicketAmount;
      }
      else {
        this.editingObject.NrOfTickets = 30; //doesnt do anything when isnew = false but still required
      }
      
      this.editingObject.PriceCategoryId = null;

    this.form = new FormGroup({
      ParentId: new FormControl(''),
      //Name: new FormControl('', Validators.required),
      Price: new FormControl({ value: '', disabled: true } ),
      TicketTypeId: new FormControl(''),
      ImageId: new FormControl({ value: '', disabled: true }),
      //RestrictedView: new FormControl(''),
      NrOfTickets: new FormControl('', [ Validators.min(0), Validators.max(30000), Validators.required, Validators.pattern('^[0-9]*$') ]), //Passed to preserve value
      //PriceCategoryId: new FormControl('', Validators.required), //Passed to preserve value
    });

    this.form.patchValue(this.editingObject);

  }

  selectablePriceCategories = null;


  updateSelectablePriceCategories() {
    //retrieve the selectable priceCategories
    this.venue.listSelectablePriceCategories(this.parentId).subscribe((data:any) => {
      this.helperService.setInheritedPriceCategoryNames(data);
      this.priceCategories = data;
      this.selectablePriceCategories = data;
    });

  }

  submit() {

    var o = this.form.value;

    //when inherited is selected we need to send null
    if (o.PriceCategoryId == -1) o.PriceCategoryId = null;

    this.saving = true;
    this.loader.open();

    this.venue.setUnplacedTicketCount (o.ParentId, o).subscribe((data:any) => {
      this.saving = false;
      this.loader.close();
      //store the object and return the id
      this.dialogRef.close(true);
    });

  }


}
