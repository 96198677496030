import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ImageService } from '../../api';

@Component({
  selector: 'app-edit-media-form',
  templateUrl: './edit-media-form.component.html'
})

export class EditMediaFormComponent implements OnInit {

  private mediaItem: any;
  public form: FormGroup;

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditMediaFormComponent>,
              private imageService: ImageService,
              private loader: HttpLoaderService) { }

  ngOnInit() {
    
    this.mediaItem =  this.passedData.editItem;

    this.form = new FormGroup({
      Id: new FormControl(''), // Passed to preserve value
      Description: new FormControl(''),
    });

    this.form.patchValue(this.mediaItem);
    
  }

  submit() {

    const frm = this.form.value;
    this.loader.open();

    this.imageService.updateImage(frm).subscribe((id: any) => {
      // close this dialog and pass the newly added id to the called
      this.dialogRef.close(id);
      this.loader.close();
    });
    
  }
}
