/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerMembershipRenewImportLineModel { 
    Id?: number;
    Created?: Date;
    OriginalTransactionKey?: string | null;
    Status?: number;
    readonly StatusCode?: string | null;
    CustomerName?: string | null;
    CustomerNumber?: string | null;
    PayingCustomerName?: string | null;
    PayingCustomerNumber?: string | null;
    Reason?: string | null;
    CalculatedPriceRange?: string | null;
    PayingCustomerId?: number;
    MembershipId?: number;
    ImportStatus?: number;
}

