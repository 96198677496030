<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-6">
    <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>

  <div class="col-sm-6">

    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(false);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>


<form [formGroup]="mainForm" class="form-content">
  <div class="section-wrapper">

    <div class="row" *ngIf="this.isUsingSASSO && this.customer.PrimaryContact && this.customer.PrimaryContact.SSOId != null">
      <div class="col-12">
        <t4-page-warning warning="{{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.RELATION-IS-LINKED-TO' | translate}} {{this.customer.PrimaryContact.SSOProvider}} {{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.SINGLE-SIGN-ON' | translate}} {{this.customer.PrimaryContact.SSOProvider}} {{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.BE-MODIFIED' | translate}}"></t4-page-warning>
      </div>
    </div>

    <div class="row" *ngIf="this.customer.PrimaryContact && this.customer.PrimaryContact.AccountingId != null">
      <div class="col-12">
        <t4-page-warning warning="{{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.RELATION-IS-LINKED-TO' | translate}} {{this.customer.PrimaryContact.AccountingProvider}}. {{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.DATA-CAN-ONLY' | translate}} {{this.customer.PrimaryContact.AccountingProvider}} {{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.BE-MODIFIED' | translate}}"></t4-page-warning>
      </div>
    </div>

    <div class="row" *ngIf="this.customer.PrimaryContact && this.customer.PrimaryContact.IsPlaceholder">
      <div class="col-12">
        <t4-page-warning warning="{{'DIALOGS.EDIT-CUSTOMER.FORM.WARNING.PLACEHOLDER' | translate}}"></t4-page-warning>
      </div>
    </div>

    <div class="form-layout row">
      <div class="col">
        <h6 class="form-header h6-icon">
          {{ 'RELATIONS.EDITDIALOG.FORM.HEADER.CUSTOMERINFO' | translate }} 
        </h6>

        <div class="row">
          <div class="col">
            <div class="row">
              <div class="form-group col-4">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.HEADER.CUSTOMERTYPE' | translate }}<span class="tx-danger" *ngIf="isRequiredField('CustomerType')">*</span>
                </label>
                <ng-select [items]="customerTypes" bindLabel="viewValue" bindValue="value"
                  formControlName="CustomerType" placeholder="{{ 'RELATIONS.EDITDIALOG.FORM.PLACEHOLDER.CUSTOMERTYPE' | translate }}" [clearable]="false"
                  notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                <t4-display-validation controlName="CustomerType" [form]="mainForm" [messages]="validationMessages">
                </t4-display-validation>
              </div>

              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.HEADER.ACCOUNTMANAGER' | translate }}<span class="tx-danger" *ngIf="isRequiredField('AccountManagerId')">*</span>
                </label>
                <ng-select [items]="accountManagers" bindLabel="Name" bindValue="Id" formControlName="AccountManagerId"
                  placeholder="{{ 'RELATIONS.EDITDIALOG.FORM.PLACEHOLDER.ACCOUNTMANAGER' | translate }}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                </ng-select>
                <t4-display-validation controlName="AccountManagerId" [form]="mainForm"
                  [messages]="validationMessages"></t4-display-validation>
              </div>

              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="ckbox ckbox-input">
                  <input type="checkbox" name="AccessToDistributix" formControlName="AccessToDistributix">
                  <span>{{ 'RELATIONS.EDITDIALOG.FORM.HEADER.USESDISTRIBUTIX' | translate }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.COMPANYNAME' | translate }}<span class="tx-danger" *ngIf="isRequiredField('Name')">*</span>
                </label>
                <input class="form-control" name="name" formControlName="Name">
                <t4-display-validation controlName="Name" [form]="mainForm" [messages]="validationMessages">
                </t4-display-validation>
              </div>

              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.KVK' | translate }} <span class="tx-danger" *ngIf="isRequiredField('COC')">*</span>
                </label>
                <input class="form-control" name="coc" formControlName="COC">
              </div>

              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.VAT' | translate }}<span class="tx-danger" *ngIf="isRequiredField('VATNr')">*</span>
                </label>
                <input class="form-control" name="name" formControlName="VATNr">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.TELEPHONE' | translate }}<span class="tx-danger" *ngIf="isRequiredField('TelephoneNr')">*</span>
                </label>
                <input class="form-control" name="TelephoneNr" formControlName="TelephoneNr">
                <t4-display-validation controlName="TelephoneNr" [form]="mainForm" [messages]="validationMessages">
                </t4-display-validation>
              </div>

              <div class="form-group col-4" *ngIf="mainForm.controls.CustomerType.value == '1'">
                <label class="form-control-label">
                  {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.EXTERNALCODE' | translate }}<span class="tx-danger" *ngIf="isRequiredField('ExternalCode')">*</span>
                </label>
                <input class="form-control" name="ExternalCode" formControlName="ExternalCode">
                <t4-display-validation controlName="ExternalCode" [form]="mainForm" [messages]="validationMessages">
                </t4-display-validation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Address & Primary account -->
    <div class="form-layout row">
      <div class="col-12 col-lg-6">
        <h6 class="form-header h6-icon">
          {{ 'RELATIONS.EDITDIALOG.FORM.HEADER.ADDRESS' | translate }}  
        </h6>

        <div class="form-group" [formGroup]="Address">
          <div class="row">
            <div class="form-group col-4">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.POSTALCODE' | translate }}<span class="tx-danger" *ngIf="isRequiredField('PostalCode', Address)">*</span>
              </label>
              <input class="form-control" name="PostalCode" (change)="checkPostalCode(false)"
                formControlName="PostalCode">
              <t4-display-validation controlName="PostalCode" [form]="Address" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="form-group col-5">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.STREETNR' | translate }}<span class="tx-danger" *ngIf="isRequiredField('StreetNr', Address)">*</span>
              </label>
              <input class="form-control" name="StreetNr" (change)="checkPostalCode(false)" formControlName="StreetNr">
              <t4-display-validation controlName="StreetNr" [form]="Address" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="form-group col-3">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.STREETADDITION' | translate }}<span class="tx-danger" *ngIf="isRequiredField('StreetNrAddition', Address)">*</span>
              </label>
              <input class="form-control" name="streetNrAddition" formControlName="StreetNrAddition">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.STREET' | translate }}<span class="tx-danger" *ngIf="isRequiredField('Street', Address)">*</span>
              </label>
              <input class="form-control" name="street" formControlName="Street">
              <t4-display-validation controlName="Street" [form]="Address" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="form-group col-6">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.CITY' | translate }} <span class="tx-danger" *ngIf="isRequiredField('City', Address)">*</span>
              </label>
              <input class="form-control" name="city" formControlName="City">
              <t4-display-validation controlName="City" [form]="Address" [messages]="validationMessages">
              </t4-display-validation>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.COUNTRY' | translate }}<span class="tx-danger" *ngIf="isRequiredField('CountryId', Address)">*</span>
              </label>
              <ng-select [items]="countries" bindLabel="Name" bindValue="Id" formControlName="CountryId"
                placeholder="{{ 'RELATIONS.EDITDIALOG.FORM.PLACEHOLDER.COUNTRY' | translate }}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                <!-- Selected item -->
                <ng-template ng-label-tmp let-item="item">
                  {{ item.Iso2 ? (('COUNTRY.'+item.Iso2) | translate) : ""}}                 
                </ng-template>
                <!-- Dropdown items -->
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  {{('COUNTRY.'+item.Iso2) | translate}}
                </ng-template>
              </ng-select>
              <t4-display-validation controlName="CountryId" [form]="Address" [messages]="validationMessages">
              </t4-display-validation>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <h6 class="form-header h6-icon">
          {{ 'RELATIONS.EDITDIALOG.FORM.HEADER.PRIMARY-ACCOUNT' | translate }}
        </h6>
        <div class="form-group" [formGroup]="PrimaryContact">
          <div class="row">
            <div class="col-3 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.GENDER' | translate }}<span class="tx-danger" *ngIf="isRequiredField('Gender', PrimaryContact)">*</span>
              </label>
              <ng-select [items]="selectableGenders" bindLabel="viewValue" bindValue="value" formControlName="Gender"
                placeholder="{{ 'RELATIONS.EDITDIALOG.FORM.PLACEHOLDER.GENDER' | translate }}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
              </ng-select>
              <t4-display-validation controlName="Gender" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="col-5 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.FIRSTNAME' | translate }} <span class="tx-danger" *ngIf="isRequiredField('FirstName', PrimaryContact)">*</span>
              </label>
              <input class="form-control" name="firstname" formControlName="FirstName">
              <t4-display-validation controlName="FirstName" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>

            <div class="col-4 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.INITIALS' | translate }}<span class="tx-danger" *ngIf="isRequiredField('Initials', PrimaryContact)">*</span>
              </label>
              <input class="form-control" name="initials" formControlName="Initials">
              <t4-display-validation controlName="Initials" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
          </div>

          <div class="row">
            <div class="col-3 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.MIDDLENAME' | translate }}<span class="tx-danger" *ngIf="isRequiredField('MiddleName', PrimaryContact)">*</span>
              </label>
              <input class="form-control" name="middlename" formControlName="MiddleName">
            </div>

            <div class="col-9 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.LASTNAME' | translate }}<span class="tx-danger" *ngIf="isRequiredField('LastName', PrimaryContact)">*</span>
              </label>
              <input class="form-control" name="lastname" formControlName="LastName">
              <t4-display-validation controlName="LastName" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
          </div>

          <div class="row">
            <div class="col-8 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.TELEPHONE' | translate }}<span class="tx-danger" *ngIf="isRequiredField('MobileNr', PrimaryContact)">*</span>
              </label>
              <input class="form-control" name="phonenumber" formControlName="MobileNr">
              <t4-display-validation controlName="MobileNr" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="form-group col-4 parsley-input parsley-error">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.DATEOFBIRTH' | translate }}<span class="tx-danger" *ngIf="isRequiredField('DateOfBirth', PrimaryContact)">*</span>
              </label>
              <input class="form-control" datepicker [beforeToday]="true" formControlName="DateOfBirth">
              <t4-display-validation controlName="DateOfBirth" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
          </div>

          <div class="row">
            <div class="col-8 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.EMAIL' | translate }}<span class="tx-danger" *ngIf="isRequiredField('Email', PrimaryContact)">*</span>
              </label>
              <input trim class="form-control" name="email" formControlName="Email">
              <t4-display-validation controlName="Email" [form]="PrimaryContact" [messages]="validationMessages">
              </t4-display-validation>
            </div>
            <div class="col-4 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.LANGUAGE' | translate }}

              </label>
              <ng-select [items]="SelectableLanguages" bindLabel="Name" bindValue="Id" formControlName="LanguageId" placeholder="{{ 'RELATIONS.EDITDIALOG.FORM.PLACEHOLDER.DEFAULT' | translate }}"
                        [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="LanguageId" [form]="PrimaryContact" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="row" *ngIf="this.isUsingSASSO">
            <div class="col-12 form-group">
              <label class="form-control-label">
                {{ 'RELATIONS.EDITDIALOG.FORM.LABEL.SSOID' | translate }}
              </label>
              <div class="row">
                <div class="col-8 form-group">
                  <input trim class="form-control" name="ssoid" formControlName="SSOId">
                </div>
                <div class="col-4 form-group" *ngIf="this.customer.PrimaryContact && this.customer.PrimaryContact.SSOId">
                  <button (click)="deleteSSOId()" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline" style="margin-top: 6px;"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row">

      <div class="col-6">
        <button (click)="submit()" [disabled]="!customerIsValidAndDirty()" class="btn btn-block btn-primary bd-0">
          <i class="far fa-save"></i>
          <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
        </button>
      </div>
      <div class="col-6">
        <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
          <i class="fas fa-ban"></i>
          <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
