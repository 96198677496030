<div *ngIf="!this.isSalesPeriod && !this.evId; else show">
  {{'ORDER.IMPORT.UNAVAILABLE' | translate}}
</div>

<ng-template #show>

  <app-long-running-operation-progress-bar (onProcessFinished)="progressFinished($event)" #progressBar></app-long-running-operation-progress-bar>


  <t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListActions>
      <div class="object-actions bg-white text-right">
        <t4-lro-button (onLROClick)="exportImport()" [icon]="'fas fa-file-export'" [text]="'ORDER.IMPORT.BUTTON.CREATE.IMPORT-FILE'" [lroTypeCode]="longRunningOperationTypeEnum.CreateOrderImportFileForSalesPeriod" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
        <t4-lro-button (onLROClick)="import()" (onLROComplete)="listVw.refreshData()" [icon]="'fas fa-file-export'" [text]="'ORDER.IMPORT.BUTTON.IMPORT'" [lroTypeCode]="longRunningOperationTypeEnum.OrderImportCreateOrderLines" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
        <button (click)="deleteUnprocessed()" [disabled]="disableDeleteButton" class="btn btn-primary border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'ORDER.IMPORT.BUTTON.REMOVE.UNPROCESSED.LINES' |translate}}</button>
        <t4-lro-button (onLROClick)="processOrders()" (onLROComplete)="listVw.refreshData()" [icon]="'fas fa-file-export'" [text]="getProcessableCountTranslation()" [lroTypeCode]="longRunningOperationTypeEnum.ProcessOrderImport" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
        <t4-lro-button (onLROClick)="exportList()" [icon]="'fas fa-file-import'" [text]="'ORDER.IMPORT.BUTTON.EXPORT'" [lroTypeCode]="longRunningOperationTypeEnum.CreateOrderImportFile" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
      </div>
    </ng-container>


    <!-- <ng-container *t4ListActions>
      <div class="object-actions bg-white text-right">
        <button (click)="exportRebuy()" [class.disabled]="(latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" class="btn btn-primary border btn-small" type="button"><i class="fas fa-file-export"></i>Importbestand samenstellen</button>

        <button (click)="procesReservations()" [class.disabled]="processableCount <= 0 || (latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" class="btn btn-primary border btn-small" type="button"><i class="fas fa-file-export"></i>Reserveringen plaatsen ({{processableCount}})</button>


      </div>
    </ng-container>
     -->
    <ng-container *t4ListItem="let item">

      <th>{{item.Id }}</th>
      <td>{{item.SectionName | replaceOnEmpty }}</td>
      <td>{{item.RowNumber | replaceOnEmpty }}</td>
      <td>{{item.SeatNumber | replaceOnEmpty }}</td>
      <td *ngIf="isSalesPeriod">{{item.ToPassePartoutName | replaceOnEmpty }}</td>
      <td>{{ getCustomerName(item) | replaceOnEmpty }}</td>
      <td>{{ item.DeliveryMethodName | replaceOnEmpty }}</td>
      <td>{{ getOtherProperties(item) | replaceOnEmpty }}</td>
      <td>{{item.Status}} - {{ getStatusName(item.Status) }}</td>
      <td>{{item.Reason | replaceOnEmpty | translate }}</td>


      <td class="grid-action" >
        <button [disabled]="item.Status === 3 || (latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)"  (click)="actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
      </td>

    </ng-container>

  </t4-list-view>

</ng-template>
