import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ListViewSelectionMode} from '@tymes4-shared';
import {GenericErrorHandler} from 'app/shared/helpers/generic-error-handler';
import {HttpLoaderService, NotificationCommunicationService} from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import {ConfirmReservationImportComponent} from '../../dialogs/confirm-reservation-import/confirm-reservation-import.component';
import {DialogHelper, DialogWidth} from '@tymes4-shared';
import { CreateOrderImportFileComponent } from '../../dialogs/create-order-import-file/create-order-import-file.component';
import { UploadOrderImportFileComponent } from '../../dialogs/upload-order-importfile/upload-order-importfile.component';
import { ConfirmOrderImportComponent } from '../../dialogs/confirm-order-import/confirm-reservation-import.component';
import { TranslateService } from '@ngx-translate/core';
import {LongRunningOperationService, LongRunningOperationTypeEnum, OrderImportProcessArgs, OrderImportService} from '../../api';

@Component({
  selector: 'app-order-import',
  templateUrl: './order-import.component.html',
  styleUrls: ['./order-import.component.scss']
})
export class OrderImportComponent implements OnInit {

  @ViewChild('listVw') listVw;
  @ViewChild('progressBar') progressBar;


  public isSalesPeriod: boolean = false;
  public isEvent: boolean = false;

  public listOptions = {};

  public deletionAllowed: boolean = false

  constructor(private dialog: MatDialog, private loader: HttpLoaderService,
              private orderImportService: OrderImportService,
              private longRunningOperationService: LongRunningOperationService,
              private errorHandler: GenericErrorHandler,
              private snackBar: SnackbarService, private confirmService: ConfirmService,
              private notificationCommunicationService : NotificationCommunicationService,
              private translate: TranslateService) {
  }


  private spId: number = null;
  public evId: number = null;
  private selectedItems = [];
  public processableCount = 0;
  public latestLongRunningOperation = null;
  public longRunningOperationTypeEnum = LongRunningOperationTypeEnum;
  public disableDeleteButton = false;


  @Input()
  set salesPeriodId(id: number) {
    this.spId = id;
    this.isSalesPeriod = true;
    this.loadListOptions();
  }

  @Input()
  set eventId(id: number) {
    this.evId = id;
    this.isEvent = true;
    this.loadListOptions();
  }

  ngOnInit() {
    this.loadListOptions();
  }

  loadListOptions() {
    this.listOptions = {
      search: true,
      showBarcodeSearch: false,
      pageSize: 12,
      noItemsText: 'ORDER.IMPORT.NO-ITEMS-TEXT',
      selectionMode: ListViewSelectionMode.None,
      disableSort: true,
      actionDropdownWidth: '300px',
      headers: [
        {sortField: 'Id', label: 'ORDER.IMPORT.LIST.HEADER.ID', width: 50, hideOn: null},
        {sortField: 'SectionName', label: 'ORDER.IMPORT.LIST.HEADER.SECTION', width: 70, hideOn: null, isDefaultSort: 'asc'},
        {sortField: 'RowNumber', label: 'ORDER.IMPORT.LIST.HEADER.ROW', width: 30, hideOn: null},
        {sortField: 'SeatNumber', label: 'ORDER.IMPORT.LIST.HEADER.SEAT', width: 30, hideOn: null},
        {sortField: 'ToPassePartoutName', label: 'ORDER.IMPORT.LIST.HEADER.PASSE-PARTOUT', width: 300, hideOn: null, visible: this.isSalesPeriod },
        {sortField: null, label: 'ORDER.IMPORT.LIST.HEADER.CUSTOMER', hideOn: null},
        {sortField: 'DeliveryMethodName', label: 'ORDER.IMPORT.LIST.HEADER.DELIVERY-METHOD', width: 150, hideOn: null },
        {sortField: null, label: 'ORDER.IMPORT.LIST.HEADER.OTHER', width: 200, hideOn: null },
        {sortField: 'Status', label: 'ORDER.IMPORT.LIST.HEADER.STATUS', width: 120, hideOn: null},
        {sortField: 'Reason', label: 'ORDER.IMPORT.LIST.HEADER.REASON', width: 300, hideOn: null},
        {label: ''}

      ]
    };
  }

  actionClick(action: string, item: any = null) {
    if (action === 'delete') {
      this.deleteLine(item);
    }
  }

  deleteLine(item: any) {

    if (item === null) return;

    let msg = this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE');

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: msg,
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (confirmed === true) {
        this.orderImportService.orderImportDeleteLine(item.Id).subscribe(data => {
          this.listVw.refreshData();
        }, error => {
          this.errorHandler.handle(error);
        });
      }
    });
  }

  OnItemSelectionChange(items) {

    this.selectedItems = items;

    var reserved = this.selectedItems.filter(function (r) {
      if (r.Status === 3) return r;
    });

    this.deletionAllowed = this.selectedItems.length > 0 && reserved.length == 0;
  }


  OnDataRequest(e) {

      this.orderImportService.orderImportSearch(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { ['eventId']: `${this.evId}`,['salesPeriodId']: `${this.spId}`  }).subscribe((resp: any) => {

        this.listVw.Data = resp;

        this.updateStatus();

        this.loader.close();

      });
  }


  updateStatus(): any {

    let spOrEventId = this.spId ? this.spId : this.evId;
    let type = this.spId ? 1 : 2;

    this.orderImportService.orderImportOrderableCount(type, spOrEventId).subscribe((count:number) => {
        this.processableCount = count;
      },
      error => {
        this.errorHandler.handle(error);
      });
    ;

      //Check if there is an active long running operation
    this.notificationCommunicationService.updateNotificationsAndLROS();


  }

  processOrders() {

    if (this.processableCount === 0) {
      return;
    }

    let spOrEventId = this.spId ? this.spId : this.evId;
    let type = this.spId ? 1 : 2;

    const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.md, {
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      payload: {processableCount: this.processableCount}
    });
    this.dialog.open(ConfirmOrderImportComponent, dialogOptions).afterClosed().subscribe((result: boolean | { groupByCustomer: boolean ,  disableC3V: boolean }) => {
      if (result === false || result === true) {
        return;
      }

      this.loader.open();


      this.notificationCommunicationService.updateNotificationsAndLROS();
      this.loader.close();
      let args : OrderImportProcessArgs = {EventId: this.evId, SalesPeriodId: this.spId,GroupByCustomer: result.groupByCustomer, DisableC3V: result.disableC3V};

      //and kickoff the process (fire-and-forget)
      this.longRunningOperationService.registerProcessOrderImport(args).subscribe(lro => {
        this.notificationCommunicationService.updateNotificationsAndLROS();
      },);

    });
  }

  import() {

    let title = this.translate.instant('ORDER.IMPORT.DIALOG.TITLE');

    let spOrEventId = this.spId ? this.spId : this.evId;
    let type = this.spId ? 1 : 2;

    let dialogRef: MatDialogRef<any> = this.dialog.open(UploadOrderImportFileComponent, {
      width: '720px',
      disableClose: true,
      data: {title: title, type: type, spOrEventId: spOrEventId}
    })
    dialogRef.afterClosed()
      .subscribe(lro => {

        this.notificationCommunicationService.updateNotificationsAndLROS();

        }
      )
  }

  progressFinished($event) {
    //refresh data and status
    this.listVw.refreshData();
  }

  getCustomerName (item) {

    if (item.OrderCustomerName === null) return "-";

    let result = item.OrderCustomerName + " (" + item.OrderCustomerNumber + ")";

    if (item.TicketCustomerName !== null && item.TicketCustomerName !== item.OrderCustomerName) {
      result += " / " + item.TicketCustomerName + " (" + item.TicketCustomerNumber + ")";
    }

    return result;
  }

  getOtherProperties (item) {

    let result = '';

    if (item.EntranceName != null) {
      if (result.length > 0) result += " / ";
      result += item.EntranceName;
    }

    if (item.TicketText != null) {
      if (result.length > 0) result += " / ";
      result += item.TicketText;
    }

    return result;

  }

  getStatusName(status) {

    var statusString =  '-';
    switch (status) {
      case 1:
        statusString = 'ORDER.IMPORT.STATUS.READY.FOR.IMPORT';
        break;
      case 2:
        statusString = 'ORDER.IMPORT.STATUS.SKIPPED';
        break;
      case 3:
        statusString = 'ORDER.IMPORT.STATUS.RESERVED';
        break;
      case 4:
        statusString = 'ORDER.IMPORT.STATUS.FAILED';
        break;
      case 5:
        statusString = 'ORDER.IMPORT.STATUS.ORDER';
        break;
    }
    return this.translate.instant(statusString);
  }

  exportList() {
    this.loader.open();

    let type = this.spId ? 1 : 2;
    let spOrEventId = this.spId ? this.spId : this.evId;

    this.orderImportService.orderImportCreateExportList(type, spOrEventId).subscribe(data => {
        this.notificationCommunicationService.updateNotificationsAndLROS();
        this.loader.close();
      },
      error => {
        this.errorHandler.handle(error);
      }
    );
  }

  deleteUnprocessed() {
    let msg = this.translate.instant('ORDER.IMPORT.DELETE.UNPROCESSED.MESSAGE');

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: msg,
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (!confirmed) {
        return;
      }

      this.disableDeleteButton = true;
      this.loader.open();

      let type = this.spId ? 1 : 2;
      let spOrEventId = this.spId ? this.spId : this.evId;

      this.orderImportService.orderImportDeleteUnprocessed(type, spOrEventId).subscribe(data => {
        this.notificationCommunicationService.updateNotificationsAndLROS();

        this.loader.close();
        this.listVw.refreshData();
        this.selectedItems = null;
      }, error => {
        this.errorHandler.handle(error);
      });
    });

    this.disableDeleteButton = false;
    this.listVw.refreshData()
  }

  exportImport() {
    let title = this.translate.instant('ORDER.IMPORT.EXPORT.IMPORT.TITLE');

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateOrderImportFileComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: title, salesPeriodId: this.spId, eventId: this.evId}
    })
    dialogRef.afterClosed()
      .subscribe(id => {
          //just close, nothing to do.
        }
      )
  }

  getProcessableCountTranslation() {
    return this.translate.instant('ORDER.IMPORT.BUTTON.PROCESSABLE.COUNT', {count: this.processableCount})
  }
}
