import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {ImageService} from "../../api";


@Component({
  selector: 't4-upload-multiple-images',
  templateUrl: './upload-multiple-images.component.html'
})

export class UploadMultipleImagesComponent implements OnInit {

  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  crossSellProductId = null;
  uploadCount = 0;
  allowUpload = false;


  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<UploadMultipleImagesComponent>,
              public imageService: ImageService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {

    this.crossSellProductId = this.passedData.crossSellProductId;
  }

  submit() {

  }

  selectFiles(event) {
    this.progressInfos = [];

    const files = event.target.files;
    let isImage = true;

    for (let i = 0; i < files.length; i++) {

      this.allowUpload = true;

      if (files.item(i).type.match('image.*')) {
        continue;
      } else {
        isImage = false;
        alert('invalid format!');
        break;
      }
    }

    if (isImage) {
      this.selectedFiles = event.target.files;
    } else {
      this.selectedFiles = undefined;
      event.srcElement.percentage = null;
    }
  }




  uploadFiles() {
    this.allowUpload = false;
    this.message = '';

    this.uploadCount = this.selectedFiles.length;

    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  upload(idx, file) {
    this.progressInfos[idx] = {value: 0, fileName: file.name};
    
    if (!file)
      return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const mimeType = (reader.result as string).split(',')[0];
      const base64string = (reader.result as string).split(',')[1];
      const uploadImageModel = {
        Description: file.name.split('.')[0],
        FileData: base64string,
        FileName: file.name,
        MimeType: file.type
      };
      this.imageService.uploadImage(this.crossSellProductId, uploadImageModel).subscribe(
        (event: any) => {

          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.uploadCount--;

            if (this.uploadCount == 0) {
              this.dialogRef.close(1); //we are done.
            }
          }

        },
        err => {
          this.progressInfos[idx].percentage = 0;
          this.message = this.translate.instant('DIALOGS.UPLOAD-MULTIPLE-IMAGES.ERROR.MESSAGE.ERROR-DURING-UPLOAD') + file.name;
          this.allowUpload = false;
        });
    }


  }
}
