
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<div class="form-group mt-5" style="max-height:400px; overflow: auto; ">
  <app-activity-log [resourceId]="resourceId" [activityType]="activityType" [inDialog]="true" [limit]="100"></app-activity-log>
</div>



