/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type QueueMessageTypeEnum = 0 | 1000 | 1001 | 1002 | 2000 | 2001 | 3000 | 3001 | 4000 | 4001 | 4002 | 5000 | 6000 | 7000 | 7001;

export const QueueMessageTypeEnum = {
    NUMBER_0: 0 as QueueMessageTypeEnum,
    NUMBER_1000: 1000 as QueueMessageTypeEnum,
    NUMBER_1001: 1001 as QueueMessageTypeEnum,
    NUMBER_1002: 1002 as QueueMessageTypeEnum,
    NUMBER_2000: 2000 as QueueMessageTypeEnum,
    NUMBER_2001: 2001 as QueueMessageTypeEnum,
    NUMBER_3000: 3000 as QueueMessageTypeEnum,
    NUMBER_3001: 3001 as QueueMessageTypeEnum,
    NUMBER_4000: 4000 as QueueMessageTypeEnum,
    NUMBER_4001: 4001 as QueueMessageTypeEnum,
    NUMBER_4002: 4002 as QueueMessageTypeEnum,
    NUMBER_5000: 5000 as QueueMessageTypeEnum,
    NUMBER_6000: 6000 as QueueMessageTypeEnum,
    NUMBER_7000: 7000 as QueueMessageTypeEnum,
    NUMBER_7001: 7001 as QueueMessageTypeEnum
};

