/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptInQuestionView { 
    Id?: number;
    Active?: boolean;
    Code?: string | null;
    Title?: string | null;
    Description?: string | null;
    Required?: boolean;
    OptInSectionId?: number;
    SectionCode?: string | null;
    SectionDescription?: string | null;
}

