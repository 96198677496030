import {ProductTypeEnum} from '@tymes4-shared';
import {Deserializable} from './deserializable';
import { OrderLine } from './orderline';

export class PlacementLine implements Deserializable<PlacementLine> {

  deserialize(input: any): PlacementLine {
    Object.assign(this, input);
    return this;
  }

  TicketTypeId: number;  
  ContingentId: number;
  PriceVariantId: number;
  OrderLines: OrderLine[];
}
