/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerReservationModel { 
    PlaceSelectionAllowed?: boolean;
    OwnedByLoggedInUser?: boolean;
    Column?: number | null;
    Row?: number | null;
    ParentVenueBuildingBlockId?: number;
    GroupingId?: string | null;
    ReservationLineId?: number;
    ReservationId?: number;
    CustomerId?: number;
    CustomerName?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    EventId?: number | null;
    EventName?: string | null;
    TicketTypeId?: number;
    TicketTypeName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    ParentSectionName?: string | null;
    ParentSectionShortName?: string | null;
    PriceExVat?: number;
    VAT?: number;
    ParentSectionColor?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductVariantName?: string | null;
    CrossSellProductId?: number | null;
    CrossSellProductName?: string | null;
    Selected?: boolean | null;
    TotalPrice?: number | null;
}

