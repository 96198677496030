import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { PromotionService } from '../../services/http/promotion.service';
import { InitiativeService } from '../../api';

@Component({
  selector: 'app-initiative-promotion-form',
  templateUrl: './edit-initiative-promotion-form.component.html'
})
export class EditInitiativePromotionFormComponent implements OnInit {
  public form: FormGroup;

  public saving = false;

  private isNew: boolean;
  private editObject: any;
  private initiativeId: number;

  public promotions: any;

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public dialogRef:  MatDialogRef<EditInitiativePromotionFormComponent>,
  private promotionService: PromotionService,
  private initiativeService: InitiativeService,
  private loader: HttpLoaderService) { }

  ngOnInit() {
    this.isNew = this.passedData.isNew;
    this.editObject = this.passedData.payload;
    this.initiativeId = this.passedData.initiativeId;

    this.form = new FormGroup({
      Id: new FormControl(''),
      PromotionId: new FormControl('', Validators.required),
      ActiveFrom: new FormControl(''),
      ActiveTill: new FormControl(''),
    });

    this.promotionService.listSelectable().subscribe(data => {
      this.promotions = data;
    });

    if (!this.isNew) {
      setTimeout(() => {
        this.form.patchValue(this.editObject);
      }, 0);
    }
  }

  submit() {
    const propertyObject = this.form.value;
    propertyObject.InitiativeId = this.initiativeId;

    this.saving = true;
    this.loader.open();

    // store the object and return the id
    if (this.isNew) {
      this.initiativeService.createPromotionLink(propertyObject).subscribe((id: number) => {
        // close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    } else {
      this.initiativeService.updatePromotionLink(propertyObject).subscribe((id: any) => {
        this.dialogRef.close(true);
        this.loader.close();
      });
    }
  }
}
