
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstService } from '../../services/const.service';
import {forkJoin} from 'rxjs';
import { HttpLoaderService } from '@tymes4-shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VenueBuildingBlockService } from '../../api';

@Component({
  selector: 'app-copy-vbb-form',
  templateUrl: './copy-vbb-form.component.html'
})
export class CopyVBBFormComponent implements OnInit {

  public form: FormGroup;
  public saving = false;

  private editingObject: any = null;
  private venueParentControl: FormControl;
  private venueBluePrintControl: FormControl;

  //*region Option Values

  public filteredParentVenues: any;
  public filteredBluePrintVenues: any;
  //*endregion

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CopyVBBFormComponent>,
    public constants: ConstService,
    private venue: VenueBuildingBlockService,
    private loader:HttpLoaderService,
    private vbbService: VenueBuildingBlockService,
    private translate: TranslateService
    
  ) { }


  ngOnInit() {

    let vbbTypeId = this.passedData.venueBuildingBlockTypeId;
    let selectedObjectId = this.passedData.selectedObjectId;
    let parentId = this.passedData.parentId;

    this.editingObject = {
      VenueBuildingBlockTypeId: vbbTypeId, //section by default
    };


    //set up the autocompletes
    this.venueParentControl = new FormControl();
    this.venueBluePrintControl = new FormControl(null, this.validateBluePrint);

    this.venueParentControl.valueChanges.pipe(
        debounceTime(400)) //give the user some time to end the query
        .subscribe(data => {
            //max 200 results sorted on name
            this.venue.searchVenueBuildingBlocks(data, 1, 200, 'Name').subscribe(data =>{
                this.filteredParentVenues = data.Records
            })
        });

    this.venueBluePrintControl.valueChanges.pipe(
        debounceTime(400)) //give the user some time to end the query
        .subscribe(data => {
            //max 200 results sorted on name
            this.venue.searchVenueBuildingBlocks(data, 1, 200, 'Name').subscribe(data =>{
                this.filteredBluePrintVenues = data.Records
            })
        });


    this.loader.open();

     this.venue.getVenueBuildingBlock(selectedObjectId).subscribe((data:any) => {
      this.venueBluePrintControl.setValue(data);
      this.loader.close();
    });

    if (parentId !== null) {
      this.venue.getVenueBuildingBlock(parentId).subscribe((data:any) => {
        this.venueParentControl.setValue(parent);
      });
    }

    this.form = new FormGroup({
      Id: new FormControl(''), //Passed to preserve value
      Version: new FormControl(''),
      Name: new FormControl('', Validators.required),
      VenueBuildingBlockTypeId: new FormControl(''),
      Parent: this.venueParentControl,
      BluePrint: this.venueBluePrintControl,
      IncludeChildren: new FormControl(true)
    });


    this.form.patchValue(this.editingObject);
  }

    validateBluePrint(c: FormControl) {

      return (c.value != null && c.value.Id) ? null : {
        validateBluePrint: {
          valid: false
        }
      };
  }

  submit() {

    var o = this.form.value;

    this.saving = true;
    this.loader.open();

    //We are just interested in the ID
    if (o.Parent != null) {
      o.ParentId = o.Parent.Id;
      o.Parent = null;
    }

    if (o.BluePrint != null) {
      o.VbbIdToCopy = o.BluePrint.Id;
      o.BluePrint = null;
    }

    this.venue.copyVenueBuildingBlock(o).subscribe((id:any) => {
      this.loader.close();
      this.dialogRef.close(id);
    });
  }

  getSelectedVenueDisplayText(venue) {
    if (venue == null) return '';
    let result = `${venue.Id} - ${venue.Name}`;
    if (venue.Version != null && venue.Version != '') result += ` - ${venue.Version}`;
    return result;
  }

  getVBBName(vbb) {
    let result: string = vbb.Name;

    if (vbb.Version != null)
    {
      result += this.translate.instant('DIALOGS.COPY-VBB.FORM.RESULT.VERSION', {vbbVersion: vbb.Version});
    }

    return result;
  }
}
