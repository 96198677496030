<div class="row modal-bar">

  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>

</div>

<form class="form-content">
  <div class="section-wrapper mg-t-20">
    
    <div >
            
      <t4-tabs (selected)="loadTabDetails($event)" style="width: 100%">
        <t4-tab *ngFor="let ticketType of this.availableTicketTypes(); let i = index" [pos]="i" [selected]="i === 0" tabTitle="{{ getTicketTypeTabelLabel(ticketType) }}">
          <div class="mg-t-20">
            <app-seat-plan  [ticketType]="ticketType"
                [eventIds]="this.selectedEventIds"
                [section]="this.selectedSellableSection"
                [passePartouts] = "this.selectedPassePartouts"
                [orderlineSelection] = "this.selectedOrderLines"
                (onSelectionChange)="onPlacementSelectionChange($event, ticketType.Id)"></app-seat-plan>
          </div>
        </t4-tab>
        <t4-tab tabTitle="SALES.ORDERS.NEW.SALES-SECTION-SELECTION.DIALOG.DETAILS.TAB.UNPLACED" selected="false">
          <div class="mg-t-20">
            <!-- a single tab for all unplaced -->
            <app-sales-unplaced-ticket-selection
                [events]="this.selectedEventIds"
                [passePartouts] = "this.selectedPassePartouts"
                [section]="this.selectedSellableSection"
                [orderlineSelection] = "this.selectedOrderLines"
                (onSelectionChange)="onUnplacementSelectionChange($event)"
                #unplacedTicketSelection></app-sales-unplaced-ticket-selection>
          </div>
        </t4-tab>
      </t4-tabs>
      
    </div>

    <div class="row mt-3">
      <div class="col-lg-8"></div>
      <div class="col-lg-2"> <button  [disabled]="selectionIsEmpty" (click)="submit()" class="btn btn-block btn-primary bd-0"><i class="far fa-save"></i> {{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button></div>
      <div class="col-lg-2"><button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i> {{ 'GENERIC.BUTTON.CANCEL' | translate }}</button></div>
      
          
         
       
    </div>


  </div>

</form>
