
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auto-number',
  templateUrl: './auto-number.component.html'
})
export class AutoNumberComponent implements OnInit {

  public form: FormGroup;
  public saving = false;
  private rows: any = null;

  public directions = [
    { Name: this.translate.instant('DIALOGS.AUTO-NUMBER.ASCENDING'), Id: 1 },
    { Name: this.translate.instant('DIALOGS.AUTO-NUMBER.DESCENDING'), Id: 2 }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<AutoNumberComponent>,
    private loader: HttpLoaderService,
    private fb: FormBuilder,
    private translate: TranslateService) {
     this.rows = this.passedData.rows;
  }


  ngOnInit() {

    this.form = new FormGroup({
      Direction: new FormControl(1),
    });
  }



  submit() {

    var o = this.form.value;
    this.saving = true;

    var ltr = (o.Direction == 1);
    this.performAutoNumbering(ltr)
    this.dialogRef.close(true);
  }


  performAutoNumbering(leftToRight) {

    this.loader.open();

    let maxRow = 0;
    let maxCol = 0;

    for (let row of this.rows) {
      for (let col of row.columns) {
        if (col.data != null && col.data.Row > maxRow) maxRow = col.data.Row;
        if (col.data != null && col.data.Column > maxCol) maxCol = col.data.Column;
      }
    }

    let currentRow: number = maxRow;

    for (let row of this.rows) {

      let seatNumber = 1;
      let hasSeats = false;

      var cols = leftToRight ? row.columns : row.columns.reverse();

      for (let col of cols) {
        if (col.data != null) {

          hasSeats = true;

          //update this one
          col.data.RowNumber = this.padDigits(currentRow, 3);
          col.data.dirty = true;

          if (col.data.PlacementTypeId == 1) //seat
          {
            col.data.SeatNumber = this.padDigits(seatNumber, 4);
            seatNumber++;
          }

        }
      }

       //flip back!
      if (leftToRight === false) row.columns = cols.reverse();

      if (hasSeats) currentRow--;
    }



    this.loader.close();
  }

  padDigits(number, digits) {
    //no longer padding
    return number.toString();

    //return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
  }


}
