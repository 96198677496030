import { ConfigServiceOld } from '../services/http/config.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ApiDetails, BaseUser, EnvironmentService, Language, LoggingService, SessionService } from '@tymes4-shared';
import {Observable} from 'rxjs';
import {AppUser} from '../models/app-user';
import { MenuItemView, Season, TokenRequestModel, UserDetailsView, UserService } from '../api';
import { JsonPipe } from '@angular/common';
import { SeasonService } from '../api';


@Injectable()
export class AuthService {

  private environment: ApiDetails;

  constructor(
    private http: HttpClient,
    private router: Router,
    private logging: LoggingService,
    private environmentService: EnvironmentService,
    private sessionService: SessionService,
    private configService: ConfigServiceOld,
    private userService: UserService,
    private seasonService: SeasonService
  ) {
    this.environment = environmentService.loadConfigurations();
  }

  _loggedInUser: any;

  setLoggedInUser(user: AppUser) {
    this.sessionService.setUser(user);
  }

  getLoggedInUser(): AppUser {
    const u: AppUser = new AppUser();
    const d = JSON.parse(sessionStorage.getItem('userData'));
    return u.deserialize(d);
  }

  isLoggedIn(): boolean {
    return (this.getToken != null);
  }

  getToken(): string {
    return sessionStorage.getItem('jwt');
  }

  setToken(token: string) {
    sessionStorage.setItem('jwt', token);
  }

  getSelectedChannelId() {
    return +sessionStorage.getItem('userSelectedChannel');
  }

  getSelectedChannelCode() {
    return sessionStorage.getItem('userSelectedChannelCode')
  }

  switchToChannel(selectedChannelId: number): any {
    this.userService.switchChannel(selectedChannelId).subscribe((data: TokenRequestModel) => {
      sessionStorage.setItem('userSelectedChannel', data.CurrentChannel.Id.toString());
      sessionStorage.setItem('userSelectedChannelCode', data.CurrentChannel.Code);
      this.sessionService.setToken(data.Token);
      this.router.navigate(['/dashboard']);
    });

    return selectedChannelId;
  }

  switchArchiveMode(newMode: boolean){
    this.userService.switchArchiveMode(newMode).subscribe((data: TokenRequestModel) => {
      sessionStorage.setItem('userIsArchiveMode', data.IsArchiveMode.toString());
      this.sessionService.setToken(data.Token);
      window.location.reload();
    })

    return newMode;
  }

  setPastAndCurrentSeasonDates(): Observable<boolean>{
    return new Observable<boolean>((observer) => {
      this.seasonService.getPastSeason().subscribe((pastSeason) => {
        pastSeason.EndDate = new Date(pastSeason.EndDate)
        var pastSeasonEndDate;
        if(pastSeason.EndDate != null){
          pastSeasonEndDate = `${("0" + pastSeason.EndDate.getDate()).slice(-2)}-${("0" + (pastSeason.EndDate.getMonth() + 1)).slice(-2)}-${pastSeason.EndDate.getFullYear()}`
        }

        this.seasonService.getCurrentSeason().subscribe((currentSeason) => {
          currentSeason.StartDate = new Date(currentSeason.StartDate)
          var currentSeasonStartDate;
          if(currentSeason.StartDate != null){
            currentSeasonStartDate = `${("0" + currentSeason.StartDate.getDate()).slice(-2)}-${("0" + (currentSeason.StartDate.getMonth() + 1)).slice(-2)}-${currentSeason.StartDate.getFullYear()}`
          }
          this.sessionService.setPastAndCurrentSeasonDates((pastSeasonEndDate + "," + currentSeasonStartDate));
          sessionStorage.setItem('pastAndCurrentSeasonDates', (pastSeasonEndDate + "," + currentSeasonStartDate));
          observer.next(true);
          observer.complete();
        });
      });
    })
  }

  setHasArchiveDB(data){
      this.sessionService.setHasArchiveDB(data);
      sessionStorage.setItem('hasArchiveDB', data.toString());
  }

  authenticate(username, password, tfaCode) {
    const data = `username=${username}&password=${password}&grant_type=password&scope=BO`;

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'no-auth': 'true',
      'tfa': tfaCode
    });

    const url = this.environment.apiURL + '/token';
    return this.http.post(url, data, {headers: reqHeader});
  }

  logOff() {
    this.logging.log('User logged off.');
    sessionStorage.removeItem('endpoint');
    sessionStorage.removeItem('userIsArchiveMode');
    sessionStorage.removeItem('hasArchiveDB');
    sessionStorage.removeItem('pastAndCurrentSeasonDates');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('ticketShopURL');
    sessionStorage.removeItem('userSelectedChannelCode');
    sessionStorage.removeItem('user_language');
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userSelectedChannel');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('user_language');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('active_modules');


    this.configService.clearConfigCache();

    this.router.navigate(['/sessions/signin']);
  }

  isInArchiveMode(): boolean {
    return (sessionStorage.getItem('userIsArchiveMode') == "true");
  }

}
