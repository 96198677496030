<div class="table-responsive" *ngIf="typeAvailability !== null; else noAvail">

    <table class="table table-striped table-ticketselect">
        <thead>
            <th style="text-align: right;">
                {{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.HEADER.TOTALPRICE' | translate }}
                <div class="amount-indication">{{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.HEADER.FOR-SELECTED-EVENTS' | translate }}</div>
            </th> 
            
            <th [class.contingent]="c.ContingentId !== null" style="width: 160px; text-align: center;" *ngFor="let c of typeAvailability.Availability">
                <ng-container *ngIf="c.ContingentId !== null">
                    {{ c.ContingentName }}
                    <div class="amount-indication">{{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.HEADER.AVAILABLE-COUNT' | translate: { availableCount: c.AmountAvailable} }}</div>
                </ng-container>

                <ng-container *ngIf="c.ContingentId === null">
                    {{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.HEADER.NO-CONTINGENT' | translate }}
                    <div class="amount-indication">{{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.HEADER.AVAILABLE-COUNT' | translate: { availableCount: c.AmountAvailable} }}</div>
                </ng-container>
            </th>

        </thead>

        <tbody>
            <tr *ngFor="let pc of typeAvailability.CombinedPrices">

                <td style="font-weight: bold; text-align: right;">
                    <ng-container *ngIf="pc.PriceVariantName != null; else noprice">{{pc.PriceVariantName}}</ng-container>
                    <ng-template #noprice>{{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.LISTVIEW.NO-PRICE' | translate}}</ng-template><ng-container *ngIf="pc.CombinedTotalPrice !== null">- {{pc.CombinedTotalPrice | currency:'EUR'}}</ng-container>
                </td>

                <ng-container  *ngFor="let c of typeAvailability.Availability">
                    <td [class.contingent]="c.ContingentId !== null" style="text-align: center;">

                        <div class="form-group m-0">
                            <input
                                [disabled]="pc.AvailableToProductSelection === false || c.AmountAvailable == 0"
                                class="form-control border text-right"
                                matInput
                                #amountInput
                                name="version"
                                type="number"
                                min="0"
                                [value]="findInitialValue(c.ContingentId, pc.PriceVariantId)"
                                [max]="c.AmountAvailable"
                                (change)="onOrderAmountChange($event, c.ContingentId, pc.PriceVariantId)"
                                placeholder="{{'COMPONENTS.TICKET-SALES.UNPLACED-TICKET-SELECTION.PLACEHOLDER.AMOUNT' | translate}}">

                        </div>

                    </td>
                </ng-container>

            </tr>
        </tbody>
    </table>

    <!-- <table class="table table-striped">
        <thead>
            <th>Type</th>
            <th>Beschikbaar</th>
            <th>Aantal</th>
        </thead>

        <tbody>
            <tr *ngFor="let ta of typeAvailability; let idx=index; let isOdd = odd">
                <td class="text-dark">{{ta.TicketTypeName}}</td>
                <td style="width: 150px;">{{ta.Available}}</td>
                <td style="width: 150px;" class="p-1">
                    <div class="form-group m-0">
                        <input
                            class="form-control border bg-white text-right"
                            matInput
                            #amountInput
                            name="version"
                            type="number"
                            min="0"
                            [max]="ta.Available"
                            [value]="typeAmountSelection[idx].nrOfTickets"
                            (change)="onOrderAmountChange($event)"
                            placeholder="# aantal">

                    </div>

                </td>

            </tr>
        </tbody>
    </table> -->

</div>

<ng-template #noAvail>
<div class="table-responsive" >
    <table class="table table-striped">
        <thead>
            <th style="text-align: right;">
                {{'SALES.ORDERS.NEW.SALES-UNPLACED-TICKET-SELECTION.NO-TICKETS' | translate }}                
            </th> 
        </thead>
    </table>
</div>
</ng-template>

