<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">

      <div class="modal-actions">
        <div class="modal-close">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span (click)="dialogRef.close(false);">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>


  <form [formGroup]="mainForm" class="form-content">
    <div class="section-wrapper">

      <div class="form-layout row">
        <div class="col-12 col-lg-6 form-block-style" >
          <h6 class="form-header h6-icon">
            {{'DIALOGS.EDIT-FANCLUB-FORM.HEADER.FANCLUB-DATA' | translate}}
          </h6>

          <div class="row form-row-style">
                <div class="form-group col-6">
                    <label class="form-control-label">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SalesChannelName')">*</span>
                      </label>
                      <input class="form-control" name="SalesChannelName" formControlName="SalesChannelName">
                      <t4-display-validation controlName="SalesChannelName" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                </div>
                <div class="form-group col-6">
                    <label class="ckbox ckbox-input">
                        <input type="checkbox" name="AllowNewMembers" formControlName="AllowNewMembers">
                        <span> {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.OPEN-FOR-NEW-MEMBERS' | translate}}</span>
                    </label>
                </div>
                <div class="form-group col-6">
                    <label class="form-control-label">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.CODE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SalesChannelCode')">*</span>
                      </label>
                      <input class="form-control" name="SalesChannelCode" formControlName="SalesChannelCode" [readonly] = "!this.isNewReseller">
                      <t4-display-validation controlName="SalesChannelCode" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                </div>
                <div class="form-group col-6">
                    <label class="form-control-label">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.WEBSITE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('ResellerWebsite')">*</span>
                      </label>
                      <input class="form-control" name="ResellerWebsite" formControlName="ResellerWebsite">
                      <t4-display-validation controlName="ResellerWebsite" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                </div>
                <div class="form-group col-6">
                    <label class="form-control-label">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.EMAIL' | translate}}<span class="tx-danger" *ngIf="isRequiredField('ResellerEmail')">*</span>
                      </label>
                      <input class="form-control" name="ResellerEmail" formControlName="ResellerEmail">
                      <t4-display-validation controlName="ResellerEmail" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                </div>
                <div class="form-group col-6">
                    <label class="form-control-label">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.IBAN' | translate}}<span class="tx-danger" *ngIf="isRequiredField('ResellerIBAN')">*</span>
                      </label>
                      <input class="form-control" name="ResellerIBAN" formControlName="ResellerIBAN">
                      <t4-display-validation controlName="ResellerIBAN" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                </div>
                <div class="form-group col-12">
                  <label class="form-control-label">{{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.PRIMARY-CONTACT' | translate}}<span class="tx-danger" *ngIf="isRequiredField('PrimaryContactId')">*</span></label>
      
                  <ng-select [items]="contacts | async" bindLabel="Name" bindValue="Id" formControlName="PrimaryContactId" placeholder="{{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.PRIMARY-CONTACT.PLACEHOLDER.ACCOUNT' | translate}}" [typeahead]="contactsInput$" loadingText="{{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.PRIMARY-CONTACT.LOADING-TEXT' | translate}}"
                              [clearable]="false" notFoundText="{{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.PRIMARY-CONTACT.NOT-FOUND-TEXT.NO-ACCOUNTS-FOUND' | translate}}" appendTo="body" [loading]="contactsLoading" [virtualScroll]="true" typeToSearchText="{{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.PRIMARY-CONTACT.TYPE-TO-SEARCH-TEXT' | translate}}">
                      <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          {{ item.FirstName }}{{ item && item.MiddleName ? ' ' + item.MiddleName + ' ' : ' ' }}{{ item.LastName }}{{ item && item.Email ? ' (' + item.Email + ')' : '' }}
                      </ng-template>
                  </ng-select>
      
                  <t4-display-validation controlName="PrimaryContactId" [form]="mainForm" [messages]="validationMessages"></t4-display-validation>
                </div>
          </div>

          <!-- <h6 class="form-header h6-icon">
            PRIMAIR ACCOUNT
          </h6>
          <div class="form-group">
            <label class="form-control-label">Primair contactpersoon: <span class="tx-danger" *ngIf="isRequiredField('PrimaryContactId')">*</span></label>

            <ng-select [items]="contacts | async" bindLabel="Name" bindValue="Id" formControlName="PrimaryContactId" placeholder="Account" [typeahead]="contactsInput$" loadingText="Laden..."
                        [clearable]="false" notFoundText="Geen accounts gevonden" appendTo="body" [loading]="contactsLoading" [virtualScroll]="true" typeToSearchText="Type om te zoeken">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    {{ item.FirstName }}{{ item && item.MiddleName ? ' ' + item.MiddleName + ' ' : ' ' }}{{ item.LastName }}{{ item && item.Email ? ' (' + item.Email + ')' : '' }}
                </ng-template>
            </ng-select>

            <t4-display-validation controlName="PrimaryContactId" [form]="mainForm" [messages]="validationMessages"></t4-display-validation>
          </div> -->

        </div>
        <div class="col-12 col-lg-6">
          <div >
            <t4-tabs  (selected)="onSelectedTabChange($event)">
              <t4-tab tabTitle="{{'DIALOGS.EDIT-FANCLUB-FORM.TAB-TITLE.FIRST-LOCATION.' | translate}}" id="First"></t4-tab> 
              <t4-tab tabTitle="{{'DIALOGS.EDIT-FANCLUB-FORM.TAB-TITLE.SECOND-LOCATION.' | translate}}" id="Second"></t4-tab>
            </t4-tabs>  
              
                    <div [hidden]="selectedTab !== 'First'">
                      <div class="row">
                          <div class="form-group col-12">
                              <label class="form-control-label">
                                {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.LOCATION-NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationName')">*</span>
                              </label>
                              <input class="form-control" name="LocationName" formControlName="LocationName">
                              <t4-display-validation controlName="LocationName" [form]="mainForm" [messages]="validationMessages">
                              </t4-display-validation>
                            </div>
                        <div class="form-group col-4">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.POSTAL-CODE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationPostalCode')">*</span>
                          </label>
                          <input class="form-control" name="LocationPostalCode" (change)="checkPostalCode(false)"
                            formControlName="LocationPostalCode">
                          <t4-display-validation controlName="LocationPostalCode" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
                        <div class="form-group col-5">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.STREET-NUMBER' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationStreetNr')">*</span>
                          </label>
                          <input class="form-control" name="LocationStreetNr" (change)="checkPostalCode(false)" formControlName="LocationStreetNr">
                          <t4-display-validation controlName="LocationStreetNr" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
                        <div class="form-group col-3">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.STREET-NUMBER.ADDITION' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationStreetNrAddition')">*</span>
                          </label>
                          <input class="form-control" name="LocationStreetNrAddition" formControlName="LocationStreetNrAddition">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-6">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.STREET-NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationStreet')">*</span>
                          </label>
                          <input class="form-control" name="LocationStreet" formControlName="LocationStreet">
                          <t4-display-validation controlName="LocationStreet" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
                        <div class="form-group col-6">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.CITY-LOCATION' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationCity')">*</span>
                          </label>
                          <input class="form-control" name="LocationCity" formControlName="LocationCity">
                          <t4-display-validation controlName="LocationCity" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.FIRST-LOCATION.COUNTRY' | translate}}<span class="tx-danger" *ngIf="isRequiredField('LocationCountryId')">*</span>
                          </label>
                          <ng-select [items]="countries" bindLabel="Name" bindValue="Id" formControlName="LocationCountryId"
                            placeholder="{{'DIALOGS.EDIT-FANCLUB-FORM.PLACEHOLDER.FIRST-LOCATION.COUNTRY' | translate}}" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                            <!-- Selected item -->
                            <ng-template ng-label-tmp let-item="item">
                              <ng-container *ngIf="item != null">
                                {{('COUNTRY.'+item.Iso2) | translate}}
                              </ng-container>
                            </ng-template>
                            <!-- Dropdown items -->
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                              {{('COUNTRY.'+item.Iso2) | translate}}
                            </ng-template>
                          </ng-select>
                          <t4-display-validation controlName="LocationCountryId" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
              
                      </div>
                    </div>
                <div [hidden]="selectedTab !== 'Second'">
              
                  <div class="row">
                      <div class="form-group col-12">
                          <label class="form-control-label">
                            {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.LOCATION-NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationName')">*</span>
                          </label>
                          <input class="form-control" name="SecondaryLocationName" formControlName="SecondaryLocationName" (change)="updateRequiredFields()">
                          <t4-display-validation controlName="SecondaryLocationName" [form]="mainForm" [messages]="validationMessages">
                          </t4-display-validation>
                        </div>
                    <div class="form-group col-4">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.POSTAL-CODE' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationPostalCode')">*</span>
                      </label>
                      <input class="form-control" name="SecondaryLocationPostalCode" (change)="checkSecondaryPostalCode(false); updateRequiredFields()"
                        formControlName="SecondaryLocationPostalCode">
                      <t4-display-validation controlName="SecondaryLocationPostalCode" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                    </div>
                    <div class="form-group col-5">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.STREET-NUMBER' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationStreetNr')">*</span>
                      </label>
                      <input class="form-control" name="SecondaryLocationStreetNr" (change)="checkSecondaryPostalCode(false) ; updateRequiredFields()" formControlName="SecondaryLocationStreetNr" >
                      <t4-display-validation controlName="SecondaryLocationStreetNr" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                    </div>
                    <div class="form-group col-3">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.STREET-NUMBER.ADDITION' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationStreetNrAddition')">*</span>
                      </label>
                      <input class="form-control" name="SecondaryLocationStreetNrAddition" formControlName="SecondaryLocationStreetNrAddition" (change)="updateRequiredFields()">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.STREET-NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationStreet')">*</span>
                      </label>
                      <input class="form-control" name="SecondaryLocationStreet" formControlName="SecondaryLocationStreet" (change)="updateRequiredFields()">
                      <t4-display-validation controlName="SecondaryLocationStreet" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                    </div>
                    <div class="form-group col-6">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.CITY-NAME' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationCity')">*</span>
                      </label>
                      <input class="form-control" name="SecondaryLocationCity" formControlName="SecondaryLocationCity" (change)="updateRequiredFields()">
                      <t4-display-validation controlName="SecondaryLocationCity" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label class="form-control-label">
                        {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.COUNTRY' | translate}}<span class="tx-danger" *ngIf="isRequiredField('SecondaryLocationCountryId')">*</span>
                      </label>
                      <ng-select [items]="countries" bindLabel="Name" bindValue="Id" formControlName="SecondaryLocationCountryId"
                        placeholder="{{'DIALOGS.EDIT-FANCLUB-FORM.PLACEHOLDER.SECOND-LOCATION.COUNTRY' | translate}}" [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="updateRequiredFields()" >
                        <!-- Selected item -->
                        <ng-template ng-label-tmp let-item="item">
                          <ng-container *ngIf="item != null">
                            {{('COUNTRY.'+item.Iso2) | translate}}
                          </ng-container>
                        </ng-template>
                        <!-- Dropdown items -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          {{('COUNTRY.'+item.Iso2) | translate}}
                        </ng-template>
                      </ng-select>
                      <t4-display-validation controlName="SecondaryLocationCountryId" [form]="mainForm" [messages]="validationMessages">
                      </t4-display-validation>
                    </div>
                  </div>
                  <div class="row">
                    <label class="label-delete-style">
                      {{'DIALOGS.EDIT-FANCLUB-FORM.LABEL.SECOND-LOCATION.DELETE' | translate}} 
                    </label> 
                    <button (click)="actionClick('delete')" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline button-delete-style"></button>
                </div>
            </div>
          </div>  
        </div>
      </div>

      <!-- Buttons -->
      <div class="row">

        <div class="col-6">
          <button (click)="submit()" [disabled]="!fanclubIsValidAndDirty()" class="btn btn-block btn-primary bd-0">
            <i class="far fa-save"></i>
            <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
          </button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
            <i class="fas fa-ban"></i>
            <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
          </button>
        </div>
      </div>

    </div>
  </form>
