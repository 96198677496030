<!-- MODAL HEADER-->
<div class="row modal-bar mb-5">

    <div class="col-sm-6">
        <h5 class="modal-title">{{'COMPONENTS.CUSTOMER.SEARCH-DIALOG.MODAL-TITLE.SELECT-CUSTOMER' | translate}}</h5>
    </div>

    <div class="col-sm-6">

        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>



<t4-customer-search viewMode="minimal" (onCustomerSelect)="onCustomerSelect($event)" ></t4-customer-search>