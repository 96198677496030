import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { HttpLoaderService, ListViewSelectionMode, PagedResult, SnackbarService} from '@tymes4-shared';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomerPassepartoutPositionsComponent } from '../customer-passepartout-positions/customer-passepartout-positions.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { ApplicationModuleService, OrderService } from '../../api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-matchdaypass-tickets',
  templateUrl: './customer-matchdaypass-details.component.html',
  styleUrls: ['./customer-matchdaypass-details.component.scss'],
})
export class CustomerMatchdaypassDetailComponent implements OnInit {
    public tickets = null;
    private newOrderManagement: boolean = false;
    @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12,
    selectionMode: ListViewSelectionMode.None,
    disableSort: true,
    headers: [
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.EVENT', width: 280, hideOn: null},
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.BLOCK', width: 90, hideOn: null},
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.ROW-SEAT', width: 120, hideOn: null},
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.ORDERID', width: 110, hideOn: null},
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.ORDERED-BY', width: 110, hideOn: null},
      {sortField: null, label: 'RELATIONS.CUSTOMERCARD.TICKETS.LISTVIEW.HEADER.BARCODE', width: 150, hideOn: null},
    ]
  };
  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<CustomerPassepartoutPositionsComponent>,
              private orderService: OrderService,
              private loader: HttpLoaderService,
              private dialog: MatDialog,
              private applicationModuleService: ApplicationModuleService,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.tickets = this.passedData.tickets;
    this.applicationModuleService.getApplicationModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.newOrderManagement = activeModules.some((module) => module.toUpperCase() == 'NEWORDERMANAGEMENT')
      }
    });
  }
  
  OnDataRequest($event)
  {
    let d : PagedResult = {
      Records: this.passedData.tickets,
      TotalRecords: this.passedData.tickets.length
    }
    
    this.listVw.Data = d;
  }
  openOrder(ev: MouseEvent, orderId: number) {
    ev.stopPropagation();
    ev.preventDefault();

    let useNewOrderScreen: boolean = false;
    if (useNewOrderScreen) {
      this.router.navigate(['/sales/orders/details', orderId]);
    }
    else {
      this.loader.open();
  
      this.orderService.getOrderDetails(orderId).subscribe((details: any) => {
        this.loader.close();
        this.openOrderDialog(details);
      });
    }
  }
  openOrderDialog(order) {
    const dialogRef: MatDialogRef<any> = this.dialog.open(OrderDetailsComponent, {
      width: '95%',
      maxWidth: '95%',
      disableClose: true,
      data: {
        title: this.translate.instant('COMPONENTS.CUSTOMER-TICKETS.OPEN-ORDER-DIALOG.TITLE.ORDER-DETAILS'),
        isReadOnly: true,
        order: order
      }
    });
    dialogRef.afterClosed()
      .subscribe(updatedOrder => {
          // nothing to do, dialog is read only
        }
      );
  }
}