
  <div class="row modal-bar">
    <div class="col-sm-6">
      <h5 class="modal-title"> {{ 'RESERVATIONS.LISTVIEW.DETAILS.RESERVATION-DETAILS-X' | translate : {reservationdetailid: reservationDetails.Id} }}</h5>
    </div>
    <div class="col-sm-6">
      <div class="modal-actions">
        <div class="modal-close">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span (click)="dialogRef.close(false);">×</span>
          </button>
        </div>
        </div>
    </div>

  </div>

  <div class="object-actions mt-3" *ngIf="!isInArchiveMode">

    <button (click)="startOrderProcess()" [disabled]="!orderAllowed()" class="btn btn-primary border btn-small" type="button"><i class="fas fa-cart-plus"></i>{{ 'RESERVATIONS.LISTVIEW.BUTTON.CONVERT-TO-ORDER' | translate }}</button>
    <span class="separator"></span>
    <button (click)="editSelectedReservation()" [disabled]="reservationCanBeEdited === false" class="btn btn-warning border btn-small" type="button"><i class="far fa-edit"></i>{{ 'RESERVATIONS.LISTVIEW.BUTTON.EDIT' | translate }}</button>
  </div>

<app-reservation-header [details]="reservationDetails"></app-reservation-header>

<t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

  <ng-container *t4ListItem="let item">
    <td *ngIf="item.PassePartoutName == null && item.CrossSellProductName == null">{{item.EventDate | localDateTime }} - {{item.EventName}}</td>
    <td *ngIf="item.PassePartoutName != null && item.CrossSellProductName == null">{{ item.PassePartoutName | replaceOnEmpty:''}}</td>
    <td *ngIf="item.PassePartoutName == null && item.CrossSellProductName != null">{{ item.CrossSellProductName | replaceOnEmpty:''}}</td>
    <td *ngIf="item.CrossSellProductVariantName == null">{{item.TicketTypeName}}</td>
    <td *ngIf="item.CrossSellProductVariantName != null">{{item.CrossSellProductVariantName | translate | replaceOnEmpty:''}}</td>
    <td>{{item.ParentSectionName}}</td>
    <td>{{item.RowNumber | replaceOnEmpty}} / {{item.SeatNumber | replaceOnEmpty}}</td>
    <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
    <td>{{item.ContingentName | replaceOnEmpty}}</td>
    <td class="text-right">{{getLinePrice(item) | currency:'EUR' }}</td>

  </ng-container>

</t4-list-view>


<div class="grid grid-row">
  <div class="grid-cell" fxFlex></div>
  <div class="grid-cell text-right" fxFlex="80px"><b>{{ calculateTotal() | currency:'EUR' }}</b></div>
</div>
