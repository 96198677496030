/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactModel { 
    Id?: number | null;
    CustomerId?: number | null;
    Email?: string | null;
    Initials?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    DateOfBirth?: Date | null;
    Gender?: string | null;
    MobileNr?: string | null;
    CustomerNumber?: string | null;
    State?: string | null;
    Primary?: boolean;
    Active?: boolean;
    LanguageId?: number | null;
    CompletedRegistration?: boolean;
    HasPendingRegistration?: boolean;
    ImpersonationLink?: string | null;
    SSOId?: string | null;
    SSOUnlinkingAllowed?: boolean;
    ExternalContactId?: string | null;
    ExternalContactReference?: string | null;
    IsPlaceholder?: boolean;
    AccountingId?: string | null;
    readonly Age?: number | null;
}

