import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";
import { EntranceService } from "../../api";

@Injectable()
export class EntranceValidator{
    constructor(private entranceService: EntranceService) {}

    public editingObject : any;

    entranceNameTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            setTimeout(() => {
                this.entranceService.getEntranceByName(control.value).subscribe(data=>{
                    if (data !== null && data !== void 0) {
                        if (data.Id != this.editingObject.Id) {
                             resolve({"nametaken": data});
                        }
                    }
                    resolve(null);
                });
            }, 200);
        });
    }

    entranceCodeTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {

            var v = control.value;

            setTimeout(() => {
                this.entranceService.getEntranceByCode(v).subscribe(data=>{
                    if (data !== null && data !== void 0) {
                        if (data.Id != this.editingObject.Id) {
                             resolve({"codetaken": data});
                        }
                    }
                    resolve(null);
                });
            }, 200);



        });
    }
}
