import {Injectable, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {ConfirmService} from '@tymes4-shared';
import {HttpLoaderService} from '@tymes4-shared';
// import {SalesEntityModificationService} from './http/sales-entity-modification.service';
import {LoggingService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { SalesEntityModificationService } from '../api';

@Injectable()
export class SalesEntityModificationStateService implements OnInit {

  constructor(private logging: LoggingService,
              private loader: HttpLoaderService,
              private router: Router,
              private confirmService: ConfirmService,
              private salesEntityModService: SalesEntityModificationService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  continueModificationFlow(): any {
    //check if the user has an active modification

    this.salesEntityModService.getActiveModificationId().subscribe(id => {

      if (id !== null) {

        this.confirmService.confirm({
          title: this.translateService.instant('SERVICES.SALES-ENTITY-MODIFICATION-STATE.SERVICE.TITLE.ACTIVE-PROCESS'),
          message: this.translateService.instant('SERVICES.SALES-ENTITY-MODIFICATION-STATE.SERVICE.MESSAGE.ACTIVE-PROCESS'),
          okonly: false
        }).subscribe((confirmed: boolean) => {
          //continue
          if (confirmed) {

            this.router.navigate(['/sales/edit-sales']);
          } else {

            this.loader.open();

            this.salesEntityModService.deleteActive().subscribe(result => {
              this.loader.close();
            });
          }
        });
      }
    });
  }
}





