/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PendingReservationLineView { 
    PlaceSelectionAllowed?: boolean;
    Id?: number;
    EventPlacementId?: number | null;
    PendingReservationId?: number;
    TicketTypeId?: number | null;
    TicketTypeName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    EventName?: string | null;
    EventId?: number | null;
    EventDate?: Date | null;
    ParentSectionName?: string | null;
    ParentSectionShortName?: string | null;
    ParentSectionColor?: string | null;
    ReservationLineId?: number | null;
    PriceVariationId?: number | null;
    PriceVariationName?: string | null;
    CustomerId?: number | null;
    CustomerName?: string | null;
    ParentVenueBuildingBlockId?: number | null;
    GroupingId?: string | null;
    Row?: number | null;
    Column?: number | null;
    Named?: boolean;
    AssigningRatio?: number | null;
    CrossSellProductId?: number | null;
    CrossSellProductName?: string | null;
    CrossSellProductDescription?: string | null;
    CrossSellProductVariantName?: string | null;
    CrossSellProductVariantPrice?: number | null;
    CrossSellProductVariantSKU?: string | null;
    CrossSellProductVariantId?: number | null;
    MatchDayPassId?: number | null;
    SectorId?: number | null;
    ContingentId?: number | null;
    ContingentName?: string | null;
    readonly PositionDetermined?: boolean;
}

