import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {CreateRebuyExportComponent} from 'app/shared/dialogs/create-rebuy-export/create-rebuy-export.component';
import {UploadRepurchaseImportfileComponent} from 'app/shared/dialogs/upload-repurchase-importfile/upload-repurchase-importfile.component';
import {ListViewSelectionMode, NotificationCommunicationService} from '@tymes4-shared';
import {GenericErrorHandler} from 'app/shared/helpers/generic-error-handler';
import {HttpLoaderService} from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import {ConfirmReservationImportComponent} from '../../dialogs/confirm-reservation-import/confirm-reservation-import.component';
import {DialogHelper, DialogWidth} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { CreateRepurchaseExportArgs, EventRepurchaseLineService, EventRepurchaseLineViewPagedResult, EventService, LongRunningOperationModel, LongRunningOperationTypeEnum, RepurchaseService, SalesPeriodRepurchaseLineService, SalesPeriodRepurchaseLineViewPagedResult, SalesPeriodService } from '../../api';

@Component({
  selector: 'app-repurchase-reservations',
  templateUrl: './repurchase-reservations.component.html',
  styleUrls: ['./repurchase-reservations.component.scss']
})
export class RepurchaseReservationsComponent implements OnInit {

  @ViewChild('listVw') listVw;
  @ViewChild('progressBar') progressBar;

  public isSalesPeriod: boolean = false;
  public isEvent: boolean = false;

  public listOptions = {};

  public deletionAllowed: boolean = false

  constructor(private dialog: MatDialog, private loader: HttpLoaderService,
              private eventRepurchaseLineService: EventRepurchaseLineService,
              private salesPeriodRepurchaseLineService: SalesPeriodRepurchaseLineService,
              private eventService: EventService,
              private salesPeriodService: SalesPeriodService,
              private errorHandler: GenericErrorHandler,
              private snackBar: SnackbarService, 
              private confirmService: ConfirmService,
              private repurchaseService: RepurchaseService,
              private translate: TranslateService,
              private notificationCommunicationService : NotificationCommunicationService) {
  }


  private spId: number;
  public evId: number;
  private selectedItems = [];
  public processableCount = 0;
  public latestLongRunningOperation = null;
  public longRunningOperationTypeEnum = LongRunningOperationTypeEnum;
  public disableDeleteSelectedButton = false;
  public disableDeleteUnprocessedButton = false;


  @Input()
  set salesPeriodId(id: number) {
    this.spId = id;
    this.isSalesPeriod = true;
    this.loadListOptions();
  }

  @Input()
  set eventId(id: number) {
    this.evId = id;
    this.isEvent = true;
    this.loadListOptions();
  }

  ngOnInit() {
  }

  loadListOptions() {
    this.listOptions = {
      search: true,
      showBarcodeSearch: false,
      pageSize: 12,
      noItemsText: this.translate.instant('COMPONENTS.REPURCHASE-RESERVATIONS.NO-ITEMS-TEXT'),
      selectionMode: ListViewSelectionMode.None,
      disableSort: true,
      actionDropdownWidth: '300px',
      headers: [
        {sortField: 'Id', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ID', width: 50, hideOn: null},
        {sortField: 'SectionName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.SECTION', width: 70, hideOn: null, isDefaultSort: 'asc'},
        {sortField: 'RowNumber', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ROWNUMBER', width: 30, hideOn: null},
        {sortField: 'SeatNumber', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.SEATNUMBER', width: 30, hideOn: null},
        {sortField: 'AllowSeatSelection', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.ALLOW.SEAT-SELECTION', width: 70, hideOn: null},
        {sortField: 'ToPassePartoutName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.PASSE-PARTOUT', width: 300, hideOn: null, visible: this.isSalesPeriod },
        {sortField: 'CustomerName', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.CUSTOMER', hideOn: null},
        {sortField: 'ExpiryDate', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.EXPIRY-DATE', width: 120, hideOn: null},
        {sortField: 'Status', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.STATUS', width: 120, hideOn: null},
        {sortField: 'Reason', label: 'REPURCHASE.RESERVATIONS.LIST.HEADER.REASON', width: 300, hideOn: null},
        {label: ''}
      ]
    };
  }


  actionClick(action: string, item: any = null) {
    if (action === 'delete') {
      this.deleteSelectedLines(item);
    }
  }

  deleteSelectedLines(item: any) {
    if (item === null) return;

    let msg = this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE');

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU_SURE'),
      message: msg,
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (confirmed === true) {
        this.disableDeleteSelectedButton = true;
        let ids = [item.Id];

        if (this.isSalesPeriod) {
          this.salesPeriodRepurchaseLineService.deleteSalesPeriodRepurchaseLineMultiple(ids).subscribe(() => {
            this.listVw.refreshData();
          }, error => {
            this.errorHandler.handle(error);
          });
        } else {
          this.eventRepurchaseLineService.deleteEventRepurchaseLineMultiple(ids).subscribe(() => {
            this.listVw.refreshData();
          }, error => {
            this.errorHandler.handle(error);
          });
        }
      }
    });

    this.disableDeleteSelectedButton = false;
    this.listVw.refreshData()
  }

  OnItemSelectionChange(items) {
    this.selectedItems = items;

    var reserved = this.selectedItems.filter(function (r) {
      if (r.Status === 3) return r;
    });

    this.deletionAllowed = this.selectedItems.length > 0 && reserved.length == 0;
  }


  OnDataRequest(e) {
    if (this.isSalesPeriod) {
      let additionalFilters : { [key: string]: string; } = {};
      if (this.spId != null)
      {
          additionalFilters['salesPeriodId'] = this.spId.toString();
      }

      this.salesPeriodRepurchaseLineService.searchSalesPeriodRepurchaseLines(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, additionalFilters).subscribe((resp: SalesPeriodRepurchaseLineViewPagedResult) => {
        this.loader.close();

        this.listVw.Data = resp;

        this.updateStatus();
      });
    } else if (this.evId) {
      const filter = `${this.evId}`;

      this.eventRepurchaseLineService.searchEventRepurchaseLines(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { ['eventId']: `${this.evId}` }).subscribe((resp: EventRepurchaseLineViewPagedResult) => {
        this.loader.close();

        this.listVw.Data = resp;

        this.updateStatus();
      });
    } else {
      this.loader.close();
    }
  }

  updateStatus(): any {
    if (this.isSalesPeriod) {
      this.salesPeriodRepurchaseLineService.getSalesPeriodRepurchaseLineReservableCount(this.spId).subscribe((count: number) => {
          this.processableCount = count;
        },
        error => {
          this.errorHandler.handle(error);
        });
    } else {
      this.eventRepurchaseLineService.getEventRepurchaseLineReservableCount(this.evId).subscribe((count: number) => {
          this.processableCount = count;
        },
        error => {
          this.errorHandler.handle(error);
        });
    }
  }

  procesReservations() {
    if (this.processableCount === 0) {
      return;
    }
    const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.md, {
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      payload: {processableCount: this.processableCount}
    });
    this.dialog.open(ConfirmReservationImportComponent, dialogOptions)
      .afterClosed().subscribe((result: boolean | { groupByCustomer: boolean }) => {
      if (result === false || result === true) {
        return;
      }

      this.loader.open();
      if (this.isSalesPeriod) {
        this.salesPeriodService.processReservationsSalesPeriod(this.spId, result.groupByCustomer).subscribe((lro: LongRunningOperationModel) => {
            this.loader.close();
            this.notificationCommunicationService.updateNotificationsAndLROS();
          },
          error => {
            this.errorHandler.handle(error)
          });
      } else {
        this.eventService.processReservations(this.evId, result.groupByCustomer).subscribe((lro: LongRunningOperationModel) => {
            this.loader.close();
            this.notificationCommunicationService.updateNotificationsAndLROS();
          },
          error => {
            this.errorHandler.handle(error)
          });
      }
    });
  }

  importRepurchase() {
    let title = this.translate.instant('REPURCHASE.RESERVATIONS.IMPORT.REPURCHASE.FILE');
    let dialogRef: MatDialogRef<any> = this.dialog.open(UploadRepurchaseImportfileComponent, {
      width: '720px',
      disableClose: true,
      data: {title: title, type: this.spId ? 1 : 2}
    })
    dialogRef.afterClosed()
      .subscribe(lro => {

          if(lro!=null){
            this.notificationCommunicationService.updateNotificationsAndLROS();
          }
        }
      )
  }

  progressFinished($event) {
    //refresh data and status
    this.listVw.refreshData();
  }

  getStatusName(status) {
    var statusString =  '-';
    switch (status) {
      case 1:
        statusString ='REPURCHASE.RESERVATIONS.IMPORT.STATUS.READY.FOR.IMPORT';
        break;
      case 2:
        statusString ='REPURCHASE.RESERVATIONS.IMPORT.STATUS.SKIPPED';
        break;
      case 3:
        statusString ='REPURCHASE.RESERVATIONS.IMPORT.STATUS.RESERVED';
        break;
      case 4:
        statusString = 'REPURCHASE.RESERVATIONS.IMPORT.STATUS.FAILED';
        break;
    }

    return this.translate.instant(statusString);
  }


  exportList() {
    this.loader.open();
    var args : CreateRepurchaseExportArgs = {
      PassePartoutSelection: null,
      SalesPeriodId: this.spId,
      EventId: this.evId
    };

    this.repurchaseService.createRepurchaseExportList(args).subscribe((data: Blob) => {
        this.loader.close();

        if(this.spId){
          FileSaver.saveAs(data, this.translate.instant('REPURCHASE.RESERVATIONS.EXPORT.LIST.MESSAGE.SALESPERIOD.FILENAME', {id: this.spId}));
        }
        else{
          FileSaver.saveAs(data, this.translate.instant('REPURCHASE.RESERVATIONS.EXPORT.LIST.MESSAGE.EVENT.FILENAME', {id: this.evId}));
        }


      },
      error => {
        this.errorHandler.handle(error);
      }
    );
  }

  deleteUnprocessed() {
    let msg = this.translate.instant('REPURCHASE.RESERVATIONS.DELETE.UNPROCESSED.MESSAGE');

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: msg,
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (!confirmed) {
        return;
      }

      this.disableDeleteUnprocessedButton = true;
      this.loader.open();

      if (this.isSalesPeriod) {
        this.salesPeriodRepurchaseLineService.deleteSalesPeriodRepurchaseLineUnprocessed(this.spId).subscribe(() => {

          this.loader.close();
          this.listVw.refreshData();
          this.listVw.clearSelection();
          this.selectedItems = null;
        }, error => {
          this.errorHandler.handle(error);
        });
      } else {
        this.eventRepurchaseLineService.deleteEventRepurchaseLineUnprocessed(this.evId).subscribe(() => {

          this.loader.close();
          this.listVw.refreshData();
          this.listVw.clearSelection();
          this.selectedItems = null;
        }, error => {
          this.errorHandler.handle(error);
        });
      }
    });

    this.disableDeleteUnprocessedButton = false;
    this.listVw.refreshData()
  }

  exportRebuy() {
    let title = this.translate.instant('REPURCHASE.RESERVATIONS.EXPORT.REBUY.TITLE');

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateRebuyExportComponent, {
      width: '1280px',
      disableClose: true,
      data: {title: title, salesPeriodId: this.spId, eventId: this.evId}
    })
    dialogRef.afterClosed()
      .subscribe(id => {
          //just close, nothing to do.
        }
      )
  }

  getProcessable() {
    return this.translate.instant('REPURCHASE.RESERVATIONS.BUTTON.PROCESS.RESERVATIONS', {count: this.processableCount})
  }
}
