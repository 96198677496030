<div class="object-actions mb-3 bg-white text-right">
  <div class="row">
    <div class="col-3 my-auto">
      <div class="form-group mb-0 mg-b-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bd-t-0 bd-l-0"><i class="fas fa-search"></i> </span>
          </div>
          <input type="text" style="background-color: #f5f6fa;" class="form-control bd-t-0 bd-r-0 bd-l-0 my-auto"
            (keydown.enter)="search($event)" placeholder="{{'T4.LIST-VIEW.SEARCHINLIST' | translate}}"
            [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="search(option)" (keydown.enter)="search(option)"
              *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="col-3 my-auto" style="text-align: left;">
      <input type="checkbox" [(ngModel)]="this.showPrimaryVariationsOnly">
      <span class="ml-2">{{ 'MATCHDAYPASS.DETAILS.SHOW-PRIMARY' | translate }}</span>
    </div>
    <div class="col-6 my-auto">
      <button [disabled]="!isDirty" (click)="submit()" class="btn btn-primary border btn-small" type="button"><i
          class="pd-x-5 far fa-save"></i> {{'GENERIC.BUTTON.SAVE' | translate}}
      </button>
      <button (click)="actionClick('cancel')" class="btn btn-secondary border btn-small" type="button"><i
          class="fas fa-ban"></i> {{ 'GENERIC.BUTTON.CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
<t4-page-info info='MATCHDAYPASS.DETAILS.INFO'></t4-page-info>
<div class="col-lg-12" #formElement style="padding-left: 0; padding-right: 0;">
  <div class="card card-table overflow-hidden">
    <div class="table-responsive" style="overflow-x: scroll;">
        <table class="table table-bordered" cellspacing="0" *ngIf="editDetails">
          <ng-container *ngFor="let sector of currentSectors;let index=index;let odd = odd">
            <tr *ngIf="isMultipleOf(10, index)" style="color: black;">
              <th [width]="cellWidth">&nbsp;</th>
              <th colspan="1" [width]="cellWidth"  *ngFor="let variation of editDetails.PriceVariations" [hidden]="(this.showPrimaryVariationsOnly === false || variation.IsPrimary === true) === false">
                  {{variation.Name}}  
              </th>
            </tr>
            <tr [class.alternative]="odd">
              <td [width]="cellWidth">
                  {{sector.Name}}
              </td>

              <ng-container *ngIf="!searching; else search" >
                <ng-container *ngFor="let variation of editDetails.PriceVariations; let variationIndex=index; let odd = odd">
                <td [hidden]="(this.showPrimaryVariationsOnly === false || variation.IsPrimary === true) === false" class="text-right clickable" [width]="cellWidth" (click)="openOrderLinePriceComponent(sector, variation)" >
                  {{ pricingGrid[index + startItem][1][variationIndex] | currency:'EUR' | replaceOnEmpty  }}
                </td>
                </ng-container>
              </ng-container>
              <ng-template #search>
                <ng-container *ngFor="let variation of editDetails.PriceVariations; let variationIndex=index; let odd = odd">
                  <td [hidden]="(this.showPrimaryVariationsOnly === false || variation.IsPrimary === true) === false" class="text-right clickable" [width]="cellWidth" (click)="openOrderLinePriceComponent(sector, variation)" >
                    {{ searchPricingGrid[index + startItem][0][1][variationIndex] | currency:'EUR' | replaceOnEmpty  }}
                  </td>
                </ng-container>
              </ng-template>
            </tr>
          </ng-container>
          
        </table>
    </div>
  </div>
  <t4-pager [pageSize] = "20" (onPage)="doPage($event)" #pagerComponent></t4-pager>
</div>