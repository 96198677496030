import { filter } from 'rxjs/operators';
import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KeyValuePair, HttpLoaderService } from "@tymes4-shared";
import {CrossSellProductVariantService, VatRateService} from "../../api";

@Component({
    selector: 'app-edit-crosssell-product-variant',
    templateUrl: './edit-crosssell-product-variant.component.html'
})
export class EditCrossSellProductVariantComponent implements OnInit {

    private isNew: boolean;
    public vatRates: any;

    public form: FormGroup;
    public saving: boolean = false;

    public productVariantId: number;
    public productVariant: any = {};

    validationMessages = {}

    private requiredFields: KeyValuePair<boolean> = null;

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditCrossSellProductVariantComponent>,
    private loader: HttpLoaderService,
    private productVariantService: CrossSellProductVariantService,
    private vatRateService: VatRateService
    ) {
    }

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {
        if(this.requiredFields === null)
          this.requiredFields = {};

        if(this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
          const form = targetForm ? targetForm : this.form;
          const formField = form.get(field);
          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
    }

    ngOnInit() {
        this.isNew = this.passedData.isNew;

        if (!this.isNew)
        {
            this.productVariantId = this.passedData.productVariant.Id;
            this.productVariant = this.passedData.productVariant;
        }
        else
        {
            this.productVariant.CrossSellProductId = this.passedData.productId;
            this.productVariant.Name = null;
            this.productVariant.SKU = null;
        }

        this.form = new FormGroup({
            Id: new FormControl(0),
            CrossSellProductId: new FormControl(''),
            Name: new FormControl({ value: '' }, [Validators.required, Validators.maxLength(256)]),
            SKU: new FormControl({ value: '' }, [Validators.required, Validators.maxLength(256)]),
            VatRateId: new FormControl('', Validators.required),
            AmountExVat: new FormControl({value: ''}, [Validators.required, Validators.max(Number.MAX_SAFE_INTEGER)]),
            AmountInVat: new FormControl({value: ''}, [Validators.required, Validators.max(Number.MAX_SAFE_INTEGER)]),
            CurrentStockCount: new FormControl({ value: '' }, [Validators.required, Validators.min(this.productVariant.SoldCount), Validators.max(Number.MAX_SAFE_INTEGER)]),
        })

        this.vatRateService.listActiveVatRates().subscribe((data) => {
            this.vatRates = data;
        });

        this.form.patchValue(this.productVariant);
    }

    getVatPercentage() {
        var vatRateId = this.form.controls['VatRateId'].value;
        if (!vatRateId)
        {
            return 0;
        }

        return this.vatRates.filter(x => x.Id == vatRateId)[0].Percentage;
    }

    OnVatRateChanged(event) {
        this.onAmountInVatChanged(null);
    }

    onAmountExVatChanged(event) {
        if (event.target.value === '') {
            this.form.controls['AmountExVat'].setValue(null);
            this.form.controls['AmountInVat'].setValue(null);
        } else {
            var vatPercentage = this.getVatPercentage();
            var amountExVat = +event.target.value;
            var vatAmount = vatPercentage == 0 ? 0 : Math.round(amountExVat * (vatPercentage / 100) * 100) / 100;
            var amountInVat = amountExVat + vatAmount;
            this.form.controls['AmountExVat'].setValue(amountExVat);
            this.form.controls['AmountInVat'].setValue(amountInVat);
        }
    }

    onAmountInVatChanged(event) {
        if (event != null && event.target.value === '') {
            this.form.controls['AmountExVat'].setValue(null);
            this.form.controls['AmountInVat'].setValue(null);
        } else {
            var vatPercentage = this.getVatPercentage();
            var amountInVat = event == null ? this.form.controls['AmountInVat'].value : +event.target.value;
            var vatAmount =  vatPercentage == 0 ? 0 : Math.round(((amountInVat / (1 + (vatPercentage / 100))) * (vatPercentage / 100)) * 100) / 100;
            var amountExVat = amountInVat - vatAmount;

            this.form.controls['AmountExVat'].setValue(amountExVat);
            this.form.controls['AmountInVat'].setValue(amountInVat);
        }
    }

    submit() {
        this.saving = true;
        this.loader.open();

        const data = this.form.value;

        if (this.isNew)
        {
            this.productVariantService.createVariant(data).subscribe((result) => {
                this.saving = false;
                this.loader.close();
                this.dialogRef.close(true);
            })
        }
        else
        {
            this.productVariantService.updateVariant(data).subscribe((result) => {
                this.saving = false;
                this.loader.close();
                this.dialogRef.close(true);
            })
        }
    }


}
