
<div class="card card-table" fill-height>

    <div class="row m-0 py-2 section-selection-header">    
        <div class="col-11"><h6>{{'SECTION.SELECTOR.HEADER.SECTIONS.WITHIN.LOCATION' | translate}}</h6></div>
    </div>

    <div class="p-5">
       
        <tree-root [nodes]="tvwNodes" (activate)="onNodeSelect($event)">
            <ng-template #treeNodeTemplate let-node let-index="index">
                
                <span class="mr-1" *ngIf="node.data.hasUnpublishedChanges">
                    <i class="fas fa-exclamation-circle text-warning"></i>
                </span>

                <span>{{ node.data.name }}</span>
                
            </ng-template>
        </tree-root>
            
    </div>
</div>
