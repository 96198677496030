<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form [formGroup]="form">
  <div class="section-wrapper mt-3">
    <div class="form-layout">
      <div class="row row-sm mg-b-10">
        <div class="col">
            <div class="form-group">
                <label class="form-control-label">{{'MEMBERSHIP.BATCH.SELECT-MEMBERSHIP-TYPE' | translate}}</label>
                <ng-select [items]="memberships" formControlName="MembershipId" bindLabel="Name" bindValue="Id" placeholder="{{'MEMBERSHIP.BATCH.SELECT-MEMBERSHIP-TYPE' | translate}}"
                [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
            <t4-display-validation [form]="form" controlName="MembershipId"></t4-display-validation>
        </div>
      </div>
      <div class="form-layout-footer row pt-5" >
        <div class="col-6">
          <button [disabled]="!form.valid" (click)="submit()" class="btn btn-block btn-primary bd-0"><span>{{'GENERIC.BUTTON.SAVE' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-window-close"></i><span>{{'GENERIC.BUTTON.CANCEL' | translate}}</span></button>
        </div>
      </div>
    </div>
  </div>


</form>

