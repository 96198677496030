<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.HEADER.DIFFERENT-TICKET-TEXT' | translate}}</label>
              <ng-select [items]="ticketTexts" bindLabel="Text" bindValue="Id" placeholder="{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.DIFFERENT-TICKET-TEXT' | translate}}" formControlName="TicketTextId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
                <label class="form-control-label">{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.HEADER.ENTRANCE' | translate}}</label>
                <ng-select [items]="selectableEntrances" bindLabel="EntranceName" bindValue="EntranceId" placeholder="{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.ENTRANCE' | translate}}" formControlName="EntranceId"
                           [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.HEADER.PRICE-VARIANT' | translate}}</label>
              <ng-select [items]="priceVariants" bindLabel="VariationName" bindValue="VariantId" placeholder="{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.PRICE-VARIANT' | translate}}" formControlName="PriceVariationId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>

        </div><!-- row -->

        <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || !form.dirty || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>
