import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PagedResult } from '@tymes4-shared';
import {HttpLoaderService} from '@tymes4-shared';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { PassePartoutPositionView, PassePartoutService } from '../../api';
export enum TicketSwapStatusEnum {
  None = "None",
  Offered = "Offered",
  Accepted = "Accepted",
  Sold = "Sold",
  Withdrawn = "Withdrawn",
  Revoked = "Revoked",
}

@Component({
  selector: 'app-customer-passepartout-positions',
  templateUrl: './customer-passepartout-positions.component.html',
  styleUrls: ['./customer-passepartout-positions.component.scss']
})
export class CustomerPassepartoutPositionsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<CustomerPassepartoutPositionsComponent>,
              private confirmService: ConfirmService,
              private passePartoutService: PassePartoutService,
              private loader: HttpLoaderService,
              private snackBar: SnackbarService,
              private translate: TranslateService) {
  }

  public listOptions; 
  public secondaryTicketingActive: boolean = false;
  private checkedTicketSwapStatus: boolean = false;

  @ViewChild('listVw') listVw;

  private customerPassePartout: any = null;

  ngOnInit() {

    this.customerPassePartout = this.passedData.payload;

    this.listOptions = {
      search: false,
      showBarcodeSearch: false,
      pageSize: 12,
      noItemsText: this.translate.instant('DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.NO-ITEMS-TEXT'),
      multiSelectMode: false,
      disableSort: true,
      disableSelect: true,
      headers: [
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.NUMBER', width: 90, hideOn: null},
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.DATE', width: 110, hideOn: null},
        {sortField: null, label: '', width: 70, hideOn: null},
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.EVENT', width: 330, hideOn: null},
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.SECTION', width: 90, hideOn: null},
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.ROW-AND-SEAT', width: 120, hideOn: null},
        {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.BARCODE', width: 240, hideOn: null},
      ]
    };
  }

  OnDataRequest(e) {
    this.loader.open();

    this.passePartoutService.getPassePartoutPositions(this.customerPassePartout.Id).subscribe((data: Array<PassePartoutPositionView>) => {
      if(!this.checkedTicketSwapStatus){
        data.forEach(position => {
          if(position.LatestTicketSwapStatus != TicketSwapStatusEnum.None && !this.checkedTicketSwapStatus){
            this.listOptions.headers.push({sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.SECONDARY_TICKETING', width: 80})
            this.checkedTicketSwapStatus = true;
            this.secondaryTicketingActive = true;
          }
        })
        this.listOptions.headers.push(
          {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.REASON', width: 200, hideOn: null},
          {sortField: null, label: 'DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.LISTVIEW.HEADER.BY', hideOn: null},
          {sortField: null, label: ''},
        );
        this.listVw.setOptions(this.listOptions)
        this.listVw.refreshData();
        this.checkedTicketSwapStatus = true;
      }
      
      this.loader.close();

      let paged = data.slice((e.pageNr - 1) * e.pageSize, (e.pageNr) * e.pageSize);
      let d : PagedResult = {
        Records: paged,
        TotalRecords: data.length
      }
      this.listVw.Data = d;

    });

  }

  confirmDelete(i) {

    let seat = i.SeatNumber == null ? '-' : i.SeatNumber;
    let row = i.RowNumber == null ? '-' : i.RowNumber;

    let confirmTxt = this.translate.instant('DIALOGS.CUSTOMER-PASSEPARTOUT-POSITIONS.CONFIRM.WARNING.DELETE-POSITION', {iEventName: i.EventName, iParentShortName: i.ParentShortName, row: row, seat: seat});

    this.passePartoutService.canRemovePosition(i.CustomerPassePartoutId, i.EventPlacementId).subscribe((result: boolean) => {
      if (result) {
        this.confirmService.confirm({ title: this.translate.instant('GENERIC.CONFIRM.CONFIRM'), message: confirmTxt, okonly: false }).subscribe((confirmed) => {
          if (confirmed) {
            this.loader.open();
            this.passePartoutService.deletePosition(i.CustomerPassePartoutId, i.EventPlacementId).subscribe(() => {
              this.loader.close();
              this.listVw.refreshData();
              this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.POSITION-DELETED'), 'GENERIC.SNACKBAR.CLOSE');
            });
          }
        });
      } else {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.ERROR.SECONDARY-TICKETS.TITLE'),
          message: this.translate.instant('GENERIC.CONFIRM.ERROR.SECONDARY-TICKETS.MSG'),
          okonly: true
        }).subscribe((confirmed) => { });
      }
    });
  }

  itemDeletable(item){
    if(!item.Deletable){
      return false;
    }
    if(item.LatestTicketSwapStatus != null){
      const deletableStatuses: TicketSwapStatusEnum[] = [TicketSwapStatusEnum.Accepted, TicketSwapStatusEnum.Offered, TicketSwapStatusEnum.Sold];
      return !deletableStatuses.includes(item.LatestTicketSwapStatus);
    }
    return true;
  }

  getTicketSwapStatus(status: TicketSwapStatusEnum) {
    switch (status) {
      case TicketSwapStatusEnum.None:
        return '';
        break;
      case TicketSwapStatusEnum.Offered:
        return 'GENERIC.TICKETSWAPSTATUS.OFFERED';
        break;
      case TicketSwapStatusEnum.Accepted:
        return 'GENERIC.TICKETSWAPSTATUS.ACCEPTED';
        break;
      case TicketSwapStatusEnum.Sold:
        return 'GENERIC.TICKETSWAPSTATUS.SOLD';
        break;
      case TicketSwapStatusEnum.Withdrawn:
        return 'GENERIC.TICKETSWAPSTATUS.WITHDRAWN';
        break;
      case TicketSwapStatusEnum.Revoked:
        return 'GENERIC.TICKETSWAPSTATUS.REVOKED';
        break;
      default:
        return '';
    }
  }
  getTicketSwapStatusClass(status: TicketSwapStatusEnum) {
    switch (status) {
      case TicketSwapStatusEnum.None:
        return [];
        break;
      case TicketSwapStatusEnum.Offered:
        return  ['chipbox', 'chipbox-open'];
        break;
      case TicketSwapStatusEnum.Accepted:
        return ['chipbox', 'chipbox-open'];
        break;
      case TicketSwapStatusEnum.Sold:
        return ['chipbox', 'chipbox-open'];
        break;
      case TicketSwapStatusEnum.Withdrawn:
        return ['chipbox', 'chipbox-closed'];
        break;
      case TicketSwapStatusEnum.Revoked:
        return ['chipbox', 'chipbox-closed'];
        break;
      default:
        return [];
    }
  }
}
