import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ListViewSelectionMode} from '@tymes4-shared';
import {ConfirmService, SnackbarService} from '@tymes4-shared';
import {HttpLoaderService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';
import { TranslateService } from "@ngx-translate/core";
import { NewSalesEntityModificationArgs, PendingOrderService, ReservationService, SalesEntityModificationService } from '../../api';
import { AuthService } from '../../services/auth.service';
import { ApplicationModuleHelperService } from '../../services/application-module.service';


@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit {

  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 7,
    disableSort: true,
    noItemsText: 'RESERVATIONS.LISTVIEW.NOITEMSTEXT',
    selectionMode: ListViewSelectionMode.None,
    headers: [
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.PRODUCT', width: 400, hideOn: null},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.TYPE', width: 130, hideOn: null, isDefaultSort: 'desc'},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.SECTION', width: 200, hideOn: null},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.ROW-SEAT', width: 150, hideOn: null},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.NAMED', width: null, hideOn: null},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.CONTIGENT', width: null, hideOn: null},
      {sortField: null, label: 'RESERVATIONS.LISTVIEW.HEADER.PRICE', width: 100, hideOn: null, class: 'text-right'},
    ]
  };

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<ReservationDetailsComponent>,
              private dialog: MatDialog,
              private apploader: HttpLoaderService,
              private reservationService: ReservationService,
              private logging: LoggingService, private confirmService: ConfirmService,
              private router : Router,
              private snackBar: SnackbarService,
              private loader: HttpLoaderService,
              private pendingOrderService: PendingOrderService,
              private salesEntityModificationService: SalesEntityModificationService,
              private translate: TranslateService,
              private authService: AuthService,
              private applicationModuleHelperService: ApplicationModuleHelperService) { }

  public reservationCanBeEdited = false;
  public reservationId = null;
  public reservationDetails = null;
  public reservationLines = null;
  public reservationLinesWithPrices = null;
  private editingObject = null;
  showPaymentDetails = true;
  public isInArchiveMode = this.authService.isInArchiveMode();
  pagedReservationLines = [];
  lastUsedPage = null;
  public isIbaActive: boolean = false;



  ngOnInit() {

    this.reservationId = this.passedData.Reservation.Id;
    this.reservationDetails = this.passedData.Reservation;
    this.reservationCanBeEdited = (this.reservationDetails.ConvertedToOrderId == null && this.reservationDetails.CanceledByCustomerId == null && this.reservationDetails.IsExpired === false);
   
    this.apploader.open();

    this.reservationService.getReservationDetails(this.reservationId).subscribe((data:any) => {
      this.reservationLines = data.ReservationLines;
      this.reservationLinesWithPrices = data.CustomerReservationLines

      this.listVw.refreshData();
      this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
        if (activeModules !== null) {
          this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
        }
        this.apploader.close();
      })
    });


  }

  orderAllowed() {
    return this.reservationDetails.ConvertedToOrderId === null && this.reservationDetails.IsExpired === false;
  }

  startOrderProcess() {

    this.loader.open();

    this.pendingOrderService.createFromReservation(this.reservationDetails.Id).subscribe((pendingOrderId) => {
      this.loader.close();
      sessionStorage.setItem('pendingOrderId', pendingOrderId.toString());
      this.dialogRef.close(false);
      this.router.navigate(['/sales/orders/sell-tickets']);
    });
  }

  OnDataRequest(e) {

    if (this.reservationLines === null) return;

    let paged = this.reservationLines.slice((e.pageNr - 1) * e.pageSize, (e.pageNr) * e.pageSize);
    let d = {
      Records: paged,
      ResultCountCapped: false,
      TotalRecords: this.reservationLines.length
    };
    this.listVw.Data = d;
  }


  getLinePrice(ol): number {
    ///{{(ol.PriceExVat + ol.VAT) | currency:'EUR'}}
    const found = this.reservationLinesWithPrices.filter((rlwp) => rlwp.ReservationLineId === ol.ReservationLineId);

    if (found !== null && found.length > 0) {
      return  (found[0].PriceExVat + found[0].VAT);
    }

    return 0;
  }


  editSelectedReservation() {

    this.confirmService.confirm({ title: this.translate.instant('RESERVATIONS.DIALOG.TITLE.RESERVATION-EDIT' ), 
    message: this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.MESSAGE.RESERVATION-EDIT' )}).subscribe(confirmed => {

      if (confirmed) {

        this.apploader.open();

        var args : NewSalesEntityModificationArgs = {
          OrderId: null,
          ReservationId: this.reservationId
        }

        this.salesEntityModificationService.addNew(args).subscribe((semId) => {
          this.dialogRef.close(null);
          this.apploader.close();
          this.router.navigate(['/sales/edit-sales']);
        });
      }

    });

  }

  calculateTotal(){
    if( this.reservationLinesWithPrices == null || this.reservationLinesWithPrices.length == 0)
      return 0;

    return this.reservationLinesWithPrices.map( rl => rl.VAT + rl.PriceExVat).reduce( (a, b) => (a + b));
  }

  constructCustomerIdentificationModel(order){
    return {
      CalculatedName : order.TicketCustomerCalculatedName,
      IsIdentified: order.TicketCustomerIsIdentified,
      CustomerNumber: order.TicketCustomerNumber,
    }
  }
}
