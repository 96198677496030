import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {HttpLoaderService} from '@tymes4-shared';
import {SnackbarHelper} from 'app/shared/helpers/snackbar-helper';
import {ConfirmService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { EmailSetting, Language, LanguageService, SystemSettingsService, UploadImageModel } from '../../../../shared/api';

@Component({
  selector: 'app-system-settings-email-settings',
  templateUrl: './system-settings-email-settings.component.html',
  styleUrls: ['./system-settings-email-settings.component.scss']
})
export class SystemSettingsEmailSettingsComponent implements OnInit {
  public readonly backgroundImageKey = 'BackgroundImage';
  public readonly backgroundImageUrlKey = 'BackgroundImageUrl';
  public readonly logoImageKey = 'Logo';
  public readonly logoImageUrlKey = 'LogoUrl';
  private readonly resolutionAdditive = '/180/180';
  public readonly noImageFoundUrl = '/assets/img/no_image.png';
  public readonly languageIdPrefix = 'language:';

  private currentSelectedLanguageId: number = null;
  public emailSettings: EmailSetting[];
  public languages: Array<Language> = [];

  @Input() private customerId: number = null;

  public config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, 'link'],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['clean'],
    ]
  };

  public localValues: any = {
    BackgroundColor: '',
    FontColor: '',
    BackgroundImageUrl: this.noImageFoundUrl,
    LogoUrl: this.noImageFoundUrl,
    Salutation: '',
    Signature: ''
  };

  constructor(
    private loader: HttpLoaderService,
    private systemSettingsService: SystemSettingsService,
    private languageService: LanguageService,
    private snackbarHelper: SnackbarHelper,
    private appConfirmService: ConfirmService,
    private translate: TranslateService
  ) {
  }


  ngOnInit() {
    this.loader.open();
    this.languageService.getLanguages().subscribe((result: Array<Language>) => {
      this.loader.close();
      if (result) {
        this.languages = result.sort((l1, l2) => l1.IsDefault && !l2.IsDefault ? -1 : 0);
        this.languages[0].Code = `Default (${this.languages[0].Code})`;
        this.languages[0].Locale = `Default (${this.languages[0].Locale})`;
        this.currentSelectedLanguageId = this.languages[0].Id;
        this.loadEmailSettings(this.currentSelectedLanguageId);
      }
    });
  }

  loadEmailSettings(languageId: number) {
    this.systemSettingsService.getEmailSettings(languageId, this.customerId).subscribe((emailSettings: Array<EmailSetting>) => {
      if (emailSettings && emailSettings.length > 0) {
        this.updateLocalValues(emailSettings);
      } else if (languageId && this.languages[0].Id !== languageId) {
        this.appConfirmService.okAndCancelConfirm(
          this.translate.instant('GENERIC.CONFIRM.TITLE.LOAD-DEFAULT-VALUES'),
          this.translate.instant('ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.CONFIRM.MESSAGE-LOAD-DEFAULT-LANGUAGE'),
        ).subscribe(confirmed => {
          if (confirmed) {
            this.loadEmailSettings(this.languages[0].Id);
          } else {
            this.updateLocalValues([]);
          }
        });
      }
    });
  }


  uploadImage(e) {

    this.loader.open();
    
    const file: File = e.target.files[0];
    if(!file)
      return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const mimeType = (reader.result as string).split(',')[0];
      const base64string = (reader.result as string).split(',')[1];
      const uploadImageModel : UploadImageModel = {
        Description: file.name.split('.')[0],
        FileData: base64string,
        FileName: file.name,
        MimeType: mimeType,
        FileTypeName: null
      };
      const key = e.target.attributes.key.value;
      this.systemSettingsService.uploadEmailSettingsBackgroundImage(key, this.currentSelectedLanguageId, this.customerId, uploadImageModel)
        .subscribe(data => {
          if (data !== null) {
            
            switch (key) {
              case this.backgroundImageKey:
                this.localValues.BackgroundImageUrl = data;
                break;
              case this.logoImageKey:
                this.localValues.LogoUrl = data;
                break;
            }

            this.loader.close();
            this.snackbarHelper.saved();
          }
        });
    };
  }

  updateLocalValues(emailSettings: EmailSetting[]) {
    this.emailSettings = emailSettings;
    this.localValues = {
      BackgroundColor: '',
      FontColor: '',
      BackgroundImageUrl: this.noImageFoundUrl,
      LogoUrl: this.noImageFoundUrl,
      Salutation: '',
      Signature: ''
    };
    for (const localValue in this.localValues) {
      if (this.localValues.hasOwnProperty(localValue)) {
        const emailSetting = emailSettings.find(x => x.Key === localValue);
        if (emailSetting) {
          this.localValues[localValue] = emailSetting.Value;
        }
      }
    }
  }

  submit() {
    this.loader.open();
    const updatedEmailSettings: EmailSetting[] = [];
    for (const localValue in this.localValues) {
      if (this.localValues.hasOwnProperty(localValue)) {
        if(localValue === this.backgroundImageKey || localValue === this.logoImageKey || localValue === this.backgroundImageUrlKey || localValue === this.logoImageUrlKey)
          continue;

        let emailSetting = null;
        if (this.emailSettings) {
          emailSetting = this.emailSettings.find(x => x.Key === localValue);
        }
        if (!emailSetting) {
          emailSetting = {
            Id: 0,
            Created: undefined,
            Modified: undefined,
            Deleted: undefined,
            Key: localValue,
            Value: null,
            CustomerId: this.customerId,
            LanguageId: this.currentSelectedLanguageId
          };
        }
        let tempValue = this.localValues[localValue];
        if (emailSetting.Value !== tempValue && tempValue !== this.noImageFoundUrl ||
          emailSetting.LanguageId === null && this.currentSelectedLanguageId !== null) {
          emailSetting.Value = tempValue;

          if (emailSetting.LanguageId !== this.currentSelectedLanguageId) {
            emailSetting.LanguageId = this.currentSelectedLanguageId;
            emailSetting.Id = 0;
          }
          updatedEmailSettings.push(emailSetting);
        }
      }
    }
    if (!updatedEmailSettings || updatedEmailSettings.length === 0) {
      this.loader.close();
      return;
    }
    this.systemSettingsService.postEmailSettings(updatedEmailSettings).subscribe(resultSettings => {
        this.updateLocalValues(resultSettings);
        this.snackbarHelper.saved();
      },
      () => {
        this.loader.close();
      },
      () => {
        this.loader.close();
      });
  }

  onSelectedTabChange(e: any) {
    const languageId = e.selectedTab.id.replace(this.languageIdPrefix, '');
    this.currentSelectedLanguageId = Number(languageId);
    this.loadEmailSettings(languageId);
  }

  removeImage(key: string) {
    this.loader.open();
    let imageUrl = this.noImageFoundUrl;
    if(key === 'BackgroundImage')
      imageUrl = this.localValues.BackgroundImageUrl;
    else if(key === 'Logo')
      imageUrl = this.localValues.LogoUrl;
    if (!imageUrl || imageUrl == this.noImageFoundUrl) {
      this.loader.close();
      return
    }
    this.systemSettingsService.deleteEmailSetting(key, this.currentSelectedLanguageId, this.customerId)
      .subscribe(() => {
        if(key === 'BackgroundImage')
          this.localValues.BackgroundImageUrl = this.noImageFoundUrl;
        else if(key === 'Logo')
          this.localValues.LogoUrl = this.noImageFoundUrl;
        this.loader.close();
      });
  }
}
