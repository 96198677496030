import { Component, OnInit, Input } from '@angular/core';

import { Tab } from '@tymes4-shared';
import { VerticalTab } from 'app/shared/models/verticaltab';
import { VerticalTabsComponent } from '../vertical-tabs/vertical-tabs.component';

@Component({
  selector: 'vertical-tab',
  templateUrl: './vertical-tab.component.html',
  styleUrls: ['./vertical-tab.component.scss']
})
export class VerticalTabComponent implements OnInit, VerticalTab {

  @Input() public tabTitle;
  @Input() public id: string;
  @Input() public pos: string;
  @Input() public faIcon: string;
  @Input() public customCss?: string;
  @Input() public hidden: boolean;
  @Input("selected")
  set selectedInit(p) {
    this.selected = p;
    this.isPreSelected = true;
  }
  public isPreSelected: boolean;
  public selected: boolean;

  constructor(private tabsComponent: VerticalTabsComponent) {}

  ngOnInit() {
    if(this.hidden !== true) {
      this.tabsComponent.addTab(this, this.pos);
    }
  }

}
