import { TranslateService } from '@ngx-translate/core';
import { ConfirmService, FileService } from '@tymes4-shared';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {CrossSellProductCategoryService, CrossSellProductService, CrossSellProductVariantService} from "../../../api";

@Component({
    selector: 'app-sales-crosssellproductvariant-selection',
    templateUrl: './sales-crosssellproductvariant-selection.component.html'
})
export class SalesCrossSellProductVariantComponent implements OnInit {

    public form: FormGroup;
    crossSellProductCategories: any;

    constructor(private crossSellProductService: CrossSellProductService,
        private crossSellProductCategoryService: CrossSellProductCategoryService,
        // private crossSellProductVariantServiceOld: CrossSellProductVariantService,
        private crossSellProductVariantService: CrossSellProductVariantService,
        private confirmService: ConfirmService,
        public fileHelper: FileService,
        private translate: TranslateService) {
    }

    @ViewChild('productPagerComponent') productPagerComponent;
    @ViewChild('variantPagerComponent') variantPagerComponent;
    @Output() onCrossSellProductVariantChange = new EventEmitter<object>();
    @Input() hasOther: boolean;

    pageSize = 6;
    searchText = '';
    categoryId = '';
    listProducts: any = [];
    totalProductsCount: number = 0;
    selectedProduct = null;
    productPageNr = 1;

    listVariants: any = [];
    totalVariantsCount: number = 0;
    selectedVariant = null;
    variantPageNr = 1;

    ngOnInit() {
        this.form = new FormGroup({
            CategoryId: new FormControl(null),
            SearchText: new FormControl(''),
            ProductId: new FormControl(null, [Validators.required]),
            ProductVariantId: new FormControl(null, [Validators.required])
        })

        this.crossSellProductCategoryService.crossSellProductCategoryGetAll().subscribe((data) => {
            this.crossSellProductCategories = data;
        })

        this.updateFilter(null);
    }

    updateFilter(e) {
        this.searchText = this.form.controls['SearchText'].value;
        this.categoryId = this.form.controls['CategoryId'].value;
        this.fetchProductData(1);
      }

    doProductPage(pageArgs) {
        this.fetchProductData(pageArgs.pageNr);
    }

    fetchProductData(pageNr){
        this.productPageNr = pageNr;

        let additionalFilters : { [key: string]: string; } = {};
        if (this.categoryId != null)
        {
            additionalFilters['categoryId'] = this.categoryId.toString();
        }
        
        this.crossSellProductService.crossSellProductSearch(this.searchText, pageNr, this.pageSize, 'Name', true, additionalFilters).subscribe((resp: any) => {

            this.totalProductsCount = resp.TotalRecords;
            this.listProducts = resp.Records;
            this.productPagerComponent.setObjectCount(this.totalProductsCount);
            this.productPagerComponent.setPage(pageNr);
        });


    }

    isProductSelected(e) {
        return (this.selectedProduct && this.selectedProduct !== null && (this.selectedProduct.Id == e.Id));
      }

    toggleProductSelection(crossSellProduct) {
        
        if (crossSellProduct == null) return;

        if (this.productSelectionIsAllowed(crossSellProduct)) {

            if (this.selectedProduct != null && this.selectedProduct.Id == crossSellProduct.Id) {
                this.clearProductSelection();
            }
            else {
                this.clearProductSelection();
                this.selectedProduct = crossSellProduct;

            }

            this.fetchProductData(this.productPageNr);
            this.fetchVariantData(1);
        }
    }

    productSelectionIsAllowed(pp) {

        if (this.hasOther) {

          this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
            message: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

            return false;
        }

        return true; //a-ok
      }

    clearProductSelection() {
        this.selectedProduct = null;
    }

    doVariantPage(pageArgs) {
        this.fetchVariantData(pageArgs.pageNr);
    }

    fetchVariantData(pageNr){
        this.variantPageNr = pageNr;

        // reset list during fetch
        this.listVariants = [];
        this.variantPagerComponent.setObjectCount(0);
        this.variantPagerComponent.setPage(1);

        if (this.selectedProduct != null)
        {
            this.crossSellProductVariantService.getByProduct(this.selectedProduct.Id).subscribe((resp: any) => {
                this.listVariants = resp;
                this.totalVariantsCount = this.listVariants.length;
            });
        }

        this.variantPagerComponent.setObjectCount(this.totalVariantsCount);
        this.variantPagerComponent.setPage(pageNr);
    }

    isVariantSelected(e) {
        return (this.selectedVariant && this.selectedVariant !== null && (this.selectedVariant.Id == e.Id));
    }

    toggleVariantSelection(crossSellProductVariant) {

        console.log('crossSellProductVariant', crossSellProductVariant);

        if (crossSellProductVariant == null || crossSellProductVariant.AvailableCount == 0) return;

        if (this.variantSelectionIsAllowed(crossSellProductVariant)) {

            if (this.selectedVariant != null && this.selectedVariant.Id == crossSellProductVariant.Id) {
                this.clearVariantSelection();
            }
            else {
                this.clearVariantSelection();
                this.selectedVariant = crossSellProductVariant;
            }

            this.fetchProductData(this.productPageNr);

        }
    }

    variantSelectionIsAllowed(pp) {

        if (this.hasOther) {

          this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
            message: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

            return false;
        }

        return true; //a-ok
      }

    clearVariantSelection() {
        this.selectedVariant = null;
    }

    canSubmit() {
        return this.selectedProduct != null && this.selectedVariant != null;
    }

    doAction() {
        if (this.hasOther) {
          this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
            message: this.translate.instant('SALES.ORDERS.NEW.SALES-CROSSSELLPRODUCTVARIANT-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

            return false;
        }

        this.onCrossSellProductVariantChange.emit(this.selectedVariant);
      }
}
