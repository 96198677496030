<div class="row modal-bar">
  <div class="col-sm-6">
    <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(false);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>


<t4-tabs (selected)="onTabChange($event)">
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.DETAILS" id="details"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.SALE" id="sales"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.PAYMENT-METHOD" id="paymentmethods"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.PRICELIST" id="pricelists"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.EVENTS" id="events"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.SEND-METHOD" id="shipping"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.ACCOUNTANCY" id="accounting"></t4-tab>
  <t4-tab tabTitle="MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.EXCHANGE" id="exchange" *ngIf="showMarketplaceSection"></t4-tab>
</t4-tabs>

<form [formGroup]="form" class="form-content">

  <div class="section-wrapper">

    <div class="form-layout pd-5" matchHeight='card-body-height'>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'details'">


        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.DETAILS.FORM.LABEL.PP-NAME' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="name" formControlName="Name" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.PP-NAME' | translate}}" maxlength="64">
              <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.DETAILS.FORM.LABEL.CODE' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" trim name="Code" formControlName="Code" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.CODE' | translate}}" maxlength="64">
              <t4-display-validation controlName="Code" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.SEASON' | translate}} <span class="tx-danger">*</span></label>
              <ng-select [items]="selectableSeasons" bindLabel="Name" bindValue="Id" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.SEASON' | translate}}" formControlName="SeasonId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="SeasonId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.PP-TYPE' | translate}} <span class="tx-danger">*</span></label>
              <ng-select [items]="selectablePassePartoutTypes" bindLabel="Name" bindValue="Id" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.PP-TYPE' | translate}}" formControlName="PassePartoutTypeId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="PassePartoutTypeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.HARDCARD-TEMPLATE' | translate}} <span class="tx-danger">*</span></label>
              <ng-select [items]="selectableHardcardTemplates" bindLabel="Name" bindValue="Id" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.HARDCARD-TEMPLATE' | translate}}" formControlName="HardcardTemplateId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
            <div class="form-group" *ngIf="showMobileTicketSection">
              <label class="form-control-label">Mobiele template voorkant: <span class="tx-danger">*</span></label>
              <ng-select [items]="eTicketTemplates" bindLabel="Name" bindValue="Id" placeholder="Mobele template voorkant" formControlName="MobileTicketFrontTemplateId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.ADDITIONAL-INFORMATION' | translate}} </label>
              <input class="form-control" trim name="ExtraInfo" formControlName="ExtraInfo" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.PLACEHOLDER.ADDITIONAL-INFORMATION' | translate}}" maxlength="64">
              <t4-display-validation controlName="ExtraInfo" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
            <div class="form-group" *ngIf="showMobileTicketSection">
              <label class="form-control-label">Mobiele template achterkant: <span class="tx-danger">*</span></label>
              <ng-select [items]="eTicketTemplates" bindLabel="Name" bindValue="Id" placeholder="Mobele template achterkant" formControlName="MobileTicketBackTemplateId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>


        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.ADDITIONAL-ATTRIBUTE' | translate}}</label>
              <input class="form-control" name="AdditionalAttribute" formControlName="AdditionalAttribute" placeholder="{{'' | translate}}" maxlength="512">
              <span class="mb-3 d-block" style="color: #8a909d; font-size: 14px;">
                {{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.DESCRIPTION.ADDITIONAL-ATTRIBUTE' | translate}}
              </span>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group" style="padding-top: 40px; padding-bottom: 5px;">
              <input type="checkbox" name="Active" formControlName="Active"><span> {{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.ACTIVE' | translate}}</span>
            </div>
          </div>
          <div class="col-lg-3" *ngIf="showMobileTicketSection === true">
            <div class="form-group" style="padding-top: 40px; padding-bottom: 5px;">
              <input type="checkbox" name="ShowMobileTicketInTicketshop" formControlName="ShowMobileTicketInTicketshop"><span> {{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.MOBILE-IN-TICKETSHOP' | translate}}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="card-body-height" [class.d-none]="currentTabId !== 'sales'">

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="HasSoldOut" formControlName="HasSoldOut">
              <label class="form-check-label" for="HasSoldOut">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.SOLD-OUT' | translate}}</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="IdentificationRequired" formControlName="IdentificationRequired">
              <label class="form-check-label" for="IdentificationRequired">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.ID-REQUIRD' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="PhotoRequired" formControlName="PhotoRequired">
              <label class="form-check-label" for="PhotoRequired">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.IMAGE-VERSION' | translate}}</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="ApplyIndexing" formControlName="ApplyIndexing">
              <label class="form-check-label" for="ApplyIndexing">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.HARDCARD-NUMBER' | translate}}</label>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="Named" formControlName="Named" (change)="onNamedChange($event)">
              <label class="form-check-label" for="Named">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.CHECK.MUST-BE-SET-TO-NAME' | translate}}</label>
              <t4-display-validation controlName="Named" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group" *ngIf="form.controls.Named.value">
              <input type="checkbox" id="IsUniquelyAssigned" formControlName="IsUniquelyAssigned">
              <label class="form-check-label" for="IsUniquelyAssigned">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.UNIQUE-CLIENT' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.LABEL.FOLLOW-UP-FOR' | translate}} <span class="tx-danger">*</span></label>
              <ng-select [items]="selectablePrecedingPassePartouts" bindLabel="Name" bindValue="Id" placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.SALES.PLACEHOLDER.FOLLOW-UP-FOR' | translate}}" formControlName="PrecedingPassePartoutId"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="PrecedingPassePartoutId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group" style="padding-top: 40px; padding-bottom: 5px;" >
              <input type="checkbox" name="KeepPrecedingBarcode" formControlName="KeepPrecedingBarcode"><span>  {{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.DETAILS.LABEL.KEEPPRECEDINGBARCODE' | translate}}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'events'">

        <t4-page-warning warning="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.WARNING.PP-EVENT' | translate}}"></t4-page-warning>


        <div class="table-responsive">

          <table class="rounded table text-dark table-striped">

            <thead>
              <th>{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.LABEL.ID' | translate}}</th>
              <th>{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.LABEL.START-DATE' | translate}}</th>
              <th>{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.LABEL.START-TIME' | translate}}</th>
              <th>{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.LABEL.TYPE' | translate}}</th>
              <th>{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EVENTS.LABEL.NAME' | translate}}</th>
            </thead>

            <tbody>
              <tr *ngFor="let event of pagedPptEvents">
                <td>{{event.Id}}</td>
                <td>{{event.StartDateTime | localDateTime | slice:0:10 }}</td>
                <td>{{event.StartDateTime | localTime }}</td>
                <td>{{event.EventTypeName}}</td>
                <td>{{event.Name}}</td>
              </tr>
            </tbody>

          </table>

          <t4-pager [pageSize]="7" (onPage)="doPageEvents($event)" #pagerComponent></t4-pager>

        </div>

      </div>


      <div class="card-body-height" [class.d-none]="currentTabId !== 'paymentmethods'">

        <t4-list-view #paymentListVw [options]="paymentMethodsListOptions" (onDataRequest)="onDataRequest($event)">

          <ng-container *t4ListItem="let item">
            <td><input type="checkbox" [checked]="item.HasPaymentMethod" (change)="updatePaymentmethod(item, $event.currentTarget.checked)" /></td>
            <td>{{item.Name}}</td>
          </ng-container>

        </t4-list-view>


      </div>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'pricelists'">
        <app-link-pricelist [passePartoutId]="passePartoutId" #priceListComponent></app-link-pricelist>
      </div>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'shipping'">

        <app-delivery-method-grid columnIdField="DeliveryMethodId" columnNameField="DeliveryMethodName"
                                  rowIdField="VenueBuildingBlockId" rowNameField="VenueBuildingBlockName" parentRowIdField="ParentVenueBuildingBlockId"
                                  [data]="deliveryMethodData" #deliveryMethodComponent></app-delivery-method-grid>

      </div>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'accounting'">
        <div class="row">
          <div class="col-lg-6">
            <div class="row p-2" *ngIf="showDim1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE1' | translate}}</label>
                  <input class="form-control" name="AccountingDimensionCode1" formControlName="AccountingDimensionCode1"
                         placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE1' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim2">
              <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE2' | translate}}</label>
              <input class="form-control" name="AccountingDimensionCode2" formControlName="AccountingDimensionCode2"
                     placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE2' | translate}}" maxlength="100">
            </div>
          </div>
          </div>
          <div class="row p-2" *ngIf="showDim3">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE3' | translate}}</label>
                  <input class="form-control" name="AccountingDimensionCode3" formControlName="AccountingDimensionCode3"
                        placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE3' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim4">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE4' | translate}}</label>
                  <input class="form-control" name="AccountingDimensionCode4" formControlName="AccountingDimensionCode4"
                        placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE4' | translate}}" maxlength="100">
                </div>
              </div>
          </div>
          <div class="row p-2" *ngIf="showDim5">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-control-label">{{'ACCOUNTING-DIMENSION.FORM.LABEL.CODE5' | translate}}</label>
                  <input class="form-control" name="AccountingDimensionCode5" formControlName="AccountingDimensionCode5"
                        placeholder="{{'ACCOUNTING-DIMENSION.FORM.PLACEHOLDER.CODE5' | translate}}" maxlength="100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body-height" [class.d-none]="currentTabId !== 'exchange'">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="LimitResell" formControlName="LimitResell" (change)="onLimitResellChange($event)">
              <label class="form-check-label" for="LimitResell">{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EZCHANGE.LABEL.LIMIT-RESELL' | translate}}</label>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label">{{ 'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EXCHANGE.LABEL.MAXRESELL' | translate}}</label>
                <input
                  class="form-control text-right"
                  #amountInput
                  type="number"
                  min="0"
                  name="MaxResell"
                  formControlName="MaxResell"
                  placeholder="{{'MANAGEMENT.MULTI-EVENT.EDITDIALOG.FORM.EXCHANGE.PLACEHOLDER.MAXRESELL' | translate}}">
              </div>
            </div>
        </div>
      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
               class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
               class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div>
  </div>
</form>









<form [formGroup]="form" class="form-content">
  <!--
  <div fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex>
      <div class="header">{{ passedData.title }}</div>
    </div>

    <div fxFlex="420px" class="text-right">

      <button class="btn btn-default" (click)="dialogRef.close(false);"
              title="Wijzigingen annuleren en het venster sluiten.">
        <i class="fas fa-ban"></i>
        <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
      </button>

      <button class="btn btn-accent" (click)="submit()" [disabled]="form.invalid || saving"
              title="De passe-partout opslaan.">
        <i class="far fa-save"></i>
        <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
      </button>

    </div>
  </div> -->


</form>
