/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrintTrailTypeEnum = 1 | 2 | 3 | 4;

export const PrintTrailTypeEnum = {
    NUMBER_1: 1 as PrintTrailTypeEnum,
    NUMBER_2: 2 as PrintTrailTypeEnum,
    NUMBER_3: 3 as PrintTrailTypeEnum,
    NUMBER_4: 4 as PrintTrailTypeEnum
};

