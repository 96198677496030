import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { PendingOrderLineService, UpdateTicketCustomerArgs } from '../../api';


@Component({
  selector: 'app-select-ticket-customer',
  templateUrl: './select-ticket-customer.component.html',
  styleUrls: ['./select-ticket-customer.component.scss']
})
export class SelectTicketCustomerComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<SelectTicketCustomerComponent>,
              private pendingOrderLineService: PendingOrderLineService,
              private logging: LoggingService,
              private loader: HttpLoaderService) { }


  selectedCustomer = null;
  selectedOrderLines = [];

  ngOnInit() {
    this.selectedOrderLines = this.passedData.payload;


  }

  submit() {

    this.loader.open();

    let orderLineIds = this.selectedOrderLines.map(ol => ol.Id);
    let newCustomerId:number = this.selectedCustomer.Id;

    var args : UpdateTicketCustomerArgs = {
      OrderLineIds: orderLineIds,
      NewCustomerId: newCustomerId
    }

    this.pendingOrderLineService.updateTicketCustomer(args).subscribe((data:any)=>{
      this.loader.close();
      this.dialogRef.close(true);
    });
  }

  onCustomerSelect(customers) {
    this.selectedCustomer = customers[0];

    this.submit();
  }
}
