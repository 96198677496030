import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import PerfectScrollbar from 'perfect-scrollbar';
import { ContingentService } from '../../api';

@Component({
  selector: 'app-change-unplaced-contingents',
  templateUrl: './change-unplaced-contingents.component.html',
  styleUrls: ['./change-unplaced-contingents.component.scss']
})
export class ChangeUnplacedContingentsComponent implements OnInit {
  public saving = false;
  public contingents: any[];
  private modifiedList: any[] = [];
  public total: number = 0;
  public noRemaining: number = 0;
  private eventPlacementEventId: number = null;
  public parentVBBHasPlacements: boolean = true;
  private scrollWindow: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private contingentService: ContingentService,
    private loader: HttpLoaderService,
    public dialogRef: MatDialogRef<ChangeUnplacedContingentsComponent>
  ) { }

  ngOnInit() {

    this.scrollWindow = new PerfectScrollbar('#contingent-scrollwindow', {
      suppressScrollX: true
    });

    this.total = this.passedData.totalPlaces;
    this.eventPlacementEventId = this.passedData.eventPlacementEventId;
    this.parentVBBHasPlacements = this.passedData.parentVBBHasPlacements;

    this.loader.open();

    if (this.eventPlacementEventId) {
      this.contingentService.listForSectionEp(this.passedData.sectionId).subscribe((data:any) => {
        this.contingents = data;
        this.calculateRemaining();
        this.loader.close();
      });
    }
    else {
      this.contingentService.listForSectionVBB(this.passedData.sectionId).subscribe((data:any) => {
        this.contingents = data;
        this.calculateRemaining();
        this.loader.close();
      });
    }
  }

  calculateRemaining() {
    let remaining = this.total;

    if (this.contingents !== undefined) {
      for(let i = 0; i < this.contingents.length; i++) {
        remaining -= Number(this.contingents[i].Number);
      }
    }

    this.noRemaining = remaining;

    if (this.contingents !== undefined) {
      for(let i = 0; i < this.contingents.length; i++) {
        this.contingents[i].Max = Number(this.contingents[i].Number) + remaining;
      }
    }
  }

  changeValue(sectionId: number, e) {
    if (Number(e.target.max) < Number(e.target.value)) {
      e.target.value = e.target.max;
    }

    if (Number(e.target.value) < 0) {
      e.target.value = "0";
    }

    let changed = this.contingents.filter(c => c.Id === sectionId);
    changed[0].Number = e.target.value;
    this.calculateRemaining();
    // add to modifications array
    this.modifiedList.push({ Id: changed[0].Id, Name: changed[0].Name, Number: changed[0].Number });
  }

  submit() {
    this.saving = true;

    if (this.eventPlacementEventId) {
      this.contingentService.updateSectionEp(
        {  
          Contingents: this.modifiedList, 
          SectionId: this.passedData.sectionId
        }).subscribe((data: any) => {
        this.dialogRef.close(true);
      });
    }
    else {
      this.contingentService.updateSectionVBB(
        {
          SectionId: this.passedData.sectionId, 
          Contingents: this.modifiedList
        }).subscribe((data: any) => {
        this.dialogRef.close(true);
      });
    }
  }
}
