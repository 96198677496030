import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { OptInQuestionModel, OptInQuestionService } from "../api";

@Injectable()
export class OptInValidator {

  constructor(private optInQuestionService: OptInQuestionService) {}

  private debouncer: any;
  public editingObject : any;

  codeIsTaken = (control: FormControl) => {
    //do not hammer the service
    clearTimeout(this.debouncer);

    return new Promise(resolve => {
      this.debouncer = setTimeout(() => {

        if(control.value === '') {
          resolve(null);
        }
        else {
          this.optInQuestionService.getOptInQuestionByCode(control.value.trim()).subscribe((data: OptInQuestionModel) => {

            if (data !== null && this.editingObject.Id != data.Id) {
              resolve({"codeTaken": data});
            }
            else {
              resolve(null);
            }
          });
        }

      }, 1000);
    });
  }
}
