import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { AccountingDimension, AccountingDimensionService, AccountingDimensionTypeEnum } from '../../api';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { AccountingDimensionHelperService } from '../../services/accounting-dimension.service';
import { AuthService } from '../../services/auth.service';



@Component({
  selector: 'app-accounting-dimensions',
  templateUrl: 'accounting-dimensions.component.html'
})

export class AccountingDimensionsComponent implements OnInit {
  
    public _entityId: number;
    public _dimensionType: AccountingDimensionTypeEnum;

    public form: FormGroup;
    public saving: boolean = false;
    // public validationMessages = {};
    public accountingDimension: any = null;

    public showDim1: boolean = false;
    public showDim2: boolean = false;
    public showDim3: boolean = false;
    public showDim4: boolean = false;
    public showDim5: boolean = false;
    public isInArchiveMode = this.authService.isInArchiveMode();

    @Input()
    set entityId(id: number) {
        this._entityId = id;
        console.log('this._entityId', this._entityId);
    // this.refresh();
    }
    @Input()
    set dimensionType(type: AccountingDimensionTypeEnum) {
        this._dimensionType = type;
        console.log('this._dimensionType', this._dimensionType);
        //this.refresh();
    }
  
    constructor(public accountingDimensionService: AccountingDimensionService,
        private dimensionHelper: AccountingDimensionHelperService,
        private snackbarHelper: SnackbarHelper,
        private loader: HttpLoaderService,
        private authService: AuthService) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            Code1: new FormControl(''),
            Code2: new FormControl(''),
            Code3: new FormControl(''),
            Code4: new FormControl(''),
            Code5: new FormControl(''),
            
            EntityId: new FormControl(''),
            AccountingDimensionType: new FormControl('')
          });

        if(this.isInArchiveMode)
          this.form.disable();

        this.loader.open();
        this.accountingDimensionService.getByDimensionTypeAndEntityId(this._dimensionType, this._entityId).subscribe((result) => {
            this.dimensionHelper.getActiveAccountingDimensions().subscribe((activeDimensions) => {
                this.showDim1 = (activeDimensions && activeDimensions.includes('accountingdimension1'));
                this.showDim2 = (activeDimensions && activeDimensions.includes('accountingdimension2'));
                this.showDim3 = (activeDimensions && activeDimensions.includes('accountingdimension3'));
                this.showDim4 = (activeDimensions && activeDimensions.includes('accountingdimension4'));
                this.showDim5 = (activeDimensions && activeDimensions.includes('accountingdimension5'));
                
                this.accountingDimension = result;

                console.log('this.accountingDimension', this.accountingDimension);
                this.form.patchValue(this.accountingDimension);

                this.loader.close();
            })
        });
    }


    submit()
    {
        const formData = this.form.value;
        
        this.saving = true;
        this.loader.open();
        
        var args : AccountingDimension = {
            EntityId : this._entityId,
            AccountingDimensionType : this._dimensionType,
            Code1 : formData.Code1,
            Code2 : formData.Code2,
            Code3 : formData.Code3,
            Code4 : formData.Code4,
            Code5 : formData.Code5,
        }

        this.accountingDimensionService.createOrUpdateAccountingDimension(args).subscribe(() => {
            this.saving = false;
            this.loader.close();
            this.snackbarHelper.saved();
            
        });

    }

}
