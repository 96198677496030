export enum ActivityTypeEnum {
  Logon = 1,
  Logoff = 2,
  Customer = 3,
  Order = 4,
  Reservation = 5,
  Event = 6,
  PassePartout = 7,
  SalesPeriod = 8,
  Pricelist = 9,
  User = 10,
  InvoiceBatch = 11,
  PrintBatch = 12,
  EventPlacement = 13,
  EventType = 14,
  PriceComponent = 15,
  Initiative = 16,
  Blacklist = 17,
  BlacklistType = 18,
  BlacklistReason = 19,
  DeliveryMethod = 20,
  Membership = 21,
  Discount = 22,
  DiscountCode = 23,
  SalesChannel = 24,
  SalesGroup = 25,
  MailTemplate = 26,
  Reseller = 27,
  DatabaseScaleUp = 28,
  CrossSellProduct = 29,
  CrossSellProductVariant = 30,
  EventCrossSellProduct = 31,
  MarketplacePolicy = 32,
  MatchDayPass = 33,
  MatchDayPassEvent = 34,
  Sector = 35,
  MatchDayPassPrice = 36,
  PricingPolicy = 37,
  MembershipRenewalBatch = 38,
  MembershipType = 39,
  AssignmentPolicy = 40,
  CustomTheme = 41,
}

export let ActivityTypeDictionary;
ActivityTypeDictionary = {
  [ActivityTypeEnum.Logon]: {
    name: '',
    article: '',
  },
  [ActivityTypeEnum.Logoff]: {
    name: '',
    article: '',
  },
  [ActivityTypeEnum.Customer]: {
    name: 'ACTIVITY.LOG.NAME.CUSTOMER',
    article: 'ACTIVITY.LOG.ARTICLE.CUSTOMER',
  },
  [ActivityTypeEnum.Order]: {
    name: 'ACTIVITY.LOG.NAME.ORDER',
    article: 'ACTIVITY.LOG.ARTICLE.ORDER',
  },
  [ActivityTypeEnum.Reservation]: {
    name: 'ACTIVITY.LOG.NAME.RESERVATION',
    article: 'ACTIVITY.LOG.ARTICLE.RESERVATION',
  },
  [ActivityTypeEnum.Event]: {
    name: 'ACTIVITY.LOG.NAME.EVENT',
    article: 'ACTIVITY.LOG.ARTICLE.EVENT',
  },
  [ActivityTypeEnum.PassePartout]: {
    name: 'ACTIVITY.LOG.NAME.PASSE-PARTOUT',
    article: 'ACTIVITY.LOG.ARTICLE.PASSE-PARTOUT',
  },
  [ActivityTypeEnum.SalesPeriod]: {
    name: 'ACTIVITY.LOG.NAME.SALES-PERIOD',
    article: 'ACTIVITY.LOG.ARTICLE.SALES-PERIOD',
  },
  [ActivityTypeEnum.Pricelist]: {
    name: 'ACTIVITY.LOG.NAME.PRICELIST',
    article: 'ACTIVITY.LOG.ARTICLE.PRICELIST',
  },
  [ActivityTypeEnum.User]: {
    name: 'ACTIVITY.LOG.NAME.USER',
    article: 'ACTIVITY.LOG.ARTICLE.USER',
  },
  [ActivityTypeEnum.InvoiceBatch]: {
    name: '',
    article: '',
  },
  [ActivityTypeEnum.PrintBatch]: {
    name: '',
    article: '',
  },
  [ActivityTypeEnum.EventPlacement]: {
    name: 'ACTIVITY.LOG.NAME.EVENT-PLACEMENT',
    article: 'ACTIVITY.LOG.ARTICLE.EVENT-PLACEMENT',
  },
  [ActivityTypeEnum.EventType]: {
    name: 'ACTIVITY.LOG.NAME.EVENT-TYPE',
    article: 'ACTIVITY.LOG.ARTICLE.EVENT-TYPE',
  },
  [ActivityTypeEnum.Blacklist]: {
    name: 'ACTIVITY.LOG.NAME.BLACKLIST',
    article: 'ACTIVITY.LOG.ARTICLE.BLACKLIST'
  },
  [ActivityTypeEnum.BlacklistType]: {
    name: 'ACTIVITY.LOG.NAME.BLACKLIST-TYPE',
    article: 'ACTIVITY.LOG.ARTICLE.BLACKLIST-TYPE'
  },
  [ActivityTypeEnum.BlacklistReason]: {
    name: 'ACTIVITY.LOG.NAME.BLACKLIST-REASON',
    article: 'ACTIVITY.LOG.ARTICLE.BLACKLIST-REASON'
  },
  [ActivityTypeEnum.DeliveryMethod]: {
    name: 'ACTIVITY.LOG.NAME.DELIVERY-METHOD',
    article: 'ACTIVITY.LOG.ARTICLE.DELIVERY-METHOD'
  },
  [ActivityTypeEnum.Membership]: {
    name: 'ACTIVITY.LOG.NAME.MEMBERSHIP',
    article: 'ACTIVITY.LOG.ARTICLE.MEMBERSHIP'
  },
  [ActivityTypeEnum.Discount]: {
    name: 'ACTIVITY.LOG.NAME.DISCOUNT',
    article: 'ACTIVITY.LOG.ARTICLE.DISCOUNT'
  },
  [ActivityTypeEnum.DiscountCode]: {
    name: 'ACTIVITY.LOG.NAME.DISCOUNT-CODE',
    article: 'ACTIVITY.LOG.ARTICLE.DISCOUNT-CODE'
  },
  [ActivityTypeEnum.SalesChannel]: {
    name: 'ACTIVITY.LOG.NAME.SALES-CHANNEL',
    article: 'ACTIVITY.LOG.ARTICLE.SALES-CHANNEL'
  },
  [ActivityTypeEnum.SalesGroup]: {
    name: 'ACTIVITY.LOG.NAME.SALES-GROUP',
    article: 'ACTIVITY.LOG.ARTICLE.SALES-GROUP'
  },
  [ActivityTypeEnum.MailTemplate]: {
    name: 'ACTIVITY.LOG.NAME.MAIL-TEMPLATE',
    article: 'ACTIVITY.LOG.ARTICLE.MAIL-TEMPLATE'
  },
  [ActivityTypeEnum.Reseller]: {
    name: 'ACTIVITY.LOG.NAME.RESELLER',
    article: 'ACTIVITY.LOG.ARTICLE.RESELLER'
  },
  [ActivityTypeEnum.DatabaseScaleUp]: {
    name: 'ACTIVITY.LOG.NAME.DATABASE-SCALEUP',
    article: 'ACTIVITY.LOG.ARTICLE.DATABASE-SCALEUP'
  },
  [ActivityTypeEnum.CrossSellProduct]: {
    name: 'ACTIVITY.LOG.NAME.CROSS-SELL-PRODUCT',
    article: 'ACTIVITY.LOG.ARTICLE.CROSS-SELL-PRODUCT'
  },
  [ActivityTypeEnum.CrossSellProductVariant]: {
    name: 'ACTIVITY.LOG.NAME.CROSS-SELL-PRODUCT-VARIANT',
    article: 'ACTIVITY.LOG.ARTICLE.CROSS-SELL-PRODUCT-VARIANT'
  },
  [ActivityTypeEnum.MarketplacePolicy]: {
    name: 'ACTIVITY.LOG.NAME.MARKETPLACE-POLICY',
    article: 'ACTIVITY.LOG.ARTICLE.MARKETPLACE-POLICY'
  },
  [ActivityTypeEnum.MatchDayPass]: {
    name: 'ACTIVITY.LOG.NAME.MATCHDAYPASS',
    article: 'ACTIVITY.LOG.ARTICLE.MATCHDAYPASS'
  },
  [ActivityTypeEnum.MatchDayPassEvent]: {
    name: 'ACTIVITY.LOG.NAME.MATCHDAYPASS-EVENT',
    article: 'ACTIVITY.LOG.ARTICLE.MATCHDAYPASS-EVENT'
  },
  [ActivityTypeEnum.Sector]: {
    name: 'ACTIVITY.LOG.NAME.SECTOR',
    article: 'ACTIVITY.LOG.ARTICLE.SECTOR'
  },
  [ActivityTypeEnum.MatchDayPassPrice]: {
    name: 'ACTIVITY.LOG.NAME.MATCHDAYPASS-PRICE',
    article: 'ACTIVITY.LOG.ARTICLE.MATCHDAYPASS-PRICE'
  },
  [ActivityTypeEnum.PricingPolicy]: {
    name: 'ACTIVITY.LOG.NAME.PRICING-POLICY',
    article: 'ACTIVITY.LOG.ARTICLE.PRICING-POLICY'
  },
  [ActivityTypeEnum.MembershipRenewalBatch]: {
    name: 'ACTIVITY.LOG.NAME.MEMBERSHIP-RENEWAL-BATCH',
    article: 'ACTIVITY.LOG.ARTICLE.MEMBERSHIP-RENEWAL-BATCH'
  },
  [ActivityTypeEnum.MembershipType]: {
    name: 'ACTIVITY.LOG.NAME.MEMBERSHIP-TYPE',
    article: 'ACTIVITY.LOG.ARTICLE.MEMBERSHIP-TYPE'
  },
  [ActivityTypeEnum.EventCrossSellProduct]: {
    name: '',
    article: ''
  },
  [ActivityTypeEnum.CustomTheme]: {
    name: '',
    article: ''
  },
  [ActivityTypeEnum.AssignmentPolicy]: {
    name: '',
    article: ''
  },
};
