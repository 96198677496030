    

  <form [formGroup]="form" class="form-content">
    
      <div fxLayout="row" fxLayoutWrap="wrap">
              <div fxFlex>
                  <div class="header">{{ passedData.title }}</div>
              </div>
      
              <div fxFlex="420px" class="text-right">
                   
                <button class="btn btn-accent" (click)="submit()" [disabled]="form.invalid || saving"  >
                    <i class="far fa-save"></i>
                    <span>{{'GENERIC.BTN.PROCEED' | translate}}</span>
                </button>

                <button class="btn btn-default" [disabled]="saving" (click)="dialogRef.close(false);" >
                      <i class="fas fa-ban"></i>
                      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
                  </button>
                                              
              </div>
          </div>
      

          
      <div fxLayout="row" fxLayoutWrap="wrap" class="form-group">
          <div fxFlex="100" fxFlex.gt-xs="100" >
  
              <!-- left page controls. -->
              
         
  
                  <mat-form-field class="full-width"  style="width: 100%;">
                      <mat-select id="selectDebtor" placeholder="{{'SALES.CASH-REGISTER.DIALOG.SELECT-DEBTOR.DEBTOR.PLACEHOLDER' | translate}}" 
                                  name="debtorId" class="full-width" formControlName="debtorId">
      
                          <mat-option *ngFor="let l of availableDebtors" [value]="l.Id" >
                              {{l.Name}} ({{l.ExternalId | replaceOnEmpty}} / {{l.ContactEmail | replaceOnEmpty}})
                          </mat-option>
      
                      </mat-select>
                  </mat-form-field>

      
                                    
          </div>
     
      </div>
    
    </form>