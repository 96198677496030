<form [formGroup]="form">

  <div class="card card-table p-2 order-height">


    <div class="row">
      <div class="col-xl-8 col-lg-12">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span (click)="updateFilter($event)" class="input-group-text bd-0"><i class="fas fa-search"></i></span>
          </div>
          <input (keydown.enter)="updateFilter($event)" formControlName="SearchText" class="form-control bd-0"  placeholder="{{'SALES.EVENT.SELECTION.PLACEHOLDER.SEARCH' | translate}}" type="text">
        </div>
      </div>
      <div class="col-xl-4 col-lg-12  pt-1">
        <button [disabled]="selectedObjects.length == 0" (click)="emptySelection()" class="btn btn-small btn-block btn-primary border" type="button"><i class="fas fa-sync-alt"></i> {{'GENERIC.BUTTON.RESET' | translate}} ({{getSelectedEventCount()}})</button>
      </div>
    </div>

    <div>

      <table class="table table-striped">

        <ng-container *ngIf="listObjects.length > 0; else noresult">

          <tbody>
            <tr *ngFor="let e of listObjects" class="clickable list-item" [class.selected-list-item]="isItemSelected(e)" (click)="toggleSelection(e)" >

              <td style="vertical-align: middle; width: 32px;"><input type="checkbox" [checked]="isItemSelected(e)" /> </td>
              <td class="p-0" style="width: 60px; vertical-align: middle;">
                <img *ngIf="e.ImageUrlHomeTeam !== null" class="mr-1" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(e.ImageUrlHomeTeam, 32, 32)">
                <img *ngIf="e.ImageUrlEvent !== null" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(e.ImageUrlEvent, 32, 32)">
                <img *ngIf="e.ImageUrlAwayTeam !== null" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(e.ImageUrlAwayTeam, 32, 32)">
              </td>
              <td class="px-0 py-3"  style="vertical-align: middle">
                <a class="text-dark">{{e.Name}}</a>
                <span class="d-block">{{e.StartDateTime | localDateLong}} {{'GENERIC.TIME.CONNECTOR.AT' | translate}} {{e.StartDateTime | localTime}}</span>
              </td>
              <td style='vertical-align: middle;' class="text-right">
                <img *ngIf="e.ImageUrlEventType !== null" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(e.ImageUrlEventType, 32, 32)">
              </td>
            </tr>
          </tbody>

        </ng-container>

        <ng-template #noresult>
          <tbody>
            <tr><td>{{'GENERIC.LISTVIEW.NO-ITEMS.TEXT' | translate}}</td></tr>
          </tbody>
        </ng-template>

      </table>

    </div><!-- table-responsive -->

    <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doPage($event)" #pagerComponent></t4-pager>

  </div>

</form>
