import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit {

  constructor() { }

  public uploader: FileUploader;

  ngOnInit() {

    this.uploader =  new FileUploader({
      url:'http://localhost/ITXSoftware.Bizztix.API/api/upload/import',
      authTokenHeader: "Authorization",
      authToken: "Bearer "+ sessionStorage.getItem("userToken"),
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

  }

}
