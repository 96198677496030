import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'venueTitle' })
export class VenueTitlePipe implements PipeTransform {
  constructor(private translate: TranslateService){}
  transform(vbb) {
    
    let result = '';
    if (vbb.VenueBuildingBlockTypeId == 1) result = this.translate.instant('VENUE-TITLE.PIPE.LOCATION');
    else result = this.translate.instant('VENUE-TITLE.PIPE.SECTION');

    result += vbb.Name;

    return result;

  }
}