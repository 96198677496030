/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProlongationLineStateEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const ProlongationLineStateEnum = {
    NUMBER_1: 1 as ProlongationLineStateEnum,
    NUMBER_2: 2 as ProlongationLineStateEnum,
    NUMBER_3: 3 as ProlongationLineStateEnum,
    NUMBER_4: 4 as ProlongationLineStateEnum,
    NUMBER_5: 5 as ProlongationLineStateEnum,
    NUMBER_6: 6 as ProlongationLineStateEnum,
    NUMBER_7: 7 as ProlongationLineStateEnum
};

