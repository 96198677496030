import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ActionType,
  ActionTypeDictionary, 
  ButtonStyleDictionary, 
  CustomButtonData
} from '../../../models/custom-button-data';

import { TranslateService } from '@ngx-translate/core';
import { FrontendButtonState } from '../../../api';
import { KeyValuePair } from '@tymes4-shared';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-custom-button-state-form',
  templateUrl: './custom-button-state-form.component.html',
  styleUrls: [],
})

export class CustomButtonStateFormComponent implements OnInit {

  public stateId: Number | null = null;
  public form: FormGroup;
  public buttonStyleValues: Array<any>;
  public actionTypeValues: Array<any>;

  private readonly regexTime = /^[0-9]{1,2}:[0-9]{2}$/;
  private readonly regexUrl = /^(http|https):\/\/[^ "]+$/;
  public isInArchiveMode = this.authService.isInArchiveMode();

  validationMessages = {
    'action': [
      {type: 'pattern', message: this.translate.instant('GENERIC.VALIDATION.URL.PATTERN')}
    ]
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: { title: string, description: string, payload: CustomButtonData },
    public dialogRef: MatDialogRef<CustomButtonStateFormComponent>,
    public translate: TranslateService,
    private authService: AuthService) {
  }

  private requiredFields: KeyValuePair<boolean> = null;

  isRequiredField(field: string, targetForm: FormGroup = null): boolean {
    if (this.requiredFields === null) {
      this.requiredFields = {};
    }

    if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
      const form = targetForm ? targetForm : this.form;
      const formField = form.get(field);
      if (!formField.validator) {
        return false;
      }

      const validator = formField.validator({} as AbstractControl);
      this.requiredFields[field] = validator && validator.required;

      formField.validator(formField);
    }
    return this.requiredFields[field];
  }

  ngOnInit() {

    this.buttonStyleValues = ButtonStyleDictionary;
    this.actionTypeValues = ActionTypeDictionary;

    const payload = this.passedData.payload;
  
    this.form = new FormGroup({
      id: new FormControl(payload.Id),
      translationCode: new FormControl(payload.TranslationCode, [Validators.required]),
      buttonStyle: new FormControl(payload.ButtonStyle === null ? this.translate.instant("GENERIC.BUTTON-STYLE.DEFAULT") : payload.ButtonStyle, [Validators.required]),
      actionType: new FormControl(payload.ActionType === null ? this.translate.instant("GENERIC.ACTION-TYPE.DEFAULT") : payload.ActionType, [Validators.required]),
      action: new FormControl(payload.Action, [Validators.pattern(this.regexUrl)]),
      openInNewTab: new FormControl(payload.OpenInNewTab),
      ActiveFrom: new FormControl(payload.ActiveFrom, [Validators.required]),
      ActiveTill: new FormControl(payload.ActiveTill, [Validators.required])
    });

    this.handleActionChanges({value: payload.ActionType === null ? this.translate.instant("GENERIC.ACTION-TYPE.DEFAULT") : payload.ActionType});

    if(this.isInArchiveMode)
        this.form.disable();
  }

  handleActionChanges(e) {
    const val = e.value;
    let disableActionInput = false;
    let disableStyleInput = false;
    switch (val) {
      case this.translate.instant('GENERIC.ACTION-TYPE.DEFAULT'):
        disableActionInput = true;
        disableStyleInput = false;
        break;
      case this.translate.instant('GENERIC.ACTION-TYPE.EXTERNAL-LINK'):
        disableActionInput = false;
        disableStyleInput = false;
        break;
      case this.translate.instant('GENERIC.ACTION-TYPE.DISABLED'):
        disableActionInput = true;
        disableStyleInput = true;
        break;
    }

    if (disableStyleInput) {
      this.form.get('buttonStyle').disable();
      this.form.get('buttonStyle').setValue(this.translate.instant('GENERIC.BUTTON-STYLE.DISABLED'));
    } else {
      this.form.get('buttonStyle').enable();
    }

    if (disableActionInput) {
      this.form.get('action').disable();
      this.form.get('action').setValue(null);
      this.form.get('openInNewTab').disable();
    } else {
      this.form.get('action').enable();
      this.form.get('openInNewTab').enable();
    }
  }


  showOpenInNewTab(): boolean {
    return this.form.value.actionType == ActionType.ExternalLink;
  }

  public save(): void {

    var f = this.form.value;

    const result: FrontendButtonState = {
       Action: f.action,
       ActiveFrom: f.ActiveFrom, 
       ActionType: f.actionType,
       ActiveTill: f.ActiveTill,
       ButtonStyle: f.buttonStyle,  
       InitiativeId: null, 
       IsVoidState: false, 
       TranslationCode: f.translationCode,
       OpenInNewTab: f.openInNewTab,
       Id: f.id
    };

    this.dialogRef.close(result);
  }
}
