import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-turnover',
  templateUrl: 'card-turnover.component.html'
})

export class CardTurnoverComponent implements OnInit {
  progressPerc: any = null;

  @Input() Name: string = null;
  @Input() TurnOverWeek = '';
  @Input() TurnOverMonth = '';
  @Input() TurnOverTotal = '';
  @Input() RealizedTargetPerc = 0;

  constructor() {
  }

  ngOnInit() {
    this.initTurnOverTotal();
  }

  initTurnOverTotal() {
    this.progressPerc = {
      chart: {
        height: 100,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      colors: ['#487EBC'],
      plotOptions: {
        bar: {
          barHeight: '100%',
          horizontal: true,
        }
      },
      xaxis: {
        min: 0,
        max: 100
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return val + '%';
        },
      },
      labels: [''],
      series: [{ name: '', data: [ this.RealizedTargetPerc ] }],
      legend: {
        show: false
      },
    };
  }
}
