import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomValidators } from "@narik/custom-validators";
import * as moment from "moment";

@Component({
  selector: "app-membership-batch-collect-form",
  templateUrl: "./membership-batch-collect-form.component.html",
})
export class MembershipBatchCollectFormComponent implements OnInit {
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<MembershipBatchCollectFormComponent>
  ) {}

  ngOnInit(): void {
    let minDate = moment(new Date());
    this.form = new FormGroup({
      collectionDate: new FormControl(null, [
        Validators.required,
        CustomValidators.minDate(minDate.add(2, "days")),
      ]),
      collectionDescription: new FormControl(""),
      CreditManagementStrategy : new FormControl(null),
    });
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }
}
