import {HttpClient} from "@angular/common/http";

import {Injectable} from "@angular/core";
import {DataService, EnvironmentService} from '@tymes4-shared';

import {LoggingService} from '@tymes4-shared';
import { catchError } from "rxjs/operators";

@Injectable()
export class ImportService extends DataService {
  constructor(private httpClient: HttpClient, logging: LoggingService, environmentService: EnvironmentService) {
    super(httpClient, environmentService);
    this.entityName = 'import';
  }

  startTempImport() {
    var url = this.getUrl() + `/start_temp_company_import`;
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }

  startTempContactImport() {
    var url = this.getUrl() + `/start_temp_contact_import`;
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }

}
