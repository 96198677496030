import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'file-import-result-dialog',
    templateUrl: './file-import-result-dialog.component.html'
  })
export class FileImportResultDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
        public dialogRef: MatDialogRef<FileImportResultDialogComponent>,
        private translate: TranslateService) {
        
    }

    ngOnInit(): void {
        
    }
}
