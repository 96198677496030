<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<div class="section-wrapper" style="height: 100%">
  <div class="form-layout">

    <div class="row mg-b-25">
      <div class="col-lg-12">

        <div class="timeline-container">
          <div id="timeline">
            <div class="dot" *ngFor="let purchaseRightInfo of purchaseRightInfoOnDates; index as i" id="dot{{i}}"
              [ngStyle]="styleDot(i)"
              (click)="onPurchaseRightInfoClick(purchaseRightInfo, i)">
              <span></span>
              <div style="font-size: 0.8rem;">{{ purchaseRightInfo.Date | localDateTime }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row mg-b-25">
      <div class="col-lg-12">
        <span [innerHtml]="purchaseRightInfoText" class="list-fix"></span>
      </div>
    </div>

    <div class="form-layout-footer row">
      <div class="col-6">

      </div>
      <div class="col-6">
        <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-window-close"></i><span>{{'CHECK.PURCHASE.RIGHT.CLOSE' | translate}}</span></button>
      </div>
    </div>
  </div>
</div>
