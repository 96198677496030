import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConstService {

  public customerTypes: any = [
    {
      viewValue: this.translate.instant('SERVICES.CONST.CUSTOMER-TYPES.BUSINESS'),
      value: 1
    },
    {
      viewValue: this.translate.instant('SERVICES.CONST.CUSTOMER-TYPES.PRIVATE'),
      value: 2
    },
  ];

  public venueBuildingBlockTypes: any = [
    {
      Name: this.translate.instant('SERVICES.CONST.VENUE-BUILDING-BLOCK-TYPES.LOCATION'),
      Id: 1
    },
    {
      Name: this.translate.instant('SERVICES.CONST.VENUE-BUILDING-BLOCK-TYPES.SECTION'),
      Id: 2
    },
  ];

  public expirationUnits: any = [
    {
      viewValue: this.translate.instant('GENERIC.MINUTES'),
      value: 'M'
    },
    {
      viewValue: this.translate.instant('GENERIC.HOURS'),
      value: 'H'
    },
    {
      viewValue: this.translate.instant('GENERIC.DAYS'),
      value: 'D'
    },
  ];

  public reportIntervals: any = [
    {
      code: 'REPORT-INTERVAL-ALL',
      value: 1
    },
    {
      code: 'REPORT-INTERVAL-CUSTOM',
      value: 999
    }
  ];

  public reportSalesTypes: any = [
    {
      code: 'REPORT-SALESTYPE-ALL',
      value: 1
    },

    {
      code: 'REPORT-SALESTYPE-SINGLETICKET',
      value: 2
    },

    {
      code: 'REPORT-SALESTYPE-PASSEPARTOUT',
      value: 3
    }
  ];


  public reportPrices: any = [
    {
      code: 'REPORT-PRICE-ALL',
      value: 1
    },
    {
      code: 'REPORT-PRICE-CUSTOM',
      value: 999
    }

  ];


  public ticketReferencePrefix = 'BT-';

  constructor(private translate: TranslateService) {
  }

}
