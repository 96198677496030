import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ConstService } from 'app/shared/services/const.service';
import {ConfirmService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { IPWhitelistingService } from '../../api';

@Component({
  selector: 'app-edit-ip-whitelisting-form',
  templateUrl: './edit-ip-whitelisting-form.component.html',
  styleUrls: ['./edit-ip-whitelisting-form.component.scss']
})
export class EditIpWhitelistingComponent implements OnInit {

  public form: FormGroup;

  public editingObject = null;
  private isNew = false;
  public saving = false;
  public pricecomponents: any;

  public instalmentOptions = [1, 2, 3, 4];
  public days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  public months = [{ id: 1, name: this.translate.instant('GENERIC.MONTH.JANUARY')},
  { id: 2, name: this.translate.instant('GENERIC.MONTH.FEBRUARY')},
  { id: 3, name: this.translate.instant('GENERIC.MONTH.MARCH')},
  { id: 4, name: this.translate.instant('GENERIC.MONTH.APRIL')},
  { id: 5, name: this.translate.instant('GENERIC.MONTH.MAY')},
  { id: 6, name: this.translate.instant('GENERIC.MONTH.JUNE')},
  { id: 7, name: this.translate.instant('GENERIC.MONTH.JULY')},
  { id: 8, name: this.translate.instant('GENERIC.MONTH.AUGUST')},
  { id: 9, name: this.translate.instant('GENERIC.MONTH.SEPTEMBER')},
  { id: 10, name: this.translate.instant('GENERIC.MONTH.OCTOBER')},
  { id: 11, name: this.translate.instant('GENERIC.MONTH.NOVEMBER')},
  { id: 12, name: this.translate.instant('GENERIC.MONTH.DECEMBER')}];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditIpWhitelistingComponent>,
              public constants: ConstService,
              private loader: HttpLoaderService,
              private confirmService: ConfirmService,
              private ipWhitelistingService: IPWhitelistingService,
              private translate: TranslateService
              ) { }

  validationMessages = {
    'IPAddress': [
      { type: 'pattern', message: 'FORM.VALIDATION.INVALIDIP' }
    ],
  };

  ngOnInit() {
    this.editingObject = this.passedData.payload;

    // Set the object to the form
    if (this.editingObject == null) {
      // this means that we are adding a new form, init with defaults.
      this.isNew = true;
      this.editingObject = {};
    } else {
      this.isNew = false;
    }

    this.form = new FormGroup({
      Id: new FormControl(0), // to preserve value
      IPAddress: new FormControl('', [
        Validators.required, 
        Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')
      ]),
      Description: new FormControl(''),
    });

    this.form.patchValue(this.editingObject);
  }

  submit() {
    const formData = this.form.value;

    this.loader.open();

    if (this.isNew === true) {
      this.ipWhitelistingService.createIPWhiteListing(formData).subscribe((id: number) => {
        this.loader.open();
        this.dialogRef.close(id);
      });
    }
    else {
      this.ipWhitelistingService.updateIPWhiteListing(formData).subscribe((id: number) => {
        this.loader.open();
        this.dialogRef.close(id);
      });
    }
  }
}
