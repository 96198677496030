import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TicketText, TicketTextService } from '../../api';

@Injectable()
export class TicketTextValidator {
    constructor(private ticketTextService: TicketTextService) {}

    public editingObject: any;

    ticketTextCodeTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        const v = control.value;

        setTimeout(() => {
          this.ticketTextService.getTicketTextByCode(v).subscribe((data: TicketText) => {
            if (data !== null && data !== void 0) {
              if (!this.editingObject || data.Id !== this.editingObject.Id) {
                resolve({'codetaken': data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
    }
}
