<!-- MODAL HEADER-->
<div class="row modal-bar">
    <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
      <div class="modal-actions">
        <div class="modal-close">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span (click)="dialogRef.close(false);">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  
  
  <form [formGroup]="form" class="form-content">
  
    <div class="section-wrapper">
  
      <div class="form-layout">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'EDITCROSSSELLPRODUCTVARIANT.FORM.NAME.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('Name')">*</span></label>
                    <input class="form-control" name="Name" formControlName="Name" >
                    <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'EDITCROSSSELLPRODUCTVARIANT.FORM.SKU.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('Name')">*</span></label>
                    <input class="form-control" name="SKU" formControlName="SKU" >
                    <t4-display-validation controlName="SKU" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'EDITCROSSSELLPRODUCTVARIANT.FORM.STOCKCOUNT.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('CurrentStockCount')">*</span></label>
                    <input class="form-control" name="CurrentStockCount" formControlName="CurrentStockCount" type="number" [min]="productVariant.SoldCount"> 
                    <t4-display-validation controlName="CurrentStockCount" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label class="form-control-label">
                        {{'EDITCROSSSELLPRODUCTVARIANT.FORM.VATRATE.LABEL' | translate}}: 
                      <span class="tx-danger" *ngIf="isRequiredField('VatRateId')">*</span>
                    </label>
        
                    <ng-select [items]="vatRates" bindLabel="Description" bindValue="Id" formControlName="VatRateId"
                               placeholder="{{'EDITCROSSSELLPRODUCTVARIANT.FORM.VATRATE.PLACEHOLDER' | translate}}"
                               [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="OnVatRateChanged($event)"></ng-select>
                    <t4-display-validation controlName="VatRateId" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                  <label class="form-control-label">
                      {{'EDITCROSSSELLPRODUCTVARIANT.FORM.AMOUNTEXVAT.LABEL' | translate}}: 
                    <span class="tx-danger" *ngIf="isRequiredField('AmountExVat')">*</span>
                  </label>
                  <input class="form-control"
                  name="AmountExVat"
                  formControlName="AmountExVat"
                  type="number"
                  currencyformat
                  min="0"
                  placeholder="{{'EDITCROSSSELLPRODUCTVARIANT.FORM.PLACEHOLDER.AMOUNTEXVAT' | translate}}"
                  (change)="onAmountExVatChanged($event)" >
                  <t4-display-validation controlName="AmountExVat" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
          </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label class="form-control-label">
                        {{'EDITCROSSSELLPRODUCTVARIANT.FORM.AMOUNTINVAT.LABEL' | translate}}: 
                      <span class="tx-danger" *ngIf="isRequiredField('AmountInVat')">*</span>
                    </label>
                    <input class="form-control"
                    name="AmountInVat"
                    formControlName="AmountInVat"
                    type="number"
                    currencyformat
                    min="0"
                    placeholder="{{'EDITCROSSSELLPRODUCTVARIANT.FORM.PLACEHOLDER.AMOUNTINVAT' | translate}}"
                    (change)="onAmountInVatChanged($event)" >
                    <t4-display-validation controlName="AmountInVat" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
        </div>

        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="!form.dirty || form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
  
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
  </form>