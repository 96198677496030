import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PurchasePeriodInfo, PurchaseRightService} from "../../api";

@Component({
  selector: 'app-check-purchase-right',
  templateUrl: './check-purchase-right.component.html',
  styleUrls: ['./check-purchase-right.component.scss']
})
export class CheckPurchaseRightComponent implements OnInit {
  public isSalesPeriod: boolean = false;
  public isEvent: boolean = false;

  public selectedIndex: number = -1;

  public purchaseRightInfoText: string = "";

  public purchaseRightInfoOnDates: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<CheckPurchaseRightComponent>,
              private purchaseRightService: PurchaseRightService) { }

  ngOnInit() {
    this.isSalesPeriod = this.passedData.salesPeriodId > -1;
    this.isEvent = this.passedData.eventId > -1;

    this.purchaseRightService.getPurchaseRightInfoPerPeriod(this.passedData.salesPeriodId, this.passedData.eventId, this.passedData.initiativeId).subscribe((data: Array<PurchasePeriodInfo>) => {
      this.purchaseRightInfoOnDates = data;

      this.onPurchaseRightInfoClick(this.purchaseRightInfoOnDates[0], 0);
    });
  }

  onPurchaseRightInfoClick(purchaseRight: any, index: number) {
    this.purchaseRightInfoText = purchaseRight.Text;
    this.selectedIndex = index;
  }

  public styleDot(index) {
    return { 'left': ((index + 1) * (95 / (this.purchaseRightInfoOnDates.length + 1))) + '%',
             'background-color': (index == this.selectedIndex ? '#2676a5' : '#6AB0DE') };
  }
}
