<div>

  <div class="row">
    <div class="col-lg-6" *ngIf="tabSelected === 'E' || tabSelected === 'P'">
      <app-sales-event-selection [hasOther]="this.hasPassePartouts || this.hasMemberships || this.hasCrossSellProductVariants" (onEventSelectionChange)="onEventSelection($event)" *ngIf="tabSelected === 'E'"></app-sales-event-selection>
      <app-sales-passepartout-selection [hasOther]="this.hasEvents || this.hasMemberships || this.hasCrossSellProductVariants" (onPassePartoutSelectionChange)="onPassePartoutSelection($event)" *ngIf="tabSelected === 'P'"></app-sales-passepartout-selection>
    </div>

    <div class="col-lg-6" [class.d-none]="tabSelected !== 'E' && tabSelected !== 'P'">

      <div class="card order-height h-100" style="min-height: 450px;">

        <div class="card-header section-selection p-0">

          <div class="row no-gutters">
            <div class="col-sm-8 col-sm-8 pl-5 pt-2">
              <h6>{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.HEADER' | translate}}</h6>
            </div>
            <div class="col-sm-4 text-right p-1 ">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-secondary" (click)="setMapMode(true)" [class.active]="inMapMode === true" ><i class="far fa-map"></i></button>
                <button type="button" class="btn btn-secondary" (click)="setMapMode(false)" [class.active]="inMapMode === false"><i class="far fa-list-alt"></i></button>
              </div>
            </div>
          </div>

        </div>

        <div class="map-holder">

          <ng-container *ngIf="displayVenue === false">
            <div class="p-5" *ngIf="hasVenuePlan === true">{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.MSG' | translate}}</div>
          </ng-container>

          <div [class.d-none]="displayVenue === false || inMapMode === false" class="h-100" fxLayoutAlign="center center">

            <div [class.d-none]= "" style="width: 100%;">
              <div class="p-5" *ngIf="hasVenuePlan === false">{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.MSG.NOVENUEPLAN' | translate}}</div>
              <app-venue-renderer [class.d-none]= "hasVenuePlan === false" (onSectionClicked)="handleSectionClick($event)"></app-venue-renderer>
            </div>

          </div>


          <div [class.d-none]="displayVenue === false || inMapMode === true">

            <t4-list-view #listVw [options]="listOptions" (onDirectSelection)="selectSection($event)" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">
              <ng-container *t4ListItem="let item">

                <td>{{ item.Name }}</td>
                <td>{{ sectionAvailability(item) }}</td>

              </ng-container>
            </t4-list-view>

          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12" *ngIf="tabSelected === 'M'">
      <app-sales-membership-selection [hasOther]="this.hasEvents || this.hasPassePartouts || this.hasCrossSellProductVariants || this.hasMatchDayPasses" (onMembershipSelectionChange)="onMembershipSelection($event)" *ngIf="tabSelected === 'M'"></app-sales-membership-selection>
    </div>

    <div class="col-lg-12" *ngIf="tabSelected === 'C'">
      <app-sales-crosssellproductvariant-selection [hasOther]="this.hasEvents || this.hasPassePartouts || this.hasMemberships || this.hasMatchDayPasses" (onCrossSellProductVariantChange)="onCrossSellProductVariantSelection($event)" *ngIf="tabSelected === 'C'"></app-sales-crosssellproductvariant-selection>
    </div>

    <div class="col-lg-12" *ngIf="tabSelected === 'D'">
      <app-sales-matchdaypass-selection [hasOther]="this.hasEvents || this.hasPassePartouts || this.hasCrossSellProductVariants || this.hasMemberships" (onMatchdaypassSelectionChange)="onMatchdaypassSelectionChange($event)"></app-sales-matchdaypass-selection>
    </div>

  </div>
</div>

