import { ModuleWithProviders, NgModule } from '@angular/core'; //tymes4v2: import corrected
import { HttpLoaderService } from '@tymes4-shared';

@NgModule({})

export class StaticSharedModule {
    //Services defined here will only get one instance in memory
    static forRoot(): ModuleWithProviders<StaticSharedModule> {
        return {
          ngModule: StaticSharedModule,
          providers: [HttpLoaderService]
        };
      }
 }
