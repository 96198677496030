<form [formGroup]="form" class="form-content">

  <div fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex>
        <div class="header">{{ passedData.title }}</div>
    </div>

    <div fxFlex="350px" class="text-right">

      <button class="btn btn-accent" (click)="downloadTagFile();" [disabled]="!form.valid" title="{{'GENERIC.BUTTON.EXPORT-AND-CLOSE-WINDOW' | translate}}">
        <i class="far fa-save"></i>
        <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
      </button>

      <button class="btn btn-default" (click)="dialogRef.close(false);" title="{{'GENERIC.BUTTON.CANCEL-CHANGES-AND-CLOSE-WINDOW' | translate}}">
          <i class="fas fa-ban"></i>
          <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
      </button>


    </div>
  </div>


  <div class="form-group">

    <div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex="100" fxFlex.gt-xs="100" class="pr-1">
        <mat-form-field  class="full-width">
          <mat-select id="tag" placeholder="Tag" name="Tag" class="full-width" formControlName="Tag">
            <mat-option *ngFor="let option of tags" [value]="option.Id">{{option.Name}}</mat-option>
          </mat-select>
          <t4-display-validation controlName="Tag" [form]="form"
                                  [messages]="validationMessages"></t4-display-validation>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
