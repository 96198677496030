/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssignmentPolicyModel { 
    Id?: number;
    Name?: string | null;
    Code?: string | null;
    AssignmentRequired?: boolean;
    IdentificationRequired?: boolean;
    BlacklistCheck?: boolean;
    MaxTicketAmount?: number;
    EnforcePurchasingRights?: boolean;
    CheckAge?: boolean;
    WorkflowRuleId?: number;
    RevokeStartDate?: Date;
    RevokeEndDate?: Date;
    TicketTypeIds?: Array<number> | null;
    CanDelete?: boolean;
    MandatoryBuyerAssignment?: boolean;
}

