
<div fxLayout="row" class="text-right">
  <div fxFlex>
    <div class="header">{{ passedData.title }}</div>
  </div>
  <div fxFlex="420px" class="text-right">

    <button class="btn btn-accent" [disabled]="this.currentValue === this.original" (click)="submit()" title="{{ 'GENERIC.BUTTON.SAVE' | translate }}">
      <i class="far fa-save"></i>
      <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
    </button>
    
    <button class="btn btn-default" (click)="dialogRef.close(null)" >
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>

    

  </div>
</div>

  <mat-form-field class="full-width">
    {{'SALES.ORDERS.DETAILS.DIALOG.EDIT-PAYMENTMETHOD.PAYBYINVOICE' | translate }}
    <mat-radio-group matInput [(ngModel)]="currentValue">
      <input matInput style="display: none">
      <mat-radio-button [value]="false" name="PayByInvoice">{{'GENERIC.BUTTON.NO' | translate}}</mat-radio-button>
      <mat-radio-button [value]="true" name="PayByInvoice">{{'GENERIC.BUTTON.YES' | translate}}</mat-radio-button>
    </mat-radio-group>
  </mat-form-field>
