import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {FileService, LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
//import { MdePopoverTrigger } from '@material-extended/mde'; //tymes4v2-not supported

import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/selectable';
import 'assets/JS/jquery-ui-itx/ui/widgets/selectable'
import 'jquery.panzoom/dist/jquery.panzoom';
import 'jquery-mousewheel/jquery.mousewheel'
import { ConversionHelper } from 'app/shared/helpers/conversion-helper';
import { SeatPlanHelper } from 'app/shared/helpers/seatplan-helper';
import { SeatplanLegendComponent } from 'app/shared/dialogs/seatplan-legend/seatplan-legend.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ContingentService, EventPlacementService, SectionAvailabilityArgs, VenueBuildingBlockService } from '../../api';
@Component({
  selector: 'app-seat-plan',
  templateUrl: './seat-plan.component.html',
  styleUrls: ['./seat-plan.component.scss'],
  animations: [
    trigger('enabledStateChange', [
      state(
        'default',
        style({
          opacity: 1,
        })
      ),
      state(
        'disabled',
        style({
          opacity: 0.5,
        })
      ),
      transition('* => *', animate('300ms ease-out')),
    ])
  ]
})
export class SeatPlanComponent implements OnInit, AfterViewInit {

  constructor(private logging: LoggingService,
              private loader: HttpLoaderService,
              private eventPlacementService: EventPlacementService,
              private cd: ChangeDetectorRef,
              private contingentService: ContingentService,
              private sanitizer: DomSanitizer,
              private convert: ConversionHelper,
              private dialog: MatDialog,
              public fileHelper: FileService,
              public seatPlanHelper: SeatPlanHelper,
              private translate: TranslateService) { }

  //@ViewChild('orientationSlider', {static: false}) orientationSlider;
  //@ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger; tymes4v2-not supported

  popoverDetailsLoading = false;
  reasonUnsellable = null;
  contingents: any = [];
  selectedSellableSection: any = {};
  selectedEventIds: any = [];
  priceCategories: any = [];
  selectedTicketType: any = {};
  selectedOrderLines = null;
  selectedPassePartouts = null;
  private contigentAdmin = false;
  public showContingents: boolean = false;
  selectedContingentId = -1;
  seatRestrictionTypes: any = [];
  availableContigents: any = [];
  selectedSeatRestrictionTypeId = -1;
  searching = false;
  selectedPositions: any =[];
  selectedPriceCategoryId = -1;
  popoverDetails = null;

  // mouseX : number = 0;
  // mouseY : number = 0;

  //moveOffset = null;
  //safeTransform;
  //scale = 100;

  //endpointData: any;
  rows = [];

  preventNextSelection = false;
  hasMoved = false;

  seatPlan: any = {};

  @Output() onSelectionChange = new EventEmitter<object>();

  @Input()
  set section(s: any) {
    this.selectedSellableSection = s;

  }

  @Input()
  set ticketType(t: any) {
    this.selectedTicketType = t;

  }

  @Input()
  set eventIds(e:any) {
    this.selectedEventIds = e;

  }


  @Input()
  set orderlineSelection(e: any) {
    this.selectedOrderLines = e;
  }

  @Input()
  set passePartouts(e: any) {
    this.selectedPassePartouts = e;
  }


  ngOnInit() {
    this.Initialize();
  }

  reselectEarlierSelection(){

    //this method selects the earlier selected seats again.
    //But since the events/passepartouts can potentially be changes
    //we need to take  the lowest common denominator
    this.selectedEndpoints = [];


    if (this.selectedEventIds == null || this.selectedEventIds.length === 0 || this.selectedOrderLines == null) return;

    var ttId = this.selectedTicketType.Id;

    //first filter the orderlines on tickettype
    var ols = this.selectedOrderLines.filter(function (ol)   {
      if (ol.TicketTypeId === ttId) return ol;
    });

    //we need the items that are selected in ALL events and or passepartouts
    //get the selection of the first (and random) event/pp.
    //Check if the seat is selected for all events/pps
    if (this.selectedPassePartouts != null && this.selectedPassePartouts.length > 0) {

      //when we have an pp selected, this has the prio
      var olsByPP = this.findByPassePartout(this.selectedOrderLines, this.selectedPassePartouts[0].Id);

      for (let ol of olsByPP) {
        var ep = this.findEndPoint(ol.Row, ol.Column);
        if (ep != null)this.selectedEndpoints.push(ep);
      }

      for (let pp of this.selectedPassePartouts) {

        //iterate in reverse and check if the items is available for this event.
        var i = this.selectedEndpoints.length;

        while (i--) {

          var ep = this.selectedEndpoints[i];

          //now check every orderline for every event to see if the endpoint is also selected here
          var ols = this.findByPassePartout(this.selectedOrderLines, pp.Id);

          var epsSelected = ols.filter(function (ol)   {
            if (ol.Row === ep.Row && ol.Column === ep.Column) return ol;
          });

          var isSelected = epsSelected.length > 0;

          if (isSelected == false ) {
            this.selectedEndpoints.splice(i, 1);
          }

        }
      }
    }
    else {

      var olByEvent = this.findByEvent(this.selectedOrderLines, this.selectedEventIds[0]);

      for (let ol of olByEvent) {
        var ep = this.findEndPoint(ol.Row, ol.Column);
        if (ep != null)this.selectedEndpoints.push(ep);
      }

      for (let eId of this.selectedEventIds){

        //iterate in reverse and check if the items is available for this event.
        var i = this.selectedEndpoints.length;

        while (i--) {

          var ep = this.selectedEndpoints[i];

          //now check every orderline for every event to see if the endpoint is also selected here
          var ols = this.findByEvent(this.selectedOrderLines, eId);

          var epsSelected = ols.filter(function (ol)   {
            if (ol.Row === ep.Row && ol.Column === ep.Column) return ol;
          });

          var isSelected = epsSelected.length > 0;

          if (isSelected == false ) {
            this.selectedEndpoints.splice(i, 1);
          }

        }

      }

    }
  }

  findByPassePartout(ols, ppId) {

    var result = ols.filter(function (ol)   {
      if (ol.PassePartoutId === ppId) return ol;
    });
    return result;

  }

  findByEvent(ols, eventId) {

    var result = ols.filter(function (ol)   {
      if (ol.EventId === eventId) return ol;
    });
    return result;
  }

  findEndPoint(row, column) {

    for (let r of this.seatPlan.Rows) {
      for (let c of r.Columns){
        if (c.Row == row && c.Column == column) {
          return c;
        }
      }
    }
    return null;

  }

  Initialize() {

    this.loader.open();

    var p : SectionAvailabilityArgs = {
      EventIds: this.selectedEventIds,
      ParentVBBId: this.selectedSellableSection.VenueBuildingBlockId,
      GroupingId: this.selectedSellableSection.GroupingId,
      TicketTypeId: this.selectedTicketType.Id
    };


    this.contingentService.listSelectableContingents().subscribe((contingents: any) => {
      this.contingents = contingents;
      this.eventPlacementService.getSection(p).subscribe((data) => {
        this.seatPlan = data;

        this.availableContigents = data.AvailableContingents;
        
        if(this.availableContigents === null)  this.availableContigents = [] 

        this.availableContigents.unshift({
          Id: null,
          Name: this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.NO-CONTINGENT') == null ? '-' : this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.NO-CONTINGENT') 
        });
    
        this.priceCategories = data.AvailablePriceCategories;

        this.seatRestrictionTypes = data.AvailableSeatRestrictions;
        if(this.seatRestrictionTypes === null) 
          this.seatRestrictionTypes = [];

        this.seatRestrictionTypes.unshift({
          Id: null,
          Name: this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.NO-RESTRICTION') == null ? '-' : this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.NO-RESTRICTION')
        });
      

        this.initializeSeatPlan();
        this.reselectEarlierSelection();
        this.initializeSelectable();
        this.loader.close();
      });
    });


  }

  selectedEndpoints = [];
  searchedEndpoints = [];

  determineRowName(row)  {

    var result = null;

    if (row == null || row.Columns == null || row.Columns.length == 0 || row.idx == 0) return ``;

    for (var col of row.Columns) {
      if (col.RowNumber != null && col.RowNumber  != '') {

        //we found a row name
        if (result == null) result = col.RowNumber; //first one we found
        else if (result != col.RowNumber) {
          //we found one that doesn't match
          result += "*"; //indicates one or more differences
          return result;//and done
        }
      }
    }

    return result;

  }

  isItemSelected(endpoint, search = false) {

    if (!endpoint) return null;
    if(search) {
      if (this.searchedEndpoints == null && this.searchedEndpoints.length == 0)
        return false;

      var found = this.searchedEndpoints.filter(function(ep){
        if (ep.Id == endpoint.Id) return ep;
      });
    } else {
      if (this.selectedEndpoints == null && this.selectedEndpoints.length == 0)
        return false;

      var found = this.selectedEndpoints.filter(function(ep){
        if (ep.Id == endpoint.Id) return ep;
      });
    }

    return (found != null && found.length > 0);
  }

  clearSelection(search = false) {
    if(search) {
      this.searchedEndpoints = [];
      this.searching = false;
    }else {
      this.selectedEndpoints = [];
    }
    this.setSeatPlanProperties(true, search);
  }

  getRestrictedViewStyle() {
    return '';
  }

  determineEntpointCssClass(ep) {

    if (ep == null) return '';


    var result = 'sp-h ';

    if (this.determineReasonUnsellable(ep) === null) {
      result += "sp-selectable "
    }

    if (ep.PlacementTypeId == -1) {
      //this happens when different events are selected and this seat has a different type
      //for the different events
      result += 'sp-indeterminate';
    }
    else if (ep.PlacementTypeId == 5) result += 's-fence'; //entrance
    else if (ep.PlacementTypeId == 4) result += 'endpoint-entrance'; //entrance
    else if (ep.PlacementTypeId == 3) result += 'invisible'; //no seat
    else if (ep.PlacementTypeId == 2) result += 's-stair'; //stairs

    else if (ep.PlacementTypeId == 1) {
      // //this is a seat, check the status of the seat
      // if (ep.Unavailable) result += 's-unavailable'; //unavailable seat
      // else if (ep.IsReserved) result += 's-reserved'; //reserved seat
      // else if (ep.IsSold === true) result += 's-taken'; //taken seat
      // else if (ep.IsAvailableForSale === false) result += 's-taken'; //not taken and not reserved but unavailable to this user (for instance, in contingent). Display as sold
      if (ep.SeatRestrictionId !== null) result += 's-rv'; //restricted view
      // else {
      //   result += 's-free'; //free seat
      // }
    }

   // console.log('called');
    return result;
  }

  private debounceTimer;

  clearPopoverDetails() {

    //this.popoverDetailsLoading = false;
    //this.popoverDetails = null;

    if (this.debounceTimer !== null) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = null;
    }
  }

  fetchPopoverDetails(seatPlanPosition) {

    if (this.debounceTimer == null) {
      //display the spinner of the operation takes longer than a second
      this.debounceTimer = setTimeout(()=> this.openDelayed(seatPlanPosition) , 300);
    }
  }

  openDelayed(seatPlanPosition) {

    this.reasonUnsellable = this.determineReasonUnsellable(seatPlanPosition);

    let reload: boolean = (this.popoverDetails == null || !(this.popoverDetails.position.Row == seatPlanPosition.Row &&
      this.popoverDetails.position.Column == seatPlanPosition.Column));

    if (reload) {

      this.popoverDetailsLoading = true;
    this.popoverDetails = null;

      var args = {
        EventIds: undefined,
        ParentVBBId: this.selectedSellableSection.VenueBuildingBlockId,
        GroupingId: this.selectedSellableSection.GroupingId,
        ticketTypeId: this.selectedTicketType.Id,
        Row: seatPlanPosition.Row,
        Column: seatPlanPosition.Column,
        ContigentAdmin: this.contigentAdmin,
        PassePartoutIds: undefined
      };
      if (this.selectedPassePartouts !== null && this.selectedPassePartouts.length > 0) {
        args.PassePartoutIds = this.selectedPassePartouts.map(x => x.Id);
      } else {
        args.EventIds = this.selectedEventIds;
      }

      this.eventPlacementService.getSeatPlanPositionDetails(args).subscribe((data) => {
        this.popoverDetails = {
          position: seatPlanPosition,
          details: data
        };
        this.popoverDetailsLoading = false;
      });

    }
  }

  determineReasonUnsellable(seatPlanPos) {
    let result:string = null;

    if (seatPlanPos.ContingentId !== null && seatPlanPos.HasAccessToContingent === false) this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-UNAVAILABLE');
    if (seatPlanPos.IsSold) result = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-SOLD');
    else if (seatPlanPos.IsReserved) result = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-RESERVED');
    else if (seatPlanPos.IsAvailableForSale === false) result = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-NOT-FOR-SALE');
    else if (seatPlanPos.IsUnavailable) result = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-MARKED-UNAVAILABLE');
    else if (seatPlanPos.PlacementTypeId != 1) result = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.REASON-UNSELLABLE.POSITION-NOT-A-SEAT');
//    else if (!seatPlanPos.HasPrice) result = 'Er is geen prijs bekend.';

    return result;
  }

  getStatusClass(details) {

    var result = 'status-dot ';
    var hasPassePartout =  (details.PassePartoutId != null);

    if (details.ContingentName !== null && details.HasAccessToContingent === false) {
      result += 'status-dot-reserved';
    }
    else if (details.Unavailable || details.PlacementTypeId != 1) {
        result += 'status-dot-unavailable';
    }
    else if (details.Sold) {
      result += 'status-dot-not-available';
    }
    else if (details.Reserved) {
      result += 'status-dot-reserved';
    }
    else {
      result += 'status-dot-available';
    }

    return result;
  }

  getSeatNumber(){
      if (this.popoverDetails == null || this.popoverDetails.details == null || this.popoverDetails.details.PositionEventDetails == null || this.popoverDetails.details.PositionEventDetails.length == 0) return '-';
      return this.popoverDetails.details.PositionEventDetails[0].SeatNumber ? this.popoverDetails.details.PositionEventDetails[0].SeatNumber : '-';
  }

  getRowNumber(){
    if (this.popoverDetails == null || this.popoverDetails.details == null || this.popoverDetails.details.PositionEventDetails == null || this.popoverDetails.details.PositionEventDetails.length == 0) return '-';
    return this.popoverDetails.details.PositionEventDetails[0].RowNumber ? this.popoverDetails.details.PositionEventDetails[0].RowNumber : '-' ;    
  }

  getSection(){
    if (this.popoverDetails == null || this.popoverDetails.details == null || this.popoverDetails.details.PositionEventDetails == null || this.popoverDetails.details.PositionEventDetails.length == 0) return '-';
    return console.log(this.popoverDetails.details.PositionEventDetails[0]);
  }

  getTotalOrderPrice (detailLines) {

    let result=0;

    for (let line of detailLines) {
      if (line.Price != null) result += line.Price;
    }
    return result;
  }

  seatplanIsInitialized = false;

  initializeSelectable() {
    //

    let updateOnlySelected = true;

    if (this.seatplanIsInitialized == false) {
      updateOnlySelected = false;
      this.seatplanIsInitialized = true;
    }
    //first up, determine all classes once
    this.setSeatPlanProperties(updateOnlySelected);

    ($(".seat-plan") as any).selectable( {
      filter: '.sp-selectable',
      start: (event, ui ) => { this.onSelectionStart(event, ui) },
      selected: (event, ui ) => { this.onItemSelect(event, ui) },
      stop: (event, ui ) => { this.onSelectionEnd(event, ui) }
    });
  }

  setSeatPlanProperties(onlyUpdateSelectable, search = false) {

    let rowIdx = 0;

    for (let row of this.seatPlan.Rows) {

      let colIdx = 0;

      row.rowName = this.determineRowName(row);

      for (let c of row.Columns) {

        // if (this.isItemSelected(ep)) {
        //   ep.selected =  += " ";
        // }
        if(search) {
          c.searched = this.isItemSelected(c, search);
        }
          c.selected = this.isItemSelected(c);

        if (onlyUpdateSelectable == false) {
          c.Classes = this.determineEntpointCssClass(c);
          c.SeatName = this.convert.namingForSeat(c, colIdx, rowIdx);
          c.artifectStyle = this.seatPlanHelper.getArtifactStyle(this.contingents, c.PlacementTypeId, c.IsSold, c.ContingentId, c.ReservationHexColor, c.AccessToContingent);
        }

        colIdx++;
      }
      rowIdx++;
    }

  }

  private inAlternateSelectionMode = false;
  private newSelectedEp : any[] = null;

  onSelectionStart(e, element) {
    this.cd.detach();
    this.newSelectedEp = [];
  }

  onItemSelect(e, element)  {

    let ep: any = null;
    let id : number = +element.selected.id;


    //find the ep
    for (let r of this.seatPlan.Rows) {
      for (let c of r.Columns) {
        if (c.Id == id) {
          ep = c;
          break;
        }
      }
      if (ep !== null) break;
    }

    if (this.determineReasonUnsellable(ep) != null) return; //no need to select an unsellable item

    this.newSelectedEp.push(ep);
  }

  onSelectionEnd(e, element) {
    if (e.ctrlKey) this.inAlternateSelectionMode = true;

    if (this.newSelectedEp.length == 1) this.inAlternateSelectionMode = true;

    if(!this.inAlternateSelectionMode) this.clearSelection();

    for (let newEp of this.newSelectedEp) {
      if (this.isItemSelected(newEp)) {
        if (this.inAlternateSelectionMode) {
          //in this case unselect the item
          var idx = this.selectedEndpoints.indexOf(newEp);
          this.selectedEndpoints.splice(idx, 1);
        }
      }
      else {
        this.selectedEndpoints.push(newEp);
      }
    }

    this.inAlternateSelectionMode = false;
    this.newSelectedEp = [];
    this.clear();
    this.cd.reattach();
    this.cd.detectChanges();

    //last up, let the parent know about the change

    this.onSelectionChange.emit(this.selectedEndpoints);
  }

  showLegend() {


    let title = this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.TITLE');
    let legendDialog: MatDialogRef<any> = this.dialog.open(SeatplanLegendComponent, {
      width: '400px',
      disableClose: true,
      data: {title: title }
    })

    legendDialog.afterClosed().subscribe((data:any) => {});
  }

  ngAfterViewInit() {


  }

  getCalculatedStyle() {
    let pageWidth = $('.page-holder').width();
    let planWidth = pageWidth - 300;

    var style='max-width: ' + pageWidth + 'px';
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  selectByProperty(prop, value, search, exclude) {
    let normalSearch =  true;
    if (this.seatPlan.Rows == null) return;
    if (value == -1) return;
    // inverse search
    if(value === null) {
      normalSearch = false;
    }

    for (let r of this.seatPlan.Rows) {
      for (let c of r.Columns) {
        if (prop === 'PriceCategoryIds' && normalSearch === true) {
          if (c != null && c[prop] !== null && c.IsUnavailable === false) {
            for (let pcId of c[prop]) {

              if (pcId === value) {

                if (search && (c.searched === false || c.searched === undefined)) {
                  this.searchedEndpoints.push(c);
                }

                if (search === false && c.IsAvailableForSale === true && c.IsReserved === false && (c.selected === false || c.selected === undefined)) {
                  this.selectedEndpoints.push(c);
                }

              }
            }
          }
        } else {
          if(normalSearch === true) {
            if (c != null && c[prop] === value && c.IsUnavailable === false) {
              if(search && (c.searched === false || c.searched === undefined)) {
                this.searchedEndpoints.push(c);
              } if(search === false && (c.selected === false || c.selected === undefined) && c.IsAvailableForSale === true  && c.IsReserved === false ) {
                this.selectedEndpoints.push(c);
              }
            }
          } else {
            let add = true;
            delete exclude[0];
            exclude.forEach((element)=>{
              if((c != null && c[prop] === element.Id && c.IsAvailableForSale === true  && c.IsReserved === false && c.IsUnavailable === false)) {
                add = false;
              }
            });
            if (add === true) {
              if (search === true && (c.searched === false || c.searched === undefined)) {
                this.searchedEndpoints.push(c);
              }
              if (search === false  && (c.selected === false || c.selected === undefined)) {
                this.selectedEndpoints.push(c);
              }
            }
          }

        }

      }
    }
    if(this.searchedEndpoints.length > 0) {
      this.searching = search;
    }
    this.setSeatPlanProperties(true, search);
  }
  initializeSeatPlan() {
    setTimeout(() => {

      (function() {

        var $section = $('#focal');

        var $panzoom = ($section.find('.panzoom-parent') as any).panzoom({
          which: 3,
          cursor: "pointer",
        });

        $panzoom.parent().on('mousewheel.focal', function( e ) {

          e.preventDefault();
          var delta = e.delta || e.originalEvent.wheelDelta;
          var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;

          $panzoom.panzoom('zoom', zoomOut, {
            increment: 0.1,
            animate: false,
            focal: e
          });
        });

        let parentSelector = '#focal';

        var planWidth = $('.seat-plan').width();
        var planTop = $(parentSelector).offset().top;
        var planLeft = $(parentSelector).offset().left;
        var parentWidthFull = $(parentSelector).width();
        var parentWidth = parentWidthFull - 20; //we subtract 80 pixels from the parentwidth as we need some slack in the width

        var focal = {
          clientX: planLeft,
          clientY: planTop
        };

        let panX = 0;
        let panY = 0;

        if (parentWidth < planWidth) {
          //not enough room for the plan to show as a whole, zoom out.
          var scale = parentWidth / planWidth;

          $panzoom.panzoom("zoom",scale, {  silent: true, focal: focal });

          //center the element, at this point easy, we just need to compensate for the padding of 20px
          panX = 10;
        }
        else {
          panX = 0; // the element itself is already centered.
        }

        panY = 30;

        $panzoom.panzoom("pan", panX, panY, { relative: true });

      }

      )();

     }, 50);
  }

  clear() {
    ($( ".seat-plan" ) as any).selectable( "destroy" );
    this.initializeSelectable();
  }

  seeContigent() {
    this.contigentAdmin = true;
    this.showContingents = this.contigentAdmin;
  
  }

  lightUpChek(searched) {
    if(searched === true && this.searching === true) {
      return 'default';
    }
    if (!searched && this.searching) {
      return 'disabled';
    }
    return 'default';

  }


}
