import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

import { FileService } from '@tymes4-shared';
import { TagService } from '../../api';

@Component({
  selector: 'app-download-customertag-exportfile',
  templateUrl: './download-customertag-exportfile.component.html'
})
export class DownloadCustomerTagExportfileComponent implements OnInit {

  public tags = [];
  public form: FormGroup;
  @ViewChild('file') fileInput;

  validationMessages = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<DownloadCustomerTagExportfileComponent>,
    private tagService: TagService,
    private fileHelper: FileService,
    private loader: HttpLoaderService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.form = new FormGroup({
      Tag: new FormControl('', Validators.required),
    });

    this.tagService.listAllTags().subscribe(resp => {
      this.loader.close();
      this.tags = resp;
    });
  }

  downloadTagFile() {
    const formData = this.form.value;

    this.loader.open();

    const tag = this.tags.find(m => m.Id === formData.Tag);

    this.tagService.downloadTagFile(formData.Tag).subscribe(data => {
      this.loader.close();
      FileSaver.saveAs(data, this.fileHelper.makeFileNameSafe(this.translate.instant('DIALOGS.DOWNLOAD-CUSTOMERTAS-EXPORTFILE.DOWNLOAD', {tagName: tag.Name})) + '.xlsx');
      this.dialogRef.close(true);
    });
  }
}
