import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { LongRunningOperationComponent } from './app-long-running-operation-status';
// import { LongRunningOperationService } from '../http/long-running-operation.service';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LongRunningOperationStatusService {
  dialogRef: MatDialogRef<LongRunningOperationComponent>;

  private longRunningOperationStatus = null;
  pollingObservable = null;
  busyPolling = false;
  pollInterval = 2500;

  constructor(
    private dialog: MatDialog,
    // private lropService: LongRunningOperationService,
    private translate: TranslateService) { }

  public open(lrop) {

    this.dialogRef = this.dialog.open(LongRunningOperationComponent, {disableClose: true});
    this.dialogRef.updateSize('400px');

    this.updateStatus(lrop);
    this.initiatePolling();

    return this.dialogRef.afterClosed();
  }

  initiatePolling(){
    // TODO new LRO setup
    // this.pollingObservable = interval(this.pollInterval)
    //     .pipe(switchMap(() => this.lropService.get(this.longRunningOperationStatus.Id)))
    //     .subscribe(status => {
    //       this.updateStatus(status);
    //     });
  }

  cancelPolling() {

    this.busyPolling = false;

    if (this.pollingObservable !== null) {
      this.pollingObservable.unsubscribe();
    }
  }

  updateStatus(lrop) {

    this.longRunningOperationStatus = lrop;

    var title = '';

    switch (this.longRunningOperationStatus.OperationStatusId) {
      case 1:
        title = this.translate.instant('MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.TITLE.WAIT-FOR-EXECUTION');
        break;

      case 2:
        title = this.translate.instant('MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.TITLE.PREPARING');
        break;

      case 3:

        title = this.translate.instant('MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.TITLE.EXECUTING') + this.longRunningOperationStatus.Message;
        break;

      case 4:
        title = this.translate.instant('MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.TITLE.EXECUTED');
        this.cancelPolling();
        break;

      case 5:
        title= this.translate.instant('MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.TITLE.FAILED') + this.longRunningOperationStatus.Message;
        this.cancelPolling();
        break;
    }

    this.dialogRef.componentInstance.title = title;

    this.dialogRef.componentInstance.status = this.longRunningOperationStatus.OperationStatusId;
    this.dialogRef.componentInstance.progressMode = this.longRunningOperationStatus.OperationStatusId === 3 ? "determinate" : "query";

    this.dialogRef.componentInstance.progress =
      this.longRunningOperationStatus.ProgressPercentage !== null ? this.longRunningOperationStatus.ProgressPercentage : 0;

  }



  public close() {

    if(this.dialogRef)
      this.dialogRef.close();
  }
}
