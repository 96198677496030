import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { ReservationTypeModel, ReservationTypeService } from "../api";

@Injectable()
export class ReservationTypeValidator {

    constructor(private reservationTypeService: ReservationTypeService) {}

    private debouncer: any;
    public editingObject : any;


    codeIsTaken = (control: FormControl) => {
        //do not hammer the service
        clearTimeout(this.debouncer);

        return new Promise(resolve => {

            this.debouncer = setTimeout(() => {

                if(control.value === '') {
                    resolve(null);
                }
                else {

                    this.reservationTypeService.getReservationTypeByCode(control.value.trim()).subscribe((data: ReservationTypeModel) => {
                        if (data !== null && this.editingObject.Id != data.Id) {
                          resolve({"codeTaken": data});
                        }
                        else {
                            resolve(null);
                        }
                    });
                }

            }, 1000);
        });
    }
}
