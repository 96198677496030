import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmService, DialogHelper, DialogWidth, FileService, HttpLoaderService, ListViewSelectionMode, SnackbarService } from '@tymes4-shared';
import { EditMediaFormComponent } from '../../dialogs/edit-media-form/edit-media-form.component';
import { UploadMultipleImagesComponent } from '../../dialogs/upload-multiple-images/upload-multiple-images.component';
import { TranslateService } from '@ngx-translate/core';
import {CrossSellProductService, ImageService, MediaViewPagedResult} from "../../api";

@Component({
 selector: 't4-images-listview',
 templateUrl: 'images-listview.component.html'
})

export class ImagesListviewComponent implements OnInit {

    public objectCount = 0;
    public cpId = null;
    public inMerchandiseMode = false;

    @Output() onImageChange = new EventEmitter();

    @Input()
    set crossSellProductId(id) {
      this.cpId = id;
      if (id !== null) this.inMerchandiseMode = true;
    }

  // for listview
  @ViewChild('listVw') listVw;

  public listOptions : any= {
    search: true,
    pageSize: 12,
    noItemsText: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.NO-ITMES-TEXT.NO-MEDIA-FOUND'),
    searchTitle: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.SEARCH-TITLE.SEARCH-FOR-MEDIA'),
    multiSelectMode: false,
    selectionMode: ListViewSelectionMode.None,
    headers: []
  };

  // end for listview

  constructor(
    private imageService: ImageService,
    private loader: HttpLoaderService,
    private snackBar: SnackbarService,
    public fileHelper: FileService,
    private dialog: MatDialog,
    private crossSellProductService: CrossSellProductService,
    private confirmService: ConfirmService,
    private translate: TranslateService) {
  }

  ngOnInit() {

      if (this.inMerchandiseMode === true) {
        this.listOptions.headers.push({sortField: 'CrossSellProductDisplayOrder', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.ORDER'), width: 120, isDefaultSort: 'asc'});
        this.listOptions.headers.push({label: '', width: 60});
      }
      else {
        this.listOptions.headers.push({sortField: 'Id', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.ID'), width: 60});
      }

      this.listOptions.headers.push({sortField: this.inMerchandiseMode ? null : 'Created', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.CREATED'), width: 140});
      this.listOptions.headers.push({sortField: null, label: '', width: 40});
      this.listOptions.headers.push({sortField: this.inMerchandiseMode ? null : 'Description', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.DESCRIPTION'), isDefaultSort: this.inMerchandiseMode ? null : 'asc'});
      this.listOptions.headers.push({sortField: null, label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.TYPE'), width: 200,});
      this.listOptions.headers.push({sortField: this.inMerchandiseMode ? null : 'Size', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.SIZE'), width: 120});
      this.listOptions.headers.push({sortField: this.inMerchandiseMode ? null : 'MimeType', label: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.LISTVIEW.LABEL.MIMETYPE'), width: 230});
      this.listOptions.headers.push({label: ''});

  }

  openWindow(url) {
    window.open(url, "_blank");
  }

  reOrder(direction, imageId) {
    this.crossSellProductService.crossellProductReorderImage(imageId, direction).subscribe((data: any) => {
      this.onImageChange.emit();
      this.listVw.refreshData();
    });

  }
  OnDataRequest($event) {

    if (this.cpId !== null) var filter = {['crossSellProductId']: this.cpId};


    this.imageService.mediaSearch($event.searchText, $event.pageNr, $event.pageSize, $event.sortField, $event.sortAsc, filter).subscribe((resp: MediaViewPagedResult) => {
      this.listVw.Data = resp;
    });
  }

  actionClick(action, item: any = null) {
    if (action === 'add') {
      this.openDialog(null, true);
    } else if (action === 'edit') {
      this.openDialog(item, false);
    } else if (action === 'delete') {
      this.openConfirmDialog(item);
    }
  }

  openConfirmDialog(item: any = {}) {

    this.loader.open();

    this.imageService.isImageInUse(item.Id).subscribe(inUse =>{

      this.loader.close();

      if (inUse === true && this.inMerchandiseMode === false) { //in merchandise mode we can always delete
        //not possible
        this.confirmService.confirm({ title: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.CONFIRM-SERVICE.TITLE.REMOVAL-NOT-POSSIBLE'),
              message: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.CONFIRM-SERVICE.MESSAGE.REMOVAL-NOT-POSSIBLE'),
              okonly: true}).subscribe((confirmed:boolean) => {});
      }
      else
      {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
          message: this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.CONFIRMSERVICE.MESSAGE.ARE-YOU-SURE-DELETE', {ItemFileName: item.FileName}),
          okonly: false
        }).subscribe((confirmed: boolean) => {
          if (confirmed === true) {
            this.imageService.deleteImage(item.Id).subscribe((data: any) => {
              // close this dialog and pass the newly added id to the called
              this.onImageChange.emit();
              this.listVw.refreshData();
              this.snackBar.open('GENERIC.SNACKBAR.ITEM-IS-DELETED', 'GENERIC.SNACKBAR.CLOSE');
            });
          }
        });
      }
    });
  }

  openDialog(data: any = {}, isNew?) {
    const title = this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.OPEN-DIALOG.TITLE.EDIT-MEDIA')

    const options = DialogHelper.GetDialogOptions(DialogWidth.md, {title: title, editItem: data});
    const dialogRef: MatDialogRef<any> = this.dialog.open(EditMediaFormComponent, options);

    dialogRef.afterClosed()
      .subscribe(id => {

          if (id !== false) {
            this.listVw.refreshData();
            this.snackBar.open('GENERIC.SNACKBAR.CHANGES-SAVED', 'GENERIC.SNACKBAR.CLOSE');
          }
        }
      );
  }

  displayUploadDialog() {

    const title = this.translate.instant('COMPONENTS.IMAGES-LISTVIEW.TITLE.UPLOAD-IMAGE')
    const options = DialogHelper.GetDialogOptions(DialogWidth.md, {title: title, crossSellProductId: this.cpId });
    const dialogRef: MatDialogRef<any> = this.dialog.open(UploadMultipleImagesComponent, options);

    dialogRef.afterClosed()
      .subscribe(id => {

          if (id !== false) {
            this.onImageChange.emit();
            this.listVw.refreshData();
            this.snackBar.open('GENERIC.SNACKBAR.IMAGES-UPLOADED', 'GENERIC.SNACKBAR.CLOSE');
          }
        }
      );
  }


}
