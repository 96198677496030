<form [formGroup]="form">
  <div class="row">
    <div class="col-lg-6">
      <div class="card card-table p-2 order-height">
        <div class="row">
          <div class="col-lg-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span (click)="updateFilter($event)" class="input-group-text bd-0"><i class="fas fa-search"></i></span>
              </div>
              <input (keydown.enter)="updateFilter($event)" formControlName="SearchText" class="form-control bd-0"  placeholder="{{'SALES.MATCHDAYPASS.SELECTION.PLACEHOLDER.SEARCH' | translate}}" type="text">
            </div>
          </div>
        </div>
        <div>
          <table class="table table-striped">
            <ng-container *ngIf="listMdps.length > 0; else noresult">
              <tbody>
                <tr *ngFor="let e of listMdps" class="clickable list-item" [class.selected-list-item]="isItemSelected(e)" (click)="toggleSelection(e)" >
                  <td style="vertical-align: middle; width: 32px;"><input type="checkbox" [checked]="isItemSelected(e)" /> </td>
                  <td class="px-0 py-3">
                    <a class="text-dark" style="word-break: break-all; white-space: normal;">{{e.Name}}</a>
                    <div>{{e.MaxTicketsCount}}</div>
                  </td>
                  <td class="text-right" style="vertical-align: middle;">
                    <img *ngIf="e.ImageUrl" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(e.ImageUrl, 32, 32)">
                  </td>
                </tr>
              </tbody>
            </ng-container>
            <ng-template #noresult>
              <tbody>
                <tr><td>{{'GENERIC.LISTVIEW.NO-ITEMS.TEXT' | translate}}</td></tr>
              </tbody>
            </ng-template>
          </table>
        </div>
        <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doPage($event)" #pagerComponent></t4-pager>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card order-height h-100" style="min-height: 450px;">
          <div class="card-header">
              <h6>{{'SALES.ORDERS.NEW.SALES-MATCHDAYPASS-SELECTION.HEADER.SELECT-A-SECTOR' | translate}}</h6>
          </div>
          <div class="mx-2 mt-2" *ngIf="listSectors.length > 0; else noresultvariant">
              <table class="table table-striped">
                  <ng-container >
                      <tbody>
                          <tr *ngFor="let e of listSectors" class="list-item" (click)="selectSector(e)">
                              <td class="text-dark">{{e.Name | translate}}</td>
                          </tr>
                      </tbody>
                  </ng-container>
              </table>
          </div>

          <t4-pager [pageSize]="pageSize" [selectCount]="null" (onPage)="doSectorPage($event)" #sectorPagerComponent></t4-pager>

          <ng-template #noresultvariant>
              <div class="p-5">{{'SALES.ORDERS.NEW.SALES-MATCHDAYPASS-SELECTION.MSG.SELECT-MDP-TO-SHOW-SECTORS' | translate}}</div>
          </ng-template>
      </div>
    </div>
  </div>
</form>
