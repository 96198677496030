<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>


  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">

            <div class="col-lg-6">
                <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.GENERATE-DISCOUNT-CODES.FORM.LABEL.AMOUNT-OF-CODES' | translate}}<span class="tx-danger">*</span></label>
                <input class="form-control" name="Count" formControlName="Count" placeholder="{{'DIALOGS.GENERATE-DISCOUNT-CODES.FORM.PLACEHOLDER.AMOUNT-OF-CODES' | translate}}" type="number">
                <t4-display-validation controlName="Count" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>

            <div class="col-lg-6" *ngIf="passedData.askAmount">
                <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.GENERATE-DISCOUNT-CODES.FORM.LABEL.AMOUNT' | translate}}</label>
                <input class="form-control" name="CodeAmountPerc" formControlName="CodeAmountPerc" [placeholder]="passedData.defaultAmount" type="number">
                <t4-display-validation controlName="CodeAmountPerc" [form]="form" [messages]="validationMessages"></t4-display-validation>
                <span class="mb-3 d-block">
                  {{'DIALOGS.GENERATE-DISCOUNT-CODES.FORM.SPAN.OVERWRITE-STANDART-AMOUNT' | translate}}
                </span>
                </div>
            </div>
        </div>

        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{'DIALOGS.GENERATE-DISCOUNT-CODES.FORM.BUTTON.GENERATE-CODES' | translate}}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>

        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
  </form>
