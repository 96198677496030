
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>



<form [formGroup]="form" class="form-content">

    <div class="section-wrapper">
      <div class="form-layout">

        <div class="row">

            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.TYPE' | translate}} <span class="tx-danger">*</span></label>
                    <ng-select (change)="onTypeChange($event)" [items]="constants.venueBuildingBlockTypes" bindLabel="Name" bindValue="Id" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.TYPE' | translate}}" formControlName="VenueBuildingBlockTypeId"
                               [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" ></ng-select>
                    <t4-display-validation controlName="VenueBuildingBlockTypeId" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>



            <div class="col-lg-6" >
                <div class="form-group"  *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value == 1">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.VERSION' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="Version" formControlName="Version" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.VERSION' | translate}}" [disabled]="editingEventPlacement || hasPlacements">
                </div>

                <div class="form-group" *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value != 1">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.UPPER-SECTION' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control" [matAutocomplete]="auto" formControlName="Parent">
                    <t4-display-validation controlName="Parent" [form]="form" [messages]="validationMessages"></t4-display-validation>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getSelectedVenueDisplayText">
                        <mat-option *ngFor="let pi of filteredParentVenues" [value]="pi">
                            {{ getVBBName(pi) }}
                        </mat-option>
                    </mat-autocomplete>

                </div>

            </div>

        </div>


        <div class="row">

            <div class="col-lg-6" >
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.NAME' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="Name" formControlName="Name" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.NAME' | translate}}">
                    <small *ngIf="form.controls['Name'].hasError('required') && form.controls['Name'].touched" class="form-error-msg">{{'GENERIC.VALIDATION.REQUIRED' | translate}}</small>
                </div>
            </div>

            <div class="col-lg-6">

                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.SHORTEN-NAME' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="ShortName" formControlName="ShortName" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.SHORTEN-NAME' | translate}}">
                    <small style="color: #4D4D4D;" *ngIf="this.editingEventPlacement && form.get('ShortName').dirty">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.WARNING.SHORTEN-NAME' | translate}}</small>
                </div>

            </div> 

        </div>


        <div class="row">
            <div class="col-lg-6" >
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.TRIBUNE-NUMBER' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="SectionNr" formControlName="SectionNr" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.TRIBUNE-NUMBER' | translate}}">
                    <small *ngIf="form.controls['SectionNr'].hasError('required') && form.controls['SectionNr'].touched  " class="form-error-msg">{{'GENERIC.VALIDATION.REQUIRED' | translate}}</small>
                    <small *ngIf="form.controls['SectionNr'].hasError('pattern') && form.controls['SectionNr'].touched  " class="form-error-msg">{{'GENERIC.VALIDATION.ONLY-NUMBERS' | translate}}</small>
                </div>
            </div>


          <div class="col-lg-6" >
            <div class="form-group">
                <label class="form-control-label">{{'LOCATION.SECTION.TICKETNAMINGTYPE' | translate}}</label> <span class="tx-danger">*</span>
                            <ng-select  [items]="ticketNamingType" bindLabel="Name" bindValue="TicketNamingType" formControlName="TicketNamingType" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.TRIBUNE-ENTRENCE-TYPE' | translate}}"
                             [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                            <t4-display-validation controlName="TicketNamingType" [form]="form" [messages]="validationMessages"></t4-display-validation></div>
        </div>
        </div>
        <div class="row" *ngIf="!this.editingEventPlacement">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.TRIBUNE-COLOR' | translate}} </label>
              <div>
                <div fxFlex="30px"><div class="color-picker-display" [(colorPicker)]="color" [style.background]="color" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'">&nbsp;</div></div>
                <div class="pt-1" fxFlex>{{ color }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value == 1">

            <div class="col-lg-10" >
                <div class="form-group"  >
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.VENUE' | translate}} <span class="tx-danger">*</span></label>

                    <ng-select [items]="stadiumPlanSections" bindLabel="Name" bindValue="Id"
                               placeholder="Type" formControlName="StadiumPlanSectionId"
                               [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

                        <t4-display-validation controlName="StadiumPlanSectionId" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
          <div class="col-lg-2" style="margin-top: 29px;">
            <div class="form-group">
              <button (click)="openVenueSvgInputDialog('StadiumPlanSectionId')" class="btn btn-block btn-primary bd-0">+</button>
            </div>
          </div>
        </div>


        <div class="row">

  
            <div class="col-lg-6" >
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.SALES-CATEGORY' | translate}} <span class="tx-danger">*</span></label>
                    <ng-select [items]="selectableSaleCategories" bindLabel="Name" bindValue="Id" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.SALES-CATEGORY' | translate}}" formControlName="SaleCategoryId"
                               [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                    <small *ngIf="form.controls['SaleCategoryId'].hasError('SaleCategoryId') && form.controls['SaleCategoryId'].touched" class="form-error-msg">{{'GENERIC.VALIDATION.REQUIRED' | translate}}</small>
                </div>
            </div>


            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.PRICE-CATEGORY' | translate}} <span class="tx-danger">*</span></label>
                    <ng-select [items]="selectablePriceCategories" bindLabel="Name" bindValue="Id" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.PRICE-CATEGORY' | translate}}" formControlName="PriceCategoryId"
                               [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                    <small *ngIf="form.controls['PriceCategoryId'].hasError('PriceCategoryId') && form.controls['PriceCategoryId'].touched" class="form-error-msg">{{'GENERIC.VALIDATION.REQUIRED' | translate}}</small>
                </div>
            </div>

          <div class="col-lg-10" *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value == 1">
            <div class="form-group">
              <label class="form-control-label">{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.LABEL.SOFTCARD-IMAGE' | translate}}</label>
              <ng-select [items]="images" bindLabel="Description" bindValue="Id" formControlName="ImageId" placeholder="{{'LOCATION.LOCATIONS.EDITDIALOG.FORM.PLACEHOLDER.SOFTCARD-IMAGE' | translate}}"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div style="display: flex; align-items: center">
                    <div style="width:32px; height:32px; margin-right: 16px;">
                      <img [src]="fileHelper.getResizedImageUrl(item.Url, 32, 32)" style="margin: 0 auto; display: block;"/>
                    </div>
                    <span>{{ item.Description }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="col-lg-2" style="margin-top: 29px;" *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value == 1">
            <div class="form-group">
              <button (click)="openFileInputDialog('ImageId')" class="btn btn-block btn-primary bd-0">+</button>
            </div>
          </div>
        </div>


        <div class="form-layout-footer row">
          <div class="col-6">
            <button *ngIf="!isInArchiveMode" (click)="submit()" [disabled]="form.invalid || saving"  class="btn btn-block btn-primary bd-0"> <i class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->

      </div><!-- form-layout -->

    </div>
  </form>



    <br />
<!--
    <t4-page-info info='Maak een nieuwe sectie of locatie aan. Locaties worden altijd op het hoogste niveau toegevoegd en kunnen worden voorzien van een versie.'></t4-page-info>
 -->



  <form [formGroup]="form" class="form-content">


    <div fxLayout="row" fxLayoutWrap="wrap" class="form-group">
        <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">

            <!-- left page controls. -->




                <div class="pb-1">

                </div>


            <div class="pb-1">

            </div>


        </div>

            <!-- right page controls. -->
        <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">






        </div>

            <!-- right page controls. -->
      <div fxFlex="100%" class="pb-1">


        <div fxFlex="50%" class="pr-1">


        </div>

        <div fxFlex="50%">


        </div>

      </div>


    </div>

  </form>
