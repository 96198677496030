

<div class="card card-default" *ngIf="event !== null">
    <div class="card-header pb-5">
        <h2>{{ event.StartDateTime | localDateTime | slice:0:10 }}: {{ event.Name }}</h2>
    </div>
</div>

<div class="card card-default" *ngIf="event === null && location !== null">
    <div class="card-header pb-5">
        <h2>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.TITLE' | translate}} {{location.Name}} ({{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.VERSION' | translate}}{{location.Version}})</h2>
    </div>
</div>



<div class="row">
    <div class="col-lg-4 col-md-12">
        <app-section-selector
            (onSelected)="onVenueBuildingBlockSelected($event)"
            #sectionSelectorComponent>
        </app-section-selector>
    </div>


    <div class="col-lg-8 col-md-12">

        <div class="card card-default" *ngIf="(editingVBB != null)">

            <div class="publish-warning bg-primary" *ngIf="hasUnpublishedChanges === true">
                <div class="row">
                    <div class="col-lg-7">
                      {{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.WARNING.UNPLUBISHED' | translate}} {{location.Name}}
                    </div>
                    <div class="col-lg-5" style="text-align: right;">

                        <t4-lro-button (onLROClick)="undo()" cssClasses="btn btn-danger border btn-small mr-2" [icon]="'fas fa-undo'" [text]="'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.BUTTON.UNDO'" [lroTypeCode]="longRunningOperationTypeEnum.ApplyPendingVbbChanges"></t4-lro-button>
                        <t4-lro-button (onLROClick)="publish()" (onLROComplete)="progressFinished()" cssClasses="btn btn-warning border btn-small" [icon]="'fas fa-upload'" [text]="'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.BUTTON.PUBLISH'" [lroTypeCode]="longRunningOperationTypeEnum.ApplyPendingVbbChanges"></t4-lro-button>

                    </div>
                </div>

            </div>


            <div class="card-header" >
                <h2>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TITLE' | translate}} {{ editingVBB | venueTitle | uppercase }}</h2>
            </div>


            <div class="card-body">

                <div class="object-actions mt-3">

                    <div class="row">
                        <div class="col-lg-3" style="text-align: left;">
                            <button *ngIf="this.eventPlacementEventId === null" style="margin-left: 0px;" (click)="openChangeLog()" [disabled]="nrOfChangeLogs == 0" class="btn btn-primary border btn-small" type="button"><i class="far fa-edit"></i>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.BUTTON.CHANGES' | translate}} ({{ nrOfChangeLogs }})</button>
                            <span *ngIf="this.eventPlacementEventId === null" class="separator"></span>
                        </div>
                        <div class="col-lg-9">
                            <button *ngIf="this.eventPlacementEventId === null && !isInArchiveMode" [disabled]="editingVBB.HasPlacements" (click)="copySection()" class="btn btn-primary border btn-small" type="button"><i class="far fa-edit"></i>{{'GENERIC.BUTTON.COPY' | translate}}</button>
                            <button (click)="editDetails()" class="btn btn-warning border btn-small" type="button"><i class="far fa-edit"></i>{{'GENERIC.BUTTON.EDIT' | translate}}</button>
                            <span *ngIf="this.eventPlacementEventId === null && !isInArchiveMode" class="separator"></span>
                            <button *ngIf="this.eventPlacementEventId === null" (click)="deleteVBB()" [disabled]="editingVBB.HasPlacements"  class="btn btn-danger border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'GENERIC.BUTTON.REMOVE' | translate}}</button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


        <div class="card"  *ngIf="(editingVBB != null)" >

            <div>
                <t4-tabs (selected)="onTabChange($event)">
                    <t4-tab tabTitle="{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TAB.TICKET-POSTITION' | translate}}" id="positions"></t4-tab>
                    <t4-tab tabTitle="{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TAB.ENTRANCES' | translate}}" id="entrances"></t4-tab>
                </t4-tabs>
            </div>

        </div>

        <ng-container *ngIf="editingVBB !== null">

            <ng-container *ngIf="currentTabId == 'positions'">

                <div class="row mt-5">
                    <div class="col-lg-3 col-md-12">
                        <div class="card card-default mb-0">
                            <div class="card-header">
                                <h2>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.TITLE' | translate}}</h2>
                            </div>

                            <div class="card-body pt-0">
                                <ul class="nav nav-settings">
                                <li class="nav-item" *ngFor="let ticketType of ticketTypes; let i = index">
                                    <a (click)="selectTicketType(ticketType, i)" [class.active]="ticketTypeIsSelected(ticketType)" class="clickable nav-link">
                                    <i class="mdi mdi-account-outline mr-1"></i> {{ ticketType.Name }}
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12">

                        <div class="card card-default mb-0" *ngIf="selectedTicketType !== null">
                            <div class="card-header">
                                <h2>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.CARD-TYPES' | translate}} {{selectedTicketType.Name}}</h2>
                            </div>

                            <div class="card-body pt-0">

                                <ng-container *ngIf="tabIndex >= 0">

                                         <!-- vbb has seats-->
                                <div *ngIf="endpointsAvailable(tabIndex)">

                                    <!-- Placed endpoint / placement grid -->
                                    <div class="endpoints-places" *ngIf="selectedTicketType.IsPlaced == 1">


                                        <div class="object-actions mt-3">

                                            <button class="btn btn-primary border btn-small" title="{{'COMPONENTS.VENUE-PLACEMENTS-EDITOR.BUTTON.TITLE.EDIT-SEATPLAN' | translate}}" (click)="openSeatPlan(tabDetails[tabIndex].endpoints)" >
                                                <i class="far fa-map"></i>
                                                <span>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.CARD-TYPES.BUTTON.OPEN-SEATPLAN' | translate}}</span>
                                            </button>

                                            <span class="separator"></span>

                                            <button class="btn btn-primary border btn-small" [disabled]="this.eventPlacementEventId !== null" title="{{'COMPONENTS.VENUE-PLACEMENTS-EDITOR.BUTTON.TITLE.CURRENT-SELECTION-SLASH-MANAGA-LOCATION' | translate}}" (click)="addTickets(selectedTicketType, tabIndex, unplacedTicketList)" >
                                                <i class="far fa-edit"></i>
                                                <span>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.CARD-TYPES.BUTTON.EDIT-AMOUNT' | translate}}</span>
                                            </button>

                                        </div>

                                        <p class="mt-3">
                                          {{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.CARD-TYPES.CARDS-PLACED' | translate}}
                                        </p>

                                    </div>
                                    <!-- End placed endpoint / placement grid -->

                                    <!-- Placed unplaced / placement grid -->
                                    <div class="endpoints-places" *ngIf="selectedTicketType.IsPlaced == 0">
                                        <app-unplaced-ticket-list #unplacedTicketList [eventId]="eventPlacementEventId" (onRefreshRequired)="onUnplacedTicketRefreshRequired($event)" [parent]="editingVBB" [ticketType]="selectedTicketType" [endpoints]="tabDetails[tabIndex].endpoints"></app-unplaced-ticket-list>
                                    </div>
                                    <!-- End placed endpoint / placement grid -->

                                </div>
                                <!-- end vbb has seats-->

                                <div *ngIf="!endpointsAvailable(tabIndex)">


                                    <div class="object-actions mt-3">

                                        <button *ngIf="!isInArchiveMode" class="btn btn-primary border btn-small" [disabled]="this.eventPlacementEventId !== null" (click)="addTickets(selectedTicketType, tabIndex, null)"  >
                                            <i class="fa fa-plus-square"></i>
                                            <span> {{'GENERIC.BUTTON.ADDNEW' | translate}}</span>
                                        </button>



                                    </div>

                                    <p class="mt-3">
                                      <span> {{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.CARD-TYPES.NO-CARDS-AVAILABLE' | translate}}</span>
                                    </p>

                                </div>

                                </ng-container>


                            </div>
                        </div>

                    </div>
                </div>


            </ng-container>

            <ng-container *ngIf="currentTabId == 'entrances'">

                <!-- BEGIN entrances -->

                <div class="card card-default">

                    <div class="card-body">

                        <form [formGroup]="entranceForm">

                            <div fxFlex  class="pr-1" >

                                <div class="form-group">
                                    <input style="min-width: 400px;" class="form-control" placeholder="{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.TICKET-TYPE.ENTRENCES-SEARCH' | translate}}" [matAutocomplete]="auto" formControlName="entranceSearch">
                                </div>

                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="Name" (optionSelected)="onEntranceAdd($event)">
                                    <mat-option  *ngFor="let e of filteredEntrances" [value]="e">
                                        {{ e.Name }}
                                    </mat-option>
                                </mat-autocomplete>

                            </div>
                        </form>

                        <div class="table-responsive bg-white">

                            <table class="rounded table text-dark table-striped clickable">

                            <thead>
                                <td>{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.ENTRENCES.HEADER.NAME' | translate}}</td>
                                <td style="width:150px" *ngIf="inSingleEntranceMode">{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.ENTRENCES.HEADER.STANDARD' | translate}}</td>
                                <td>&nbsp;</td>
                            </thead>

                                <tbody>
                                    <ng-container *ngIf="vbbEntrances !== null && vbbEntrances.length == 0; else entranceLines">
                                        <tr>
                                            <td colspan="3">{{'LOCATION.VENUE-DEFINITION.VENUE-PLACEMENT.SECTION.ENTRENCES.NOT-LINKED' | translate}}</td>
                                        </tr>
                                    </ng-container>

                                    <ng-template #entranceLines>
                                        <tr *ngFor="let entrance of vbbEntrances">
                                            <td>{{entrance.Name}}</td>
                                            <td *ngIf="inSingleEntranceMode">
                                                <span *ngIf="entrance.IsDefault === false"></span>
                                                <i *ngIf="entrance.IsDefault === true" class="far fa-check-circle"></i>

                                            </td>

                                            <td class="grid-action pt-1" >
                                                <div style="padding-top: 5px; ">
                                                    <ng-container >
                                                        <button  *ngIf="inSingleEntranceMode && !isInArchiveMode" [disabled]="entrance.IsDefault === true"  (click)="markSelectedAsDefault(entrance)" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-check-circle-outline"></button>
                                                        <button *ngIf="!isInArchiveMode" (click)="deleteEntrance(entrance)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
                                                    </ng-container>
                                                </div>
                                            </td>

                                        </tr>
                                    </ng-template>
                                </tbody>

                            </table>

                        </div>

                    </div>


                </div>

                <!-- END Entrances-->

            </ng-container>

        </ng-container>


        <!-- TODO -->

        <!-- <ng-container *ngIf="eventPlacementEventId !== null && editingVBB !== null && editingVBB.DiffersFromVBB === true">
            <div class="alert-box" fxLayout="row" fxLayoutWrap="wrap">
                <div class="alert-msg" fxFlex>
                    <b>Let op:</b> Deze sectie wijkt af van de locatiedefinitie.
                </div>
                <div fxFlex="100px" class="alert-action">
                    <button class="btn btn-default" (click)="resetToDefinition()" >
                        <i class="fas fa-undo-alt"></i>
                        <span>Herstellen</span>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
         -->
        <!--- EIND TODO-->
    </div>
</div>





