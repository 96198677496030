import {AbstractControl, FormControl} from '@angular/forms';
import {Injectable} from '@angular/core';
import { ContactService } from '../api';

@Injectable()
export class ContactValidator {

  constructor(private contactService: ContactService) {
  }

  private debouncer: any;
  public currentEmail: string;

  emailTaken = (control: AbstractControl) => {

    if (control.setErrors === undefined) {
      return;
    }
    // do not hammer the service
    clearTimeout(this.debouncer);

    return new Promise(resolve => {

      this.debouncer = setTimeout(() => {

        if (!control.dirty && control.value !== '') {
          // control.setErrors(null);
          resolve(null);
        } else {
          if (control.value === this.currentEmail || control.value === '') {
            resolve(null);
          } else {

            this.contactService.contactEmailIsInUse(control.value.trim()).subscribe(data => {

              if (data === true) {
                // control.setErrors({'emailTaken': true});
                resolve({'emailTaken': data});
              } else {
                // control.setErrors(null);
                resolve(null);
              }
            });
          }
        }

      }, 1000);
    });
  }
}
