<!-- MODAL HEADER-->
<div style="overflow: hidden;">
  <div class="row modal-bar">

  <div class="col-sm-6">
      <h5 class="modal-title">{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.TITLE' | translate }}</h5>
  </div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
  </div>

  <form class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row">
        <div class="col-lg-3">

          <div *ngIf="startedIdentification; else identificationMessage" >

            <div>
              <div class="alert alert-success text-format" *ngIf="isVerified">
                <span>{{ getStatusText(identificationStatus.Status) | translate }}</span>
              </div>
              <div class="alert alert-warning text-format" *ngIf="!isVerified && !isRejected()">
                <span>{{ getStatusText(identificationStatus.Status) | translate }}</span>
              </div>
              <div class="alert alert-danger text-format" *ngIf="!isVerified && isRejected()">
                <span>{{ getStatusText(identificationStatus.Status) | translate }}</span>
              </div>
            </div>
  
            <br>
  
            <div class="row">
              <div class="col-lg-3">
                <span>{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.CUSTOMER' | translate }}</span>
              </div>
              <div class="col-lg-9">
                <span><b><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel()"></t4-customer-identification-control></b></span>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-3">
                <span>{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.METHOD' | translate }}</span>
              </div>
              <div class="col-lg-9">
                <b>{{ identificationStatus.CustomerIdentificationType }}</b>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-3">
                <span>{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.BY' | translate }}</span>
              </div>
              <div class="col-lg-9">
                <b>{{ identificationStatus.UserCalculatedName }}</b>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-3">
                <span>{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.REASON' | translate }}</span>
              </div>
              <div class="col-lg-9">
                <b>{{ identificationStatus.Reason ? identificationStatus.Reason : '-' }}</b>
              </div>
            </div>

          </div>

          <ng-template #identificationMessage>
              <span>{{ 'RELATIONS.MANUAL-IDENTIFICATION-DIALOG.IDENTIFICATION-NOT-STARTED' | translate }}</span>
          </ng-template>

          <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">

          <div *ngIf="!isVerified && !pendingActionOfEmployee">
            <button (click)="verifyCustomer()"  class="btn btn-block btn-success bd-0">
              <i class="far fa-check-square"></i><span>{{ 'GENERIC.BUTTON.IDENTIFICATION.IDENTIFY' | translate }}</span>
            </button>
          </div>
          <br>
          <div>
            <button (click)="rejectCustomer()" class="btn btn-block btn-danger bd-0">
              <i class="fas fa-redo"></i><span>{{ 'GENERIC.BUTTON.IDENTIFICATION.RESTART' | translate }}</span>
            </button>
          </div>

        </div>
        <div class="col-lg-9">
          <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">
          
            <ng-container *t4ListItem="let item">
          
              <td>{{ item.Created | localDate }}</td>
              <td>{{ item.Created | localTime }}</td>
              <td>{{ getStatusText(item.Status) | translate }}</td>
              <td>{{ item.UserCalculatedName }}</td>
              <td>{{ item.CustomerIdentificationType }}</td>
              <td>{{ item.Reason }}</td>
          
            </ng-container>
          
          </t4-list-view>
        </div>
      </div>
    </div><!-- form-layout -->
  </div>  
  </form>
</div>