import { Component, OnInit, Input, ViewChildren, EventEmitter, Output } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { EventPlacementService } from '../../../api';


@Component({
  selector: 'app-sales-unplaced-ticket-selection',
  templateUrl: './sales-unplaced-ticket-selection.component.html',
  styleUrls: ['./sales-unplaced-ticket-selection.component.scss']
})
export class SalesUnplacedTicketSelectionComponent implements OnInit {

  constructor(private loader: HttpLoaderService,
              private eventPlacementService : EventPlacementService) { }

  @ViewChildren('amountInput') amountInputs;
  @Output() onSelectionChange = new EventEmitter<object>();

  public hasMultiplePrices = false;
  public ticketPrices = [];



  selectedOrderLines: any[];
  selectedSellableSection: any = {};
  selectedEventIds: any = [];
  selectedTicketType: any = {};
  isLoaded = false;
  selectedPassePartouts = null;

  typeAvailability : any =[];
  //typeAmountSelection = [];

  amountPerPriceVariantAndContingent = null;

  pageSize = 10;
  totalObjectCount: number;

  @Input()
  set section(s: any) {
    this.selectedSellableSection = s;

  }

  @Input()
  set ticketType(t: any) {
    this.selectedTicketType = t;

  }

  @Input()
  set events(e:any) {
    this.selectedEventIds = e;
  }

  @Input()
  set passePartouts(e:any) {
    this.selectedPassePartouts = e;
  }

  @Input()
  set orderlineSelection(e: any) {
    this.selectedOrderLines = e;
  }

  ngOnInit() {

      //not yet fetched and required
      this.loader.open();

      //retrieve the state of tickets
      var p = {
        EventIds: this.selectedEventIds,
        ParentVBBId: this.selectedSellableSection.VenueBuildingBlockId,
        GroupingId: this.selectedSellableSection.GroupingId
      }

      this.eventPlacementService.getUnplacedAvailability(p).subscribe((data: any) => {

        this.typeAvailability = data;
        this.initializeSelection();

        this.loader.close();
      });

    this.isLoaded = true;
  }

  findInitialValue(contingentId, priceVariantId) {

    for (let a of this.amountPerPriceVariantAndContingent)
    {
      if (a.contingentId == contingentId && a.priceVariantId == priceVariantId)  {
        return a.selectedAmount;
      }
    }

    return -1; //should not be possible
  }

  initializeSelection() {

    if (this.typeAvailability == null || this.typeAvailability.Availability == null || this.typeAvailability.CombinedPrices == null) return;

    this.amountPerPriceVariantAndContingent = [];
    for (let av of this.typeAvailability.Availability) {
        for (let pc of this.typeAvailability.CombinedPrices) {

          let selectedCount = this.getCountEarlierSelected(this.typeAvailability.TicketTypeId, av.ContingentId, pc.PriceVariantId);

          this.amountPerPriceVariantAndContingent.push({
            selectedAmount: selectedCount,
            contingentId: av.ContingentId,
            priceVariantId: pc.PriceVariantId,
            ticketTypeId: this.typeAvailability.TicketTypeId
          });
        }
      }

  }

  getCountEarlierSelected(ticketTypeId, contingentId, priceVariationId)
  {
    let result = Number.MAX_VALUE;
    if (this.selectedOrderLines == null || this.selectedOrderLines.length == 0) return 0;

    //this.selectedOrderLines => contains only the orderlines in this section.
    var ols = this.selectedOrderLines.filter(function (ol)   {
      if (ol.TicketTypeId === ticketTypeId && ol.ContingentId == contingentId && ol.PriceVariationId == priceVariationId) return ol;
    });

    if (ols.length == 0) return 0;

    //we need the least amount of items for this tickettype to be displayed
    if (this.selectedPassePartouts != null && this.selectedPassePartouts.length > 0) {

      //handle the passepartouts
      for (let pp of this.selectedPassePartouts) {

        var olsForPP = ols.filter(function (ol)   {
          if (ol.PassePartoutId === pp.Id) return ol;
        });

        if (olsForPP.length < result) result = olsForPP.length;
      }
    }
    else {
      //Handle individual events


      for (let eId of this.selectedEventIds) {
        var olsForEvent = ols.filter(function (ol)   {
          if (ol.EventId === eId) return ol;
        });


        if (olsForEvent.length < result) result = olsForEvent.length;
      }

    }

    return result;

  }
  onOrderAmountChange(e, contingentId, priceVariantId) {

    if (isNaN(e.target.value)) {
      //no number entered
      e.target.value = '0';
    }

    var amount = Number(e.target.value);
    var maxAmount = +e.target.max;

    if (amount < 0) amount = 0;
    else if (amount > maxAmount) amount = maxAmount;

    e.target.value = amount;

    for (let a of this.amountPerPriceVariantAndContingent)
    {
      if (a.contingentId == contingentId && a.priceVariantId == priceVariantId)  {
        a.selectedAmount = amount;
      }
    }

    //notify the parent that the selection has changed
    this.onSelectionChange.emit(this.amountPerPriceVariantAndContingent);

  }


}
