import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class PersistedSearchService {

  constructor() { }

  public getPersistedSearch(searchId: string) {
    
    let result = null;
    let key = 'persisted-search-' + searchId;

    if (localStorage.getItem(key) && localStorage.getItem(key) !== null) {
        var json = localStorage.getItem(key);
        result = JSON.parse(json);
    }
    return result;
  }

  public setPersistedSearch(searchId: string, filter:any) {
    let key = 'persisted-search-' + searchId;
    localStorage.setItem(key, JSON.stringify(filter));
  }
}
