import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpLoaderService} from '@tymes4-shared';
import {GenericErrorHandler} from '../../helpers/generic-error-handler';
import {ExternalTicketService} from "../../api";

@Component({
  selector: 'app-upload-order-importfile',
  templateUrl: './upload-external-tickets-importfile.component.html',
  styleUrls: ['./upload-external-tickets-importfile.component.scss']
})
export class UploadExternalTicketsImportfileComponent implements OnInit {

  public progress: number;
  public message: string;
  @ViewChild('file') fileInput: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<UploadExternalTicketsImportfileComponent>,
    private loader: HttpLoaderService,
    private externalTicketService: ExternalTicketService,
    private errorHandler: GenericErrorHandler,
  ) {
  }

  ngOnInit() {
  }

  uploadImportFile(files: any) {

    const type = this.passedData.type;
    const spOrEventId = this.passedData.spOrEventId

    if (files === null || files === 'undefined' || !(files.length > 0)) {
      return;
    }
    const file = files[0];
    const blobArray: Array<Blob> = new Array<Blob>();
    blobArray.push(file);

    this.loader.open();

    this.externalTicketService.externalTicketsUploadImportFile(spOrEventId, blobArray).subscribe(event => {
        this.loader.close();
        this.dialogRef.close(event);
      },
      error => {
        this.errorHandler.handle(error);
      }
    );

    this.fileInput.nativeElement.value = '';
  }
}
