import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {CanDeactivateComponent} from './can-deactivate-component';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(private translate: TranslateService) {
  }
  canDeactivate(component: CanDeactivateComponent): boolean {

    if(!component.canDeactivate()){
      if (confirm(this.translate.instant('UNSAVED.CHANGES.WARNING'))) {
      // if (confirm("U heeft niet-opgeslagen wijzigingen! Als u de pagina verlaat, gaan uw wijzigingen verloren.")) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
