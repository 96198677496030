
 <div class="table-responsive">

    <table class="rounded table text-dark table-striped clickable">

    <thead>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.DATE' | translate}}</th>
        <th></th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.TYPE' | translate}}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.REFERENCE' | translate}}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.METHOD' | translate}}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.PAYMENTMETHOD' | translate}}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.HANDLED' | translate        }}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.STATUS' | translate}}</th>
        <th>{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.DETAILS' | translate}}</th>
        <th class="text-right">{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.HEADER.AMOUNT' | translate}}</th>
      </thead>

      <tbody>

        <ng-container *ngIf="pagedHistoryLines !== null && pagedHistoryLines.length == 0; else subscriptionlines">
            <tr>
              <td colspan="10">{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.LISTVIEW.NO-RESULT' | translate}}</td>
            </tr>
        </ng-container>

        <ng-template #subscriptionlines>
          <tr *ngFor="let pl of pagedHistoryLines">

            <td style="width: 100px">{{pl.ReceivedTimeStamp | localDate | replaceOnEmpty }}</td>
            <td>{{pl.ReceivedTimeStamp | localTime | replaceOnEmpty }}</td>
            <td>{{pl.Type }}</td>
            <td>{{pl.PaymentReference | replaceOnEmpty }}</td>
            <td>{{pl.PaymentMethodName }}</td>
            <td [ngSwitch]="pl.PaymentTypeId">
                <span *ngSwitchCase="1">{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-TYPE.DEBIT-CARD' | translate}}</span>
                <span *ngSwitchCase="2">{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-TYPE.CASH' | translate}}</span>
                <span *ngSwitchCase="3">{{'SALES.ORDERS.DETAILS.DIALOG.PAYMENT-OVERVIEW.PAYMENT-TYPE.OTHER' | translate}}</span>
                <span *ngSwitchDefault>-</span>
            </td>

            <td>{{pl.Handled | booleantype }}</td>
            <td>{{ getPaymentStatusText(pl.PaymentStatus) | replaceOnEmpty }}</td>
            <td style="width: 600px;">{{pl.Description | replaceOnEmpty }}</td>
            <td class="text-right">{{ pl.Amount | currency:'EUR' | replaceOnEmpty }}</td>
          </tr>
        </ng-template>

      </tbody>
    </table>

</div>

<div class="list-actions">
    <input type="checkbox" id="showDetails"  name="showDetails" (change)="onShowDetailsChange($event)" [checked]="false" /> <label for="showDetails">&nbsp;{{'GENERIC.BTN.SHOW-DETAILS' | translate}} </label>
</div>


<t4-pager (onPage)="doHistoryPage($event)" #historyPagerComponent></t4-pager>







