/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventForPassePartoutView { 
    Id?: number;
    StartDateTime?: Date;
    Name?: string | null;
    EventTypeName?: string | null;
    LocationVbbId?: number;
    LocationName?: string | null;
    LocationVersion?: string | null;
    EventPlacementAvailable?: boolean;
    ImageAwayId?: number;
    ImageAwayFileName?: string | null;
    ImageHomeId?: number;
    ImageHomeFileName?: string | null;
    EndDateTime?: Date;
}

