import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
import {HttpLoaderService} from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import { CustomerMergeArgs, CustomerMergeService } from "../../api";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-merge-customer-dialog',
  templateUrl: './merge-customer-dialog.component.html',
  styleUrls: []
})
export class MergeCustomerDialogComponent implements OnInit {

  @ViewChild('customerSelector') customerSelector;

  public customerId: number;
  public customerName: string;
  public form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: { customerId: number, customerName: string },
              public dialogRef: MatDialogRef<MergeCustomerDialogComponent>,
              private loader: HttpLoaderService,
              private confirmService: ConfirmService,
              private mergeService: CustomerMergeService,
              private translate: TranslateService) {
    this.customerId = passedData.customerId;
    this.customerName = passedData.customerName;
  }

  ngOnInit() {
    this.form = new FormGroup({
      Active : new FormControl(false),
    });
  }

  mergeCustomer() {
    this.mergeService.getMergeSummary(this.customerId, this.customerSelector.selectedCustomer.Id).subscribe((summary: any) => {
      this.confirmService.confirm({
        title: this.translate.instant('DIALOG.MERGE_CUSTOMER_DIALOG.CONFIRM.TITLE'),
        message: this.translate.instant('DIALOG.MERGE_CUSTOMER_DIALOG.CONFIRM.MSG',
        {
          targetCustomer: this.customerName,
          sourceCustomer: this.customerSelector.selectedCustomer.Name,
          BlacklistItems: summary.BlacklistItems,
          Contacts: summary.Contacts,
          Memberships: summary.Memberships,
          Passepartouts: summary.Passepartouts,
          Photos: summary.Photos,
          CustomerTags: summary.CustomerTags,
          EmailSettings: summary.EmailSettings,
          Invoices: summary.Invoices,
          MailTemplates: summary.MailTemplates,
          Payments: summary.Payments,
          PaymentMethodExclusions: summary.PaymentMethodExclusions,
          Subscriptions: summary.Subscriptions,
          Tickets: summary.Tickets,
          Orders: summary.Orders,
          OrderLines: summary.OrderLines,
          OrdersBlocked: summary.OrdersBlocked,
          OrderFailures: summary.OrderFailures,
          OrderImportLines: summary.OrderImportLines,
          PendingOrders: summary.PendingOrders,
          PendingOrderLines: summary.PendingOrderLines,
          PendingReservations: summary.PendingReservations,
          PendingReservationLines: summary.PendingReservationLines,
          OutstandingVouchers: summary.OutstandingVouchers,
          PaymentRequests: summary.PaymentRequests,
          PrintJobItems: summary.PrintJobItems,
          SalesPeriodRepurchaseLines: summary.SalesPeriodRepurchaseLines,
          EventPlacements: summary.EventPlacements,
          CustomerMemos: summary.CustomerMemos,
          IdentificationDocuments: summary.IdentificationDocuments
        })
      }).subscribe(success => {
        if (success) {
          const form = this.form.getRawValue();
          this.loader.open();
          const args : CustomerMergeArgs = {
            TargetCustomerId: this.customerId,
            SourceCustomerId: this.customerSelector.selectedCustomer.Id,
            InvalidateHardcards: form.Active
          };

          this.mergeService.mergeCustomers(args).subscribe((result: boolean) => {
            this.loader.close();
            this.dialogRef.close(1);
          }, err => {
            this.loader.close();
            this.confirmService.confirm({
              title: this.translate.instant('DIALOG.MERGE_CUSTOMER_DIALOG.ERROR.TITLE'),
              message: this.translate.instant('DIALOG.MERGE_CUSTOMER_DIALOG.ERROR.MSG'),
              okonly: true
            }).subscribe(d => this.dialogRef.close(-1));
          });
        } else {
          this.loader.close()
          this.dialogRef.close(0);
        }
      });
    });
  }
}
