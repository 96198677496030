import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { SeasonService, StatisticsService } from '../../api';

@Component({
  selector: 'app-statistics-dashboard-targets',
  templateUrl: './statistics-dashboard-targets.component.html'
})
export class StatisticsDashboardTargetsComponent implements OnInit, OnDestroy {
  public statistics: any = null;

  turnOverTotalOptions: any = null;
  turnoverWeekOptions: any = null;
  refreshTimerOptions = null;
  refreshTimer = null;
  public selectableSeasons: any = [];
  public seasonValue: number;

  selectedTabId = 'A';

  constructor (private statisticsService: StatisticsService,
               private seasonService: SeasonService,
               private loader: HttpLoaderService,
               private translateService: TranslateService) { }

  ngOnInit() {

    this.seasonService.getAllSeasons().subscribe(data => {
    
      this.selectableSeasons = data;

      // determine next season and make it default
      const now = new Date();
      let closest: Date = null;
      let currentSeason: any = null;
      this.selectableSeasons.forEach(function(d) {
        const date = new Date(d.EndDate);
        if (date >= now && (closest === null || date < new Date(closest) || date < closest)) {
          closest = date;
          currentSeason = d;
        }
      });

      this.seasonValue = this.selectableSeasons.filter(function(s) { if (s.PrecedingId === currentSeason.Id) { return s; } })[0].Id;

      this.loadCharts();
    });
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  onIntervalChange(e) {
    this.seasonValue = +e.Id;
    this.loadCharts();
  }

  stopTimer() {
    if (this.refreshTimer !== null) {
      clearInterval(this.refreshTimer);
    }
  }

  loadCharts(): any {
    this.loader.open();

    this.stopTimer();

    this.statisticsService.getBusinessDashboardStatistics('' + this.seasonValue, false).subscribe((data => {
      this.statistics = data;

      this.loader.close();

      setTimeout(() => {
        this.refreshTimer = setInterval(() => {
          this.updateRefreshTimer();
        }, 1000);

        this.initCharts();
      }, 50);
    }));
  }

  onSelectedTabChange(e) {
    this.selectedTabId = e.selectedTab.id;
  }

  initCharts(): any {
    this.initTurnover();
  }

  updateRefreshTimer() {
    if (this.statistics === null) {
      return;
    }

    // substract second
    this.statistics.ExpiryInSeconds--;

    if (this.statistics.ExpiryInSeconds < 0) {
      // we need to refresh
      this.loadCharts();
    }
  }

  initTurnover() {
    this.initTurnOverTotal();
    this.initTurnoverWeekChart();
  }

  initTurnoverWeekChart() {
    this.turnoverWeekOptions = {
      chart: {
        height: 275,
        type: 'bar',
        toolbar: {
          show: false
        },
      },
      stroke: {
        width: [2, 3],
        curve: 'smooth',
        dashArray: [0, 5]
      },
      plotOptions: {
        horizontal: false,
      },
      colors: ['#487EBC'],
      series: [{
        name: this.translateService.instant('STATISTICS-DASHBOARD.TARGETS.SEVEN-DAY-REVENUE'),
        data: this.statistics.TurnOverWeekDays.map (t => t.TurnOver)
      }],
      labels: this.statistics.TurnOverWeekDays.map (t => t.Day),
      markers: {
        size: [5, 0]
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        },
        title: {
          text: this.translateService.instant('STATISTICS-DASHBOARD.TARGETS.SEVEN-DAY-REVENUE'),
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return '€ ' + value.toFixed(2);
          }
        },
        marker: {
          show: true,
        },
      },
      legend: {
        show: false
      },
    };
  }

  initTurnOverTotal() {
    this.turnOverTotalOptions = {
      chart: {
        height: 325,
        type: 'radialBar',
        toolbar: {
          show: false
        },
      },
      colors: ['#487EBC'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%'
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '24px'
            }
          }
        }
      },
      labels: [''],
      series: [ this.statistics.RealizedTargetPerc ],
    };
  }
}
