<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form class="form-content">

  <div class="section-wrapper mt-3">

    <div class="form-layout">

      <div class="row mg-b-25">
        <div class="col-lg-10">

        </div>
        <div class="col-lg-2 mb-3 text-right">
          <button class="mr-1 btn btn-primary btn-small" (click)="pushNewRow()"><span>+</span></button>
          <button class="btn btn-primary btn-small" (click)="removeLastRow()" [disabled]="!passePartoutSelections || passePartoutSelections.length === 1"><span>-</span></button>
        </div>

      </div>

      <div *ngFor="let ps of passePartoutSelections" class="row row-sm mg-b-25">

        <div [class]="salesPeriodeId ? 'col-lg-3' : 'col-lg-6'">
          <ng-select [items]="allPassePartouts" name="Name" bindLabel="Name" bindValue="Id" placeholder="{{'CREATE.REBUY.EXPORT.FROM.PASSE-PARTOUT' | translate}}" (change)="onFromPassePartoutChanged($event, ps)"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
        </div>

        <div class="col-lg-3"  *ngIf="salesPeriodeId">
          <ng-select [items]="allPassePartouts" name="NameWithSalesPeriod" bindLabel="Name" bindValue="Id" placeholder="{{'CREATE.REBUY.EXPORT.TO.PASSE-PARTOUT' | translate}}" (change)="onToPassePartoutChanged($event, ps)"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
        </div>

        <div class="col-lg-6">
          <t4-date-time-control required="false" datePlaceholder="CREATE.REBUY.EXPORT.EXPIRY.DATE" timePlaceholder="CREATE.REBUY.EXPORT.EXPIRY.TIME" (changeEvent)="onExpiryDateTimeChanged($event, ps)"></t4-date-time-control>
        </div>

        <div class="col-lg-2" *ngIf="salesPeriodeId">
          <div class="form-group mt-2">
            <label class="ckbox mg-t-15">
              <input type="checkbox" name="SeatSelectionChange" [checked]="true" (change)="onSeatSelectionAllowedChanged($event, ps)"><span>  {{'CREATE.REBUY.EXPORT.ALLOW.SEAT.SELECTION.CHANGE' | translate}}</span>
            </label>

          </div>
        </div>
      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="doDownload()"  class="btn btn-block btn-primary bd-0" [disabled]="cannotDownload()"><i class="fas fa-file-export"></i><span>{{'CREATE.REBUY.EXPORT.DOWNLOAD' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-window-close"></i><span>{{'CREATE.REBUY.EXPORT.CLOSE' | translate}}</span></button>
        </div>
      </div>
    </div>
  </div>


</form>

