import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import * as moment from 'moment';

@Injectable()
export class ProfileValidator{
  constructor() {}

  static ValidatePasswords = (group: FormGroup) => {
    if (group.controls.NewPassword && group.controls.NewPasswordAgain) {
      if (group.controls.NewPassword.value !== group.controls.NewPasswordAgain.value) {
        group.controls.NewPasswordAgain.setErrors({passwordsDoNotMatch: true}, {emitEvent: true})

        return ({passwordsDoNotMatch: true});
      }
      else {
        if (group.controls.NewPasswordAgain.hasError('passwordsDoNotMatch')){
          group.controls.NewPasswordAgain.setErrors(null, {emitEvent: true});
        }
        return null;
      }
    }
  }
}
