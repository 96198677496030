/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { environment } from 'environments/environment';

// @ts-ignore
import { CreateMergeBatchArgs } from '../model/createMergeBatchArgs';
// @ts-ignore
import { CustomerMergeArgs } from '../model/customerMergeArgs';
// @ts-ignore
import { CustomerMergeBatchLineView } from '../model/customerMergeBatchLineView';
// @ts-ignore
import { CustomerMergeBatchLineViewPagedResult } from '../model/customerMergeBatchLineViewPagedResult';
// @ts-ignore
import { CustomerMergeBatchViewPagedResult } from '../model/customerMergeBatchViewPagedResult';
// @ts-ignore
import { CustomerMergeDetailViewBatchView } from '../model/customerMergeDetailViewBatchView';
// @ts-ignore
import { CustomerMergeIgnoreViewPagedResult } from '../model/customerMergeIgnoreViewPagedResult';
// @ts-ignore
import { MergeBatchDetailsView } from '../model/mergeBatchDetailsView';
// @ts-ignore
import { ProcessMergeBatchArgs } from '../model/processMergeBatchArgs';
// @ts-ignore
import { SingleMergeSummaryView } from '../model/singleMergeSummaryView';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CustomerMergeService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param createMergeBatchArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMergeBatch(createMergeBatchArgs?: CreateMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public createMergeBatch(createMergeBatchArgs?: CreateMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public createMergeBatch(createMergeBatchArgs?: CreateMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public createMergeBatch(createMergeBatchArgs?: CreateMergeBatchArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.createMergeBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/create-merge-batch`;
        return this.httpClient.post<any>(`${this.configuration.basePath}${localVarPath}`,
            createMergeBatchArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param batchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteMergeBatch(batchId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling deleteMergeBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.deleteMergeBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/delete-batch/${encodeURIComponent(String(batchId))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMergeIgnoreLine(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteMergeIgnoreLine(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteMergeIgnoreLine(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteMergeIgnoreLine(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMergeIgnoreLine.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.deleteMergeIgnoreLine - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/delete-ignore-line/${encodeURIComponent(String(id))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param batchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeBatchDetails(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<MergeBatchDetailsView>;
    public getMergeBatchDetails(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<MergeBatchDetailsView>>;
    public getMergeBatchDetails(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<MergeBatchDetailsView>>;
    public getMergeBatchDetails(batchId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling getMergeBatchDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeBatchDetails - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/get-batch-details/${encodeURIComponent(String(batchId))}`;
        return this.httpClient.get<MergeBatchDetailsView>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param batchId 
     * @param page 
     * @param maxPerPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeBatchLinesByBatch(batchId: number, page: number, maxPerPage: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMergeBatchLineViewPagedResult>;
    public getMergeBatchLinesByBatch(batchId: number, page: number, maxPerPage: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMergeBatchLineViewPagedResult>>;
    public getMergeBatchLinesByBatch(batchId: number, page: number, maxPerPage: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMergeBatchLineViewPagedResult>>;
    public getMergeBatchLinesByBatch(batchId: number, page: number, maxPerPage: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling getMergeBatchLinesByBatch.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getMergeBatchLinesByBatch.');
        }
        if (maxPerPage === null || maxPerPage === undefined) {
            throw new Error('Required parameter maxPerPage was null or undefined when calling getMergeBatchLinesByBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeBatchLinesByBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/get-lines-by-batch/${encodeURIComponent(String(batchId))}/${encodeURIComponent(String(page))}/${encodeURIComponent(String(maxPerPage))}`;
        return this.httpClient.get<CustomerMergeBatchLineViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param batchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeBatchLocked(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public getMergeBatchLocked(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public getMergeBatchLocked(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public getMergeBatchLocked(batchId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling getMergeBatchLocked.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeBatchLocked - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/get-batch-locked/${encodeURIComponent(String(batchId))}`;
        return this.httpClient.get<boolean>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerNumberLeft 
     * @param customerNumberRight 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeCustomerDetails(customerNumberLeft: string, customerNumberRight: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMergeDetailViewBatchView>;
    public getMergeCustomerDetails(customerNumberLeft: string, customerNumberRight: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMergeDetailViewBatchView>>;
    public getMergeCustomerDetails(customerNumberLeft: string, customerNumberRight: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMergeDetailViewBatchView>>;
    public getMergeCustomerDetails(customerNumberLeft: string, customerNumberRight: string, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (customerNumberLeft === null || customerNumberLeft === undefined) {
            throw new Error('Required parameter customerNumberLeft was null or undefined when calling getMergeCustomerDetails.');
        }
        if (customerNumberRight === null || customerNumberRight === undefined) {
            throw new Error('Required parameter customerNumberRight was null or undefined when calling getMergeCustomerDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeCustomerDetails - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/merge-customer-details/${encodeURIComponent(String(customerNumberLeft))}/${encodeURIComponent(String(customerNumberRight))}`;
        return this.httpClient.get<CustomerMergeDetailViewBatchView>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param pageNr 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeIgnoreList(searchText: string, pageNr: number, pageSize: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMergeIgnoreViewPagedResult>;
    public getMergeIgnoreList(searchText: string, pageNr: number, pageSize: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMergeIgnoreViewPagedResult>>;
    public getMergeIgnoreList(searchText: string, pageNr: number, pageSize: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMergeIgnoreViewPagedResult>>;
    public getMergeIgnoreList(searchText: string, pageNr: number, pageSize: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (searchText === null || searchText === undefined) {
            throw new Error('Required parameter searchText was null or undefined when calling getMergeIgnoreList.');
        }
        if (pageNr === null || pageNr === undefined) {
            throw new Error('Required parameter pageNr was null or undefined when calling getMergeIgnoreList.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getMergeIgnoreList.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeIgnoreList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/get-ignore-list/${encodeURIComponent(String(searchText))}/${encodeURIComponent(String(pageNr))}/${encodeURIComponent(String(pageSize))}`;
        return this.httpClient.get<CustomerMergeIgnoreViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param targetId 
     * @param sourceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMergeSummary(targetId: number, sourceId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SingleMergeSummaryView>;
    public getMergeSummary(targetId: number, sourceId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SingleMergeSummaryView>>;
    public getMergeSummary(targetId: number, sourceId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SingleMergeSummaryView>>;
    public getMergeSummary(targetId: number, sourceId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (targetId === null || targetId === undefined) {
            throw new Error('Required parameter targetId was null or undefined when calling getMergeSummary.');
        }
        if (sourceId === null || sourceId === undefined) {
            throw new Error('Required parameter sourceId was null or undefined when calling getMergeSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.getMergeSummary - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/get-merge-summary/${encodeURIComponent(String(targetId))}/${encodeURIComponent(String(sourceId))}`;
        return this.httpClient.get<SingleMergeSummaryView>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerMergeArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergeCustomers(customerMergeArgs?: CustomerMergeArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public mergeCustomers(customerMergeArgs?: CustomerMergeArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public mergeCustomers(customerMergeArgs?: CustomerMergeArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public mergeCustomers(customerMergeArgs?: CustomerMergeArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.mergeCustomers - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/merge`;
        return this.httpClient.post<boolean>(`${this.configuration.basePath}${localVarPath}`,
            customerMergeArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param processMergeBatchArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processMergeBatch(processMergeBatchArgs?: ProcessMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public processMergeBatch(processMergeBatchArgs?: ProcessMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public processMergeBatch(processMergeBatchArgs?: ProcessMergeBatchArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public processMergeBatch(processMergeBatchArgs?: ProcessMergeBatchArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.processMergeBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/process-batch`;
        return this.httpClient.post<any>(`${this.configuration.basePath}${localVarPath}`,
            processMergeBatchArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCustomerMergeBatch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMergeBatchViewPagedResult>;
    public searchCustomerMergeBatch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMergeBatchViewPagedResult>>;
    public searchCustomerMergeBatch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMergeBatchViewPagedResult>>;
    public searchCustomerMergeBatch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.searchCustomerMergeBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/search`;
        return this.httpClient.get<CustomerMergeBatchViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param batchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public submitMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public submitMergeBatch(batchId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public submitMergeBatch(batchId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling submitMergeBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.submitMergeBatch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/submit-batch/${encodeURIComponent(String(batchId))}`;
        return this.httpClient.get<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerMergeBatchLineView 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMergeBatchLine(customerMergeBatchLineView?: CustomerMergeBatchLineView, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public updateMergeBatchLine(customerMergeBatchLineView?: CustomerMergeBatchLineView, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateMergeBatchLine(customerMergeBatchLineView?: CustomerMergeBatchLineView, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateMergeBatchLine(customerMergeBatchLineView?: CustomerMergeBatchLineView, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('CustomerMergeService.updateMergeBatchLine - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/CustomerMerge/update-batch-line`;
        return this.httpClient.post<any>(`${this.configuration.basePath}${localVarPath}`,
            customerMergeBatchLineView,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
