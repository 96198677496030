import { Output, EventEmitter, Injectable, Directive } from "@angular/core";

@Directive()
@Injectable()
export class SelectionHelper {

    constructor() { }

    @Output() onSelectionChange = new EventEmitter<object>();

    public inMultiMode = false;
    public selectedObjects = [];
    public selectedObject = null;

    toggleSelection(item) {

        if (item === null) return;

        if (this.inMultiMode) {

            if (this.isItemSelected(item)) {
                //remove from the selection
                var index = this.selectedObjects.indexOf(item);
                this.selectedObjects.splice(index, 1);
              }
              else {
                this.selectedObjects.push(item);
              }
        }
        else {
            if (this.selectedObject == item) this.selectedObject = null;
            else    this.selectedObject = item;
        }

        this.emitChange();
      }

      isItemSelected(item) {

        if (!item) return false;

        if (this.inMultiMode) {

            if (this.selectedObjects == null && this.selectedObjects.length == 0)
                return false;

            var found = this.selectedObjects.filter(function(ep){
                if (ep.Id == item.Id) return ep;
            });

            return (found != null && found.length > 0);
        }
        else {
            return item == this.selectedObject;
        }
      }

      clearSelection() {
        this.selectedObjects = [];
        this.selectedObject = null;
        this.emitChange();
      }

      emitChange() {

        var selection = this.selectedObjects

        if (this.inMultiMode === false) {
            if (this.selectedObject === null) selection = null;
            else selection = [ this.selectedObject ];
        }

        this.onSelectionChange.emit(selection);
      }

}

