
<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(null);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>



<form [formGroup]="form" class="form-content" *ngIf="deliveryMethods !== null">
  <div class="section-wrapper">

    <div class="row">
      <div class="col-lg-12">

        <div class="form-group">

          <label class="form-control-label d-block">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.PRINT-FOR' | translate}}<span class="tx-danger">*</span></label>

          <div class="mb-3 d-inline-block">
            <input type="radio" formControlName="printFor" value="E">
            <label class="form-check-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.EVENTS' | translate}}</label>
          </div>
          <div class="form-check mb-3 d-inline-block">
            <input type="radio" formControlName="printFor" value="P">
            <label class="form-check-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.PASSE-PARTOUTS' | translate}}</label>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="form.controls['printFor'].value !== 'P'">

      <div class="col-lg-12">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.EVENT-THAT-NEEDS-PRINTING' | translate}}</label>
          <ng-select [items]="selectableEvents" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.EVENT-THAT-NEEDS-PRINTING' | translate}}" formControlName="eventId"
                     [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
            {{ item && item.Name ? item.Name + (item.StartDateTime ? ' - ' : '') : '' }}{{ item.StartDateTime | localDateTime | slice:0:10 }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.controls['printFor'].value !== 'P'">
      <div class="col-lg-12">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.EVENT-TYPE' | translate}}</label>
          <ng-select [items]="eventTypes" bindLabel="DisplayName" bindValue="Id" placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.EVENT-TYPE' | translate}}" formControlName="eventTypeId"
                     [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body"></ng-select>
        </div>
      </div>

    </div>

    <div class="row"  *ngIf="form.controls['printFor'].value === 'P'">
      <div class="col-lg-12">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.PASSE-PARTOUT-THAT-NEEDS-PRINTING' | translate}}</label>
          <ng-select [items]="selectablePassePartout" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.PASSE-PARTOUT-THAT-NEEDS-PRINTING' | translate}}" formControlName="passePartoutId"
                     [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              {{ item.Code + ' - ' + item.Name }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.SELECT-PRINTING-METHOD' | translate}}<span class="tx-danger">*</span></label>
          <ng-select placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.SELECT-PRINTING-METHOD' | translate}}" formControlName="printMethodId" [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-option [value]="1">{{'DIALOGS.CREATE-PRINT-BATCH.VALUE.SOFTCARD' | translate}}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.SELECT-DELIVERY-METHOD' | translate}}</label>
          <ng-select [items]="deliveryMethods" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.SELECT-SHIPPING-METHOD' | translate}}" formControlName="deliveryMethodId"
                     [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body"></ng-select>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.CUT-OFF' | translate}}<span class="tx-danger">*</span></label>
          <ng-select placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.CUT-OFF' | translate}}" formControlName="cutMode" [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-option [value]="1">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.CUT-OFF.DROPDOWN.AFTER-EACH-CARD' | translate}}</ng-option>
            <ng-option [value]="2">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.CUT-OFF.DROPDOWN.AFTER-THE-ORDER' | translate}}</ng-option>
            <ng-option [value]="4" *ngIf="form.controls['printFor'].value === 'P'">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.CUT-OFF.DROPDOWN.AFTER-THE-PASSE-PARTOUT' | translate}}</ng-option>
            <ng-option [value]="3">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.CUT-OFF.DROPDOWN.DONT-CUT-OFF' | translate}}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.ADRESS-CARRIER' | translate}}<span class="tx-danger">*</span></label>
          <ng-select placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.ADRESS-CARRIER' | translate}}" formControlName="packagingSlip" [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-option [value]="1">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.ADRESS-CARRIER.DROPDOWN.NONE' | translate}}</ng-option>
            <ng-option [value]="2">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.ADRESS-CARRIER.DROPDOWN.PER-MAIN-BOOKER' | translate}}</ng-option>
            <ng-option [value]="3">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.ADRESS-CARRIER.DROPDOWN.PER-TICKET-RELATION' | translate}}</ng-option>
          </ng-select>
        </div>

      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label">{{'DIALOGS.CREATE-PRINT-BATCH.LABEL.MAXIMUN-AMOUNT' | translate}}</label>
          <ng-select placeholder="{{'DIALOGS.CREATE-PRINT-BATCH.PLACEHOLDER.MAXIMUN-AMOUNT' | translate}}" formControlName="maxNrOfTickets" [clearable]="false" notFoundText="{{'GENERIC.NOT-FOUND-TEXT' | translate}}" appendTo="body">
            <ng-option [value]="50">{{'GENERIC.VALUE.50' | translate}}</ng-option>
            <ng-option [value]="100">{{'GENERIC.VALUE.100' | translate}}</ng-option>
            <ng-option [value]="250">{{'GENERIC.VALUE.250' | translate}}</ng-option>
            <ng-option [value]="500">{{'GENERIC.VALUE.500' | translate}}</ng-option>
            <ng-option [value]="1000">{{'GENERIC.VALUE.1000' | translate}}</ng-option>
          </ng-select>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <button class="btn btn-primary btn-block" (click)="doDownload()" [disabled]="form.invalid || saving" title="{{'DIALOGS.CREATE-PRINT-BATCH.BUTTON.TITLE.PRINT' | translate}}">
          <i class="fas fa-print"></i>
          <span>{{'GENERIC.BTN.PRINT' | translate}}</span>
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-secondary btn-block" [disabled]="saving" (click)="dialogRef.close(null);" title="{{'GENERIC.BUTTON.CANCEL-CHANGES-AND-CLOSE-WINDOW' | translate}}">
          <i class="fas fa-ban"></i>
          <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
