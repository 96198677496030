import * as moment from 'moment';
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {LoggingService, NotificationCommunicationService, SnackbarService} from '@tymes4-shared';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ConfirmService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { FormGroup, FormControl } from '@angular/forms';
import { EditSeatPlanComponent } from 'app/shared/dialogs/edit-seat-plan/edit-seat-plan.component';
import { CreateSeatGridComponent } from 'app/shared/dialogs/create-seat-grid/create-seat-grid.component';
import { EditUnplacedTicketsComponent } from 'app/shared/dialogs/edit-unplaced-tickets/edit-unplaced-tickets.component';
import { CopyVBBFormComponent } from 'app/shared/dialogs/copy-vbb-form/copy-vbb-form.component';
import { EditVBBFormComponent } from 'app/shared/dialogs/edit-vbb-form/edit-vbb-form.component';
import * as FileSaver from 'file-saver';
import { SectionSelectorComponent } from '../venue-definition/section-selector/section-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { VenueEditChangeLogComponent } from '../../dialogs/venue-edit-change-log/venue-edit-change-log.component';
import { debounceTime, first } from 'rxjs/operators';
import { EntranceService, EventPlacementService, EventService, LongRunningOperationService, LongRunningOperationTypeEnum, TicketTypeService, VenueBuildingBlockService } from '../../api';
import { Observable } from 'rxjs-compat/Observable';
//import { VenueEditModeEnum } from 'app/shared/models/venue-edit-mode-enum';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-venue-placements-editor',
  templateUrl: './venue-placements-editor.component.html',
  styleUrls: ['./venue-placements-editor.component.scss']
})
export class VenuePlacementsEditorComponent implements OnInit {

  public passedId = null;

  //FDP: this is an old setting which is no longer used.
  public inSingleEntranceMode: boolean = true;

  public longRunningOperationTypeEnum = LongRunningOperationTypeEnum;
  public isInArchiveMode = this.authService.isInArchiveMode();
  
  constructor(private venue: VenueBuildingBlockService,
              private notificationCommunicationService : NotificationCommunicationService,
              private eventPlacementService: EventPlacementService,
              private ref: ChangeDetectorRef,
              private logging: LoggingService,
              private dialog: MatDialog,
              private confirmService: ConfirmService,
              private entrance: EntranceService,
              private loader:HttpLoaderService,
              private eventService: EventService,
              private ticketTypeService: TicketTypeService,
              private translate: TranslateService,
              private lroService: LongRunningOperationService,
              private snackBar: SnackbarService,
              private authService: AuthService) { }

    @ViewChild('pbPendingChanges') pbPendingChanges;

    currentTabId = 'positions';
    editingVBBId: number = 0;
    editingVBB = null;
    location = null;
    ticketTypes = [];
    selectedTabIdx = 0;
    hasUnpublishedChanges = false;
    nrOfChangeLogs = 0;

    private tabDetails = [];

    private entrancesIsDirty: boolean = false;
    private filteredEntrances: any;
    private vbbEntrances = [];
    private selectedEntrances =[];
    public entranceForm: FormGroup;
    private entranceSearchControl: FormControl;
    public event  = null;

    eventPlacementEventId: number = null;


    tabIndex = -1;

    @ViewChild('sectionSelectorComponent') sectionSelector: SectionSelectorComponent;
    @ViewChild('ticketTabs') ticketTabGroup;

    ngOnInit() {

    }


    onTabChange(e) {
      this.currentTabId = e.selectedTab.id;
    }

    public initializeComponent(parentId, eventId) {

      this.editingVBBId = parentId;
      this.eventPlacementEventId = eventId;

      //loadVenueDetails
      this.sectionSelector.initialize(parentId, this.eventPlacementEventId);

      if (this.eventPlacementEventId !== null) {
        this.eventService.getEvent(this.eventPlacementEventId).subscribe((data) => {
          this.event = data;
        });
      }
      else {

        this.venue.getVenueBuildingBlockDetails(parentId).subscribe((data:any) => {
          this.location = data;
          this.nrOfChangeLogs = data.ChangeResults.length;
        });
      }

       //retrieve all tickettypes that should be editable
       this.ticketTypeService.getAllEditableInVenueDefinition().subscribe((data: any) => {

        data.forEach(element => {
          this.tabDetails.push ({
            loading: false, endpoints: null
          });
        });

        this.ticketTypes = data;

      });

      //setup the autocomplete for entrances
      this.entranceSearchControl = new FormControl();

      this.entranceSearchControl.valueChanges.pipe(debounceTime(400)) //give the user some time to end the query
        .subscribe(data => {

          this.loader.open();

          //max 200 results sorted on name
          this.entrance.searchEntrances(data, 1, 200, 'Name').subscribe(data =>{
              this.filteredEntrances = this.removeLinkedEntrances(data.Records)
              this.loader.close();
          });

        });


      this.entranceForm = new FormGroup({
        entranceSearch: this.entranceSearchControl
      });

    }

    selectedTicketType:any = null;

  
    publish() {

      this.venue.getLinkedEvents(this.editingVBBId).subscribe(events => {

        this.loader.close();

        let title = this.translate.instant('LOCATIONEDIT.APPLYCHANGES.CONFIRM.TITLE');

        let msg: string;

        if (!events?.length) {
          msg = this.translate.instant('LOCATIONEDIT.APPLYCHANGES.CONFIRM.MESSAGE-NO-EVENTS');
        }
        else {
          msg = this.translate.instant('LOCATIONEDIT.APPLYCHANGES.CONFIRM.MESSAGE');

          let done = 0;
          let evList = '';
  
          for (let e of events) {
  
            done ++;
  
            if (done > 10) {
              let moremsg = this.translate.instant('LOCATIONEDIT.CREATESECTION.CONFIRM.MOREMESSAGE');
              moremsg = moremsg.replace('[count]', events.length - 10);
              evList += `<li>${moremsg}</li>`;
              break;
            }
            else {
              var dte = moment(e.StartDateTime);
              evList += `<li>${dte.format("DD-MM-YYYY hh:mm")} - ${e.Name}</li>`;
            }
          }
  
          evList = "<ul>" + evList + "</ul>";
  
          msg = msg.replace('[eventlist]', evList);
        }

        this.confirmService.confirm({
          title: title,
          message: msg,
          okonly: false
        },'500px').subscribe((confirmed: boolean) => {

          if (confirmed === true) {
            ///apply it!

            this.loader.open();

            //and kickoff the process (fire-and-forget)
            this.venue.applyPendingChanges(this.editingVBBId).subscribe(lro => {
              this.notificationCommunicationService.updateNotificationsAndLROS();
              this.loader.close();
            });
          }
        });
      });

    }

    undo() {

      let title = this.translate.instant('LOCATIONEDIT.UNDOPENDING.CONFIRM.TITLE');
      let msg = this.translate.instant('LOCATIONEDIT.UNDOPENDING.CONFIRM.MESSAGE');

      this.confirmService.confirm({
        title: title,
        message: msg,
        okonly: false
      }).subscribe((confirmed: boolean) => {

        if (confirmed === true) {

          this.loader.open();

          this.venue.undoPendingChanges(this.editingVBBId).subscribe((data:any) => {
            this.loadVenueDetails(this.editingVBBId);
            this.loader.close();
            this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.CHANGES-UNDONE'), 'GENERIC.SNACKBAR.CLOSE');
          });

        }
      });
    }

    selectTicketType(ticketType, i) {
      //this.selectedTicketType = ticketType;

      this.loadTabDetails(i);

    }

    ticketTypeIsSelected(ticketType) {
      if (this.selectedTicketType == null) return false;
      return (this.selectedTicketType.Id == ticketType.Id);
    }

    markSelectedAsDefault(entrance) {

      this.loader.open();

      var defaultObservable = this.eventPlacementEventId == null ? this.venue.setDefaultEntranceForVBB(this.editingVBB.Id, entrance.Id) : this.eventPlacementService.setDefaultEntranceForEP(this.editingVBB.Id, entrance.Id);

      defaultObservable.subscribe((data:any) => {
        this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx);
      });
    }

    //Removes entrances that are already linked to the vbb
    removeLinkedEntrances(foundEntrances) {

      let result = [];

      for(let e of foundEntrances) {
        if (this.findInArray(e.Id) == null)   {
          result.push(e);
        }
      }

      return result;
    }

    findInArray(id) {
      var r = this.vbbEntrances.find(function(vbbEntrance) { return vbbEntrance.Id == id })
      return r;
    }

    onEntranceAdd(e) {

      this.loader.open();
      this.entranceForm.controls['entranceSearch'].setValue(''); //clear for the next add
      let entrance = e.option.value;

      //add the entrance to the list before storing.
      this.vbbEntrances.splice(0, 0, entrance);

      var saveObservable = this.eventPlacementEventId == null ? this.venue.addEntranceToVBB(this.editingVBB.Id, entrance) : this.eventPlacementService.addEntranceToEP(this.editingVBB.Id, entrance);

      //we can save now
      saveObservable.subscribe((data:any) => {
        //refresh the list
        this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx);
        this.loader.close();
      });

      this.logging.log(entrance);
    }

    deleteEntrance(e) {

      this.confirmService.confirm({title: this.translate.instant('GENERIC.CONFIRM.CONFIRM'),
        message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE')})
        .subscribe((result) => {

        if (result) {

          this.loader.open();
          var idsToDelete = [e.Id];

          //remove the selected items from the array
          for (let e of this.selectedEntrances) {
            var idx = this.vbbEntrances.indexOf(e);
            this.vbbEntrances.splice(idx, 1);
          }

          var deleteObservable = this.eventPlacementEventId == null ? this.venue.deleteEntrancesFoVBB(this.editingVBB.Id, idsToDelete) : this.eventPlacementService.deleteEntrancesForEP(this.editingVBB.Id, idsToDelete);

          //and store the deletes
          deleteObservable.subscribe((data : any) => {
            this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx);
            this.loader.close();
          });
        }
      });
    }

    openSeatPlan(endpoints) {

      let title = `${this.translate.instant('LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.TITLE.NEW')} ${this.editingVBB.Name}`;

      let dialogRef: MatDialogRef<any> = this.dialog.open(EditSeatPlanComponent,
      {
        panelClass: 'full-width-dialog',
        disableClose: false,
        height: '95%',
        width: '98vw',
        maxWidth: '98vw',
        data: {
              title: title,
              payload:
              {
                eventPlacementEventId: this.eventPlacementEventId,
                endpoints: endpoints,
                ParentVBB: this.editingVBB
              }
            }
      });

      dialogRef.afterClosed().subscribe(data => {
        //done, refresh.
        this.loadVenueDetails (this.editingVBBId, this.selectedTabIdx);
      });
    }


    loadTabDetails(idx) {

      if (idx >= this.tabDetails.length) return;

      this.tabIndex = idx;
      this.selectedTicketType = this.ticketTypes[idx];
      this.loader.open();
      this.selectedTabIdx = idx;

      this.tabDetails[idx].endpoints = null; //reset

      var detailObservable : Observable<any> = this.eventPlacementEventId === null ?
        this.venue.getVenueBuildingBlockSeatsByTicketType(this.editingVBB.Id, this.ticketTypes[idx].Id) : this.eventPlacementService.getEventPlacementSeatsByTicketType(this.editingVBB.Id, this.ticketTypes[idx].Id);

        detailObservable.subscribe(data => {
          this.tabDetails[idx].endpoints = data;
          this.tabDetails[idx].loading = false;

          this.loadLinkedEntrances(); //loader is being closed in this method

          //this.loader.close();
      });
    }

    openPlacedEndPointDialog(data: any = {}, isNew?) {

      let title = this.translate.instant('LOCATION.VENUE-DEFINITION.EDIT-TICKETS-DIALOG.TITLE.NEW');

      let dialogRef: MatDialogRef<any> = this.dialog.open(CreateSeatGridComponent, {
        width: '720px',
        disableClose: true,
        data: { title: title, payload: data, isNew: isNew }
      });

      dialogRef.afterClosed().subscribe(data => {
        //Done, refresh the data.
        this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx)
      });

    }

    openUnplacedTicketsDialog(data: any = {}, isNew?) {

      let title = this.translate.instant('LOCATION.VENUE-DEFINITION.ADD-TICKETS-DIALOG.TITLE.NEW', {name: data.TicketTypeName});

      let dialogRef: MatDialogRef<any> = this.dialog.open(EditUnplacedTicketsComponent, {
        width: '720px',
        disableClose: true,
        data: { title: title, payload: data, isNew: isNew }
      });

      dialogRef.afterClosed().subscribe((data:any) => {
        //Done, refresh the data.
        this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx)
      });
    }

  openEditDialog(parentId, data: any = {}, isNew?) {

    let title = isNew ? this.translate.instant('LOCATION.VENUE-DEFINITION.EDIT-SECTION-DIALOG.TITLE.NEW') : `${this.translate.instant('LOCATION.VENUE-DEFINITION.EDIT-SECTION-DIALOG.TITLE.EDIT')} ${data.Name}`;

    data.VenueBuildingBlockTypeId = 2;
    data.parentId = parentId;

    let dialogRef: MatDialogRef<any> = this.dialog.open(EditVBBFormComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data, isNew : isNew, eventId: this.eventPlacementEventId }
    });

    dialogRef.afterClosed().subscribe(id => {

      if(!id) {
        // If user press cancel
        this.logging.log('Dialog canceled');
        return;
      }
      else {

        this.logging.log('Object has been saved, id =' + id);

        //Reload the edited object
        this.loadVenueDetails(id);

        //Now notify the section selection that a refresh of selection is needed.
        this.sectionSelector.sectionUpdated(id);
      }
    });
  }

    deleteVBB()
    {
      this.logging.log ('deleting ' + this.editingVBB.Name);

      this.confirmService.confirm({title: this.translate.instant('GENERIC.CONFIRM.CONFIRM'), message: this.translate.instant('LOCATION.LOCATIONS.CONFIRM.DELETE.DESCRIPTION')}).subscribe((result) => {

        if (result) {

          this.loader.open();

          this.venue.deleteVenueBuildingBlock(this.editingVBB.Id).subscribe ((data:any) => {

            //After a deleted the tree needs to be reloaded
            this.sectionSelector.sectionUpdated(null);
            this.loader.close();
          });
        }
      });
    }

    onUnplacedTicketRefreshRequired($event)
    {
      this.loadVenueDetails(this.editingVBBId, this.selectedTabIdx);
    }

    //#region VBB selector Events

    onVenueBuildingBlockSelected(args) {

      this.editingVBBId = args.Id;

      if (args.Id < 0) {
        //the component passes a negative id when a new item should be added.
        this.openEditDialog(args.ParentId, {}, true);
      }
      else {
        //this means an item is selected
        this.loadVenueDetails(args.Id);
      }
    }

    progressFinished() {
      this.loadVenueDetails(this.editingVBBId);
    }

    loadVenueDetails(id, selectedTabIdx = 0) {

      this.loader.open();

      //clear the current vbb so that the user is unable to click on the buttons before the section is fully loaded
      this.editingVBB = null;
      this.editingVBBId = null;

      var detailObservable : Observable<any> = this.eventPlacementEventId === null ? this.venue.getVenueBuildingBlockDetails(id) : this.eventPlacementService.getEventPlacementDetails(id);

      detailObservable.subscribe((data: any) =>{

        this.editingVBB = data;
        this.editingVBBId = data.Id;
        this.hasUnpublishedChanges = (this.event === null && this.editingVBB.HasUnpublishedChanges);
        this.nrOfChangeLogs = this.eventPlacementEventId === null ? data.ChangeResults.length : 0;

        //the first tab is always open, so get the details
        this.loadTabDetails(selectedTabIdx);
  
      });
    }

    openChangeLog() {

      let title = this.translate.instant('LOCATION.LOCATIONS.HISTORYDIALOG.TITLE');

      let dialogRef: MatDialogRef<any> = this.dialog.open(VenueEditChangeLogComponent, {
        width: '1024px',
        disableClose: true,
        data: { title: title, vbbId: this.editingVBB.Id, logRecords: this.editingVBB.ChangeResults }
      });

      dialogRef.afterClosed().subscribe(id => {});

    }

    loadLinkedEntrances() {

      var detailObservable = this.eventPlacementEventId === null ? this.venue.getEntrancesForVBB(this.editingVBB.Id) : this.eventPlacementService.getEntrancesForEp(this.editingVBB.Id);

      //retrieve the entrances for the VBB
      detailObservable.subscribe((data : any) => {
        this.vbbEntrances = data;
        this.loader.close();
      });
    }

    copySection() {

      let title = this.translate.instant('LOCATION.LOCATIONS.COPYDIALOG.TITLE');

      let parentId = null;

      if (this.editingVBB.Parents != null && this.editingVBB.Parents.length > 1) {
        //Set the parent so that the form can display it.
        parentId = this.editingVBB.Parents[this.editingVBB.Parents.length - 2].Id;
      }


      let dialogRef: MatDialogRef<any> = this.dialog.open(CopyVBBFormComponent, {
        width: '720px',
        disableClose: true,
        data: {
            title: title,
            venueBuildingBlockTypeId: this.editingVBB.VenueBuildingBlockTypeId,
            parentId: parentId,
            selectedObjectId: this.editingVBB.Id
          }
      });

      dialogRef.afterClosed().subscribe(id => {
        if(!id) {
          // If user press cancel
          this.logging.log('Dialog canceled');
          return;
        }
        else {
          this.logging.log('Object has been saved, id =' + id);
          this.sectionSelector.sectionUpdated(this.editingVBB.Id);
        }
      });

    }

    onActionSelected(action) {

      // this.logging.log('Action selected.');
      // if (action == 'add') {
      //
      // }
      // else if (action == 'copy') {

      // }

    }

    editDetails() {
      //Called from the actions menu of the venue.
      //At this point we have the details but not the physical object.
      //Also, refresh to make sure we have the latest version

      var detailObservable : Observable<any> = this.eventPlacementEventId === null ?  this.venue.getVenueBuildingBlock(this.editingVBB.Id) :  this.eventPlacementService.getEventPlacement(this.editingVBB.Id);

        detailObservable.subscribe((data:any) => {

        let parentId = null;

        if (this.editingVBB.Parents != null && this.editingVBB.Parents.length > 1) {
          //Set the parent so that the form can display it.
          parentId = this.editingVBB.Parents[this.editingVBB.Parents.length - 2].Id;
        }

        this.openEditDialog(parentId, data, false);

      });
    }

    addTickets(ticketType, idx) {

      let isNew: boolean = false;
      var currentDimensions = null;

      //set the required data
      currentDimensions = {};
      currentDimensions.SelectedIdx = idx;
      currentDimensions.ParentId = this.editingVBB.Id;
      currentDimensions.TicketTypeId = ticketType.Id;

      if (ticketType.IsPlaced == 1){

        //when this section already contains places endpoints we need to pass the current dimensions
        if (this.tabDetails[idx].endpoints != null && this.tabDetails[idx].endpoints.length > 0) {
          //already tickets
          var data = this.tabDetails[idx].endpoints;
          currentDimensions.NrOfRows = Math.max.apply(Math, data.map(r=> r.Row));
          currentDimensions.NrOfColumns = Math.max.apply(Math, data.map(r => r.Column));
        }
        else {
          //create defaults
          currentDimensions.NrOfColumns=10;
          currentDimensions.NrOfRows=10;
          isNew = true;
        }

        this.openPlacedEndPointDialog(currentDimensions, isNew);
      }
      else {
        //unplaced endpoints
        isNew = true;
        this.logging.log('opening unplaced tickets dialog');
        currentDimensions.TicketTypeName = ticketType.Name;
        this.openUnplacedTicketsDialog(currentDimensions, isNew);
      }
    }

    endpointsAvailable(i) {
      return (this.tabDetails[i] && this.tabDetails[i].endpoints != null && this.tabDetails[i].endpoints.length > 0);
    }

    //#endregion
}

