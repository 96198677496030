<div class="row modal-bar">
    <div class="col-sm-11">
        <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>
    <div class="col-sm-1">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.LABEL.KEY' | translate }} <span class="tx-danger">*</span></label>
              <input class="form-control" name="key" formControlName="Key" placeholder="{{ 'SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.PLACEHOLDER.KEY' | translate }}" autocomplete="off">
                <t4-display-validation controlName="Key" [form]="form" [messages]="validationMessages">
                </t4-display-validation>
            </div>
          </div>
        </div>
        <div class="row mg-b-25">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.LABEL.VALUE' | translate }} <span class="tx-danger"></span></label>
              <textarea class="form-control" name="value" formControlName="Value" placeholder="{{ 'SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.PLACEHOLDER.VALUE' | translate }}" autocomplete="off"></textarea>
            </div>
          </div>
        </div>
        
        <div class="row mg-b-25">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" name="sensitive" formControlName="Sensitive">
              <label class="form-check-label" for="IdentificationRequired">{{ 'SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.LABEL.SENSITIVE' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid || form.controls['Key'].pending || saving" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'BTN.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'BTN.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
  </form>
  