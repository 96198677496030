
import {throwError, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';



@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
  return next.handle(request).pipe( tap(() => {},
  (err: any) => {

    if (err instanceof HttpErrorResponse) {

      if (err.status === 503 && err.error.Message == 'maintenance') {
        window.location.href = "/maintenance.html";
      }  
      return;
  
    }
  }));

  }

}
