import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmService } from '@tymes4-shared';
import { IdentificationStateEnum, IdVerificationService, UpdateIdArgs } from '../../api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manual-identification-reason',
  templateUrl: './manual-identification-reason.component.html',
  styleUrls: ['./manual-identification-reason.component.scss']
})
export class ManualIdentificationReasonComponent implements OnInit {

  public form: FormGroup;
  public customerId: number;
  public status: IdentificationStateEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<ManualIdentificationReasonComponent>,
    private idVerificationService: IdVerificationService,
    private confirmService: ConfirmService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {

    this.customerId = this.passedData.customerId;
    this.status = this.passedData.status;

    this.form = new FormGroup({
      Reason: new FormControl(''),
    });


  }

  submit() {
    const formValues = this.form.value;

    this.updateIdentificationStatus(this.status, formValues.Reason).subscribe(data => {
      this.dialogRef.close(true);
    }, err => {
      this.confirmService.confirm({
        title: this.translate.instant('FANS.ADMINISTRATE-CUSTOMERS.MANUAL-IDENTIFICATION.CONFIRM-SERVICE.TITLE.SOMETHING-WENT-WRONG'),
        message: this.translate.instant('FANS.ADMINISTRATE-CUSTOMERS.MANUAL-IDENTIFICATION.CONFIRM-SERVICE.MESSAGE.SAVING-NEW-STATUS-ERROR'),
        okonly: true
      }).subscribe(d => this.dialogRef.close(true));
    });

  }

  updateIdentificationStatus(newStatus: IdentificationStateEnum, reason: string) {
    const args: UpdateIdArgs = {
      CustomerId: this.customerId,
      Status: newStatus,
      Reason: reason
    }

    return this.idVerificationService.updateVerificationStatus(args);
  }

}
