import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-reservation-import',
  template: `
    <div class="row modal-bar">
      <div class="col-sm-6">
        <h5 class="modal-title">{{ data.title }}</h5>
      </div>
      <div class="col-sm-6">
        <div class="modal-actions">
          <div class="modal-close">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span (click)="dialogRef.close(false);">×</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="section-wrapper mt-3">
    <div>
      <p>{{'DIALOGS.CONFIRM-RESERVATION-IMPORT.SECTION-WRAPPER.YOURE-ABOUT-TO' | translate}} {{ data.payload.processableCount }} {{'DIALOGS.CONFIRM-RESERVATION-IMPORT.SECTION-WRAPPER.CREATE-RESERVATIONS' | translate}}</p>
      <p class="my-2"><b>{{'DIALOGS.CONFIRM-RESERVATION-IMPORT.SECTION-WRAPPER.CHOOSE-A-CALM-MOMENT' | translate}}</b></p>
      <p *ngIf="showGroupBy" class="mt-3"><label class="font-weight-normal"><input type="checkbox" [(ngModel)]="groupByCustomer">{{'DIALOGS.CONFIRM-RESERVATION-IMPORT.SECTION-WRAPPER.GROUP-RESERVATIONS' | translate}}</label></p>
    </div>
    </div>
    <div class="form-layout-footer mt-5 row">
      <div class="col-6">
        <button (click)="dialogRef.close({groupByCustomer: groupByCustomer});" class="btn btn-block btn-primary bd-0"><span>Ok</span>
        </button>
      </div>
      <div class="col-6">
        <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
        </button>
      </div>
    </div>`,
})
export class ConfirmReservationImportComponent {
  public groupByCustomer = false;
  public showGroupBy = true;
  constructor(
    public dialogRef: MatDialogRef<ConfirmReservationImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {
    if(data.payload.showGroupBy != null){
      this.showGroupBy = data.payload.showGroupBy;
    }
  }
}
