
<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<div class="row mt-3 mb-3">
  <div class="col-12">
    <div class="important-info info-highlight">
      <div class="row mg-b-25">

        <div class="col-5">
          <p class="info-label">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.HEADER.CUSTOMER' | translate}}</p>
          <p class="info-data"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(orderBlockedData, null)"></t4-customer-identification-control></p>
        </div>
        <div class="col-1">
          <i class="fas fa-user"></i>
        </div>

        <div class="col-5">
          <p class="info-label">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.HEADER.DETAILS' | translate}}</p>
          <p class="info-data">{{ orderBlockedData.StatusText }}</p>
        </div>

        <div class="col-1">
          <i class="fas fa-stream"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<t4-tabs>

  <t4-tab tabTitle="SALES.ORDERS.BLOCKED.DIALOG.DETAILS.TAB.ORDERLINES" id="orderlines">

     <div class="table-responsive">

      <table class="rounded table text-dark table-striped clickable">
        <thead>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.EVENT-PASSEPARTOUT' | translate}}</th>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.SECTION-ROW-SEAT' | translate}}</th>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.ENTRANCE' | translate}}</th>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.TICKET-TEXT' | translate}}</th>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.TYPE' | translate}}</th>

          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.CUSTOMER' | translate}}</th>
          <th>{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.PRICE-VARIANT' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.AMOUNTEXVAT' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.DISCOUNT' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.VAT' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.TOTAL' | translate }}</th>
        </thead>

        <tbody>

          <tr *ngFor="let ol of pagedOrderLines" >

            <td *ngIf="ol.PassePartoutName == null">
              {{ ol.EventDate | localDateTime }} - {{ ol.EventName }}
            </td>
            <td *ngIf="ol.PassePartoutName != null">{{ ol.PassePartoutName | replaceOnEmpty:'' }}</td>
            <td style="width: 150px">{{ ol.ParentSectionName}} / {{ol.RowNumber | replaceOnEmpty}} / {{ol.SeatNumber | replaceOnEmpty }}</td>
            <td style="width: 150px">{{ ol.SelectedSingleEntranceName | replaceOnEmpty:'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.DEFAULT-ON-EMPTY' }}</td>
            <td style="width: 200px">{{ ol.TicketText | replaceOnEmpty:'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.DEFAULT-ON-EMPTY' }}</td>
            <td style="width: 150px">{{ ol.TicketTypeName }}</td>
            <td style="width: 280px"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(null, ol)"></t4-customer-identification-control></td>
            <td style="width: 130px">{{ ol.PriceVariationName }}</td>
            <td style="width: 80px" class="text-right">{{ ol.Price  | currency:'EUR' }}</td>
            <td style="width: 80px" class="text-right">{{ ol.CalculatedDiscount | percent:'1.0-2' | replaceOnEmpty }}</td>
            <td style="width: 80px" class="text-right">{{ ol.VAT  | currency:'EUR' }}</td>
            <td style="width: 80px" class="text-right">{{ ol.Total | currency:'EUR' }}</td>
          </tr>

        </tbody>
      </table>

    </div>

    <t4-pager (onPage)="doPage($event)" #pagerComponent></t4-pager>

  </t4-tab>

  <t4-tab tabTitle="SALES.ORDERS.BLOCKED.DIALOG.DETAILS.TAB.ADDITIONAL-INFO-C3V" id="additional" *ngIf="orderBlockedData.Source === 2">
    <div>
      <div class="col-12">
        <div class="important-info">
          <div class="row">
            <div class="col-12">
              <p class="info-data" style="white-space: pre-wrap;">{{ additionalInfoC3V | replaceOnEmpty }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </t4-tab>
</t4-tabs>
