import {LoggingService} from '@tymes4-shared';
import { OnInit, Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HelperService implements OnInit {

    constructor(
        private logging : LoggingService,
        private translate: TranslateService) { }

    ngOnInit() {
    }

    public setInheritedPriceCategoryNames(selectablePriceCategories) {

        var ipcs = selectablePriceCategories.filter(function(pc){ if (pc.Id == -1)  { return  pc; }});

        if (ipcs != null && ipcs.length > 0) {
            var ipc = ipcs[0];
            var name = (ipc.Name == "NOT_SET" ? "-" : ipc.Name);
            ipc.Name = this.translate.instant('COMPONENTS.COMMON.HELPER-SERVICE.TAKE-OVER', {name: name});
        }
    }
  }
