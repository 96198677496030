import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import {DiscountService} from "../api";

@Injectable()
export class DiscountSettingsValidator {
    constructor(private discountService: DiscountService) {}

    public editingObject: any;

    discountCodeTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        const v = control.value;

        setTimeout(() => {
          this.discountService.getDiscountByCode(v).subscribe(data => {
            if (data !== null && data !== void 0) {
              if (!this.editingObject || data.Code !== this.editingObject.DiscountCode) {
                resolve({'codetaken': data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
    }

    prefixTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        const v = control.value;

        setTimeout(() => {

          this.discountService.getDiscountByPrefix(v).subscribe((data:any) => {
            if (data !== null && data !== void 0) {
              if (!this.editingObject || data.GenericPrefix !== this.editingObject.GenericPrefix) {

                resolve({'prefixtaken': data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
    }

}
