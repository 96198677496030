
<div class="card card-default">
  <div class="card-header">
    <h2>{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.TITLE' | translate}}</h2>
  </div>
  <t4-tabs (selected)="onSelectedTabChange($event)">
    <t4-tab *ngFor="let language of languages; index as i;" tabTitle="{{language.Locale}}"
      id="{{languageIdPrefix}}{{language.Id}}"></t4-tab>
  </t4-tabs>
  <div class="card-body">
    <div class="form-group">

      <div class="row row-sm">

        <div class="col-lg-2">
          <div class="form-group">
            <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.BACKGROUND-IMAGE' | translate}}</label>
            <div class="form-control" style="height: unset;">
              <img src="{{ this.localValues.BackgroundImageUrl }}" (click)="selectedBackgroundFile.click()"
                style="width: 100%;  cursor: pointer;">
              <input type="file" (change)="uploadImage($event)" style="display: none" #selectedBackgroundFile
                [attr.key]="backgroundImageKey">
            </div>
            <button *ngIf="this.localValues.BackgroundImageUrl != noImageFoundUrl" (click)="removeImage('BackgroundImage')" class="btn btn-primary border btn-block" type="button">
              <i class="far fa-trash-alt"></i>
              {{'GENERIC.BUTTON.REMOVE' | translate}}
            </button>
          </div>
          <div class="form-group">
            <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.LOGO' | translate}}</label>
            <div class="form-control" style="height: unset;">
              <img src="{{ this.localValues.LogoUrl }}" (click)="selectedLogoFile.click()"
                style="width: 100%;  cursor: pointer;">
              <input type="file" (change)="uploadImage($event)" style="display: none" #selectedLogoFile
                [attr.key]="logoImageKey">
            </div>
            <button *ngIf="this.localValues.LogoUrl != noImageFoundUrl" (click)="removeImage('Logo')" class="btn btn-primary border btn-block" type="button">
              <i class="far fa-trash-alt"></i>
              {{'GENERIC.BUTTON.REMOVE' | translate}}
            </button>
          </div>
        </div>

        <div class="col-lg-10">

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.SALUTATION' | translate}}</label>
                <quill-editor name="Salutation" [(ngModel)]="localValues.Salutation" [sanitize]="false" [modules]="config" name="richTextContent" [styles]="{height: '100px'}">
                </quill-editor>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.CONCLUSION' | translate}}</label>
                <quill-editor name="Signature" [(ngModel)]="localValues.Signature" [sanitize]="false" [modules]="config"
                  name="richTextContent" [styles]="{height: '100px'}">
                </quill-editor>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.BACKGROUND-COLOR' | translate}}</label>
                <div>

                  <div fxFlex="30px">
                    <div class="color-picker-display" [(colorPicker)]="localValues.BackgroundColor"
                      [style.background]="localValues.BackgroundColor">&nbsp;</div>
                  </div>
                  <div class="pt-1" fxFlex>{{ localValues.BackgroundColor }}</div>

                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">{{'ADMINISTRATION.SYSTEM-SETTINGS.EMAIL-SETTINGS.FROM.LABEL.TEXT-COLOR' | translate}}</label>
                <div>

                  <div fxFlex="30px">
                    <div class="color-picker-display" [(colorPicker)]="localValues.FontColor"
                      [style.background]="localValues.FontColor">&nbsp;</div>
                  </div>
                  <div class="pt-1" fxFlex>{{ localValues.FontColor }}</div>

                </div>

              </div>
            </div>
          </div>
          <button (click)="submit()" class="btn btn-primary border btn-block" type="button">
            <i class="far fa-save"></i>
            {{'GENERIC.BUTTON.SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
