import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ɵConsole } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'app/shared/models/key-value-pair';
import { HttpLoaderService, PagedResult } from '@tymes4-shared';
import * as moment from 'moment';
import { PurchaseRightValidator } from './purchase-right.validator';
import {
  EventService,
  PurchaseRightService,
  PurchaseRightStateArgs,
  SalesChannelService,
  PassePartoutService,
  SaleCategoryService,
  TagService,
  InitiativeService,
  SalesPeriodService,
  VenueBuildingBlockService,
  VenueBuildingBlockPassePartoutPagedResult, VenueBuildingBlockPagedResult, PurchaseRight, TicketTypeService,
  PassePartout
} from "../../api";
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, skipWhile, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-edit-purchase-right-form',
  templateUrl: './edit-purchase-right-form.component.html',
  styleUrls: ['./edit-purchase-right-form.component.scss']
})
export class EditPurchaseRightFormComponent implements OnInit {
  private isNew: boolean;
  public form: FormGroup;

  public isSalesPeriod = false;
  public isEvent = false;
  public isInitiative = false;

  public selectedPurchaseRight: any = [];

  public targetPassePartouts: PassePartout[] = [];
  public originPassePartouts: any = [];
  public tags: any = [];
  public salesCategories: any = [];
  public salesChannels: any = [];
  public searchInPassePartoutId: number;

  public events: any;
  public salesperiods: any;
  public initiatives: any;
  public initialValuesVBB: any = [];

  public groupSelectorTypes : any = null;
  public isInArchiveMode = this.authService.isInArchiveMode();

  ticketTypeName: string = null;

  vbbLoading = false;
  venueBuildingBlocks: Observable<unknown>;
  vbbInput$ = new Subject<string>();

  validationMessages = {
    'TargetPassePartoutId': [
      {type: 'isInvalidPassePartout', message: 'FORM.VALIDATION.INVALIDPASSEPARTOUT'}
    ],
    'OriginPassePartoutId': [
      {type: 'isInvalidPassePartoutUnequal', message: 'FORM.VALIDATION.INVALIDPPUNEQUAL'}
    ],
    'AmountAbsolute': [
      {type: 'amountAbsoluteOneFilled', message: 'FORM.VALIDATION.AMOUNTABSOLUTEFILLED'}
    ],
    'AmountPerTicket': [
      {type: 'amountTicketOneFilled', message: 'FORM.VALIDATION.AMOUNTABSOLUTEFILLED'}
    ],
    'Transferable': [
      {type: 'oneTransferableType', message: 'FORM.VALIDATION.ONETRANSFERABLETYPE'},
      {type: 'transferableNamed', message: 'FORM.VALIDATION.TRANSFERABLENAMED'}
    ],
    'IsPrimary': [
      {type: 'isPrimaryOneFilled', message: 'FORM.VALIDATION.PRIMARYORCROSSSELL'},
      {type: 'isInvalidIsPrimary', message: 'FORM.VALIDATION.INVALIDPASSEPARTOUT'}
    ],
    'IsCrossSell': [
      {type: 'isCrossSellOneFilled', message: 'FORM.VALIDATION.PRIMARYORCROSSSELL'}
    ]
  };

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditPurchaseRightFormComponent>,
              public venueBuildingBlockService: VenueBuildingBlockService,
              private passePartoutService: PassePartoutService,
              private saleCategoryService: SaleCategoryService,
              private salesChannelService: SalesChannelService,
              private tagService: TagService,
              private purchaseRightService: PurchaseRightService,
              private ticketTypeService: TicketTypeService,
              private salesPeriodService: SalesPeriodService,
              private eventService: EventService,
              private initiativeService: InitiativeService,
              private loader: HttpLoaderService,
              private purchaseRightValidator: PurchaseRightValidator,
              private datePipe: DatePipe,
              private apploader: HttpLoaderService,
              private translate: TranslateService,
              private authService: AuthService) { }

  private requiredFields: KeyValuePair<boolean> = null;
  isRequiredField(field: string, targetForm: FormGroup = null): boolean {
    if (this.requiredFields === null) {
      this.requiredFields = {};
    }

    if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
      const form = targetForm ? targetForm : this.form;
      const formField = form.get(field);
      if (!formField.validator) {
        return false;
      }

      const validator = formField.validator({} as AbstractControl);
      this.requiredFields[field] = validator && validator.required;

      formField.validator(formField);
    }
    return this.requiredFields[field];
  }

  ngOnInit() {
    this.apploader.open();
    this.isNew = this.passedData.isNew;
    this.purchaseRightValidator.purchaseRightForNamedEntity = this.passedData.entityIsNamed;

    this.isSalesPeriod = this.passedData.salesPeriodId > -1;
    this.isEvent = this.passedData.eventId > -1;
    this.isInitiative = this.passedData.initiativeId > -1;

    if (this.passedData.payload !== null) {
      this.selectedPurchaseRight = this.passedData.payload;
    } else {
      this.selectedPurchaseRight = {};
    }

    this.groupSelectorTypes = [];
    this.groupSelectorTypes.push ({ Id: 'G', Name: this.translate.instant('DIALOGS.EDIT-PURCHASE-RIGHT.FORM.DROPDOWN.RELATIONS-WITH-TAG')})
    this.groupSelectorTypes.push ({ Id: 'H', Name: this.translate.instant('DIALOGS.EDIT-PURCHASE-RIGHT.FORM.DROPDOWN.CARD-HOLDER')})
    this.groupSelectorTypes.push ({ Id: 'P', Name: this.translate.instant('DIALOGS.EDIT-PURCHASE-RIGHT.FORM.DROPDOWN.CROWD-SLASH-EVERYONE')})


    this.form = new FormGroup({
      Id: new FormControl(0), // Passed to preserve value
      RightsType: new FormControl(''), // For display purposes
      SalesPeriodId: new FormControl(null),
      TargetEventId: new FormControl(null),
      SalesPeriodPassePartoutId: new FormControl(null), // To store the PP id if there is already a primary for the SalesPeriod for validation purposes
      TargetPassePartoutId: new FormControl({value: '', disabled: !this.isSalesPeriod && !this.isInitiative}, Validators.required),
      SaleCategoryId: new FormControl({value: '', disabled: !this.isEvent && !this.isInitiative}, Validators.required),
      SalesChannelId: new FormControl(null),
      AvailableFrom: new FormControl('', Validators.required),
      AvailableTill: new FormControl('', Validators.required),
      RightsFor: new FormControl('', Validators.required),
      OriginPassePartoutId: new FormControl('', Validators.required),
      TagId: new FormControl('', Validators.required),
      UnlimitedAmount: new FormControl(false),
      AmountAbsolute: new FormControl(null),
      AmountPerTicket: new FormControl(null),
      Transferable: new FormControl(false, null, this.purchaseRightValidator.ValidateTransferableNamed),
      IsPrimary: new FormControl(false),
      IsCrossSell: new FormControl(false),
      VenueBuildingBlockId: new FormControl(null, Validators.required),
      TicketTypeId: new FormControl(null, Validators.required),
      InitiativeId: new FormControl(null),
      RestrictToChannel: new FormControl(false),
    }, [ this.purchaseRightValidator.ValidateDates,
          this.purchaseRightValidator.ValidateAmounts,
          this.purchaseRightValidator.ValidateChecks,
          this.purchaseRightValidator.ValidatePassepartoutPrimary,
          this.purchaseRightValidator.ValidatePassepartoutUnequal]);

    if (this.isNew) {
      if (this.isSalesPeriod) {
        this.selectedPurchaseRight = { SalesPeriodId: this.passedData.salesPeriodId };
      } else if (this.isEvent) {
        this.selectedPurchaseRight = { TargetEventId: this.passedData.eventId };
      } else if (this.isInitiative) {
        this.selectedPurchaseRight = { RightsType: 'E', InitiativeId: this.passedData.initiativeId };
        this.rightsTypeChanged(null, 'E');
      }

      this.form.patchValue(this.selectedPurchaseRight);
      this.form.controls.RightsFor.setValue('P');
      this.SalesChannelIdChanged(null);
    } else {

      //make reselectable
      if (this.selectedPurchaseRight.TagId) {
        this.selectedPurchaseRight.TagId = 's' + this.selectedPurchaseRight.TagId
      }
      else if (this.selectedPurchaseRight.DynamicTagId) {
        this.selectedPurchaseRight.TagId = 'd' + this.selectedPurchaseRight.DynamicTagId
      }

      this.form.patchValue(this.selectedPurchaseRight);

      // fill/clean specific fields
      if (this.isInitiative) {
        if (this.selectedPurchaseRight.TargetEventId) {
          this.form.controls.RightsType.setValue('E');
          this.rightsTypeChanged(null, 'E');
        } else if (this.selectedPurchaseRight.SalesPeriodId) {
          this.form.controls.RightsType.setValue('S');
          this.rightsTypeChanged(null, 'S');
        }
      }

      if (this.selectedPurchaseRight.OriginPassePartoutId) {
        this.form.controls.RightsFor.setValue('H');
      } else if (this.selectedPurchaseRight.TagId || this.selectedPurchaseRight.DynamicTagId) {
        this.form.controls.RightsFor.setValue('G');
      } else {
        this.form.controls.RightsFor.setValue('P');
      }
      if (this.form.controls['AmountAbsolute'].value === 0) {
        this.form.controls['AmountAbsolute'].setValue('');
      }
      if (this.form.controls['AmountPerTicket'].value === 0) {
        this.form.controls['AmountPerTicket'].setValue('');
      }
    }

    this.unlimitedAmountChanged(null, this.selectedPurchaseRight.UnlimitedAmount);
    this.rightsForChanged(null, this.form.controls.RightsFor.value);
    this.crossSellChanged(null, this.selectedPurchaseRight.IsCrossSell);
    this.checkTransferableChanges(this.form.controls['AvailableFrom'].value, this.form.controls['AvailableTill'].value, this.form.controls['Transferable'].value);

    this.passePartoutService.getCurrentFuturePassePartouts().subscribe(data => {
      this.originPassePartouts = data;
      if (this.isSalesPeriod || this.isInitiative) {
        this.targetPassePartouts = data;
        if (this.form.controls['TargetPassePartoutId'].value !== '') {
          this.targetPassePartoutChanged(this.targetPassePartouts.find(p => p.Id === +this.form.controls['TargetPassePartoutId'].value));
        }
      }
      if(this.isInArchiveMode)
        this.form.disable();
    });

    if (this.isSalesPeriod) {
      var method = this.isNew ? this.purchaseRightService.getPrimaryPassePartoutInSalesPeriod(this.selectedPurchaseRight.SalesPeriodId) : this.purchaseRightService.getPrimaryPassePartoutInSalesPeriodById(this.selectedPurchaseRight.SalesPeriodId, this.selectedPurchaseRight.Id)
      method.subscribe(data => {
        this.form.controls['SalesPeriodPassePartoutId'].setValue(data);
      });
    }

    if (this.isEvent || this.isInitiative) {
      this.saleCategoryService.allForPurchaseRights().subscribe(data => {
        this.salesCategories = data;
      });
    }

    this.salesChannelService.searchSalesChannel('%%',1, 99999999, 'Id', false).subscribe(data => {
      this.salesChannels = data.Records;
    });

    if (this.isInitiative) {
      this.eventService.eventSearch(null,1, 99999999, 'Id', false).subscribe(data => {
        this.events = data.Records;
      });
      this.salesPeriodService.searchSalesPeriods('',1, 99999999, 'Id', false).subscribe(data => {
        this.salesperiods = data.Records;
      });
    } else {
      this.initiativeService.searchInitiatives(null,1, 99999999, 'Id', false).subscribe(data => {
        this.initiatives = data.Records;
      });
    }

    this.tagService.listSelectableTags().subscribe(data => {
      this.tags = postProcessTags(data);
    });

    if (this.selectedPurchaseRight.VenueBuildingBlockId) {
      this.loadVBBs({ Id: this.selectedPurchaseRight.VenueBuildingBlockId, Name: this.selectedPurchaseRight.VenueBuildingBlockName });
    } else {
      this.loadVBBs();
    }
    this.apploader.close();
  }

  rightsTypeChanged(e, setValue = '') {
    if (setValue === 'E' || (e !== null && e.target.value === 'E')) {
      this.isEvent = true;
      this.isSalesPeriod = false;
      this.form.controls['SaleCategoryId'].enable();
      this.form.controls['TargetPassePartoutId'].disable();
    } else if (setValue === 'S' || e !== null && e.target.value === 'S') {
      this.isEvent = false;
      this.isSalesPeriod = true;
      this.form.controls['SaleCategoryId'].disable();
      this.form.controls['TargetPassePartoutId'].enable();
    }
  }

  targetEventIdChanged(event: any) {
    if (event) {
      this.purchaseRightValidator.purchaseRightForNamedEntity = event.Named;

      if (this.purchaseRightValidator.purchaseRightForNamedEntity) {
        // clear the error
        this.form.controls.Transferable.setErrors(null);
        this.form.controls.Transferable.markAsTouched();
        this.checkTransferableChanges(this.form.controls['AvailableFrom'].value, this.form.controls['AvailableTill'].value, this.form.controls['Transferable'].value);
      }
    }
  }

  salesPeriodIdChanged(salesPeriod: any) {
    if (salesPeriod) {
      this.purchaseRightService.getPrimaryPassePartoutInSalesPeriod(salesPeriod.Id, this.selectedPurchaseRight.Id).subscribe(data => {
        this.form.controls['SalesPeriodPassePartoutId'].setValue(data);
      });
    }
  }

  targetPassePartoutChanged(passePartout : PassePartout) {
    if (passePartout) {
      this.purchaseRightValidator.purchaseRightForNamedEntity = passePartout.Named;
      if (this.purchaseRightValidator.purchaseRightForNamedEntity) {
        // clear the error
        this.form.controls.Transferable.setErrors(null);
        this.form.controls.Transferable.markAsTouched();
        this.checkTransferableChanges(this.form.controls['AvailableFrom'].value, this.form.controls['AvailableTill'].value, this.form.controls['Transferable'].value);

        if (this.form.controls['IsCrossSell'].value) {
          this.form.controls['VenueBuildingBlockId'].enable();
          this.form.controls['TicketTypeId'].enable();
        }
      }

      this.searchInPassePartoutId = passePartout.Id;
    }
  }

  transferableChanged(e) {
    this.checkTransferableChanges(this.form.controls['AvailableFrom'].value, this.form.controls['AvailableTill'].value, e.target.checked);
  }

  DatesChanged(e) {
    this.checkTransferableChanges(this.form.controls['AvailableFrom'].value, this.form.controls['AvailableTill'].value, this.form.controls['Transferable'].value);
  }


  checkTransferableChanges(fromDateTime, tillDateTime, transferable) {

    let availableFrom = null;
    let availableTill = null;

    if (fromDateTime) {
      availableFrom = moment.utc(fromDateTime);
    }
    if (tillDateTime) {
      availableTill = moment.utc(tillDateTime);
    }

    if (availableFrom && availableFrom.isValid() && availableTill && availableTill.isValid()) {
      const salesPeriodId = this.form.controls['SalesPeriodId'] !== undefined ? this.form.controls['SalesPeriodId'].value : null;
      const eventId = this.form.controls['TargetEventId'] !== undefined ? this.form.controls['TargetEventId'].value : null;
      let args : PurchaseRightStateArgs = {
        Id: this.form.controls['Id'].value,
        Checked: (transferable == true), //force boolean type
        From: availableFrom,
        Till: availableTill,
        SalesPeriodId: salesPeriodId == "" ? null : salesPeriodId,
        EventId: eventId
      };
        // = {this.form.controls['Id'].value, transferable, availableFrom, availableTill, salesPeriodId, eventId};
      this.purchaseRightService.checkForActiveTransferableAllowed(args).subscribe(data => {
        if (data !== null && data !== void 0 && data) {
          if (this.form.controls.Transferable.hasError('oneTransferableType')) {
            this.form.controls.Transferable.setErrors(null);
            this.form.controls.Transferable.markAsTouched();
          }
        } else {
          this.form.controls.Transferable.setErrors({oneTransferableType: true});
          this.form.controls.Transferable.markAsTouched();
        }
      });
    }
  }

  unlimitedAmountChanged(e, setValue = false) {
    if (setValue || (e !== null && e.target.checked)) {
      this.form.controls['AmountAbsolute'].setValue('');
      this.form.controls['AmountPerTicket'].setValue('');
      this.form.controls['AmountAbsolute'].disable();
      this.form.controls['AmountPerTicket'].disable();
    } else {
      this.form.controls['AmountAbsolute'].enable();
      this.form.controls['AmountPerTicket'].enable();
    }
  }

  rightsForChanged(e, setValue = '') {


    if (setValue === 'G' || (e !== null && e.Id === 'G')) {
      this.form.controls['OriginPassePartoutId'].disable();
      this.form.controls['TagId'].enable();
      this.form.controls['AmountPerTicket'].setValue('');
      this.form.controls['AmountPerTicket'].disable();
    } else if (setValue === 'H' || e !== null && e.Id === 'H') {
      this.form.controls['OriginPassePartoutId'].enable();
      this.form.controls['TagId'].disable();
      if (this.form.controls['UnlimitedAmount'].value) {
        this.form.controls['AmountPerTicket'].disable();
      } else {
        this.form.controls['AmountPerTicket'].enable();
      }
    } else {
      this.form.controls['OriginPassePartoutId'].disable();
      this.form.controls['TagId'].disable();
      this.form.controls['AmountPerTicket'].setValue('');
      this.form.controls['AmountPerTicket'].disable();
    }
  }

  crossSellChanged(e, setValue = false) {
    if (setValue || (e !== null && e.target.checked && (!this.isRequiredField('TargetPassePartoutId') || this.form.controls['TargetPassePartoutId'].value))) {
      this.form.controls['VenueBuildingBlockId'].enable();
      this.form.controls['TicketTypeId'].enable();
    } else {
      this.form.controls['VenueBuildingBlockId'].disable();
      this.form.controls['TicketTypeId'].disable();
    }
  }

  VenueBuildingBlockChange(item) {
    if (item) {
      this.ticketTypeService.getFirstUnplacedForVBB(item.Id).subscribe(data => {

        if (data !== null) {
          this.form.controls['TicketTypeId'].setValue(data.Id);
          this.ticketTypeName = data.Name;
        } else {
          this.form.controls['TicketTypeId'].setValue(null);
          this.ticketTypeName = null;
        }
      });
    }
  }

  SalesChannelIdChanged(salesChannelId)
  {
    if (salesChannelId)
    {
      this.form.controls['RestrictToChannel'].enable();
    }
    else
    {
      this.form.controls['RestrictToChannel'].disable();
    }

  }

  private loadVBBs(vbb: any = null) {
    const prefilled = vbb ? [vbb] : [];
    this.venueBuildingBlocks = concat(
      of(prefilled), // default items
      this.vbbInput$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => { this.vbbLoading = true; }),
        switchMap(term => {
          let request : Observable<PagedResult> = this.searchInPassePartoutId
            ? this.venueBuildingBlockService.searchVenueBuildingBlocksForPassePartout(term, 1, 100, 'Name', true, { passePartoutId: this.searchInPassePartoutId.toString() })
            : this.venueBuildingBlockService.searchVenueBuildingBlocks(term, 1, 100, 'Name', true);
          return request.pipe(
              catchError(() => of({ Records: [] })), // empty list on error
              map(f => f.Records),
              tap(() => { this.vbbLoading = false; })
            );
        })
      )
    );
  }

  checkSearchableCrossSell() {
    if (!this.form.controls['TargetPassePartoutId'].value && this.form.controls['IsCrossSell'].value) {
      return false;
    }
    return true;
  }

  submit() {
    const pr = this.form.value;
    this.loader.open();


    if (pr.RightsFor === 'G') {
      //based on tag. We need to check the selected purchaseright to see what kind of tag is selected.
      pr.OriginPassePartoutId = null;

      if (pr.TagId.startsWith('d'))  {
        //dynamic tag
        pr.DynamicTagId = +pr.TagId.replace('d', '');
        pr.TagId = null;
      }
      else {
          //dynamic tag
          pr.DynamicTagId = null;
          pr.TagId = +pr.TagId.replace('s', '');
      }

    }
    else if (pr.RightsFor === 'H') {
      pr.TagId = null;
    }

    if (pr.IsCrossSell && pr.VenueBuildingBlockId !== null) {

    } else {
      pr.VenueBuildingBlockId = null;
      pr.TicketTypeId = null;
    }

    // Convert fields to null if they are strings
    if (typeof pr.DynamicTagId === 'string') {
      pr.DynamicTagId = null;
    }
    if (typeof pr.TagId === 'string') {
      pr.TagId = null;
    }
    if (typeof pr.OriginPassePartoutId === 'string') {
      pr.OriginPassePartoutId = null;
    }
    if (typeof pr.AmountPerTicket === 'string') {
      pr.AmountPerTicket =
        null;
    }
    if (typeof pr.AmountAbsolute === 'string') {
      pr.AmountAbsolute = null;
    }
    if (typeof pr.TargetPassePartoutId === 'string') {
      pr.TargetPassePartoutId = null;
    }
    if (typeof pr.TargetEventId === 'string') {
      pr.TargetEventId = null;
    }
    if (typeof pr.VenueBuildingBlockId === 'string') {
      pr.VenueBuildingBlockId = null;
    }
    if (typeof pr.TicketTypeId === 'string') {
      pr.TicketTypeId = null;
    }
    if (typeof pr.SaleCategoryId === 'string') {
      pr.SaleCategoryId = null;
    }
    if (typeof pr.SalesPeriodId === 'string') {
      pr.SalesPeriodId = null;
    }
    if (typeof pr.InitiativeId === 'string') {
      pr.InitiativeId = null;
    }
    if (typeof pr.SalesChannelId === 'string') {
      pr.SalesChannelId = null;
    }
    if (typeof pr.IsPrimary === 'string') {
      pr.IsPrimary = false;
    }
    if (typeof pr.IsCrossSell === 'string') {
      pr.IsCrossSell = false;
    }
    let request: PurchaseRight = pr;

    pr.RestrictToChannel = (pr.RestrictToChannel === true); //force a boolean type

    if (this.isNew) {
      this.purchaseRightService.createPurchaseRight(request).subscribe((id: any) => {
        this.loader.close();
        this.dialogRef.close(true);
      });
    } else {
      this.purchaseRightService.updatePurchaseRight(request).subscribe((id: any) => {
        this.loader.close();
        this.dialogRef.close(true);
      });
    }
  }
}
function postProcessTags(data: any): any {

  for (let tag of data) {
    if (tag.TagType == 1) tag.Id = 's' + tag.Id; //static tag
    else if (tag.TagType == 2) tag.Id = 'd' + tag.Id; //dynamic tag
  }

  return data;
}

