<div class="row modal-bar">
  <div class="col-sm-9">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-3">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>



  <div class="section-wrapper">

    <div class="my-2" id="contingent-scrollwindow" style="max-height: 500px; overflow-y: scroll;"> <!-- holder for all contingents-->

      <div class="form-layout">  

        <div class="row py-4">

          <div class="col-lg-9">
            <b>{{'DIALOGS.CHANGE-UNPLACED-CONTINGENTS.NONE' | translate}}</b>
          </div>

          <div class="col-lg-3">
            {{ this.noRemaining }} /  {{ this.total }}
          </div>
        </div>

        <div class="row" *ngFor="let contingent of contingents">

          <div class="col-lg-9">
            <b>{{ contingent.Name }}</b><br />
            <span class="tx-12">{{'DIALOGS.CHANGE-UNPLACED-CONTINGENTS.SOLD' | translate}}{{ contingent.CountOrders }} {{'DIALOGS.CHANGE-UNPLACED-CONTINGENTS.RESERVED' | translate}} {{ contingent.CountReservations }})</span>
          </div>

          <div class="col-lg-3">
            
            <div class="form-group text-right">
              
              <input min="0" [max]="contingent.Max" [value]="contingent.Number" (change)="changeValue(contingent.Id, $event)" type="number" class="form-control" name="number">
            </div>

          </div>
        </div>

      </div>

    </div>
    <div class="form-layout">  
      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="saving || parentVBBHasPlacements"  class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button  (click)="dialogRef.close(false);"class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>  
