import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ETicketTemplateValidator } from './etickettemplate.validator';
import { TranslateService } from '@ngx-translate/core';
import { ETicketTemplate, ETicketTemplateService } from '../../api';

@Component({
  selector: 'app-edit-etickettemplate-form',
  templateUrl: './edit-etickettemplate-form.component.html'
})
export class EditETicketTemplateFormComponent implements OnInit {
  public form: FormGroup;

  public eTicketTemplates: ETicketTemplate[] = [];
  public isNewObject = false;
  public ticketTemplateType: number = null;

  private formObject = null;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditETicketTemplateFormComponent>,
    private eTicketTemplateService: ETicketTemplateService,
    private eTicketTemplateValidator: ETicketTemplateValidator,
    private translate: TranslateService,
    private loader: HttpLoaderService) { }

  public barcodeBlockTypes = [
    { Name: this.translate.instant('MOBILETICKET.BARCODEBLOCKTYPE.NONE.DESCRIPTION'), Id: 1 },
    { Name: this.translate.instant('MOBILETICKET.BARCODEBLOCKTYPE.ONMATCHDAY.DESCRIPTION'), Id: 2 },
    { Name: this.translate.instant('MOBILETICKET.BARCODEBLOCKTYPE.TIMEBASED.DESCRIPTION'), Id: 3 }
  ];

  validationMessages = [];

  ngOnInit() {

    this.ticketTemplateType = this.passedData.ticketTemplateType;

    if (this.passedData.editedObject == null) {
      //Create a new object to bound to the form
      this.retrieveETicketTemplates();
      this.formObject = {};
      this.isNewObject = true;
    }
    else {
      this.formObject = this.passedData.editedObject;
      this.isNewObject = false;
    }

    this.form = new FormGroup({
      Name: new FormControl('', { validators: Validators.required, asyncValidators: [this.eTicketTemplateValidator.eTicketTemplateNameTaken.bind(this, {'currentName': this.isNewObject ? null : this.formObject.Name})], updateOn: "blur" }),
      CurrentTemplateId: new FormControl({value: null, disabled: !this.isNewObject}, Validators.required),
      TicketTemplateTypeId: new FormControl(this.ticketTemplateType),
      BarcodeBlockType: new FormControl(1, Validators.required)
    });

    this.form.patchValue(this.formObject);
  }

  retrieveETicketTemplates() {

    this.loader.open();

    this.eTicketTemplateService.listETicketTemplatesByType(this.ticketTemplateType).subscribe(templates => {
      this.eTicketTemplates = templates;
      this.loader.close();
    });
  }

  submit() {
    var propertyObject = this.form.value;

    if (propertyObject.CurrentTemplateId === -1) {
      propertyObject.CurrentTemplateId = null;
    }

    this.dialogRef.close(propertyObject);
  }
}
