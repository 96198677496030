import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DataService, EnvironmentService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class DatabaseScaleUpService extends DataService {
  
  constructor(private httpClient: HttpClient, logging: LoggingService, environmentService: EnvironmentService) {
    super(httpClient, environmentService);
    this.entityName = 'database-scale-up';
  }

  getSelectableTiers(IsPlanned: boolean = true) {
    const isPlannedIndicator = IsPlanned ? '1' : '0';
    const url = this.getUrl() + `/selectable-database-tiers/${isPlannedIndicator}`;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  getScaleUpStatistics(seasonId) {
    const url = this.getUrl() + `/stats/${seasonId}`;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  validateDatabaseScaleUp(scaleUpDate, noOfDays)
  {
    const url = this.getUrl() + `/validate-databasescaleup/${noOfDays}`;
    return this.http.post(url, JSON.stringify(scaleUpDate) ,{headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  validateDirectDatabaseScaleUp(noOfDays)
  {
    const url = this.getUrl() + `/validate-direct-databasescaleup/${noOfDays}`;
    return this.http.post(url, null, {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  directDatabaseScaleUp(databaseScaleUp)
  {
    const url = this.getUrl() + `/direct-databasescaleup`;
    return this.http.post(url, JSON.stringify(databaseScaleUp), {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  createDatabaseScaleUp(databaseScaleUp)
  {
    const url = this.getUrl() + `/create-databasescaleup`;
    return this.http.post(url, JSON.stringify(databaseScaleUp), {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  retryDatabaseScaleUp(id)
  {
    const url = this.getUrl() + `/retry-databasescaleup/${id}`;
    return this.http.post(url, null, {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }

  deleteDatabaseScaleUp(id)
  {
    const url = this.getUrl() + `/${id}`;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError));

  }

  calculateCreditsAndPrice(newTierId, noOfDays)
  {
    const url = this.getUrl() + `/calculate-credits-price/${newTierId}/${noOfDays}`;
    return this.http.get(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError));
  }
  

}
 