import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-activity-log-dialog',
  templateUrl: 'activity-log-dialog.component.html'
})

export class ActivityLogDialogComponent implements OnInit {

  public resourceId: number;
  public activityType: number;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<ActivityLogDialogComponent>,) {
  }

  ngOnInit() {
    this.resourceId = this.passedData.resourceId;
    this.activityType = this.passedData.activityType;
  }
}
