<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{'BOX-OFFICE.CUSTOMER-BARCODE-SEARCH-DIALOG.COMPONENT.TITLE.SCAN-BARCODE' | translate}}</h5>
    </div>

    <div class="col-sm-6">

        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row mt-3">
  <div class="col-xl-12 col-lg-12">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <span class="input-group-text bd-0"><i class="fas fa-search"></i></span>
      </div>
      <input id="barcode" (keyup.enter)="onKey($event);" (focusout)="onKey($event);" class="form-control bd-0"  placeholder="{{'BOX-OFFICE.CUSTOMER-BARCODE-SEARCH-DIALOG.COMPONENT.LISTVIEW.PLACEHOLDER.SEARCH-BARCODE' | translate}}" type="text">
    </div>
  </div>
  <div class="form-content mb-2">
    <span class="mb-3 col-lg-12">{{ 'BTN.SEARCH.BARCODE.INFO' | translate }}</span>
    <div class="col-lg-12 pt-0 mb-5" *ngIf="notFound">

      <span class="tx-danger">{{'BOX-OFFICE.CUSTOMER-BARCODE-SEARCH-DIALOG.COMPONENT.LISTVIEW.WARNING-MESSAGE.BARCODE-NOT-FOUND' | translate}}</span>
    </div>
  </div>
</div>

<!-- Buttons -->
<div class="row">

  <div class="col-6">
    <button class="btn btn-block btn-primary bd-0">
      <i class="fas fa-search"></i>
      <span>{{'BOX-OFFICE.CUSTOMER-BARCODE-SEARCH-DIALOG.COMPONENT.LISTVIEW.BUTTON.SEARCH' | translate}}</span>
    </button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>
  </div>
</div>

<!--<t4-customer-search viewMode="minimal" (onCustomerSelect)="onCustomerSelect($event)" ></t4-customer-search>-->


