<form [formGroup]="form">

  <div class="card card-table order-height">

    <div class="card-header">
      <h6>{{'SALES.ORDERS.NEW.SALES-MEMBERSHIP-SELECTION.HEADER.SELECT-A-MEMBERSHIP' | translate}}</h6>
    </div>

    <div class="py-3 px-6 bd-b">
      <div class="row">
        <div class="col-lg-12">

          <ng-select [items]="memberships" bindLabel="Name" bindValue="Id" formControlName="MembershipId" placeholder="{{'SALES.ORDERS.NEW.SALES-MEMBERSHIP-SELECTION.MEMBERSHIP-DROPDOWN.PLACEHOLDER' | translate}}"
                      [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>

        </div>

        <div class="col-lg-12" style="min-height: 250px;"></div>

        <div class="col-lg-8"></div>

        <div class="col-lg-4">
          <button class="px-0 btn btn-primary btn-block btn-small mt-1" (click)="doAction()" [disabled]="form.invalid">
            <i class="fas fa-cart-plus"></i>
            {{'GENERIC.BUTTON.ADD-TO-ORDER' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

</form>
