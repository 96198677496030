<div *ngIf="!this.isSalesPeriod && !this.evId; else show">
    {{'REPURCHASE.RESERVATIONS.UNAVAILABLE' | translate}}
   </div>
   
   <ng-template #show>
   
     <app-long-running-operation-progress-bar (onProcessFinished)="progressFinished($event)" #progressBar></app-long-running-operation-progress-bar>
   
   
     <t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">
   
      <ng-container *t4ListActions>
        <div class="object-actions bg-white text-right">
          <t4-lro-button *ngIf="!isInArchiveMode" (onLROClick)="importOpenReservations()" (onLROComplete)="refreshListAndButtons()" [icon]="'fas fa-file-export'" [text]="'REPURCHASE.RESERVATIONS.BUTTON.IMPORT'" [lroTypeCode]="longRunningOperationTypeEnum.ImportOpenReservationImportFile" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
          <t4-lro-button *ngIf="!isInArchiveMode" (onLROClick)="processReservations()" (onLROComplete)="refreshListAndButtons()" [icon]="'fas fa-file-export'" [text]="getProcessable()" [lroTypeCode]="longRunningOperationTypeEnum.ProcessOpenReservations" cssClasses="btn btn-primary border btn-small" [isDisabled]="processableCount <= 0"></t4-lro-button>
          <t4-lro-button (onLROClick)="exportList()" [disabled]="(latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" [icon]="'fas fa-file-import'" [text]="'REPURCHASE.RESERVATIONS.BUTTON.EXPORT'"cssClasses="btn btn-primary border btn-small" [lroTypeCode]="longRunningOperationTypeEnum.CreateOpenReservationOrderExportFileForEvent"></t4-lro-button>
          <button  *ngIf="!isInArchiveMode"(click)="deleteUnprocessed()" [disabled]="(latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" class="btn btn-primary border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'REPURCHASE.RESERVATIONS.BUTTON.DELETE.UNPROCESSED' | translate}}</button>
        </div>
      </ng-container>
   
       <ng-container *t4ListItem="let item">
   
         <th>{{item.ReservationLineId }}</th>
         <td>{{item.ParentSectionName | replaceOnEmpty }}</td>
         <td>{{item.RowNumber | replaceOnEmpty }}</td>
         <td>{{item.SeatNumber | replaceOnEmpty }}</td>
         <td>{{item.PlaceSelectionAllowed | booleantype }}</td>
         <td *ngIf="isSalesPeriod">{{item.PassePartoutName | replaceOnEmpty }}</td>
         <td>{{item.CustomerName | replaceOnEmpty }}</td>
         <td>{{item.SystemExpiration | localDateTime | replaceOnEmpty}}</td>
         <td>{{item.Description | replaceOnEmpty }}</td>
         <td>{{item.Status | replaceOnEmpty }}</td>
   
       </ng-container>
   
     </t4-list-view>
   
   </ng-template>
   