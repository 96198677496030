<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">

      <div class="card-body-height">
        <div class="row ">

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.NAME' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('Name')">*</span></label>
              <input
                class="form-control"
                name="Name"
                autocomplete="off"
                formControlName="Name"
                placeholder="{{'FINANCE.DISCOUNTS.FIELDS.NAME' | translate}}">
              <t4-display-validation controlName="Name" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.SHOPNAME' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('ShopDisplayName')">*</span></label>
              <input
                class="form-control"
                name="ShopDisplayName"
                autocomplete="off"
                formControlName="ShopDisplayName"
                placeholder="{{'FINANCE.DISCOUNTS.FIELDS.SHOPNAME' | translate}}">
              <t4-display-validation controlName="ShopDisplayName" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.DISCOUNTTYPE' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('CodeType')">*</span></label>

              <ng-select [items]="discountTypes" bindLabel="Name" bindValue="Id" formControlName="CodeType" placeholder="{{'FINANCE.DISCOUNTS.FIELDS.DISCOUNTTYPE.PLACEHOLDER' | translate}}"
                         [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="onDiscountTypeChange($event)"></ng-select>

              <t4-display-validation controlName="CodeType" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-6" *ngIf="isSingleCode()">
            <div class="form-group">
              <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.DISCOUNTCODE' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('DiscountCode')">*</span></label>
              <input
                class="form-control"
                trim
                name="DiscountCode"
                autocomplete="off"
                formControlName="DiscountCode"
                placeholder="{{'FINANCE.DISCOUNTS.FIELDS.DISCOUNTCODE' | translate}}">
              <t4-display-validation controlName="DiscountCode" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>

</form>
