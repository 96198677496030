<div *ngIf="!this.isSalesPeriod && !this.evId; else show">
 {{'REPURCHASE.RESERVATIONS.UNAVAILABLE' | translate}}
</div>

<ng-template #show>

  <app-long-running-operation-progress-bar (onProcessFinished)="progressFinished($event)" #progressBar></app-long-running-operation-progress-bar>


  <t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListActions>
      <div class="object-actions bg-white text-right">
        <button (click)="exportRebuy()" [disabled]="(latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" class="btn btn-primary border btn-small" type="button"><i class="fas fa-file-export"></i>{{'REPURCHASE.RESERVATIONS.BUTTON.CREATE.IMPORT-FILE' | translate}}</button>
        <t4-lro-button (onLROClick)="importRepurchase()" (onLROComplete)="listVw.refreshData()" [icon]="'fas fa-file-export'" [text]="'REPURCHASE.RESERVATIONS.BUTTON.IMPORT'" [lroTypeCode]="longRunningOperationTypeEnum.ImportReservationImportFile" cssClasses="btn btn-primary border btn-small"></t4-lro-button>
        <t4-lro-button (onLROClick)="procesReservations()" (onLROComplete)="listVw.refreshData()" [icon]="'fas fa-file-export'" [text]="getProcessable()" [lroTypeCode]="longRunningOperationTypeEnum.ProcessReservationImport" cssClasses="btn btn-primary border btn-small" [isDisabled]="processableCount <= 0"></t4-lro-button>
        <button (click)="exportList()" [disabled]="(latestLongRunningOperation !== null && latestLongRunningOperation.IsRunning === true)" class="btn btn-primary border btn-small" type="button"><i class="fas fa-file-import"></i>{{'REPURCHASE.RESERVATIONS.BUTTON.EXPORT' | translate}}</button>
        <button (click)="deleteUnprocessed()" [disabled]="disableDeleteUnprocessedButton" class="btn btn-primary border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'REPURCHASE.RESERVATIONS.BUTTON.DELETE.UNPROCESSED' | translate}}</button>
      </div>
    </ng-container>


    <ng-container *t4ListItem="let item">

      <th>{{item.Id }}</th>
      <td>{{item.SectionName | replaceOnEmpty }}</td>
      <td>{{item.RowNumber | replaceOnEmpty }}</td>
      <td>{{item.SeatNumber | replaceOnEmpty }}</td>
      <td>{{item.AllowSeatSelection | booleantype }}</td>
      <td *ngIf="isSalesPeriod">{{item.ToPassePartoutName | replaceOnEmpty }}</td>
      <td>{{item.CustomerName | replaceOnEmpty }}</td>
      <td>{{item.ExpiryDateTime | localDateTime}}</td>
      <td>{{item.Status}} - {{ getStatusName(item.Status) }}</td>
      <td>{{item.Reason | replaceOnEmpty }}</td>


      <td class="grid-action" >
        <button [disabled]="item.Status === 3 || disableDeleteSelectedButton" (click)="actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
      </td>

    </ng-container>

  </t4-list-view>

</ng-template>
