/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarketplacePriceSettingsModel { 
    PriceCategoryId?: number;
    PriceCategoryName?: string | null;
    MarketplacePolicyId?: number | null;
    MarketplacePriceRangeId?: number | null;
    MinPrice?: number | null;
    MaxPrice?: number | null;
    ClubFeeFixed?: number | null;
    ClubFeePerc?: number | null;
    MarketplacePriceVariantIds?: Array<number> | null;
}

