import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MailTemplate, MailTemplateService } from '../api';

@Injectable()
export class MailTemplateValidator {
    constructor(private mailTemplateService: MailTemplateService) {}

    public editingObject: MailTemplate;

    codeTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            const v = control.value;
            
            this.mailTemplateService.getTemplateByTypeCode(v).subscribe((data: Array<MailTemplate>) => {
               if (data != null && (data.length > 1 || (data.length == 1 && data[0].Id != null))) {
                    
                    // if (!this.editingObject || (data.Id != this.editingObject.Id)) {
                        resolve({'codetaken': data});
                    // }
                }
                resolve(null)
            });
        });
    }

}