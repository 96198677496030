import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ActiveConfigurationSettingView, SystemConfigurationSettingsService } from '../../api';

@Injectable()
export class ConfigurationSettingsValidator{
    constructor(private settingsService: SystemConfigurationSettingsService) {}

    public editingObject : any;

    settingsKeyTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            setTimeout(() => {
                this.settingsService.getConfigurationSettingByKey(control.value).subscribe((data: ActiveConfigurationSettingView)=> {
                    if (data !== null && data !== void 0) {
                        if (data.Key != this.editingObject.Key) {
                             resolve({'keytaken': data});
                        }
                    }
                    resolve(null);
                });
            }, 200);
        });
    }
}
