import { TranslateService } from '@ngx-translate/core';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PagedResult, ProductTypeEnum, SearchDataRequest, localDateTimePipe } from '@tymes4-shared';
import { DatePipe } from '@angular/common';
import { InvoiceBatchInvoicesView, InvoiceBatchService } from '../../api';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {

  @ViewChild('listVw') listVw;
  public accountingExportEnabled: boolean = false;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    disableSelect: true,
    disableSort: true,
    multiSelectMode: false,
    pageSize: 10,
    headers: [
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.ORDER-NR', width: 90 },
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.DESCRIPTION', width: 300 },
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.TYPE', width: 100 },
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.SECTION', width: 40},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.ROW-SEAT', width: 120},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.CUSTOMER', width: 180},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.PRICE-VARIANT', width: 70},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.PRICE', width: 70, class: 'text-right'},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.DISCOUNT-PERC', width: 70, class: 'text-right'},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.VAT', width: 70, class: 'text-right'},
      {sortField: null, label: 'SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.HEADER.TOTAL', width: 70, class: 'text-right'},
    ]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<InvoiceDetailsComponent>,
    private invoiceBatchService: InvoiceBatchService,
    private datePipe: DatePipe,
    private localDateTimePipe: localDateTimePipe,
    private translate: TranslateService
  ) {
  }

  public loading: boolean = false;
  public invoicingOrder: InvoiceBatchInvoicesView;

  ngOnInit() {
    this.invoicingOrder = this.passedData.payload;
    this.accountingExportEnabled = this.passedData.accountingExportEnabled;
  }

  onDataRequest(e: SearchDataRequest) {

    this.invoiceBatchService.getBatchOrderDetails(this.invoicingOrder.Id, this.invoicingOrder.InvoiceId).subscribe((data) => {
      data = data.map(d => {
        if (d.TicketTypeName == null) {
          switch (d.ProductType) {
            case ProductTypeEnum.DeliveryMethod:
              d.TicketTypeName = 'PRODUCT-TYPE.DELIVERY-METHOD';
              break;
            case ProductTypeEnum.PaymentMethod:
              d.TicketTypeName = 'PRODUCT-TYPE.PAYMENT-METHOD';
              break;
          }
        }
        return d;
      });

      const length = data.length;
      const paged = data.slice((e.pageNr - 1) * e.pageSize, e.pageNr * e.pageSize);

      const dataSource : PagedResult = {
        Records: paged,
        ResultCountCapped: false,
        TotalRecords: length
      };
      this.listVw.Data = dataSource;
    });
  }

  displayDescription(eventStartDateTime, eventName, passePartoutName, membershipName) {
    let descriptionText = '';
    if (eventName) {
      descriptionText = this.localDateTimePipe.transform(eventStartDateTime) + ': ' + eventName;
    } else if (passePartoutName) {
      descriptionText = passePartoutName;
    } else {
      descriptionText = membershipName;
    }
    return descriptionText;
  }

  displayExportStatusText(accountingExportDate, accountingExportInvoiceId, accountingExportMessage) {
    let statusText = '';
    if (accountingExportDate) {
      statusText = this.translate.instant('SALES.ORDERS.INVOICING.INVOICE.DETAIL.LISTVIEW.STATUS.SUCCESS-ID', {invoiceId: accountingExportInvoiceId});
    } else if (accountingExportMessage) {
      statusText = accountingExportMessage;
    } else {
      statusText = '-';
    }
    return statusText;
  }
}

interface InvoiceBatchOrderLines {
  InvoiceId: number;
  InvoiceBatchId: number;
  OrderId: number;
  CustomerId: number;
  TicketCustomer: string;
  CalculatedDiscount: number;
  MembershipName: string;
  PassePartoutName: string;
  EventName: string;
  EventStartDateTime: string;
  ParentSectionName: string;
  AmountExVat: number;
  AmountInclVat: number;
  VAT: number;
  VATRateId: number;
  PriceVariationName: string;
  ProductType: ProductTypeEnum;
  RowNumber: string;
  SeatNumber: string;
  TicketTypeName: string;


}










