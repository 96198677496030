/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscountRejectionReason = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const DiscountRejectionReason = {
    NUMBER_0: 0 as DiscountRejectionReason,
    NUMBER_1: 1 as DiscountRejectionReason,
    NUMBER_2: 2 as DiscountRejectionReason,
    NUMBER_3: 3 as DiscountRejectionReason,
    NUMBER_4: 4 as DiscountRejectionReason,
    NUMBER_5: 5 as DiscountRejectionReason,
    NUMBER_6: 6 as DiscountRejectionReason,
    NUMBER_7: 7 as DiscountRejectionReason,
    NUMBER_8: 8 as DiscountRejectionReason
};

