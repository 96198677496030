/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TicketCategoryEnum = 'MyTicket' | 'FreeTicket' | 'Invites' | 'TicketsOnExchange';

export const TicketCategoryEnum = {
    MyTicket: 'MyTicket' as TicketCategoryEnum,
    FreeTicket: 'FreeTicket' as TicketCategoryEnum,
    Invites: 'Invites' as TicketCategoryEnum,
    TicketsOnExchange: 'TicketsOnExchange' as TicketCategoryEnum
};

