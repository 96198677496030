

<div class="row modal-bar">
    <div class="col-sm-9">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-3">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  
  
  <form [formGroup]="form" class="form-content"> 
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">
  
           <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.CROSSSELL.PRODUCT.EVENT.CATEGORY' | translate}} </label>
                <ng-select [items]="categories" bindLabel="Name" bindValue="Id" formControlName="CategoryId" placeholder="{{'EDIT.CROSSSELL.PRODUCT.EVENT.PLACEHOLDER.CATEGORY' | translate}}"
                            notFoundText="{{'EDIT.CROSSSELL.PRODUCT.EVENT.NOT-FOUND-TEXT.NO-CATEGORY' | translate}}" appendTo="body" (change)="onCategoryChange($event)"></ng-select>
            </div>
          </div>

        </div>
        <div class="row">
  
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.CROSSSELL.PRODUCT.EVENT.PRODUCT' | translate}} </label>
                <ng-select [items]="products" bindLabel="Name" bindValue="Id" formControlName="ProductId" placeholder="{{'EDIT.CROSSSELL.PRODUCT.EVENT.PLACEHOLDER.PRODUCT' | translate}}"
                            notFoundText="{{'EDIT.CROSSSELL.PRODUCT.EVENT.NOT-FOUND-TEXT.NO-PRODUCTS' | translate}}" appendTo="body" (change)="onProductChange($event)"></ng-select>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'EDIT.CROSSSELL.PRODUCT.EVENT.VARIANTS' | translate}} : <span class="tx-danger">*</span></label>
                <ng-select [items]="variants" bindLabel="Name" bindValue="Id" formControlName="ProductVariants" placeholder="{{'EDIT.CROSSSELL.PRODUCT.EVENT.VARIANTS' | translate}}"
                [clearable]="true" [multiple]="true" notFoundText="{{'EDIT.CROSSSELL.PRODUCT.EVENT.NOT-FOUND-TEXT.NO-VARIANTS' | translate}}" appendTo="body"></ng-select>
                
            </div>
          </div>
        </div>

        <div class="row">

          
          <div class="col-lg-12">
            <t4-date-time-control formControlName="OnSaleFrom" [labelDate]="'EDIT.CROSSSELL.PRODUCT.EVENT.ACTIVE-FROM'" [labelTime]="'EDIT.CROSSSELL.PRODUCT.EVENT.ACTIVE-FROM-TIME'"></t4-date-time-control>
            <t4-display-validation controlName="OnSaleFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>

        </div>
      
        
        <div class="row">

          <div class="col-lg-12">
            <t4-date-time-control formControlName="OnSaleTill" [labelDate]="'EDIT.CROSSSELL.PRODUCT.EVENT.ACTIVE-TILL'" [labelTime]="'EDIT.CROSSSELL.PRODUCT.EVENT.ACTIVE-TILL-TIME'"></t4-date-time-control>
            <t4-display-validation controlName="OnSaleTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>

        </div>
        
  
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
  
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
  
  </form>
  
