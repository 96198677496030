import { FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import * as moment from 'moment';

@Injectable()
export class ReportUserTemplateValidator{
    constructor() {}

    static ValidateReportForm  = (group: FormGroup) => {
        // let ctrlTimeIntervalStart = group.controls.TimeIntervalStart;
        // let ctrlTimeIntervalEnd = group.controls.TimeIntervalEnd;
        // let ctrlTimeInterval = group.controls.TimeInterval;
        // let ctrlSalesType = group.controls.SalesType;
        // let ctrlSellPriceType = group.controls.SellPriceType;
        // let ctrl = group.controls.TimeIntervalStart;
        // let ctrl = group.controls.TimeIntervalStart;
        // let ctrl = group.controls.TimeIntervalStart;
        // let ctrl = group.controls.TimeIntervalStart;

    }

    static ValidateDates  = (group: FormGroup) => {

        //Always valid when custom interval is not selected
        if (group.controls.TimeIntervalStart.dirty === true || group.controls.TimeIntervalEnd.dirty == true && group.controls.TimeInterval.value === 999)
        {
            var startDate = typeof group.controls.TimeIntervalStart.value === 'string' ? moment(group.controls.TimeIntervalStart.value) : group.controls.TimeIntervalStart.value;
            var endDate = typeof group.controls.TimeIntervalEnd.value === 'string' ? moment(group.controls.TimeIntervalEnd.value) : group.controls.TimeIntervalEnd.value;

            if (startDate > endDate){
                group.controls.TimeIntervalStart.setErrors({startDateAfterEndDate: true}, {emitEvent: true})
                group.controls.TimeIntervalEnd.setErrors({endDateBeforeStartDate: true}, {emitEvent: true})

                return ({startDateAfterEndDate: true, endDateBeforeStartDate: true});
            }
            else{
                if (group.controls.TimeIntervalStart.hasError('startDateAfterEndDate')){
                    group.controls.TimeIntervalStart.setErrors(null, {emitEvent: true});
                }
                if (group.controls.TimeIntervalEnd.hasError('endDateBeforeStartDate')){
                    group.controls.TimeIntervalEnd.setErrors(null, {emitEvent: true});
                }
                return null;
            }
        }

        return null;
    }
}
