import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '@tymes4-shared';
import { SalesSectorDetailsComponent } from '../../../dialogs/sales-sector-details/sales-sector-details.component';
import { MatchDayPassService } from "../../../api";
import { SectorService } from '../../../api';

@Component({
  selector: 'app-sales-matchdaypass-selection',
  templateUrl: './sales-matchdaypass-selection.component.html',
})
export class SalesMatchdaypassSelectionComponent {
  @ViewChild('pagerComponent') pagerComponent;
  @ViewChild('sectorPagerComponent') sectorPagerComponent;
  @Output() onMatchdaypassSelectionChange = new EventEmitter<object>();
  @Input() hasOther: boolean;

  form: FormGroup;
  pageSize = 6;
  searchText = '';

  listMdps = [];
  selectedMdp = null
  totalMdpsCount = 0;
  mdpPageNr = 1;

  listSectors = []
  totalSectorCount = 0
  sectorPageNr = 1

  constructor(
    private matchdaypassService: MatchDayPassService,
    private sectorService: SectorService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      SearchText: new FormControl(''),
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.fetchData(1);
    }, 0);
  }

  updateFilter(e) {
    e.preventDefault();
    this.searchText = this.form.controls['SearchText'].value;
    this.fetchData(1);
  }

  fetchData(pageNr){

    this.mdpPageNr = pageNr;

    this.matchdaypassService.searchMatchDayPasses(this.searchText, pageNr, this.pageSize, 'OnSaleFrom', true).subscribe((resp: any) => {
      this.totalMdpsCount = resp.Records.length;
      this.listMdps = resp.Records;
      this.pagerComponent.setObjectCount(this.totalMdpsCount);
      this.pagerComponent.setPage(pageNr);
    });
  }

  isItemSelected(e) {
    return (this.selectedMdp && this.selectedMdp !== null && (this.selectedMdp.Id == e.Id));
  }

  clearMdpSelection() {
    this.selectedMdp = null;
  }

  doPage(pageArgs) {
    this.fetchData(pageArgs.pageNr);
  }

  doSectorPage(pageArgs) {
    this.fetchSectorData(pageArgs.pageNr);
  }

  toggleSelection(mdp) {
    if(!this.mdpSelectionIsAllowed()) return

      if (mdp == null) return;

      if (this.selectedMdp != null && this.selectedMdp.Id == mdp.Id) {
          this.clearMdpSelection();
      }
      else {
          this.clearMdpSelection();
          this.selectedMdp = mdp;
      }
      this.fetchSectorData(1)
  }

  fetchSectorData(pageNr){
    this.sectorPageNr = pageNr;

    // reset list during fetch
    this.listSectors = [];
    this.sectorPagerComponent.setObjectCount(0);
    this.sectorPagerComponent.setPage(1);

    if (this.selectedMdp != null) {
      this.sectorService.searchSectors('', pageNr, 8, '', true, {matchDayPassId: this.selectedMdp.Id}).subscribe((resp: any) => {
        resp.Records.forEach(i => {
          i.AmountInVat = 12
        });
        this.listSectors = resp.Records
        this.totalSectorCount = this.listSectors.length;
        this.sectorPagerComponent.setObjectCount(this.totalSectorCount);
        this.sectorPagerComponent.setPage(pageNr);
      });
    }
  }

  mdpSelectionIsAllowed() {

    if (this.hasOther) {

      this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-MATCHDAYPASS-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
        message: this.translate.instant('SALES.ORDERS.NEW.SALES-MATCHDAYPASS-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

        return false;
    }

    return true; //a-ok
  }

  selectSector(sector) {
    if(!this.mdpSelectionIsAllowed()) return
    this.dialog.open(SalesSectorDetailsComponent, {
      width: '720px',
      data: {
        title: this.translate.instant('SALES.ORDERS.NEW.SALES-MATCHDAYPASS-SELECTION.HEADER.SELECT-A-SECTOR'),
        payload: sector
      }
    }).afterClosed().subscribe(mdpLines => {
      if(mdpLines) {
        this.onMatchdaypassSelectionChange.emit(mdpLines)
      }
    })
  }
}
