<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>



<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'ADMINISTRATION.TEMPLATES.COMPONENTS.EDIT.FORM.LABEL.NAME' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" name="name" formControlName="Name" maxlength="256" autocomplete="off" placeholder="{{'ADMINISTRATION.TEMPLATES.COMPONENTS.EDIT.FORM.PLACEHOLDER.NAME' | translate}}">
            <t4-display-validation controlName="Name" [form]="form"
                                    [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'ADMINISTRATION.TEMPLATES.COMPONENTS.EDIT.FORM.LABEL.CHOOSE-TEMPLATE' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="templates" bindLabel="Name" bindValue="Id" placeholder="Template" formControlName="CurrentTemplateId"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
          </div>
        </div>

      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
