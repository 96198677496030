/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { environment } from 'environments/environment';

// @ts-ignore
import { DeepLink } from '../model/deepLink';
// @ts-ignore
import { DeliveryMethodMembershipModel } from '../model/deliveryMethodMembershipModel';
// @ts-ignore
import { Membership } from '../model/membership';
// @ts-ignore
import { MembershipArgs } from '../model/membershipArgs';
// @ts-ignore
import { MembershipModel } from '../model/membershipModel';
// @ts-ignore
import { MembershipModelPagedResult } from '../model/membershipModelPagedResult';
// @ts-ignore
import { MembershipPriceList } from '../model/membershipPriceList';
// @ts-ignore
import { MembershipPriceListView } from '../model/membershipPriceListView';
// @ts-ignore
import { PaymentMethodModel } from '../model/paymentMethodModel';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MembershipService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param membershipArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public createMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public createMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public createMembership(membershipArgs?: MembershipArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.createMembership - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership`;
        return this.httpClient.post<number>(`${this.configuration.basePath}${localVarPath}`,
            membershipArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMembership(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteMembership(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteMembership(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteMembership(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMembership.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.deleteMembership - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(id))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipDeliveryMethods(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DeliveryMethodMembershipModel>>;
    public getMembershipDeliveryMethods(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DeliveryMethodMembershipModel>>>;
    public getMembershipDeliveryMethods(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DeliveryMethodMembershipModel>>>;
    public getMembershipDeliveryMethods(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembershipDeliveryMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.getMembershipDeliveryMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/deliverymethods/${encodeURIComponent(String(membershipId))}`;
        return this.httpClient.get<Array<DeliveryMethodMembershipModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipDetails(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<MembershipModel>;
    public getMembershipDetails(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<MembershipModel>>;
    public getMembershipDetails(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<MembershipModel>>;
    public getMembershipDetails(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMembershipDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.getMembershipDetails - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(id))}/details`;
        return this.httpClient.get<MembershipModel>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public getMembershipPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public getMembershipPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public getMembershipPaymentMethods(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMembershipPaymentMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.getMembershipPaymentMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/paymentmethods/${encodeURIComponent(String(id))}`;
        return this.httpClient.get<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipPriceList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<MembershipPriceListView>>;
    public getMembershipPriceList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<MembershipPriceListView>>>;
    public getMembershipPriceList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<MembershipPriceListView>>>;
    public getMembershipPriceList(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembershipPriceList.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.getMembershipPriceList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(membershipId))}/pricelists`;
        return this.httpClient.get<Array<MembershipPriceListView>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipTabLink(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DeepLink>;
    public getMembershipTabLink(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DeepLink>>;
    public getMembershipTabLink(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DeepLink>>;
    public getMembershipTabLink(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembershipTabLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.getMembershipTabLink - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(membershipId))}/tablink`;
        return this.httpClient.get<DeepLink>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isMembershipInUse(membershipid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public isMembershipInUse(membershipid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public isMembershipInUse(membershipid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public isMembershipInUse(membershipid: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipid === null || membershipid === undefined) {
            throw new Error('Required parameter membershipid was null or undefined when calling isMembershipInUse.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.isMembershipInUse - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(membershipid))}/inuse`;
        return this.httpClient.get<boolean>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllMemberships(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<Membership>>;
    public listAllMemberships(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<Membership>>>;
    public listAllMemberships(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<Membership>>>;
    public listAllMemberships(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.listAllMemberships - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/all`;
        return this.httpClient.get<Array<Membership>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchMemberships(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<MembershipModelPagedResult>;
    public searchMemberships(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<MembershipModelPagedResult>>;
    public searchMemberships(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<MembershipModelPagedResult>>;
    public searchMemberships(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.searchMemberships - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/search`;
        return this.httpClient.get<MembershipModelPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public updateMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public updateMembership(membershipArgs?: MembershipArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public updateMembership(membershipArgs?: MembershipArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.updateMembership - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership`;
        return this.httpClient.put<boolean>(`${this.configuration.basePath}${localVarPath}`,
            membershipArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param deliveryMethodMembershipModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembershipDeliveryMethods(membershipId: number, deliveryMethodMembershipModel?: Array<DeliveryMethodMembershipModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DeliveryMethodMembershipModel>>;
    public updateMembershipDeliveryMethods(membershipId: number, deliveryMethodMembershipModel?: Array<DeliveryMethodMembershipModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DeliveryMethodMembershipModel>>>;
    public updateMembershipDeliveryMethods(membershipId: number, deliveryMethodMembershipModel?: Array<DeliveryMethodMembershipModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DeliveryMethodMembershipModel>>>;
    public updateMembershipDeliveryMethods(membershipId: number, deliveryMethodMembershipModel?: Array<DeliveryMethodMembershipModel>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling updateMembershipDeliveryMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.updateMembershipDeliveryMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(membershipId))}/deliverymethods`;
        return this.httpClient.put<Array<DeliveryMethodMembershipModel>>(`${this.configuration.basePath}${localVarPath}`,
            deliveryMethodMembershipModel,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param membershipPriceList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembershipLinkedPricelists(id: number, membershipPriceList?: Array<MembershipPriceList>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public updateMembershipLinkedPricelists(id: number, membershipPriceList?: Array<MembershipPriceList>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateMembershipLinkedPricelists(id: number, membershipPriceList?: Array<MembershipPriceList>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateMembershipLinkedPricelists(id: number, membershipPriceList?: Array<MembershipPriceList>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateMembershipLinkedPricelists.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.updateMembershipLinkedPricelists - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(id))}/pricelists`;
        return this.httpClient.put<any>(`${this.configuration.basePath}${localVarPath}`,
            membershipPriceList,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param paymentMethodModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembershipPaymentMethods(membershipId: number, paymentMethodModel?: Array<PaymentMethodModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public updateMembershipPaymentMethods(membershipId: number, paymentMethodModel?: Array<PaymentMethodModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public updateMembershipPaymentMethods(membershipId: number, paymentMethodModel?: Array<PaymentMethodModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public updateMembershipPaymentMethods(membershipId: number, paymentMethodModel?: Array<PaymentMethodModel>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling updateMembershipPaymentMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipService.updateMembershipPaymentMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/Membership/${encodeURIComponent(String(membershipId))}/paymentmethods`;
        return this.httpClient.put<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            paymentMethodModel,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
