export enum ActivityActionTypeEnum
{
  Other = 1,
  Create = 2,
  Update = 3,
  Delete = 4,
  RoleChange = 5, // for activity type user only
  Processed = 6, // for activity type invoicebatch only
  Printed = 7, // for activity type printbatch only
  DeepLink = 8,
  PurchaseRights = 9,
  Ticketshop = 10,
  Text = 11,
  PaymentMethods = 12,
  Availability = 13,
  Business = 14,
  UpdatePrice = 15,
  ChannelChange = 16,  // for activity type user only
  DeliveryMethods = 17,
  Anonymised = 18, // for anonymising a customer
  UnlinkFromSSO = 19, // means that the user is unlinked from sso
  PassePartouts = 20,
  PriceSettings = 21
}

export let ActivityActionTypeDictionary;
ActivityActionTypeDictionary = {
  [ActivityActionTypeEnum.Other]: '',
  [ActivityActionTypeEnum.Create]: 'ACTIVITY.ACTION.ENUM.CREATED',
  [ActivityActionTypeEnum.Update]: 'ACTIVITY.ACTION.ENUM.UPDATED',
  [ActivityActionTypeEnum.Delete]: 'ACTIVITY.ACTION.ENUM.DELETED',
  [ActivityActionTypeEnum.RoleChange]: 'ACTIVITY.ACTION.ENUM.ROLECHANGE',
  [ActivityActionTypeEnum.Processed]: 'ACTIVITY.ACTION.ENUM.PROCESSED',
  [ActivityActionTypeEnum.Printed]: 'ACTIVITY.ACTION.ENUM.PRINTED',
  [ActivityActionTypeEnum.DeepLink]: 'ACTIVITY.ACTION.ENUM.DEEPLINK',
  [ActivityActionTypeEnum.PurchaseRights]: 'ACTIVITY.ACTION.ENUM.PURCHASE-RIGHT',
  [ActivityActionTypeEnum.Ticketshop]: 'ACTIVITY.ACTION.ENUM.TICKETSHOP',
  [ActivityActionTypeEnum.Text]: 'ACTIVITY.ACTION.ENUM.TEXT',
  [ActivityActionTypeEnum.PaymentMethods]: 'ACTIVITY.ACTION.ENUM.PAYMENT.METHODS',
  // [ActivityActionTypeEnum.Availability]: 'de beschrijving aangepast',
  [ActivityActionTypeEnum.Business]: 'ACTIVITY.ACTION.ENUM.BUSINESS',
  [ActivityActionTypeEnum.UpdatePrice]: 'ACTIVITY.ACTION.ENUM.UPDATE.PRICE',
  //[ActivityActionTypeEnum.UpdatePrice]: 'de verkoopkanalen gewijzigd',
  [ActivityActionTypeEnum.ChannelChange]: 'ACTIVITY.ACTION.ENUM.CHANNEL-CHANGE',
  [ActivityActionTypeEnum.DeliveryMethods]: 'ACTIVITY.ACTION.ENUM.DELIVERY-METHODS',
  [ActivityActionTypeEnum.Anonymised]: 'ACTIVITY.ACTION.ENUM.ANONYMISED',
  [ActivityActionTypeEnum.UnlinkFromSSO]: 'ACTIVITY.ACTION.ENUM.UNLINK-FROM-SSO',
  [ActivityActionTypeEnum.PassePartouts]: 'ACTIVITY.ACTION.ENUM.PASSE-PARTOUTS',
  [ActivityActionTypeEnum.PriceSettings]: 'ACTIVITY.ACTION.ENUM.PRICE-SETTINGS',
};
