import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import {ConfirmService, FileService} from '@tymes4-shared';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../api';

@Component({
  selector: 'app-sales-event-selection',
  templateUrl: './sales-event-selection.component.html',
  styleUrls: ['./sales-event-selection.component.scss']
})
export class SalesEventSelectionComponent implements OnInit {

  @ViewChild('pagerComponent') pagerComponent;
  @Output() onEventSelectionChange = new EventEmitter<object>();
  @Input() hasOther: boolean;

  selectedItem: any;
  form: FormGroup;
  pageSize = 6;
  searchText = '';
  listObjects: any = [];
  totalObjectCount: number = 0;
  selectedObjects = [];
  pageNr = 1;

  constructor(
    private eventService: EventService,
    private confirmService: ConfirmService,
    public fileHelper: FileService,
    private translate: TranslateService)
    { }


  ngOnInit() {

    this.form = new FormGroup({
      SearchText: new FormControl(''),
    });

    this.fetchData(1);

  }


  clear() {
    this.selectedObjects = [];
  }

  emptySelection() {
    this.clear();
    this.onEventSelectionChange.emit(this.selectedObjects);
  }

  getSelectedEventCount() {
    if (this.selectedObjects && this.selectedObjects !== null) {
      return this.selectedObjects.length;
    }

    return 0
  }

  updateFilter(e) {
    e.preventDefault();
    this.searchText = this.form.controls['SearchText'].value;
    this.fetchData(1);
  }

  fetchData(pageNr){

    this.pageNr = pageNr;

    this.eventService.searchForSales(this.searchText, pageNr, this.pageSize, 'StartDateTime', true).subscribe((resp: any) => {
      this.totalObjectCount = resp.TotalRecords;
      this.listObjects = resp.Records;
      this.pagerComponent.setObjectCount(this.totalObjectCount);
      this.pagerComponent.setPage(pageNr);
    });

  }

  doPage(pageArgs) {
    this.fetchData(pageArgs.pageNr);
  }

  reload() {
    this.fetchData(this.pageNr);
  }

  toggleSelection(event) {
    if (event == null) return;

    if (this.isItemSelected(event)) {
      //remove from the selection
      var index = this.selectedObjects.indexOf(event);
      this.selectedObjects.splice(index, 1);
    }
    else {
      if (this.selectionIsAllowed(event) === true) {
        this.selectedObjects.push(event);
      }
      else {
        this.reload();
      }
    }

    this.onEventSelectionChange.emit(this.selectedObjects);
  }

  selectionIsAllowed(event) {
     if (this.hasOther) {
      this.confirmService.confirm({ title: this.translate.instant('SALES.EVENT.SELECTION.CONFIRM.TITLE.SELECTION-IMPOSSIBLE'),
        message: this.translate.instant('SALES.EVENT.SELECTION.CONFIRM.MESSAGE.HAS-OTHER'), okonly: true }).subscribe(confirmed => {});

        return false;
    }

    //it is only possible to select events of a single venue(-version) at the same time

    if (this.selectedObjects == null || this.selectedObjects.length == 0) //first event, always OK
        return true;

    if (this.selectedObjects[0].VenueBuildingBlockId != event.VenueBuildingBlockId) {

      //oops, different venue (/version)
      this.confirmService.confirm({ title: this.translate.instant('SALES.EVENT.SELECTION.CONFIRM.TITLE.SELECTION-IMPOSSIBLE'),
        message: this.translate.instant('SALES.EVENT.SELECTION.CONFIRM.MESSAGE.DIFFERENT-VENUE'), okonly: true }).subscribe(confirmed => {});

        return false;
    }

    return true; //a-ok
  }

  isItemSelected(event) {

    if (!event) return null;

    if (this.selectedObjects == null && this.selectedObjects.length == 0)
      return false;

    var found = this.selectedObjects.filter(function(ep){
                      if (ep.Id == event.Id) return ep;
                    });

    return (found != null && found.length > 0);
  }

  clearSelection() {
    this.selectedObjects = [];
    this.onEventSelectionChange.emit(this.selectedObjects);
  }
}
