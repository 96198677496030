<div class="row">
    <div class="col-sm-11">
      <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>
    <div class="col-sm-1">
      <div class="modal-actions">
        <div class="modal-close">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span (click)="dialogRef.close(false);">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25"> 
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{ 'CUSTOMER.MANUAL-IDENTIFICATION-DIALOG.REASON' | translate}}</label>
                    <input class="form-control" name="Reason" formControlName="Reason" >
                </div>
            </div>
        </div>
  
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" class="btn btn-block btn-primary bd-0"><i class="far fa-save"></i>
            <span>{{'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i>
            <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div>
  
      </div>
    </div>
  </form>