import * as moment from 'moment';
import {EventSaleCategoryButtonState, ActionType as ActionTypeAPI, ButtonStyle as ButtonStyleAPI} from "../api";

export enum ButtonStyle {
  Default = -1,
  Standard = 'Standard',
  Accent = 'Accent',
  Disabled = 'Disabled'
}

export const ButtonStyleDictionary = [
  { value: ButtonStyle.Default, viewValue: '[Standaard]' },
  { value: ButtonStyle.Standard, viewValue: 'Standaard' },
  { value: ButtonStyle.Accent, viewValue: 'Accent' },
  { value: ButtonStyle.Disabled, viewValue: 'Uitgeschakeld' },
];

export enum ActionType {
  Default = -1,
  Disabled = 'Disabled',
  ExternalLink = 'ExternalLink'
}

export const ActionTypeDictionary = [
  { value: ActionType.Default, viewValue: '[Standaard]' },
  { value: ActionType.Disabled, viewValue: 'Uitgeschakeld' },
  { value: ActionType.ExternalLink, viewValue: 'Externe link' },
];

export abstract class CustomButtonData{
  Id: number;
  TranslationCode: string;
  ButtonStyle?: ButtonStyle = ButtonStyle.Default;
  ActionType?: ActionType = ActionType.Default;
  Action: string = null;
  OpenInNewTab: boolean = null;
  ActiveFrom: Date = null;
  ActiveTill: Date = null;

  public static clone<T extends CustomButtonData>(base: T): T {
    let newState: any;
    if (base instanceof CustomSalesPeriodButtonState){
      newState = new CustomSalesPeriodButtonState();
    } else if (base instanceof EventSaleCategoryState){
      newState = new EventSaleCategoryState();
    } else {
      console.error('baseState is neither a SalesPeriodButtonState nor an EventSaleCategoryState');
      return;
    }

    newState.cloneFrom(base);
    return newState;
  }

  public abstract cloneFrom<T extends CustomButtonData>(base: T): boolean;
  protected copy<T extends CustomButtonData>(base: T): void {
    this.Id = base.Id;
    this.TranslationCode = base.TranslationCode;
    this.ButtonStyle = base.ButtonStyle;
    this.ActionType = base.ActionType;
    this.Action = base.Action;
    this.OpenInNewTab = base.OpenInNewTab;
    this.ActiveFrom = typeof base.ActiveFrom === 'string' ? moment.utc(base.ActiveFrom).toDate() : base.ActiveFrom ? base.ActiveFrom : null;
    this.ActiveTill = typeof base.ActiveTill === 'string' ? moment.utc(base.ActiveTill).toDate() : base.ActiveTill ? base.ActiveTill : null;
  }

  public convertButtonStyleReverse(buttonStyle: ButtonStyleAPI): ButtonStyle {
    switch (buttonStyle) {
      case 'Standard':
        return ButtonStyle.Standard;
      case 'Accent':
        return ButtonStyle.Accent;
      case 'Disabled':
        return ButtonStyle.Disabled;
      default:
        return ButtonStyle.Default; // Fallback to 'Default' if an unknown value is encountered
    }
  }

  public convertActionTypeReverse(actionType: ActionTypeAPI): ActionType {
    switch (actionType) {
      case 'Disabled':
        return ActionType.Disabled;
      case 'ExternalLink':
        return ActionType.ExternalLink;
      default:
        return ActionType.Default; // Fallback to 'Default' if an unknown value is encountered
    }
  }

  public get dateFrom(): Date {
    return this.ActiveFrom;
  }

  public get timeFrom(): string {
    return this.formatTime(this.ActiveFrom);
  }

  public get dateUntil(): Date {
    return this.ActiveTill;
  }

  public get timeUntil(): string {
    return this.formatTime(this.ActiveTill);
  }

  public setDatetime(date: Date, time: string): Date {
    if (date) {
      const t = this.parseTime(time);
      date.setHours(t.h, t.m);
      date.setSeconds(0, 0);
    }
    return date;
  }

  public parseTime(time: string): { h: number, m: number } {
    const t = time.match(/^([0-9]{1,2}):([0-9]{1,2})$/);

    const h = +t[1];
    const m = +t[2];
    return {h: h, m: m};
  }

  public formatTime(time: Date): string{
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
  }
}

export class CustomSalesPeriodButtonState extends CustomButtonData {
  public SalesPeriodId: number = null;

  public cloneFrom<T extends CustomButtonData>(base: T): boolean {
    if (base instanceof CustomSalesPeriodButtonState || ((<any>base).SalesPeriodId) !== undefined ) {
      const baseState = <any>base as CustomSalesPeriodButtonState;
      this.SalesPeriodId = baseState.SalesPeriodId;
      this.copy(baseState);
      return true;
    }
    return false;
  }
}

export class EventSaleCategoryState extends CustomButtonData {
  public EventId: number = null;
  public SaleCategoryId: number = null;

  public cloneFrom<T extends CustomButtonData>(base: T): boolean {
    if (base instanceof EventSaleCategoryState || ((<any>base).EventId) !== undefined) {
      const baseState = <any>base as EventSaleCategoryState;
      this.EventId = baseState.EventId;
      this.SaleCategoryId = baseState.SaleCategoryId;
      this.copy(baseState);
      return true;
    }
    return false;
  }

  public toCustomButtonDataEventSaleCategoryState(state: EventSaleCategoryButtonState): any {
    const customButtonData = new EventSaleCategoryState()
    customButtonData.EventId = state.EventId;
    customButtonData.SaleCategoryId = state.SaleCategoryId;
    customButtonData.Id = state.Id;
    customButtonData.TranslationCode = state.TranslationCode;
    customButtonData.ButtonStyle = this.convertButtonStyleReverse(state.ButtonStyle);
    customButtonData.ActionType = this.convertActionTypeReverse(state.ActionType);
    customButtonData.Action = state.Action;
    customButtonData.OpenInNewTab = state.OpenInNewTab;
    customButtonData.ActiveFrom = state.ActiveFrom;
    customButtonData.ActiveTill = state.ActiveTill;
    return customButtonData;

  }

}
