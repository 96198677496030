


<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>



<!-- MODAL BODY -->

<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">

        <div class="row">
            <div class="col-lg-6">


                <label class="form-control-label">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.SELECTABLE-LOCATION' | translate}} <span class="tx-danger">*</span></label>
                <input class="form-control" [matAutocomplete]="auto" formControlName="BluePrint">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getSelectedVenueDisplayText">
                    <mat-option *ngFor="let pi of filteredBluePrintVenues" [value]="pi">
                        {{ getVBBName(pi) }}
                    </mat-option>
                </mat-autocomplete>

            </div>
            <div class="col-lg-6">

                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.TYPE' | translate}} <span class="tx-danger">*</span></label>
                    <ng-select [items]="constants.venueBuildingBlockTypes" bindLabel="Name" bindValue="Id" placeholder="{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.PLACEHOLDER.TYPE' | translate}}" formControlName="VenueBuildingBlockTypeId"
                               [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-6" >
                <div class="form-group"  *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value == 1">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.VERSION' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.PLACEHOLDER.VERSION' | translate}}" formControlName="Version" placeholder="{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.PLACEHOLDER.VERSION' | translate}}" [disabled]="editingEventPlacement || hasPlacements">
                </div>

                <div class="form-group" *ngIf="this.form.controls['VenueBuildingBlockTypeId'].value != 1">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.MAKE-PART-OF' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control" [matAutocomplete]="auto" formControlName="Parent">

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getSelectedVenueDisplayText">
                        <mat-option *ngFor="let pi of filteredParentVenues" [value]="pi">
                            {{ getVBBName(pi) }}
                        </mat-option>
                    </mat-autocomplete>

                </div>

            </div>


            <div class="col-lg-6">

                <div class="form-group">
                    <label class="form-control-label">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.NAME-AFTER-COPY' | translate}} <span class="tx-danger">*</span></label>
                    <input class="form-control"  name="Name" formControlName="Name" placeholder="{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.LABEL.NAME-AFTER-COPY' | translate}}">
                    <small *ngIf="form.controls['Name'].hasError('required') && form.controls['Name'].touched" class="form-error-msg">{{'LOCATION.LOCATIONS.COPYDIALOG.NAME.REQUIRED' | translate}}</small>
                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <input type="checkbox" id="IncludeChildren" formControlName="IncludeChildren">
                    <label class="form-check-label" for="IncludeChildren">{{'LOCATION.LOCATIONS.COPYDIALOG.FORM.INCLUDE-CHILDREN' | translate}}</label>
                </div>
            </div>

        </div>

        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>

