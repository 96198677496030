import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { HttpEventType } from '@angular/common/http';
import {ConfirmService} from '@tymes4-shared';
import { GenericErrorHandler } from 'app/shared/helpers/generic-error-handler';
import { TranslateService } from '@ngx-translate/core';
import { FileImportResult, RepurchaseService } from '../../api';

@Component({
  selector: 'app-upload-repurchase-importfile',
  templateUrl: './upload-repurchase-importfile.component.html',
  styleUrls: ['./upload-repurchase-importfile.component.scss']
})
export class UploadRepurchaseImportfileComponent implements OnInit {

  public progress: number;
  public message: string;
  public uploadKey: string;
  public uploadErrors: string[];
  public uploadWarnings: string[];
  @ViewChild('file') fileInput;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<UploadRepurchaseImportfileComponent>,
    private loader: HttpLoaderService,
    private repurchaseService: RepurchaseService,
    private confirmService: ConfirmService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }


  uploadRepurchaseFile(files) {
    const type = this.passedData.type;

    if (files === null || files === 'undefined' || !(files.length > 0)) {
      return;
    }

    const file = files[0];
    var blobArray : Array<Blob> = new Array<Blob>();
    blobArray.push(file);

    this.loader.open();

    this.repurchaseService.uploadRepurchaseFile(type, blobArray).subscribe((event: FileImportResult) => {

        this.loader.close();
        this.uploadKey = event.Key;
        this.uploadWarnings = event.Warnings;
        this.uploadErrors = event.Errors;
        if (this.uploadErrors.length > 0) {
          this.handleUploadErrors();
        }
        else {
          if (this.uploadWarnings.length > 0) {
            this.handleUploadWarnings();
          }
          else {
           //pass LRO info
           this.dialogRef.close(event.LongRunningOperation);           
          }
      }
    },
    error => { this.errorHandler.handle(error);}
    );
    this.fileInput.nativeElement.value = '';
  }


  handleUploadErrors() {
    const messageContent = this.buildUploadErrorText(this.uploadErrors);
    this.confirmService.confirm({ title: this.translate.instant('GENERIC.SNACKBAR.IMPORT-FAILED'), message: messageContent, okonly: true }).subscribe(confirmed => {});
  }

  buildUploadErrorText(errors) {
    let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.FAILED') + '</b></div><br />';
    let errorCounter = 0;
    for (const e of errors) {

      if (errorCounter >= 5) {
        result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.ERRORS.COUNT', {errors : (errors.length - errorCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;${e}</span></div><br />`;
      errorCounter++;

    }
    return result;
  }

  handleUploadWarnings() {
    const messageContent = this.buildUploadWarningText(this.uploadWarnings);
    this.confirmService.confirm({ title: this.translate.instant('UPLOAD.REPURCHASE.IMPORT-FILE.IMPORT.WARNINGS'), message: messageContent, okonly: false }).subscribe(confirmed => {
      if (confirmed) {
        this.verifyTranslationFileExport();
      }
    });
  }

  buildUploadWarningText(warnings) {
    let result = '<div><b>' + this.translate.instant('GENERIC.UPLOAD.WARNINGS') + '</b></div><br />';
    let warningCounter = 0;
    for (const e of warnings) {

      if (warningCounter >= 5) {
        result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;` + this.translate.instant('GENERIC.UPLOAD.MORE.WARNING.COUNT', {count: (warnings.length - warningCounter)}) + `.</span></div><br />`;
        break;
      }

      result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;${e}</span></div><br />`;
      warningCounter++;

    }
    return result;
  }

  verifyTranslationFileExport() {
    // this.translationService.veryTranslationUpload(this.uploadKey).subscribe(event => {
    //   if (event === -1) {
    //     this.message = `Er zijn geen vertalingen gevonden die verwerkt konden worden. Probeer het bestand nogmaals te uploaden.`;
    //   }
    //   if (event === 0) {
    //     this.message = `Er zijn geen vertalingen gevonden in het geuploade bestand.`;
    //   }
    //   if (event > 0) {
    //     this.message = `Er zijn ${event} vertalingen verwerkt.`;
    //   }

    //   this.snackBar.open(this.message, 'GENERIC.SNACKBAR.CLOSE');
    // });
  }

}
