<div class="row modal-bar">
  <div class="col-sm-9">
      <h5 class="modal-title">{{'PASSE-PARTOUT.EVENT.QUEUE.PROGRESS.ADD.DELETE' | translate}}</h5>
  </div>
  <div class="col-sm-3">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>





  <t4-list-view #passepartoutEventQueueListVw [options]="pptEventQueueListOptions" (onDataRequest)="onDataRequest($event)">

    <ng-container *t4ListActions>
    </ng-container>

    <ng-container *t4ListItem="let item">
      <!--[checked]="passepartout.Selected" -->

      <td class="text-center">
        <i *ngIf="item.IsQueued" class="pl-1 far fa-hourglass icon-24 disabled"></i>
        <i *ngIf="item.IsQueued === false && item.IsRunning === false && item.Failed === false" class="pl-1 far fa-check-circle text-success icon-24"></i>
        <i *ngIf="item.IsQueued === false && item.IsRunning === false && item.Failed === true" class="pl-1 fas fa-exclamation-circle text-danger icon-24"></i>
          
        <div *ngIf="item.IsRunning" class="d-flex align-items-center justify-content-center" style="height: 16px; width: 16px;">
          <div class="sk-three-bounce" style="height: 16px; width: 16px;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      
      </td>

      <td>{{item.Action}}</td>
      <td>{{item.PassePartoutName}}</td>
      <td>{{item.PassePartoutCode}}</td>
      <td>{{ item.UserName | replaceOnEmpty  }}</td>
      <td>{{item.LastStatusUpdate | localDateTime | replaceOnEmpty }}</td>
      <td *ngIf="item.IsRunning">{{"LRO.STATUS.EXECUTING" | translate | replaceOnEmpty}}</td>
      <td *ngIf="item.IsQueued">{{"LRO.STATUS.QUEUED" | translate | replaceOnEmpty}}</td>
      <td *ngIf="item.Failed">{{"LRO.STATUS.ERRORED" | translate | replaceOnEmpty}}</td>
      <td *ngIf="!item.IsRunning && !item.IsQueued && !item.Failed">{{"LRO.STATUS.SUCCEEDED" | translate | replaceOnEmpty}}</td>
      
      
      <!--last column-->
      <td *ngIf="item.IsRunning == true;else gridActions">
        <app-progress-bar *ngIf="item.IsRunning" [progress]="item.ProgressPercentage"></app-progress-bar>
      </td>

      <ng-template #gridActions>
        <td class="grid-action" >
          <button *ngIf="item.HasReportBinary" (click)="downloadReport(item.Id)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-file-excel"></button>  
        </td>
      </ng-template>
      

      
      
    </ng-container>

  </t4-list-view>

