import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from '../../../api';

@Component({
  selector: 't4-customer-barcode-search-dialog',
  templateUrl: 'customer-barcode-search-dialog.component.html'
})

export class CustomerBarcodeSearchDialogComponent implements OnInit {
  public barcode: string='';
  public values: string[] =[];
  public notFound = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<CustomerBarcodeSearchDialogComponent>
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    document.getElementById('barcode').focus();
  }

  onCustomerSelect(customers) {
    this.dialogRef.close(customers[0]);
  }
  onKey(event: any) {
    if(this.barcode != event.target.value) {
      this.barcode = event.target.value;

      if(this.barcode !== '' && this.barcode) {
        this.getCustomer();
      }
    }
    else {
      // @ts-ignore
      document.getElementById('barcode').select();
      document.getElementById('barcode').focus();
    }

  }
  getCustomer() {
    this.customerService.getCustomerWithHardCard(this.barcode).subscribe((resp: any) => {
      if (resp != null) {
        this.dialogRef.close(resp);
      }
      else {
        this.notFound = true;
        // @ts-ignore
        document.getElementById('barcode').select();
        document.getElementById('barcode').focus();
      }
    });
  }
}
