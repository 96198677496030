/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderImportLineView { 
    Id?: number;
    ToPassePartoutId?: number;
    ToPassePartoutName?: string | null;
    OrderCustomerName?: string | null;
    OrderCustomerNumber?: string | null;
    TicketCustomerName?: string | null;
    TicketCustomerNumber?: string | null;
    DeliveryMethodName?: string | null;
    EntranceName?: string | null;
    SectionName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    TicketText?: string | null;
    SalesPeriodId?: number | null;
    EventId?: number | null;
    Status?: number;
    Reason?: string | null;
}

