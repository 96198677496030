<!-- MODAL HEADER-->
<app-dialog-header [title]="passedData.title" (onClose)="dialogRef.close(false)"></app-dialog-header>

<form class="form-content" [formGroup]="form">

<t4-page-warning *ngIf="selectedSalesChannelMissesTemplates === true" warning='SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.USE-TEMPLATE-SALESCHANNEL.WARNING'></t4-page-warning>

<t4-tabs>
  <t4-tab tabTitle="E-mail" id="email">
    <div class="section-wrapper">

        <div class="form-layout">
          <div class="row">

            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-control-label">{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.EMAIL.LABEL' | translate}} <span class="tx-danger">*</span></label>
                <input class="form-control" name="Email" formControlName="Email" placeholder="{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.EMAIL.PLACEHOLDER' | translate}}" maxlength="255">
                <t4-display-validation controlName="Email" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>


          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.SUBJECT.LABEL' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="Subject" formControlName="Subject" placeholder="{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.SUBJECT.PLACEHOLDER' | translate}}" maxlength="255">
              <t4-display-validation controlName="Subject" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.BODY.LABEL' | translate}}: <span class="tx-danger">*</span></label>
              <quill-editor name="body" formControlName="Body" [sanitize]="false" [modules]="config" name="richTextContent" [styles]="{height: '350px'}"></quill-editor>
              <t4-display-validation controlName="Body" [form]="form"
                                      [messages]="validationMessages"></t4-display-validation>

            </div>
          </div>

        </div>


        </div><!-- form-layout -->
      </div>


  </t4-tab>

      <t4-tab tabTitle="Attachments ({{ attachmentsSelected }}/{{ attachmentsTotal }})" id="attachments">

      <div class="section-wrapper">
        <div class="form-layout">

          <t4-page-warning *ngIf="!showAttachmentsTab()" warning="{{'DIALOGS.MANUAL-MAIL-DIALOG.TAB.WARNING.NO-ATTACHMENTS-AVAILABLE' | translate}}"></t4-page-warning>

          <div class="row" *ngIf="showAttachmentsTab()" >
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.USE-TEMPLATE-SALESCHANNEL.LABEL' | translate}}</label>
                    <ng-select  [items]="selectableSalesChannels" bindLabel="ChannelName" bindValue="Id" placeholder="{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.USE-TEMPLATE-SALESCHANNEL.PLACEHOLDER' | translate}}" formControlName="SalesChannelId"  (change)="handleSalesChannelChange($event)"
                                [clearable]="false" notFoundText="" appendTo="body"></ng-select>
                </div>
            </div>
          </div>

          <div class="row" [class.d-none]="!showAttachmentsTab()">
            <div class="col-lg-12">

                <div class="form-group">
                    <div class="row">
                          <div class="col-lg-12">
                            <label class="form-control-label">{{'SALES.ORDERS.DETAILS.DIALOG.MAIL.FORM.SELECT-ATTACHMENTS.LABEL' | translate}}</label>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="rounded table text-dark table-striped clickable">

                          <thead>
                            <th><input type="checkbox" [checked]="isAnyAttachmentSelectable" (click)="allSelectionToggle($event.currentTarget.checked)" /></th>
                            <th>{{'SALES.ORDERS.DETAILS.DIALOG.SEND-TICKETS.ATTACHMENT.HEADER.EVENT' | translate}}</th>
                            <th>{{'SALES.ORDERS.DETAILS.DIALOG.SEND-TICKETS.ATTACHMENT.HEADER.CUSTOMER' | translate}}</th>
                            <th>{{'SALES.ORDERS.DETAILS.DIALOG.SEND-TICKETS.ATTACHMENT.HEADER.SECTION-ROW-SEAT' | translate}}</th>
                            <th *ngIf="secondaryTicketingActive">{{'SALES.ORDERS.DETAILS.DIALOG.SEND-TICKETS.ATTACHMENT.HEADER.SECONDARY-TICKETING-STATUS' | translate}}</th>
                          </thead>

                          <tbody>
                            <tr *ngFor="let item of pagedAttachments" (click)="toggleSelection(item)">
                              <td class=""><input type="checkbox" [checked]="isItemSelected(item)" /> </td>
                              <td>{{item.EventStartDate | localDateTime}} - {{item.EventName}}</td>
                              <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="item.IdentificationCustomerModel"></t4-customer-identification-control></td>
                              <td>{{item.Ticketname}} / {{item.Row | replaceOnEmpty}} / {{item.Seat | replaceOnEmpty}}</td>
                              <td *ngIf="secondaryTicketingActive"><span [ngClass]="getTicketSwapStatusClass(item.TicketSwapStatus)">{{getTicketSwapStatus(item.TicketSwapStatus) | translate}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>

                    <t4-pager [listCounts]="false" (onPage)="doPage($event)" #pagerComponent></t4-pager>

                </div>


            </div>
        </div>


        </div>
      </div>




  </t4-tab>

</t4-tabs>

<div class="form-layout-footer row">
  <div class="col-6">
    <button (click)="closeWithData()" [disabled]="!this.form.valid || selectedSalesChannelMissesTemplates" class="btn btn-block btn-primary bd-0">
      <i class="far fa-envelope"></i>
      <span>{{'GENERIC.BTN.SEND' | translate}}</span>
    </button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>
  </div>

</div><!-- form-layout-footer -->

</form>

