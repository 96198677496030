import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';


import {HttpLoaderService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private logging: LoggingService, private loader: HttpLoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loginPageUrl = '/sessions/signin';

    if (req.headers.get('no-auth') == 'true') {
      // this header needs to be passed for methods that do not required a token      
      return next.handle(req.clone());
    }
    
    // check if the user has been authenticated
    if (sessionStorage.getItem('jwt') != null) {

      // pass our token in the header
      const authHeader = 'Bearer ' + sessionStorage.getItem('jwt');
      const channel = sessionStorage.getItem('userSelectedChannel');

      // The channel the user selected will always need to be passed.
      let authHeaders = req.headers.set('Authorization', authHeader);

      if (channel !== null) { authHeaders = authHeaders.set('Channel', channel); }

      const authReq = req.clone({headers: authHeaders});
      
      return next.handle(authReq).pipe(
        catchError((error, caught) => {

          // intercept the respons error
          if (error.status == 401) {
            this.logging.log('401 returned. Redirecting to login.');
            this.router.navigate([loginPageUrl]);
          }

          return observableThrowError(error);
        })) as any;

    } else {
      // no token present in local store
      this.router.navigate([loginPageUrl]);
    }

  }
}
