<div class="row modal-bar">
  <div class="col-sm-6">
    <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(false);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="form" class="form-content">
  <div class="form-group">
    <div class="row row-sm mg-b-10">
      <div class="col">
        <label class="form-control-label">
          {{ 'MEMBERSHIP.BATCH.SELECT-COLLECTION-DATE' | translate }}
          <span class="tx-danger">*</span>
        </label>
        <input class="form-control" datepicker placeholder="{{'MEMBERSHIP.BATCH.SELECT-COLLECTION-DATE' | translate}}"
               formControlName="collectionDate">
        <t4-display-validation [form]="form" controlName="collectionDate"></t4-display-validation>
        <label class="row row-sm ml-1 mt-1 form-control-label"> {{ 'MEMBERSHIP.BATCH.COLLECTION-DATE.REMARK' | translate }}</label>
      </div>

      <div class="col">
        <label class="form-control-label">
          {{ 'MEMBERSHIP.BATCH.DESCRIPTION' | translate }}
          <span class="tx-danger">*</span>
        </label>
        <input class="form-control" placeholder="{{'MEMBERSHIP.BATCH.DESCRIPTION' | translate}}"
               formControlName="collectionDescription">
        <t4-display-validation [form]="form" controlName="collectionDescription"></t4-display-validation>
      </div>
    </div>

    <div class="row row-sm mg-b-10 mt-2">
      <div class="col">
        <div class="form-group">
          <label class="form-control-label">{{ 'MEMBERSHIP.BATCH.CREDIT-MANAGEMENT-STRATEGY' | translate }}
          </label>
          <input class="form-control" formControlName="CreditManagementStrategy"
                 placeholder="{{'MEMBERSHIP.BATCH.CREDIT-MANAGEMENT-STRATEGY' | translate}}">
        </div>
        <t4-display-validation [form]="form" controlName="CreditManagementStrategy"></t4-display-validation>
      </div>
    </div>

  </div>
</form>
<div class="row">
  <div class="col-6">
    <button (click)="submit()" [disabled]="!this.form.valid" class="btn btn-block btn-primary bd-0">
      <i class="far fa-save"></i>
      <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
    </button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>
  </div>
</div>
