import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: 'dialog-header.component.html'
})

export class DialogHeaderComponent implements OnInit {
  @Input() public title: string;
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }
}
