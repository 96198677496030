import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SalesChannel, SalesChannelService } from '../api';

@Injectable()
export class SalesChannelValidator {
    constructor(private salesChannelService: SalesChannelService) {}

    public editingObject: any;

    codeTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            const v = control.value;

            this.salesChannelService.getSalesChannelByCode(v).subscribe((data: SalesChannel) => {
                if (data != null && data !== void 0) {
                    if (!this.editingObject || (data.Code !== this.editingObject.Code && data.Id !== this.editingObject.Id)) {
                        resolve({'codetaken': data});
                    }
                }
                resolve(null);
            });
        });
    }

}