import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  constructor(private router: Router, private logging : LoggingService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (sessionStorage.getItem('jwt') != null) {
      this.logging.log('User is authenticated with token.');
      return true;
    }

    this.logging.log('User not authenticated.');
    this.router.navigate(['/sessions/signin']);
    return false;
  }
}
