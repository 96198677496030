import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";
import { ETicketTemplateService } from "../../api";

@Injectable()
export class ETicketTemplateValidator {
    constructor(private eTicketTemplateService: ETicketTemplateService) {}

    public currentName : string;
    
    eTicketTemplateNameTaken = (argArray, control: AbstractControl) => {
    
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        
        var v = control.value;
        
        this.currentName = argArray.currentName;

        if (this.currentName != null && control.value === this.currentName ) {
          resolve(null); //same name as before, this is ok
        }
        else {
          setTimeout(() => {
            this.eTicketTemplateService.getETicketTemplateByName(v).subscribe(data => {
              if (data !== null && data !== void 0) {
                resolve({"nametaken": data});
              }
              resolve(null);
            });
          }, 200);
        }
      });
    }
}
 