/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InitiativeModel { 
    Id?: number | null;
    Guid?: string | null;
    Name?: string | null;
    Code?: string | null;
    ActiveFrom?: Date | null;
    ActiveTill?: Date | null;
    Active?: boolean;
    DeepLinkId?: number | null;
    Private?: boolean;
    MaxNumber?: number | null;
    ContingentId?: number | null;
    DescriptionText?: string | null;
    IntroText?: string | null;
    Title?: string | null;
    LogoImageId?: number | null;
    Named?: boolean;
}

