/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BackofficeConfigModel { 
    apiURL?: string | null;
    ticketShopURL?: string | null;
    messageDuration?: number | null;
    enableErrorPage?: boolean | null;
    companiesFromExternalSource?: boolean | null;
    entranceMode?: string | null;
    clientUrl?: string | null;
    versionCheckURL?: string | null;
    versionCheckInterval?: number | null;
    SportsAllianceOpenCustomerBaseUrl?: string | null;
    ShopGuardResourceUID?: string | null;
    ShopGuardEnabled?: boolean;
    ShopGuardUrl?: string | null;
    HasArchiveDB?: boolean;
    PhoneValidation?: string | null;
}

