import { Component, OnInit } from '@angular/core';

@Component({
 selector: 'app-nav-header',
 templateUrl: 'nav-header.component.html'
})

export class NavHeaderComponent implements OnInit {
 constructor() { }

 ngOnInit() { }
}
