/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerParentRelationshipView { 
    SalesGroupId?: number;
    Id?: number;
    CustomerType?: number;
    Name?: string | null;
    ContactFirstName?: string | null;
    ContactMiddleName?: string | null;
    ContactLastName?: string | null;
    ContactEmail?: string | null;
    readonly ContactFullName?: string | null;
    ActiveTickets?: number;
    ActivePassePartouts?: number;
    ExternalId?: string | null;
    CustomerNumber?: string | null;
    DateOfBirth?: Date | null;
    PaysByInvoice?: boolean;
    AccountManagerId?: number | null;
    ChildId?: number;
    RelationShipTypeId?: number;
    ConnectedOn?: Date;
    DisconnectedOn?: Date | null;
    Deleted?: Date | null;
}

