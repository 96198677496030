
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<t4-list-view #listVw [options]="listOptions" (onDataRequest)="onDataRequest($event)">
  <ng-container *t4ListActions>
    <div class="object-actions bg-white text-right">
      <button (click)="deleteUnprocessed()" [disabled]="!canDeleteUnprocessed || allActionsDisabled" class="btn btn-primary border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'MEMBERSHIP.RENEWAL-FILE-DETAILS.BUTTON.DELETE.UNPROCESSED' | translate}}</button>
      <button (click)="deleteFailed()" [disabled]="!canDeleteUnprocessed || allActionsDisabled" class="btn btn-primary border btn-small" type="button"><i class="far fa-trash-alt"></i>{{'MEMBERSHIP.RENEWAL-FILE-DETAILS.BUTTON.DELETE.FAILED' | translate}}</button>
      <t4-lro-button (onLROClick)="process()" (onLROComplete)="onProgressFinished()" [lroTypeCode]="longRunningOperationTypeEnum.ProcessImportedRenewalFile" [isDisabled]="isBlockProcess || allActionsDisabled" cssClasses="btn btn-primary border btn-small" icon="fas fa-file-import" text="BTN.MEMBERSHIP_IMPORT-FILE.PROCESS"></t4-lro-button>
      <t4-lro-button (onLROClick)="exportList()" (onLROComplete)="onProgressFinished()" [lroTypeCode]="longRunningOperationTypeEnum.ExportMembershipRenewalList" [isDisabled]="allActionsDisabled" cssClasses="btn btn-primary border btn-small" icon="fas fa-file-export" text="BTN.MEMBERSHIP_IMPORT.EXPORT"></t4-lro-button>
    </div>
  </ng-container>
  <ng-container *t4ListItem="let item">
    <td>{{ item.Id | replaceOnEmpty }}</td>
    <td>{{ item.CustomerName | replaceOnEmpty }}</td>
    <td>{{ item.CustomerNumber | replaceOnEmpty }}</td>
    <td>{{ item.PayingCustomerName | replaceOnEmpty }}</td>
    <td>{{ item.PayingCustomerNumber | replaceOnEmpty }}</td>
    <td *ngIf="this.passedData.DisplayCalculatedPrice">{{ item.CalculatedPriceRange | replaceOnEmpty }}</td>
    <td>{{ item.OriginalTransactionKey | replaceOnEmpty }}</td>
    <td>{{ 'ENUM.CUSTOMER_MEMBERSHIP_RENEW_IMPORT_LINE_STATUS.' + item.StatusCode | translate }}</td> <!-- add order by -->
    <td>{{ item.Reason | replaceOnEmpty | translate }}</td> <!-- add order by -->
    <td class="grid-action">
      <button (click)="deleteLine(item)" [disabled]="!(item.Status == 2 || item.Status == 0)"  type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
    </td>
  </ng-container>
</t4-list-view>
