import {Pipe, PipeTransform} from '@angular/core';
import {ActivityActionTypeDictionary} from '../enums/activity-action-type.enum';
import {ActivityTypeDictionary, ActivityTypeEnum} from '../enums/activity-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'activityLogFormat' })
export class ActivitiyLogUserLinePipe implements PipeTransform {

  constructor(private translate: TranslateService){}

  transform(activityLogLine: any) {
    let result : string = activityLogLine.ActivityType;

    let action = '';
    switch (activityLogLine.ActionType) {
      case 2:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.CREATED');
        break;
      case 3:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.MODIFIED');
        break;
      case 4:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.DELETED');
        break;
      case 5:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-ROLES');
        break;
      case 6:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.INCORPORATED');
        break;
      case 7:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.PRINTED');
        break;
      case 8:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-DEEPLINK');
        break;
      case 9:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-PURCHASE-RIGHTS');
        break;
      case 10:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-TICKETSHOP');
        break;
      case 11:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-TEXT');
        break;
      case 12:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-PAYMENT-METHODS');
        break;
      case 13:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-AVAILABILTY');
        break;
      case 14:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-COMMERCIAL');
        break;
      case 15:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-PRICELIST-PRICE');
        break;
      case 16:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.EDITED-SALES-CHANNELS');
        break;
      case 18:
        action = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.ACTION.ANONYMIZED');
        break;
      default:
        action = ActivityActionTypeDictionary[activityLogLine.ActionType];
    }

    switch (activityLogLine.ActivityType) {
      case 1:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.IP-LOGIN') + activityLogLine.IpAddress;
        break;
      case 2:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.LOGGED-OUT');
        break;
      case 3:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.RELATION') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/relations/customers/details/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 4:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.ORDER') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/sales/orders/overview/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 5:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.RESERVATION') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/sales/reservations/overview/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 6:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.EVENT') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/events">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 7:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.PASSE-PARTOUT') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/passepartouts">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 8:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.SALE-PERIOD') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/salesperiod">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 9:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.PRICELIST') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/pricelists">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 10:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.USER') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/profile/admin/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 11:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.INVOICE-BATCH') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/sales/invoicing">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 12:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.PRINT-BATCH') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/service/shipment">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 13:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.EVENT-PLACEMENT') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/events">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 14:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.EVENT-TYPE') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/system/eventtype/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 15:
        result = ''; //'Prijscomponent ' + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/system/eventtype/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 21:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.MEMBERSHIP') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/sales/membership/details/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 24:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.SALES-CHANNEL') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/system/saleschannels/details/' + activityLogLine.ActionId + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      case 26:
        result = this.translate.instant('MODELS.PIPES.ACTIVITY-USER-LINE.RESULT.EMAIL-TEMPLATE') + (activityLogLine.ActionName.endsWith('|D') ? activityLogLine.ActionName.slice(0, -2) : '<a href="/administration/system/mailtemplates/details/' + activityLogLine.ActionName + '">' + activityLogLine.ActionName + '</a>') + action;
        break;
      default:
        const activityTypeDict = ActivityTypeDictionary[activityLogLine.ActivityType] ?? {name: 'Activity not found: ' + activityLogLine.ActivityType, article: ''};
        result = `${activityTypeDict.article} ${activityTypeDict.name} ${action}`;
        if (activityLogLine.ActionName) {
          let link: string;
          switch (activityLogLine.ActivityType) {
            case ActivityTypeEnum.Blacklist:
              link = `<a href="/relations/customers/details/${activityLogLine.ActionName.replace('#', '')}">${activityLogLine.ActionName}</a>`;
              break;
            default:
              link = activityLogLine.ActionName;
              break;
          }
          result += ` (${link})`;
        }
    }

    return result;
  }
}
