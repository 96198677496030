import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { KeyValuePair } from 'app/shared/models/key-value-pair';
import {TranslateService} from '@ngx-translate/core'
import { DiscountSettingsValidator } from 'app/shared/validators/discount-settings-validator';
import {DiscountService} from "../../api";

@Component({
  selector: 'app-discount-form',
  templateUrl: './edit-discount-form.component.html'
})
export class EditDiscountFormComponent implements OnInit, AfterViewInit {

  public form: FormGroup;
  public typeCbo: FormControl;

  public saving = false;

  private isNew: boolean = true;
  private editObject: any = null;

  validationMessages = [];

  discountTypes = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditDiscountFormComponent>,
    private translate: TranslateService,
    private discountService: DiscountService,
    private discountSettingsValidator: DiscountSettingsValidator,
    private loader: HttpLoaderService) { }

  private requiredFields: KeyValuePair<boolean> = null;

  isRequiredField(field: string, targetForm: FormGroup = null): boolean {

    if (this.requiredFields === null) {
      this.requiredFields = {};
    }

    if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {

      const form = targetForm ? targetForm : this.form;
      const formField = form.get(field);

      if (!formField.validator) {
        return false;
      }

      const validator = formField.validator({} as AbstractControl);
      this.requiredFields[field] = validator && validator.required;

      formField.validator(formField);
    }
    return this.requiredFields[field];
  }

  ngOnInit() {

    this.isNew = this.passedData.isNew;
    this.editObject = this.passedData.payload;

    this.discountTypes.push({ Id: 2, Name: this.translate.instant('FINANCE.DISCOUNTS.FIELDS.TYPEDISCOUNT.SINGLE') });
    this.discountTypes.push({ Id: 3, Name: this.translate.instant('FINANCE.DISCOUNTS.FIELDS.TYPEDISCOUNT.MULTIPLE') });

    this.typeCbo = new FormControl('', [Validators.required]);

    this.form = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl('', [Validators.required]),
      ShopDisplayName: new FormControl('', [Validators.required]),
      CodeType: this.typeCbo,
      DiscountCode: new FormControl('', {
        validators: Validators.required,
        asyncValidators: [
          this.discountSettingsValidator.discountCodeTaken.bind(this)
        ],
        updateOn: 'blur'
      }),
    });


    if (this.passedData.payload !== null) {

      this.loader.open();

      this.discountService.getById(this.passedData.payload.Id).subscribe((discount: any) => {

        this.editObject = discount;
        this.discountSettingsValidator.editingObject = discount;

        if (!this.isNew) {
          this.editObject.CodeType = +this.editObject.CodeType;
          this.form.patchValue(this.editObject);
          this.typeCbo.disable();
          this.updateRequiredFields();
        }

        this.loader.close();

      });
    }


  }

  ngAfterViewInit() {


  }

  onDiscountTypeChange(e) {
    this.updateRequiredFields();
  }

  updateRequiredFields() {

    let type = this.form.controls.CodeType.value;

    const discountCodeControl = this.form.get('DiscountCode');

    if (type === 2) {
      //code required
      discountCodeControl.setValidators([Validators.required]);
      discountCodeControl.setAsyncValidators(this.discountSettingsValidator.discountCodeTaken.bind(this));
    }
    else {
      //code not required
      discountCodeControl.setValidators(null);
      discountCodeControl.setAsyncValidators(null);
    }

    discountCodeControl.updateValueAndValidity();
  }

  isSingleCode(): boolean {
    const value = this.form.controls.CodeType.value;
    return value === 2;
  }


  submit() {
    const propertyObject = this.form.value;

    this.saving = true;
    this.loader.open();

    // store the object and return the id
    if (this.isNew) {
      this.discountService.createDiscount(propertyObject).subscribe((id: number) => {
        // close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    } else {
      this.discountService.putDiscount(propertyObject).subscribe((id: any) => {
        this.dialogRef.close(true);
        this.loader.close();
      });
    }
  }
}
