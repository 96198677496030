

<div class="object-actions mt-3">

    <ng-container *ngIf="showContingentEditButton">
        <button class="btn btn-primary border btn-small" [disabled]="allowContingentEdit === false || existingEndpoints.length === 0" (click)="manageContingents()" >
            <i class="fa fa-users"></i>
            <span>{{'COMPONENTS.VENUE-DEFINITION.UNPLACED-TICKETS-LIST.BUTTON.QUOTAS' | translate}}</span>
        </button>
    
        <span class="separator"></span>
    </ng-container>

    <button [disabled]="eventPlacementEventId !== null"  class="btn btn-primary border btn-small" title="Huidige sectie / locatie beheren." (click)="addOrUpdateTickets(1, true, null)"  >
        <i class="far fa-plus-square"></i>
        <span>{{'COMPONENTS.VENUE-DEFINITION.UNPLACED-TICKETS-LIST.BUTTON.AMOUNT-OF-CHANGES' | translate}}</span>
    </button>

</div>


<p class="py-3">{{'COMPONENTS.VENUE-DEFINITION.UNPLACED-TICKETS-LIST.AMOUNT-OF-TICKETS-OF-THIS-TYPE' | translate}}<b>{{existingEndpoints.length | number }}</b></p>


