import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SalesSectionDetailsComponent } from '../../../dialogs/sales-section-details/sales-section-details.component';
import { VenueRendererComponent } from '../../venue-renderer/venue-renderer.component';
import {ListViewSelectionMode, PagedResult} from '@tymes4-shared';
import { EventService, SectionAvailabilityArgs, SellablePlacementSectionView, TicketTypeService, VenueAvailabilityArgs, VenueService } from '../../../api';

@Component({
  selector: 'app-sales-section-selection',
  templateUrl: './sales-section-selection.component.html',
  styleUrls: ['./sales-section-selection.component.scss']
})
export class SalesSectionSelectionComponent implements OnInit {

  //for listview
  @ViewChild('listVw') listVw;
  @ViewChild(VenueRendererComponent) venueComponent: VenueRendererComponent;

  public listOptions = {
    search: true,
    showBarcodeSearch: false,
    pageSize: 5,
    noItemsText: 'GENERIC.LISTVIEW.NO-ITEMS.TEXT',
    searchTitle: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.SEARCHTITLE',
    renderInCard: false,
    selectionMode: ListViewSelectionMode.DirectSelect,
    headers: [
      { sortField: 'Name', label: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.HEADER.SECTION', width: 200, isDefaultSort: 'asc' },
      { sortField: null, label: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.HEADER.AVAILABILITY' },
   ]
  };

  public selectedItem: any;
  displayVenue = false;
  hasVenuePlan = true;
  inMapMode = true;
  //end for listview

  constructor(
    private ticketTypeService: TicketTypeService,
    private dialog: MatDialog,
    private eventService: EventService,
    private venueService: VenueService,
    private translate: TranslateService
  ) { }

  ticketTypes = [];
  selectedCustomer = {};
  selectedEvents = [];
  selectedPassePartout = null;
  selectedMembership = null;
  selectedCrossSellProductVariant = null;
  sellableSections = [];
  selectedSellableSection = null;
  selectedOrderLines = [];

  public hasEvents = false;
  public hasPassePartouts = false;
  public hasMemberships = false;
  public hasMatchDayPasses = false;
  public hasCrossSellProductVariants = false;

  @Output() onSectionOrderChange = new EventEmitter<object>();

  @Input()
  set customer(c) {
    this.selectedCustomer = c;
  }

  @Input()
  set orderlineSelection(e) {
    this.selectedOrderLines = e;
    if (e != null && e.length > 0) {
      this.hasEvents = e[0].EventId !== null;
      this.hasPassePartouts = e[0].PassePartoutId !== null;
      this.hasMemberships = e[0].MembershipId !== null;
      this.hasMatchDayPasses = e[0].MatchDayPassId !== null;
      this.hasCrossSellProductVariants = e[0].CrossSellProductVariantId !== null;
    } else {
      this.hasEvents = false;
      this.hasPassePartouts = false;
      this.hasMemberships = false;
      this.hasCrossSellProductVariants = false;
      this.hasMatchDayPasses = false;
    }
  }

  @Input()
  set reservations(o: any) {
    if (o != null && o.Lines != null && o.Lines.length > 0) {
      this.hasEvents = o.Lines[0].EventId !== null;
      this.hasPassePartouts = o.Lines[0].PassePartoutId !== null;
      this.hasMemberships = o.Lines[0].MembershipId !== undefined && o.Lines[0].MembershipId !== null;
      this.hasCrossSellProductVariants = o.Lines[0].CrossSellProductVariantId !== null;
      this.hasMatchDayPasses = o.Lines[0].MatchDayPassId !== null;
    } else {
      this.hasEvents = false;
      this.hasPassePartouts = false;
      this.hasMemberships = false;
      this.hasCrossSellProductVariants = false;
      this.hasMatchDayPasses = false;
    }
  }

  public tabSelected: string = 'E';
  @Input()
  set selectedTab(s: string) {
    if (this.tabSelected !== s) {
      this.selectedPassePartout = null;
      this.selectedEvents = [];
      this.selectedMembership = null;
      this.selectedCrossSellProductVariant = null;
      this.refreshData();
    }

    this.tabSelected = s;

  }

  ngOnInit() {
    this.ticketTypeService.getAllTicketTypes().subscribe((data:any) =>{
        this.ticketTypes = data;
    });
  }

  venuePlanSvg = null;

  refreshData() {

    this.listVw.refreshData();

    const ppId = this.selectedPassePartout === null ? null : this.selectedPassePartout.Id;
    const eventIds = this.selectedEvents.map(e => e.Id);

    if (ppId !== null || (eventIds !== null && eventIds.length > 0)) {

      var args : VenueAvailabilityArgs = {
        EventIds: eventIds,
        PassePartoutId: ppId
      }

      // first get the availability
      this.venueService.getVenueAvailability(args).subscribe((availability) => {

        if (availability === null) {
          // nothing to display
          this.displayVenue = false;
        } else {
          // we have availability, get the baseplan
          this.venueService.getAvailabilityBasePlan(args).subscribe((planSVG: string) => {
            this.venuePlanSvg = planSVG;
            this.setAvailability(availability);
          });
        }
      });
    } else {
      this.displayVenue = false;
    }

  }

  setAvailability(availability) {
    // method returns if there is a match on section
    this.displayVenue = true;
    this.hasVenuePlan = this.venueComponent.setVenuePlan(this.venuePlanSvg, availability);
    this.venueComponent.resizeMap();

     setTimeout(() => {
      this.venueComponent.resizeMap();
     }, 500);
  }

  setMapMode (mapMode) {
    this.inMapMode = mapMode;

    if (this.inMapMode === false) {
      // research for the sections
      this.listVw.refreshData();
    }
  }

  getPageTitle() {
    return '';
  }

  determineSelectedIds() {

    let result: number[];

    if (this.selectedEvents.length > 0) {
      result = this.selectedEvents.map(e => e.Id);
    }
    else {

      result = [];

      //Passe partouts selected, use the combined event ids
      if (this.selectedPassePartout != null) {

          for (let id of this.selectedPassePartout.LinkedEventIds) {
            //prevent duplicates
            if (result.indexOf(id) <= 0) {
              result.push(id);
            }
          }

      }
    }

    return result;
  }

  OnDataRequest(e) {
    const doEmptyRequest = (this.inMapMode == false);
    const isEmptyRequest = (e.searchText === null || e.searchText === '');
    let doRequest = false;

    if ((this.selectedEvents !== null && this.selectedEvents.length > 0) || (this.selectedPassePartout != null)) {
      //something selected, we might need to do the request.
      doRequest = (doEmptyRequest === true || isEmptyRequest === false);
    }

    if (doRequest === true) {

      const ids = this.determineSelectedIds();
      this.eventService.findSellableSectionsForEvent(e.searchText, e.pageNr, e.pageSize, null, null, null, null, ids).subscribe((resp:any) => {
        this.listVw.Data = resp;
      });
    } else {
      const d : PagedResult =
      {
        TotalRecords: 0,
        Records: [],
        ResultCountCapped: false

      }
      this.listVw.Data = d;
    }

  }

  OnItemSelectionChange(items) {
    //implementation for single selection mode
    if (items.length > 0) this.selectedItem = items[0];
    else this.selectedItem = null;
  }

  onEventSelection(events) {
    this.selectedEvents = events;
    this.refreshData();
  }

  onPassePartoutSelection(passePartout) {
    this.selectedPassePartout = passePartout;
    this.refreshData();
  }

  onMembershipSelection(membership) {
    this.selectedMembership = membership[0];

    //notify the parent
    this.onSectionOrderChange.emit({
      parentVbbId: null,
      parentGroupingId: null,
      orderLines: null,
      eventIds: [],
      passePartoutIds: [],
      membershipIds: this.selectedMembership === null ? [] : [this.selectedMembership.Id],
      crossSellProductVariantIds: []
    });
  }

  onCrossSellProductVariantSelection(crossSellProductVariant) {
    this.selectedCrossSellProductVariant = crossSellProductVariant;

    //notify the parent
    this.onSectionOrderChange.emit({
      parentVbbId: null,
      parentGroupingId: null,
      orderLines: null,
      eventIds: [],
      passePartoutIds: [],
      membershipIds: this.selectedMembership === null ? [] : [this.selectedMembership.Id],
      crossSellProductVariantIds: this.selectedCrossSellProductVariant === null ? [] : [this.selectedCrossSellProductVariant.Id]
    });
  }

  onMatchdaypassSelectionChange(orderLines) {
    //notify the parent
    this.onSectionOrderChange.emit({
      parentVbbId: null,
      parentGroupingId: null,
      orderLines: orderLines,
      eventIds: [],
      passePartoutIds: [],
      membershipIds: [],
      crossSellProductVariantIds: [],
      matchDayPassId: orderLines === null ? [] : orderLines[0].MatchDayPassId
    });
  }

  sectionAvailability(section) {

    let result: string = '';

    for (let t of this.ticketTypes) {

      let c = this.getCount(section, t.Id);

      if (c > 0)  {
        if (result.length > 0) result += ', ';
        result += `${t.Name} (${c})`;
      }
    }

    if (result.length == 0) return this.translate.instant('SALES.ORDERS.NEW.SALES-SECTION-SELECTION.NOT-AVAILABILE.MSG');

    return result;
  }

  getCount(section, ticketTypeId) {
    const c = section.TicketsPerTicketTypeId.filter(function(tptt){ if (tptt.Id == ticketTypeId) { return  tptt}});

    if (c != null && c.length > 0) {
      return c[0].Value;
    }

    return 0;
  }

  handleSectionClick(e) {

    let ppId = this.selectedPassePartout === null ? null : this.selectedPassePartout.Id;
    let eventIds = this.selectedEvents.map(e => e.Id);


    let args : SectionAvailabilityArgs = {
      EventIds: eventIds,
      ParentVBBId: e.VenueBuildingBlockId
    }
    if (ppId && ppId != null)
    {
      args.PassePartoutIds = [ppId]
    }

    this.venueService.getTicketTypeForSection(args).subscribe(tts => {
       e.TicketsPerTicketTypeId = tts;
      this.selectSection(e);
    });

  }

  selectSection(section) {
    this.selectedSellableSection = section;
    this.openSectionDialog();
  }

  openSectionDialog() {

    let title = this.translate.instant('SALES.ORDERS.NEW.SALES-SECTION-SELECTION.DIALOG.DETAILS.TITLE', { sectionName: this.selectedSellableSection.Name});
    let dialogRef: MatDialogRef<any> = this.dialog.open(SalesSectionDetailsComponent, {
      height: '95%',
      width: '98vw',
      maxWidth: '98vw',
      disableClose: true,
      data: { title: title, payload: {
        section: this.selectedSellableSection,
        ticketTypes: this.ticketTypes,
        selectedEvents: this.selectedEvents,
        selectedEventIds: this.determineSelectedIds(),
        selectedPassePartouts: this.selectedPassePartout === null ?  [] : [this.selectedPassePartout],
        selectedOrderLines: this.selectedOrderLines
      } }
    });
    dialogRef.afterClosed()
      .subscribe(orderLinesPerTicketType => {
        if (orderLinesPerTicketType) {
          //notify the parent
          this.onSectionOrderChange.emit({
            parentVbbId: this.selectedSellableSection.VenueBuildingBlockId,
            parentGroupingId: this.selectedSellableSection.GroupingId,
            orderLines: orderLinesPerTicketType,
            eventIds: this.selectedEvents.map(a => a.Id),
            passePartoutIds: this.selectedPassePartout === null ?  [] : [this.selectedPassePartout.Id],
            membershipIds: this.selectedMembership === null ? [] : [this.selectedMembership.Id],
            crossSellProductVariantIds: this.selectedCrossSellProductVariant === null ? [] : [this.selectedCrossSellProductVariant.Id]
          });
        }

      }
    );
  }
}
