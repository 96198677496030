
<div class="row modal-bar">
  <div class="col-sm-10">
      <h5 class="modal-title">{{ title }}</h5>
  </div>
  <div class="col-sm-2">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
          

      <div class="row">
        <div class="col-6">
          <b>{{'DIALOGS.VBB-UPDATE-EP-DIALOG.FORM.LABEL.EQUAL' | translate}}</b>
          <div style="overflow-y: auto; border: 1px solid #EEEEEE; padding: 5px 15px; height: 300px;">
            <ng-container *ngTemplateOutlet="eventList; context:{filter: false}"></ng-container>
          </div>
        </div>
        <div class="col-6">
          <b>{{'DIALOGS.VBB-UPDATE-EP-DIALOG.FORM.LABEL.DIFFERENT' | translate}}</b>
          <div style="overflow-y: auto; border: 1px solid #EEEEEE; padding: 5px 15px; height: 300px;">
            <ng-container *ngTemplateOutlet="eventList; context:{filter: true}"></ng-container>
          </div>
        </div>
      </div>
      



      <div class="form-layout-footer row mt-3">
        <div class="col-6">
          <button (click)="submit()" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="cancel()" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>  
</form>
<!-- 



<div>
  <div>
    <input type="checkbox" [(ngModel)]="showPassedEvents"><span>  Toon evenementen uit het verleden</span>
  </div>

  
    <div>
  
    <div fxLayoutAlign="flex-end" style="margin-top: 10px;">
    <button type="button" class="btn" title="Annuleren">
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>
    <button type="button" class="btn btn-accent" title="Doorvoeren op evenement placeringen." fxFlexOffset="5px">
      <i class="far fa-save"></i>
      <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
    </button>
    </div>
  </div>
</div> -->

<!-- Template -->
<ng-template #eventList let-filter="filter">
  <div>
    <ng-container *ngFor="let epEventState of epEventStates">
      <ng-container *ngIf="filter === epEventState.DiffersFromVBB">
        <div *ngIf="!epEventState.PassedEvent || showPassedEvents">
          <mat-checkbox [(ngModel)]="epsToUpdate[epEventState.EventId]"
                        [name]="epEventState.EventId"><span [style.fontStyle]="epEventState.PassedEvent ? 'italic' : 'normal'">{{epEventState.EventName}}</span></mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
