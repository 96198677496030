
<div style="height: 100%;">
  <div class="row">
    <div class="col-md-6 col-lg-10" style="height: 68px;">{{ passedData.title }}</div>
    <div class="col-md-6 col-lg-2 pd-t-10 text-right">
      <button class="btn btn-primary btn-block" (click)="dialogRef.close(null)">
        <i class="pd-x-5 fas fa-ban"></i>
        {{'GENERIC.SNACKBAR.CLOSE' | translate}}
      </button>
    </div>
  </div>

  <iframe #pdfFrame style="width: 100%; position: relative; top: 0px; bottom: 0px;"></iframe>
</div>
