import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import {LoggingService} from '@tymes4-shared';
import { Router } from '@angular/router';
import {ConfirmService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { Observable } from 'rxjs';
import { AppSalesState } from '../models/app-sales-state';
import { PendingOrderService, PendingReservationService, PutPendingReservationLinesArgs } from '../api';

@Injectable()
export class SalesHelperService implements OnInit {

  constructor(  private logging: LoggingService,
                private loader: HttpLoaderService,
                private pendingOrderService: PendingOrderService,
                private confirmService: ConfirmService,
                private pendingReservationService: PendingReservationService) {
  }

//  @Output() onSuccesfullStore = new EventEmitter<number>();

  public pendingOrderKey = 'pendingOrderId';
  public state: AppSalesState = new AppSalesState();

  ngOnInit() {

  }

  clearState(): any {
    this.state = new AppSalesState();
    sessionStorage.removeItem(this.pendingOrderKey);

  }

  public init() {
    this.state = new AppSalesState();
  }

  mapToServiceLines(orderLines) {
    if (!orderLines) {
      return null;
    }

    const result = [];

    for (const olPerTicketType of orderLines) {
      if (olPerTicketType.OrderLines != null) {

        for (const line of olPerTicketType.OrderLines) {

          result.push ({
            ContingentId: line.ContingentId,
            PriceVariantId: line.PriceVariantId,
            TicketTypeId: line.TicketTypeId,
            Column: line.Column,
            NumberOfTickets: line.NumberOfTickets,
            Row: line.Row
          });
        }
      }
    }

    
    return result;
  }

  public handleSectionReservationLinesChanged (changeArgs, currentPendingReservation): Observable<any> {

    var payload: PutPendingReservationLinesArgs = {
      ParentVenueBuildingBlockId: changeArgs.parentVbbId,
      GroupingId: changeArgs.parentGroupingId,
      EventIds: changeArgs.eventIds,
      PassePartoutIds: changeArgs.passePartoutIds,
      CrossSellProductVariantIds: changeArgs.crossSellProductVariantIds,
      Lines: this.mapToServiceLines(changeArgs.orderLines)
    };

    this.loader.open();

    let result = currentPendingReservation;

    const resultObservable = new Observable((observer) => {

      if (currentPendingReservation == null) {
          //new order
          this.pendingReservationService.initializeNewPendingReservation().subscribe((reservation:any) => {

            this.storeLinesForSection(payload, reservation).subscribe((reservation:any) => {
              observer.next(reservation);
              observer.complete();
            });

          });
        }
        else {
          this.storeLinesForSection(payload, currentPendingReservation).subscribe((reservation:any) => {
            observer.next(reservation);
            observer.complete();
          });
        }
    });

    return resultObservable;

  }

  storeLinesForSection (payload, pendingReservation): Observable<any> {
    const resultObservable = new Observable((observer) => {

        //store the orderlines
        this.pendingReservationService.putPendingReservationLines(pendingReservation.Id, payload).subscribe((success:any) =>  {

          if (success) {

              //reget the order and details
            this.pendingReservationService.getPendingReservationDetails(pendingReservation.Id).subscribe((reservation:any) => {
              this.loader.close();
              observer.next(reservation);
              observer.complete();
            });
          }
          else {

            this.loader.close();

            this.confirmService.confirm({ title: 'De regel is niet toegevoegd',
              message: `Het is niet gelukt de positie(s) aan de reservering toe te voegen. Normaal gesproken betekent dit dat de positie inmiddels bezet is.`,
              okonly: true}).subscribe((confirmed:boolean) => {
                observer.next(pendingReservation);
                observer.complete();
              });
          }
        });


    });

    return resultObservable;
  }


  handleSectionOrderLinesChanged(changeArgs): Observable<any> {
    const payload: any = {
      ParentVenueBuildingBlockId: changeArgs.parentVbbId,
      GroupingId: changeArgs.parentGroupingId,
      EventIds: changeArgs.eventIds,
      PassePartoutIds: changeArgs.passePartoutIds,
      MembershipIds: changeArgs.membershipIds,
      CrossSellProductVariantIds: changeArgs.crossSellProductVariantIds,
      MatchDayPassId: changeArgs.matchDayPassId,
      OrderLines: changeArgs.matchDayPassId !== null && changeArgs.matchDayPassId !== undefined ? changeArgs.orderLines : this.mapToServiceLines(changeArgs.orderLines),
    };

    const resultObservable = new Observable((observer) => {

      if (this.state.order == null) {

        // new order
        let getNewPendingOrder: Observable<object>;

        if (this.state.selectedCustomer != null) {
          getNewPendingOrder = this.pendingOrderService.getNewForCustomer(this.state.selectedCustomer.Id);
        } else {
          getNewPendingOrder = this.pendingOrderService.getNewAnonymous();
        }

        getNewPendingOrder.subscribe((order: any) => {

          sessionStorage.setItem('pendingOrderId', order.Id.toString());

          this.storeOrderLinesForSection(payload, order).subscribe((newOrder: any) => {
            this.state.order = newOrder;
            observer.next(newOrder);
            observer.complete();
          });

        });
      } else {
        this.storeOrderLinesForSection(payload, this.state.order).subscribe((newOrder: any) => {
          this.state.order = newOrder;
          observer.next(newOrder);
          observer.complete();
        });
      }
    });

    return resultObservable;
  }

  storeOrderLinesForSection (payload, order): Observable<any> {

    const resultObservable = new Observable((observer) => {

       //store the orderlines
       this.pendingOrderService.putOrderLines(order.Id, payload).subscribe((success:any) =>  {

          if (success) {

            this.pendingOrderService.getPendingOrderDetails(order.Id).subscribe((updatedOrder:any) => {
              this.state.order = updatedOrder;
              observer.next(updatedOrder);
              observer.complete();
            });
          }
          else {

            this.loader.close();

            this.confirmService.confirm({ title: 'De regel is niet toegevoegd',
              message: `Het is niet gelukt de positie(s) aan de order toe te voegen. Normaal gesproken betekent dit dat de positie inmiddels bezet is.`,
              okonly: true}).subscribe((confirmed:boolean) => {

                observer.next(order);
                observer.complete();
              });
          }
      });
    });

    return resultObservable;
  }
}





