<div class="row justify-content-between" *ngIf="statistics !== null">
  <div class="col-lg-8">
  </div>
  <div class="col-lg-4 d-flex justify-content-xl-end flex-column flex-wrap align-items-lg-end pb-1">

    <ng-select [items]="selectableSeasons" bindLabel="Name" bindValue="Id" [(ngModel)]='seasonValue' (change)="onIntervalChange($event)" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>

    <span class="justify-content-left">{{'STATISTICS-DASHBOARD.TARGETS.UPDATE-TIME' | translate : {time:this.statistics.ExpiryInSeconds} }}</span>
  </div>
</div>


<div class="row" *ngIf="statistics !== null">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-header">
        <h2>{{'STATISTICS-DASHBOARD.TARGETS.TITLE' | translate}}</h2>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-xl-3">
            <apx-chart  [chart]="turnOverTotalOptions.chart" *ngIf="turnOverTotalOptions !== null"
                        [colors]="turnOverTotalOptions.colors"
                        [labels]="turnOverTotalOptions.labels"
                        [plotOptions]="turnOverTotalOptions.plotOptions"
                        [series]="turnOverTotalOptions.series">
            </apx-chart>
          </div>
          <div class="col-xl-3 card-turnover-total">
            <div>
              <h1 class="amountTotalCardFormat">{{ statistics.TurnOverWeek | currency:'EUR':'symbol':'1.0-0' }}</h1>
              <p class="amountTitleTotalCardFormat">{{'STATISTICS-DASHBOARD.TARGETS.WEEK' | translate}}</p>
            </div>
            <div class="card-turnover-total-line">
              <h1 class="amountTotalCardFormat">{{ statistics.TurnOverMonth | currency:'EUR':'symbol':'1.0-0' }}</h1>
              <p class="amountTitleTotalCardFormat">{{'STATISTICS-DASHBOARD.TARGETS.MONTH' | translate}}</p>
            </div>
            <div class="card-turnover-total-line">
              <h1 class="amountTotalCardFormat">{{ statistics.TurnOverTotal | currency:'EUR':'symbol':'1.0-0' }}</h1>
              <p class="amountTitleTotalCardFormat">{{'STATISTICS-DASHBOARD.TARGETS.TOTAL' | translate}}</p>
            </div>
          </div>
          <div class="col-xl-6">
            <apx-chart  [chart]="turnoverWeekOptions.chart" *ngIf="turnoverWeekOptions !== null"
                        [stroke]="turnoverWeekOptions.stroke"
                        [colors]="turnoverWeekOptions.colors"
                        [plotOptions]="turnoverWeekOptions.plotOptions"
                        [series]="turnoverWeekOptions.series"
                        [labels]="turnoverWeekOptions.labels"
                        [xaxis]="turnoverWeekOptions.xaxis"
                        [markers]="turnoverWeekOptions.markers"
                        [legend]="turnoverWeekOptions.legend"
                        [tooltip]="turnoverWeekOptions.tooltip">
            </apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card card-default card-profile" *ngIf="statistics !== null">
  <div class="card-footer card-profile-footer">
    <t4-tabs (selected)="onSelectedTabChange($event)">
      <t4-tab tabTitle="{{'STATISTICS-DASHBOARD.TARGETS.ACCOUNTMANAGER' | translate}}" id="A"></t4-tab>
      <t4-tab tabTitle="{{'STATISTICS-DASHBOARD.TARGETS.PASSE-PARTOUT' | translate}}" id="P"></t4-tab>
    </t4-tabs>
  </div>
</div>

<div class="customer-details" *ngIf="statistics !== null">

  <ng-container *ngIf="selectedTabId === 'A'">
    <h2>{{'STATISTICS-DASHBOARD.TARGETS.REVENUE-PER-ACCOUNTMANAGER' | translate}}</h2>
    <div class="row row-sm" matchHeight="card-body-height">
      <div class="col-4" *ngFor="let item of statistics.TurnOverAccountManagers">
        <app-card-turnover [Name]="item.Name" [TurnOverWeek]="item.TurnOverWeek" [TurnOverMonth]="item.TurnOverMonth" [TurnOverTotal]="item.TurnOverTotal" [RealizedTargetPerc]="item.RealizedTargetPerc"></app-card-turnover>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedTabId === 'P'">
    <h2>{{'STATISTICS-DASHBOARD.TARGETS.REVENUE-PER-PASSE-PARTOUT' | translate}}</h2>
    <div class="row row-sm" matchHeight="card-body-height">
      <div class="col-4" *ngFor="let item of statistics.TurnOverPassePartouts">
        <app-card-turnover [Name]="item.Name" [TurnOverWeek]="item.TurnOverWeek" [TurnOverMonth]="item.TurnOverMonth" [TurnOverTotal]="item.TurnOverTotal" [RealizedTargetPerc]="item.RealizedTargetPerc"></app-card-turnover>
      </div>
    </div>
  </ng-container>

</div>
