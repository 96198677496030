import { Injectable, OnInit } from '@angular/core';
import * as numeral from "numeral";

@Injectable()
export class LocalizationService  implements OnInit {

    constructor() 
    {

        numeral.register('locale', 'nl', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal : function (number) {
                return number === 1 ? 'e' : 'e';
            },
            currency: {
                symbol: '€ ' 
            }
        });

        numeral.locale('nl');

     }

    ngOnInit() {

      
    }
    numberToLocalString(value: number) {
        var n = numeral(value.toLocaleString());
        return n.format('0,0.00');
    }

    numberToCurrencyString(value: number) {
        var n = numeral(value.toLocaleString());
        return n.format('$0,0.00');
    }

    currencyStringToNumber (str) {
        var b = numeral (str);
        return b.value();    
    }
}
