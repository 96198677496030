import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AccountingDimensionService } from "../api";

@Injectable()
export class AccountingDimensionHelperService {

    public readonly activeAccountingDimensionsKey = 'active_dimensions';

    constructor(private accountingDimensionService: AccountingDimensionService) {}

    public getActiveAccountingDimensions(): Observable<Array<string>> {
        return new Observable((observer) => {
            let dimensionsJson: string = sessionStorage.getItem(this.activeAccountingDimensionsKey);
            let dimensions: Array<string>;
            if (dimensionsJson === null) {
                this.accountingDimensionService.getAccountingDimensions().subscribe((result) => {
                    dimensions = result.map((val) => val.toLowerCase());
                    dimensionsJson = JSON.stringify(dimensions);
                    sessionStorage.setItem(this.activeAccountingDimensionsKey, dimensionsJson);
                    observer.next(dimensions);
                    observer.complete();
                });
            } else {
                dimensions = JSON.parse(dimensionsJson);
                observer.next(dimensions);
                observer.complete();
            }
        });
    }

}