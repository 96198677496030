

<div class="text-center">
    <div fxLayout="row" fxLayoutWrap="wrap">
        <div fxFlex="40px">
            <i *ngIf="status <= 3" class="far fa-hourglass"></i>
            <i *ngIf="status === 5" class="fas fa-exclamation-circle" style="color: red;"></i>
            <i *ngIf="status === 4" class="far fa-check-circle" style="color: green;"></i>
        </div>
        <div fxFlex style="margin-left: -40px;">
            {{ title }}        
        </div>
    </div>
    <br/>            
    <ng-container *ngIf="status <= 3; else closeButton">
        <div mat-dialog-content>
            <mat-progress-bar [mode]="progressMode" [value]="progress"></mat-progress-bar>
        </div>
        <span style="padding-top: 20px; font-size: 9px;">{{'MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.DONT-REFRESH-BROWSER' | translate}}</span>
    </ng-container>

    <ng-template #closeButton>

        <button class="btn btn-default" (click)="dialogRef.close(false);">
            <i class="fas fa-ban"></i>
            <span>{{'MODELS.SERVICES.APP-LONG-RUNNING-OPERATION.CLOSE' | translate}}</span>
        </button>

    </ng-template>
</div>


