<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-10">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-2">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">

          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'INITIATIVE.TAB.PROMOTION.DISCOUNT' | translate}}<span class="tx-danger">*</span></label>
              <ng-select [items]="promotions" bindLabel="Name" bindValue="Id" placeholder="{{ 'INITIATIVE.TAB.PROMOTION.PLACEHOLDER.DISCOUNT' | translate}}" formControlName="PromotionId"
                         [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="PromotionId" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label">{{ 'GENERIC.EDITDIALOG.ACTIVEFROM' | translate}}</label>
              <input class="form-control" datepicker formControlName="ActiveFrom">
              <t4-display-validation controlName="ActiveFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label">{{ 'GENERIC.EDITDIALOG.ACTIVETILL' | translate}}</label>
              <input class="form-control" datepicker formControlName="ActiveTill">
              <t4-display-validation controlName="ActiveTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div><!-- row -->

        <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>
