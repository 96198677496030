import {ProductTypeEnum} from '@tymes4-shared';
import {Deserializable} from './deserializable';

export class OrderLine implements Deserializable<OrderLine> {

  deserialize(input: any): OrderLine {
    Object.assign(this, input);
    return this;
  }

  Id: number;
  TicketTypeId: number;
  EventPlacementId: number;
  NumberOfTickets: number;
  ParentVenueBuildingBlockId;
  Column: number;
  Row: number;
  ContingentId: number;
  PriceVariantId: number;
}
