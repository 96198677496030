import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {ConfirmService} from '@tymes4-shared';
import { MembershipService } from '../../../api';

@Component({
  selector: 'app-sales-membership-selection',
  templateUrl: './sales-membership-selection.component.html'
})
export class SalesMembershipSelectionComponent implements OnInit {
  form: FormGroup;
  memberships: any;

  constructor(private membershipService: MembershipService,
              private confirmService: ConfirmService,
              private translate: TranslateService) { }

  @Output() onMembershipSelectionChange = new EventEmitter<object>();
  @Input() hasOther: boolean;

  ngOnInit() {
  
    this.form = new FormGroup({
      MembershipId: new FormControl(null, [Validators.required]),
    });

    this.membershipService.listAllMemberships().subscribe(data => {
      this.memberships = data;
    });
  }

  doAction() {
    if (this.hasOther) {
      this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-MEMBERSHIP-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
        message: this.translate.instant('SALES.ORDERS.NEW.SALES-MEMBERSHIP-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

        return false;
    }

    const membership = this.memberships.filter(m => m.Id === this.form.controls.MembershipId.value);
    this.onMembershipSelectionChange.emit(membership);
    this.form.controls.MembershipId.setValue(null);
  }
}
