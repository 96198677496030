import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-confirm-membership-import',
  templateUrl: './confirm-membership-import.component.html',
  styleUrls: ['./confirm-membership-import.component.scss'],
})
export class ConfirmMembershipImportComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmMembershipImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
}
}
