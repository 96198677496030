


<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>




    <div  class="left-menu">

        <div class="left-menu-card" fxLayout="row">
            <div class="ddholder" fxFlex fxLayout="row" *ngIf="eventPlacementEventId == null">

                <div  class="full-width_first-child full-width_main">
                  <ng-select class="full-width_first-child" [items]="placementTypes" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.PLACEMENT.TYPE'  | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedPlacementTypeId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                </div>

                <i class="fa fa-save" (click)="setProperty('PlacementTypeId', selectedPlacementTypeId)"></i>
                <i class="fa fa-hand-pointer" (click)="selectByProperty('PlacementTypeId', selectedPlacementTypeId)"></i>

            </div>
            <div class="ddholder" fxFlex fxLayout="row">

                <div [class.full-width_first-child]="eventPlacementEventId != null"  class="full-width full-width_main">
                  <ng-select [class.full-width_first-child]="eventPlacementEventId != null" class="full-width" [items]="priceCategories" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.PRICE.CATEGORY' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedPriceCategoryId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                </div>

                <i class="fa fa-save" (click)="setProperty('PriceCategoryId', selectedPriceCategoryId)"></i>
                <i class="fa fa-hand-pointer" (click)="selectByProperty('PriceCategoryId', selectedPriceCategoryId);"></i>
            </div>

            <div class="ddholder" fxFlex fxLayout="row">
              <div class="form-group full-width full-width_main">
                <input class="form-control full-width" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.ROW-NAME' | translate}}" maxlength="64" #rowName>
              </div>
              <div fxflex="20px"><i class="fa fa-save" (click)="setProperty('RowNumber', rowName.value)"></i></div>
              <div fxFlex="20px" class="pad-right" ><i class="fa fa-hand-pointer" (click)="selectByProperty('RowNumber', rowName.value);"></i></div>
            </div>

            <div  class="ddholder" fxFlex fxLayout="row" >
              <div class="form-group full-width full-width_main">
                <input class="form-control full-width" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.SEAT-NAME' | translate}}" maxlength="64" #seatName>
              </div>
                <i class="fa fa-save" (click)="setProperty('SeatNumber', seatName.value)"></i>
                <i class="fa fa-hand-pointer" (click)="selectByProperty('SeatNumber', seatName.value);"></i>
            </div>
            <div class="ddholder" fxFlex fxLayout="row">
                <div  class="full-width full-width_main">
                  <ng-select class="full-width" [items]="seatRestrictionTypes" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.SEAT-RESTRICTION' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedSeatRestrictionTypeId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                </div>

                <i class="fa fa-save" (click)="setProperty('SeatRestrictionId', selectedSeatRestrictionTypeId)"></i>
                <i class="fa fa-hand-pointer" (click)="selectByProperty('SeatRestrictionId', selectedSeatRestrictionTypeId)"></i>
            </div>

            <div class="ddholder" fxFlex fxLayout="row">

                <div  class="full-width full-width_main">
                  <ng-select class="full-width"  #contingent [items]="contingents" placeholder="{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.PLACEHOLDER.CONTINGENT' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedContingentId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                </div>

                <i class="fa fa-save" (click)="setProperty('ContingentId', selectedContingentId)"></i>
                <i class="fa fa-hand-pointer" (click)="selectByProperty('ContingentId', selectedContingentId)"></i>

            </div>
            <div class="ddholder" fxFlex="300px" fxLayoutAlign="end center" fxLayout="row" class="pb-2">

                <button class="btn btn-primary border btn-small"  (click)="confirmAutoNumber()" title="{{'DIALOGS.EDIT-SEAT-PLAN.BUTTON.TITLE.ENABLE-NUMBERING' | translate}}" >
                    <i class="fas fa-magic"></i>
                    <span>{{'DIALOGS.EDIT-SEAT-PLAN.BUTTON.ENABLE-NUMBERING' | translate}}</span>
                </button>

                <button class="btn btn-primary border btn-small" [disabled]="selectedEndpoints.length != 1" (click)="confirmRowOffset()" title="{{'DIALOGS.EDIT-SEAT-PLAN.BUTTON.TITLE.ENABLE-NUMBERING' | translate}}" >
                    <i class="fas fa-sort-numeric-down"></i>
                    <span>{{'DIALOGS.EDIT-SEAT-PLAN.BUTTON.ROW-OFFSET' | translate}}</span>
                </button>


            </div>

        </div>
    </div>

    <section id="focal">

        <div class="plan-holder">

            <div class="app-key-area" fxLayout="row" fxLayoutAlign="space-between">
                <div>
                    <div class="app-key-holder">
                        <div class="app-key-lmouse">&nbsp;</div> + <div class="app-key">{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.DRAG' | translate}}</div> {{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.SELECT' | translate}}
                    </div>
                    <div class="app-key-holder">
                        <div class="app-key">Ctrl</div> + <div class="app-key-lmouse">&nbsp;</div> + <div class="app-key">{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.DRAG' | translate}}</div> {{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.DESELECT' | translate}}
                    </div>
                    <div class="app-key-holder">
                        <div class="app-key-rmouse">&nbsp;</div> + <div class="app-key">{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.DRAG' | translate}}</div> {{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.MOVE' | translate}}
                    </div>
                    <div class="app-key-holder">
                        <div class="app-key">{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.SCROLL' | translate}}</div> {{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.INFO.ZOOM-UNZOOM' | translate}}
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary border btn-small"  title="Legenda tonen" (click)="showLegend()" >
                        <span>{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.BUTTON.LEGAENDA' | translate}}</span>
                    </button>

                    <button [disabled]="selectedEndpoints.length == 0" class="btn btn-primary border btn-small"  title="Selectie leegmaken.." (click)="clearSelection()" >
                        <span>{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.BUTTON.EMPTY-SECTION' | translate}} ({{selectedEndpoints.length}})</span>
                    </button>
                </div>
            </div>




                <div  class="panzoom-parent" >

                    <div class="seat-plan">
                        <div *ngFor="let row of rows; let rowIdx = index" class="spr">
                            <div class="seat-row">{{ determineRowName(row, rowIdx) }}</div>
                            <ng-container *ngFor="let c of row.columns; let colIdx = index">

                                <div *ngIf="c.data !== null"
                                    data-id="{{c.data.Id}}"
                                    [style]="c.artifectStyle"
                                    [class.sp-sel]="c.selected"
                                    [class]="c.Classes">
                                    {{c.SeatName}}
                                </div>
                            </ng-container>
                            <div class="seat-row"> {{ determineRowName(row, rowIdx) }}</div>
                        </div>

                        <div class="seat-row-full-field">{{'LOCATION.VENUE-DEFINITION.EDIT-SEATPLAN-DIALOG.LABEL.VIELD' | translate}}</div>


                    </div>

                </div>



        </div>


    </section>



        <div  style="position: absolute;" class="dialog-action-bar row">
            <div class="col-6">
              <button (click)="storeChanges()" [disabled]="!isDirty || saving" class="btn btn-block btn-primary bd-0"><i
                class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
            </div>
            <div class="col-6">
              <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
                <i class="fas fa-window-close"></i><span>{{'GENERIC.BUTTON.CANCEL' | translate}}</span></button>
            </div>
          </div><!-- form-layout-footer -->










