import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";

import * as _moment from 'moment';
import {EventService, InitiativeService, PassePartoutService, PriceListService} from "../../api";
const moment = _moment;


@Component({
  selector: 'app-initiative-pricelist-form',
  templateUrl: './edit-initiative-pricelist-form.component.html'
})
export class EditInitiativePricelistFormComponent implements OnInit {
  public form: FormGroup;

  public saving = false;

  private isNew: boolean;
  private editObject: any;
  private initiativeId: number;

  public events: any;
  public passepartouts: any;
  public pricelists: any;

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public dialogRef:  MatDialogRef<EditInitiativePricelistFormComponent>,
  private eventService: EventService,
  private passePartoutService: PassePartoutService,
  private priceListService: PriceListService,
  private initiativeService: InitiativeService,
  private datePipe: DatePipe,
  private translate: TranslateService,
  private loader: HttpLoaderService) { }

  ngOnInit() {
    this.isNew = this.passedData.isNew;
    this.editObject = this.passedData.payload;
    this.initiativeId = this.passedData.initiativeId;

    this.form = new FormGroup({
      Id: new FormControl(0),
      EventId: new FormControl(-1),
      PassePartoutId: new FormControl(-1),
      PricelistId: new FormControl(null, Validators.required),
      ActiveTill: new FormControl('', Validators.required),
    });

    this.eventService.getUpcomingEvents().subscribe(data => {
      this.events = (data as any);
      this.events.splice(0, 0, { Name: this.translate.instant('GENERIC.DROPDOWN.NONE'), Id: -1});
    });

    this.passePartoutService.pptSearch('', 1, 99999999,'Name').subscribe(data => {
      this.passepartouts = data.Records;
      this.passepartouts.splice(0, 0, { Name: this.translate.instant('GENERIC.DROPDOWN.NONE'), Id: -1});
    });

    this.priceListService.listAllPriceLists().subscribe( (data: Array<any>) => {
      this.pricelists = data;
    });

    if (!this.isNew) {

      if (!this.editObject.EventId) {
        this.editObject.EventId = -1;
      }
      if (!this.editObject.PassePartoutId) {
        this.editObject.PassePartoutId = -1;
      }
      setTimeout(() => {
        this.form.patchValue(this.editObject);
      }, 0);
    }
  }

  eventIdChange(event) {
    if (event && event.Id > 0) {
      this.form.controls.PassePartoutId.disable();
    } else {
      this.form.controls.PassePartoutId.enable();
    }
  }

  passePartoutIdChange(pp) {
    if (pp && pp.Id > 0) {
      this.form.controls.EventId.disable();
    } else {
      this.form.controls.EventId.enable();
    }
  }

  submit() {
    const propertyObject = this.form.value;

    propertyObject.InitiativeId = this.initiativeId;

    if (propertyObject.EventId && propertyObject.EventId > 0) propertyObject.EventPricelistId = propertyObject.PricelistId;
    else if (propertyObject.PassePartoutId && propertyObject.PassePartoutId > 0) propertyObject.PassePartoutPricelistId = propertyObject.PricelistId;

    if (propertyObject.EventId === -1) propertyObject.EventId = null;
    if (propertyObject.PassePartoutId === -1) propertyObject.PassePartoutId = null;

    this.saving = true;
    this.loader.open();

    // store the object and return the id
    if (this.isNew) {
      this.initiativeService.createPricelistLink(propertyObject).subscribe((id: number) => {
        // close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    } else {
      this.initiativeService.updatePricelistLink(propertyObject).subscribe((id: any) => {
        this.dialogRef.close(true);
        this.loader.close();
      });
    }
  }
}
