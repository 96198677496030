import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { VenueBuildingBlockService } from "../../api";

@Injectable()
export class VBBValidator{
    constructor(private vbbService : VenueBuildingBlockService) {}
    
    public editingObject : any;

    nrIsTaken = (control: AbstractControl) => {      
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            setTimeout(() => {
                this.vbbService.getVBBBySectionNr(control.value).subscribe(data=>{                
                    if (data !== null && data !== void 0){ 
                        if (data.Id != this.editingObject.Id) {
                            return resolve({"nrtaken": data});
                        }
                        
                    } 
                    return resolve(null);
                });    
            }, 200);            
        });
    }   
}