import {Injectable} from '@angular/core';
import {ConstService} from '../services/const.service';
import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {KeyValuePair} from '../models/key-value-pair';


@Injectable()
export class ValidationHelper {

    constructor(private constants: ConstService) { }

    isRequiredField(passedForm:FormGroup, requiredFields:KeyValuePair<boolean>, field: string, targetForm: FormGroup = null): boolean {
        
        if (requiredFields === null) 
            requiredFields = {};
        
        if (requiredFields[field] === null || requiredFields[field] === undefined) {
          const form = targetForm ? targetForm : passedForm;
          const formField = form.get(field);
          if (!formField.validator) {
            return false;
          }
    
          const validator = formField.validator({} as AbstractControl);
          requiredFields[field] = validator && validator.required;
    
          formField.validator(formField);
        }

        return requiredFields[field];
        
      }

    public StringIsEmpty(str) {
        return (str === null || 0 === str.length);
    }

    public isValidBarcode(enteredReference){
        return enteredReference.length == 24;
    }

    public isTicketReferenceNumber(enteredReference) {
        return enteredReference.toLowerCase().startsWith(this.constants.ticketReferencePrefix.toLowerCase()) && this.isNumeric(enteredReference);
    }

    public isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }

    public requiredOnValueCompare(controlToCompare, valueToCompare): ValidatorFn {
        return (control: AbstractControl): KeyValuePair<any> | null => {

            if (controlToCompare.value == valueToCompare) {
                return this.StringIsEmpty(control.value) ? {'requiredOnValueCompare': {value: control.value}} : null;
            }

            return null; //always valid
        };
    }
}

