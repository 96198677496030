<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">

          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'INITIATIVE.TAB.PRICELIST.CHOOSEEVENT' | translate}}</label>
              <ng-select [items]="events" bindLabel="Name" bindValue="Id" placeholder="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.EVENT' | translate}}" formControlName="EventId"
                         [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="eventIdChange($event)">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  {{ item && item.Name ? item.Name + (item.StartDateTime ? ' - ' : '') : '' }}{{ item.StartDateTime | localDateTime | slice:0:10 }}
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'INITIATIVE.TAB.PRICELIST.CHOOSEPPT' | translate}}</label>
              <ng-select [items]="passepartouts" bindLabel="Name" bindValue="Id" placeholder="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.EVENT' | translate}}" formControlName="PassePartoutId"
                         [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="passePartoutIdChange($event)"></ng-select>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{ 'INITIATIVE.TAB.PRICELIST.PRICELIST' | translate}}<span class="tx-danger">*</span></label>
              <ng-select [items]="pricelists" bindLabel="Name" bindValue="Id" placeholder="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.PRICELIST' | translate}}" formControlName="PricelistId"
                         [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
              <t4-display-validation controlName="PricelistId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-12">
            <t4-date-time-control formControlName="ActiveTill" [labelDate]="'GENERIC.EDITDIALOG.ACTIVETILL'" [labelTime]="'GENERIC.EDITDIALOG.ACTIVETILLTIME'"></t4-date-time-control>
            <t4-display-validation controlName="ActiveTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>

        </div><!-- row -->

        <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>
