import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VenueBuildingBlockService } from '../../api';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-seat-grid',
  templateUrl: './create-seat-grid.component.html'
})
export class CreateSeatGridComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any, 
              public dialogRef: MatDialogRef<CreateSeatGridComponent>,
              private venue : VenueBuildingBlockService) { }

  private editingObject = null;
  private isNew = false;
  public saving = false;
  public form: FormGroup;
  
  validationMessages = {
    'NrOfRows': [
      { type: 'required', message: 'FORM.VALIDATION.REQUIRED' },
      { type: 'pattern', message: 'FORM.VALIDATION.NUMERIC' },
      { type: 'max', message: 'FORM.VALIDATION.MAX200' },
    ],
    'NrOfColumns': [
      { type: 'required', message: 'FORM.VALIDATION.REQUIRED' },
      { type: 'pattern', message: 'FORM.VALIDATION.NUMERIC' },
      { type: 'max', message: 'FORM.VALIDATION.MAX1000' },
    ]
  }

  ngOnInit() {

    this.editingObject = this.passedData.payload;
    this.isNew = this.passedData.isNew;
    
    
    this.form = new FormGroup({
      ParentId: new FormControl(''), //Passed to preserve value
      TicketTypeId: new FormControl(''), //Passed to preserve value
      NrOfRows: new FormControl('', [ Validators.min(1), Validators.max(200), Validators.required, Validators.pattern('^[0-9]*$') ]), //Passed to preserve value
      NrOfColumns: new FormControl('', [ Validators.min(1), Validators.max(1000), Validators.required, Validators.pattern('^[0-9]*$') ]), 
      SelectedIdx: new FormControl('')
    });

    this.form.patchValue(this.editingObject);
  }

  willCauseDeletion(){
    let result = false;

    if (!this.isNew) {
      var r = this.form.get('NrOfRows');
      var c = this.form.get('NrOfColumns');
  
      if (!r.errors && !c.errors) {
          result = (c.value < this.editingObject.NrOfColumns || r.value < this.editingObject.NrOfRows);
      }
  
    }
  
    return result;
  }

  submit() {

    var o = this.form.value;
    this.saving = true;
  
    this.venue.setPlacementGrid(o.ParentId, { 
      TicketTypeId: o.TicketTypeId,   
      Rows: o.NrOfRows, 
      Columns: o.NrOfColumns
    })
    .pipe(first())
    .subscribe((data : any) => {
      this.saving = false;
      this.dialogRef.close( o.SelectedIdx); //notify a succesfull commit
    });

  }
}
