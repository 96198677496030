import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import { SeatPlanHelper } from 'app/shared/helpers/seatplan-helper';


@Component({
  selector: 'app-seatplan-legend',
  templateUrl: './seatplan-legend.component.html',
  styleUrls: ['./seatplan-legend.component.scss']
})
export class SeatplanLegendComponent implements OnInit {
  selectedCustomer = null;


  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef:  MatDialogRef<SeatplanLegendComponent>,
              public seatPlanHelper: SeatPlanHelper) { }

  public contingents: any = null;

  ngOnInit() {

    this.contingents = [{
      HexColor: '#c7ae5d',
      Id: 1
    }]
  }

  submit() {
    this.dialogRef.close(this.selectedCustomer);
  }


}
