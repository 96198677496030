<div class="row">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6"><br></div>
  </div>
<t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">
    <ng-container *t4ListItem="let item">
      <td>{{item.EventStartDate | localDateTime }} - {{item.EventName}} </td>
      <td>{{item.SectionShortName}}</td>
      <td>{{item.RowNumber | replaceOnEmpty:'-' }} / {{item.SeatNumber | replaceOnEmpty:'-' }}</td>
      <td>
        <a href="#" (click)="openOrder($event, item.OrderId)">{{item.OrderId}}</a>
      </td>
      <td>
        {{item.OrderCustomerName}}
      </td>
      <td>{{item.Barcode}}</td>
    </ng-container>
  </t4-list-view>