<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>



<app-link-pricelist [readonlyMode]="isInArchiveMode" *ngIf="eventDetails !== null" [eventId]="eventId" #pricelistComponent></app-link-pricelist>    



  <div class="section-wrapper">
    <div class="form-layout">
      <div class="form-layout-footer row">
        <div class="col-6">
          <button *ngIf="!isInArchiveMode" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>  




