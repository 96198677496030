<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ data.title }}</h5>
    </div>
      <div class="col-sm-6">
        <div class="modal-actions">
          <div class="modal-close">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span (click)="dialogRef.close(false);">×</span>
            </button>
          </div>
        </div>
    </div>
</div>
<div class="section-wrapper mt-3">
    <div>
      <p>{{ 'DIALOG.MEMBERSHIP_IMPORT.PROCESS.MSG' | translate : data.payload }}</p>
      <p class="my-2"><b>{{ 'DIALOG.MEMBERSHIP_IMPORT.PROCESS.WARNING' | translate }}</b></p>
    </div>
    </div>
    <div class="form-layout-footer mt-5 row">
      <div class="col-6">
        <button (click)="dialogRef.close(true);" class="btn btn-block btn-primary bd-0"><span>{{ 'DIALOG.MEMBERSHIP_IMPORT.PROCESS.BTN.OK' | translate }}</span>
        </button>
      </div>
      <div class="col-6">
        <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><span>{{ 'DIALOG.MEMBERSHIP_IMPORT.PROCESS.BTN.CANCEL' | translate }}</span>
        </button>
      </div>
</div>
