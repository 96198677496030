/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { environment } from 'environments/environment';

// @ts-ignore
import { CreateOrUpdateTagArgs } from '../model/createOrUpdateTagArgs';
// @ts-ignore
import { CustomerTagImportViewPagedResult } from '../model/customerTagImportViewPagedResult';
// @ts-ignore
import { FileImportResult } from '../model/fileImportResult';
// @ts-ignore
import { PaymentMethodModel } from '../model/paymentMethodModel';
// @ts-ignore
import { Tag } from '../model/tag';
// @ts-ignore
import { TagModel } from '../model/tagModel';
// @ts-ignore
import { TagModelPagedResult } from '../model/tagModelPagedResult';
// @ts-ignore
import { TagViewPagedResult } from '../model/tagViewPagedResult';
// @ts-ignore
import { VerifyTagDetailArgs } from '../model/verifyTagDetailArgs';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TagService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param createOrUpdateTagArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public createTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public createTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public createTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.createTag - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag`;
        return this.httpClient.post<number>(`${this.configuration.basePath}${localVarPath}`,
            createOrUpdateTagArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTag(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public deleteTag(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public deleteTag(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public deleteTag(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTag.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.deleteTag - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/${encodeURIComponent(String(id))}`;
        return this.httpClient.delete<boolean>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tagid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTagFile(tagid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public downloadTagFile(tagid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public downloadTagFile(tagid: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public downloadTagFile(tagid: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (tagid === null || tagid === undefined) {
            throw new Error('Required parameter tagid was null or undefined when calling downloadTagFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.downloadTagFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/v2/tag/${encodeURIComponent(String(tagid))}/download-file`;
        return this.httpClient.get(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerTagImports(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerTagImportViewPagedResult>;
    public getCustomerTagImports(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerTagImportViewPagedResult>>;
    public getCustomerTagImports(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerTagImportViewPagedResult>>;
    public getCustomerTagImports(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.getCustomerTagImports - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/customertagimports`;
        return this.httpClient.get<CustomerTagImportViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerTags(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<TagModel>>;
    public getCustomerTags(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<TagModel>>>;
    public getCustomerTags(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<TagModel>>>;
    public getCustomerTags(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCustomerTags.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.getCustomerTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/getcustomertags/${encodeURIComponent(String(id))}`;
        return this.httpClient.get<Array<TagModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTagPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public getTagPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public getTagPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public getTagPaymentMethods(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.getTagPaymentMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/paymentmethods`;
        return this.httpClient.get<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTagPaymentMethodsByTagId(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public getTagPaymentMethodsByTagId(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public getTagPaymentMethodsByTagId(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public getTagPaymentMethodsByTagId(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTagPaymentMethodsByTagId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.getTagPaymentMethodsByTagId - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/paymentmethods/${encodeURIComponent(String(id))}`;
        return this.httpClient.get<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<TagModel>>;
    public listAllTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<TagModel>>>;
    public listAllTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<TagModel>>>;
    public listAllTags(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.listAllTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/all`;
        return this.httpClient.get<Array<TagModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSelectableTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<TagModel>>;
    public listSelectableTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<TagModel>>>;
    public listSelectableTags(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<TagModel>>>;
    public listSelectableTags(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.listSelectableTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/selectable`;
        return this.httpClient.get<Array<TagModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAllTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TagModelPagedResult>;
    public searchAllTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TagModelPagedResult>>;
    public searchAllTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TagModelPagedResult>>;
    public searchAllTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.searchAllTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/searchall`;
        return this.httpClient.get<TagModelPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TagViewPagedResult>;
    public searchTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TagViewPagedResult>>;
    public searchTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TagViewPagedResult>>;
    public searchTags(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.searchTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/search`;
        return this.httpClient.get<TagViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagInUse(code: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public tagInUse(code: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public tagInUse(code: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public tagInUse(code: string, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling tagInUse.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.tagInUse - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/${encodeURIComponent(String(code))}/inuse`;
        return this.httpClient.get<number>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagsGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<Tag>>;
    public tagsGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<Tag>>>;
    public tagsGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<Tag>>>;
    public tagsGetForQueryEditor(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.tagsGetForQueryEditor - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/for-query`;
        return this.httpClient.get<Array<Tag>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerid 
     * @param tagModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerTags(customerid: number, tagModel?: Array<TagModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public updateCustomerTags(customerid: number, tagModel?: Array<TagModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public updateCustomerTags(customerid: number, tagModel?: Array<TagModel>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public updateCustomerTags(customerid: number, tagModel?: Array<TagModel>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (customerid === null || customerid === undefined) {
            throw new Error('Required parameter customerid was null or undefined when calling updateCustomerTags.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.updateCustomerTags - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/${encodeURIComponent(String(customerid))}/updatetags`;
        return this.httpClient.post<boolean>(`${this.configuration.basePath}${localVarPath}`,
            tagModel,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param createOrUpdateTagArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public updateTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public updateTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public updateTag(createOrUpdateTagArgs?: CreateOrUpdateTagArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.updateTag - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag`;
        return this.httpClient.put<boolean>(`${this.configuration.basePath}${localVarPath}`,
            createOrUpdateTagArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tagId 
     * @param isReplace 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadTagFile(tagId: number, isReplace: boolean, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileImportResult>;
    public uploadTagFile(tagId: number, isReplace: boolean, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileImportResult>>;
    public uploadTagFile(tagId: number, isReplace: boolean, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileImportResult>>;
    public uploadTagFile(tagId: number, isReplace: boolean, files?: Array<Blob>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling uploadTagFile.');
        }
        if (isReplace === null || isReplace === undefined) {
            throw new Error('Required parameter isReplace was null or undefined when calling uploadTagFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.uploadTagFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (files) {
            files.forEach((element) => {
                localVarFormParams = localVarFormParams.append('files', <any>element) as any || localVarFormParams;
            })
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/upload-file/${encodeURIComponent(String(tagId))}/${encodeURIComponent(String(isReplace))}`;
        return this.httpClient.post<FileImportResult>(`${this.configuration.basePath}${localVarPath}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param verifyTagDetailArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyTagDetails(verifyTagDetailArgs?: VerifyTagDetailArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public verifyTagDetails(verifyTagDetailArgs?: VerifyTagDetailArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public verifyTagDetails(verifyTagDetailArgs?: VerifyTagDetailArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public verifyTagDetails(verifyTagDetailArgs?: VerifyTagDetailArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('TagService.verifyTagDetails - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/tag/verifytagdetails`;
        return this.httpClient.post<boolean>(`${this.configuration.basePath}${localVarPath}`,
            verifyTagDetailArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
