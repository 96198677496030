<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>

<span class="mb-3 d-block mt-3">
    {{ 'DIALOG.CREATE_MERGE_BATCH.WARNING.MSG' | translate }}
</span>

<form [formGroup]="form">
  <div class="row">
    <div class="col-lg-6">
        <label class="form-control-label">{{ 'DIALOG.CREATE_MERGE_BATCH.AMMOUNT.HEADER' | translate }}</label>  <!-- Max. aantal: -->
        <ng-select placeholder="{{ 'DIALOG.CREATE_MERGE_BATCH.AMMOUNT.PLACEHOLDER' | translate }}" formControlName="Ammount" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">  <!-- Maximum merge mogelijkheden -->
          <ng-option [value]="50">{{'GENERIC.VALUE.50' | translate}}</ng-option>
          <ng-option [value]="100">{{'GENERIC.VALUE.100' | translate}}</ng-option>
          <ng-option [value]="250">{{'GENERIC.VALUE.250' | translate}}</ng-option>
          <ng-option [value]="500">{{'GENERIC.VALUE.500' | translate}}</ng-option>
          <ng-option [value]="1000">{{'GENERIC.VALUE.1000' | translate}}</ng-option>
        </ng-select>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-6">
        <label class="ckbox">
          <input type="checkbox" name="Active" formControlName="Hardcards"><span>   {{ 'DIALOG.CREATE_MERGE_BATCH.HARDSCARDS.HEADER' | translate }}</span> <!--   Hardcards Opnieuw -->
        </label>
    </div>
  </div>
  
</form>

<div class="row mt-3">
    <div class="col-6">
        <button class="btn btn-primary btn-block" (click)="confirm()" [disabled]="false" title="Create">
        <i class="fas fa-save"></i>
        <span>{{ 'BTN.CREATE' | translate }}</span>
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-secondary btn-block" [disabled]="false" (click)="dialogRef.close(null);" title="{{'GENERIC.BUTTON.CANCEL-CHANGES-AND-CLOSE-WINDOW' | translate}}">
        <i class="fas fa-ban"></i>
        <span>{{ 'BTN.CANCEL' | translate }}</span>
      </button>
    </div>
</div>