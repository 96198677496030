import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';

@Component({
  selector: 'app-seat-plan-row-offset',
  templateUrl: './seat-plan-row-offset.component.html',
  styleUrls: ['./seat-plan-row-offset.component.scss']
})
export class SeatPlanRowOffsetComponent implements OnInit {

  public directions = [
    { Name: this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.DIRECTION.ASCENDING'), Id: 1 },
    { Name: this.translate.instant('SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.DIRECTION.DESCENDING'), Id: 2 }
  ]

  public form: FormGroup;
  public saving = false;

  private editingObject: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<SeatPlanRowOffsetComponent>,
    private fb: FormBuilder,
    private logging : LoggingService,
    private translate : TranslateService
  ) { }


  ngOnInit() {

    this.form = new FormGroup({
      StartNumber: new FormControl('', Validators.required),
      Direction: new FormControl(1),
    });

  }


  submit() {
      //notify caller about the chosen seatnumber
      var o = this.form.value;
      this.dialogRef.close({ StartNumber: o.StartNumber, LTR: (o.Direction === 1) });
  }
}
