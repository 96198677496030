<!--Contigenten altijd zichtbaar maken-->
<input class="placement-input" (keyup.shift.f)='seeContigent()' />

<div fxLayout="row" fxLayoutWrap class="page-holder">
    <div fxFlex="350px" class="placement-sidebar">
        <div class="plan-holder-intab" >

            <ng-container *ngIf="popoverDetails === null">
                <div  class="seat-details-summary seat-details-undetermined text-center">
                    &nbsp;
                </div>
                <div class="legend">{{'SALES.ORDERS.NEW.SEATPLAN.NO-SEAT-DETAILS' | translate}}</div>
            </ng-container>

            <ng-container *ngIf="popoverDetails !== null">
                <div class="seat-details-summary seat-details-sellable text-center" *ngIf="reasonUnsellable == null">
                    {{'SALES.ORDERS.NEW.SEATPLAN.SEAT-AVAILABLE' | translate}}
                </div>

                <div class="seat-details-summary seat-details-unsellable text-center" *ngIf="reasonUnsellable != null">
                    <!-- this seat cannot be sold with a reason -->
                    {{reasonUnsellable | translate}}
                </div>
            <div class="detail-popover tx-12" >

                <div *ngIf="popoverDetails != null && !popoverDetailsLoading">

                    <div class="pt-2">
                        <div class="placement-sidebar-title">  {{'SALES.ORDERS.NEW.SEATPLAN.ROW-SEAT' | translate : {rowNumber: getRowNumber(), seatNumber: getSeatNumber()} }}</div>
                    </div>


                    <div class="p-2">
                      <div *ngIf="popoverDetails.details.PassePartoutPrices != null">
                        <div *ngFor="let d of popoverDetails.details.PassePartoutPrices; let i = index">
                          <table style="width: 100%">
                            <tr *ngIf="i == 0">
                              <td colspan="3">
                                <div class="event-separator">&nbsp;</div>
                              </td>
                            </tr>


                            <tr>
                              <td>
                                <a class="text-dark"><b>{{d.PassePartoutName}}</b></a>
                                <table>
                                  <tr>
                                    <td colspan="2" style="width: 100%;">
                                      <div class="separator"></div>
                                    </td>
                                  </tr>
                                  <ng-container *ngIf="d.PriceVariants !== null && d.PriceVariants.length > 0; else noprices">
                                    <tr *ngFor="let pv of d.PriceVariants">
                                      <td>{{pv.VariationName}}</td>
                                      <td colspan="2"
                                          style="text-align: right; font-weight: bold;">{{pv.PriceTotal | currency:'EUR'}}</td>
                                    </tr>
                                  </ng-container>
                                  <ng-template #noprices>

                                  </ng-template>
                                  <tr>
                                    <td colspan="3" style="color: red; font-weight: bold;">{{ d.SeatRestrictionName }}</td>
                                  </tr>
                                </table>

                              </td>


                              </tr>
                              <tr>
                                <td class="pt-4" colspan="3">
                                  <div class="event-separator">&nbsp;</div>
                                </td>
                              </tr>
                          </table>
                        </div>
                      </div>
                        <div *ngFor="let d of popoverDetails.details.PositionEventDetails; let i = index">

                            <table style="width: 100%">

                                <tr *ngIf="i == 0">
                                    <td colspan="3">
                                        <div class="event-separator">&nbsp;</div>
                                    </td>
                                </tr>


                                <tr>
                                    <td class="pr-2 pt-1" style="vertical-align: top;">
                                        <span [style.background]="d.ReservationHexColor ? d.ReservationHexColor : ''" [class]="getStatusClass(d)">&nbsp;</span>
                                    </td>
                                    <td class="p-0" style="width: 60px; vertical-align: top;">
                                        <img *ngIf="d.ImageUrlHomeTeam !== null" class="mr-1" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(d.ImageUrlHomeTeam, 32, 32)">
                                        <img *ngIf="d.ImageUrlAwayTeam !== null" style="width: 20px;" [src]="fileHelper.getResizedImageUrl(d.ImageUrlAwayTeam, 32, 32)">
                                    </td>
                                    <td>
                                        <a class="text-dark"><b>{{d.EventName}}</b></a>
                                        <span class="d-block" *ngIf="popoverDetails.details.PassePartoutPrices === null">{{d.EventStartDateTime | localDateLong}} om {{d.EventStartDateTime | localTime}}</span>


                                        <ng-container *ngIf="d.Sold || d.Reserved; else prices">

                                            <table style="width: 100%;">
                                                <tr><td colspan="2" style="width: 100%;"><br>  </td></tr>
                                                <tr>
                                                    <td>{{'SALES.ORDERS.NEW.SEATPLAN.RESERVED.NAME.LABEL' | translate}}</td>
                                                    <td style="font-weight: bold;">{{d.CustomerName | replaceOnEmpty: this.translate.instant('GENERIC.EMPTY-TEXT.ANONYMOUS')}} ({{d.CustomerNumber}})</td>
                                                </tr>
                                                <tr *ngIf="d.Reserved">
                                                    <td>{{'SALES.ORDERS.NEW.SEATPLAN.RESERVED.RESERVATION.LABEL' | translate}}</td>
                                                    <td>{{d.ReservationTypeName | excerpt:28}}</td>
                                                </tr>
                                                <tr *ngIf="d.PassePartoutId === null">
                                                    <td>{{'SALES.ORDERS.NEW.SEATPLAN.RESERVED.TYPE.LABEL' | translate}}</td>
                                                    <td style="font-weight: bold;">{{'SALES.ORDERS.NEW.SEATPLAN.RESERVED.TYPE.TICKET' | translate}}</td>
                                                </tr>
                                                <tr *ngIf="d.PassePartoutId !== null">
                                                    <td>{{'SALES.ORDERS.NEW.SEATPLAN.RESERVED.PASSE-PARTOUT.LABEL' | translate}}</td>
                                                    <td style="font-weight: bold;">{{d.PassePartoutName}}</td>
                                                </tr>
                                            </table>


                                          </ng-container>

                                          <ng-template #prices>
                                              <table>
                                                <tr>
                                                    <td colspan="2" style="width: 100%;">
                                                        <div class="separator"></div>
                                                    </td>
                                                </tr>
                                                  <ng-container *ngIf="d.PriceVariants !== null && d.PriceVariants.length > 0; else noprices">
                                                    <tr *ngFor="let pv of d.PriceVariants">
                                                        <td>{{pv.VariationName}}</td>
                                                          <td colspan="2" style="text-align: right; font-weight: bold;">{{pv.PriceTotal | currency:'EUR'}}</td>
                                                      </tr>
                                                  </ng-container>
                                                  <ng-template #noprices>

                                                  </ng-template>

                                                  <tr *ngIf="d.ContingentName !== null && d.HasAccessToContingent === true">
                                                      <td style="font-weight: bold;">{{ d.ContingentName }} </td>
                                                  </tr>
                                                  <tr *ngIf="d.ContingentName !== null && showContingents === true">
                                                    <td style="font-weight: bold;">{{ d.ContingentName }} </td>
                                                </tr>

                                                  <tr>
                                                    <td colspan="3" style="color: red; font-weight: bold;">{{ d.SeatRestrictionName }}</td>
                                                  </tr>
                                              </table>

                                          </ng-template>
                                    </td>

                                </tr>
                                <tr>
                                    <td class="pt-4" colspan="3">
                                        <div class="event-separator">&nbsp;</div>
                                    </td>
                                </tr>
                            </table>






                        </div>
                        <!-- <div fxLayout="row" fxLayoutWrap="wrap">
                            <div fxFlex>
                                &nbsp;
                            </div>
                            <div class="text-right seat-details-total" >
                                {{ getTotalOrderPrice(popoverDetails.details.PositionEventDetails) | currency:'EUR' }}
                            </div>

                        </div> -->



                    </div>

                </div>

              </div>
            </ng-container>

            <div  style="padding-bottom: 30px;" *ngIf="popoverDetailsLoading">
                <div class="d-flex align-items-center justify-content-center" style="height: 160px">
                    <div class="sk-circle">
                      <div class="sk-circle1 sk-child"></div>
                      <div class="sk-circle2 sk-child"></div>
                      <div class="sk-circle3 sk-child"></div>
                      <div class="sk-circle4 sk-child"></div>
                      <div class="sk-circle5 sk-child"></div>
                      <div class="sk-circle6 sk-child"></div>
                      <div class="sk-circle7 sk-child"></div>
                      <div class="sk-circle8 sk-child"></div>
                      <div class="sk-circle9 sk-child"></div>
                      <div class="sk-circle10 sk-child"></div>
                      <div class="sk-circle11 sk-child"></div>
                      <div class="sk-circle12 sk-child"></div>
                    </div>
                  </div>
            </div>

        </div>
    </div>
    <div fxFlex="calc(100% - 350px)" >

            <section id="focal" [style]="getCalculatedStyle()">

                    <div class="plan-holder-intab">

                            <div class="app-key-area" fxLayout="row" fxLayoutAlign="space-between">
                                <div>
                                  <div fxLayout="row">
                                    <div class="ddholder" fxFlex.gt-lg="300px" fxFlex="200px" fxLayout="row"  *ngIf="priceCategories != null">

                                      <div class="full-width full-width_main">
                                        <ng-select class="full-width" [items]="priceCategories" placeholder="{{'SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.PRICE-CATEGORY.PLACEHOLDER' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedPriceCategoryId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                                      </div>

                                      <!--    <i class="fa fa-save" (click)="setProperty('PriceCategoryId', selectedPriceCategoryId)"></i>-->
                                      <i class="fa fa-search" (click)="selectByProperty('PriceCategoryIds', selectedPriceCategoryId, true, priceCategories);"></i>
                                      <i class="fa fa-hand-pointer" (click)="selectByProperty('PriceCategoryIds', selectedPriceCategoryId, false, priceCategories);"></i>
                                    </div>
                                    <div class="ddholder" fxFlex.gt-lg="300px" fxFlex="200px" fxLayout="row" *ngIf="seatRestrictionTypes != null">
                                      <div  class="full-width full-width_main">
                                        <ng-select class="full-width" [items]="seatRestrictionTypes" placeholder="{{'SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.SEAT-RESTRICTION.PLACEHOLDER' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedSeatRestrictionTypeId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                                      </div>
                                      <i class="fa fa-search" (click)="selectByProperty('SeatRestrictionId', selectedSeatRestrictionTypeId, true, seatRestrictionTypes);"></i>
                                      <i class="fa fa-hand-pointer" (click)="selectByProperty('SeatRestrictionId', selectedSeatRestrictionTypeId, false, seatRestrictionTypes)"></i>
                                    </div>

                                    <div class="ddholder" fxFlex.gt-lg="300px" fxFlex="200px" fxLayout="row" *ngIf="availableContigents != null">

                                      <div  class="full-width full-width_main">
                                        <ng-select class="full-width" [items]="availableContigents" placeholder="{{'SALES.ORDERS.NEW.SEATPLAN.DROPDOWN.CONTINGENT.PLACEHOLDER' | translate}}" bindLabel="Name" bindValue="Id" (change)="selectedContingentId = $event.Id" [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
                                      </div>
                                      <i class="fa fa-search" (click)="selectByProperty('ContingentId', selectedContingentId, true, availableContigents);"></i>
                                      <i class="fa fa-hand-pointer" (click)="selectByProperty('ContingentId', selectedContingentId, false, availableContigents)"></i>

                                    </div>
                                  </div>
                                  <div class="information pt-5">
                                    <div class="app-key-holder">
                                        <div class="app-key-lmouse">&nbsp;</div> + <div class="app-key">{{'SALES.ORDERS.NEW.SEATPLAN.ACTION.DRAG' | translate}}</div> {{'SALES.ORDERS.NEW.SEATPLAN.ACTION.SELECT' | translate}}
                                    </div>
                                    <div class="app-key-holder">
                                        <div class="app-key">Ctrl</div> + <div class="app-key-lmouse">&nbsp;</div> + <div class="app-key">{{'SALES.ORDERS.NEW.SEATPLAN.ACTION.DRAG' | translate}}</div> {{'SALES.ORDERS.NEW.SEATPLAN.ACTION.ADDSELECT' | translate}}
                                    </div>
                                    <div class="app-key-holder">
                                        <div class="app-key-rmouse">&nbsp;</div> + <div class="app-key">{{'SALES.ORDERS.NEW.SEATPLAN.ACTION.DRAG' | translate}}</div> {{'SALES.ORDERS.NEW.SEATPLAN.ACTION.MOVE' | translate}}
                                    </div>
                                    <div class="app-key-holder">
                                        <div class="app-key">{{'SALES.ORDERS.NEW.SEATPLAN.ACTION.SCROLL' | translate}}</div> {{'SALES.ORDERS.NEW.SEATPLAN.ACTION.RESIZE' | translate}}
                                    </div>
                                  </div>
                                </div>
                                <div style="text-align: end">
                                    <button class="btn btn-primary border btn-small"  title="{{'COMPONENTS.SEAT-PLAN.BUTTON.TITLE.SHOW-LEGEND' | translate}}" (click)="showLegend()" >
                                        <span>{{'GENERIC.BTN.LEGEND' | translate}}</span>
                                    </button>
                                  <button [disabled]=" searchedEndpoints.length == 0" class="btn btn-primary border btn-small"  title="{{'COMPONENTS.SEAT-PLAN.BUTTON.TITLE.EMPTY-SEARCH' | translate}}" (click)="clearSelection(true)" >
                                    <span><i class="fa fa-search"></i> {{'GENERIC.BTN.CLEAR-SEARCH-ITEMS' | translate : {count: searchedEndpoints.length} }}</span>
                                  </button>
                                    <button [disabled]="selectedEndpoints.length == 0" class="btn btn-primary border btn-small"  title="{{'COMPONENTS.SEAT-PLAN.BUTTON.TITLE.CLEAR SELECTION' | translate}}" (click)="clearSelection(false)" >
                                        <span><i class="fa fa-hand-pointer"></i>{{'GENERIC.BTN.CLEAR-SELECTED-ITEMS' | translate : {count: selectedEndpoints.length} }}</span>
                                    </button>
                                </div>
                            </div>


                            <div  class="panzoom-parent" >

                                <div class="seat-plan">


                                    <div *ngFor="let row of seatPlan.Rows; let rowIdx = index" class="spr">
                                        <div class="seat-row">{{ row.rowName }}</div>

                                        <ng-container *ngFor="let c of row.Columns; let colIdx = index">
                                            <div [@enabledStateChange]="lightUpChek(c.searched)" *ngIf="c !== null" data-id="{{c.Id}}"
                                            [class]="c.Classes"
                                            [style]="c.artifectStyle"
                                            (mouseover)="fetchPopoverDetails(c)"
                                            [class.sp-sel]="c.selected"
                                            [class.sp-search]="c.searched"
                                            (mouseleave)="clearPopoverDetails()">
                                                {{c.SeatName}}
                                            </div>
                                        </ng-container>
                                        <div class="seat-row">{{ determineRowName(row, rowIdx) }}</div>
                                    </div>

                                    <div class="seat-row-full-field">{{'SALES.ORDERS.NEW.SEATPLAN.FIELD' | translate}}</div>

                                </div>

                            </div>


                    </div>

                </section>

    </div>

</div>
