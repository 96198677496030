/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { environment } from 'environments/environment';

// @ts-ignore
import { CustomerMembershipRenewImportLineModelPagedResult } from '../model/customerMembershipRenewImportLineModelPagedResult';
// @ts-ignore
import { CustomerMembershipRenewImportModelPagedResult } from '../model/customerMembershipRenewImportModelPagedResult';
// @ts-ignore
import { FileImportResult } from '../model/fileImportResult';
// @ts-ignore
import { GenerateReportArgs } from '../model/generateReportArgs';
// @ts-ignore
import { LongRunningOperation } from '../model/longRunningOperation';
// @ts-ignore
import { LongRunningOperationModel } from '../model/longRunningOperationModel';
// @ts-ignore
import { MembershipImportLinePagedResult } from '../model/membershipImportLinePagedResult';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MembershipImportService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clearMembershipImportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public clearMembershipImportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public clearMembershipImportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public clearMembershipImportList(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling clearMembershipImportList.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.clearMembershipImportList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/clear-memberships`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMembershipExportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperation>;
    public createMembershipExportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperation>>;
    public createMembershipExportList(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperation>>;
    public createMembershipExportList(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling createMembershipExportList.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.createMembershipExportList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/export-list`;
        return this.httpClient.get<LongRunningOperation>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMembershipImportTemplate(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public createMembershipImportTemplate(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public createMembershipImportTemplate(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public createMembershipImportTemplate(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.createMembershipImportTemplate - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/v2/MembershipImport/create-template`;
        return this.httpClient.get(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param importId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFailedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteFailedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteFailedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteFailedImportLines(importId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (importId === null || importId === undefined) {
            throw new Error('Required parameter importId was null or undefined when calling deleteFailedImportLines.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.deleteFailedImportLines - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(importId))}/delete-failed-lines`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param importId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRenewalFile(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteRenewalFile(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteRenewalFile(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteRenewalFile(importId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (importId === null || importId === undefined) {
            throw new Error('Required parameter importId was null or undefined when calling deleteRenewalFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.deleteRenewalFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/renewal-file/${encodeURIComponent(String(importId))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fileId 
     * @param lineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRenewalFileLine(fileId: number, lineId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteRenewalFileLine(fileId: number, lineId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteRenewalFileLine(fileId: number, lineId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteRenewalFileLine(fileId: number, lineId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling deleteRenewalFileLine.');
        }
        if (lineId === null || lineId === undefined) {
            throw new Error('Required parameter lineId was null or undefined when calling deleteRenewalFileLine.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.deleteRenewalFileLine - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(fileId))}/${encodeURIComponent(String(lineId))}/delete-renewal-file-line`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param importId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUnprocessedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteUnprocessedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteUnprocessedImportLines(importId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteUnprocessedImportLines(importId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (importId === null || importId === undefined) {
            throw new Error('Required parameter importId was null or undefined when calling deleteUnprocessedImportLines.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.deleteUnprocessedImportLines - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(importId))}/delete-unprocessed-lines`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param generateReportArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportRenewalList(generateReportArgs?: GenerateReportArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public exportRenewalList(generateReportArgs?: GenerateReportArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public exportRenewalList(generateReportArgs?: GenerateReportArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public exportRenewalList(generateReportArgs?: GenerateReportArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.exportRenewalList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/export-renewal-list`;
        return this.httpClient.post<string>(`${this.configuration.basePath}${localVarPath}`,
            generateReportArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param fileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportRenewalListFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperationModel>;
    public exportRenewalListFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperationModel>>;
    public exportRenewalListFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperationModel>>;
    public exportRenewalListFile(membershipId: number, fileId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling exportRenewalListFile.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling exportRenewalListFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.exportRenewalListFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/export-renewal-file/${encodeURIComponent(String(membershipId))}/${encodeURIComponent(String(fileId))}`;
        return this.httpClient.get<LongRunningOperationModel>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImportLinesByMembership(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<MembershipImportLinePagedResult>;
    public getImportLinesByMembership(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<MembershipImportLinePagedResult>>;
    public getImportLinesByMembership(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<MembershipImportLinePagedResult>>;
    public getImportLinesByMembership(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.getImportLinesByMembership - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/get-by-membership`;
        return this.httpClient.get<MembershipImportLinePagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembershipImportReadyCount(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public getMembershipImportReadyCount(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public getMembershipImportReadyCount(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public getMembershipImportReadyCount(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembershipImportReadyCount.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.getMembershipImportReadyCount - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/ready-count`;
        return this.httpClient.get<number>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param fileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processImportedRenewalFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperationModel>;
    public processImportedRenewalFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperationModel>>;
    public processImportedRenewalFile(membershipId: number, fileId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperationModel>>;
    public processImportedRenewalFile(membershipId: number, fileId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling processImportedRenewalFile.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling processImportedRenewalFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.processImportedRenewalFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/${encodeURIComponent(String(fileId))}/process-imported-renewal-file`;
        return this.httpClient.post<LongRunningOperationModel>(`${this.configuration.basePath}${localVarPath}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processMembershipImport(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperation>;
    public processMembershipImport(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperation>>;
    public processMembershipImport(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperation>>;
    public processMembershipImport(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling processMembershipImport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.processMembershipImport - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/process-memberships`;
        return this.httpClient.get<LongRunningOperation>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processRenewalImportFile(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperationModel>;
    public processRenewalImportFile(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperationModel>>;
    public processRenewalImportFile(membershipId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperationModel>>;
    public processRenewalImportFile(membershipId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling processRenewalImportFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.processRenewalImportFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/process-renewal-import-file`;
        return this.httpClient.post<LongRunningOperationModel>(`${this.configuration.basePath}${localVarPath}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchRenewalFileLines(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMembershipRenewImportLineModelPagedResult>;
    public searchRenewalFileLines(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMembershipRenewImportLineModelPagedResult>>;
    public searchRenewalFileLines(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMembershipRenewImportLineModelPagedResult>>;
    public searchRenewalFileLines(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.searchRenewalFileLines - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/search-renewal-file-lines`;
        return this.httpClient.get<CustomerMembershipRenewImportLineModelPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchRenewalFiles(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CustomerMembershipRenewImportModelPagedResult>;
    public searchRenewalFiles(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CustomerMembershipRenewImportModelPagedResult>>;
    public searchRenewalFiles(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CustomerMembershipRenewImportModelPagedResult>>;
    public searchRenewalFiles(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.searchRenewalFiles - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/search-renewal-files`;
        return this.httpClient.get<CustomerMembershipRenewImportModelPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadMembershipImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileImportResult>;
    public uploadMembershipImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileImportResult>>;
    public uploadMembershipImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileImportResult>>;
    public uploadMembershipImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling uploadMembershipImportFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.uploadMembershipImportFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (files) {
            files.forEach((element) => {
                localVarFormParams = localVarFormParams.append('files', <any>element) as any || localVarFormParams;
            })
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/upload-import-file`;
        return this.httpClient.post<FileImportResult>(`${this.configuration.basePath}${localVarPath}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param membershipId 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadRenewalImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileImportResult>;
    public uploadRenewalImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileImportResult>>;
    public uploadRenewalImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileImportResult>>;
    public uploadRenewalImportFile(membershipId: number, files?: Array<Blob>, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling uploadRenewalImportFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('MembershipImportService.uploadRenewalImportFile - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (files) {
            files.forEach((element) => {
                localVarFormParams = localVarFormParams.append('files', <any>element) as any || localVarFormParams;
            })
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/MembershipImport/${encodeURIComponent(String(membershipId))}/upload-renewal-import-file`;
        return this.httpClient.post<FileImportResult>(`${this.configuration.basePath}${localVarPath}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
