


<div class="row my-5">

    <div class="col-md-6">
      <div class="important-info info-highlight">
        <div class="row">
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.NUMBER' | translate }}</p>
            <p class="info-data">{{reservationDetails.Id}}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-hashtag"></i>
          </div>
  

          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.PLACED' | translate }}</p>
            <p class="info-data">{{reservationDetails.ReservationDate | localDateTime }}</p>
          </div>
          <div class="col-1">
            <i class="far fa-calendar-alt"></i>
          </div>

         
        </div>
        <div class="row">
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.NAMED' | translate }}</p>
            <p class="info-data"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(reservationDetails)"></t4-customer-identification-control></p>
          </div>
          <div class="col-1">
            <i class="fas fa-user"></i>
          </div>
          
  
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.STATUS' | translate }}</p>
            <p class="info-data">
              
              <span *ngIf="reservationDetails.ConvertedToOrderId !== null" class="chipbox chipbox-closed">{{ 'RESERVATIONS.HEADER.CHIPBOX.ORDER' | translate }}</span>
              <span *ngIf="reservationDetails.ConvertedToOrderId === null && !reservationDetails.IsExpired" class="chipbox chipbox-active">{{ 'RESERVATIONS.HEADER.CHIPBOX.ACTIVE' | translate }}</span>
              <span *ngIf="reservationDetails.ConvertedToOrderId === null && reservationDetails.IsExpired && reservationDetails.CanceledByCustomerId !== null" class="chipbox chipbox-open">{{ 'RESERVATIONS.HEADER.CHIPBOX.CANCEL-BY-CUSTOMER' | translate }}</span>
              <span *ngIf="reservationDetails.ConvertedToOrderId === null && reservationDetails.IsExpired && reservationDetails.CanceledByCustomerId === null" class="chipbox chipbox-open">{{ 'RESERVATIONS.HEADER.CHIPBOX.EXPIRED' | translate }}</span>
              
            </p>
          </div>
          <div class="col-1">
            <i class="fas fa-stream"></i>
          </div>
        </div>

      </div>
      
    </div>
    <div class="col-md-6">
      <div class="important-info">

        <div class="row">
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.EXPIRES' | translate }}</p>
            <p class="info-data">{{ reservationDetails.Expires | localDateTime | replaceOnEmpty:'-' }}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-stopwatch"></i>
          </div>
  
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.BY' | translate }}</p>
            <p class="info-data">{{reservationDetails.UserFullName === null ? 'De klant' : reservationDetails.UserFullName }}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-user-tie"></i>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.TYPE' | translate }}</p>
            <p class="info-data">{{reservationDetails.ReservationTypeName}}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-file-alt"></i>
          </div>
  
          <div class="col-5">
            <p class="info-label">{{ 'RESERVATIONS.HEADER.LABEL.DESCRIPTION' | translate }}</p>
            <p class="info-data">{{reservationDetails.Description | replaceOnEmpty }}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-align-left"></i>
          </div>
        </div>
        

      </div>
      
    </div>

</div>


