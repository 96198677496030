
<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-9">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>

  <div class="col-sm-3">

      <div class="modal-actions">

          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<t4-page-warning warning="{{'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.WARNING.NO-HARDCARD-CHOSEN' | translate}}" *ngIf="customerPassePartout.UseHardcard == false;else hcoverview"></t4-page-warning>

<ng-template #hcoverview>
  <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListItem="let item;">

      <td>{{item.Version | replaceOnEmpty }}</td>
      <td>{{item.Barcode}}</td>
      <td>{{item.Created | localDateTime }}</td>

      <td>{{item.LastStatusChanged | localDateTime | replaceOnEmpty: '-' }}</td>
      <td>{{item.LastStatusChangeBy | replaceOnEmpty:'-'}}</td>

      <td class="grid-select" *ngIf="item.IsLatest === true">
        <ng-select [items]="selectableStatusses" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.CUSTOMER-PASSEPARTOUT-HARDCARDS.PLACEHOLDER.STATUS' | translate}}" [(ngModel)]="item.HardcardStatusId"
                   [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" (change)="onStatusChange($event, item.Id)"></ng-select>
      </td>

      <td *ngIf="item.IsLatest === false">{{item.HardcardStatusName}}</td>
      <td>{{item.HardCardTypeName | replaceOnEmpty}}</td>

      <ng-container *ngIf="item.IsLatest === true; else nostatus">
          <td><t4-list-value-boolean [value]="item.CreateHardCard"> </t4-list-value-boolean></td>
          <td><t4-list-value-boolean [value]="item.ExportToACL"> </t4-list-value-boolean></td>
      </ng-container>

      <ng-template #nostatus>
        <td colspan="2">&nbsp;</td>
      </ng-template>

    </ng-container>

  </t4-list-view>

</ng-template>



<div class="row">

  <div class="col-6">
    <button (click)="submit()" [disabled]="saving === true || (statusDirty === false && createDirty === false)" class="btn btn-block btn-primary bd-0">
      <i class="far fa-save"></i>
      <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
    </button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
      <i class="fas fa-ban"></i>
      <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
    </button>
  </div>
</div>
