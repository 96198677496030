import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { EventService, SalesChannel } from '../api';

@Injectable()
export class EventAssignmentValidator {
    constructor(private eventService: EventService) {}

    public eventId: number | null = null;
    public isNamed: boolean | null = null;

    hasAssignmentSettings = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            if(this.eventId === null) {
                resolve(null);
            } else {
                const v = control.value;
                if (v === true) {
                    this.eventService.checkEventAssignmentSettings(this.eventId).subscribe((hasAssignmentSettings: boolean) => {
                        if (hasAssignmentSettings) {
                            resolve({'policies': hasAssignmentSettings});
                        }
                        resolve(null);
                    });
                } else {
                    resolve(null);
                }
            }
            
        }).finally(() => control.markAsTouched());
    }

    eventIsNamed = (control: AbstractControl) => {
        if(this.isNamed === null) {
            throw('requires isNamed to be set!');
        }

        if(control instanceof FormArray) {
            for(let i = 0; i < control.controls.length; i++) {
                let subControl = control.controls[i];
                if(subControl.value > 0 && this.isNamed) {
                    return {'named': true};
                } 
            }
        } else {
            if (control.value > 0 && this.isNamed) {
                return {'named': true};
            }
        }

        return null;
    }
}