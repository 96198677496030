import { Component, OnInit, Output } from '@angular/core';
import {LoggingService} from '@tymes4-shared';
import { EventEmitter } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { ActivatedRoute } from '@angular/router';
import { ITreeOptions, TreeNode } from 'angular-tree-component';
import { TranslateService } from '@ngx-translate/core';
import { EventPlacementService, VenueBuildingBlockService } from '../../../api';
//import { VenueEditModeEnum } from 'app/shared/models/venue-edit-mode-enum';

@Component({
  selector: 'app-section-selector',
  templateUrl: './section-selector.component.html',
  styleUrls: ['./section-selector.component.scss']
})
export class SectionSelectorComponent implements OnInit {




  constructor(  private venueBuildingBlockService: VenueBuildingBlockService,
                private eventPlacementService: EventPlacementService,
                private logging: LoggingService,
                private route: ActivatedRoute,
                private loader : HttpLoaderService,
                private translate: TranslateService) { }

  @Output() onSelected = new EventEmitter<object>();

  pageSize: number = 7;

  //NEW IMPLEMENTATIONS
  allSections = null;
  eventId: number = null;
  event: any = null;
  parentId: number = null;
  parentObject: any = null;
  tvwNodes = null;

  // parentItem = null;
  // parentStack = [];
  // pathToItem = "";
  // childItems = [];

  lastUsedNegative = null;

  ngOnInit() {
    
  }
  
  initialize(parentId, eventId: number) {

    this.parentId = parentId;
    this.eventId = eventId;

    var observable = null;

    if (this.eventId !== null) {
      observable = this.eventPlacementService.getEventPlacement(this.parentId);
    }
    else {
      observable = this.venueBuildingBlockService.getVenueBuildingBlock(this.parentId);
    }

    observable.subscribe((data:any) => {
      this.parentObject = data;
      this.loadSections();
    });

    
  }

  private loadSections () {
    
    this.loader.open();

    var observable = null;

    if (this.eventId !== null) {
      observable = this.eventPlacementService.getEPChildSectionsRecursive(this.parentId);
    }
    else {
      observable = this.venueBuildingBlockService.getVBBChildSectionsRecursive(this.parentId);
    }

    observable.subscribe ((data:any) => {
      this.allSections = data;
      this.lastUsedNegative = -1;
      this.tvwNodes = this.convertToNodes(this.parentId, this.parentObject.Name);
      this.loader.close();
    });
  }

  convertToNodes(parentId, parentName) {
    
    let resultNodes = [];

    var filtered = this.allSections.filter(function (section)   {
      if (section.ParentId === parentId) return section;
    });

    for (let section of filtered) {
      
      var children = this.convertToNodes(section.Id, section.Name );

      resultNodes.push({
        id: section.Id, 
        parentId: parentId,
        name: section.Name, 
        hasUnpublishedChanges: section.HasUnpublishedChanges,
        children: children
      });



    }

    if (!this.eventId || this.eventId === null) {
      //add the new section
      resultNodes.push({
        id: this.lastUsedNegative, 
        parentId: parentId,
        name: this.translate.instant('COMPONENTS.VENUE-DEFENITION.SECTION-SELECTOR.ADD-NEW-SECTION.NAME', {parentName}), 
        children: null
      });
    }
    

    this.lastUsedNegative --;

    return resultNodes;

  }

  onNodeSelect(e) {


    if (e.node.id < 0) {
      //new item node clicked
      this.onSelected.emit( {
        Id: -1,
        ParentId: e.node.data.parentId,
        TypeId: 2 //add a section
      });

    }
    else {
      //section selected

      //find the clicked section
      var filtered = this.allSections.filter(function (section)   {
        if (section.Id === e.node.id) return section;
      });
  

      this.selectSection(filtered[0]);
    }
  }
  
  selectSection(section) {

    this.editingVBB = section;

    //let the master know a selection has been made
    this.onSelected.emit( {
      Id: section.Id,
      ParentId: section.ParentId,
      TypeId: section.VenueBuildingBlockTypeId
    });

  }

  sectionUpdated (id) {
    this.loadSections();
  }
  
  venuedefinition;
  editingVBB = null;
  lastSelectedVBB = null;
  itemsInCurrentDepth = []; //hier moet ik de items hebben

  

}
