/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PrintLocationModel { 
    HardwareId?: string | null;
    LocationName?: string | null;
    TicketPrinterEnabled?: boolean;
    TicketPrinterName?: string | null;
    TicketPrinterType?: number | null;
    HardcardPrinterEnabled?: boolean;
    HardcardPrinterName?: string | null;
}

