import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ConversionHelper} from 'app/shared/helpers/conversion-helper';
import {DialogHelper, DialogWidth} from '@tymes4-shared';
import {SeatPlanHelper} from 'app/shared/helpers/seatplan-helper';
import * as $ from 'jquery';
import 'jquery-mousewheel/jquery.mousewheel';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery.panzoom/dist/jquery.panzoom';
import {forkJoin} from 'rxjs';
import {ConfirmService} from '@tymes4-shared';
import 'assets/JS/jquery-ui-itx/ui/widgets/selectable';
import {HelperService} from '../../components/common/helper-service';
import {HttpLoaderService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';
import {AutoNumberComponent} from '../auto-number/auto-number.component';
import {SeatPlanRowOffsetComponent} from '../seat-plan-row-offset/seat-plan-row-offset.component';
import {SeatplanLegendComponent} from '../seatplan-legend/seatplan-legend.component';
import { TranslateService } from '@ngx-translate/core';
import { ContingentService, EventPlacementService, PlacementTypeService, VBBPropertyArgs, VenueBuildingBlockService } from '../../api';

@Component({
  selector: 'app--seat-plan',
  templateUrl: './edit-seat-plan.component.html',
  styleUrls: ['./edit-seat-plan.component.scss']
})
export class EditSeatPlanComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private placementTypeService: PlacementTypeService,
              public dialogRef: MatDialogRef<EditSeatPlanComponent>,
              private venue: VenueBuildingBlockService,
              private logging: LoggingService, private loader: HttpLoaderService,
              private eventPlacementService: EventPlacementService,
              private helperService: HelperService,
              private confirmService: ConfirmService, private cd: ChangeDetectorRef,
              public convert: ConversionHelper,
              private dialog: MatDialog,
              private contingentService: ContingentService,
              public seatPlanHelper: SeatPlanHelper,
              private translate: TranslateService
  ) {
  }


  moveOffset = null;
  eventPlacementEventId: number = null;
  seatPlanOrientation: number = 0;
  safeTransform;
  scale = 100;

  saving = false;
  isDirty = false;

  endpointData: any;
  parentVBB: any = {};

  rows = null;

  selectedContingentId: Number = -1 ;
  selectedSeatRestrictionTypeId: Number = -1 ;
  selectedPriceCategoryId: Number = -1;
  selectedPlacementTypeId: Number = -1;

  seatRestrictionTypes: any = [];
  contingents: any = [];
  priceCategories: any = [];
  placementTypes: any = [];

  clear() {
    ($(".seat-plan") as any).selectable("destroy");
    this.initializeSelectable();
  }


  //selectedEpIds =[];
  private inAlternateSelectionMode = false;
  private newSelectedEp: any[] = null;

  onSelectionStart(e, element) {
    this.cd.detach();
    this.newSelectedEp = [];
  }

  onItemSelect(e, element) {
    let id: number = +element.selected.id;

    var found = this.endpointData.filter(function (ep) {
      if (ep.Id === id) return ep;
    });

    if (found && found.length > 0) {
      this.newSelectedEp.push(found[0]);
    }
  }

  onSelectionEnd(e, element) {
    if (e.ctrlKey) this.inAlternateSelectionMode = true;

    if (this.newSelectedEp.length == 1) this.inAlternateSelectionMode = true;

    if (!this.inAlternateSelectionMode) this.clearSelection();

    for (let newEp of this.newSelectedEp) {
      if (this.isItemSelected(newEp)) {
        if (this.inAlternateSelectionMode) {
          //in this case unselect the item
          var idx = this.selectedEndpoints.indexOf(newEp);
          this.selectedEndpoints.splice(idx, 1);
        }
      } else {
        this.selectedEndpoints.push(newEp);
      }
    }

    this.inAlternateSelectionMode = false;
    this.newSelectedEp = [];
    this.clear();
    this.cd.reattach();
    this.cd.detectChanges();
  }

  ngOnInit() {

    this.endpointData = this.passedData.payload.endpoints;
    this.parentVBB = this.passedData.payload.ParentVBB;
    this.eventPlacementEventId = this.passedData.payload.eventPlacementEventId;

    if (this.parentVBB.Scale != null) this.scale = this.parentVBB.Scale;

    this.Initialize(this.endpointData);
    this.updateTransform();

    var call0 = this.venue.listSelectablePriceCategories(this.parentVBB.Id);
    var call1 = this.placementTypeService.getAllPlacementTypes();
    var call2 = this.venue.listSeatRestrictions();
    var call3 = this.contingentService.listSelectableContingents();

    this.loader.open();

    forkJoin([call0, call1, call2, call3]).subscribe(data => {

      //call 0
      this.helperService.setInheritedPriceCategoryNames(data[0]);
      this.priceCategories = data[0];

      //call 3
      this.contingents = data[3];

      //add the empty item to the first index in the array.
      this.contingents.unshift({
        Id: null,
        Name: this.translate.instant('GENERIC.DROPDOWN.NONE')
      });

      //call 1
      this.placementTypes = data[1];
      this.initializeSelectable();

      //call 2
      this.seatRestrictionTypes = data[2];

      //add the empty item to the first index in the array.
      this.seatRestrictionTypes.unshift({
        Id: null,
        Name: this.translate.instant('GENERIC.DROPDOWN.NONE')
      });


      this.loader.close();
    });
  }

  setSeatPlanProperties(onlyUpdateSelectable) {

    let rowIdx = 0;

    for (let row of this.rows) {

      let colIdx = 0;

      row.rowName = this.determineRowName(row);

      for (let c of row.columns) {

        if (c.data !== null) {

          c.selected = this.isItemSelected(c.data);

          if (onlyUpdateSelectable == false) {
            c.Classes = this.determineEntpointCss(row, c);
            c.SeatName = this.convert.namingForSeat(c.data, colIdx, rowIdx);
            c.artifectStyle = this.seatPlanHelper.getArtifactStyle(this.contingents, c.data.PlacementTypeId, c.data.Sold, c.data.ContingentId, c.data.ReservationHexColor, true);
          }
        }

        colIdx++;

      }
      rowIdx++;
    }

  }

  getFieldRowStyle(rows) {
    return 'width: 1000px';
  }

  determineEntpointCss(row, col) {

    var endpoint = col.data;

    if (endpoint == null) return '';

    var result = "sp-h ";

    if (endpoint.PlacementTypeId == 2) result += "s-stair";
    else if (endpoint.PlacementTypeId == 3) result += "endpoint-noseat";
    else if (endpoint.PlacementTypeId == 4) result += "endpoint-entrance";
    else if (endpoint.PlacementTypeId == 5) result += "s-fence";
    else if (endpoint.PlacementTypeId && this.eventPlacementEventId !== null) {

      //this is a seat (eventplacement), check the status of the seat
      if (endpoint.Unavailable) result += 's-not-available-in-edit'; //unavailable seat
        // else if (endpoint.Reserved) result += 's-taken'; //unavailable seat
      //else if (endpoint.Sold === true) result += 's-taken'; //taken seat
      else if (endpoint.SeatRestrictionId !== null) result += 's-rv'; //restricted view
      // else result += 's-free'; //free seat


    } else if (endpoint.PlacementTypeId == 1) {
      if (endpoint.SeatRestrictionId !== null) result += "s-rv";
    }


    if (this.isItemSelected(endpoint)) {
      result += " sp-sel";
    }

    return result;
  }

  ngAfterViewInit() {

    (function () {

      var $section = $('#focal');

      var $panzoom = ($section.find('.panzoom-parent') as any).panzoom({
        which: 3,
        cursor: "pointer",
      });

      $panzoom.parent().on('mousewheel.focal', function (e) {

        
        e.preventDefault();
        var delta = e.delta || e.originalEvent.wheelDelta;
        var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;

        $panzoom.panzoom('zoom', zoomOut, {
          increment: 0.1,
          animate: false,
          focal: e
        });
      });

      var planWidth = $('.seat-plan').width();
      var planHeight = $('.seat-plan').width();
      var parentHeight = $('.plan-holder').height();
      var planTop = $('.plan-holder').offset().top;
      var planLeft = $('.plan-holder').offset().left;
      var parentWidthFull = $('.plan-holder').width();
      var parentWidth = parentWidthFull - 20; //we subtract 80 pixels from the parentwidth as we need some slack in the width

      var actionTop = (parentHeight + planTop + 20) + 'px';
      $(".dialog-action-bar").css('top', actionTop);
      $(".dialog-action-bar").css('width', parentWidthFull + 'px');


      var focal = {
        clientX: planLeft,
        clientY: planTop
      };

      let panX = 0;
      let panY = 0;

      //$panzoom.panzoom("pan", centerX, centerY);
      if (parentWidth < planWidth) {
        //not enough room for the plan to show as a whole, zoom out.
        var scale = parentWidth / planWidth;

        $panzoom.panzoom("zoom", scale, {silent: true, focal: focal});

        //center the element, at this point easy, we just need to compensate for the padding of 20px
        panX = 10;
      } else {
        panX = 0; // the element itself is already centered.
      }

      panY = 30;

      $panzoom.panzoom("pan", panX, panY, {relative: true});

    })();

  }

  seatplanIsInitialized = false;

  initializeSelectable() {

    let updateOnlySelected = true;

    if (this.seatplanIsInitialized == false) {
      updateOnlySelected = false;
      this.seatplanIsInitialized = true;
    }

    //first up, determine all classes once
    this.setSeatPlanProperties(updateOnlySelected);

    ($(".seat-plan") as any).selectable({
      filter: '.sp-h',
      start: (event, ui) => {
        this.onSelectionStart(event, ui)
      },
      selected: (event, ui) => {
        this.onItemSelect(event, ui)
      },
      stop: (event, ui) => {
        this.onSelectionEnd(event, ui)
      }
    });
  }


  showLegend() {


    let title = this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.TITLE.LEGEND');
    let legendDialog: MatDialogRef<any> = this.dialog.open(SeatplanLegendComponent, {
      width: '400px',
      disableClose: true,
      data: {title: title}
    })

    legendDialog.afterClosed().subscribe((data: any) => {
    });
  }


  selectedEndpoints = [];

  selectColumn(rowIdx, colIdx) {

    if (this.preventNextSelection) return;


    if (rowIdx == 0 && colIdx == 0) {
      //selecting complete grid
      if (this.selectedEndpoints.length == this.endpointData.length) {
        //All items are selected, do a complete deselect
        this.selectedEndpoints = [];

      } else {
        //select the items that are not yet selected
        for (let ep of this.endpointData) {
          if (!this.isItemSelected(ep)) {
            this.toggleSelection(ep);
          }
        }
      }
    } else if (rowIdx == 0) {
      let cols = [];

      for (let row of this.rows) {
        cols.push(row.columns[colIdx]);
      }

      this.doSelectAllOnCols(cols);
    } else if (colIdx == 0) {
      let cols = this.rows[rowIdx].columns;
      let allSelected = true;
      this.doSelectAllOnCols(cols);

    }
  }

  doSelectAllOnCols(cols: any) {

    let allSelected = true;

    for (let c of cols) {

      if (c.data != null && this.selectedEndpoints.indexOf(c.data) < 0) {
        allSelected = false;
      }
    }

    for (let c of cols) {
      if (allSelected || !(this.isItemSelected(c.data))) {
        if (c.data != null)
          this.toggleSelection(c.data);
      }
    }
  }

  selectByProperty(prop, value) {

    this.selectedEndpoints = [];

    if (this.rows == null) return;
    if (value == -1) value = null;

    for (let r of this.rows) {
      for (let c of r.columns) {
        if (c.data != null && c.data[prop] == value) {
          this.selectedEndpoints.push(c.data);
        }
      }
    }

    this.setSeatPlanProperties(true);
  }

  setProperty(prop, value) {

    console.log(prop, value);
    this.logging.log(value);
    this.logging.log(prop + ' - ' + value);

    if (this.rows == null) return;
    if (value == -1) value = null;

    this.isDirty = true;

    for (let ep of this.selectedEndpoints) {
      ep[prop] = value;
      ep.dirty = true;
    }

    //clear selection after set
    this.selectedEndpoints = [];

    //and update the seatplan
    this.setSeatPlanProperties(false);

  }

  toggleSelection(endpoint) {

    if (this.preventNextSelection) return;
    if (endpoint == null) return;

    if (this.isItemSelected(endpoint)) {
      //remove from the selection
      var index = this.selectedEndpoints.indexOf(endpoint);
      this.selectedEndpoints.splice(index, 1);
    } else {
      this.selectedEndpoints.push(endpoint);
    }

  }

  isItemSelected(endpoint) {

    if (!endpoint) return null;

    if (this.selectedEndpoints == null && this.selectedEndpoints.length == 0)
      return false;

    var found = this.selectedEndpoints.filter(function (ep) {
      if (ep.Id == endpoint.Id) return ep;
    });

    return (found != null && found.length > 0);
  }

  clearSelection() {
    this.selectedEndpoints = [];
    this.setSeatPlanProperties(true);
  }

  //#endregion

  storeChanges() {
    this.saving = true;
    this.loader.open();

    //construct the payload
    var data : VBBPropertyArgs = {
      OrientationDegrees: this.seatPlanOrientation,
      Scale: this.scale,
      UpdatableChildren: this.endpointData.filter(function (ep) {
        if (ep.dirty) {
          return ep;
        }
      }) //only interested in the updated endpoints
    }

    var id = this.parentVBB.Id;

    var observable = this.eventPlacementEventId == null ? this.venue.updateProperties(id, data) : this.eventPlacementService.putEventPlacementProperties(id, data);

    observable.subscribe((data: any) => {
      if (data && data.Warnings && data.Warnings !== null && data.Warnings.length > 0) {

        let curWarning = 0;
        let seatWarnings = '';
        let nrOfWarnings = data.Warnings.length;

        
        for (let w of data.Warnings) {

          if (curWarning > 3) {

            let remaining = (nrOfWarnings - curWarning);

            
            if (remaining == 1) {
              seatWarnings += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.SEAT-WARNINGS.ROW') + w.RowNumber + this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.SEAT-WARNINGS.SEAT') + w.SeatNumber;
            } else if (remaining > 1) {
              seatWarnings += '<br />- ' + remaining + this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.SEAT-WARNINGS.OTHER');
            }

            break;
          }

          seatWarnings += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.SEAT-WARNINGS.ROW') + w.RowNumber + this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.SEAT-WARNINGS.SEAT') + w.SeatNumber;
          curWarning++;
        }

        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.TITLE.WARNING'),
          message: this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.CONFIRM.WARNING.MESSAGE.CHANGES-SAVED') + seatWarnings,
          okonly: true
        }, 'auto').subscribe(() => {

        });

      }
      this.resetChangeState();
    });
  }

  resetChangeState() {

    this.isDirty = false;

    for (let ep of this.endpointData) {
      ep.dirty = false;
    }


    //and done
    this.loader.close();
    this.saving = false;
  }

  setZoom($event) {
    this.scale = $event.value;
    this.updateTransform();
    this.isDirty = true;
  }

  setOrientation($event) {
    this.seatPlanOrientation = $event.value;
    this.updateTransform();
    this.isDirty = true;
    // var rot = `rotate(${this.seatPlanOrientation}deg)`;

    // //  this.rotationStyle = `-ms-transform: ${rot};
    // //                        -webkit-transform: ${rot};
    // //                        transform: ${rot};`

    // this.rotationStyle = `transform: ${rot};`

    //console.log(this.rotationStyle);
  }

  setOrientationFromSelectors(deg) {
    this.seatPlanOrientation = deg;
    this.updateTransform();
    // this.orientationSlider.value = deg;
    this.isDirty = true;
    //set the selected orientation on the slider

  }


  updateTransform() {
    //  var style = `transform: rotate(${this.seatPlanOrientation}deg);`;
    // let style: string = ``;

    // if (this.rows != null && this.rows.length > 0)
    // {
    //   style += `width: ` + this.calculateDimension(this.rows[0].columns.length);
    //   //style += `height: ` + this.calculateDimension(this.rows.length);
    // }

    // var calcScale = Math.round((this.scale / 100) * 100) / 100;

    // let c = `rotate(${this.seatPlanOrientation}deg) `
    // c += `scale(${calcScale})`;

    // style  += `-webkit-transform: ${caches};
    //             -moz-transform: ${c};
    //             -o-transform: ${c};
    //             transform: ${c};`;

    // this.safeTransform = this.sanitizer.bypassSecurityTrustStyle(style);
    // this.logging.log('transform set to: '+style);
  }

  calculateDimension(count) {
    return (count * 20) + 'px;';
  }

  public Initialize(data: any) {

    var maxRow = Math.max.apply(Math, data.map(r => r.Row));
    var maxCol = Math.max.apply(Math, data.map(r => r.Column));


    //delete the previous grid
    this.rows = [];
    this.clearSelection();

    //Restructure data
    for (var r = 1; r <= maxRow; r++) {
      //add the row
      this.rows.push({
        columns: [],
        idx: r
      });

      for (var c = 0; c <= maxCol; c++) {

        var record = data.filter(function (record) {
          if (record.Row == r && record.Column == c) {
            // delete record.row; delete record.column;
            return record;
          }
        });

        this.rows[r - 1].columns.push(
          {
            data: record != null && record.length > 0 ? record[0] : null,
            idx: c
          });
      }
    }


  }

  close() {
    this.dialogRef.close(null);
  }


  preventNextSelection = false;
  hasMoved = false;

  onDragStart(e) {
    this.preventNextSelection = false;
    this.moveOffset = null;
    this.hasMoved = false;
  }

  onDragMove(e) {

    if (this.moveOffset == null) {
      this.moveOffset = {
        x: e.x,
        y: e.y
      }
    } else {
      if (this.moveOffset.x != e.x || this.moveOffset.y != e.y) {
        this.hasMoved = true;
      }
    }

  }

  onDragEnd(e) {

    if (this.hasMoved)
      this.preventNextSelection = true;
  }

  getToolTip(data) {

    if (data == null) return "";

    let result: string = '';
    // result = `Positie: Rij = ${data.Row}, Stoel = ${data.Column}`;

    if (data.PlacementTypeId) {
      if (data.PlacementTypeId == 1) {
        result += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.GET-TOOL-TIP.SEAT-IN-BRACKETS');
        result += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.GET-TOOL-TIP.ROW') + (data.RowNumber != null ? data.RowNumber : '-');
        result += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.GET-TOOL-TIP.SEAT') + (data.SeatNumber != null ? data.SeatNumber : '-');
      } else if (data.PlacementTypeId == 2) result += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.GET-TOOL-TIP.STAIRS');
      else if (data.PlacementTypeId == 3) result += this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.GET-TOOL-TIP.EMPTY');

    }

    return result;
  }

  determineRowName(row) {

    var result = null;

    if (row == null || row.columns == null || row.columns.length == 0 || row.idx == 0) return ``;

    for (var col of row.columns) {
      if (col.data != null && col.data.RowNumber != null && col.data.PlacementTypeId === 1) { //placementtypeid 1 = seat

        //we found a row name
        if (result == null) result = col.data.RowNumber; //first one we found
        else if (result != col.data.RowNumber) {
          //we found one that doesn't match
          result += "*"; //indicates one or more differences
          return result;//and done
        }
      }
    }

    return result;

  }

  confirmAutoNumber() {

    const options = DialogHelper.GetDialogOptions(DialogWidth.md, {
      title: this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.CONFIRM.TITLE.ENABLE-AUTOMATIC-SEAT-NUMBERING'),
      rows: this.rows
    });
    const dialogRef: MatDialogRef<any> = this.dialog.open(AutoNumberComponent, options);

    dialogRef.afterClosed()
      .subscribe(result => {
          if (result === true) {
            this.isDirty = true;
            //and update the seatplan
            this.setSeatPlanProperties(false);
          }
        }
      );
  }

  confirmRowOffset() {

    if (this.selectedEndpoints.length != 1) return;

    var offSetEndpoint = this.selectedEndpoints[0];

    let title = this.translate.instant('DIALOGS.EDIT-SEAT-PLAN.CONFIRM.TITLE.DIFFERENT-SET-OF-SEATNUMBERS');
    let dialogRef: MatDialogRef<any> = this.dialog.open(SeatPlanRowOffsetComponent,
      {
        panelClass: 'full-width-dialog',
        disableClose: false,
        width: '650px',
        maxWidth: '98vw',
        data: {
          title: title
        }
      });


    dialogRef.afterClosed()
      .subscribe(data => {
          if (data !== null) {
            this.offsetNumbering(offSetEndpoint, data.StartNumber, data.LTR)
          }
        }
      );
  }

  offsetNumbering(offSetEndpoint: any, startNr: number, ltr: boolean): any {

    var row = this.rows[offSetEndpoint.Row - 1];

    let maxRow = 0;
    let maxCol = 0;

    //determing the max for the selected row
    for (let col of row.columns) {
      if (col.data != null && col.data.Row > maxRow) maxRow = col.data.Row;
      if (col.data != null && col.data.Column > maxCol) maxCol = col.data.Column;
    }

    let seatNumber = startNr;

    let cols = row.columns;


    for (let col of row.columns) {
      if (col.data != null) {

        this.isDirty = true;

        if (col.data.Column >= offSetEndpoint.Column) {
          //update this one
          col.data.dirty = true;

          if (col.data.PlacementTypeId == 1) //seat
          {
            col.data.SeatNumber = this.padDigits(seatNumber, 4);

            if (ltr) seatNumber++;
            else seatNumber--;

          }
        }

      }
    }

    //and update the seatplan
    this.setSeatPlanProperties(false);

  }


  padDigits(number, digits) {
    //no longer padding
    return number.toString();

    //return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
  }


}
