<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-6"><h5 class="modal-title">{{ passedData.title }}</h5></div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<div class="row row-sm mb-2">
  <div class="col-md-6 col-lg-12"></div>
  <div class="col-lg-12">
    <div>
      <t4-tabs (selected)="onTabChange($event)">
        <t4-tab tabTitle="{{'SALES-ENTITY-MODIFICATION.DIALOG.TAB-TITLE.SINGLE.TICKETS' | translate}}" id="E" [selected]="this.selectedTab === 'E'"></t4-tab>
        <t4-tab tabTitle="{{'SALES-ENTITY-MODIFICATION.DIALOG.TAB-TITLE.MULTI-EVENT-TICKETS' | translate}}" id="P" [selected]="this.selectedTab === 'P'"></t4-tab>
        <t4-tab tabTitle="{{'SALES-ENTITY-MODIFICATION.DIALOG.TAB-TITLE.MERCHANDISE' | translate}}" id="C" [selected]="this.selectedTab === 'C'" *ngIf="showMerchandiseTab"></t4-tab>
      </t4-tabs>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 20px">
  <div class="col-12">

    <div #scrollArea id="scroll-area" style="overflow-x: hidden; overflow-y: scroll;" >
      <app-sales-section-selection (onSectionOrderChange)="sectionOrderLinesChanges($event)"  [selectedTab]="this.selectedTab" [orderlineSelection]="getOrderLineSelection()">
      </app-sales-section-selection>
    </div>
  </div>
</div>
