import { Directive, HostListener, ElementRef, OnInit, HostBinding, Input } from "@angular/core";
import { AppCurrencyPipe } from "../pipes/currency.pipe";


@Directive({ selector: "[currencyformat]" })
export class CurrencyFormatterDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private AppCurrencyPipe: AppCurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
   
    this.el.value = this.AppCurrencyPipe.transform(this.el.value);
    
  }


  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = this.AppCurrencyPipe.parse(value); 
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.AppCurrencyPipe.transform(value);
  }
}
