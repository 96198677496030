/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SalesChannel { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Name?: string | null;
    Code?: string | null;
    SystemChannel?: boolean;
    HexColor?: string | null;
    HexBackColor?: string | null;
    HexForeColor?: string | null;
    ImageId?: number | null;
    Active?: boolean;
    UserSelectable?: boolean;
    DistributionChannelId?: number;
    SalesGroupId?: number;
    ParentId?: number | null;
    SystemManaged?: boolean;
    ShowMarketplaceForEvent?: boolean;
    RedirectToMarketplace?: boolean;
    AllowAnonymous?: boolean;
    TicketshopHeaderLinkText?: string | null;
}

