import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DeliveryMethodService, DeliveryMethod } from '../../api';

@Injectable()
export class DeliveryMethodsValidator {
    constructor(private deliveryMethodService: DeliveryMethodService) {}

    public editingObject: any;

    deliverMethodCodeTaken = (control: AbstractControl) => {

      return new Promise<ValidationErrors | null>((resolve, reject) => {
        const v = control.value;

        setTimeout(() => {
          this.deliveryMethodService.getDeliveryMethodByCode(v).subscribe((data: DeliveryMethod) => {
            if (data !== null && data !== void 0) {
              if (!this.editingObject || data.Id !== this.editingObject.Id) {
                resolve({'codetaken': data});
              }
            }
            resolve(null);
          });
        }, 200);
      });
    }
}
