
<div >
    <t4-list-view #crossSellProductEventListVw [options]="pptEventEditListOptions" (onDataRequest)="onDataRequest($event)">
  
      <ng-container *t4ListActions>
        <button *ngIf="!isInArchiveMode" (click)="actionClick('add')"  class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
      </ng-container>

  
      <ng-container *t4ListItem="let item">      

        <th scope="row" >
          <button (click)="reOrder('up', item.GroupingId)" [disabled]="item.SortOrder === 1" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-arrow-up-bold-circle-outline"></button>
          <button (click)="reOrder('down', item.GroupingId)" [disabled]="+item.SortOrder === +totalObjectCount" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-arrow-down-bold-circle-outline"></button>
        </th>

        <td >{{item.ProductName}}</td>
        <td >{{item.VariantNames}}</td>
        <td >{{item.OnSaleFrom | localDateTime}}</td>
        <td >{{item.OnSaleTill | localDateTime}}</td>


        <td class="grid-action" >
          <button *ngIf="!isInArchiveMode" (click)="actionClick('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="!isInArchiveMode" (click)="actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>

      </ng-container>
  
    </t4-list-view>
  
  <div>