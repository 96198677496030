import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService, HttpLoaderService, ListViewSelectionMode, NotificationCommunicationService, SearchDataRequest } from '@tymes4-shared';
import { ActivityLogDialogComponent } from '../../components/activity-log/dialog-activity-log/activity-log-dialog.component';
import { GenericErrorHandler } from '../../helpers/generic-error-handler';
import * as cloneDeep from 'lodash/cloneDeep';
import {LongRunningOperationModel, LongRunningOperationService, LongRunningOperationTypeEnum, MembershipImportService} from '../../api';

@Component({
  selector: 'app-membership-renewal-import-file-details',
  templateUrl: './membership-renewal-import-file-details.component.html',
})
export class MembershipRenewalImportFileDetailsComponent implements OnInit {
  @ViewChild('listVw') listVw;

  fileId = null
  membershipId = null
  allActionsDisabled = false; // true while an LRO is running
  canDeleteUnprocessed = false
  isBlockProcess = false

  listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12,
    selectionMode: ListViewSelectionMode.None,
    disableSort: false,
    renderInCard: false,
    noSpacing: true,
    headers: [
      {sortField: 'Id', label: this.translate.instant('LISTVIEW.COLUMNHEADER.ID'), width: 60, isDefaultSort: 'asc'},
      {sortField: 'CustomerName', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.CUSTOMERNAME'), width: 250, hideOn: null},
      {sortField: 'CustomerNumber', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.CUSTOMERNUMBER'), width: 110, isDefaultSort: 'asc'},
      {sortField: 'PayingCustomerName', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.PAYING-CUSTOMER-NAME'), width: 250, hideOn: null},
      {sortField: 'PayingCustomerNumber', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.PAYING-CUSTOMER-NUMBER'), width: 110, hideOn: null},
      {sortField: 'OriginalTransactionKey', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.TRANSACTION-KEY'), width: 110, isDefaultSort: 'asc'},
      {sortField: 'Status', label: this.translate.instant('GENERIC.LISTVIEW.HEADER.STATUS'), width: 110, isDefaultSort: 'asc'},
      {sortField: 'Reason', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.REASON'), width: 110, hideOn: null},
      {label: '', width: 1}
    ]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<ActivityLogDialogComponent>,
    private translate: TranslateService,
    private loader: HttpLoaderService,
    private errorHandler: GenericErrorHandler,
    private membershipImportService: MembershipImportService,
    private confirmService: ConfirmService,
    private longRunningOperationService: LongRunningOperationService,
    private cdr: ChangeDetectorRef,
    private notificationCommunicationService: NotificationCommunicationService
  ) { }

  onDataRequest(e: SearchDataRequest) {
    this.loader.open();

    this.membershipImportService.searchRenewalFileLines(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { fileId: this.fileId }).subscribe((resp) => {
      this.listVw.Data = resp;

      this.canDeleteUnprocessed = resp.Records.length !== 0
      this.setProcessButtonState(resp.Records);
      this.cdr.detectChanges();
      this.loader.close();
    });
  }

  setProcessButtonState(items: any[]) {
    const allLinesSucessed = items.every(item => [1,3,4].indexOf(item.Status) !== -1)
    const hasFailed = !!items.find(item => item.Status === 0)
    this.isBlockProcess = allLinesSucessed || hasFailed
  }

  process() {
    this.allActionsDisabled = true;
    this.membershipImportService.processImportedRenewalFile(this.membershipId, this.fileId).subscribe((lro: LongRunningOperationModel) => {
        this.notificationCommunicationService.updateNotificationsAndLROS();
        this.dialogRef.close(lro);
    }, this.onError);
  }

  exportList() {
    this.allActionsDisabled = true;
    this.membershipImportService.exportRenewalListFile(this.membershipId, this.fileId).subscribe(() => {
      this.notificationCommunicationService.updateNotificationsAndLROS();
      this.dialogRef.close();
    }, this.onError);
  }

  ngOnInit(): void {
    this.fileId = this.passedData.fileId
    this.membershipId = this.passedData.membershipId
    if(this.passedData.DisplayCalculatedPrice) {
      const placeIndex= 5;
      const priceRangeHeader = {sortField: 'CalculatedPriceRange', label: this.translate.instant('ADMINISTRATION.MEMBERSHIP.RENEWAL-FILE-DETAILS.LISTVIEW.HEADER.CALCULATEDPRICERANGE'), width: 300, hideOn: null};
      this.listOptions.headers.splice(placeIndex, 0, priceRangeHeader);
    }
  }

  actionClick(action, item) {
    if(action === 'delete') {
      this.deleteLine(item)
    }
  }

  deleteLine(item) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE'),
      okonly: false
    }).subscribe((result) => {
      if(result) {
        this.membershipImportService.deleteRenewalFileLine(this.fileId, item.Id).subscribe(() => {
          this.listVw.refreshData();
        })
      }
    })
  }

  onError = (error: any) => {
    this.errorHandler.handle(error);
    this.allActionsDisabled = false;
  }

  onProgressFinished() {
    this.listVw.refreshData();
    this.allActionsDisabled = false;
  }

  deleteUnprocessed() {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('MEMBERSHIP.RENEWAL-FILE-DETAILS.DELETE.UNPROCESSED.MESSAGE'),
      okonly: false
    }).subscribe(result => {
      if(result) {
        this.allActionsDisabled = true;
        this.loader.open();
        this.membershipImportService.deleteUnprocessedImportLines(this.fileId).subscribe(data => {
          this.loader.close();
          this.listVw.refreshData();
        }, this.onError);
      }
    })
  }

  deleteFailed() {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('MEMBERSHIP.RENEWAL-FILE-DETAILS.DELETE.FAILED.MESSAGE'),
      okonly: false
    }).subscribe(result => {
      if(result) {
        this.allActionsDisabled = true;
        this.loader.open();
        this.membershipImportService.deleteFailedImportLines(this.fileId).subscribe(data => {
          console.log(this.canDeleteUnprocessed);
          console.log(this.isBlockProcess);
          this.loader.close();
          this.allActionsDisabled = false;
          this.listVw.refreshData();
        }, this.onError);
      }
    })
  }

  protected readonly longRunningOperationTypeEnum = LongRunningOperationTypeEnum;
}
