export enum DiscountRejectionReason {
  CodeNotFound = 1,
  DiscountNotFound = 2,
  DiscountInactive = 3,
  DiscountExpired = 4,
  DiscountNotStarted = 5,
  CodeAlreadyUsed = 6,
  NotApplicable = 7,
  NotStackable = 8,
}
