import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { FileService, HttpLoaderService } from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import {ListViewSelectionMode} from '@tymes4-shared';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../api';
import {PagedResult} from "../../../../../../../dist/tymes4-shared";


@Component({
  selector: 'app-passepartout-event-queue',
  templateUrl: './passepartout-event-queue.component.html',
  styleUrls: ['./passepartout-event-queue.component.scss']
})
export class PassepartoutEventQueueComponent implements OnInit {

  // for listview
  @ViewChild('passepartoutEventQueueListVw') channelListVw;
  @ViewChild('progressBar') progressBar;


  public pptEventQueueListOptions = {
    search: false,
    pageSize: 5,
    disableSort: true,
    renderInCard: false,

    //searchTitle: 'Zoeken naar passepartouts',
    selectionMode: ListViewSelectionMode.None,
    headers: [
      {sortField: null, label: '', width: 70, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.ACTION', width: 200, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.NAME', width: 375, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.CODE', width: 150, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.ACTION.BY', width: 250, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.PROGRESS', width: 150, hideOn: null},
      {sortField: null, label: 'PASSE-PARTOUT.EVENT.QUEUE.LIST.HEADER.STATUS', width: 400, hideOn: null},
      {sortField: null, label: '', width: 200, hideOn: null},
    ]
  };
  // end for listview
  public latestLongRunningOperation = null;
  public selectedSalesPeriodId = -1;
  public selectedEventId = -1;
  public selectedInitiativeId = -1;
  private selectedEventIsNamed = false;
  private selectedList: any[] = [];

  public isSalesPeriod = false;
  public isEvent = false;
  public isInitiative = false;
  public queueListMode = false;

  public pollingObservable = null;
  public pollInterval = 4000;
  public busyPolling = false;
  public pollingStarted = false;

  @ViewChild('pagerComponent') pagerComponent;

  private timerRunning = false;
  private pageNr = 0;
  private  pageSize = 0

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<PassepartoutEventQueueComponent>,
              private eventService: EventService,
              private fileHelper: FileService,
              private loader: HttpLoaderService,
              private translate: TranslateService) { }



  ngOnInit() {

    this.selectedEventId = this.passedData.eventId;

    if(!(this.selectedEventId === null)){
      this.isEvent = true;
    }
  }

  onDataRequest(e) {

    if (this.selectedEventId === undefined) return;

    this.loader.open();

    this.pageNr = e.pageNr;
    this.pageSize = e.pageSize;

    this.eventService.getPassePartoutQueue(this.selectedEventId).subscribe((queueData: any) => {
      this.loadData(queueData);
      this.loader.close();
    });

  }

  loadData(queueData) {

    let isRunning = false;

    for (let q of queueData) {
      q.Action = q.IsDeletion ? this.translate.instant('PASSE-PARTOUT.EVENT.QUEUE.REMOVE.FROM.GAME') : this.translate.instant('PASSE-PARTOUT.EVENT.QUEUE.ADD');
      q.StatusMessage = q.IsQueued == true ? this.translate.instant('PASSE-PARTOUT.EVENT.QUEUE.IN.QUEUE') : q.Message;
      if (q.IsRunning || q.IsQueued) isRunning = true;
    }

    let paged = queueData.slice((this.pageNr - 1) * this.pageSize, (this.pageNr) * this.pageSize);
    var d: PagedResult = {
      Records: paged,
      TotalRecords: queueData.length,
    }
    this.channelListVw.Data = d;

    this.EnablePolling(isRunning);
  }

  EnablePolling(isRunning: boolean) {
    if (isRunning === true && this.busyPolling === false) {
      //start polling
      this.initializePolling();

    }
    else if (isRunning === false && this.busyPolling === true) {
      //stop polling
      this.cancelPolling();
    }

  }

  downloadReport(queueItemId) {

    this.loader.open();

    this.eventService.getQueueReport(queueItemId).subscribe((data: any) => {
      this.loader.close();
      FileSaver.saveAs(data, this.fileHelper.makeFileNameSafe(this.translate.instant('PASSE-PARTOUT.EVENT.QUEUE.NON.AVAILABLE.POSITIONS')) + this.fileHelper.getFileExtension('excel'));
    });
  }


  cancelPolling(): any {

    this.busyPolling = false;

    if (this.pollingObservable !== null) {
      this.pollingObservable.unsubscribe();
    }

  }

  initializePolling(): any {

    this.busyPolling = true;

    this.pollingObservable = interval(this.pollInterval).pipe(switchMap((ev) =>  this.eventService.getPassePartoutQueue(this.selectedEventId)))
        .subscribe(data => {
          this.loadData(data);
        });
  }


  enableQueueListMode() {
    this.queueListMode = true;
  }

  closeQueueListMode() {
    this.EnablePolling(false);
    this.dialogRef.close(true);
  }

  isReadOnly() {
    return !this.queueListMode;
  }
}
