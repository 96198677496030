import { FormGroup, AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";

import * as moment from 'moment';

@Injectable()
export class PurchaseRightValidator{
  constructor() {}

  public purchaseRightForNamedEntity: boolean = false;

  ValidateFormat = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve, reject) => {
       var isValid = moment(control.value, 'HH:mm', true).isValid();

       if (isValid === true){
           return resolve(null);
       } else{
           return resolve({invalidFormat: true});
       }
    }); 
  }

  ValidateDates = (group: FormGroup) => {

    if (group.controls.AvailableFrom.dirty === true || group.controls.AvailableTill.dirty == true) {
      
    
      var startDate = group.controls.AvailableFrom.value;
      var endDate = group.controls.AvailableTill.value;

      if (startDate > endDate) {
        group.controls.AvailableFrom.setErrors({startDateAfterEndDate: true}, {emitEvent: true})
        group.controls.AvailableTill.setErrors({endDateBeforeStartDate: true}, {emitEvent: true})

        return ({startDateAfterEndDate: true, endDateBeforeStartDate: true});
      } else {
        if (group.controls.AvailableFrom.hasError('startDateAfterEndDate')){
            group.controls.AvailableFrom.setErrors(null, {emitEvent: true});
        }
        if (group.controls.AvailableTill.hasError('endDateBeforeStartDate')){
            group.controls.AvailableTill.setErrors(null, {emitEvent: true});
        }
        return null;
      }
    }
  }

  ValidateAmounts = (group: FormGroup) => {
    const amountAbsoluteFilled = group.controls.AmountAbsolute.value !== 0 && group.controls.AmountAbsolute.value !== null && group.controls.AmountAbsolute.value !== '' && group.controls.AmountAbsolute.value !== undefined;
    const amountPerTicketFilled = !group.controls.AmountPerTicket.disabled && group.controls.AmountPerTicket.value !== 0 && group.controls.AmountPerTicket.value !== null && group.controls.AmountPerTicket.value !== '' && group.controls.AmountPerTicket.value !== undefined;

    if (!group.controls.AmountAbsolute.disabled && ((amountAbsoluteFilled && amountPerTicketFilled) || (!amountAbsoluteFilled && !amountPerTicketFilled))) {
      group.controls.AmountAbsolute.setErrors({amountAbsoluteOneFilled: true}, {emitEvent: true})
      group.controls.AmountPerTicket.setErrors({amountTicketOneFilled: true}, {emitEvent: true})

      return ({amountAbsoluteOneFilled: true, amountTicketOneFilled: true});
    } else {
      if (group.controls.AmountAbsolute.hasError('amountAbsoluteOneFilled')) {
        group.controls.AmountAbsolute.setErrors(null, {emitEvent: true});
      }
      if (group.controls.AmountPerTicket.hasError('amountTicketOneFilled')) {
        group.controls.AmountPerTicket.setErrors(null, {emitEvent: true});
      }

      return null;
    }
  }

  ValidateChecks = (group: FormGroup) => {
    if (group.controls.SalesPeriodId.value !== null && group.controls.SalesPeriodId.value !== "") {
      if (!group.controls.IsPrimary.value && !group.controls.IsCrossSell.value) {
        group.controls.IsPrimary.setErrors({isPrimaryOneFilled: true}, {emitEvent: true})
        group.controls.IsCrossSell.setErrors({isCrossSellOneFilled: true}, {emitEvent: true})

        return ({isPrimaryOneFilled: true, isCrossSellOneFilled: true});
      } else {
        if (group.controls.IsPrimary.hasError('isPrimaryOneFilled')) {
          group.controls.IsPrimary.setErrors(null, {emitEvent: true});
        }
        if (group.controls.IsCrossSell.hasError('isCrossSellOneFilled')) {
          group.controls.IsCrossSell.setErrors(null, {emitEvent: true});
        }
      }
      return null;
    }
  }

  ValidatePassepartoutPrimary = (group: FormGroup) => {
    if (group.controls.SalesPeriodPassePartoutId.value != null && group.controls.TargetPassePartoutId.value !== null && group.controls.IsPrimary.value &&
        group.controls.SalesPeriodPassePartoutId.value != group.controls.TargetPassePartoutId.value) {
      group.controls.TargetPassePartoutId.setErrors({isInvalidPassePartout: true}, {emitEvent: true});
      group.controls.IsPrimary.setErrors({isInvalidIsPrimary: true}, {emitEvent: true});

      return ({isInvalidPassePartout: true, isInvalidIsPrimary: true});
    } else {
      if (group.controls.TargetPassePartoutId.hasError('isInvalidPassePartout')) {
        group.controls.TargetPassePartoutId.setErrors(null, {emitEvent: true});
      }
      if (group.controls.IsPrimary.hasError('isInvalidIsPrimary')) {
        group.controls.IsPrimary.setErrors(null, {emitEvent: true});
      }

      return null;
    }
  }

  ValidatePassepartoutUnequal = (group: FormGroup) => {
    if (group.controls.TargetPassePartoutId.value !== null && group.controls.OriginPassePartoutId.value !== null &&
        group.controls.TargetPassePartoutId.value !== '' && group.controls.OriginPassePartoutId.value !== '' &&
        group.controls.TargetPassePartoutId.value == group.controls.OriginPassePartoutId.value) {
      group.controls.OriginPassePartoutId.setErrors({isInvalidPassePartoutUnequal: true}, {emitEvent: true})

      return ({isInvalidPassePartoutUnequal: true});
    } else {
      if (group.controls.OriginPassePartoutId.hasError('isInvalidPassePartoutUnequal')) {
        group.controls.OriginPassePartoutId.setErrors(null, {emitEvent: true});
      }

      return null;
    }
  }

  ValidateTransferableNamed = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve, reject) => {
      const isValid = this.purchaseRightForNamedEntity || control.value;


      if (isValid === true) {
         return resolve(null);
      } else {
        return resolve({transferableNamed: true});
      }
    });
  }
}
