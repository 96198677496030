<!-- MODAL HEADER-->
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>




  <div class="row my-5">

    <div class="col-md-6">
      <div class="important-info info-highlight">
        <div class="row">

            <div class="col-5">
                <p class="info-label">{{'DIALOGS.EVENT-PLACEMENT-DETAILS.LABEL.ACTIVE-BARCODE' | translate}}</p>
                <p class="info-data">{{this.eventPlacementDetails.ActiveBarcode}}</p>
              </div>
              <div class="col-1">
                <i class="fas fa-barcode"></i>
              </div>


              <div class="col-5">
                <p class="info-label">{{'DIALOGS.EVENT-PLACEMENT-DETAILS.LABEL.POSITION' | translate}}</p>
                <p class="info-data">{{this.eventPlacementDetails.ParentName}} / {{this.eventPlacementDetails.RowNumber | replaceOnEmpty }} / {{this.eventPlacementDetails.SeatNumber | replaceOnEmpty }}</p>
              </div>
              <div class="col-1">
                <i class="fas fa-chair"></i>
              </div>

        </div>

      </div>

    </div>

    <div class="col-md-6">
      <div class="important-info">

        <div class="row">

            <div class="col-5">
                <p class="info-label">{{'DIALOGS.EVENT-PLACEMENT-DETAILS.LABEL.EVENT' | translate}}</p>
                <p class="info-data"> {{this.eventPlacementDetails.EventName}}</p>
              </div>
              <div class="col-1">
                <i class="far fa-calendar-alt"></i>
              </div>

              <div class="col-5">
                <p class="info-label">{{'DIALOGS.EVENT-PLACEMENT-DETAILS.LABEL.TYPE' | translate}}</p>
                <p class="info-data">{{this.eventPlacementDetails.TicketTypeName }}</p>
              </div>
              <div class="col-1">
                <i class="fas fa-stream"></i>
              </div>


        </div>

      </div>

    </div>

</div>



<div class="table-responsive">

    <table class="rounded table text-dark table-striped clickable">


    <thead>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.DATE' | translate}}</th>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.EVENT' | translate}}</th>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.BY' | translate}}</th>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.CUSTOMER-NAME' | translate}}</th>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.BARCODE-AFTER-ACTION' | translate}}</th>
        <th>{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.HEADER.DETAILS' | translate}}</th>
      </thead>

      <tbody>

        <ng-container *ngIf="pagedTrailItems !== null && pagedTrailItems.length == 0; else subscriptionlines">
            <tr>
              <td colspan="10">{{'DIALOGS.EVENT-PLACEMENT-DETAILS.TABLE.BODY.NO-HISTORY-KNOWN' | translate}}</td>
            </tr>
        </ng-container>

        <ng-template #subscriptionlines>


          <tr  *ngFor="let ti of pagedTrailItems">

            <td style="width: 200px">{{ti.DateTime | localDateTime }}</td>
            <td>{{ systemEventHelper.systemEventTypeToText(ti.TypeCode) }}</td>
            <td>{{ ti.By | replaceOnEmpty:'Systeem' }}</td>
            <td>{{ ti.CustomerName | replaceOnEmpty }} </td>
            <td style="width: 240px">{{ ti.Barcode }}</td>
            <td>
                <div *ngFor="let d of getDetails(ti)">
                    {{ d }}
                </div>
            </td>

          </tr>
        </ng-template>

      </tbody>
    </table>

    <t4-pager (onPage)="doPage($event)" #pagerComponent></t4-pager>

</div>

