import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SectorService } from '../../api';

@Component({
  selector: 'app-sales-sector-details',
  templateUrl: './sales-sector-details.component.html',
})
export class SalesSectorDetailsComponent implements OnInit {

  variants = null
  selectionIsEmpty = true
  orderLinesPerMdp = []
  sector = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<SalesSectorDetailsComponent>,
    private sectorService: SectorService) { }


    onOrderAmountChange(e, pvId) {
      if (isNaN(e.target.value)) {
        //no number entered
        e.target.value = '0';
      }

      let amount = Number(e.target.value);

      if (amount < 0) amount = 0;

      const lineItem = this.orderLinesPerMdp.find(i => i.PriceVariantId === pvId)
      lineItem.NumberOfMdps = amount

      this.updateIsEmpty()

    }

  updateIsEmpty () {
    this.selectionIsEmpty = true;

    for(let pv of this.orderLinesPerMdp) {
      if(pv.NumberOfMdps > 0) {
        this.selectionIsEmpty =  false;
      }
    }
  }

  submit() {
    this.dialogRef.close(this.orderLinesPerMdp)
  }

  ngOnInit(): void {
    if(!this.passedData.payload) return
    this.sector = this.passedData.payload

    this.sectorService.getPricesForMdpSector(this.sector.Id, this.sector.MatchDayPassId).subscribe(variants => {
      this.variants = variants
      for(let pv of this.variants) {
        this.orderLinesPerMdp.push({
          NumberOfMdps: 0,
          PriceVariantId: pv.PriceVariationId,
          SectorId: this.sector.Id,
          MatchDayPassId: this.sector.MatchDayPassId
        })
      }
    })
  }
}
