import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpLoaderService} from '@tymes4-shared';
import { interval, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { LongRunningOperationService } from '../../api';

@Component({
  selector: 'app-long-running-operation-progress-bar',
  templateUrl: './long-running-operation-progress-bar.component.html',
  styleUrls: ['./long-running-operation-progress-bar.component.scss']
})
export class LongRunningOperationProgressBarComponent implements OnInit {

  pollingObservable = null;
  busyPolling = false;
  pollInterval = 2500;

  @Output() onProcessFinished = new EventEmitter();

  constructor(private lropService: LongRunningOperationService, private loader: HttpLoaderService) { }

  public operation = null;
  private pollingStarted = false;


  public setLongRunningOperation(lro) {

    this.operation = lro;

    if (this.operation !== null && this.operation.IsRunning === true) {
      this.initializePolling();
    }
    else {
      this.cancelPolling();
    }
  }

  public getLongRunningOperation() {
    return this.operation;
  }

  public determineCssClass() {
    if (this.operation === null) return;

    if (this.operation.IsRunning) { return "lro lro-running" }
    else if (this.operation.OperationStatusId == 5 || this.operation.OperationStatusId == 6) { return "lro lro-error" }
    else  { return "lro lro-succes" }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.pollingObservable !== null) {
      this.pollingObservable.unsubscribe();
    }
  }

  cancel() {

    this.loader.open();
    
    this.lropService.cancelLongRunningOperation(this.operation.Id).subscribe(data => {
      this.loader.close();
    });
  }

  cancelPolling(): any {

    this.busyPolling = false;

    if (this.pollingObservable !== null) {
      this.pollingObservable.unsubscribe();
    }

    if (this.pollingStarted) this.onProcessFinished.emit({});

    this.pollingStarted = false;
  }

  initializePolling(): any {
    this.pollingStarted = true;

    //TODO new LRO setup
    // this.pollingObservable = interval(this.pollInterval).pipe(switchMap((ev) => this.lropService.get(this.operation.Id).pipe(
    //   catchError(error => {
    //     //An error in polling shouldn't stop the polling
    //     return of(null);
    //   })
    // )))
    //     .subscribe(lrop => {
    //       if (lrop != null)
    //       {
    //         this.operation = lrop;
    //         if (this.operation.IsRunning === false) this.cancelPolling();
    //       }
    //     });
  }


}
