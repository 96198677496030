import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import {ConfirmService} from "../../../../../../../dist/tymes4-shared";
import { PriceVariationService, PriceVariationView } from '../../api';

@Injectable()
export class PriceVariationValidator {
    constructor(private priceVariationService: PriceVariationService) {}

    public editingObject : any;

    settingsKeyTaken = (control: AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            setTimeout(() => {
                this.priceVariationService.getPriceVariationByCode(control.value).subscribe((data: PriceVariationView)=> {
                    if (data !== null && data !== void 0) {
                        if (data.Code !== this.editingObject.Code) {
                             resolve({'codetaken': data});
                        }
                    }
                    resolve(null);
                });
            }, 200);
        });
    }
}
