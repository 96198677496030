
<div class="row modal-bar">
    <div class="col-sm-9">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-3">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  
  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.STARTNUMBER.LABEL' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="name" type="number" formControlName="StartNumber" placeholder="{{'SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.STARTNUMBER.PLACEHOLDER' | translate}}" maxlength="64">
              <small *ngIf="form.controls['StartNumber'].hasError('required') && form.controls['StartNumber'].touched" class="form-error-msg">{{'GENERIC.VALIDATION.REQUIRED' | translate}}</small>
            </div>
          </div>
        </div>

        <div class="row">
         
          <div class="col-lg-12">

              <div class="form-group">
                  <label class="form-control-label">{{'SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.DIRECTION.LABEL' | translate}} <span class="tx-danger">*</span></label>
                  <ng-select [items]="directions" 
                             bindLabel="Name" bindValue="Id" placeholder="{{'SALES.ORDERS.NEW.SEATPLAN.DIALOG.ROW-OFFSET.DIRECTION.PLACEHOLDER' | translate}}" 
                             formControlName="Direction"
                             [clearable]="false" 
                             notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" 
                             appendTo="body">
                  </ng-select>
              </div>
          </div>
        </div>
  
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(null);" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>  
</form>




  

  <form [formGroup]="form" class="form-content">
        

    <div fxLayout="row" fxLayoutWrap="wrap" class="form-group">
        <div fxFlex="100" class="pr-1">

            <div class="pb-1">
               
            </div>


        </div>

            
    </div>
  
  </form>