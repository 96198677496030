import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService, NotificationCommunicationService } from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core'
import { forkJoin, Observable, Subject } from 'rxjs';
import { SelectionHelper } from '../../helpers/selection-helper';
import { ValidationHelper } from '../../helpers/validation.helper';
import { HttpLoaderService } from '@tymes4-shared';
import { ConstService } from '../../services/const.service';
import {LoggingService} from '@tymes4-shared';
import { ReportUserTemplateValidator } from './report-user-template.validator';
import {ProductTypeEnum} from "../../../../../../tymes4-shared/src/lib/enums/product-type.enum";
import {Product} from "../../models/product";
import { EventService, GenerateReportArgs, InitiativeService, MatchDayPassService, MembershipService, PassePartoutService, ReportService, ReportUserTemplateService, SalesChannelService, SeasonService, TicketTypeService } from '../../api';
import { merge } from 'jquery';
import { first } from 'rxjs/operators';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { ApplicationModuleHelperService, ApplicationModules } from '../../services/application-module.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-edit-report-user-template-form',
  templateUrl: './edit-report-user-template-form.component.html'
})
export class EditReportUserTemplateFormComponent implements OnInit {

  public form: FormGroup;
  public availableReportCategories = null;
  public availableReports = null;
  public constTimeInterval;
  public constSellPriceType;
  public selectableEvents = null;
  public selectablePassePartouts = null;
  public selectableSeasons = null;
  public selectableInitiatives = null;
  public selectableMemberships = null;
  public selectableProducts = null;
  public selectableFanSalesChannel = null;
  public selectableTicketTypes = null;
  public selectableMembrsipTypes = null;
  public isSpecificMembership = false;
  public selectableMatchDayPasses = null;
  public isSelectSpecificRecurringMembership = false;
  public isSpecificMatchDayPass = false;
  public selectableLinkedMembershipTypes = null;
  public selectableRecurringMemberships = null;
  public selectableMatchDayPassTypes = null;
  public productTypeEnum = ProductTypeEnum;
  public productTypeList: Array<Product> = [{Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PRODUCT-TYPE.TICKET') , Id: 1}, {Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PRODUCT-TYPE.SEASON-PASS'), Id: -1},
    {Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PRODUCT-TYPE.MEMBERSHIP'), Id: 4}, {Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.PRODUCT-TYPE.CROSSELL-PRODUCT'), Id: 6}
  ];
  public selectedReport = null;
  public allowedParams = [];

  public displayDetailsForm = false;

  private preselectedReportId = null;
  private editingObject = null;
  private isNew = false;
  public saving = false;
  public selectionHelper: SelectionHelper;
  public isAvailableRecurringMemberships: boolean;

  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef:  MatDialogRef<EditReportUserTemplateFormComponent>,
    private logging: LoggingService,
    //private reportService: ReportService,
    private reportUserTemplateService: ReportUserTemplateService,
    private loader: HttpLoaderService,
    public constants: ConstService,
    private eventService: EventService,
    private reportService: ReportService,
    private seasonService: SeasonService,
    public translate: TranslateService,
    private validation: ValidationHelper,
    private initiativeService: InitiativeService,
    private membershipService: MembershipService,
    private passePartoutService: PassePartoutService,
    private salesChannelService: SalesChannelService,
    private matchDayPassService: MatchDayPassService,
    private notificationCommunicationService: NotificationCommunicationService,
    private tickettypeService: TicketTypeService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private snackbar: SnackbarHelper,
    private authService: AuthService) { }


  validationMessages = {
    'TimeIntervalStart': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ],
    'TimeIntervalEnd': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ],
    'PriceFrom': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ],
    'PriceTill': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ],
    'MembershipId': [
      { type: 'requiredOnValueCompare', message: 'FORM.VALIDATION.REQUIRED' },
    ]
  };

  ngOnInit() {

    this.loadSettings();
    this.editingObject = null;

    // Set the object to the form
    if (this.passedData.isNew) {
      // this means that we are adding a new form, init with defaults.
      this.isNew = true;
      this.editingObject = {};
    } else {
      this.isNew = false;
      const p = this.passedData.payload;

      // reconstruct the editing object
      this.editingObject = JSON.parse(p.ParamJson);
      this.editingObject.Id = p.Id;
      this.editingObject.Name = p.Name;
      this.editingObject.EventId = this.editingObject.EventId ? +this.editingObject.EventId : null;
      this.editingObject.PassePartoutId = this.editingObject.PassePartoutId ? +this.editingObject.PassePartoutId : null;
      this.editingObject.InitiativeId = this.editingObject.InitiativeId ? +this.editingObject.InitiativeId : null;
      this.editingObject.SeasonId = this.editingObject.SeasonId ? +this.editingObject.SeasonId : null;
      this.editingObject.MembershipId = this.editingObject.MembershipId ? +this.editingObject.MembershipId : null;
      this.editingObject.SalesChannelId = this.editingObject.SalesChannelId ? +this.editingObject.SalesChannelId : null;
      this.preselectedReportId = p.ReportId;
    }

    this.constTimeInterval = this.constants.reportIntervals.map(c => ({ Id: c.value, Name: this.translate.instant(c.code) }));
    this.constSellPriceType = this.constants.reportPrices.map(c => ({ Id: c.value, Name: this.translate.instant(c.code) }));

    this.createForm();
    this.retrieveRequiredData();

    this.form.patchValue(this.editingObject);
  }

  loadSettings() {
    this.applicationModuleHelperService.getActiveModules().subscribe((module) => {
      this.isAvailableRecurringMemberships = (module && module.includes(ApplicationModules.RecurringMemberships));; 
    });
  }

  createForm() {
    const ctrlTimeInterval = new FormControl(null);
    const ctrlSellType = new FormControl(null);

    this.form = new FormGroup({
      ReportCategory: new FormControl(null, [Validators.required]),
      Report: new FormControl(null, [Validators.required]),
      Name: new FormControl('', [Validators.required]),
      TimeInterval: ctrlTimeInterval,
      TimeIntervalStart: new FormControl(null, this.validation.requiredOnValueCompare(ctrlTimeInterval, 999)),
      TimeIntervalEnd:  new FormControl(null, this.validation.requiredOnValueCompare(ctrlTimeInterval, 999)),
      SellPriceType: ctrlSellType,
      PriceFrom: new FormControl(null, this.validation.requiredOnValueCompare(ctrlSellType, 999)),
      PriceTill: new FormControl(null, this.validation.requiredOnValueCompare(ctrlSellType, 999)),
      EventId: new FormControl(null),
      PassePartoutId: new FormControl(null),
      SeasonId: new FormControl(null),
      MembershipId: new FormControl(null),
     Product: new FormControl(null),
      InitiativeId: new FormControl(null),
      SalesChannelId: new FormControl(null),
      LinkedMembershipId: new FormControl(null),
      RecurringMembershipTypeId: new FormControl(null),
      RecurringMembershipId: new FormControl(null),
      MembershipTypeId: new FormControl(null),
      MatchDaypassTypeId: new FormControl(null),
      MatchDayPassId: new FormControl(null),
      TicketTypes: new FormControl(null)
    }, ReportUserTemplateValidator.ValidateDates);

    this.updateFormValidators();

    this.form.get('TimeInterval').valueChanges.subscribe((data: any) => {
      this.form.get('TimeIntervalStart').updateValueAndValidity();
      this.form.get('TimeIntervalEnd').updateValueAndValidity();
    });

    this.form.get('SellPriceType').valueChanges.subscribe((data: any) => {
      this.form.get('PriceFrom').updateValueAndValidity();
      this.form.get('PriceTill').updateValueAndValidity();
    });

    this.form.get('LinkedMembershipId').valueChanges.subscribe((data: any) => {
      this.form.get('MembershipId').updateValueAndValidity();
    });
    this.form.get('RecurringMembershipTypeId').valueChanges.subscribe((data: any) => {
      this.form.get('MembershipId').updateValueAndValidity();
    });
    this.form.get('MatchDaypassTypeId').valueChanges.subscribe((data: any) => {
      this.form.get('MatchDayPassId').updateValueAndValidity();
    });

  }

  updateFormValidators() {
    this.setConditionalValidation('TIME_INTERVAL', 'TimeInterval', [Validators.required]);
    this.setConditionalValidation('PRICE', 'SellPriceType', [Validators.required]);
    this.setConditionalValidation('EVENT_SINGLE', 'EventId', [Validators.required]);
    this.setConditionalValidation('PASSEPARTOUT_SINGLE', 'PassePartoutId', [Validators.required]);
    this.setConditionalValidation('SEASON', 'SeasonId', [Validators.required]);
    this.setConditionalValidation('MEMBERSHIP', 'MembershipId', [Validators.required]);
    this.setConditionalValidation('PRODUCT', 'Product', [Validators.required]);
    this.setConditionalValidation('INITIATIVE', 'InitiativeId', [Validators.required]);
    this.setConditionalValidation('FANCLUB', 'SalesChannelId', [Validators.required]);
    this.setConditionalValidation('LINKED_MEMBERSHIP', 'LinkedMembershipId', [Validators.required]);
    this.setConditionalValidation('RECURRING_MEMBERSHIP_TYPE', 'RecurringMembershipTypeId', [Validators.required]);
    this.setConditionalValidation('MATCHDAYPASS_TYPE', 'MatchDaypassTypeId', [Validators.required]);
    this.form.updateValueAndValidity();
  }

  setConditionalValidation(paramName, formControlName, validators, update = true) {
    if (this.allowedParams.includes(paramName)) {
      this.form.get(formControlName).setValidators(validators);
    } else {
      this.form.get(formControlName).clearValidators();
    }

    if (update === true) {
      this.form.get(formControlName).updateValueAndValidity();
    }
  }

  retrieveRequiredData() {
    this.loader.open();

    const call0 = this.reportService.getAvailableReports();
    const call1 = this.eventService.eventSearch('', 1, 9999999, 'StartDateTime', true);
    const call2 = this.seasonService.getAllSeasons();
    const call3 = this.initiativeService.searchInitiatives('', 1, 9999999, 'Name', true);
    const call4 = this.membershipService.searchMemberships('', 1, 9999999, 'Name', true);
    const call5 = this.passePartoutService.pptSearch('', 1, 9999999, 'Name'); 
    const call6 = this.salesChannelService.getAllSalesChannelsByParentCode('FAN');
    const call7 = this.tickettypeService.getAllTicketTypes();
    const call8 = this.matchDayPassService.searchMatchDayPasses('',1,9999999, 'Name', true);

    forkJoin([call0, call1, call2, call3, call4, call5, call6, call7, call8]).subscribe(data => {

      this.availableReportCategories = (data[0] as any).Categories.map((a, index) => ({ Id: index + 1, Name: this.translate.instant('REPORT-CATEGORY-' + a.Code), ...a }));
      
      this.selectableEvents = (data[1] as any).Records;
      this.selectableSeasons = data[2];
      this.selectableInitiatives = (data[3] as any).Records;
      this.selectableMemberships = (data[4] as any).Records;
      this.selectablePassePartouts = (data[5] as any).Records;
      this.selectableFanSalesChannel = data[6];
      this.selectableTicketTypes = data[7];
      this.selectableMatchDayPasses = (data[8] as any).Records;
      this.selectableRecurringMemberships = this.selectableMemberships.filter(data=> data.IsRecurring);

      this.selectableMatchDayPassTypes = [
        {Id: 1, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.SPECIFIC-MDP')},
        {Id: 2, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.ALL-MDP')},
      ]
      this.selectableMembrsipTypes = [
        {Id: 1, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.SPECIFIC-MEMBERSHIP')},
        {Id: 2, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.ALL-MEMBERSHIP')},
      ]
      this.selectableLinkedMembershipTypes = [
        {Id: 1, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.SPECIFIC-MEMBERSHIP')},
        {Id: 3, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.ALL-MEMBERSHIP')},
        {Id: 4, Name: this.translate.instant('DIALOGS.EDIT-REPORT-USER-TEMPLATE.FORM.MEMBERSHIP-TYPES.ACTIVE-ONLY')},
      ]
      if (!this.isAvailableRecurringMemberships)
      {
        this.selectableLinkedMembershipTypes = this.selectableLinkedMembershipTypes.filter(item=> item.Id != 2);
      }

      // add the extra options to the events
      this.selectableEvents.splice(0, 0, { Name: this.translate.instant('REPORT-EVENT-NEXT'), Id: -2 });
      this.selectableEvents.splice(0, 0, { Name: this.translate.instant('REPORT-EVENT-PREV'), Id: -1 });

      this.reselectReport();

      this.loader.close();
    });
  }

  reselectReport() {
    if (this.preselectedReportId === null) {
       return;
    }

    for (const c of this.availableReportCategories) {

      for (const r of c.Reports) {
        if (r.Id === this.preselectedReportId) {
          // found it!
          this.form.controls.ReportCategory.setValue(c.Id);
          this.onChangeReportCategories(c);
          this.form.controls.Report.setValue(this.preselectedReportId);
          this.onChangeReports(r);
          this.updateFormValidators();
          return;
        }
      }
    }
  }

  onChangeReportCategories(e) {
    if (e && e.Reports) {
      this.availableReports = e.Reports.map(r => ({ Name: this.translate.instant('REPORT-' + r.Code), ...r }));
      this.form.controls.Report.setValue("");
      this.allowedParams = [];
    } else {
      this.availableReports = [];
      this.allowedParams = [];
    }

    this.isSpecificMembership = false;
    this.isSelectSpecificRecurringMembership = false;
    this.form.controls.MembershipId.reset();
    this.updateFormValidators();

  }

  
  onChangeLinkedMembership(linkedType) {
    if(linkedType.Id === 1) {
      this.isSpecificMembership = true;
    } else {
      this.isSpecificMembership = false;
    }
  }
  onChangeSpecificMembership(membershipType)
  {
    if(membershipType.Id === 1) {
      this.isSelectSpecificRecurringMembership = true;
    } else {
      this.isSelectSpecificRecurringMembership = false;
      this.form.controls.MembershipId.reset();
    }
  }
  onChangeSpecificMatchDayPass(MatchDayPassType) {
    if(MatchDayPassType.Id === 1) {
      this.isSpecificMatchDayPass = true;
    } else {
      this.isSpecificMatchDayPass = false;
      this.form.controls.MatchDayPassId.reset();
    }
  }

  isTimeIntervalCustom() {
    return this.form.controls['TimeInterval'].value == 999;
  }

  isSellPriceCustom() {
    return this.form.controls['SellPriceType'].value == 999;
  }

  onChangeReports(report) {
    if (report) {
      this.allowedParams = report.AllowedParams.split(';');
      this.selectedReport = report;
    } else {
      this.allowedParams = [];
      this.selectedReport = null;
    }
    this.isSpecificMembership = false;
    this.isSelectSpecificRecurringMembership = false;
    this.form.controls.RecurringMembershipTypeId.reset();
    this.updateFormValidators();
  }

  formatEvent(state) {
    if (!state.id) {
      return state.text;
    }
    if (state.id <= 0) {
      return state.text;
    } else {
      const dateTime = moment(state.element.getAttribute('data-StartDateTime'));
      return dateTime.format('DD-MM-YYYY HH:mm') + ' ' + state.text;
    }
  }

  formIsInvalid() {
    return (this.selectedReport === null || this.form.invalid || this.saving);
  }

  sanitizeReportParams(form: any): GenerateReportArgs {
    
    if (form.TimeInterval === 1) {
      form.TimeIntervalStart = null;
      form.TimeIntervalEnd = null;
    }

    if (form.PriceFrom === '')
      form.PriceFrom = null;

    if (form.PriceTill === '')
      form.PriceTill = null;

    if (form.SellPriceType === 1) {
      form.PriceFrom = null;
      form.PriceTill = null;
    }
    if (form.Product === -1) {
      form.Product = 1;
      form.PassePartoutSearch = true;
    } else {
      form.PassePartoutSearch = false;
    }

    return form;
  }

  download(fileType) {
    this.loader.open();

    var args : GenerateReportArgs = this.sanitizeReportParams(this.form.value);
    args.ReportId = this.selectedReport.Id;
    args.FileType = fileType;

    this.reportService.generateReport(args).subscribe((lro: any) => {
      this.loader.close();
      if (lro) {
        this.snackbar.open('GENERIC.SNACKBAR.REPORT-GENERATION-STARTED');
        this.notificationCommunicationService.updateNotificationsAndLROS();
        this.dialogRef.close(true);
      }


    });
  }

  submit() {
    this.storeTemplate()
      .pipe(first())
      .subscribe(id => {
        // close this dialog and pass the newly added id to the called
        this.snackbar.saved();
        this.dialogRef.close(true);
        this.saving = false;
        this.loader.close();
    });
  }

  storeTemplate() : Observable<number> {

    const userReportParams =  this.sanitizeReportParams(this.form.value);
    this.saving = true;
    this.loader.open();

    const name = this.form.value.Name;

    const userReport = {
      ParamJson: JSON.stringify(userReportParams),
      ReportId: this.selectedReport.Id,
      Name: name
    };

    // store the object and return the id
    if (this.isNew) {
      return this.reportUserTemplateService.createReportUserTemplate(userReport);
    } 
    else {
      userReport['Id'] = this.editingObject.Id;
      return this.reportUserTemplateService.updateReportUserTemplate(userReport);
    }
  }
}
