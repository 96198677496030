import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { GenericErrorHandler } from 'app/shared/helpers/generic-error-handler';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {CreateRepurchaseExportArgs, PassePartoutPagedResult, PassePartoutSelection, PassePartoutService, RepurchaseService} from "../../api";

@Component({
  selector: 'app-create-rebuy-export',
  templateUrl: './create-rebuy-export.component.html',
  styleUrls: ['./create-rebuy-export.component.scss']
})
export class CreateRebuyExportComponent implements OnInit {

  public saving = false;
  public passePartoutSelections : PassePartoutSelection[] = [];
  public allPassePartouts = [];
  public salesPeriodeId: number;
  private eventId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CreateRebuyExportComponent>,
    private passePartoutService: PassePartoutService,
    public repurchaseService: RepurchaseService,
    private loader: HttpLoaderService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService
  ) { }


  ngOnInit() {
    this.salesPeriodeId = this.passedData.salesPeriodId;
    this.eventId = this.passedData.eventId;
    this.initForm();
  }

  initForm(): any {

    this.passePartoutSelections = [];

    this.loader.open();

    this.passePartoutService.pptSearch('', 1, 99999, 'Name').subscribe((resp: PassePartoutPagedResult) => {
      //activate the first row
      this.pushNewRow();

      this.loader.close();
      this.allPassePartouts = resp.Records;
    },
    error => { this.errorHandler.handle(error);}
    );
  }

  pushNewRow(): any {
    const lastRow = this.passePartoutSelections.length > 0 ? this.passePartoutSelections[this.passePartoutSelections.length - 1] : null;
    this.passePartoutSelections.push({
      FromPassePartoutId: null,
      ToPassePartoutId: null,
      ExpiryDateTime: lastRow?.ExpiryDateTime,
      PlaceSelectionAllowed: true
    });
  }

  removeLastRow() {
    if (this.passePartoutSelections.length === 0) return;
    this.passePartoutSelections.pop();
  }

  onFromPassePartoutChanged(event, ps) {
    ps.FromPassePartoutId = event.Id;
  }

  onToPassePartoutChanged(event, ps) {
    ps.ToPassePartoutId = event.Id;
  }

  onExpiryDateTimeChanged(value: any, ps: PassePartoutSelection) {
    ps.ExpiryDateTime = value;
  }

  onSeatSelectionAllowedChanged (event: any, ps){
    ps.PlaceSelectionAllowed = event.target.checked;
  }

  cannotDownload() {
    let allowed = true;

    for (let pps of this.passePartoutSelections) {
      if (this.salesPeriodeId) {
        allowed = allowed && (pps.FromPassePartoutId !== null && pps.ToPassePartoutId !== null);
      } else {
        allowed = allowed && pps.FromPassePartoutId !== null;
      }
    }

    return !allowed;
  }

  doDownload() {
    this.loader.open();
    const args : CreateRepurchaseExportArgs = {
      PassePartoutSelection: this.passePartoutSelections,
      SalesPeriodId: this.salesPeriodeId,
      EventId: this.eventId
    };

    this.repurchaseService.createRepurchaseImportFile(args).subscribe((data: Blob) => {
      this.loader.close();
      FileSaver.saveAs(data, this.translate.instant('CREATE.REBUY.EXPORT.FILENAME') + '.xlsx');
    },
    error => { this.errorHandler.handle(error);}
    );

  }
}
