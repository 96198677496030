import { Component, OnInit, Input } from '@angular/core';
import { ApplicationModuleHelperService } from '../../services/application-module.service';


@Component({
  selector: 'app-reservation-header',
  templateUrl: './reservation-header.component.html',
  styleUrls: ['./reservation-header.component.scss']
})
export class ReservationHeaderComponent implements OnInit {

  constructor(
    private applicationModuleHelperService: ApplicationModuleHelperService
  ) { }

  reservationDetails = null;
  isIbaActive: boolean = false;
  
  @Input()
  set details(d) {
    this.reservationDetails = d;
  }


  ngOnInit() {
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  constructCustomerIdentificationModel(order){
    return {
      CalculatedName : order.CustomerCalculatedName,
      IsIdentified: order.CustomerIsIdentified,
      CustomerNumber: order.CustomerNumber,
    }
  }

}
