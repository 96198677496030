<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<div class="section-wrapper mt-5">

  <div class="form-layout">
    <div class="row">

      <div class="col-lg-12 mg-b-25">

        <t4-list-view #listVw [options]="listOptions" (onDataRequest)="onDataRequest($event)">

          <ng-container *t4ListItem="let item">

              <td>{{ item.OrderId }}</td>
              <td>{{ displayDescription(item.EventStartDateTime, item.EventName, item.PassePartoutName, item.MembershipName) | replaceOnEmpty }}</td>
              <td>{{ item.TicketTypeName | translate }}</td>
              <td>{{ item.ParentSectionName }}</td>
              <td *ngIf="item.ProductType == 1">{{ item.RowNumber | replaceOnEmpty}} / {{item.SeatNumber | replaceOnEmpty}}</td>
              <td *ngIf="item.ProductType != 1">-</td>
              <td>{{ item.TicketCustomer }}</td>
              <td class="grid-cell">{{ item.PriceVariationName }}</td>
              <td class="text-right">{{ item.AmountExVat | currency:'EUR' }}</td>
              <td class="text-right">{{ item.CalculatedDiscount | percent:'1.0-2' | replaceOnEmpty:'-' }}</td>
              <td class="text-right">{{ item.VAT | currency:'EUR' }}</td>
              <td class="text-right">{{ item.AmountInclVat | currency:'EUR' }}</td>
              
          </ng-container>

        </t4-list-view>
      </div>
    </div>
  </div><!-- form-layout -->
</div>
