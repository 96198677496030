<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<div class="table-responsive">
  <table class="rounded table text-dark table-striped clickable">
    <thead>
      <th>{{'SALES-ENTITY-MODIFICATION.DIALOG.HEADER.DISCOUNT' | translate}}</th>
      <th class="text-right">{{'SALES-ENTITY-MODIFICATION.DIALOG.HEADER.AMOUNT' | translate}}</th>
    </thead>

    <tbody>
      <ng-container *ngIf="this.pagedDiscountLines.length == 0; else discountlines">
        <tr>
          <td colspan="2">{{'SALES-ENTITY-MODIFICATION.DIALOG.LISTVIEW.NO-DISCOUNT-ON-ORDER' | translate}}</td>
        </tr>
      </ng-container>
      <ng-template #discountlines>
        <tr *ngFor="let d of pagedDiscountLines">
          <td>{{ d.DiscountName | replaceOnEmpty}}</td>
          <td style="width: 80px" class="text-right">{{d.Amount | currency:'EUR'}}</td>
          <td class="grid-action" >
            <button (click)="removeDiscountCode(d.DiscountCodeId)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>

<t4-pager [listCounts]="false" (onPage)="doPageForDiscounts($event)" #pagerComponentForDiscounts></t4-pager>
