import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: 'edit-payment-method.component.html'
})

export class EditPaymentMethodComponent implements OnInit {

  public get original() { return this._original; }

  public currentValue: boolean;
  private _original: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditPaymentMethodComponent>) {
  }

  ngOnInit() {
    this._original = !!this.passedData.payload.payByInvoice;
    this.currentValue = this._original;
  }

  public submit() {
    this.dialogRef.close(this.currentValue);
  }
}
