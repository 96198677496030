import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpLoaderService, T4Validators } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import {PriceListService, SalesChannelPagedResult, SalesChannelService} from '../../api';
import {PagedResult} from "../../../../../../../dist/tymes4-shared";

@Component({
  selector: 'app-edit-pricelist-link',
  templateUrl: './edit-pricelist-link.component.html',
  styleUrls: ['./edit-pricelist-link.component.scss']
})

export class EditPricelistLinkComponent implements OnInit {

  // public availableSalesChannels: any = null;
  public form: FormGroup;
  public saving = false;
  public filteredPriceLists: any;
  public selectedPriceList = null;
  public expiryRequired: boolean = false;
  // public selectedSalesChannelIds = [];
  public salesChannelsTouched = false;
  private requireDateForChannels: Array<number> = [];

  private salesChannelSearchControl: FormControl;
  public filteredSalesChannels: any;
  public linkedSalesChannels: any = [];
  public salesChannelsForm: FormGroup;
  public selectedSalesChannels: any = [];

  validationMessages = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private loader: HttpLoaderService,
    public dialogRef: MatDialogRef<EditPricelistLinkComponent>,
    private salesChannelService: SalesChannelService,
    private priceListService: PriceListService,
    private translate: TranslateService
  ) { }

  @ViewChild('listSalesChannelVw') listSalesChannelVw;

  public salesChannelListOptions = {
    search: false,
    pageSize: 12 ,
    multiSelectMode: true,
    disableSort: true,
    headers: [
      { sortField: null, label: 'DIALOGS.EDIT-PRICELIST-LINK.LISTVIEW.HEADER.ID', width: 60, hideOn: null },
      { sortField: null, label: 'DIALOGS.EDIT-PRICELIST-LINK.LISTVIEW.HEADER.NAME',width: null,  hideOn: null },
      { sortField: null, label: 'DIALOGS.EDIT-PRICELIST-LINK.LISTVIEW.HEADER.CODE', width: 220, hideOn: null },
      { label: '' }
    ]
  };

  ngOnInit() {
    this.requireDateForChannels = this.passedData.requireDateForChannels ? this.passedData.requireDateForChannels : [];

    this.loader.open();

    this.form = new FormGroup({
      PriceList: new FormControl('', Validators.required),
      ActiveTill: new FormControl('', Validators.required),
      HasTillDate: new FormControl()
    });

    this.form.controls.PriceList.valueChanges.pipe(
      debounceTime(400)) //give the user some time to end the query
      .subscribe(data => {
          //max 200 results sorted on name
          this.priceListService.searchPriceList(data, 1, 200, 'Name').subscribe(data =>{
              this.filteredPriceLists = data.Records
          });
      });

    this.onChangeHasTillDate(null, this.expiryRequired);

    this.salesChannelSearchControl = new FormControl();

    this.salesChannelSearchControl.valueChanges.pipe(debounceTime(400)).subscribe(searchQ => {

      this.loader.open();

      this.salesChannelService.searchSalesChannel(searchQ, 1, 200, 'Name', true).subscribe((data:SalesChannelPagedResult) => {

        this.filteredSalesChannels = this.removeLinkedSalesChannels(data.Records);

        this.salesChannelsTouched = false;

        this.loader.close();

      });
    });

    this.salesChannelsForm = new FormGroup({
      channelSearch: this.salesChannelSearchControl
    })

  }

  OnSalesChannelItemSelectionChange(items){
    this.selectedSalesChannels = items;
  }

  OnSalesChannelDataRequest(e){
    //existing contingent, fetch the linked sales channels
    this.loader.close();
    const data : PagedResult = {
      TotalRecords: this.linkedSalesChannels.length,
      Records: this.linkedSalesChannels
    };
    this.listSalesChannelVw.Data = data;
  }

  removeLinkedSalesChannels(foundSalesChannels) {
    let result = [];
    for(let e of foundSalesChannels) {
      if (this.findInSalesChannelsArray(e.Id) == null) {
        result.push(e);
      }
    }

    return result;
  }

  findInSalesChannelsArray(id) {
    var r = this.linkedSalesChannels.find(function(pptEvents) { return pptEvents.Id == id})
    return r;
  }

  onSalesChannelAdd(e) {
    let event = e.option.value;
    this.salesChannelsForm.controls['channelSearch'].setValue('');
    this.linkedSalesChannels.push(event);
    this.listSalesChannelVw.refreshData();

    this.salesChannelsTouched = true;
  }

  deleteSalesChannel(channel)
  {
    var idx = this.linkedSalesChannels.indexOf(channel);
    this.linkedSalesChannels.splice(idx, 1);

    this.listSalesChannelVw.refreshData();

    this.salesChannelsTouched = true;
  }

  getSelectedItemText(priceList) {
    if (priceList === undefined || priceList === '' || priceList === null) return '';
    return priceList.Id + ' - ' +  priceList.Name;
  }

  onPriceListLink(e) {
    this.selectedPriceList = e.option.value;
  }

  onChangeHasTillDate(e, required: boolean = false) {

    if (required || (e != null && e.target.checked)) {
      this.form.controls['ActiveTill'].enable();
    } else {
      this.form.controls['ActiveTill'].disable();
    }
  }

  submit() {

    var o = this.form.value;
    o.SalesChannels = [];

    for (let channel of this.linkedSalesChannels)
    {
      o.SalesChannels.push ({
            Id: channel.Id,
            Name: channel.Name
          });
    }

    this.dialogRef.close(o);
  }
}
