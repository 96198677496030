
  <t4-page-warning *ngIf="displayOverlappingPeriodsWarning" warning="COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.OVERLAPWARNING"></t4-page-warning>

  <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListActions>
  
      <div *ngIf="!isInArchiveMode" class="object-actions bg-white text-right">
        <button (click)="addNewState()" class="btn btn-primary border btn-small" type="button"><i class="pd-x-5 far fa-plus-square"></i> {{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
      </div>
  
    </ng-container>
  
    <ng-container *t4ListItem="let item">
      
    
      <ng-container *ngIf="item.IsVoidState">
        <td colspan="4">{{ 'COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.CUSTOM-BUTTON-STATE-LIST.NO-ITEMS-TEXT' | translate }}  </td>
      </ng-container>
      
      <ng-container *ngIf="!item.IsVoidState">
      
       <ng-template #standard>
          <td>   {{ 'CUSTOM.BUTTON.STATE.LIST.STANDARD' | translate}} </td>
       </ng-template>

        <td>{{ item !== null ? item.TranslationCode : this.translate.instant('COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.CUSTOM-BUTTON-STATE-LIST.CALCULATED') }}</td>
        <td *ngIf="item !== null" >{{ getButtonActionType(item) }} </td>
        <td *ngIf="item !== null">{{ getButtonStyle(item) }}</td>
        <td>{{ item.Action | replaceOnEmpty }}</td>
      
      </ng-container>
  
      
      <td>{{ item.ActiveFrom | localDateTime | replaceOnEmpty }}</td>
        <td>{{ item.ActiveTill | localDateTime | replaceOnEmpty }}</td>
    
        <td class="grid-action" >
          <ng-container *ngIf="!item.IsVoidState">
            <button (click)="modifyExistingState(item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
            <button *ngIf="!isInArchiveMode" (click)="deleteState(item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
          </ng-container>
        </td>
  
  
    </ng-container>
  
  </t4-list-view>

  