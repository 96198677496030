import {Component, Inject, OnInit} from '@angular/core';
import {FileInputResult} from '../../components/file-input/file-input.component';
import {HttpResponseBase} from '@angular/common/http';
import {HttpLoaderService} from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import {ConfirmService} from '../../../../../../../dist/tymes4-shared';
import {TranslateService} from '@ngx-translate/core'
import { UploadImageModel, VenueService } from '../../api';

@Component({
  selector: 'app-file-input-dialog',
  templateUrl: './venue-svg-input-dialog.component.html',
  styleUrls: ['./venue-svg-input-dialog.component.scss']
})
export class VenueSvgInputDialogComponent implements OnInit {

  public fileInputResult: FileInputResult = null;
  public fileInputDescription: string;
  public isUploading: boolean;
  public title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private loader: HttpLoaderService,
              private venueService: VenueService,
              private confirmService: ConfirmService,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<VenueSvgInputDialogComponent>,
) { }

  ngOnInit() {
    this.title = this.passedData.title;
  }

  onFileSelect(fileInputResult: FileInputResult) {
    this.fileInputResult = fileInputResult;
  }

  onDescriptionChanged(e) {
    this.fileInputDescription = e.target.value;
  }

  uploadFile() {
    if (this.fileInputResult != null) {
      this.isUploading = true;
      const file = this.fileInputResult.file;
      if (file.type.toLocaleLowerCase() === 'image/svg+xml') {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (e) => {
          const mimeType = '';
          const svg = reader.result as string;
          const placeholder = '!-- PLACEHOLDER_FOR_SECTIONS -->';
          if (!svg.includes('<!-- PLACEHOLDER_FOR_SECTIONS -->')) {
            this.confirmService.confirm({
              title: this.translate.instant('STADIUMPLANSECTION.SVG.FILE.UPLOAD.PLACEHOLDER.WARNING.TITLE'),
              message: this.translate.instant(`STADIUMPLANSECTION.SVG.FILE.UPLOAD.PLACEHOLDER.WARNING.MESSAGE`)
            });
          } else if (!svg.includes('class="baseStadion"')) {
            this.confirmService.confirm({
              title: this.translate.instant('STADIUMPLANSECTION.SVG.FILE.UPLOAD.NO.BASECLASS.CONFRIM.TITLE'),
              message: this.translate.instant(`STADIUMPLANSECTION.SVG.FILE.UPLOAD.NO.BASECLASS.CONFRIM.MESSAGE`)
            })
              .subscribe((result) => {
                if (result) {
                  this.upload(svg, mimeType, file);
                }
              });
          } else {
            this.upload(svg, mimeType, file);
          }
        };
      } else {
        this.isUploading = false;
        this.confirmService.confirm({
          title: this.translate.instant('STADIUMPLANSECTION.SVG.FILE.UPLOAD.WRONG.FILE.WARNING.TITLE'),
          message: this.translate.instant(`STADIUMPLANSECTION.SVG.FILE.UPLOAD.WRONG.FILE.WARNING.MESSAGE`)
        });
      }
    }
  }

  upload(svg, mimeType, file) {
    this.loader.open();
    var args: UploadImageModel = {
      Description: this.fileInputDescription,
      FileData: svg,
      FileName: file.name,
      FileTypeName: file.type,
      MimeType: mimeType
    }

    this.venueService.setVenueSvg(args)
      .subscribe(
        (data: number) => {
          this.dialogRef.close(data);
          this.loader.close();
        },
        error => {
          console.log(error);
          this.loader.close();
          this.confirmService.confirm({title: this.translate.instant('GENERIC.CONFIRM.ERRORS.TITLE.ERROR'),
            message: this.translate.instant('GENERIC.CONFIRM.ERRORS.MESSAGE.ERROR-TRY-AGAIN')});
        }
      );
  }
}
