<div class="input-group mb-3" fxFlex>
  <input type="search" class="form-control" aria-label="Text input with dropdown button" [(ngModel)]="selectedCustomerName" placeholder="{{ 'CUSTOMER.SELECTOR.SEARCH' | translate }}" readonly />
  <div *ngIf=isClearable fxFlex="20px" style="padding-top: 5px;"><button (click)="resetCustomerInternal()" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button></div>
    <div class="input-group-prepend">
        <button class="btn btn-primary" type="button" (click)="customerPickup()">
            <i class="fas fa-search pr-0"></i>
        </button>
      <button class="btn-border-right btn btn-secondary" type="button" (click)="customerPickupBarcode()">
        <i class="fas fa-barcode pr-0"></i>
      </button>
    </div>
</div>
