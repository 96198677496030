import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-number-entry-form',
  templateUrl: './number-entry-form.component.html'
})
export class NumberEntryFormComponent implements OnInit {

  validationMessages = [];

  public form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public dialogRef: MatDialogRef<NumberEntryFormComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      Count: new FormControl('', [Validators.required, Validators.min(0)]),
    });
  }

  submit() {
    this.dialogRef.close(this.form.controls.Count);
  }
}
