<t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

  <ng-container *t4ListActions>
      <button *ngIf="inMerchandiseMode == true" (click)="displayUploadDialog()" class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button> 
  </ng-container>

  <ng-container *t4ListItem="let item">

    <th scope="row" *ngIf="!this.inMerchandiseMode">#{{ item.Id }}</th>

    <th scope="row" *ngIf="this.inMerchandiseMode">
      <button (click)="reOrder('up', item.Id)" [disabled]="item.CrossSellProductDisplayOrder === 1" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-arrow-up-bold-circle-outline"></button>
      <button (click)="reOrder('down', item.Id)" [disabled]="+item.CrossSellProductDisplayOrder === +objectCount" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-arrow-down-bold-circle-outline"></button>
    </th>

    <td scope="row" *ngIf="this.inMerchandiseMode">
      <b>{{ item.CrossSellProductDisplayOrder }}</b>
    </td>

    <td>{{ item.Created | localDateTime | slice:0:10 }}</td>
    
    <td style="cursor: pointer;">
      <img (click)="openWindow(item.Url)" style='width: 20px;' [src]="fileHelper.getResizedImageUrl(item.Url, 32, 32)" />
    </td>

    <td>{{ item.Description }}</td>
    <td>{{ item.ImageTypeTranslationCode | translate }}</td>
    <td>{{ item.Size | fileSize}}</td>
    <td>{{ item.MimeType }}</td>

    <td class="grid-action">
    
      <button (click)="actionClick('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
      <button (click)="actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
    </td>


  </ng-container>

</t4-list-view>
