
  <div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>




  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-PRICELIST-LINK.FORM.HEADER.PRICELIST' | translate}}</label>
                <input style="min-width: 400px;" class="form-control" placeholder="{{'DIALOGS.EDIT-PRICELIST-LINK.FORM.PLACEHOLDER.PRICELIST' | translate}}" [matAutocomplete]="auto" formControlName="PriceList">
              </div>

              <mat-autocomplete #auto="matAutocomplete" [displayWith]="getSelectedItemText" (optionSelected)="onPriceListLink($event)">
                <mat-option *ngFor="let pi of filteredPriceLists" [value]="pi">
                    {{ pi.Name }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="form-group" style="padding-top: 38px">

              <div class="input-height">
                <label class="ckbox">
                  <input type="checkbox" id="HasTillDate" formControlName="HasTillDate"  name="HasTillDate" (change)="onChangeHasTillDate($event)" /> <label for="showDetails">&nbsp;{{'DIALOGS.EDIT-PRICELIST-LINK.FORM.HEADER.HAS-END-DATE' | translate}}</label>
                  <t4-display-validation controlName="HasTillDate" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </label>
              </div>

            </div>

          </div>
          
          <div class="col-lg-8">
            <t4-date-time-control formControlName="ActiveTill"  [labelDate]="'DIALOGS.EDIT-PRICELIST-LINK.FORM.HEADER.ACTIVE-UNTIL-DATE'" [labelTime]="'DIALOGS.EDIT-PRICELIST-LINK.FORM.HEADER.ACTIVE-UNTIL-TIME'"></t4-date-time-control>
            <t4-display-validation controlName="ActiveTill" [form]="form"[messages]="validationMessages"></t4-display-validation>
          </div>
      
 
        </div>

        <div class="row">
          <div class="col-12">
            <form [formGroup]="salesChannelsForm">
              <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-PRICELIST-LINK.FORM.HEADER.SALE-CHANNELS' | translate}}</label>
                <input  style="min-width: 400px;" class="form-control" placeholder="{{'DIALOGS.EDIT-PRICELIST-LINK.FORM.PLACEHOLDER.SALE-CHANNELS' | translate}}" [matAutocomplete]="autoChannel" formControlName="channelSearch">
              </div>

              <mat-autocomplete #autoChannel="matAutocomplete" (optionSelected)="onSalesChannelAdd($event)">
                <mat-option *ngFor="let s of filteredSalesChannels" [value]="s">
                  {{s.Id}} - {{s.Name}} ({{s.Code}})
                </mat-option>
              </mat-autocomplete>

              <t4-list-view #listSalesChannelVw [options]="salesChannelListOptions" (onItemSelectionChange)="OnSalesChannelItemSelectionChange($event)" (onDataRequest)="OnSalesChannelDataRequest($event)">

                <ng-container *t4ListItem="let item">
                  <td>{{item.Id }}</td>
                  <td>{{item.Name}}</td>
                  <td>{{item.Code }}</td>
                  <td class="grid-action" >
                    <button (click)="deleteSalesChannel(item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
                  </td>
                </ng-container>

              </t4-list-view>


            </form>
          </div>
        </div>


        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="submit()" [disabled]="form.invalid || linkedSalesChannels.length == 0 || saving || selectedPriceList === null" class="btn btn-block btn-primary bd-0"><i class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>


