import {Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ListViewSelectionMode} from '@tymes4-shared';
import {DialogHelper, DialogWidth} from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import {EditConfigurationFormComponent} from '../../../../shared/dialogs/edit-configuration-form/edit-configuration-form.component';
import {SnackbarHelper} from 'app/shared/helpers/snackbar-helper';
import {LoggingService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { ActiveConfigurationSettingViewPagedResult, SystemConfigurationSettingsService } from '../../../../shared/api';

@Component({
    selector: 'app-system-settings-configuration-settings',
    templateUrl: './system-settings-configuration-settings.component.html',
    styleUrls: ['./system-settings-configuration-settings.component.scss']
})
export class SystemSettingsConfigurationSettingsComponent implements OnInit {

  // listview
  @ViewChild('listVw') listVw;

  public listOptions = {
    search: true,
    showBarcodeSearch: false,
    pageSize: 12,
    noItemsText: this.translate.instant('VIEW.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.EMPTY'),
    searchTitle: this.translate.instant('VIEW.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.SEARCH'),
    selectionMode: ListViewSelectionMode.DirectSelect,
    fixedTableLayout: true,
    headers: [
      {sortField: 'Key', label: this.translate.instant('VIEW.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.HEADER.KEY'), width: 500, overflowHidden: true, hideOn: null, isDefaultSort: 'asc'},
      {sortField: 'Value', label: this.translate.instant('VIEW.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.HEADER.VALUE'), width: 800, overflowHidden: true, hideOn: null},
    ]
  };

  public selectedItem: any;
  // end listview

  constructor(private settingsService: SystemConfigurationSettingsService,
              private dialog: MatDialog,
              private snackBar: SnackbarHelper,
              private confirmService: ConfirmService,
              private logging: LoggingService,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  // load data from service
  OnDataRequest(e) {
    this.settingsService.searchConfigurationSettings(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc).subscribe((resp: ActiveConfigurationSettingViewPagedResult) => {
        this.listVw.Data = resp;
    });
  }

  // perform actions on click
  actionClick(action, item: any = null) {
    this.logging.log('Action selected.');
    if (action === 'add') {
      this.openDialog(null, true);
    } else if (action === 'edit') {
      this.openDialog(item, false);
    } else if (action === 'delete') {
      this.openConfirmDialog(item);
    }
  }

  // Open confirmation dialog
  openConfirmDialog(data: any = {}) {
    this.confirmService.confirm({
      title: this.translate.instant('CONFIRM.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.DELETE.TITLE'),
      message: this.translate.instant('CONFIRM.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.DELETE.MSG', {key: data.Key}),
      okonly: false
    }).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.settingsService.deleteConfigurationSetting(data.Id).subscribe(() => {
          this.listVw.refreshData();
          this.snackBar.open(this.translate.instant('SNACKBAR.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.DELETE.MSG'));
        });
      }
    });
  }

  // Open editor dialog
  openDialog(data: any = {}, isNew?) {
    let title = '';
    if(isNew) {
      title = this.translate.instant('SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.TITLE.NEW');
    } else {
      title = this.translate.instant('SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.TITLE.EXISTING', {key: data.Key});
    }
    const options = DialogHelper.GetDialogOptions(DialogWidth.md, {title: title, payload: data});
    const dialogRef: MatDialogRef<any> = this.dialog.open(EditConfigurationFormComponent, options);

    dialogRef.afterClosed()
      .subscribe(id => {
          if (id) {
            this.listVw.refreshData();
            this.snackBar.open(this.translate.instant('SNACKBAR.SYSTEM_SETTINGS.EDIT_CONFIGURATION_SETTINGS.SAVE'));
          } else {
            return;
          }
        }
      );
   }
}
