import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ContactValidator} from '../../helpers/contact-validator';
//FDP: Tymes4V2 migration: ngx-custom-validators is incompatible with ng13, this is a fork
//import {CustomValidators} from 'ngx-custom-validators';
import {CustomValidators} from '@narik/custom-validators';
import {ConfirmService, HttpLoaderService, SnackbarService, T4Validators} from '@tymes4-shared';
import {KeyValuePair} from '../../models/key-value-pair';
import {EditContactFormComponent} from '../edit-contact-form/edit-contact-form.component';
import {AuthService} from '../../services/auth.service';
import { ApplicationModules, ApplicationModuleHelperService } from '../../services/application-module.service';
import {
  AccountManagerModel,
  AccountManagerService,
  ActiveConfigurationSettingViewPagedResult,
  CountryService,
  CustomerModel,
  CustomerService,
  LanguageService,
  PostalCodeService,
  SystemConfigurationSettingsService
} from '../../api';


@Component({
  selector: 'app-edit-customer-form',
  templateUrl: './edit-customer-form.component.html',
  styleUrls: ['./edit-customer-form.component.scss']
})

export class EditCustomerFormComponent implements OnInit {
  @ViewChild('pagerComponent') pagerComponent;
  @ViewChild('accountsPagerComponent') accountsPagerComponent;
  @ViewChild('accountsSearchComponent') accountsSearchComponent;

  customerId: number = 0;
  mainForm: FormGroup;
  PrimaryContact: FormGroup;
  Address: FormGroup;
  public customer: any = {};
  countries: any = null;
  accountManagers: Array<AccountManagerModel> = null;
  public selectedObjects: any = [];

  private businessTypeId = 1;

  isNewCustomer: boolean = false;
  isUsingSASSO: boolean = false;

  public saving = false;
  public isDirty = false;

  public selectedAccount: any = null;

  private checkingPostalCode = false;
  private postalCheckOK = true;

  private addressChecked = false;
  private foundAddress: any;

  private requiredFields: KeyValuePair<boolean> = null;

  public customerTypes: Array<any> = [
    {viewValue: this.translate.instant('FANS.EDIT-CUSTOMER-FORM.COMPONENT.CUSTOMER-DETAILS.CUSTOMER-TYPE.FORM.DROPDOWN.BUSINESS-CUSTOMER'), value: 1},
    {viewValue: this.translate.instant('FANS.EDIT-CUSTOMER-FORM.COMPONENT.CUSTOMER-DETAILS.CUSTOMER-TYPE.FORM.DROPDOWN.PRIVATE-CUSTOMER'), value: 2},
  ];

  public selectableGenders: Array<any> = [
    {viewValue: this.translate.instant('FANS.EDIT-CUSTOMER-FORM.COMPONENT.CUSTOMER-DETAILS.PRIMARY-ACCOUNT.FORM.DROPDOWN.MALE-SALUTATION'), value: 'M'},
    {viewValue: this.translate.instant('FANS.EDIT-CUSTOMER-FORM.COMPONENT.CUSTOMER-DETAILS.PRIMARY-ACCOUNT.FORM.DROPDOWN.FEMALE-SALUTATION'), value: 'F'},
  ];

  public SelectableLanguages: Array<any> = [];

  validationMessages = {
    'DateOfBirth': [
      {type: 'maxDate', message: 'FORM.VALIDATION.INVALIDDOB'},
      {type: 'minDate', message: 'FORM.VALIDATION.INVALIDDOB'},

    ]
  };

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditCustomerFormComponent>,
              public contactDialog: MatDialogRef<EditContactFormComponent>,
              private dialog: MatDialog,
              private postalcodeService: PostalCodeService,
              private countryService: CountryService,
              private customerService: CustomerService,
              private contactValidator: ContactValidator,
              private loader: HttpLoaderService,
              private accountManagerService: AccountManagerService,
              private applicationModuleHelperService: ApplicationModuleHelperService,
              private confirmService: ConfirmService,
              private snackBar: SnackbarService,
              private languageService: LanguageService,
              private translate: TranslateService,
              private auth: AuthService,
              private config: SystemConfigurationSettingsService) {
  }

  isRequiredField(field: string, targetForm: FormGroup = null): boolean {
    if (this.requiredFields === null)
      this.requiredFields = {};

    const form = targetForm ? targetForm : this.mainForm;
    const formField = form.get(field);
    if (!formField.validator) {
      return false;
    }

    const validator = formField.validator({} as AbstractControl);
    this.requiredFields[field] = validator && validator.required;

    formField.validator(formField);
    return this.requiredFields[field];
  }


  ngOnInit() {

    this.customerId = this.passedData.payload;
    this.isNewCustomer = this.passedData.isNew;

    // NOTE: when the customer type is changed, it is overwitten which controls are required and which are not.
    // For fans, the list of required fields is in a configuration setting.
    // For companies, the list of required fields is in the method subscribeToCustomerTypeChanges.

    this.mainForm = new FormGroup({
      Id: new FormControl(null),
      PaysByInvoice: new FormControl(), //here to preserve value
      Name: new FormControl('', [Validators.maxLength(512)]),
      COC: new FormControl(''),
      CustomerType: new FormControl('', [Validators.required]),
      AccountManagerId: new FormControl(null),
      TelephoneNr: new FormControl('', [T4Validators.validatePhonenumber]),
      ExternalCode: new FormControl(''),
      AccessToDistributix: new FormControl(false),
      VATNr: new FormControl(''),
      ExternalId: new FormControl(''),
      InvoiceAddressId: new FormControl(null)
    });

    this.PrimaryContact = new FormGroup({
      Id: new FormControl(),
      CustomerId: new FormControl(),
      Initials: new FormControl('', [Validators.maxLength(20)]),
      FirstName: new FormControl('', [Validators.maxLength(50), Validators.required]),
      MiddleName: new FormControl('', [Validators.maxLength(50)]),
      LastName: new FormControl('', [Validators.maxLength(50), Validators.required]),
      Email: new FormControl(null, [CustomValidators.email], [this.contactValidator.emailTaken.bind(this)]),
      MobileNr: new FormControl('', [T4Validators.validatePhonenumber]),
      Gender: new FormControl(''),
      DateOfBirth: new FormControl(null, {
        updateOn: 'blur',
        validators: [CustomValidators.minDate(new Date(1900, 0, 1)), CustomValidators.maxDate(new Date())]
      }),
      Primary: new FormControl(true),
      Active: new FormControl(true),
      LanguageId: new FormControl(null),
      SSOId: new FormControl(),
    });

    this.Address = new FormGroup({
      Street: new FormControl('', [Validators.required]),
      StreetNr: new FormControl('', [CustomValidators.number, Validators.required]),
      StreetNrAddition: new FormControl(''),
      PostalCode: new FormControl('', [Validators.required]),
      CountryId: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required])
    });

    this.countryService.listAllCountries().subscribe((countries) => {
      // Get translated strings
      countries.map(c => c.Name = this.translate.instant(`COUNTRY.${c.Iso2}`));

      // Sort by prio and alphabetically
      this.countries = countries.sort((c1, c2) => {
        const prio1 = c1.Priority || 0;
        const prio2 = c2.Priority || 0;
        if (prio2 - prio1 !== 0)
          return (prio2 - prio1);

        // Remove non-letters from the names
        const name1 = c1.Name.replace(/[^a-z]/gi, '');
        const name2 = c2.Name.replace(/[^a-z]/gi, '');
        return (name1 < name2) ? -1 : (name1 > name2) ? 1 : 0;
      });
    });

    this.accountManagerService.listActiveAccountManagers().subscribe((data: Array<AccountManagerModel>) => {
      this.accountManagers = data;
    });

    this.languageService.getLanguages().subscribe((languages) => {
      this.SelectableLanguages = languages;
    });

    if (!this.isNewCustomer) {
      this.loadCustomerForEdit();
    } else {
      this.customer = {PrimaryContact: {}, Address: {}};
      this.mainForm.patchValue(this.customer);
      this.PrimaryContact.patchValue(this.customer.PrimaryContact);
      this.Address.patchValue(this.customer.Address);
      this.getSSOInfo();
    }

    this.subscribeToCustomerTypeChanges();
  }

  private updateRequiredFieldsFromConfig(configurationKey: string) {
    this.config.searchConfigurationSettings(configurationKey, 0, 1).subscribe((resp: ActiveConfigurationSettingViewPagedResult) => {

      if (resp.Records?.length !== 1 || resp.Records[0]?.Key != configurationKey) {
        console.error("Configuration key not found: " + configurationKey);
        return;
      }

      const requiredFields = resp.Records[0].Value.split(';');

      this.setRequiredFields(this.mainForm, requiredFields);
      this.setRequiredFields(this.PrimaryContact, requiredFields);
      this.setRequiredFields(this.Address, requiredFields);
    });
  }

  private setRequiredFields(form: FormGroup, requiredFields: string[]) {
    for (let controlName in form.controls) {

      if (controlName === "CustomerType") {
        continue; // skip because updating this control would cause an infinite loop
      }

      let control = form.controls[controlName];

      if (requiredFields.includes(controlName)) {
        if (!control.hasValidator(Validators.required)) {
          control.addValidators([Validators.required]);
        }
      }
      else {
        control.removeValidators([Validators.required]);
      }

      control.updateValueAndValidity();
    }
  }

  private subscribeToCustomerTypeChanges() {
    this.mainForm.get('CustomerType').valueChanges.subscribe((type: number) => {
      if (type === this.businessTypeId) {
        this.setRequiredFields(this.mainForm, ["Name"]);
        this.setRequiredFields(this.PrimaryContact, ["FirstName", "LastName"]);
        this.setRequiredFields(this.Address, ["Street", "StreetNr", "PostalCode", "CountryId", "City"]);
      } else {
        this.updateRequiredFieldsFromConfig('Customerform.RequiredFields');
      }
    });
  }

  loadCustomerForEdit() {
    this.customer = {};
    this.customerService.getCustomerWithContacts(this.customerId).subscribe((data: CustomerModel) => {
      //set the customer on screen and load the address
      this.customer = data;

      this.contactValidator.currentEmail = this.customer.Email;
      this.mainForm.patchValue(this.customer);
      if (this.customer.PrimaryContact === null || this.customer.PrimaryContact === void 0) {
        this.customer.PrimaryContact = {};
      }
      this.PrimaryContact.patchValue(this.customer.PrimaryContact);
      this.Address.patchValue(this.customer.Address);

      // disable all fields when we use SSO
      if (this.customer.PrimaryContact.SSOId || this.customer.PrimaryContact.AccountingId || this.customer.PrimaryContact.IsPlacehoder) {

        this.disableMainformFields();
        this.PrimaryContact.disable();
        this.Address.disable();
      } else {
        this.mainForm.enable();
        this.PrimaryContact.enable();
        this.Address.enable();
      }

      this.getSSOInfo();
    });
  }

  disableMainformFields() {
    this.mainForm.controls.CustomerType.disable();
    this.mainForm.controls.TelephoneNr.disable();
    this.mainForm.controls.Name.disable();
  }

  checkPostalCode(forceCheck: boolean) {
    const postalcode = this.Address['controls'].PostalCode;
    const streetNr = this.Address['controls'].StreetNr;
    const doCall = ((postalcode.value && streetNr.value) && (postalcode.valid && streetNr.valid) && (postalcode.dirty || streetNr.dirty));

    if (doCall || forceCheck) {

      this.checkingPostalCode = true;

      this.postalcodeService.checkPostalCode(postalcode.value, streetNr.value).subscribe((address: any) => {
        this.postalCheckOK = (address !== null);
        this.addressChecked = true;
        this.foundAddress = address;

        if (address != null) {
          this.Address.patchValue(address);
        }
        this.checkingPostalCode = false;
      });
    }
  }

  customerIsValidAndDirty() {
    const anyFieldDirty = this.mainForm.dirty || this.PrimaryContact.dirty || this.Address.dirty;
    if (!anyFieldDirty) {
      return false;
    }

    let isValid: boolean =
      (this.PrimaryContact.valid || this.PrimaryContact.disabled) &&
      (this.Address.valid || this.Address.disabled);

    const activeType = this.mainForm.get('CustomerType').value;
    if (activeType == this.businessTypeId) {
      isValid &&= this.mainForm.valid;
    }

    return isValid;
  }

  submit() {
    // mainForm may contain disabled controls, in which case mainForm.value does not include those properties.
    // However, the backend currently lacks validation and interprets missing properties as them being set to null, but we just want to keep the values unchanged for disabled controls.
    // Therefore, mainForm.getRawValue() is used here so that also the unchanged disabled fields are sent to the backend.
    const customer = this.mainForm.getRawValue();

    customer.PrimaryContact = this.PrimaryContact.value;
    customer.Address = this.Address.value;

    // Prevents email validation
    if (customer.PrimaryContact.Email == '') {
      customer.PrimaryContact.Email = null;
    }

    if (!this.isNewCustomer) {
      this.loader.open();
      this.saving = true;
      this.customerService.updateCustomer(customer).subscribe((data: boolean) => {
        this.loader.close();
        this.dialogRef.close(true);
        this.saving = false;
        this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.CUSTOMER-SAVED'), 'GENERIC.SNACKBAR.CLOSE');
      });
    } else {
      this.customerService.createCustomer(customer).subscribe((data: number) => {
        this.loader.close();
        this.dialogRef.close(data);
        this.saving = false;
        this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.CUSTOMER-SAVED'), 'GENERIC.SNACKBAR.CLOSE');
      });
    }
  }

  deleteSSOId() {
    const title = this.translate.instant('DIALOG.SSO_REMOVE.CONFIRM.TITLE');
    const message = this.translate.instant('DIALOG.SSO_REMOVE.CONFIRM.MSG');
    this.confirmService.confirm({ title: title, message: message, okonly: false}).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.customer.PrimaryContact.SSOId = null;
        this.PrimaryContact.patchValue(this.customer.PrimaryContact);
        this.PrimaryContact.markAsDirty();
        this.mainForm.enable();
        this.PrimaryContact.enable();
        this.Address.enable();
      }});
  }

  getSSOInfo(){
    const user = this.auth.getLoggedInUser();
    if (user != null){
      this.isUsingSASSO = user.SystemSettings['SASSOEnabled'].toLowerCase() == 'true';
    }
  }
}
