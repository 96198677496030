import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from "@angular/core";
import { MarketplacePolicyService } from '../../api';

@Injectable()
export class MarketplacePolicyValidator{
    constructor(private marketplacePolicyService: MarketplacePolicyService) {}

    public editingObject: any;

    marketplacePolicyCodeTaken = (control : AbstractControl) => {
        return new Promise<ValidationErrors | null>((resolve, reject) => {
            var v = control.value;
            setTimeout(() => {
                  if (v === '')
                  {
                      resolve(null);
                  }
                
                  this.marketplacePolicyService.getPolicyByCode(v).subscribe(data => {
                  
                  if (data !== null && data !== void 0) {
                      
                    if (data.Id != this.editingObject.Id) {
                        
                      resolve({"codetaken": data});
                    }
                  }
                  resolve(null);
                });
              }, 200);
        });
    }
}