import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { ConstService } from 'app/shared/services/const.service';
import { LinkPricelistComponent } from 'app/shared/components/link-pricelist/link-pricelist.component';

@Component({
  selector: 'app-edit-linked-membership-pricelists',
  templateUrl: './edit-linked-membership-pricelists.component.html'
})
export class EditLinkedMembershipPriceListsComponent implements OnInit {

  public form: FormGroup;

  membershipDetails = null;
  membershipId = null;

  @ViewChild('pricelistComponent') public pricelistComponent: LinkPricelistComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef:  MatDialogRef<EditLinkedMembershipPriceListsComponent>,
              public constants: ConstService) { }

  ngOnInit() {
    this.membershipId = this.passedData.payload.Id;
    this.membershipDetails = this.passedData.payload;
  }

  submit() {
    const linkedLists = this.pricelistComponent.linkedLists;
    this.dialogRef.close(linkedLists);
  }
}
