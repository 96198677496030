<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<div class="section-wrapper">
  <div class="table-responsive">
    <table class="rounded table text-dark table-striped clickable">
      <thead>
        <th>{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-PRICE-STRUCTURE.LISTVIEW.HEADER.PRICE-CATEGORY' | translate}}</th>
        <th>{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-PRICE-STRUCTURE.LISTVIEW.HEADER.VAT-PERCENTAGE' | translate}}</th>
        <th>{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-PRICE-STRUCTURE.LISTVIEW.HEADER.PRICE-WITHOUT-VAT' | translate}}</th>
        <th>{{'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-PRICE-STRUCTURE.LISTVIEW.HEADER.PRICE-WITH-VAT' | translate}}</th>
        <th *ngIf="clearComponentPrice"></th>

      </thead>

      <tbody>
        <tr *ngFor="let ps of priceLineComponentSelections">
          <td class="pt-4">{{ ps.ComponentName }}</td>
          <td class="pt-4">{{ ps.VATPercentage }}%</td>
          <td><input
            class="form-control"
            #amountInput
            type="number"
            currencyformat
            min="0"
            style="background-color:white; text-align: right; height: 36px;"
            placeholder="{{'DIALOGS.EDIT-PRICE.FORM.TABLE.BODY.PLACEHOLDER.PRICE-EX-VAT' | translate}}"
            [value]="ps.AmountExVAT"
            (change)="onAmountExVATChanged($event, ps)"
            [disabled]="isReadOnly"></td>
          <td><input
            class="form-control"
            #amountInput
            type="number"
            currencyformat
            min="0"
            style="background-color:white; text-align: right; height: 36px;"
            placeholder="{{'DIALOGS.EDIT-PRICE.FORM.TABLE.BODY.PLACEHOLDER.PRICE-IN-VAT' | translate}}"
            [value]="ps.AmountInVAT"
            (change)="onAmountInVATChanged($event, ps)"
            [disabled]="isReadOnly"></td>
          <td *ngIf="clearComponentPrice" style="min-width:160px">
            <button (click)="removePrice(ps)" class="btn btn-sm btn-danger" *ngIf="!isReadOnly"><i class="far fa-trash-alt"></i> Geen Prijs</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="form-layout">
    <div class="form-layout-footer row mt-3">
      <div [class.col-6]="allowEmptyRate === false" [class.col-4]="allowEmptyRate === true">
        <button (click)="submit()" class="btn btn-block btn-primary bd-0" *ngIf="!isReadOnly"><i
          class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
      </div>
      <div class="col-4" *ngIf="allowEmptyRate === true">
        <button (click)="dialogRef.close(null)" class="btn btn-block btn-danger bd-0" *ngIf="!isReadOnly"><i class="far fa-trash-alt"></i><span>{{'DIALOGS.EDIT-PRICE.FORM.BUTTON.NO-PRICE' | translate}}</span></button>
      </div>
      <div [class.col-6]="allowEmptyRate === false" [class.col-4]="allowEmptyRate === true">
        <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0" *ngIf="!isReadOnly"><i
          class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        <button (click)="dialogRef.close(false)" class="btn btn-block btn-primary bd-0" *ngIf="isReadOnly"><i
          class="fas fa-ban"></i><span>{{'GENERIC.BUTTON.CLOSE' | translate}}</span></button>
      </div>

    </div><!-- form-layout-footer -->
  </div><!-- form-layout -->
</div>
