import { Deserializable } from "./deserializable";

export class CacheRegisterState implements Deserializable <CacheRegisterState> {

  deserialize(input: any) : CacheRegisterState {
    Object.assign(this, input);
    return this;
  }

 selectedCustomers : any[]; 
 currentAction: CashRegisterAction = CashRegisterAction.NotSet;
 placedOrderId: number = null;
 
}

export enum CashRegisterAction {
    NotSet = 0,
    ExistingReservation =  1,
    ExistingOrder = 2,
    NewOrder = 3,
    SelectAnonymousUser = 4,
    OrderingStarted = 5,
    OrderPaymentRequired = 6 
  };



