import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import {ConfirmService} from '@tymes4-shared';
import { FormGroup, FormControl } from '@angular/forms';
import { PassePartoutService } from '../../../api';

@Component({
  selector: 'app-sales-passepartout-selection',
  templateUrl: './sales-passepartout-selection.component.html',
  styleUrls: ['./sales-passepartout-selection.component.scss']
})
export class SalesPassepartoutSelectionComponent implements OnInit {


  selectedItem: any;
  form: FormGroup;
  pageSize = 6;
  searchText = ''
  listObjects: any = [];
  totalObjectCount: number = 0;
  selectedObject = null;
  pageNr = 1;


  constructor(
    private passePartoutService : PassePartoutService,
    private confirmService: ConfirmService,
    private translate: TranslateService)
    { }

  @ViewChild('pagerComponent') pagerComponent;
  @Output() onPassePartoutSelectionChange = new EventEmitter<object>();
  @Input() hasOther: boolean;


  ngOnInit() {

    this.form = new FormGroup({
      SearchText: new FormControl(''),
    });

    this.fetchData(1);
  }

  updateFilter(e) {
    e.preventDefault();
    this.searchText = this.form.controls['SearchText'].value;
    this.fetchData(1);
  }

  fetchData(pageNr){

    this.pageNr = pageNr;

    this.passePartoutService.passePartoutSalesSearch(this.searchText, pageNr, this.pageSize, 'Name', true).subscribe((resp: any) => {
      this.totalObjectCount = resp.TotalRecords;
      this.listObjects = resp.Records;
      this.pagerComponent.setObjectCount(this.totalObjectCount);
      this.pagerComponent.setPage(pageNr);
    });
  }

  doPage(pageArgs) {
    this.fetchData(pageArgs.pageNr);
  }

  reload() {
    this.fetchData(this.pageNr);
  }

  isItemSelected(e) {
    return (this.selectedObject && this.selectedObject !== null && (this.selectedObject.Id == e.Id));
  }


  toggleSelection(passePartout) {

    if (passePartout == null) return;

    if (this.selectionIsAllowed(passePartout)) {

      if (this.selectedObject != null && this.selectedObject.Id == passePartout.Id) {
        this.clearSelection();
      }
      else {
        this.clearSelection();
        this.selectedObject = passePartout;
      }

      this.reload();
      this.onPassePartoutSelectionChange.emit(this.selectedObject);

    }

  }

  selectionIsAllowed(pp) {

    if (this.hasOther) {

      this.confirmService.confirm({ title: this.translate.instant('SALES.ORDERS.NEW.SALES-PASSEPARTOUT-SELECTION.CONFIRM.NOTPOSSIBLE.TITLE'),
        message: this.translate.instant('SALES.ORDERS.NEW.SALES-PASSEPARTOUT-SELECTION.CONFIRM.NOTPOSSIBLE.MSG'), okonly: true }).subscribe(confirmed => {});

        return false;
    }

    return true; //a-ok
  }



  clearSelection() {
    this.selectedObject = null;
    this.onPassePartoutSelectionChange.emit(this.selectedObject);
  }
}
