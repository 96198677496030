<ng-container>
  <div class="custom-file">
    <input #file type="file" class="custom-file-input" id="upload-translation"
            (change)="fileChange(file)" multiple>
    <label class="custom-file-label custom-file-label-primary" for="upload-translation">
        {{ selectedFile == null ? this.translate.instant('COMPONENTS.FILE-INPUT.SELECT-FILE') : selectedFile.name }}
    </label>
  </div>
  <div>
    <span class="invalid-feedback" *ngFor="let error of validationErrors"> {{ error.key | translate:error.params }}</span>
  </div>
</ng-container>
