<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form class="form-content">

  <div class="section-wrapper mt-3">

    <div class="form-layout">

      <div class="row mg-b-25">
        <div class="col-lg-10">

        </div>
        <div class="col-lg-2 mb-3 text-right">
          <button class="mr-1 btn btn-primary btn-small" (click)="pushNewRow()"><span>+</span></button>
          <button class="btn btn-primary btn-small" (click)="removeLastRow()" [disabled]="!passePartoutSelections || passePartoutSelections.length === 1"><span>-</span></button>
        </div>

      </div>

      <div *ngFor="let ps of passePartoutSelections" class="row row-sm mg-b-25">

        <div [class]="'col-lg-6'">
          <ng-select [items]="allPassePartouts" bindLabel="Name" bindValue="Id" placeholder="{{'CREATE.ORDER.IMPORT.FILE.FROM.PASSE-PARTOUTS' | translate}}" (change)="onFromPassePartoutChanged($event, ps)"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
        </div>

        <div class="col-lg-6"  *ngIf="salesPeriodeId">
          <ng-select [items]="allPassePartouts" bindLabel="Name" bindValue="Id" placeholder="{{'CREATE.ORDER.IMPORT.FILE.TO.PASSE-PARTOUTS' | translate}}" (change)="onToPassePartoutChanged($event, ps)"
                     [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
        </div>

      
      </div>

      <div class="form-layout-footer row pt-5" >
        <div class="col-6">
          <button (click)="doDownload()"  class="btn btn-block btn-primary bd-0" [disabled]="cannotDownload()"><i class="fas fa-file-export"></i><span>{{'GENERIC.BUTTON.DOWNLOAD' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-window-close"></i><span>{{'GENERIC.BUTTON.CLOSE' | translate}}</span></button>
        </div>
      </div>
    </div>
  </div>


</form>

