import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { SeasonService } from '../../api';

@Component({
  selector: 'app-select-season',
  templateUrl: './select-season.component.html'
})
export class SelectSeasonComponent implements OnInit {

  public seasons: any;
  public form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private seasonService: SeasonService,
              public dialogRef: MatDialogRef<SelectSeasonComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      SeasonId: new FormControl('')
    });

    this.seasonService.getAllSeasonsWithCurrent().subscribe((data) => {
      this.seasons = data;

      const seasonId = this.seasons.filter(s => s.Active)[0].Id;
      if (seasonId) {
        const o = {
          SeasonId: seasonId
        };

        this.form.patchValue(o);
      }
    });
  }

  submit() {
    const o = this.form.value;

    this.dialogRef.close(o.SeasonId);
  }
}
