<div class="row modal-bar">
  <div class="col-sm-6">
    <h5 class="modal-title">{{ title | translate }}</h5>
  </div>
  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="onClose.emit()">×</span>
        </button>
      </div>
    </div>
  </div>
</div>
