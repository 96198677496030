import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmService, HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { DiscountService, PendingOrderService } from '../../api';

@Component({
  selector: 'app-remove-discount-code-form',
  templateUrl: './remove-discount-code-form.component.html'
})
export class RemoveDiscountCodeFormComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
  public dialogRef: MatDialogRef<RemoveDiscountCodeFormComponent>,
  private confirmService: ConfirmService,
  private apploader: HttpLoaderService,
  private pendingOrderService: PendingOrderService,
  private discountService: DiscountService,
  private translate: TranslateService) { }
  lastUsedPageForDiscounts = null;
  public orderDetails = null;
  pagedDiscountLines: Array<any> = [];

  @ViewChild('pagerComponentForDiscounts') pagerComponentForDiscounts;

  ngOnInit() {
    this.orderDetails = this.passedData.order;

    this.refetchOrderLines();
  }

  refetchOrderLines() {
    this.apploader.open();

    // refetch the details.
    this.pendingOrderService.getPendingOrderDetails(this.orderDetails.Id).subscribe((order: any) => {
      this.loadOrderLines(order);
      this.apploader.close();
    });
  }

  loadOrderLines(order: any) {
    this.orderDetails = order;

    if (this.lastUsedPageForDiscounts != null) {
      this.setSearchAndPageForDiscounts(this.lastUsedPageForDiscounts);
    } else {
      this.setSearchAndPageForDiscounts(1);
    }

  }

  doPageForDiscounts(pageArgs) {
    this.setSearchAndPageForDiscounts(pageArgs.pageNr);
  }

  setSearchAndPageForDiscounts(pageNr: number) {
    this.lastUsedPageForDiscounts = pageNr;
    const length = this.orderDetails.DiscountTotals.length; // we need a copy
    this.pagerComponentForDiscounts.setObjectCount(length);
    this.pagedDiscountLines = this.orderDetails.DiscountTotals
      .slice((pageNr - 1) * this.pagerComponentForDiscounts.pageSize, (pageNr) * this.pagerComponentForDiscounts.pageSize);
    this.pagerComponentForDiscounts.setPage(pageNr);
  }

  removeDiscountCode(codeId: number) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('DIALOGS.REMOVE-DISCOUNT-CODE.CONFIRM.WARNING.MESSAGE.DISCOUNT-CODE-REMOVED')
    }).subscribe((r: boolean) => {
      if (r) {
        this.apploader.open();
        this.discountService.removeDiscountCode(codeId, this.orderDetails.Id).subscribe((order: any) => {
          this.apploader.close();
          this.dialogRef.close();
        });
      }
    });
  }
}
