<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.SELECT-EVENT.FORM.LABEL.EVENT' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="selectableEvents" bindLabel="Name" bindValue="Id" placeholder="{{'DIALOGS.SELECT-EVENT.FORM.PLACEHOLDER.EVENT' | translate}}" formControlName="EventId"
                       [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item && item.Name ? item.Name + (item.StartDateTime ? ' - ' : '') : '' }}{{ item.StartDateTime | localDateTime | slice:0:10 }}
              </ng-template>
            </ng-select>
          </div>
        </div>

      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{'GENERIC.BUTTON.CONTINUE' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
