import {Component, Inject, Input, ViewChild, OnInit, Output, EventEmitter} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {EventSaleCategoryState} from '../../models/custom-button-data';
import {ConfirmService} from '@tymes4-shared';
import { CustomButtonStateListComponent } from "app/shared/components/manage-event-sale-category-states/custom-button-state-list/custom-button-state-list.component";
import { EventService, SaleCategory } from '../../api';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-manage-event-sale-category-states',
  templateUrl: './manage-event-sale-category-states.component.html',
  styleUrls: ['./manage-event-sale-category-states.component.scss']
})
export class ManageEventSaleCategoryStatesComponent implements OnInit {
  
  @ViewChild(CustomButtonStateListComponent) customButtonStateListComponent: CustomButtonStateListComponent;
  @ViewChild('CustomButtonStateList') customButtonStateList;


  scStates = null;
  eventSaleCategories: SaleCategory[] | null = null;

  @Output() onSave = new EventEmitter();
  @Input() eventId: number = null;

  
  public statesInCurrentTab: Array<EventSaleCategoryState>;

  currentTabId = null;

  constructor(public confirmService: ConfirmService, private eventService: EventService) {
  }

  ngOnInit() {
    if (this.eventId != null)  {
      this.eventService.getEventSaleCategoryForEvent(this.eventId)
        .pipe(first())
        .subscribe(categories => {
          
          this.eventSaleCategories = categories;

          if (this.eventSaleCategories.length > 0) {
            this.currentTabId = this.eventSaleCategories[0].Id;
          }
        }); 
    }
  }

  refresh() {
    
    if (this.currentTabId !== null) {
      this.customButtonStateListComponent.setEvent(this.eventId, this.currentTabId);
    }
  }

  saveSaleCategories() {
    this.onSave.emit();
  }
  onTabChange(e) {

    this.currentTabId = e.selectedTab.id;
    this.customButtonStateListComponent.setEvent(this.eventId, this.currentTabId); //currenttab === salescategoryid
  }

  public getFilter(saleCategoryId: number): Function{
    return s => s.SaleCategoryId === saleCategoryId;
  }

  public getBaseState(saleCategoryId: number): EventSaleCategoryState{
    const baseState = new EventSaleCategoryState();
    baseState.SaleCategoryId = saleCategoryId;
    baseState.EventId = this.eventId;
    return baseState;
  }
}
