import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { PriceComponentService } from '../api';

@Injectable()
export class PriceComponentSchemeValidator {

  constructor(private priceComponentsService: PriceComponentService) {}

  private debouncer: any;
  public editingObject: any;

  otherIsDefault = (control: FormControl) => {
    // do not hammer the service
    clearTimeout(this.debouncer);

    return new Promise(resolve => {
      this.debouncer = setTimeout(() => {

        if (!control.value || !this.editingObject) {
          resolve(null);
        } else {
          this.priceComponentsService.getPriceComponentDefaultSchemeId().subscribe(data => {
            if (data !== null && this.editingObject.Id !== data) {
              resolve({'otherDefault': data});
            } else {
              resolve(null);
            }
          });
        }
      }, 1000);
    });
  }
}
