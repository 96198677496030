
<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{'SALES.ORDERS.FAILURES.DIALOG.DETAILS.TITLE' | translate}}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>



<t4-tabs>

  <t4-tab tabTitle="{{'ORDERS.INCORRECT.DETAIL.PAYMENT-HISTORY.TITLE' | translate}}">
    <app-payment-overview #paymentOverview></app-payment-overview>
  </t4-tab>

</t4-tabs>
