import {Component, Inject, Input, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 't4-customer-search-dialog',
  templateUrl: 'customer-search-dialog.component.html'
})

export class CustomerSearchDialogComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CustomerSearchDialogComponent>
  ) { }

  ngOnInit() {
  }
  
  onCustomerSelect(customers) {
    this.dialogRef.close(customers[0]);
  }
}
 