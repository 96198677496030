import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class TemplateValidator {
    constructor() {}

    TemplateNameTaken = (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>((resolve, reject) => {
        // var v = control.value;

        // setTimeout(() => {
        //   this.eTicketTemplateService.getByName(v).subscribe(data => {
        //     if (data !== null && data !== void 0) {
        //       resolve({"nametaken": data});
        //     }
        //     resolve(null);
        //   });
        // }, 200);
      });
    }
}
