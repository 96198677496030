import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SystemEventHelper {

    constructor(private translate: TranslateService) { }

    public systemEventTypeToText (code) {

        let result = '';

        switch (code) {
            case 100:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.TICKET-ISSUED');
            break;

            case 101:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.TICKET-REVOKED');
            break;

            case 102:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.NEW-BARCODE-GRANTED');
            break;

            case 103:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.TICKET-UPDATED');
            break;

            case 200:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.PRINTED');
            break;

            case 201:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.SENT-TO-CUSTOMER');
            break;

            case 300:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.PLACED-IN-RESERVATION');
            break;

            case 301:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.RESERVATION-REVOKED');
            break;


        }

        return result;
    }

    public systemEventReasonToText (code) {

        let result = '';

        switch (code) {
            case 100:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.ORDER-CREATED');
            break;

            case 101:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.ORDER-DELETED');
            break;

            case 102:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.ORDER-UPDATED');
            break;

            case 201:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.ADDED-TO-PASSE-PARTOUT');
            break;

            case 202:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.REMOVED-FROM-PASSE-PARTOUT');
            break;

            case 300:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.RESERVARION-CREATED');
            break;

            case 301:
                result = this.translate.instant('HELPERS.SYSTEM-EVENT.RESERVARION-REVOKED');
            break;

        }

        return result;
    }

}

