import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class FileInputResult {
  file: File;
  description: string;
  constructor(file: File, description: string) {
    this.file = file;
    this.description = description;
  }
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {

  @Input()
  public accept = '.png,.jpg,.jpeg,.gif';

  @Input()
  public label = null;

  @Input()
  public buttonText = this.translate.instant('COMPONENTS.FILE-INPUT.BUTTONTEXT.UPLOAD-FILE');

  @ViewChild('inputFileElement') inputFileElement;

  public selectedFile: File = null;

  public maxFileSize = 4; // MB

  @Output()
  public onFileSelect = new EventEmitter<FileInputResult>();

  @Output()
  public onDescriptionChanged = new EventEmitter<string>();

  public validationErrors: Array<{ key: string, params: any }> = [];
  description: any;

  constructor(
    public translate: TranslateService
    ) { }

  ngOnInit() {
  }

  fileChange(event) {
    // https://stackoverflow.com/questions/40214772/file-upload-in-angular
    const fileList: FileList = event.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
    }
    this.validationErrors = [];

    if (this.selectedFile.size >= this.maxFileSize * 1000000) {
      this.validationErrors.push({key: 'FILE-UPLOAD.SIZE-EXCEEDED', params: {'maxSize': this.maxFileSize + 'MB'}});
    }

    if (this.validationErrors.length === 0) {
      this.onFileSelect.emit(new FileInputResult(this.selectedFile, this.description));
    } else {
      this.onFileSelect.emit(null);
    }
  }
}
