import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {LoggingService} from '@tymes4-shared';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { UserService } from '../../api';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { ConstantPool } from '@angular/compiler';

@Injectable()
export class ArchiveGuard implements CanActivate {
  public authToken;
  constructor(
    private router: Router, 
    private logging : LoggingService, 
    private authService: AuthService, 
    private userService: UserService, 
    private snackbarHelper: SnackbarHelper) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
    const path = state.url;
    return this.matchUrl(path);
  }

  matchUrl(path: string): Observable<boolean>{
    return new Observable<boolean>((observer) => {
      const token = sessionStorage.getItem('jwt');
      if(token == null)
        return;
      this.userService.getCurrentUserMenuItems().subscribe((menuItems) => {
        if (!this.authService.isInArchiveMode()) {
          this.logging.log('User is not in archive mode.');
          observer.next(true);
          observer.complete();
        }

        else{
          var notFound = true;

          var allowedUrls = (() => {
            var result: Array<string> = [];
            for(var i = 0; i < menuItems.length; i++){
              result.push("/" + menuItems[i].State);

              menuItems[i].SubItems.forEach(subMenuItem => {
                result.push("/" + menuItems[i].State + "/" + subMenuItem.State)
              })
            }

            return result;
          })();

          // Remove excluded parts from the URL
          var sanitizedUrl = (() => {
            var result = path;
            const detailsIndeces = [path.indexOf('/details/'), path.indexOf('/detail/')];
            detailsIndeces.forEach((detailsIndex) => {
              if (detailsIndex !== -1) {
                result = path.substring(0, detailsIndex);
              }
            })
            return result;
          })()
        
          // Check if the sanitized URL or any parent paths are whitelisted
          if (allowedUrls.some(url => url === sanitizedUrl)) {
            notFound = false;
            observer.next(true);
            observer.complete();
          }

          if(notFound){
            this.router.navigate(['/dashboard']);
            this.snackbarHelper.open("GENERIC.SNACKBAR.NOTAVAILABLE_ARCHIVE")
            observer.next(false);
            observer.complete();
          }
        }
      })
    })
  }
}
