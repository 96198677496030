/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Username?: string | null;
    Email?: string | null;
    Firstname?: string | null;
    Middlename?: string | null;
    Lastname?: string | null;
    Password?: string | null;
    Salt?: string | null;
    LastLoginDate?: Date | null;
    ProfileImage?: string | null;
    Active?: boolean;
    Description?: string | null;
    LanguageId?: number | null;
    TFAEnabled?: boolean;
}

