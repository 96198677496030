/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WorkflowRuleContextEnum = 'Payment' | 'TicketAssignment';

export const WorkflowRuleContextEnum = {
    Payment: 'Payment' as WorkflowRuleContextEnum,
    TicketAssignment: 'TicketAssignment' as WorkflowRuleContextEnum
};

