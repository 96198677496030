import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core'

@Component({
  selector: 'create-merge-batch',
  templateUrl: './create-merge-batch.component.html',
  styleUrls: ['./create-merge-batch.component.scss']
})

export class CreateMergeBatchComponent implements OnInit {
    constructor(
      @Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef: MatDialogRef<CreateMergeBatchComponent>
    ) { }
  
    public form: FormGroup;
    public saving = false;
    public deliveryMethods: any = null;
  
    selectableEvents: any = [];
    eventTypes: any = [];
    selectablePassePartout: any = [];
  
    ngOnInit() {
        this.form = new FormGroup({
            Ammount: new FormControl(50),
            Hardcards: new FormControl(false)
          });
    }

    confirm() {
        this.dialogRef.close(this.form.value);
    }
}