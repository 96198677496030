import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {ExternalTicketService} from "../../api";

@Component({
  selector: 'app-upload-external-images',
  templateUrl: './upload-external-tickets.component.html'
})

export class UploadExternalTicketsComponent implements OnInit {

  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  crossSellProductId = null;
  uploadCount = 0;
  allowUpload = false;
  private eventId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<UploadExternalTicketsComponent>,
              public externalTicketService: ExternalTicketService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.eventId = this.passedData.eventId;
  }

  submit() {
  }

  selectFiles(event) {
    this.progressInfos = [];

    const files = event.target.files;
    let isPdf = true;

    for (let i = 0; i < files.length; i++) {
      this.allowUpload = true;

      if (!files.item(i).type.match('application\/pdf')) {
        isPdf = false;
        alert('invalid format!');
        break;
      }
    }

    if (isPdf) {
      this.selectedFiles = event.target.files;
    } else {
      this.selectedFiles = undefined;
      event.target.percentage = null;
    }
  }

  uploadFiles() {
    this.allowUpload = false;
    this.message = '';

    this.uploadCount = this.selectedFiles.length;

    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  upload(idx, file) {
    this.progressInfos[idx] = {value: 0, fileName: file.name};

    if (!file)
      return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64string = (reader.result as string).split(',')[1];
      const uploadExternalTicketModel = {
        Description: file.name.split('.')[0],
        FileData: base64string,
        FileName: file.name,
        MimeType: file.type
      };
      this.externalTicketService.uploadExternalTicket(this.eventId, uploadExternalTicketModel, null, null, "response", true).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.uploadCount--;

            if (this.uploadCount == 0) {
              this.dialogRef.close(1); //we are done.
            }
          }
        },
        err => {
          this.progressInfos[idx].percentage = 0;
          this.message = this.translate.instant('DIALOGS.UPLOAD-EXTERNAL-TICKETS.ERROR.MESSAGE.ERROR-DURING-UPLOAD') + file.name;
          this.allowUpload = false;
        });
    }
  }
}
