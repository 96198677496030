<t4-card [showFooter]="false" bodyClass="p-0">
  <ng-container data-body>
    <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

      <ng-container *ngIf="readonlyMode === false">
        <ng-container *t4ListActions >
          <button (click)="onActionSelected('add')"  class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
        </ng-container>
      </ng-container>
      <ng-container *t4ListItem="let item">
        <td><b>{{ item.ContactFirstName }} {{ item.ContactMiddleName }} {{ item.ContactLastName }}</b></td>
        <td>{{ item.ContactEmail }}</td>
        <td>{{ item.RolesDescription }}</td>
        <td><t4-list-value-boolean [value]="item.IsPrimaryContact" [allowToggle]="true" (onToggle)="TogglePrimaryContact(item.ContactId, $event)"> </t4-list-value-boolean></td>
        <td class="grid-action" >
          <button *ngIf="readonlyMode === false" (click)="onActionSelected('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="readonlyMode === false && !item.IsPrimaryContact" (click)="onActionSelected('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>
    </t4-list-view>
  </ng-container>
</t4-card>

