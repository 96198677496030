import {BaseUser} from '@tymes4-shared';
import { Deserializable } from "./deserializable";
import {MenuItem} from './menuitem';

export class AppUser implements Deserializable <AppUser>, BaseUser {

  Id: number;
  Email: string;
  Username: boolean;
  Firstname: string;
  Middlename: string;
  Lastname: string;
  Fullname: string;
  Roles: string[];
  MenuItems: Array<MenuItem>;
  Channels: any[];
  IsArchiveMode: boolean;
  ProfileImage: string;
  LanguageCode: string;
  ShowGoToProfile: boolean;
  SystemSettings: object;

  deserialize(input: any): AppUser {
    Object.assign(this, input);
    return this;
  }
}

