
  <div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>

  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
        <div class="form-layout row">
            <div class="col-lg-12">
                <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-RESELLER-CONTACT.FORM.LABEL.ACCOUNT' | translate}}<span class="tx-danger" *ngIf="isRequiredField('ContactId')">*</span></label>

                <ng-select [disabled]="!isNewResellerContact" [items]="contacts | async" bindLabel="Name" bindValue="Id" formControlName="ContactId" placeholder="{{'DIALOGS.EDIT-RESELLER-CONTACT.FORM.PLACEHOLDER.ACCOUNT' | translate}}" [typeahead]="contactsInput$" loadingText="{{'GENERIC.LOADING-TEXT' | translate}}"
                            [clearable]="false" notFoundText="{{'DIALOGS.EDIT-RESELLER-CONTACT.FORM.NO-ACCOUNTS-FOUND' | translate}}" appendTo="body" [loading]="contactsLoading" [virtualScroll]="true" typeToSearchText="{{'GENERIC.TYPE-TO-SEARCH-TEXT' | translate}}">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                      {{ getItemDisplayText(item) }}
                    </ng-template>
                </ng-select>

                <t4-display-validation controlName="ContactId" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
        </div>

        <div class="row mb-3">

            <div class="col-lg-12">
            <h6 class="form-header h6-icon">
              {{'DIALOGS.EDIT-RESELLER-CONTACT.FORM.LABEL.ROLES' | translate}}
            </h6>
              <ul class="list-group">
                <li class="list-group-item" style="padding-top: 20px" *ngFor="let item of availableRoles">
                  <label class="ckbox"><input type="checkbox" name="ActiveRoles" formControlName="ActiveRoles" [checked]="roleIsChecked(item)" (change)="checkChanged($event, item)"><span> {{ item.Description }}</span></label>
                </li>
              </ul>
            </div>
          </div>


       <!-- Buttons -->
      <div class="row">

        <div class="col-6">
          <button (click)="submit()" [disabled]="!IsValidAndDirty()" class="btn btn-block btn-primary bd-0">
            <i class="far fa-save"></i>
            <span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span>
          </button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
            <i class="fas fa-ban"></i>
            <span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
