<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">

        <div class="card-body-height">
         <div class="row">
            <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.CUSTOMER' | translate}}:</label>
                        <t4-customer-selector #customerSelector [clearable]="true" (onCustomerChanged)="customerSelected()"></t4-customer-selector>                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-control-label">{{'FINANCE.DISCOUNTS.FIELDS.ORIGINALAMOUNT' | translate}}: </label>
                      <input class="form-control" name="OriginalAmount" formControlName="OriginalAmount" placeholder="{{'FINANCE.DISCOUNTS.FIELDS.ORIGINALAMOUNT.PLACEHOLDER' | translate}}" type="number">
                      <t4-display-validation controlName="OriginalAmount" [form]="form" [messages]="validationMessages"></t4-display-validation>
                      </div>
                  </div>
                </div>

                <div class="form-layout-footer row">
                  <div class="col-6">
                    <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
                      class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
                  </div>
                  <div class="col-6">
                    <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
                      class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
                  </div>
                </div>

            </div>

          </div>
        </div>

        <!-- <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div> -->
      </div><!-- form-layout -->
    </div>

  </form>
