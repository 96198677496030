import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MembershipModel, MembershipModelPagedResult, MembershipService } from '../../api';

@Component({
  selector: 'app-create-membership-batch-form',
  templateUrl: './create-membership-batch-form.component.html',
})
export class CreateMembershipBatchFormComponent implements OnInit {
  memberships : MembershipModel[] = []
  form: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<CreateMembershipBatchFormComponent>,
    private membershipService: MembershipService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      MembershipId: new FormControl(null, Validators.required)
    });

    const filters = { onlyRecurring: "Y" }; // any non-empty string means yes

    this.membershipService.searchMemberships('', 1, 999999, "Name", true, filters).subscribe((resp: MembershipModelPagedResult) => {
      this.memberships = resp.Records
    });
  }

  submit() {
    this.dialogRef.close(this.form.value)
  }
}
