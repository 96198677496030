<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form class="form-content">
  <div class="section-wrapper">
    <div class="form-layout">
      
      <div class="row">

        <div class="col-lg-12">
          <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.UPLOAD-DISCOUNTCODES-IMPORTFILE.FORM.LABEL.FILE' | translate}}<span class="tx-danger">*</span></label>
              <input #file type="file" class="form-control-file"/>
          </div>
        </div>

      </div><!-- row -->

      <div class="row">
        
        <div class="col-lg-12">
          <div class="form-group">
              <label class="form-control-label">
                  <a (click)="downloadTemplate()" target="_blank" class="file-download-link">
                      <i class="far fa-file-excel"></i>
                      {{'DIALOGS.UPLOAD-DISCOUNTCODES-IMPORTFILE.FORM.LABEL.DOWNLOAD-IMPORTFILE' | translate}}
                  </a>

              </label>
            
          </div>
        </div>

      </div>

      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="uploadDiscountCodesFile(file.files);" [disabled]="!file.files"  class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{'GENERIC.BUTTON.IMPORT' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>
