<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{passedData.title}}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal-body p-5">
    <p class="mg-b-5" [innerHtml]="passedData.message | safe: 'html'"></p>
</div>
<div class="modal-footer">
    <div class="col-6">
      <button type="button" class="btn btn-block btn-secondary" (click)="dialogRef.close(false);">{{ 'BTN.CLOSE' | translate }}</button>
    </div>
</div>
