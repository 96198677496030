import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {HttpLoaderService} from '@tymes4-shared';
import {Router} from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ReservationDetailsComponent} from 'app/shared/dialogs/reservation-details/reservation-details.component';
import {ConfirmService} from '@tymes4-shared';
import {SelectPendingorderDebtorComponent} from 'app/shared/dialogs/select-pendingorder-debtor/select-pendingorder-debtor.component';
import {CashRegisterStateService} from 'app/shared/services/cash-register-state.service';
import {CashRegisterAction} from 'app/shared/models/cache-register-state';
import {ListViewSelectionMode} from '@tymes4-shared';
import { TranslateService } from "@ngx-translate/core";
import { PendingOrderFromReservationsArgs, PendingOrderService, ReservationService, ReservationViewPagedResult } from '../../api';
import { AuthService } from 'app/shared/services/auth.service';
import { ApplicationModuleHelperService } from '../../services/application-module.service';


@Component({
  selector: 'app-reservation-selection',
  templateUrl: './reservation-selection.component.html',
  styleUrls: ['./reservation-selection.component.scss']
})
export class ReservationSelectionComponent implements OnInit {

  @ViewChild('listVw') listVw;

  public selectedReservationItems: any[] = null;

  public singleCustomerId: number = null;
  public selectedCustomers: any[] = null;
  public componentTitle = null;
  public orderAllowed = false;
  public isIbaActive: boolean = false;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12,
    noItemsText: 'CUSTOMER.RESERVATIONS.LISTVIEW.NOITEMSTEXT',
    searchTitle: 'CUSTOMER.RESERVATIONS.LISTVIEW.SEARCH',
    selectionMode: ListViewSelectionMode.MultiSelect,
    multiSelectMode: true,
    fixedTableLayout: true,
    headers: [
      {sortField: null, label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.DATE', width: 100},
      {sortField: 'ReservationDate', label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.TIME', width: 80, isDefaultSort: 'desc'},
      {sortField: null, label: '', width: 30},
      {sortField: 'ReservationTypeName', label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.TYPE', width: 175},
      {sortField: 'CustomerName', label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.CUSTOMER', width: 215},
      {sortField: null, label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.CONTENT', overflowHidden: true},
      {sortField: 'Expires', label: 'CUSTOMER.RESERVATIONS.LISTVIEW.HEADER.EXPIRES', width: 110},
      {sortField: null, label: '', width: 120},
    ]
  };

  public isInArchiveMode = this.authService.isInArchiveMode();

  @Input()
  set title(t: string) {
    this.componentTitle = t;

  }

  @Input()
  set SelectedCustomers(cs: any[]) {
    this.selectedCustomers = cs;
    this.singleCustomerId = null;
  }

  @Input()
  set SingleCustomerId(id) {
    this.singleCustomerId = id;
    this.selectedCustomers = null;
  }


  constructor(
    private loader: HttpLoaderService,
    private pendingOrderService: PendingOrderService,
    private router: Router,
    private dialog: MatDialog,
    private confirmService: ConfirmService,
    private reservationService: ReservationService,
    private translate: TranslateService,
    private cashRegisterState: CashRegisterStateService,
    private authService: AuthService,
    private applicationModuleHelperService: ApplicationModuleHelperService
  ) {
  }

  ngOnInit() {
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  actionClick(action: string, reservation: any = null) {
    if (action === 'order') {
      this.startOrderProcess();
    } else if (action === 'deletereservation') {
      this.openReservationConfirmDialog(reservation);
    } else if (action === 'open-reservation') {
      this.openReservationDetailsDialog(reservation);
    }
  }

  openAllowed() {
    if (this.selectedReservationItems === null) {
      return false;
    } else if (this.selectedReservationItems.length !== 1) {
      return false;
    }
    return true;
  }

  deleteAllowed(reservation) {
    if (reservation === null) {
      return false;
    }
    return reservation.ConvertedToOrderId === null;
  }

  OnItemSelectionChange(items) {
    const isAdd = items && ((items.length === 1 && !this.selectedReservationItems) || (items.length > this.selectedReservationItems.length));

    if (items.length > 0) {
      this.selectedReservationItems = items.map(obj => ({...obj}));
      this.orderAllowed = true;
    } else {
      this.selectedReservationItems = null;
      this.orderAllowed = false;
    }

    if (items.filter(i => i.IsExpired || i.ConvertedToOrderId !== null).length > 0) {
      if (isAdd) {
        this.confirmService.okOnlyConfirm(
          this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.TITLE.RESERVATIONS' ),
          this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.MESSAGE.NO-EXPIRED' )).subscribe(r => {});
      }
      this.orderAllowed = false;
    }
  }

  startOrderProcess() {

    if (this.selectedReservationItems === null || this.selectedReservationItems.length === 0) {
      return;
    }

    var debtorCustomerId = this.singleCustomerId;

    if (this.singleCustomerId != null) {
      this.initiateOrder(this.singleCustomerId, false);
    } else if (this.selectedCustomers !== null) {
      if (this.selectedCustomers.length > 1) {

        //we need to ask which customer of the selected items will be the debtor
        var customerIdsInSelection = Array.from(new Set(this.selectedReservationItems.map((item: any) => item.CustomerId))); // this.selectedReservationItems.map((r) => r.CustomerId);
        var customersInSelection = [];

        for (let id of customerIdsInSelection) {
          customersInSelection.push(this.selectedCustomers.find(c => c.Id === id));
        }

        if (customersInSelection.length > 1) {

          let dialogRef: MatDialogRef<any> = this.dialog.open(SelectPendingorderDebtorComponent, {
            width: '720px',
            disableClose: true,
            data: {title: this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.TITLE.WHO-ORDERCUSTOMER' ), payload: customersInSelection}
          })
          dialogRef.afterClosed()
            .subscribe(selectedDebtorId => {

                if (selectedDebtorId) {
                  this.initiateOrder(selectedDebtorId, true);
                }
              }
            );

        } else {
          this.initiateOrder(customersInSelection[0].Id, true);
        }


      } else {
        debtorCustomerId = this.selectedCustomers[0].Id;
        this.initiateOrder(debtorCustomerId, true);
      }
    }
  }

  initiateOrder(debtorCustomerId: number, fromCashRegister): any {

    if (fromCashRegister) {
      var state = this.cashRegisterState.get();
      state.currentAction = CashRegisterAction.OrderingStarted;
      this.cashRegisterState.set(state);
    }

    this.loader.open();

    const args : PendingOrderFromReservationsArgs = {
      CustomerId: debtorCustomerId,
      ReservationIds: this.selectedReservationItems.map((r) => r.Id)
    }

    this.pendingOrderService.createFromReservations(args).subscribe((pendingOrderId: number) => {
      this.loader.close();
      sessionStorage.setItem('pendingOrderId', pendingOrderId.toString());
      this.router.navigate(['/sales/orders/sell-tickets']);
    });

  }

  openReservationDetailsDialog(reservation) {

    let dialogRef: MatDialogRef<any> = this.dialog.open(ReservationDetailsComponent, {
      width: '95%',
      maxWidth: '95%',
      disableClose: true,
      data: {
        title: this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.TITLE.RESERVATION-DETAILS' ),
        isReadOnly: true,
        Reservation: reservation
      }
    })
    dialogRef.afterClosed()
      .subscribe(() => {
          //nothing to do, dialog is read only
        }
      );

  }

  openReservationConfirmDialog(rt) {

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE' ),
      message: this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.MESSAGE.ARE-YOU-SURE' ),
      okonly: false
    }).subscribe((confirmed: boolean) => {

      if (confirmed === true) {
        this.reservationService.deleteReservationCascading(rt.Id).subscribe(() => {
          //close this dialog and pass the newly added id to the called
          this.listVw.refreshData();
          this.selectedReservationItems = null;
        });

      }
    });
    ;
  }

  OnDataRequest(e) {

    let joinedCustomerIds: string = ''

    if (this.singleCustomerId !== null) {
      joinedCustomerIds = this.singleCustomerId.toString();
    } else {
      joinedCustomerIds = this.selectedCustomers.map(c => c.Id).join(',');
    }

    console.log('e', e);
    this.reservationService.searchReservations(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, {['customerIds']: joinedCustomerIds}).subscribe((resp: ReservationViewPagedResult) => {
      this.listVw.Data = resp;
    });
  }

  getActionTitle() {
    let titleString: string = this.translate.instant('CUSTOMER.RESERVATIONS.DIALOG.TITLE.GET-RESERVATION-FOR' );

    titleString = titleString.toUpperCase() ;

    for (const customer of this.selectedCustomers) {
      if (customer.ContactFullName != undefined) {
        titleString += customer.ContactFullName + ", ";
      } else {
        titleString += "-, ";
      }
    }
    if (titleString.endsWith(', ')) {
      titleString = titleString.slice(0, -2);
    }

    return titleString;
  }

  constructCustomerIdentificationModel(reservation){
    return {
      CalculatedName : reservation.CustomerCalculatedName,
      IsIdentified: reservation.CustomerIsIdentified,
      CustomerNumber: reservation.CustomerNumber,
    }
  }
}
