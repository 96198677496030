<div *ngIf="data.length === 0">
  {{'COMPONENTS.DELIVERY-METHOD-GRID.TITLE.EVENT-NOT-CREATED' | translate}}
</div>

<div *ngIf="!isInitialized && data.length !== 0">
  {{'COMPONENTS.DELIVERY-METHOD-GRID.LOADINGTEXT' | translate}}
</div>


<div class="delivery-method-grid" *ngIf="isInitialized && data.length > 0">
  <ng-container *ngFor="let colLabel of columnLabels">
    <div [ngStyle]="{'grid-column': colLabel.position, 'grid-row': 1}" class="grid-cell grid-cell--center grid-cell--label delivery-method-header">
      {{colLabel.name}}
    </div>
    <div *ngIf="colLabel.position%2" [ngStyle]="{'grid-row-end':numberOfRows, 'grid-column': colLabel.position}" class="column--borders"></div>
  </ng-container>

  <ng-container *ngFor="let rowLabel of pageRowLabels">
    <div [ngStyle]="{'grid-column': 1, 'grid-row': rowLabel.position}" class="grid-cell grid-cell--label">
      {{rowLabel.name}}
    </div>
    <div *ngIf="rowLabel.position%2" [ngStyle]="{'grid-column-end':numberOfColumns, 'grid-row':rowLabel.position}" class="row--alternating"></div>
  </ng-container>

  <div *ngFor="let d of pageData" [ngStyle]="{'grid-column': getColumn(d), 'grid-row': getRow(d)}" class="grid-cell grid-cell--center">
    <div *ngIf="useCustomStyledCheckboxes" class="tri-state-inheritance" [ngClass]="getState(d)" (click)="updateValue(d)"></div>
    <input *ngIf="!useCustomStyledCheckboxes" type="checkbox" readonly [checked]="getShownValue(d)" [indeterminate]="isIndeterminate(d)" (click)="updateValue(d)">
  </div>
</div>

<div class="mt-3">
  <t4-pager-irregular-size (onPage)="updatePage($event.pageNr)" #pagerComponent></t4-pager-irregular-size>
</div>
