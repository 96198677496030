import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//FDP: Tymes4V2 migration: ngx-custom-validators is incompatible with ng13, this is a fork
//import {CustomValidators} from 'ngx-custom-validators';
import { CustomValidators } from '@narik/custom-validators';
import { ValidationHelper } from '../../helpers/validation.helper';
import { PrintableTicket } from '../../api';

@Component({
  selector: 'app-manual-mail-dialog',
  templateUrl: './manual-mail-dialog.component.html',
  styleUrls: ['./manual-mail-dialog.component.scss']
})
export class ManualMailDialogComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: ManualMailDialogParameters,
    public dialogRef: MatDialogRef<ManualMailDialogComponent>,
    private translate: TranslateService
  ) { }

  public config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],

      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link'],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      ['clean'],
    ]
  };

  public title: string;
  public form: FormGroup;

  public attachments: PrintableTicket[] = [];
  public pagedAttachments: PrintableTicket[] = [];
  public lastUsedPage = null;

  public selectedAttachments: number[] = [];
  public attachmentsSelected: number = 0;
  public attachmentsTotal: number = 0;

  public showSalesChannelSelection: boolean = false;
  public salesChannels: any[] = [];
  public selectableSalesChannels: any[] = [];
  public selectedSalesChannelMissesTemplates = false;

  public secondaryTicketingActive = false;
  
  @ViewChild('pagerComponent') pagerComponent;

  public validationMessages = {};

  get isAnyAttachmentSelectable() {
    return this.attachments.some(ticket => ticket.Selectable);
  }

  ngOnInit() {
    this.title = this.passedData.title;
    this.form = new FormGroup({
      Email: new FormControl(this.passedData.email, [Validators.required, CustomValidators.email]),
      Subject: new FormControl(this.passedData.subject, Validators.required),
      Body: new FormControl(this.passedData.body, Validators.required),
      SalesChannelId: new FormControl(null)
    });

    if (this.passedData.attachments) {
      this.showSalesChannelSelection = this.passedData.showSalesChannelSelection ? this.passedData.showSalesChannelSelection : false;
      this.salesChannels = this.passedData.salesChannels;

      this.attachments = this.passedData.attachments;
      this.attachmentsTotal = this.attachments.length;

      for (let ticket of this.attachments) {
        if (ticket.TicketSwapStatus != null && ticket.TicketSwapStatus != "None") {
          this.secondaryTicketingActive = true;
          break;
        }
      }

      this.doPreselect();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setSearchAndPage(1);
      this.allSelectionToggle(true, true);
    });
  }

  doPage(pageArgs) {
    this.setSearchAndPage(pageArgs.pageNr);
  }

  setSearchAndPage(pageNr: number) {
    this.lastUsedPage = pageNr;
    let length = this.attachments.length; //we need a copy
    this.pagerComponent.setObjectCount(length);
    this.pagedAttachments = this.attachments.slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize);
    this.pagerComponent.setPage(pageNr);
  }

  showAttachmentsTab() {
    return this.attachmentsTotal > 0
  }

  isItemSelected(attachment: PrintableTicket) {

    if (!attachment) return null;

    if (this.selectedAttachments == null && this.selectedAttachments.length == 0)
      return false;

    var found = this.selectedAttachments.filter(function (Id) {
      if (Id == attachment.Id) return Id;
    });

    return (found != null && found.length > 0);
  }

  toggleSelection(attachment: PrintableTicket) {

    if (attachment == null) return;

    if (this.isItemSelected(attachment)) {
      //remove from the selection
      var index = this.selectedAttachments.indexOf(attachment.Id);
      this.selectedAttachments.splice(index, 1);
    }
    else {
      this.selectedAttachments.push(attachment.Id);
    }

    this.attachmentsSelected = this.selectedAttachments.length;
  }

  allSelectionToggle(toggleOn: boolean, onlySelectable: boolean = false) {
    //let toggleOn = this.selectedObjects.length != this.orderDetails.OrderLines.length;
    this.selectedAttachments = [];

    if (toggleOn) {
      for (const a of this.attachments) {
        if (onlySelectable && !a.Selectable) continue;

        this.selectedAttachments.push(a.Id);
      }
    }

    this.attachmentsSelected = this.selectedAttachments.length;
  }

  doPreselect() {
    this.selectableSalesChannels = this.salesChannels.filter(function (sc) {
      if (sc.AllTemplatesAvailable === true || sc.IsDefault === true) return sc;
    });

    let defaultChannel = this.salesChannels.filter(function (sc) {
      if (sc.IsDefault === true) return sc;
    });

    this.selectedSalesChannelMissesTemplates = (defaultChannel[0].AllTemplatesAvailable === false);
    this.form.patchValue({ SalesChannelId: defaultChannel[0].Id });
  }

  handleSalesChannelChange(e) {
    const channelId = e.Id;

    let selectedChannel = this.salesChannels.filter(function (sc) {
      if (sc.Id === channelId) return sc;
    });

    this.selectedSalesChannelMissesTemplates = (selectedChannel[0].AllTemplatesAvailable === false);
  }

  closeWithData() {
    var data: ManualMailDialogResult = {
      email: this.form.controls.Email.value,
      subject: this.form.controls.Subject.value,
      body: this.form.controls.Body.value
    }

    if (this.showSalesChannelSelection) {
      data.salesChannelId = this.form.controls.SalesChannelId.value;
    }

    if (this.showAttachmentsTab()) {
      data.attachments = this.selectedAttachments;
    }

    this.dialogRef.close(data);
  }

  // The same function is also in PrintOrDownloadTicketsComponent
  getTicketSwapStatus(status: string) {
    switch (status) {
      case "Offered":
        return 'GENERIC.TICKETSWAPSTATUS.OFFERED';
      case "Accepted":
        return 'GENERIC.TICKETSWAPSTATUS.ACCEPTED';
      case "Sold":
        return 'GENERIC.TICKETSWAPSTATUS.SOLD';
      case "Withdrawn":
        return 'GENERIC.TICKETSWAPSTATUS.WITHDRAWN';
      case "Revoked":
        return 'GENERIC.TICKETSWAPSTATUS.REVOKED';
      case "PendingInvitation":
        return "GENERIC.TICKETSWAPSTATUS.PENDING-INVITATION";
      case "InvitationDeclined":
        return "GENERIC.TICKETSWAPSTATUS.INVITATION-DECLINED";
      case "Assigned":
        return "GENERIC.TICKETSWAPSTATUS.ASSIGNED";
      case "AssignmentRequired":
        return "GENERIC.TICKETSWAPSTATUS.ASSIGNMENT-REQUIRED";
      default:
        return '';
    }
  }
  
  getTicketSwapStatusClass(status: string) {
    switch (status) {
      case "Offered":
        return  ['chipbox', 'chipbox-open'];
      case "Accepted":
        return ['chipbox', 'chipbox-open'];
      case "Sold":
        return ['chipbox', 'chipbox-open'];
      case "Withdrawn":
        return ['chipbox', 'chipbox-closed'];
      case "Revoked":
        return ['chipbox', 'chipbox-closed'];
      case "PendingInvitation":
        return ['chipbox', 'chipbox-open'];
      case "InvitationDeclined":
        return ['chipbox', 'chipbox-open'];
      case "Assigned":
        return ['chipbox', 'chipbox-open'];
      case "AssignmentRequired":
        return ['chipbox', 'chipbox-open'];
      default:
        return [];
    }
  }
}

export interface ManualMailDialogParameters {
  title: string;
  email: string;
  subject: string;
  body: string;

  showSalesChannelSelection?: boolean;
  salesChannels?: any[];

  attachments?: PrintableTicket[];
}

export interface ManualMailDialogResult {
  email: string;
  subject: string;
  body: string;
  salesChannelId?: number;
  attachments?: any[];
}
