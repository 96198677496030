import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EditPurchaseRightFormComponent } from 'app/shared/dialogs/edit-purchase-right-form/edit-purchase-right-form.component';
import {ConfirmService} from '@tymes4-shared';
import { CheckPurchaseRightComponent } from 'app/shared/dialogs/check-purchase-right/check-purchase-right.component';
import { DialogHelper, DialogWidth } from '@tymes4-shared';
import { SnackbarHelper } from 'app/shared/helpers/snackbar-helper';
import { TranslateService } from '@ngx-translate/core';
import {EventService, PurchaseRightService, PurchaseRightView} from "../../api";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-purchase-right',
  templateUrl: './purchase-right.component.html',
  styleUrls: ['./purchase-right.component.scss']
})
export class PurchaseRightComponent implements OnInit {

  // for listview
  @ViewChild('listVw') listVw;

  public isSalesPeriod = false;
  public isEvent = false;
  public isInitiative = false;

  public listOptions = {};
  // end for listview

  public selectedSalesPeriodId = -1;
  public selectedEventId = -1;
  public selectedInitiativeId = -1;
  private selectedEventIsNamed = false;
  public hasPurchaseRights = false;
  public isInArchiveMode = this.authService.isInArchiveMode();



  @ViewChild('pagerComponent') pagerComponent;

  @Input('salesPeriodId')
  set salesPeriodId(id) {
    this.selectedSalesPeriodId = id;
  }
  @Input('eventId')
  set eventId(id) {
    this.selectedEventId = id;
  }
  @Input('initiativeId')
  set initiativeId(id) {
    this.selectedInitiativeId = id;
  }

  constructor(private purchaseRightService: PurchaseRightService,
              private dialog: MatDialog,
              private confirmService: ConfirmService,
              private snackbarHelper: SnackbarHelper,
              private eventService: EventService,
              private loader: HttpLoaderService,
              private translate: TranslateService,
              private authService: AuthService) { }

  ngOnInit() {
    this.isSalesPeriod = this.selectedSalesPeriodId > -1;
    this.isEvent = this.selectedEventId > -1;
    this.isInitiative = this.selectedInitiativeId > -1;
    this.loadListOptions();
  }

  OnDataRequest(e) {
    if (this.selectedSalesPeriodId !== undefined && this.selectedEventId !== undefined) {
      const purchaseType = this.isInitiative ? { ['InitiativeId']: this.selectedInitiativeId.toString() }   : (this.isSalesPeriod ? { ['SalesPeriodId']: this.selectedSalesPeriodId.toString() } : { ['TargetEventId']: this.selectedEventId.toString() });
      this.purchaseRightService.purchaseRightSearch('', e.pageNr, 12, 'AvailableFrom', true, purchaseType ).subscribe((resp: Array<PurchaseRightView>) => {
        this.listVw.Data = resp;
        this.hasPurchaseRights = resp.length > 0;
      });
    }
  }

  loadListOptions()
  {
    this.listOptions = {
      search: false,
      showBarcodeSearch: false,
      pageSize: 12,
      searchTitle: 'Zoeken naar aankooprecht',
      multiSelectMode: false,
      headers: [
        { sortField: 'Id', label: 'Id', width: 50 },
        { sortField: 'Name', label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.FROM', width: 150, isDefaultSort: 'asc'},
        { sortField: 'Code', label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.TILL', width: 150 },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.SALESTYPE', width: 120, visible: this.isEvent },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.PASSE-PARTOUT', width: null, visible: this.isSalesPeriod },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.EVENT-PASSE-PARTOUT', width: null, visible: this.isInitiative },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.AUDIENCE', width: null },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.SALES-CHANNEL', width: 100},
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.NUMBERS', width: 100 },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.TRANSFERABLE', width: 80 },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.PRIMARY', width: 70, visible: this.isSalesPeriod },
        { sortField: null, label: 'EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.HEADER.CROSSSELL', width: 100, visible: this.isSalesPeriod },
        { label: ''},
      ]
    };
  }

  showAmount(unlimitedAmount, amountPerTicket, amountAbsolute) {
    if (unlimitedAmount) {
      return this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.NUMBERS.UNLIMITED');
    } else if (amountPerTicket) {
      return amountPerTicket + ' ' + this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.NUMBERS.AMOUNT.PER.TICKET');
    } else if (amountAbsolute) {
      return amountAbsolute;
    }
  }

  showRightsFor(tagName: string, originPassePartoutName: string) {
    if (tagName) {
      return tagName;
    } else if (originPassePartoutName) {
      return originPassePartoutName;
    } else {
      return this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.LISTVIEW.SHOW.RIGHTS.FOR.AUDIENCE');
    }
  }

  actionClick(action: string, item: any = null) {
    if (action === 'add') {
      this.openDialog(null, true);
    } else if (action === 'edit') {
      this.openDialog(item, false);
    } else if (action === 'delete') {
      this.openConfirmDialog(item);
    } else if (action === 'check') {
      this.openCheckDialog();
    }
  }

  openDialog(data: any = {}, isNew?) {

    if (this.isEvent) {
       if (isNew) {
         //for new events we need to retrieve the event details.
         this.loader.open();

         this.eventService.getEventDetails(this.selectedEventId).subscribe((ev: any) => {
           this.loader.close();
            this.openPurchaseRightDialog(isNew, data, ev.Named);
         });
       }
       else this.openPurchaseRightDialog(isNew, data, data.TargetEventIsNamed);
    }
    else {

      let namedEntity;

      //passe-partouts and initiatives
      if (isNew) namedEntity = false;
      else if (this.isSalesPeriod) namedEntity = data.TargetPassePartoutIsNamed;
      else if (this.isInitiative) namedEntity = data.TargetEventId != null ? data.TargetEventIsNamed : data.TargetPassePartoutIsNamed;
      this.openPurchaseRightDialog(isNew, data, namedEntity);
    }
  }

  openPurchaseRightDialog(isNew, data, named) {

    const title = isNew ? this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.OPEN.DIALOG.NEW.PURCHASE-RIGHT') : this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.OPEN.DIALOG.EDIT.PURCHASE-RIGHT');
    var options = DialogHelper.GetDialogOptions(DialogWidth.lg, {title: title, payload: data, isNew: isNew, salesPeriodId: this.selectedSalesPeriodId, eventId: this.selectedEventId, initiativeId: this.selectedInitiativeId, entityIsNamed: named});
    let purchaseRightDialog: MatDialogRef<any> = this.dialog.open(EditPurchaseRightFormComponent, options);

    purchaseRightDialog.afterClosed()
      .subscribe(rightUpdated => {
        if (rightUpdated) {
          this.listVw.refreshData();
          this.snackbarHelper.saved();
        } else {
          return;
        }
      });

  }

  openConfirmDialog(purchaseRight: any = {}) {
    this.confirmService.confirm({
        title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
        message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE'),
        okonly: false
      }).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.loader.open();

          this.purchaseRightService.deletePurchaseRight(purchaseRight.Id).subscribe((data: any) => {
            // close this dialog and pass the newly added id to the called
            this.listVw.refreshData();
            this.loader.close();
            this.snackbarHelper.deleted();
          });
        } else {
          this.loader.close();
        }
    });
  }

  openCheckDialog() {
    const purchaseRightCheckDialog: MatDialogRef<any> = this.dialog.open(CheckPurchaseRightComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: this.translate.instant('EVENT.DETAILS.PURCHASE-RIGHT.CHECK.DIALOG.TITLE'), salesPeriodId: this.selectedSalesPeriodId, eventId: this.selectedEventId, initiativeId: this.selectedInitiativeId}
    });
    purchaseRightCheckDialog.afterClosed()
      .subscribe(done => {
    });
  }
}
