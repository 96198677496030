<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>


<form class="form-content">

  <div class="section-wrapper">
  
    <div class="form-layout">
      <div class="row">
  
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.FILE-INPUT.FORM.LABEL.TITLE.DESCRIPTION' | translate}}<span class="tx-danger">*</span></label>
            <input class="form-control" name="description"  placeholder="{{'DIALOGS.FILE-INPUT.FORM.LABEL.PLACEHOLDER.DESCRIPTION' | translate}}" maxlength="255" (change)="onDescriptionChanged($event)">
          </div>
        </div>
  
        <div class="col-lg-12 mg-b-20">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.FILE-INPUT.FORM.LABEL.TITLE.SELECT-FILE' | translate}}<span class="tx-danger">*</span></label>
            <app-file-input accept=".png,.jpg,.gif" buttonText="" (onFileSelect)="onFileSelect($event)"></app-file-input>
          </div>
        </div>
      </div>
  
      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="uploadFile()" [disabled]="!this.fileInputDescription || !this.fileInputResult" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>
  
      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>

  
</form>
